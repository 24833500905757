import * as types from './actionTypes'
import services from '../services'
import { COMPLIMENTS_MODERATION_SYSTEM_ID } from '../../utils/constants'


export function postModerationItemBySystem(moderationItem, moderationSystemId) {
  return dispatch => {
    dispatch(postModerationItemActionPending(true))
    services.moderation
      .postModerationItem(moderationItem)
      .then(response => {
        dispatch(postModerationItemActionSuccess(response))

        if (moderationSystemId === COMPLIMENTS_MODERATION_SYSTEM_ID) {
          dispatch(postModerationItemForComplimentActionSuccess(response))
        }
        
        dispatch(postModerationItemActionPending(false))
      })
      .catch(error => {
        dispatch(postModerationItemActionError(error))
        dispatch(postModerationItemActionPending(false))
      })
  }
}

export function postModerationItem(moderationItem, flaggedItem) {
  return dispatch => {
    dispatch(postModerationItemActionPending(true))
    services.moderation
      .postModerationItem(moderationItem)
      .then(response => {
        dispatch(postModerationItemActionSuccess(response, flaggedItem))
        dispatch(postModerationItemActionPending(false))
      })
      .catch(error => {
        dispatch(postModerationItemActionError(error))
        dispatch(postModerationItemActionPending(false))
      })
  }
}

const postModerationItemForComplimentActionSuccess = (response) => {
  return {
    type: types.POST_MODERATION_ITEM_FOR_COMPLIMENT_SUCCESS,
    payload: response.data
  }
}

const postModerationItemActionSuccess = (response, flaggedItem) => {
  return {
    type: types.POST_MODERATION_ITEM_SUCCESS,
    payload: response.data,
    flaggedItem
  }
}
const postModerationItemActionError = response => {
  return {
    type: types.POST_MODERATION_ITEM_ERROR,
    error: response,
  }
}
const postModerationItemActionPending = status => {
  return {
    type: types.POST_MODERATION_ITEM_PENDING,
    status,
  }
}

export function getModerationReasons() {
  return dispatch => {
    dispatch(getModerationReasonsActionPending(true))
    services.moderation
      .getModerationReasons()
      .then(response => {
        dispatch(getModerationReasonsActionSuccess(response))
        dispatch(getModerationReasonsActionPending(false))
      })
      .catch(error => {
        dispatch(getModerationReasonsActionError(error))
        dispatch(getModerationReasonsActionPending(false))
      })
  }
}


const getModerationReasonsActionSuccess = response => {
  return {
    type: types.GET_MODERATION_REASONS_SUCCESS,
    payload: response.data,
  }
}
const getModerationReasonsActionError = response => {
  return {
    type: types.GET_MODERATION_REASONS_ERROR,
    error: response,
  }
}
const getModerationReasonsActionPending = (status) => {
  return {
    type: types.GET_MODERATION_REASONS_PENDING,
    status,
  }
}
