import { obfuscate, deObfuscate, canDeObfuscate } from './obfuscateUtils'
import { isDefinedAndNotEmpty } from './nullSafeCheckUtils'

const RGIS_PERSON_ID_KEY = 'rgis-personId'
const RGIS_LANGUAGE_KEY = 'rgis-language'
const RGIS_COUNTRY_KEY = 'rgis-country'
const RGIS_ROLE_KEY = 'rgis-role'
const RGIS_BEARER_KEY = 'rgis-bearer'
const RGIS_ID_TOKEN_HINT_KEY = 'rgis_id_token_hint'
const RGIS_NEW_POSTS_KEY = 'newPosts'

const RGIS_FSC_ROLE = 'FSC'
const RGIS_MANAGER_ROLE = 'MANAGER'
const DEFAULT_LANGUAGE = 'EN'
const DEFAULT_COUNTRY = 'US'

export function getRgisIdTokenHint() {
    checkLocalStorageBackwordsCompatibility(RGIS_ID_TOKEN_HINT_KEY)

    let rgisIdTokenHint = getLocalStorageItem(RGIS_ID_TOKEN_HINT_KEY)

    if (isDefinedAndNotEmpty(rgisIdTokenHint)) {
        return rgisIdTokenHint
    } else {
        return null
    }
}

export function setRgisIdTokenHint(rgisIdTokenHint) {
    setLocalStorageItem(RGIS_ID_TOKEN_HINT_KEY, rgisIdTokenHint)
}

export function getRgisBearerToken() {
    checkLocalStorageBackwordsCompatibility(RGIS_BEARER_KEY)

    var bearerToken = getLocalStorageItem(RGIS_BEARER_KEY)

    if (isDefinedAndNotEmpty(bearerToken)) {
        return bearerToken
    } else {
        return null
    }
}

export function setRgisBearerToken(bearerToken) {
    setLocalStorageItem(RGIS_BEARER_KEY, bearerToken)
}

export function getNewPosts() {
    checkLocalStorageBackwordsCompatibility(RGIS_NEW_POSTS_KEY)

    var newPosts = getLocalStorageItem(RGIS_NEW_POSTS_KEY)

    if (!isDefinedAndNotEmpty(newPosts)) {
        return 0
    }

    try {
        return parseInt(newPosts, 10)
    } catch {
        return 0
    }
}

export function setNewPosts(newPosts) {
    setLocalStorageItem(RGIS_NEW_POSTS_KEY, newPosts)
}

export function loggedInUserRoleIsFsc () {
    return getRgisRole() === RGIS_FSC_ROLE
}

export function loggedInUserIsManager() {
    return getRgisRole() === RGIS_MANAGER_ROLE
}

export function getRgisRole() {
    checkLocalStorageBackwordsCompatibility(RGIS_ROLE_KEY)

    return getLocalStorageItem(RGIS_ROLE_KEY)
}

export function setRgisRole(rgisRole) {
    setLocalStorageItem(RGIS_ROLE_KEY, rgisRole)
}

export function getLoggedInPersonId() {
    checkLocalStorageBackwordsCompatibility(RGIS_PERSON_ID_KEY)

    const personId = getLocalStorageItem(RGIS_PERSON_ID_KEY)

    if (!isDefinedAndNotEmpty(personId)) {
        return null
    }

    try {
        return parseInt(personId, 10)
    } catch {
        return null
    }
}

export function setLoggedInPersonId(personId) {
    setLocalStorageItem(RGIS_PERSON_ID_KEY, personId)
}

function checkPreferredLanguageBackwordsCompatibility() {
    if (RGIS_LANGUAGE_KEY in localStorage) { // check for old storage values for language
        let currentStorageValue = localStorage.getItem(RGIS_LANGUAGE_KEY)
        if (isDefinedAndNotEmpty(currentStorageValue)) {
            if (currentStorageValue.toUpperCase() === 'EN' 
                || currentStorageValue.toUpperCase() === 'FR' 
                || currentStorageValue.toUpperCase() === 'ES') {
                setLocalStorageItem(RGIS_LANGUAGE_KEY, currentStorageValue)
            }
        }
    }
}

export function getPreferredLanguage() {
    checkPreferredLanguageBackwordsCompatibility()

    var language = getLocalStorageItem(RGIS_LANGUAGE_KEY)

    if (isDefinedAndNotEmpty(language)) {
        if (language === 'US') { // check for old language value and reset to default
            language = DEFAULT_LANGUAGE
            setDefaultLanguage()
        }
    } else { // no default language set, set to default language
        language = DEFAULT_LANGUAGE
        setDefaultLanguage()
    }

    return language
}

function setDefaultLanguage () {
    setPreferredLanguage(DEFAULT_LANGUAGE)
}

export function setPreferredLanguage(language) {
    setLocalStorageItem(RGIS_LANGUAGE_KEY, language)
}

function checkCountryBackwordsCompatibility() {
    if (RGIS_COUNTRY_KEY in localStorage) { // check for old storage values for country
        let currentStorageValue = localStorage.getItem(RGIS_COUNTRY_KEY)
        if (isDefinedAndNotEmpty(currentStorageValue)) {
            if (currentStorageValue.toUpperCase() === 'US' || 
                currentStorageValue.toUpperCase() === 'FR' || 
                currentStorageValue.toUpperCase() === 'CA' || 
                currentStorageValue.toUpperCase() === 'GB') {
                setLocalStorageItem(RGIS_COUNTRY_KEY, currentStorageValue)
            }
        }
    }
}

export function getCountry() {
    checkCountryBackwordsCompatibility()

    var country = getLocalStorageItem(RGIS_COUNTRY_KEY)

    if (!isDefinedAndNotEmpty(country)) {
        country = DEFAULT_COUNTRY
        setDefaultCountry()
    }

    return country
}

function setDefaultCountry () {
    setCountry(DEFAULT_COUNTRY)
}

export function setCountry(country) {
    setLocalStorageItem(RGIS_COUNTRY_KEY, country)
}

export function clearLocalStorage() {
    try {
        removeLocalStorageItem(RGIS_PERSON_ID_KEY)
    } catch (ex) {
        // TODO: call logging 
    }
    
    try {
        removeLocalStorageItem(RGIS_LANGUAGE_KEY)
    } catch (ex) {
        // TODO: call logging
    }

    try {
        removeLocalStorageItem(RGIS_ROLE_KEY)
    } catch (ex) {
        // TODO: call logging
    }

    try {
        removeLocalStorageItem(RGIS_NEW_POSTS_KEY)
    } catch (ex) {
        // TODO: call logging
    }

    try {
        removeLocalStorageItem(RGIS_BEARER_KEY)
    } catch (ex) {
        // TODO: call logging
    }

    try {
        removeLocalStorageItem(RGIS_ID_TOKEN_HINT_KEY)
    } catch (ex) {
        // TODO: call logging
    }

    try {
        removeLocalStorageItem(RGIS_COUNTRY_KEY)
    } catch (ex) {
        // TODO: call logging
    }
}

function removeLocalStorageItem(key) {
    if (key in localStorage) {
        localStorage.removeItem(key)
    }
}

function setLocalStorageItem(key, value) {
    return localStorage.setItem(key, obfuscate(value))
}

function getLocalStorageItem(key) {
    if (key in localStorage) {
        return deObfuscate(localStorage.getItem(key))
    } else {
        return null
    }
}

function canDeObfuscateLocalStorageItem(key) {
    if (key in localStorage) {
        return canDeObfuscate(localStorage.getItem(key))
    } else {
        return true
    }
}

function checkLocalStorageBackwordsCompatibility(key) {
    if (!canDeObfuscateLocalStorageItem(key)) {
        if (key in localStorage) {
            let currentStorageValue = localStorage.getItem(key)
            setLocalStorageItem(key, currentStorageValue)
        }
    }
}