import {
  GET_TEAM_MEMBER_INFO_SUCCESS,
  GET_TEAM_MEMBER_INFO_ERROR,
  GET_TEAM_MEMBER_INFO_PENDING,
} from '../actions/actionTypes'

export default function teamMemberInfoReducer(
  state = {
    teamMemberInfoResponse: {},
    teamMemberInfoPending: false,
    teamMemberInfoError: '',
  },
  action
) {
  switch (action.type) {
    case GET_TEAM_MEMBER_INFO_SUCCESS:
      state.teamMemberInfoResponse[action.personId] = action.payload
      return {
        ...state,
        teamMemberInfoPending: false,
      }
    case GET_TEAM_MEMBER_INFO_ERROR:
      return {
        ...state,
        teamMemberInfoError: action.error,
      }
    case GET_TEAM_MEMBER_INFO_PENDING:
      return {
        ...state,
        teamMemberInfoPending: true,
      }
    default:
      return state
  }
}
