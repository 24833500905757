import axios from 'axios'
import apiConfig from '../../config/api-config'

export function uploadFile(file, onUploadProgress, fileRequestToken) {


	const url = `${apiConfig.S3_FILE}?name=${file.name}`
	return axios.post(`${url}`, file, {
		headers: {
			'Content-Type': 'application/octet-stream'
		},
		onUploadProgress,
		cancelToken: fileRequestToken.token
	})
}

export function deleteFile(file_url) {
	const formData = new FormData()
	formData.append('url', file_url)
	const url = `${apiConfig.S3_FILE}`
	return axios.delete(`${url}`, {data: formData}, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
}