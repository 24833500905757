import * as types from './actionTypes'
import services from '../services'

export function getOrganization(organizationId) {
  return dispatch => {
    dispatch(getOrganizationActionPending(true))
    services.organizations
      .getOrganization(organizationId)
      .then(response => {
        dispatch(getOrganizationActionSuccess(response))
        dispatch(getOrganizationActionPending(false))
      })
      .catch(error => {
        dispatch(getOrganizationActionError(error))
        dispatch(getOrganizationActionPending(false))
      })
  }
}

const getOrganizationActionSuccess = response => {
  return {
    type: types.GET_ORGANIZATION_SUCCESS,
    payload: response.data,
  }
}
const getOrganizationActionError = response => {
  return {
    type: types.GET_ORGANIZATION_ERROR,
    error: response,
  }
}
const getOrganizationActionPending = status => {
  return {
    type: types.GET_ORGANIZATION_PENDING,
    pending: status,
  }
}

export function searchPeopleInOrganization(organizationId, searchString) {
  return dispatch => {
    dispatch(searchPeopleInOrganizationPendingAction(true))
    services.organizations
      .searchPeopleInOrganization(organizationId, searchString)
      .then(response => {
        dispatch(searchPeopleInOrganizationSuccessAction(response))
        dispatch(searchPeopleInOrganizationPendingAction(false))
      })
      .catch(error => {
        dispatch(searchPeopleInOrganizationErrorAction(error))
        dispatch(searchPeopleInOrganizationPendingAction(false))
      })
  }
}

const searchPeopleInOrganizationSuccessAction = response => {
  return {
    type: types.SEARCH_PEOPLE_IN_ORGANIZATION_SUCCESS,
    payload: response.data,
  }
}
const searchPeopleInOrganizationErrorAction = response => {
  return {
    type: types.SEARCH_PEOPLE_IN_ORGANIZATION_ERROR,
    error: response,
  }
}
const searchPeopleInOrganizationPendingAction = status => {
  return {
    type: types.SEARCH_PEOPLE_IN_ORGANIZATION_PENDING,
    pending: status,
  }
}

export function setOrganizationPeople(organizationPeople) {
  return dispatch => {
    dispatch(
      setOrganizationPeopleAction(organizationPeople)
    )
  }
}

const setOrganizationPeopleAction = payload => {
  return {
    type: types.SET_ORGANIZATION_PEOPLE,
    payload: payload,
  }
}

export function getAllPeopleInOrganization(organizationId) {
  return dispatch => {
    dispatch(getAllPeopleInOrganizationPendingAction(true))
    services.organizations
      .getAllPeopleInOrganization(organizationId)
      .then(response => {
        dispatch(getAllPeopleInOrganizationSuccessAction(response))
        dispatch(getAllPeopleInOrganizationPendingAction(false))
      })
      .catch(error => {
        dispatch(getAllPeopleInOrganizationErrorAction(error))
      })
  }
}

const getAllPeopleInOrganizationSuccessAction = response => {
  return {
    type: types.GET_ALL_PEOPLE_IN_ORGANIZATION_SUCCESS,
    payload: response.data,
  }
}
const getAllPeopleInOrganizationErrorAction = response => {
  return {
    type: types.GET_ALL_PEOPLE_IN_ORGANIZATION_ERROR,
    error: response,
  }
}
const getAllPeopleInOrganizationPendingAction = status => {
  return {
    type: types.GET_ALL_PEOPLE_IN_ORGANIZATION_PENDING,
    pending: status,
  }
}