import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './SentComplimentsItem.scss'
import PostUserAvatar from '../PostUserAvatar'
import { t } from '../../utils/i18n'
import moment from 'moment'
import {
  INTEGRITY_VALUE,
  PRIDE_VALUE,
  SERVICE_VALUE,
  TEAMWORK_VALUE,
  INNOVATION_VALUE,
  RESULTS_VALUE,
} from '../../utils/constants'
import { obfuscate } from '../../components/utils/obfuscateUtils'
import { formatTime, getMonthAndDay, isToday } from '../../components/utils/datetimeUtils'
import commentActionsImg from '../../styles/images/ellipsis-v-solid.svg'


class SentComplimentsItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      complimentActionDropdown: false,
    }

    this.complimentActionsClick = this.complimentActionsClick.bind(this)
    this.editComplimentClick = this.editComplimentClick.bind(this)
    this.removeComplimentClick = this.removeComplimentClick.bind(this)
  }

  eventIdIsValid = (eventId) => {
    return (eventId !== undefined && eventId !== null && eventId > 0)
  }

  eventDetailsAreValid = () => {
    return (this.props.eventDetails !== undefined && this.props.eventDetails !== null && this.props.eventDetails.scheduledEventId > 0)
  }

  renderDate(date) {
    return (
      <div className="row">
        <div className="col">
          {isToday(date) ?
            <div>{formatTime(date)}</div>
            :
            <div>{`${getMonthAndDay(date)} | ${formatTime(date)}`}</div>
          }
        </div>
      </div>
    )
  }

  getEventDetailsName = () => {
    const eventName = this.props.eventDetails.bannerName === null || this.props.eventDetails.bannerName === undefined
      ? this.props.eventDetails.customerName
      : this.props.eventDetails.bannerName

    return (<div className="name">{eventName} #{this.props.eventDetails.storeNumber}</div>)
  }

  getNonEventTitle = () => (
    <div
      className="name cursor-pointer"
      onClick={() => this.props.history.push(`/public-profile/${obfuscate(this.props.compliment.recipients[0])}`)}
    >
      {this.props.compliment.recipients.length > 1
        ? this.props.complimentReceiverName.concat(` ${t('and_others')}`)
        : this.props.complimentReceiverName
      }
    </div>
  )

  getComplimentTitle = () => {
    return this.eventDetailsAreValid() ? this.getEventDetailsName() : this.getNonEventTitle()
  }

  returnPeopleMarkUp = () => {
    let peopleCount = this.props.compliment.recipients.length.toString();
    return peopleCount === "1" ? '' :
      (<div className="people-count">{peopleCount.concat(` ${t('people')}`)}</div>)
  }

  complimentActionsClick(evt) {
    evt.stopPropagation()
    this.setState({ complimentActionDropdown: !this.state.complimentActionDropdown })
  }

  editComplimentClick(evt) {
    evt.stopPropagation()
    const { compliment } = this.props
    this.props.editComplimentClick(compliment)
  }

  removeComplimentClick(evt) {
    evt.stopPropagation()
    const { compliment } = this.props
    this.setState({ complimentActionDropdown: false })
    this.props.removeComplimentClick(compliment.complimentId)
  }

  returnComplimentActionMarkUp = () => {
    const { complimentActionDropdown } = this.state

    return (
      <div className="dropleft compliment-actions">
        <div
          className="compliment-actions-button"
          onClick={this.complimentActionsClick}
        >
          <img src={commentActionsImg} alt="Compliment actions" />
        </div>
        <div className={`dropdown-menu ${complimentActionDropdown ? 'show' : ''}`} aria-labelledby="dropdownMenu2">
          <div>
            <button className="dropdown-item" type="button" onClick={this.editComplimentClick}>{t('edit_comment')}</button>
            {/* <button className="dropdown-item" type="button" onClick={this.removeComplimentClick}>{t('remove')}</button> */}
          </div>
        </div>
      </div>
    )
  }

  returnCoreValueIconMarkUp = () => {
    let coreValues = []

    for (let x = 0; x < this.props.compliment.coreValues.length; x++) {
      coreValues.push(this.props.compliment.coreValues[x].coreValue)
    }

    return (
      <div className="row core-value-icons">
        <div className="col-2 col-md-auto">
          <div
            className={coreValues.indexOf(SERVICE_VALUE) === -1 ? 'core-value-service' : 'core-value-service-fill'}
            title={t('service')}
            />
        </div>
        <div className="col-2 col-md-auto">
          <div
            className={coreValues.indexOf(PRIDE_VALUE) === -1 ? 'core-value-pride' : 'core-value-pride-fill'}
            title={t('pride')}
            />
        </div>
        <div className="col-2 col-md-auto">
          <div
            className={coreValues.indexOf(INTEGRITY_VALUE) === -1 ? 'core-value-integrity' : 'core-value-integrity-fill'}
            title={t('integrity')}
          />
        </div>
        <div className="col-2 col-md-auto">
          <div
          className={coreValues.indexOf(RESULTS_VALUE) === -1 ? 'core-value-results' : 'core-value-results-fill'}
          title={t('results')}            
          />
        </div>
        <div className="col-2 col-md-auto">
          <div
            className={coreValues.indexOf(INNOVATION_VALUE) === -1 ? 'core-value-innovation' : 'core-value-innovation-fill'}
            title={t('innovation')}            
            />
        </div>
        <div className="col-2 col-md-auto">
          <div
            className={coreValues.indexOf(TEAMWORK_VALUE) === -1 ? 'core-value-teamwork' : 'core-value-teamwork-fill'}
            title={t('teamwork')}
          />
        </div>
        <div className="col-2 col-md-7"></div>
      </div>
    )
  }

  render() {
    const { compliment, complimentReceiverName, userAvatar, history } = this.props

    return (
      <div className="compliments-item">
        <div className="row">
          <div className="col-12 mt-1">{this.renderDate(moment(new Date(compliment.createdDate)))}</div>
          <div className="col">
            <div className="card">
              <div className="card-body">
                <div className="section">
                  <div className="row">
                    <div className="col d-flex">
                      {this.eventDetailsAreValid() ? '' :
                        <PostUserAvatar
                          personName={complimentReceiverName}
                          userAvatarUrl={userAvatar !== undefined ? userAvatar.url : ''}
                          personId={compliment.recipients[0]}
                          redirectToAvatarPage={false}
                          history={history}
                        />
                      }
                      <div className={`col d-flex flex-column justify-content-center ${this.eventDetailsAreValid() ? 'pl-0' : ''}`}>
                        {this.getComplimentTitle()}
                      </div>
                      {this.returnComplimentActionMarkUp()}
                    </div>
                  </div>
                </div>
                {this.returnPeopleMarkUp()}
                {this.returnCoreValueIconMarkUp()}
                <div>{this.props.compliment.comment}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

SentComplimentsItem.propTypes = {
  compliment: PropTypes.object,
  complimentReceiverName: PropTypes.string,
  eventDetails: PropTypes.object,
  editComplimentClick: PropTypes.func,
  removeComplimentClick: PropTypes.func,
  userAvatar: PropTypes.object,
  history: PropTypes.object,
}

export default SentComplimentsItem

