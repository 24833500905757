import get from 'lodash/get'

import * as types from './actionTypes'
import * as appConfig from '../../config/config'
import services from '../services'

import { getIdentityUserManager } from '../../utils/odicUserManager'
import { 
  setLoggedInPersonId, 
  clearLocalStorage, 
  setRgisRole, 
  getRgisIdTokenHint 
} from '../../components/utils/localStorageUtils'

const LOGOUT_REDIRECT_URL = appConfig.config.LOGOUT_REDIRECT_URL

export function setProfileLocale(profileLocale) {
  return dispatch => {
    dispatch(setProfileLocaleAction(profileLocale))
  }
}

const setProfileLocaleAction = profileLocale => {
  return {
    type: types.SET_PROFILE_LOCALE,
    payload: profileLocale,
  }
}

export function getUserProfile() {
  return dispatch => {
    dispatch(getUserProfilePendingAction(true))
    services.auth
      .fetchSessionUserInfo()
      .then(response => {
        const responseData = get(response, 'data', {})

        // Store some key info in the local storage
        if (responseData.role !== undefined) {
          setRgisRole(responseData.role)
        } else {
          logout()
        }
        if (responseData.personId !== undefined) {
          setLoggedInPersonId(responseData.personId)
        } else {
          logout()
        }

        // Store the User Profile Data
        dispatch(getUserProfileAction(responseData))
        dispatch(getUserProfilePendingAction(false))
      })
      .catch(error => {
        dispatch(getUserProfileErrorAction(error))
        dispatch(getUserProfilePendingAction(false))
      })
  }
}

export function checkSession() {
  return () => {
    services.auth
      .fetchCheckSession()
      .then(() => {
        return
      })
      .catch(() => {
        // Do nothing
        // Let the interceptor redirect if needed
      })
  }
}

export function logout() {
  services.auth
    .insertAccountLogout()
    .then(() => {
      const id_token_hint = getRgisIdTokenHint()
      clearLocalStorage()
      getIdentityUserManager().signoutRedirect({
        id_token_hint: id_token_hint,
        post_logout_redirect_uri: LOGOUT_REDIRECT_URL,
      })
    })
    .catch(() => {
      // Do nothing
    })
}

export function resetState() {
  return dispatch => {
    dispatch(
      resetStateAction()
    )
  }
}

const resetStateAction = () => {
  return {
    type: types.RESET_STATE,
  }
}

const getUserProfileAction = response => {
  return {
    type: types.GET_USER_PROFILE,
    payload: response,
  }
}

const getUserProfilePendingAction = pending => {
  return {
    type: types.GET_USER_PROFILE_PENDING,
    payload: pending,
  }
}

const getUserProfileErrorAction = error => {
  return {
    type: types.GET_USER_PROFILE_ERROR,
    payload: error,
  }
}