import axios from 'axios'
import apiConfig from '../../config/api-config'
// import { toQueryString } from './util';

// Post to Logout
export function insertAccountLogout() {
  const url = apiConfig.ACCOUNT_LOGOUT
  return axios.post(url)
}

// Get the Session User Information
export function fetchSessionUserInfo() {
  const url = apiConfig.CONNECT_USER_INFO
  return axios.get(url)
}

// Get the session information
export function fetchCheckSession() {
  const url = apiConfig.SESSION_CHECK
  return axios.get(url)
}
