import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Tab extends Component {
  componentDidMount() {
    if (!this.props.hide) {
      this.props.addTab({
        id: this.props.id,
        title: this.props.title,
      })
    }
  }

  render() {
    if (this.props.hide) {
      return ''
    }

    if (this.props.id === 'event_chat' && this.props.activeTab.id !== 'event_chat') { //  load chat tab only when becomes active
      return ''
    }

    return (
      <div className={`nav-item ${this.props.activeTab.id === this.props.id ? 'active' : ''}`}>
        {this.props.children}
      </div>
    )
  }
}

Tab.propTypes = {
  activeTab: PropTypes.object,
  addTab: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  hide: PropTypes.bool,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}
