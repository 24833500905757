import React from 'react'
import PropTypes from 'prop-types'

import { t } from '../../utils/i18n'
import { formatTime } from '../../components/utils/datetimeUtils'

import './MeetInfo.scss'

export default function MeetInfo({ scheduleData, eventDetails, eventMeetSite }) {
  function createMapLink() {
    let meetSiteAddress

    if (Object.keys(eventMeetSite).length && eventMeetSite.meetSiteAddress) {
      meetSiteAddress = `${eventMeetSite.meetSiteAddress
        .split(',')
        .filter(Boolean)
        .join(', ')} ${eventMeetSite.meetSiteCity}, ${eventMeetSite.meetSiteState} ${eventMeetSite.meetSiteZipCode}`
    }

    if (meetSiteAddress && meetSiteAddress !== scheduleData.meetSite) {
      const encodedAddress = encodeURIComponent(meetSiteAddress)
      const url = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`
      return (
        <a href={url} target="_blank" rel="noopener noreferrer">
          <div>{eventMeetSite.meetSiteAddress1}</div>
          <div>{eventMeetSite.meetSiteAddress2}</div>
          <div>{eventMeetSite.meetSiteAddress3}</div>
          <div>{eventMeetSite.meetSiteCity}, {eventMeetSite.meetSiteState} {eventMeetSite.meetSiteZipCode}</div>
        </a>
      )
    }
    return scheduleData.meetSite
  }

  // If no meetSite, hide the section
  if (!Object.keys(eventMeetSite).length || !eventMeetSite.meetSiteAddress) {
    return ''
  }

  return (
    <div className="card meet-info">
      <div className="card-body">
        <div className="section">
          <div className="row">
            <div className="heading col capitalize">{t('meet_site')}</div>
          </div>
          <div className="row">
            <div className="col-1">
              <i className="material-icons map">place</i>
            </div>
            <div className="col">{createMapLink()} </div>
          </div>
        </div>

        <div className="section">
          <div className="row">
            <div className="col">
              <div className="heading capitalize">{t('meet_time')}</div>
              <div className="icon-label">
                <i className="material-icons access_time">access_time</i>
                <span>{formatTime(scheduleData.meetTime)}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="heading capitalize">{t('meet_site_notes')} </div>
            <div>{eventDetails.meetSiteNotes || t('no_meet_site_notes')}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

MeetInfo.propTypes = {
  scheduleData: PropTypes.object,
  eventDetails: PropTypes.object,
  eventMeetSite: PropTypes.object,
}
