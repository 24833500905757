import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { t } from '../../../utils/i18n'
import {
    setEditingTransportationProfile,
    saveTransportationProfile,
    setBackupTransportationProfile,
    patchTransportationProfile,
    setSearchRideAlongTransportationProfile,
} from '../../../state/actions/transportationProfileActions'
import { searchPeopleInOrganization, setOrganizationPeople } from '../../../state/actions/organizationsActions'
import { cloneDeep } from 'lodash'
import PostUserAvatar from '../../PostUserAvatar'
import { obfuscate } from '../../utils/obfuscateUtils'
import { getPreferredLanguage } from '../../utils/localStorageUtils'

import './TransportationPreferences.scss'

const NO_TRANSPORTATION_TYPE = ''
const PERSONAL_VEHICLE_TRANSPORTATION_TYPE = 0
const WITH_SOMEONE_ELSE_TRANSPORTATION_TYPE = 3
const MAX_PASSENGERS_DEFAULT = 0
const WILL_DRIVE_OTHERS_DEFAULT = false

class TransportationPreferences extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    handleCancelOnClick = () => {
        this.restoreBackup()

        this.setEditingTransportationProfile(false)
    }

    handleEditOnClick = () => {
        this.setEditingTransportationProfile(true)
    }

    handleEditOnClickNoContent = () => {
        let cloneTransportationProfile = cloneDeep(this.props.transportationProfile)
        cloneTransportationProfile.transportationType = PERSONAL_VEHICLE_TRANSPORTATION_TYPE
        cloneTransportationProfile.overNightTravel = false
        cloneTransportationProfile.willDriveOthers = false
        this.saveTransportationProfile(cloneTransportationProfile)

        this.setEditingTransportationProfile(true)
    }

    setEditingTransportationProfile = (editing) => {
        this.props.setEditingTransportationProfile(editing)
    }

    handleSaveClick = (event) => {
        if (this.formIsValid()) {
            this.props.patchTransportationProfile(this.props.transportationProfile)
            this.setEditingTransportationProfile(false)
        } else {
            event.preventDefault()
        }
    }

    formIsValid = () => {
        let isValid = true
        const MAX_PASSENGER_LIMIT = 16
        const MIN_PASSENGER_LIMIT = 1

        if (parseInt(this.props.transportationProfile.transportationType) === WITH_SOMEONE_ELSE_TRANSPORTATION_TYPE) {
            if (this.props.transportationProfile.rideAlongs.length === 0) {
                isValid = false
                alert(t('enter_ride_along'))
            } else if (this.haveRideAlongErrors()) {
                isValid = false
                alert(t('error_fix_ride_along'))
            }
        } else if (this.props.transportationProfile.willDriveOthers === undefined || this.props.transportationProfile.willDriveOthers === null || this.props.transportationProfile.willDriveOthers === '') {
            isValid = false
            alert(t('error_drive_others'))
        } else if ((this.props.transportationProfile.willDriveOthers !== undefined && this.props.transportationProfile.willDriveOthers !== null && this.props.transportationProfile.willDriveOthers.toString() === 'true')
            && (!Number.isInteger(parseInt(this.props.transportationProfile.maxPassengers)) || parseInt(this.props.transportationProfile.maxPassengers) < MIN_PASSENGER_LIMIT || parseInt(this.props.transportationProfile.maxPassengers) > MAX_PASSENGER_LIMIT)) {
            isValid = false
            alert(t('error_max_passengers'))
        } else if (this.props.transportationProfile.transportationType === undefined || this.props.transportationProfile.transportationType === null || this.props.transportationProfile.transportationType === NO_TRANSPORTATION_TYPE) {
            isValid = false
            alert(t('error_travel_preferences'))
        } else if (this.props.transportationProfile.overNightTravel === undefined || this.props.transportationProfile.overNightTravel === null || this.props.transportationProfile.overNightTravel === '') {
            isValid = false
            alert(t('error_overnight_travel'))
        }

        return isValid
    }

    haveRideAlongErrors = () => {
        for (let i = 0; i < this.props.transportationProfile.rideAlongs.length; i++) {
            for (let j = 0; j < this.props.rideAlongErrors.length; j++) {
                if (this.props.rideAlongErrors[j].toString() === this.props.transportationProfile.rideAlongs[i].driverId.toString()) {
                    return true
                }
            }
        }

        return false
    }

    getEditTranportationPreferencesMarkUp = () => {
        const language = getPreferredLanguage()

        return (
            <form onSubmit={this.handleSaveClick}>
                <div className="transportation-preferences-component">

                    <div className="section">
                        <div className="row">
                            <div className={`col general-title uppercase ${language.toUpperCase() === 'FR' ? '' : 'capitalize'}`}> 
                                {t('travel_preferences')} | <a onClick={this.handleCancelOnClick} className="transportation-preferences-link uppercase cursor-pointer">{t('cancel')}</a> {/* eslint-disable-line */}
                            </div>
                        </div>
                    </div>

                    <div className="section">
                        <div className="row">
                            <div className='col'>
                                <div className="heading">{t('transportation_method')}</div>
                                <div>{this.getTransportationTypeSelectMarkUp()}</div>
                            </div>
                        </div>
                    </div>

                    {this.getWillingToDriveOthersMarkUp()}

                    {this.getMaxPassengersCanDriveMarkUp()}

                    {this.getRideAlongMarkUp()}

                    {this.getAvailOvernightTravelMarkUp()}

                    <div className="section">
                        <div className="row">
                            <div className="col">
                                <input className="btn btn-rgis-blue semiBoldText uppercase cursor-pointer" type="submit" value={t('save')} />
                            </div>
                        </div>
                    </div>

                    {this.getSearchRideAlongDisclaimerMarkUp()}
                </div>
            </form>
        )
    }

    getSearchRideAlongDisclaimerMarkUp = () => {
        if (parseInt(this.props.transportationProfile.transportationType) === WITH_SOMEONE_ELSE_TRANSPORTATION_TYPE) {
            return (
                <div className='explanation-text'>*{t('three_or_more_to_search')}</div>
            )
        } else {
            return ('')
        }
    }

    saveTransportationProfile = (transportationProfile) => {
        transportationProfile.personId = this.props.personDetails.personId
        this.props.saveTransportationProfile(transportationProfile)
    }

    getWillingToDriveOthersMarkUp = () => {
        if (parseInt(this.props.transportationProfile.transportationType) === PERSONAL_VEHICLE_TRANSPORTATION_TYPE) {
            return (
                <div className="section">
                    <div className="row">
                        <div className='col'>
                            <div className="heading">{t('will_drive_others')}</div>
                            <div>
                                {this.getWillingToDriveOthersControlMarkUp()}
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return ('')
        }
    }

    getAvailOvernightTravelMarkUp = () => {
        if (this.props.transportationProfile.transportationType === undefined || this.props.transportationProfile.transportationType === null || this.props.transportationProfile.transportationType === NO_TRANSPORTATION_TYPE) {
            return ('')
        } else {
            return (
                <div className="section">
                    <div className="row">
                        <div className='col'>
                            <div className="heading">{t('avail_overnight_travel')}</div>
                            <div>
                                {this.getAvailOvernightTravelControlMarkUp()}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    handleWillDriveOthersOnChange = (event) => {
        let newTransProfile = cloneDeep(this.props.transportationProfile)
        newTransProfile.willDriveOthers = event.target.value
        newTransProfile.maxPassengers = MAX_PASSENGERS_DEFAULT
        this.saveTransportationProfile(newTransProfile)
    }

    handleAvailOvernightTravelOnChange = (event) => {
        let newTransProfile = cloneDeep(this.props.transportationProfile)
        newTransProfile.overNightTravel = event.target.value
        this.saveTransportationProfile(newTransProfile)
    }

    getAvailOvernightTravelControlMarkUp = () => {
        if (this.props.editingTransportationProfile) {
            return (
                <select onChange={this.handleAvailOvernightTravelOnChange} value={this.props.transportationProfile.overNightTravel}>
                    <option value=''></option>
                    <option value='false'>No</option>
                    <option value='true'>Yes</option>
                </select>
            )
        } else {
            if (this.props.transportationProfile.overNightTravel === null || this.props.transportationProfile.overNightTravel === undefined || this.props.transportationProfile.overNightTravel === '') {
                return (<div></div>)
            } else if (this.props.transportationProfile.overNightTravel.toString().toLowerCase() === 'true') {
                return (<div>{t('yes')}</div>)
            } else {
                return (<div>{t('no')}</div>)
            }
        }
    }

    getWillingToDriveOthersControlMarkUp = () => {
        if (this.props.editingTransportationProfile) {
            return (
                <select onChange={this.handleWillDriveOthersOnChange} value={this.props.transportationProfile.willDriveOthers}>
                    <option value=''></option>
                    <option value='false'>No</option>
                    <option value='true'>Yes</option>
                </select>
            )
        } else {
            if (this.props.transportationProfile.willDriveOthers) {
                return (<div>{t('yes')}</div>)
            } else {
                return (<div>{t('no')}</div>)
            }
        }
    }

    handleTransportationTypeOnChange = (event) => {
        let newTransProfile = cloneDeep(this.props.transportationProfile)
        newTransProfile.transportationType = event.target.value

        if (parseInt(event.target.value) !== PERSONAL_VEHICLE_TRANSPORTATION_TYPE) {
            newTransProfile.maxPassengers = MAX_PASSENGERS_DEFAULT
            newTransProfile.willDriveOthers = WILL_DRIVE_OTHERS_DEFAULT
        }

        if (parseInt(event.target.value) !== WITH_SOMEONE_ELSE_TRANSPORTATION_TYPE || newTransProfile.rideAlongs === undefined || newTransProfile.rideAlongs === null) {
            newTransProfile.rideAlongs = []
        }

        this.saveTransportationProfile(newTransProfile)
    }

    getTransportationTypeSelectMarkUp = () => {
        let newTransportationTypes = [{ value: '', translationTag: '' }].concat(this.props.transportationTypes)

        return (
            <select onChange={this.handleTransportationTypeOnChange} value={this.props.transportationProfile.transportationType}>
                {newTransportationTypes.map((transportationType, index) => {

                    return (
                        <option key={index} value={transportationType.value}>{t(transportationType.translationTag)}</option>
                    )
                })}
            </select>
        )
    }

    transportationProfileIsValid = () => {
        return this.props.transportationProfile.personId !== undefined && this.props.transportationProfile.personId !== null
    }

    getReadOnlyTranportationPreferencesMarkUp = () => {
        const language = getPreferredLanguage()

        if (!this.transportationProfileIsValid()) {
            return (
                <div className="transportation-preferences-component">

                    <div className="section">
                        <div className="row">
                            <div className={`col general-title uppercase ${language.toUpperCase() === 'FR' ? '' : 'capitalize'}`}>
                                {t('travel_preferences')} | <a onClick={this.handleEditOnClickNoContent} className="transportation-preferences-link uppercase cursor-pointer">{t('edit')}</a> {/* eslint-disable-line */}
                            </div>
                        </div>
                    </div>

                    <div className="section">
                        <div className="row">
                            <div className={`col general-title`}>
                                {t('enter_travel_preferences')}
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="transportation-preferences-component">

                    <div className="section">
                        <div className="row">
                            <div className={`col general-title uppercase ${language === 'FR' ? '' : 'capitalize'}`}>
                                {t('travel_preferences')} | <a onClick={this.handleEditOnClick} className="transportation-preferences-link uppercase cursor-pointer">{t('edit')}</a> {/* eslint-disable-line */}
                            </div>
                        </div>
                    </div>

                    <div className="section">
                        <div className="row">
                            <div className="col">
                                <div className="heading">{t('transportation_method')}</div>
                                <div>{this.getTranslatedTransporationType(this.props.transportationProfile.transportationType)}</div>
                            </div>
                        </div>
                    </div>

                    {this.getWillingToDriveOthersMarkUp()}

                    {this.getMaxPassengersCanDriveMarkUp()}

                    {this.getRideAlongMarkUp()}

                    {this.getAvailOvernightTravelMarkUp()}
                </div>
            )
        }
    }

    getRideAlongMarkUp = () => {
        if (parseInt(this.props.transportationProfile.transportationType) === WITH_SOMEONE_ELSE_TRANSPORTATION_TYPE) {
            return (
                <div className="section">
                    <div className="row">
                        <div className='col'>
                            <div className="heading">{t('team_members_to_ride_with')}</div>
                            <div>
                                {this.getRideAlongControlMarkUp()}
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return ('')
        }
    }

    handleRideAlongSearchOnChange = (event) => {
        this.setSearchRideAlongTransportationProfile(event.target.value)

        if (event.target.value && event.target.value.length > 2) {
            this.props.searchPeopleInOrganization(this.props.personDetails.organizationId, event.target.value)
        }

        if (document.getElementById('rideAlongSearch').value) {
            let selectedValue = document.getElementById("rideAlongSearch").value;
            var opts = document.getElementById('rideAlongOptions').childNodes;
            for (var i = 0; i < opts.length; i++) {
                if (selectedValue === opts[i].value) {
                    if (!this.rideAlongExists(opts[i].getAttribute('data-id'))) {
                        let newTransProfile = cloneDeep(this.props.transportationProfile)
                        newTransProfile.rideAlongs.push({ driverId: parseInt(opts[i].getAttribute('data-id')), driverName: opts[i].getAttribute('data-name') })
                        this.saveTransportationProfile(newTransProfile)
                    }

                    this.setSearchRideAlongTransportationProfile('')
                    this.setOrganizationPeople([])
                    break;
                }
            }
        }
    }

    rideAlongExists = (id) => {
        for (let i = 0; i < this.props.transportationProfile.rideAlongs.length; i++) {
            if (this.props.transportationProfile.rideAlongs[i].driverId === parseInt(id)) {
                return true
            }
        }

        return false
    }

    setOrganizationPeople = (organizationPeople) => {
        this.props.setOrganizationPeople(organizationPeople)
    }

    setSearchRideAlongTransportationProfile = (searchString) => {
        this.props.setSearchRideAlongTransportationProfile(searchString)
    }

    getRideAlongSearchDataListMarkUp = () => {
        return (
            <datalist id='rideAlongOptions'>
                {this.props.organizationPeople.map((person, index) => {
                    return this.getRideAlongSearchDataListItemMarkUp(person, index)
                })}
            </datalist>
        )
    }

    getRideAlongSearchDataListItemMarkUp = (person, index) => {
        return (
            <option
                data-id={person.personId}
                data-name={person.firstName + ' ' + person.lastName}
                data-index={index}
                value={person.badgeId}
            >
                {person.firstName + ' ' + person.lastName}
            </option>
        )
    }

    getRideAlongControlMarkUp = () => {
        const { profileImages } = this.props
        if (this.props.editingTransportationProfile) {
            return (
                <div>
                    <div>
                        <input
                            className='search-by-name-textbox'
                            placeholder={t('search_by_name')}
                            autoComplete='off'
                            type='text'
                            id='rideAlongSearch'
                            value={this.props.searchRideAlongTransportationProfile}
                            onChange={this.handleRideAlongSearchOnChange}
                            list="rideAlongOptions"
                        />
                        *{this.getRideAlongSearchDataListMarkUp()}
                    </div>
                    <div>
                        {this.props.transportationProfile.rideAlongs.map((rideAlong) => {
                            const userAvatar = profileImages.find(obj => obj.personId === rideAlong.driverId)
                            return (
                                this.getRideAlongItemEditControlMarkUp(rideAlong, userAvatar)
                            )
                        })}

                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    {this.props.transportationProfile.rideAlongs.map((rideAlong) => {
                        const userAvatar = profileImages.find(obj => obj.personId === rideAlong.driverId)
                        return this.getRideAlongItemReadOnlyControlMarkUp(rideAlong, userAvatar)
                    })}
                </div>
            )
        }
    }

    getRideAlongErrorMarkUp = (rideAlong) => {
        if (this.getRideAlongHasError(rideAlong)) {
            return (<div>{t('team_member_not_valid')} <i title={t('team_member_not_valid')} className="material-icons error">error</i></div>)
        } else {
            return ('')
        }
    }

    getRideAlongHasError = (rideAlong) => {
        for (let i = 0; i < this.props.rideAlongErrors.length; i++) {
            if (this.props.rideAlongErrors[i] === parseInt(rideAlong.driverId)) {
                return true
            }
        }

        return false
    }

    handleDeleteRideAlongOnClick = (rideAlong, event) => {
        event.stopPropagation()
        if (window.confirm(t('confirm_delete_ride_along'))) {
            let newTransProfile = cloneDeep(this.props.transportationProfile)
            newTransProfile.rideAlongs = []
            for (let i = 0; i < this.props.transportationProfile.rideAlongs.length; i++) {
                if (this.props.transportationProfile.rideAlongs[i].driverId !== rideAlong.driverId) {
                    newTransProfile.rideAlongs.push({ ...this.props.transportationProfile.rideAlongs[i] })
                }
            }

            this.props.saveTransportationProfile(newTransProfile)
        }
    }

    getRideAlongItemEditControlMarkUp = (rideAlong, userAvatar) => {
        return (!this.getRideAlongHasError(rideAlong) ?
            <div key={rideAlong.driverId} className="d-flex flex-row align-items-center mb-2">
                <div className="driver-info">
                    <PostUserAvatar
                        personName={rideAlong.driverName}
                        userAvatarUrl={userAvatar !== undefined ? userAvatar.url : ''}
                        personId={rideAlong.driverId}
                        redirectToAvatarPage={false}
                        history={this.props.history}
                    />
                </div>
                <div className="col driver-name">
                    <div
                        className="name cursor-pointer"
                        onClick={() => this.props.history.push(`/public-profile/${obfuscate(rideAlong.driverId)}`)}
                    >
                        {rideAlong.driverName} <i onClick={(event) => this.handleDeleteRideAlongOnClick(rideAlong, event)} className="material-icons delete cursor-pointer transportation-preferences-icon">delete</i>
                    </div>
                </div>
            </div>
            :
            <div key={rideAlong.driverId} className="d-flex flex-row align-items-center mb-2">
                <div className="driver-info">
                    <PostUserAvatar
                        personName={rideAlong.driverName}
                        userAvatarUrl={userAvatar !== undefined ? userAvatar.url : ''}
                        clickable={false}
                    />
                </div>
                <div className="col driver-name">
                    <div className="name">
                        {rideAlong.driverName} <i onClick={(event) => this.handleDeleteRideAlongOnClick(rideAlong, event)} className="material-icons delete cursor-pointer transportation-preferences-icon">delete</i>
                    </div>
                    {<div>{t('team_member_not_valid')} <i title={t('team_member_not_valid')} className="material-icons error">error</i></div>}
                </div>
            </div>
        )
    }

    getRideAlongItemReadOnlyControlMarkUp = (rideAlong, userAvatar) => {
        return (!this.getRideAlongHasError(rideAlong) ?
            <div key={rideAlong.driverId} className="d-flex flex-row align-items-center mb-2">
                <div className="personal-info">
                    <PostUserAvatar
                        personName={rideAlong.driverName}
                        userAvatarUrl={userAvatar !== undefined ? userAvatar.url : ''}
                        personId={rideAlong.driverId}
                        redirectToAvatarPage={false}
                        history={this.props.history}
                    />
                </div>
                <div className="col chat-person-name">
                    <div
                        className="name cursor-pointer"
                        onClick={() => this.props.history.push(`/public-profile/${obfuscate(rideAlong.driverId)}`)}
                    >
                        {rideAlong.driverName}
                    </div>
                </div>
            </div>
            :
            <div key={rideAlong.driverId} className="d-flex flex-row align-items-center mb-2">
                <div className="driver-info">
                    <PostUserAvatar
                        personName={rideAlong.driverName}
                        userAvatarUrl={userAvatar !== undefined ? userAvatar.url : ''}
                        clickable={false}
                    />
                </div>
                <div className="col driver-name">
                    <div className="name">
                        {rideAlong.driverName}
                    </div>
                    {<div>{t('team_member_not_valid')} <i title={t('team_member_not_valid')} className="material-icons error">error</i></div>}
                </div>
            </div>
        )
    }

    handleMaxPassengersCanDriveOnChange = (event) => {
        let newTransProfile = cloneDeep(this.props.transportationProfile)
        newTransProfile.maxPassengers = event.target.value
        this.saveTransportationProfile(newTransProfile)
    }

    getMaxPassengersCanDriveControlMarkUp = () => {
        if (this.props.editingTransportationProfile) {
            return (
                <div><input type='text' maxLength='2' onChange={this.handleMaxPassengersCanDriveOnChange} value={this.props.transportationProfile.maxPassengers} /></div>
            )
        } else {
            return (
                <div>{this.props.transportationProfile.maxPassengers}</div>
            )
        }
    }

    getMaxPassengersCanDriveMarkUp = () => {
        if (parseInt(this.props.transportationProfile.transportationType) === PERSONAL_VEHICLE_TRANSPORTATION_TYPE
            && this.props.transportationProfile.willDriveOthers !== undefined
            && this.props.transportationProfile.willDriveOthers !== null
            && this.props.transportationProfile.willDriveOthers.toString() === 'true') {
            return (
                <div className="section">
                    <div className="row">
                        <div className='col'>
                            <div className="heading">{t('max_passengers_can_drive')}</div>
                            <div>
                                {this.getMaxPassengersCanDriveControlMarkUp()}
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return ('')
        }
    }

    restoreBackup = () => {
        this.saveTransportationProfile(cloneDeep(this.props.backupTransportationProfile))
    }

    createBackup = () => {
        this.props.setBackupTransportationProfile()
    }

    getTransportationPreferencesMarkUp = () => {
        if (this.props.editingTransportationProfile) {
            return (<div>{this.getEditTranportationPreferencesMarkUp()}</div>)
        } else {
            return (<div>{this.getReadOnlyTranportationPreferencesMarkUp()}</div>)
        }
    }

    getTranslatedTransporationType = (transportationTypeId) => {
        for (let i = 0; i < this.props.transportationTypes.length; i++) {
            if (this.props.transportationTypes[i].value === parseInt(transportationTypeId)) {
                return t(this.props.transportationTypes[i].translationTag)
            }
        }

        return ''
    }

    render() {
        const { transportationTypesPending, transportationProfilePending } = this.props

        if (transportationTypesPending || transportationProfilePending) {
            return (
                <div>
                    <h3>{t('travel_preferences')}</h3>
                    <h5>{t('loading')}</h5>
                </div>
            )
        }

        return (
            <div>{this.getTransportationPreferencesMarkUp()}</div>
        )
    }
}

function mapStateToProps(state) {
    return {
        transportationTypesPending: state.transportationProfileReducer.transportationTypesPending,
        transportationTypes: state.transportationProfileReducer.transportationTypes,
        editingTransportationProfile: state.transportationProfileReducer.editingTransportationProfile,
        transportationProfile: state.transportationProfileReducer.transportationProfile,
        transportationProfilePending: state.transportationProfileReducer.transportationProfilePending,
        backupTransportationProfile: state.transportationProfileReducer.backupTransportationProfile,
        personDetails: state.personDetailsReducer.personDetails,
        organizationPeople: state.organizationsReducer.organizationPeople,
        searchRideAlongTransportationProfile: state.transportationProfileReducer.searchRideAlongTransportationProfile,
        rideAlongErrors: state.transportationProfileReducer.rideAlongErrors,
        rideAlongErrorsPending: state.transportationProfileReducer.rideAlongErrorsPending,
        profileImages: state.profileReducer.profileImages,
        profileImagesPending: state.profileReducer.profileImagesPending['transportation'],
        profileImagesError: state.profileReducer.profileImagesError,
        profileLocale: state.loginReducer.profileLocale,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setEditingTransportationProfile: (editing) => setEditingTransportationProfile(editing),
            saveTransportationProfile: (transportationProfile) => saveTransportationProfile(transportationProfile),
            setBackupTransportationProfile: () => setBackupTransportationProfile(),
            patchTransportationProfile: (transportationProfile) => patchTransportationProfile(transportationProfile),
            searchPeopleInOrganization: (organizationId, searchString) => searchPeopleInOrganization(organizationId, searchString),
            setSearchRideAlongTransportationProfile: (searchString) => setSearchRideAlongTransportationProfile(searchString),
            setOrganizationPeople: (organizationPeople) => setOrganizationPeople(organizationPeople),
        },
        dispatch
    )
}

TransportationPreferences.propTypes = {
    transportationTypesPending: PropTypes.bool,
    transportationTypes: PropTypes.array,
    editingTransportationProfile: PropTypes.bool,
    setEditingTransportationProfile: PropTypes.func,
    transportationProfile: PropTypes.object,
    transportationProfilePending: PropTypes.bool,
    saveTransportationProfile: PropTypes.func,
    setBackupTransportationProfile: PropTypes.func,
    backupTransportationProfile: PropTypes.object,
    patchTransportationProfile: PropTypes.func,
    personDetails: PropTypes.object,
    searchPeopleInOrganization: PropTypes.func,
    organizationPeople: PropTypes.array,
    setSearchRideAlongTransportationProfile: PropTypes.func,
    searchRideAlongTransportationProfile: PropTypes.string,
    setOrganizationPeople: PropTypes.func,
    rideAlongErrors: PropTypes.array,
    rideAlongErrorsPending: PropTypes.bool,
    profileImages: PropTypes.array,
    profileImagesPending: PropTypes.bool,
    profileImagesError: PropTypes.object,
    history: PropTypes.object,
    profileLocale: PropTypes.string,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TransportationPreferences)

