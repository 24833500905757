import get from 'lodash/get'
import * as types from './actionTypes'
import services from '../services'

export function getOtherAvailability() {
  return dispatch => {
    dispatch(getOtherAvailabilityPending(true))
    services.otherAvailability
      .getOtherAvailabilty()
      .then(response => {
        dispatch(getOtherAvailabilitySuccess(response))
        dispatch(getOtherAvailabilityPending(false))
      })
      .catch(error => {
        dispatch(getOtherAvailabilityError(error))
        dispatch(getOtherAvailabilityPending(false))
      })
  }
}

export function setCurrentOtherAvailabilityRecord(currentOtherAvailabilityRecord) {
  return dispatch => {
    dispatch(
      setCurrentOtherAvailabilityRecordAction(currentOtherAvailabilityRecord)
    )
  }
}

const setCurrentOtherAvailabilityRecordAction = payload => {
  return {
    type: types.SET_CURRENT_OTHER_AVAIL_RECORD,
    payload: payload,
  }
}

export function setShowOtherAvailabilityListModal(showModal) {
  return dispatch => {
    dispatch(
      setShowOtherAvailabilityListModalAction(showModal)
    )
  }
}

const setShowOtherAvailabilityListModalAction = payload => {
  return {
    type: types.SHOW_OTHER_AVAILABILITY_LIST_MODAL,
    payload: payload,
  }
}

const getOtherAvailabilitySuccess = response => {
  return {
    type: types.GET_OTHER_AVAILABILITY_SUCCESS,
    payload: response.data,
  }
}
const getOtherAvailabilityError = response => {
  return {
    type: types.GET_OTHER_AVAILABILITY_ERROR,
    payload: response,
  }
}
const getOtherAvailabilityPending = response => {
  return {
    type: types.GET_OTHER_AVAILABILITY_PENDING,
    payload: response,
  }
}

export function setEditOtherAvailabilityRecord(editing) {
  return dispatch => {
    dispatch(
      setEditOtherAvailabilityRecordAction(editing)
    )
  }
}

const setEditOtherAvailabilityRecordAction = payload => {
  return {
    type: types.EDIT_OTHER_AVAILABILITY_RECORD,
    payload: payload,
  }
}

export function setOtherAvailability(otherAvailability) {
  return dispatch => {
    dispatch(
      setOtherAvailabilityAction(otherAvailability)
    )
  }
}

const setOtherAvailabilityAction = payload => {
  return {
    type: types.SET_OTHER_AVAILABILITY,
    payload: payload,
  }
}

export function setNewOtherAvailabilityRecord(isNew) {
  return dispatch => {
    dispatch(
      setNewOtherAvailabilityRecordAction(isNew)
    )
  }
}

const setNewOtherAvailabilityRecordAction = payload => {
  return {
    type: types.NEW_OTHER_AVAILABILITY_RECORD,
    payload: payload,
  }
}

export function patchOtherAvailability(otherAvailability) {
  return dispatch => {
    dispatch(patchOtherAvailabilityActionPending(true))
    services.otherAvailability
      .patchOtherAvailability(otherAvailability)
      .then(response => {
        dispatch(patchOtherAvailabilityActionPending(false))
        dispatch(patchOtherAvailabilityActionSuccess(response))
      })
      .catch(error => {
        dispatch(patchOtherAvailabilityActionError(error))
      })
  }
}

const patchOtherAvailabilityActionSuccess = response => {
  return {
    type: types.PATCH_OTHER_AVAILABILITY_SUCCESS,
    payload: response.data,
  }
}
const patchOtherAvailabilityActionError = response => {
  return {
    type: types.PATCH_OTHER_AVAILABILITY_ERROR,
    error: response,
  }
}
const patchOtherAvailabilityActionPending = status => {
  return {
    type: types.PATCH_OTHER_AVAILABILITY_PENDING,
    pending: status,
  }
}

export function deleteOtherAvailability(otherAvailability) {
  return dispatch => {
    dispatch(deleteOtherAvailabilityActionPending(true))
    services.otherAvailability
      .deleteOtherAvailability(otherAvailability)
      .then(response => {
        dispatch(deleteOtherAvailabilityActionPending(false))
        dispatch(deleteOtherAvailabilityActionSuccess(response))
      })
      .catch(error => {
        dispatch(deleteOtherAvailabilityActionError(error))
      })
  }
}

const deleteOtherAvailabilityActionSuccess = response => {
  return {
    type: types.DELETE_OTHER_AVAILABILITY_SUCCESS,
    payload: response.data,
  }
}
const deleteOtherAvailabilityActionError = response => {
  return {
    type: types.DELETE_OTHER_AVAILABILITY_ERROR,
    error: response,
  }
}
const deleteOtherAvailabilityActionPending = status => {
  return {
    type: types.DELETE_OTHER_AVAILABILITY_PENDING,
    pending: status,
  }
}

export function setBackupOtherAvailability() {
  return dispatch => {
    dispatch(
      setBackupOtherAvailabilityAction()
    )
  }
}

const setBackupOtherAvailabilityAction = payload => {
  return {
    type: types.BACKUP_OTHER_AVAILABILITY,
    payload: payload,
  }
}