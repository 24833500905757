import axios from 'axios'
import apiConfig from '../../config/api-config'
// import { toQueryString } from './utils/requestUtil'

// Get info about channel
export function fetchChannel(channelId, lastPostId) {
  const url = apiConfig.CHANNEL.replace('[channelId]', channelId);
  return axios.get(url, {
    params: {
      postCount: 10,
      lastPostId
    }
  });
}

export function fetchChannelAccess(channelId) {
  const url = apiConfig.CHANNEL_ACCESS.replace('[channelId]', channelId);
  return axios.get(url);
}

export function getChannelLastPost(channelId) {
  const url = apiConfig.CHANNEL.replace('[channelId]', channelId);
  return axios.get(url, {
    params: {
      postCount: 1
    }
  });
}
