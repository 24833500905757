import * as types from './actionTypes'
import services from '../services'

export function getTeamDetailsList(scheduledEventId) {
  return dispatch => {
    dispatch(setTeamDetailsPending(true))
    services.schedule
      .fetchScheduleTeamDetailByScheduleEventId({
        scheduledEventId,
      })
      .then(response => {
        dispatch(getTeamDetailsAction(response))
        dispatch(setTeamDetailsPending(false))
      })
      .catch(error => {
        dispatch(setTeamDetailsError(error))
        dispatch(setTeamDetailsPending(false))
      })
  }
}

const setTeamDetailsPending = response => {
  return {
    type: types.GET_TEAM_LIST_DETAILS_PENDING,
    payload: response,
  }
}
const getTeamDetailsAction = response => {
  return {
    type: types.GET_TEAM_LIST_DETAILS,
    payload: response.data,
    status: response.status,
  }
}
const setTeamDetailsError = response => {
  return {
    type: types.GET_TEAM_LIST_DETAILS_ERROR,
    payload: response,
  }
}
