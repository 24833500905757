import * as types from './actionTypes'
import services from '../services'
import moment from 'moment'

export function setComplimentFilter(complimentFilter, currentPage, complimentCount) {
    return dispatch => { 
        dispatch(setComplimentFilterAction({complimentFilter, currentPage, complimentCount}))
    }
}

const setComplimentFilterAction = complimentFilter => {
    return {
        type: types.SET_COMPLIMENT_FILTER,
        payload: complimentFilter
    }
}

export function getCompliment(complimentId) {
    return dispatch => {
        dispatch(getComplimentActionPending(true))
        services.compliments
            .getCompliment(complimentId)
            .then(response => {
                dispatch(getComplimentActionSuccess(response))
                dispatch(getComplimentActionPending(false))
            })
            .catch(error => {
                dispatch(getComplimentActionError(error))
                dispatch(getComplimentActionPending(false))
            })
    }
  }
  
const getComplimentActionSuccess = response => {
    return {
        type: types.GET_COMPLIMENT_BY_ID_SUCCESS,
        payload: response.data,
    }
}

const getComplimentActionError = response => {
    return {
        type: types.GET_COMPLIMENT_BY_ID_ERROR,
        error: response,
    }
}

const getComplimentActionPending = pending => {
    return {
        type: types.GET_COMPLIMENT_BY_ID_PENDING,
        payload: pending,
    }
}
  
export function deleteCompliment(complimentId) {
    return dispatch => {
        dispatch(deleteComplimentActionPending(true))
        services.compliments
            .deleteCompliment(complimentId)
            .then(response => {
                dispatch(deleteComplimentActionSuccess(response, complimentId))
                dispatch(deleteComplimentActionPending(false))
            })
            .catch(error => {
                dispatch(deleteComplimentActionError(error))
                dispatch(deleteComplimentActionPending(false))
            })
    }
}
  
const deleteComplimentActionSuccess = (response, complimentId) => {
    return {
        type: types.DELETE_COMPLIMENT_BY_ID_SUCCESS,
        payload: response.data,
        complimentId,
    }
}

const deleteComplimentActionError = response => {
    return {
        type: types.DELETE_COMPLIMENT_BY_ID_ERROR,
        error: response,
    }
}

const deleteComplimentActionPending = pending => {
    return {
        type: types.DELETE_COMPLIMENT_BY_ID_PENDING,
        payload: pending,
    }
}

export function getReceivedCompliments() {
    return dispatch => {
        dispatch(getReceivedComplimentsActionPending(true))
        services.compliments
            .getReceivedCompliments()
            .then(response => {
                dispatch(getReceivedComplimentsActionSuccess(response))
                dispatch(getReceivedComplimentsActionPending(false))
            })
            .catch(error => {
                dispatch(getReceivedComplimentsActionError(error))
                dispatch(getReceivedComplimentsActionPending(false))
            })
    }
}
  
const getReceivedComplimentsActionSuccess = response => {
    return {
        type: types.GET_RECEIVED_COMPLIMENTS_SUCCESS,
        payload: response.data.length > 0 ? response.data.sort((a, b) => {
            return moment(b.createdDate).isAfter(moment(a.createdDate)) ? 1 : -1
          }) : [],
    }
}

const getReceivedComplimentsActionError = response => {
    return {
        type: types.GET_RECEIVED_COMPLIMENTS_ERROR,
        error: response,
    }
}

const getReceivedComplimentsActionPending = pending => {
    return {
        type: types.GET_RECEIVED_COMPLIMENTS_PENDING,
        payload: pending,
    }
}

export function getSentCompliments() {
    return dispatch => {
        dispatch(getSentComplimentsActionPending(true))
        services.compliments
            .getSentCompliments()
            .then(response => {
                dispatch(getSentComplimentsActionSuccess(response))
                dispatch(getSentComplimentsActionPending(false))
            })
            .catch(error => {
                dispatch(getSentComplimentsActionError(error))
                dispatch(getSentComplimentsActionPending(false))
            })
    }
}
  
const getSentComplimentsActionSuccess = response => {
    return {
        type: types.GET_SENT_COMPLIMENTS_SUCCESS,
        payload: response.data,
    }
}

const getSentComplimentsActionError = response => {
    return {
        type: types.GET_SENT_COMPLIMENTS_ERROR,
        error: response,
    }
}

const getSentComplimentsActionPending = pending => {
    return {
        type: types.GET_SENT_COMPLIMENTS_PENDING,
        payload: pending,
    }
}

export function postCompliment(compliment) {
    return dispatch => {
        dispatch(postComplimentActionPending(true))
        services.compliments
            .postCompliment(compliment)
            .then(response => {
                dispatch(postComplimentActionPending(false))
                dispatch(postComplimentActionSuccess(response))
                
            })
            .catch(error => {
                dispatch(postComplimentActionPending(false))
                dispatch(postComplimentActionError(error))
            })
    }
}
  
const postComplimentActionSuccess = response => {
    return {
        type: types.POST_COMPLIMENT_SUCCESS,
        payload: response.data,
    }
}

const postComplimentActionError = response => {
    return {
        type: types.POST_COMPLIMENT_ERROR,
        error: response,
    }
}

const postComplimentActionPending = pending => {
    return {
        type: types.POST_COMPLIMENT_PENDING,
        payload: pending,
    }
}

export function patchComplimentComment(complimentId, comments) {
    return dispatch => {
        dispatch(patchComplimentCommentActionPending(true))
        services.compliments
            .patchComplimentComment(complimentId, comments)
            .then(response => {
                dispatch(patchComplimentCommentActionSuccess(response))
                dispatch(patchComplimentCommentActionPending(false))
            })
            .catch(error => {
                dispatch(patchComplimentCommentActionError(error))
                dispatch(patchComplimentCommentActionPending(false))
            })
    }
}
  
const patchComplimentCommentActionSuccess = response => {
    return {
        type: types.PATCH_COMPLIMENT_COMMENT_SUCCESS,
        payload: response.data,
    }
}

const patchComplimentCommentActionError = response => {
    return {
        type: types.PATCH_COMPLIMENT_COMMENT_ERROR,
        error: response,
    }
}

const patchComplimentCommentActionPending = pending => {
    return {
        type: types.PATCH_COMPLIMENT_COMMENT_PENDING,
        payload: pending,
    }
}

export function getSentComplimentsPaged(page, pageSize) {
    return dispatch => {
        dispatch(getSentComplimentsPagedActionPending(true))
        services.compliments
            .getSentComplimentsPaged(page,pageSize)
            .then(response => {
                dispatch(getSentComplimentsPagedActionSuccess(response))
                dispatch(getSentComplimentsPagedActionPending(false))
            })
            .catch(error => {
                dispatch(getSentComplimentsPagedActionError(error))
                dispatch(getSentComplimentsPagedActionPending(false))
            })
    }
}

const getSentComplimentsPagedActionSuccess = response => {
    return {
        type: types.GET_SENT_COMPLIMENTS_PAGED_SUCCESS,
        payload: response.data,
    }
}

const getSentComplimentsPagedActionError = response => {
    return {
        type: types.GET_SENT_COMPLIMENTS_PAGED_ERROR,
        error: response,
    }
}

const getSentComplimentsPagedActionPending = pending => {
    return {
        type: types.GET_SENT_COMPLIMENTS_PAGED_PENDING,
        payload: pending,
    }
}