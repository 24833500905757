import axios from 'axios'
import apiConfig from '../../config/api-config'

export function getUserReactions(postIds) {
  const url = apiConfig.USER_REACTIONS;
  let queryParams = '?'
  postIds.map(postId => queryParams += `postIds=${postId}&`)
  queryParams = queryParams.slice(0, -1)
  return axios.get(url + queryParams);
}

export function postReaction(reaction) {
  const url = apiConfig.POST_REACTION;
  return axios.post(url, reaction);
}

export function deleteReaction(reactionId) {
  const url = apiConfig.DELETE_REACTION.replace('[reactionId]', reactionId);
  return axios.delete(url);
}
