import React, { Component } from 'react'
import PropTypes from 'prop-types'
import logo from '../../styles/images/w-connect-logo-2x.png'
import { t } from '../../utils/i18n'

import './Preloader.scss'

export default class Preloader extends Component {
  render() {
    const { insideDiv } = this.props
    return (
      <div className="preloader-component">
        <div className={`preloader show ${insideDiv ? 'inside-div' : ''}`}>
          <div className="loader-logo-wrapper">
            <img src={logo} alt="logo" className="preloader-logo" />
            <div className="spinning-circle"></div>
          </div>
          <p className="preloader-caption">{t('please_wait')}</p>
        </div>
      </div>
    )
  }
}



Preloader.propTypes = {
  insideDiv: PropTypes.bool,
}