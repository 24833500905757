import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import BadgeItem from '../../Badges/BadgeItem'
import PostUserAvatar from '../../PostUserAvatar'
import Preloader from '../../Preloader'

import './PersonDetails.scss'
import { t } from '../../../utils/i18n';
import { getBadges } from '../../../state/actions/badgesActions'
import { getReceivedCompliments } from '../../../state/actions/complimentsActions'
import { getProfileImage } from '../../../state/actions/profileActions'
import { nullSafeCheckIsTrue, isDefined, isDefinedAndHasOwnProperties } from '../../utils/nullSafeCheckUtils'
import { hasComplimentsAccess } from '../../utils/featureAccessUtils'
import { formatNumber } from '../../utils/numberUtils'
import { INTEGRITY_VALUE, PRIDE_VALUE, SERVICE_VALUE, TEAMWORK_VALUE, INNOVATION_VALUE, RESULTS_VALUE } from '../../../utils/constants';

class PersonDetails extends Component {
  constructor(props) {
    super(props)
    this.badgeRowRef = React.createRef()
    this.state = {
      personId: null,
      badgesRowRef: null,
      personDetails: {},
      userAvatarId: '',
      userAvatarUrl: '',
      userAvatarIsFlagged: false,
    }
  }

  componentDidMount() {
    const _state = this.state

    if (this.state.badgesRowRef !== this.badgeRowRef) {
      _state.badgesRowRef = this.badgeRowRef
      this.setState(_state)
    }

    this.props.getProfileImage()
    this.props.getBadges()
    if (hasComplimentsAccess(this.props.featureAccess)) {
      this.props.getReceivedCompliments()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.getProfileImageActionPending === true && this.props.getProfileImageActionPending === false) {
      const profileImage = this.props.profileImage;
      if (profileImage) {
        this.setState({
          userAvatarId: profileImage.id,
          userAvatarUrl: profileImage.url,
          userAvatarIsFlagged: profileImage.isFlagged
        })
      }
    }
  }

  getOrganizationName = () => {
    return this.props.personDetails.organizationName === null || this.props.personDetails.organizationName === undefined ? this.props.personDetails.hrOrganizationName : this.props.personDetails.organizationName
  }

  getEarnedBadgesDictionary = (earnedBadgesUnsorted) => {
    if (earnedBadgesUnsorted === undefined || earnedBadgesUnsorted === null) { return {} }

    let badgesDictionary = {}

    for (let i = 0; i < earnedBadgesUnsorted.length; i++) {
      if (badgesDictionary[earnedBadgesUnsorted[i].badgeGroupTag] === undefined || badgesDictionary[earnedBadgesUnsorted[i].badgeGroupTag] === null) {
        badgesDictionary[earnedBadgesUnsorted[i].badgeGroupTag] = []
      }

      if (badgesDictionary[earnedBadgesUnsorted[i].badgeGroupTag].length === 0) {
        badgesDictionary[earnedBadgesUnsorted[i].badgeGroupTag].push(earnedBadgesUnsorted[i])
        continue
      }
      for (let j = 0; j < badgesDictionary[earnedBadgesUnsorted[i].badgeGroupTag].length; j++) {
        if (badgesDictionary[earnedBadgesUnsorted[i].badgeGroupTag][j].sortOrder > earnedBadgesUnsorted[i].sortOrder) {
          badgesDictionary[earnedBadgesUnsorted[i].badgeGroupTag].splice(j, 0, earnedBadgesUnsorted[i])
          break;
        }
      }
    }

    return badgesDictionary
  }

  reverseRecentlyEarnedBadgeGroupsBySortOrder = (earnedBadgesDictionary) => {
    if (!isDefined(earnedBadgesDictionary)) { return {} }

    for (var badgeGroupTag in earnedBadgesDictionary) {
      if (earnedBadgesDictionary.hasOwnProperty(badgeGroupTag)) {
        if (earnedBadgesDictionary[badgeGroupTag].length === 0) { continue }

        earnedBadgesDictionary[badgeGroupTag] = earnedBadgesDictionary[badgeGroupTag].reverse()
      }
    }

    return earnedBadgesDictionary
  }

  getSortedRecentlyEarnedBadges = (earnedBadgesDictionary) => {
    let sortedBadges = []
    const MONTHS_FOR_BADGES_TO_BE_RECENT = 1
    let recentBadgesDate = moment().subtract(MONTHS_FOR_BADGES_TO_BE_RECENT, 'months')

    for (var badgeGroupTag in earnedBadgesDictionary) {
      if (earnedBadgesDictionary.hasOwnProperty(badgeGroupTag)) {
        if (earnedBadgesDictionary[badgeGroupTag].length === 0) { continue; }

        for (let i = 0; i < earnedBadgesDictionary[badgeGroupTag].length; i++) {
          if (moment(earnedBadgesDictionary[badgeGroupTag][i].earnedUnearnedDateTime).isBefore(recentBadgesDate)) { continue; }

          sortedBadges.push(earnedBadgesDictionary[badgeGroupTag][i])
        }
      }
    }

    sortedBadges.sort(function (a, b) { return moment(b.earnedUnearnedDateTime).isAfter(moment(a.earnedUnearnedDateTime)) ? 1 : -1 })

    return sortedBadges
  }

  getEarnedBadges = (badges) => {
    let earnedBadges = []

    for (let i = 0; i < badges.length; i++) {
      for (let j = 0; j < badges[i].badgeModels.length; j++) {
        if (nullSafeCheckIsTrue(badges[i].badgeModels[j].userEarned)) {
          earnedBadges.push(badges[i].badgeModels[j])
        }
      }
    }

    return earnedBadges
  }

  getBadgesMarkup() {
    const { badgesRowRef } = this.state;

    let sortedBadges = []
    if (!isDefined(this.props.badgesError)) {
      sortedBadges = this.getSortedRecentlyEarnedBadges(
        this.reverseRecentlyEarnedBadgeGroupsBySortOrder(
          this.getEarnedBadgesDictionary(
            this.getEarnedBadges(this.props.badges))))
    }

    let numberOfBadges = 0
    let maxNumberOfBadges = 4
    if (badgesRowRef && badgesRowRef.current) {
      maxNumberOfBadges = parseInt(badgesRowRef.current.offsetWidth / 71.05) - 1
    }

    return (
      <div className="cursor-pointer" onClick={() => this.props.history.push('/badges')}>
        <div className="row">
          <div className="col">
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="heading">{t('recent_badges')}</div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="d-flex flex-wrap align-items-center justify-content-around justify-content-sm-start">
              {
                sortedBadges.length === 0 ?
                  <div className='no-recent-badges-text'>{t('no_recent_badges_message')}</div>
                  :
                  sortedBadges.map((badge) => {
                    if ((badge.visibleStartDatetime === null && badge.visibleEndDatetime === null)
                      || moment(new Date()).isAfter(new Date(badge.visibleStartDatetime))
                      || moment(new Date()).isBefore(new Date(badge.visibleEndDatetime))) {
                      numberOfBadges += 1
                      if (numberOfBadges <= maxNumberOfBadges) {
                        return (<BadgeItem key={badge.id} showDetails={false} badgeModel={badge} />)
                      }
                      return ''
                    }
                    return ''
                  })
              }
              {numberOfBadges > maxNumberOfBadges &&
                <div className="more-badges d-flex align-items-center justify-content-center">
                  <div>{numberOfBadges - maxNumberOfBadges}+</div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  getComplimentsMarkUp = (receivedComplimentsTotals) => {
    if (!hasComplimentsAccess(this.props.featureAccess)) {
      return ''
    } else {
      return (
        <div className="section cursor-pointer" onClick={() => this.props.history.push('/compliments')}>
          <div>
            <div className="row">
              <div className="col">
                <hr />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="heading">{t('received_compliments')}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-2 col-md-1">
                <div
                  className={receivedComplimentsTotals[SERVICE_VALUE] > 0 ? 'core-value1-fill' : 'core-value1'}
                  title={t('service')}
                />
              </div>
              <div className="col-2 col-md-1">
                <div
                  className={receivedComplimentsTotals[PRIDE_VALUE] > 0 ? 'core-value2-fill' : 'core-value2'}
                  title={t('pride')}
                />
              </div>
              <div className="col-2 col-md-1">
                <div
                  className={receivedComplimentsTotals[INTEGRITY_VALUE] > 0 ? 'core-value3-fill' : 'core-value3'}
                  title={t('integrity')}
                />
              </div>
              <div className="col-2 col-md-1">
                <div
                  className={receivedComplimentsTotals[RESULTS_VALUE] > 0 ? 'core-value4-fill' : 'core-value4'}
                  title={t('results')}
                />
              </div>
              <div className="col-2 col-md-1">
                <div
                  className={receivedComplimentsTotals[INNOVATION_VALUE] > 0 ? 'core-value5-fill' : 'core-value5'}
                  title={t('innovation')}
                />
              </div>
              <div className="col-2 col-md-1">
                <div
                  className={receivedComplimentsTotals[TEAMWORK_VALUE] > 0 ? 'core-value6-fill' : 'core-value6'}
                  title={t('teamwork')}
                />
              </div>
              <div className="col-2 col-md-7" />
            </div>
            <div className="row">
              <div className="col-2 col-md-1 text-center">
                <div className='core-value-counts'>{formatNumber(receivedComplimentsTotals[SERVICE_VALUE])}</div>
              </div>
              <div className="col-2 col-md-1 text-center">
                <div className='core-value-counts'>{formatNumber(receivedComplimentsTotals[PRIDE_VALUE])}</div>
              </div>
              <div className="col-2 col-md-1 text-center">
                <div className='core-value-counts'>{formatNumber(receivedComplimentsTotals[INTEGRITY_VALUE])}</div>
              </div>
              <div className="col-2 col-md-1 text-center">
                <div className='core-value-counts'>{formatNumber(receivedComplimentsTotals[RESULTS_VALUE])}</div>
              </div>
              <div className="col-2 col-md-1 text-center">
                <div className='core-value-counts'>{formatNumber(receivedComplimentsTotals[INNOVATION_VALUE])}</div>
              </div>
              <div className="col-2 col-md-1 text-center">
                <div className='core-value-counts'>{formatNumber(receivedComplimentsTotals[TEAMWORK_VALUE])}</div>
              </div>
              <div className="col-2 col-md-7" />
            </div>
          </div>
        </div>
      )
    }
  }

  render() {
    const { personDetails, personDetailsPending, featureAccess,
      badgesPending, receivedComplimentsPending, receivedComplimentsTotals,
      getProfileImageActionPending
    } = this.props
    const { userAvatarId, userAvatarUrl, userAvatarIsFlagged } = this.state
    if (personDetailsPending || badgesPending
      || receivedComplimentsPending || getProfileImageActionPending) {
      return (
        <Preloader />
      )
    }
    return (
      <div className="person-details">
        <div className="section">
          <div className="row personal-info">
            <div className="col col-4 col-md-2 col-xl-1" >
              <PostUserAvatar
                personName={`${personDetails.firstName} ${personDetails.lastName}`}
                userAvatarId={userAvatarId}
                userAvatarUrl={userAvatarUrl}
                userAvatarIsFlagged={userAvatarIsFlagged}
                personId={personDetails.personId}
                redirectToAvatarPage={true}
                history={this.props.history}
              />
            </div>
            <div className="col d-flex flex-column justify-content-center">
              <div className="name">
                {personDetails.firstName} {personDetails.lastName}
              </div>
              <div className="rank">{personDetails.currentJob}</div>
            </div>
          </div>
        </div>

        {this.getComplimentsMarkUp(receivedComplimentsTotals)}

        <div className="section" ref={this.badgeRowRef}>
          {isDefinedAndHasOwnProperties(featureAccess) && nullSafeCheckIsTrue(featureAccess.badges) && this.getBadgesMarkup()}
        </div>

        <div className="section">
          <div className="row">
            <div className="col">
              <div className="heading">{t('badge_number')}</div>
              <div>{personDetails.badgeId}</div>
            </div>
          </div>
        </div>

        <div className="section">
          <div className="row">
            <div className="col">
              <div className="heading">{t('rgis_organization')}</div>
              <div>{this.getOrganizationName()}</div>
            </div>
          </div>
        </div>

        <div className="section">
          <div className="row">
            <div className="col">
              <div className="heading">{t('phone_number')}</div>
              <div className="phone">{personDetails.telephoneNumber1 || personDetails.telephoneNumber2}</div>
            </div>
          </div>
        </div>

        <div className="section">
          <div className="row">
            <div className="col">
              <div className="heading">{t('email_address')}</div>
              <div className="email">{personDetails.emailAddress}</div>
            </div>
          </div>
        </div>

        <div className="section">
          <div className="row">
            <div className="col">
              <div className="heading">{t('home_address')}</div>
              <div>{personDetails.addressLine1}</div>
              <div>{personDetails.addressLine2}</div>
              <div>{personDetails.addressLine3}</div>
              <div>
                {personDetails.townOrCity}, {personDetails.state} {personDetails.postalCode}
              </div>
              <div>
                {personDetails.country}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    personDetails: state.personDetailsReducer.personDetails,
    personDetailsPending: state.personDetailsReducer.personDetailsPending,
    featureAccess: state.featureAccessReducer.featureAccess,
    earnedBadges: state.badgesReducer.earnedBadges,
    badges: state.badgesReducer.badges,
    badgesPending: state.badgesReducer.badgesPending,
    receivedComplimentsPending: state.complimentsReducer.receivedComplimentsPending,
    getReceivedCompliments: state.complimentsReducer.getReceivedCompliments,
    receivedComplimentsTotals: state.complimentsReducer.receivedComplimentsTotals,

    profileImage: state.profileReducer.profileImage,
    getProfileImageActionPending: state.profileReducer.profileImagePending,
    getProfileImageActionError: state.profileReducer.profileImageError,

    profileLocale: state.loginReducer.profileLocale,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getBadges: () => getBadges(),
    getReceivedCompliments: () => getReceivedCompliments(),
    getProfileImage: () => getProfileImage(),
  }, dispatch)
}

PersonDetails.propTypes = {
  personDetails: PropTypes.object,
  personDetailsPending: PropTypes.bool,
  featureAccess: PropTypes.object,
  earnedBadges: PropTypes.array,
  history: PropTypes.object,
  badges: PropTypes.array,
  badgesError: PropTypes.object,
  badgesPending: PropTypes.bool,
  getBadges: PropTypes.func,
  getReceivedCompliments: PropTypes.func,
  receivedComplimentsPending: PropTypes.bool,
  receivedComplimentsTotals: PropTypes.object,

  getProfileImage: PropTypes.func,
  profileImage: PropTypes.object,
  getProfileImageActionPending: PropTypes.bool,
  getProfileImageActionError: PropTypes.object,
  profileLocale: PropTypes.string,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonDetails)
