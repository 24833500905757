/* eslint-disable import/no-named-as-default */
import React from 'react'
import PropTypes from 'prop-types'
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'

import Home from '../Home'
import ScheduleContainer from '../../containers/ScheduleContainer'
import EventDetailsContainer from '../../containers/EventDetailsContainer'
import ProfileContainer from '../../containers/ProfileContainer'
import ChannelContainer from '../../containers/ChannelContainer'
import PostCardContainer from '../../containers/PostCardContainer'
import OidcCallbackContainer from '../../containers/OidcCallbackContainer'
import NewsContainer from '../../containers/NewsContainer'
import NewPostContainer from '../../containers/NewPostContainer'
import SurveysListContainer from '../../containers/SurveysListContainer'
import SurveyFeedbackContainer from '../../containers/SurveyFeedbackContainer'
import OpenShiftsContainer from '../../containers/OpenShiftsContainer'
import BadgesContainer from '../../containers/BadgesContainer'
import NotFoundContainer from '../../containers/NotFoundContainer'
import ComplimentsContainer from '../../containers/ComplimentsContainer'
import SentComplimentsContainer from '../../containers/SentComplimentsContainer'
import SendComplimentsContainer from '../../containers/SendComplimentsContainer'
import NotificationsContainer from '../../containers/NotificationsContainer/NotificationsContainer'
import NotificationDetailsContainer from '../../containers/NotificationDetailsContainer/NotificationDetailsContainer'
import ContactsContainer from '../../containers/ContactsContainer'
import ContactDetailsContainer from '../../containers/ContactDetailsContainer'
import TimePunchesContainer from '../../containers/TimePunchesContainer'
import TimePunchDetailsContainer from '../../containers/TimePunchDetailsContainer'
import MyTeamContainer from '../../containers/MyTeamContainer'
import PublicProfileContainer from '../../containers/PublicProfileContainer'
import UserAvatarContainer from '../../containers/UserAvatarContainer'
import InitializerContainer from '../../containers/InitializerContainer'

import './App.scss'

// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.

class App extends React.Component {
  
  render() {
    return (
      <HashRouter>
        <div>
          <Route component={Home} />
          <Switch>
            <Route exact path="/schedule" component={ScheduleContainer} />
            <Route exact path="/schedule/:id" component={EventDetailsContainer} />
            <Route exact path="/profile" component={ProfileContainer} />
            <Route exact path="/public-profile/:person_id" component={PublicProfileContainer} />
            <Route exact path="/user-avatar/:person_id" component={UserAvatarContainer} />
            <Route exact path="/notifications" component={NotificationsContainer} />
            <Route exact path="/notifications/:notification_id" component={NotificationDetailsContainer} />
            <Route exact path="/news" component={NewsContainer} />
            <Route exact path="/news/channel/:channel_id" component={ChannelContainer} />
            <Route exact path="/news/channel/:channel_id/new_post" component={NewPostContainer} />
            <Route exact path="/news/channel/:channel_id/post/:post_id" component={PostCardContainer} />
            <Route exact path="/surveys/:event_id" component={SurveysListContainer} />
            <Route exact path="/survey-feedback/:event_id/:template_name" component={SurveyFeedbackContainer} />
            <Route exact path="/open-shifts" component={OpenShiftsContainer} />
            <Route exact path="/badges" component={BadgesContainer} />
            <Route exact path="/badges/:person_id" component={BadgesContainer} />
            <Route exact path="/compliments" component={ComplimentsContainer} />
            <Route exact path="/sent-compliments" component={SentComplimentsContainer} />
            <Route exact path="/send-compliments" component={SendComplimentsContainer} />
            <Route exact path="/contacts" component={ContactsContainer} />
            <Route exact path="/contacts/contact-details" component={ContactDetailsContainer} />
            <Route exact path="/time-punches" component={TimePunchesContainer} />
            <Route exact path="/time-punches/:time_punch_id" component={TimePunchDetailsContainer} />
            <Route exact path="/my-team" component={MyTeamContainer} />
            <Route exact path="/not-found" component={NotFoundContainer} />
            <Route exact path="/callback" component={OidcCallbackContainer} />
            <Route exact path="/my-avatar" component={UserAvatarContainer} />
            <Route exact path="/initializer" component={InitializerContainer} />
            <Redirect from="*" to="/initializer" />  
          </Switch>
        </div>
      </HashRouter>
    )
  }
}

App.propTypes = {
  children: PropTypes.element,
}

export default App
