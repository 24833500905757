import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import { loggedInUserIsManager } from '../utils/localStorageUtils'
import { formatTime } from '../utils/datetimeUtils'

import TeamMember from '../TeamMember'

import './TeamDetails.scss'

class TeamDetails extends Component {
  constructor(props) {
    super(props)

    this.renderEmployee = this.renderEmployee.bind(this)
  }

  renderEmployee(item, index) {
    const userAvatar = this.props.profileImages.find(obj => obj.personId === item.personId)
    const status = item.changeRequestStatus === 'PENDING_REVIEW' ? 'pending' : item.confirmStatus.toLowerCase()
    const isSupervisor = this.props.isSupervisor || loggedInUserIsManager()
    return (
      <TeamMember
        item={item}
        userAvatar={userAvatar}
        isSupervisor={isSupervisor}
        status={status}
        index={index}
        key={index}
        history={this.props.history}
      />
    )
  }

  getSortedTeamMembers(unsortedTeamMembers, driversOnly) {
    const filteredList = []
    unsortedTeamMembers.forEach(teamMember => {
      if (driversOnly === teamMember.driver) {
        filteredList.push({...teamMember})
      } 
    })
    
    const filteredSortedList = filteredList.sort((a, b) => {
      if (a.employeeName > b.employeeName) {
        return 1
      } else {
        return -1
      }
    })

    return filteredSortedList;
  }

  getSortedDistricts(districtsList) {
    const sortableDistricts = []
    districtsList.forEach(district => {
      const splitMeetTime = district.meetTime.trim().split(" ")
      let sortableTime = district.meetTime
      if (splitMeetTime.length > 1) {
        const amPm = splitMeetTime[1]
        let splitHoursMinutes = splitMeetTime[0].split(":")
        if (amPm && amPm.toUpperCase() === "PM") {
          splitHoursMinutes[0] = String(parseInt(splitHoursMinutes[0]) + 12)
        }
        sortableTime = splitHoursMinutes[0] + splitHoursMinutes[1]
      }
      sortableDistricts.push({...district, "sortableTime": sortableTime})
    })

    const sortedDistricts = sortableDistricts.sort((a, b) => {
      if (a.sortableTime > b.sortableTime) {
        return 1
      } else {
        return -1
      }
    })

    return sortedDistricts;
  }

  render() {
    let { teamDetailsList, teamDetailsListDistricts, eventDate} = this.props

    const sortedTeamMembers = this.getSortedTeamMembers(teamDetailsList, false)
    const sortedDrivers = this.getSortedTeamMembers(teamDetailsList, true)
    const sortedDistricts = this.getSortedDistricts(teamDetailsListDistricts)

    return (
      <>
        {sortedDistricts.map((district, index) => {
          const districtNumber = district.organizationNumber.trim().replace(/\D+/g, '')
          const driverTeamDetailsListForDistrict = sortedDrivers.filter(a => {
            return (
              a.meetSite === district.meetSite &&
              a.meetTime === district.meetTime &&
              a.organizationNumber.trim().replace(/\D+/g, '') === districtNumber
            )
          })      
          const teamDetailsListForDistrict = sortedTeamMembers.filter(a => {
            return (
              a.meetSite === district.meetSite &&
              a.meetTime === district.meetTime &&
              a.organizationNumber.trim().replace(/\D+/g, '') === districtNumber
            )
          })     
          return (
            <div key={index} className="card team-details">
              <div className="card-body">
                <div className="row title">
                  <div className="col meet-time">{formatTime(district.meetTime)}</div>
                  <div className="col backroom-indicator text-right">
                    {district.meetSite} {district.backRoomInd === 'N' ? '' : 'Backroom'}
                  </div>
                </div>
                {driverTeamDetailsListForDistrict.map(this.renderEmployee)}
                {teamDetailsListForDistrict.map(this.renderEmployee)}
              </div>
            </div>
          )
        })}
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    requestDetailsListCount: state.requestDetailsListReducer.requestDetailsListCount,
    storeData: state.eventDetailsReducer.eventDetails,
    teamDetailsList: state.teamDetailsListReducer.teamDetailsList,
    teamDetailsListDistricts: state.teamDetailsListReducer.teamDetailsListDistricts,
    teamDetailsListNumberScheduled: state.teamDetailsListReducer.teamDetailsListNumberScheduled,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch)
}

TeamDetails.propTypes = {
  isSupervisor: PropTypes.bool,
  requestDetailsListCount: PropTypes.number,
  showTeamDetailsList: PropTypes.bool,
  teamDetailsList: PropTypes.array,
  teamDetailsListDistricts: PropTypes.array,
  teamDetailsListNumberScheduled: PropTypes.number,
  profileImages: PropTypes.array,
  history: PropTypes.object,
  eventDate: PropTypes.string,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamDetails)
