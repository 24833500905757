// AUTHENTICATION
export const CHECK_AUTHENTICATION = 'CHECK_AUTHENTICATION'
export const SESSION_STATUS = 'SESSION_STATUS'
export const LOGOUT = 'LOGOUT'
export const RESET_STATE = 'RESET_STATE'

// PROFILE
export const GET_USER_PROFILE = 'GET_USER_PROFILE'
export const GET_USER_PROFILE_PENDING = 'GET_USER_PROFILE_PENDING'
export const GET_USER_PROFILE_ERROR = 'GET_USER_PROFILE_ERROR'

// SCHEDULE
export const GET_SCHEDULE_CHANGE_TYPES_SUCCESS = 'GET_SCHEDULE_CHANGE_TYPES_SUCCESS'
export const GET_SCHEDULE_CHANGE_TYPES_PENDING = 'GET_SCHEDULE_CHANGE_TYPES_PENDING'
export const GET_SCHEDULE_CHANGE_TYPES_ERROR = 'GET_SCHEDULE_CHANGE_TYPES_ERROR'
export const GET_SCHEDULE_CHANGE_REASONS_SUCCESS = 'GET_SCHEDULE_CHANGE_REASONS_SUCCESS'
export const GET_SCHEDULE_CHANGE_REASONS_PENDING = 'GET_SCHEDULE_CHANGE_REASONS_PENDING'
export const GET_SCHEDULE_CHANGE_REASONS_ERROR = 'GET_SCHEDULE_CHANGE_REASONS_ERROR'

export const SET_SCHEDULE_DATES = 'SET_SCHEDULE_DATES'
export const GET_SCHEDULE = 'GET_SCHEDULE'
export const GET_SCHEDULE_ERROR = 'GET_SCHEDULE_ERROR'
export const GET_SCHEDULE_PENDING = 'GET_SCHEDULE_PENDING'

export const GET_MONTH_SCHEDULE_SUCCESS = 'GET_MONTH_SCHEDULE_SUCCESS'
export const GET_MONTH_SCHEDULE_ERROR = 'GET_MONTH_SCHEDULE_ERROR'
export const GET_MONTH_SCHEDULE_PENDING = 'GET_MONTH_SCHEDULE_PENDING'

export const GET_DISTRICT_EVENT_DETAILS_SUCCESS = 'GET_DISTRICT_EVENT_DETAILS_SUCCESS'
export const GET_DISTRICT_EVENT_DETAILS_PENDING = 'GET_DISTRICT_EVENT_DETAILS_PENDING'
export const GET_DISTRICT_EVENT_DETAILS_ERROR = 'GET_DISTRICT_EVENT_DETAILS_ERROR'
export const RESET_DISTRICT_EVENT_DETAILS = 'RESET_DISTRICT_EVENT_DETAILS'

// REQUEST CHANGE
export const GET_REQUEST_CHANGE_PENDING = 'GET_REQUEST_CHANGE_PENDING'
export const GET_REQUEST_CHANGE_SUCCESS = 'GET_REQUEST_CHANGE_SUCCESS'
export const GET_REQUEST_CHANGE_ERROR = 'GET_REQUEST_CHANGE_ERROR'

// CONFIRM WEEK
export const CONFIRM_EVENT = 'CONFIRM_EVENT'
export const CONFIRM_EVENT_PENDING = 'CONFIRM_EVENT_PENDING'
export const CONFIRM_EVENT_ERROR = 'CONFIRM_EVENT_PENDING_ERROR '

// EVENT DETAILS
export const GET_EVENT_DETAILS_SUCCESS = 'GET_EVENT_DETAILS_SUCCESS'
export const GET_EVENT_DETAILS_ERROR = 'GET_EVENT_DETAILS_ERROR'
export const GET_EVENT_DETAILS_PENDING = 'GET_EVENT_DETAILS_PENDING'
export const GET_EVENT_APH_SUCCESS = 'GET_EVENT_APH_SUCCESS'
export const GET_EVENT_APH_ERROR = 'GET_EVENT_APH_ERROR'
export const GET_EVENT_APH_PENDING = 'GET_EVENT_APH_PENDING'
export const GET_EVENT_DETAILS_LIST_SUCCESS = 'GET_EVENT_DETAILS_LIST_SUCCESS'
export const GET_EVENT_DETAILS_LIST_ERROR = 'GET_EVENT_DETAILS_LIST_ERROR'
export const GET_EVENT_DETAILS_LIST_PENDING = 'GET_EVENT_DETAILS_LIST_PENDING'
export const GET_EVENT_MEET_SITE_SUCCESS = 'GET_EVENT_MEET_SITE_SUCCESS'
export const GET_EVENT_MEET_SITE_ERROR = 'GET_EVENT_MEET_SITE_ERROR'
export const GET_EVENT_MEET_SITE_PENDING = 'GET_EVENT_MEET_SITE_PENDING'

// PROFILE PREF
export const GET_PROFILE_PREF_SUCCESS = 'GET_PROFILE_PREF_SUCCESS'
export const GET_PROFILE_PREF_ERROR = 'GET_PROFILE_PREF_ERROR'
export const GET_PROFILE_PREF_PENDING = 'GET_PROFILE_PREF_PENDING'

// LOCALE
export const SET_PROFILE_LOCALE = 'SET_PROFILE_LOCALE'

// NOTIFICATIONS
export const PATCH_NOTIFICATIONS_HISTORY_STATUS_AS_READ_SUCCESS = 'PATCH_NOTIFICATIONS_HISTORY_STATUS_AS_READ_SUCCESS'
export const PATCH_NOTIFICATIONS_HISTORY_STATUS_AS_READ_ERROR = 'PATCH_NOTIFICATIONS_HISTORY_STATUS_AS_READ_ERROR'
export const PATCH_NOTIFICATIONS_HISTORY_STATUS_AS_READ_PENDING = 'PATCH_NOTIFICATIONS_HISTORY_STATUS_AS_READ_PENDING'
export const GET_UNREAD_NOTIFICATIONS_HISTORY_SUCCESS = 'GET_UNREAD_NOTIFICATIONS_HISTORY_SUCCESS'
export const GET_UNREAD_NOTIFICATIONS_HISTORY_ERROR = 'GET_UNREAD_NOTIFICATIONS_HISTORY_ERROR'
export const GET_UNREAD_NOTIFICATIONS_HISTORY_PENDING = 'GET_UNREAD_NOTIFICATIONS_HISTORY_PENDING'
export const GET_NOTIFICATIONS_HISTORY_SUCCESS = 'GET_NOTIFICATIONS_HISTORY_SUCCESS'
export const GET_NOTIFICATIONS_HISTORY_ERROR = 'GET_NOTIFICATIONS_HISTORY_ERROR'
export const GET_NOTIFICATIONS_HISTORY_PENDING = 'GET_NOTIFICATIONS_HISTORY_PENDING'
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS'
export const GET_NOTIFICATIONS_ERROR = 'GET_NOTIFICATIONS_ERROR'
export const GET_NOTIFICATIONS_PENDING = 'GET_NOTIFICATIONS_PENDING'
export const GET_NOTIFICATIONS_COUNT_SUCCESS = 'GET_NOTIFICATIONS_COUNT_SUCCESS'
export const GET_NOTIFICATIONS_COUNT_ERROR = 'GET_NOTIFICATIONS_COUNT_ERROR'
export const GET_NOTIFICATIONS_COUNT_PENDING = 'GET_NOTIFICATIONS_COUNT_PENDING'
export const PATCH_MARK_NOTIFICATIONS_AS_READ_SUCCESS = 'PATCH_MARK_NOTIFICATIONS_AS_READ_SUCCESS'
export const PATCH_MARK_NOTIFICATIONS_AS_READ_ERROR = 'PATCH_MARK_NOTIFICATIONS_AS_READ_ERROR'
export const PATCH_MARK_NOTIFICATIONS_AS_READ_PENDING = 'PATCH_MARK_NOTIFICATIONS_AS_READ_PENDING'
export const GET_NOTIFICATION_HISTORY_TYPES_SUCCESS = 'GET_NOTIFICATION_HISTORY_TYPES_SUCCESS'
export const GET_NOTIFICATION_HISTORY_TYPES_PENDING = 'GET_NOTIFICATION_HISTORY_TYPES_PENDING'
export const GET_NOTIFICATION_HISTORY_TYPES_ERROR = 'GET_NOTIFICATION_HISTORY_TYPES_ERROR'
export const GET_NOTIFICATION_PREFERENCES_SUCCESS = 'GET_NOTIFICATION_PREFERENCES_SUCCESS'
export const GET_NOTIFICATION_PREFERENCES_PENDING = 'GET_NOTIFICATION_PREFERENCES_PENDING'
export const GET_NOTIFICATION_PREFERENCES_ERROR = 'GET_NOTIFICATION_PREFERENCES_ERROR'
export const POST_NOTIFICATION_PREFERENCES_SUCCESS = 'POST_NOTIFICATION_PREFERENCES_SUCCESS'
export const POST_NOTIFICATION_PREFERENCES_PENDING = 'POST_NOTIFICATION_PREFERENCES_PENDING'
export const POST_NOTIFICATION_PREFERENCES_ERROR = 'POST_NOTIFICATION_PREFERENCES_ERROR'

export const GET_CHANNEL_ID_BY_POST_ID_SUCCESS = 'GET_CHANNEL_ID_BY_POST_ID_SUCCESS'
export const GET_CHANNEL_ID_BY_POST_ID_ERROR = 'GET_CHANNEL_ID_BY_POST_ID_ERROR'
export const GET_CHANNEL_ID_BY_POST_ID_PENDING = 'GET_CHANNEL_ID_BY_POST_ID_PENDING'
export const GET_ORG_NAME_BY_PERSON_ID_SUCCESS = 'GET_ORG_NAME_BY_PERSON_ID_SUCCESS'
export const GET_ORG_NAME_BY_PERSON_ID_ERROR = 'GET_ORG_NAME_BY_PERSON_ID_ERROR'
export const GET_ORG_NAME_BY_PERSON_ID_PENDING = 'GET_ORG_NAME_BY_PERSON_ID_PENDING'

// PUBLISHED WEEK DETAILS
export const GET_PUBLISHED_WEEK_SUCCESS = 'GET_PUBLISHED_WEEK_SUCCESS'
export const GET_PUBLISHED_WEEK_ERROR = 'GET_PUBLISHED_WEEK_ERROR'
export const GET_PUBLISHED_WEEK_PENDING = 'GET_PUBLISHED_WEEK_PENDING'

// SCHEDULING PREFERENCES
export const GET_SCHEDULING_PREFERENCES_SUCCESS = 'GET_SCHEDULING_PREFERENCES_SUCCESS'
export const GET_SCHEDULING_PREFERENCES_ERROR = 'GET_SCHEDULING_PREFERENCES_ERROR'
export const GET_SCHEDULING_PREFERENCES_PENDING = 'GET_SCHEDULING_PREFERENCES_PENDING'

// EDITING SCHEDULING PREFERENCES
export const EDITING_SCHEDULING_PREFERENCES = 'EDITING_SCHEDULING_PREFERENCES'
export const NOT_EDITING_SCHEDULING_PREFERENCES = 'NOT_EDITING_SCHEDULING_PREFERENCES'
export const SAVE_SCHEDULING_PREFERENCES = 'SAVE_SCHEDULING_PREFERENCES'
export const PATCH_SCHEDULING_PREFERENCES_SUCCESS = 'PATCH_SCHEDULING_PREFERENCES_SUCCESS'
export const PATCH_SCHEDULING_PREFERENCES_ERROR = 'PATCH_SCHEDULING_PREFERENCES_ERROR'
export const PATCH_SCHEDULING_PREFERENCES_PENDING = 'PATCH_SCHEDULING_PREFERENCES_PENDING'

// PERSON DETAILS
export const GET_PERSON_DETAILS_SUCCESS = 'GET_PERSON_DETAILS_SUCCESS'
export const GET_PERSON_DETAILS_ERROR = 'GET_PERSON_DETAILS_ERROR'
export const GET_PERSON_DETAILS_PENDING = 'GET_PERSON_DETAILS_PENDING'
export const GET_PERSON_DETAILS_LIST_SUCCESS = 'GET_PERSON_DETAILS_LIST_SUCCESS'
export const GET_PERSON_DETAILS_LIST_ERROR = 'GET_PERSON_DETAILS_LIST_ERROR'
export const GET_PERSON_DETAILS_LIST_PENDING = 'GET_PERSON_DETAILS_LIST_PENDING'
export const GET_SEARCH_ALL_PEOPLE_SUCCESS = 'GET_SEARCH_ALL_PEOPLE_SUCCESS'
export const GET_SEARCH_ALL_PEOPLE_ERROR = 'GET_SEARCH_ALL_PEOPLE_ERROR'
export const GET_SEARCH_ALL_PEOPLE_PENDING = 'GET_SEARCH_ALL_PEOPLE_PENDING'

// GENERAL AVAILABILITY
export const GET_GENERAL_AVAILABILITY_SUCCESS = 'GET_GENERAL_AVAILABILITY_SUCCESS'
export const GET_GENERAL_AVAILABILITY_ERROR = 'GET_GENERAL_AVAILABILITY_ERROR'
export const GET_GENERAL_AVAILABILITY_PENDING = 'GET_GENERAL_AVAILABILITY_PENDING'
export const EDITING_GENERAL_AVAILABILITY = 'EDITING_GENERAL_AVAILABILITY'
export const NOT_EDITING_GENERAL_AVAILABILITY = 'NOT_EDITING_GENERAL_AVAILABILITY'
export const SAVE_GENERAL_AVAILABILITY = 'SAVE_GENERAL_AVAILABILITY'
export const PATCH_GENERAL_AVAILABILITY_ERROR = 'PATCH_GENERAL_AVAILABILITY_ERROR'
export const PATCH_GENERAL_AVAILABILITY_PENDING = 'PATCH_GENERAL_AVAILABILITY_PENDING'
export const PATCH_GENERAL_AVAILABILITY_SUCCESS = 'PATCH_GENERAL_AVAILABILITY_SUCCESS'

// OTHER AVAILABILITY
export const GET_OTHER_AVAILABILITY_SUCCESS = 'GET_OTHER_AVAILABILITY_SUCCESS'
export const GET_OTHER_AVAILABILITY_ERROR = 'GET_OTHER_AVAILABILITY_ERROR'
export const GET_OTHER_AVAILABILITY_PENDING = 'GET_OTHER_AVAILABILITY_PENDING'
export const SHOW_OTHER_AVAILABILITY_LIST_MODAL = 'SHOW_OTHER_AVAILABILITY_LIST_MODAL'
export const SET_CURRENT_OTHER_AVAIL_RECORD = 'SET_CURRENT_OTHER_AVAIL_RECORD'
export const EDIT_OTHER_AVAILABILITY_RECORD = 'EDIT_OTHER_AVAILABILITY_RECORD'
export const NEW_OTHER_AVAILABILITY_RECORD = 'NEW_OTHER_AVAILABILITY_RECORD'
export const SET_OTHER_AVAILABILITY = 'SET_OTHER_AVAILABILITY'
export const PATCH_OTHER_AVAILABILITY_PENDING = 'PATCH_OTHER_AVAILABILITY_PENDING'
export const PATCH_OTHER_AVAILABILITY_SUCCESS = 'PATCH_OTHER_AVAILABILITY_SUCCESS'
export const PATCH_OTHER_AVAILABILITY_ERROR = 'PATCH_OTHER_AVAILABILITY_ERROR'
export const DELETE_OTHER_AVAILABILITY_PENDING = 'DELETE_OTHER_AVAILABILITY_PENDING'
export const DELETE_OTHER_AVAILABILITY_SUCCESS = 'DELETE_OTHER_AVAILABILITY_SUCCESS'
export const DELETE_OTHER_AVAILABILITY_ERROR = 'DELETE_OTHER_AVAILABILITY_ERROR'
export const BACKUP_OTHER_AVAILABILITY = 'BACKUP_OTHER_AVAILABILITY'

// DISTRICT TEAM
export const GET_DISTRICT_TEAM_REQUEST = 'GET_DISTRICT_TEAM_REQUEST'
export const GET_DISTRICT_TEAM_REQUEST_ERROR = 'GET_DISTRICT_TEAM_REQUEST_ERROR'
export const GET_DISTRICT_TEAM_REQUEST_PENDING = 'GET_DISTRICT_TEAM_REQUEST_PENDING'
export const GET_DISTRICT_TEAM_REQUEST_SUCCESS = 'GET_DISTRICT_TEAM_REQUEST_SUCCESS'

export const GET_DISTRICT_REQUEST_CHANGE_PENDING = 'GET_DISTRICT_REQUEST_CHANGE_PENDING'

// ORGANIZATIONS
export const GET_ORGANIZATION_SUCCESS = 'GET_ORGANIZATION_SUCCESS'
export const GET_ORGANIZATION_ERROR = 'GET_ORGANIZATION_ERROR'
export const GET_ORGANIZATION_PENDING = 'GET_ORGANIZATION_PENDING'
export const SEARCH_PEOPLE_IN_ORGANIZATION_SUCCESS = 'SEARCH_PEOPLE_IN_ORGANIZATION_SUCCESS'
export const SEARCH_PEOPLE_IN_ORGANIZATION_ERROR = 'SEARCH_PEOPLE_IN_ORGANIZATION_ERROR'
export const SEARCH_PEOPLE_IN_ORGANIZATION_PENDING = 'SEARCH_PEOPLE_IN_ORGANIZATION_PENDING'
export const SET_ORGANIZATION_PEOPLE = 'SET_ORGANIZATION_PEOPLE'
export const GET_ALL_PEOPLE_IN_ORGANIZATION_SUCCESS = 'GET_ALL_PEOPLE_IN_ORGANIZATION_SUCCESS'
export const GET_ALL_PEOPLE_IN_ORGANIZATION_ERROR = 'GET_ALL_PEOPLE_IN_ORGANIZATION_ERROR'
export const GET_ALL_PEOPLE_IN_ORGANIZATION_PENDING = 'GET_ALL_PEOPLE_IN_ORGANIZATION_PENDING'

// EVENT TYPES
export const GET_EVENT_TYPES = 'GET_EVENT_TYPES'
export const GET_EVENT_REASONS = 'GET_EVENT_REASONS'

export const REQUEST_EVENT_CHANGE_SUCCESS = 'REQUEST_EVENT_CHANGE_SUCCESS'
export const REQUEST_EVENT_CHANGE_PENDING = 'REQUEST_EVENT_CHANGE_PENDING'
export const REQUEST_EVENT_CHANGE_ERROR = 'REQUEST_EVENT_CHANGE_ERROR'

//TEAM MEMBER INFO
export const GET_TEAM_MEMBER_INFO_SUCCESS = 'GET_TEAM_MEMBER_INFO_SUCCESS'
export const GET_TEAM_MEMBER_INFO_ERROR = 'GET_TEAM_MEMBER_INFO_ERROR'
export const GET_TEAM_MEMBER_INFO_PENDING = 'GET_TEAM_MEMBER_INFO_PENDING'

// REQUESTS HISTORY TODO: rename to remove district
export const GET_DISTRICT_REQUESTS_HISTORY_SUCCESS = 'GET_DISTRICT_REQUESTS_HISTORY_SUCCESS'
export const GET_DISTRICT_REQUESTS_HISTORY_ERROR = 'GET_DISTRICT_REQUESTS_HISTORY_ERROR'
export const GET_DISTRICT_REQUESTS_HISTORY_PENDING = 'GET_DISTRICT_REQUESTS_HISTORY_PENDING'

// REQUEST HISTORY LIST
export const GET_REQUEST_HISTORY = 'GET_REQUEST_HISTORY'
export const GET_REQUEST_HISTORY_ERROR = 'GET_REQUEST_HISTORY_ERROR'
export const GET_REQUEST_HISTORY_PENDING = 'GET_REQUEST_HISTORY_PENDING'

// REQUEST DETAILS
export const GET_REQUEST_DETAILS = 'GET_REQUEST_DETAILS'
export const GET_REQUEST_DETAILS_ERROR = 'GET_REQUEST_DETAILS_ERROR'
export const GET_REQUEST_DETAILS_PENDING = 'GET_REQUEST_DETAILS_PENDING'

// TEAM DETAILS LIST
export const GET_TEAM_LIST_DETAILS = 'GET_TEAM_LIST_DETAILS'
export const GET_TEAM_LIST_DETAILS_ERROR = 'GET_TEAM_LIST_DETAILS_ERROR'
export const GET_TEAM_LIST_DETAILS_PENDING = 'GET_TEAM_LIST_DETAILS_PENDING'

// TOASTS
export const ADD_TOAST = 'ADD_TOAST'
export const REMOVE_TOAST = 'REMOVE_TOAST'

// TRANSPORTATION PROFILES
export const GET_TRANSPORTATION_TYPES_SUCCESS = 'GET_TRANSPORTATION_TYPES_SUCCESS'
export const GET_TRANSPORTATION_TYPES_ERROR = 'GET_TRANSPORTATION_TYPES_ERROR'
export const GET_TRANSPORTATION_TYPES_PENDING = 'GET_TRANSPORTATION_TYPES_PENDING'
export const EDITING_TRANSPORTATION_PROFILE = 'EDITING_TRANSPORTATION_PROFILE'
export const GET_TRANSPORTATION_PROFILE_SUCCESS = 'GET_TRANSPORTATION_PROFILE_SUCCESS'
export const GET_TRANSPORTATION_PROFILE_ERROR = 'GET_TRANSPORTATION_PROFILE_ERROR'
export const GET_TRANSPORTATION_PROFILE_PENDING = 'GET_TRANSPORTATION_PROFILE_PENDING'
export const SAVE_TRANSPORTATION_PROFILE = 'SAVE_TRANSPORTATION_PROFILE'
export const BACKUP_TRANSPORTATION_PROFILE = 'BACKUP_TRANSPORTATION_PROFILE'
export const PATCH_TRANSPORTATION_PROFILE_SUCCESS = 'PATCH_TRANSPORTATION_PROFILE_SUCCESS'
export const PATCH_TRANSPORTATION_PROFILE_ERROR = 'PATCH_TRANSPORTATION_PROFILE_ERROR'
export const PATCH_TRANSPORTATION_PROFILE_PENDING = 'PATCH_TRANSPORTATION_PROFILE_PENDING'
export const SEARCH_RIDE_ALONG_TRANSPORTATION_PROFILE = 'SEARCH_RIDE_ALONG_TRANSPORTATION_PROFILE'
export const GET_RIDE_ALONG_ERRORS_SUCCESS = 'GET_RIDE_ALONG_ERRORS_SUCCESS'
export const GET_RIDE_ALONG_ERRORS_ERROR = 'GET_RIDE_ALONG_ERRORS_ERROR'
export const GET_RIDE_ALONG_ERRORS_PENDING = 'GET_RIDE_ALONG_ERRORS_PENDING'

// MOBILE DEVICE
export const SET_PROMPTED_FOR_APP_DOWNLOAD = 'SET_PROMPTED_FOR_APP_DOWNLOAD'
export const SET_SHOW_APP_DOWNLOAD_MODAL = 'SET_SHOW_APP_DOWNLOAD_MODAL'

// CHANNELS
export const GET_CHANNELS_SUCCESS = 'GET_CHANNELS_SUCCESS'
export const GET_CHANNELS_ERROR = 'GET_CHANNELS_ERROR'
export const GET_CHANNELS_PENDING = 'GET_CHANNELS_PENDING'

// WATCH A CHANNEL
export const WATCH_CHANNEL_SUCCESS = 'WATCH_CHANNEL_SUCCESS'
export const WATCH_CHANNEL_ERROR = 'WATCH_CHANNEL_ERROR'
export const WATCH_CHANNEL_PENDING = 'WATCH_CHANNEL_PENDING'

// FEATURE ACCESS
export const GET_FEATURE_ACCESS_LIST_PENDING = 'GET_FEATURE_ACCESS_LIST_PENDING'
export const GET_FEATURE_ACCESS_LIST_SUCCESS = 'GET_FEATURE_ACCESS_LIST_SUCCESS'
export const GET_FEATURE_ACCESS_LIST_ERROR = 'GET_FEATURE_ACCESS_LIST_ERROR'
export const GET_PERSON_FEATURE_ACCESS_SUCCESS = 'GET_PERSON_FEATURE_ACCESS_SUCCESS'
export const GET_PERSON_FEATURE_ACCESS_PENDING = 'GET_PERSON_FEATURE_ACCESS_PENDING'
export const GET_PERSON_FEATURE_ACCESS_ERROR = 'GET_PERSON_FEATURE_ACCESS_ERROR'

// CHANNEL
export const GET_POSTS_SUCCESS = 'GET_POSTS_SUCCESS'
export const GET_POSTS_ERROR = 'GET_POSTS_ERROR'
export const GET_POSTS_PENDING = 'GET_POSTS_PENDING'
export const RESET_POSTS_STATE = 'RESET_POSTS_STATE'
export const GET_CHANNEL_LAST_POST_SUCCESS = 'GET_CHANNEL_LAST_POST_SUCCESS'
export const GET_CHANNEL_LAST_POST_ERROR = 'GET_CHANNEL_LAST_POST_ERROR'
export const GET_CHANNEL_LAST_POST_PENDING = 'GET_CHANNEL_LAST_POST_PENDING'
export const UPDATE_POST_REACTION = 'UPDATE_POST_REACTION'

//CHANNEL ACCESS
export const GET_CHANNEL_ACCESS_SUCCESS = 'GET_CHANNEL_ACCESS_SUCCESS'
export const GET_CHANNEL_ACCESS_ERROR = 'GET_CHANNEL_ACCESS_ERROR'
export const GET_CHANNEL_ACCESS_PENDING = 'GET_CHANNEL_ACCESS_PENDING'


// POST CARD 
export const GET_POST_CARD_SUCCESS = 'GET_POST_CARD_SUCCESS'
export const GET_POST_CARD_ERROR = 'GET_POST_CARD_ERROR'
export const GET_POST_CARD_PENDING = 'GET_POST_CARD_PENDING'

// POST CARD COMMENTS
export const GET_NEW_POST_COUNT_SUCCESS = 'GET_NEW_POST_COUNT_SUCCESS'
export const GET_NEW_POST_COUNT_ERROR = 'GET_NEW_POST_COUNT_ERROR'
export const GET_NEW_POST_COUNT_PENDING = 'GET_NEW_POST_COUNT_PENDING'

export const GET_POST_COMMENTS_SUCCESS = 'GET_POST_COMMENTS_SUCCESS'
export const GET_POST_COMMENTS_ERROR = 'GET_POST_COMMENTS_ERROR'
export const GET_POST_COMMENTS_PENDING = 'GET_POST_COMMENTS_PENDING'

export const GET_COMMENT_REPLY_SUCCESS = 'GET_COMMENT_REPLY_SUCCESS'
export const GET_COMMENT_REPLY_ERROR = 'GET_COMMENT_REPLY_ERROR'
export const GET_COMMENT_REPLY_PENDING = 'GET_COMMENT_REPLY_PENDING'

export const POST_COMMENT_REPLY_SUCCESS = 'POST_COMMENT_REPLY_SUCCESS'
export const POST_COMMENT_REPLY_ERROR = 'POST_COMMENT_REPLY_ERROR'
export const POST_COMMENT_REPLY_PENDING = 'POST_COMMENT_REPLY_PENDING'

export const DELETE_COMMENT_REPLY_SUCCESS = 'DELETE_COMMENT_REPLY_SUCCESS'
export const DELETE_COMMENT_REPLY_ERROR = 'DELETE_COMMENT_REPLY_ERROR'
export const DELETE_COMMENT_REPLY_PENDING = 'DELETE_COMMENT_REPLY_PENDING'

export const EDIT_POST_COMMENT_REPLY_SUCCESS = 'EDIT_POST_COMMENT_REPLY_SUCCESS'
export const EDIT_POST_COMMENT_REPLY_ERROR = 'EDIT_POST_COMMENT_REPLY_ERROR'
export const EDIT_POST_COMMENT_REPLY_PENDING = 'EDIT_POST_COMMENT_REPLY_PENDING'

// TRANSLATE POST TEXT
export const TRANSLATE_POST_SUCCESS = 'TRANSLATE_POST_SUCCESS'
export const TRANSLATE_POST_ERROR = 'TRANSLATE_POST_ERROR'
export const TRANSLATE_POST_PENDING = 'TRANSLATE_POST_PENDING'

// WATCH A POST
export const WATCH_POST_SUCCESS = 'WATCH_POST_SUCCESS'
export const WATCH_POST_ERROR = 'WATCH_POST_ERROR'
export const WATCH_POST_PENDING = 'WATCH_POST_PENDING'

// REACTIONS
export const GET_USER_REACTIONS_SUCCESS = 'GET_USER_REACTIONS_SUCCESS'
export const GET_USER_REACTIONS_ERROR = 'GET_USER_REACTIONS_ERROR'
export const GET_USER_REACTIONS_PENDING = 'GET_USER_REACTIONS_PENDING'
export const POST_REACTION_SUCCESS = 'POST_REACTION_SUCCESS'
export const POST_REACTION_ERROR = 'POST_REACTION_ERROR'
export const POST_REACTION_PENDING = 'POST_REACTION_PENDING'
export const DELETE_REACTION_SUCCESS = 'DELETE_REACTION_SUCCESS'
export const DELETE_REACTION_ERROR = 'DELETE_REACTION_ERROR'
export const DELETE_REACTION_PENDING = 'DELETE_REACTION_PENDING'

//POST CARD ADD COMMENT
export const POST_POST_COMMENT_SUCCESS = 'POST_POST_COMMENT_SUCCESS'
export const POST_POST_COMMENT_ERROR = 'POST_POST_COMMENT_ERROR'
export const POST_POST_COMMENT_PENDING = 'POST_POST_COMMENT_PENDING'

//POST CARD REMOVE COMMENT
export const DELETE_POST_COMMENT_SUCCESS = 'DELETE_POST_COMMENT_SUCCESS'
export const DELETE_POST_COMMENT_ERROR = 'DELETE_POST_COMMENT_ERROR'
export const DELETE_POST_COMMENT_PENDING = 'DELETE_POST_COMMENT_PENDING'

//POST CARD EDIT COMMENT
export const EDIT_POST_COMMENT_SUCCESS = 'EDIT_POST_COMMENT_SUCCESS'
export const EDIT_POST_COMMENT_ERROR = 'EDIT_POST_COMMENT_ERROR'
export const EDIT_POST_COMMENT_PENDING = 'EDIT_POST_COMMENT_PENDING'

//REMOVE POST
export const REMOVE_POST_SUCCESS = 'REMOVE_POST_SUCCESS'
export const REMOVE_POST_ERROR = 'REMOVE_POST_ERROR'
export const REMOVE_POST_PENDING = 'REMOVE_POST_PENDING'


//POST NEW POST
export const POST_NEW_POST_SUCCESS = 'POST_NEW_POST_SUCCESS'
export const POST_NEW_POST_ERROR = 'POST_NEW_POST_ERROR'
export const POST_NEW_POST_PENDING = 'POST_NEW_POST_PENDING'

// PLATFORM
export const POST_PLATFORM_LOG_SUCCESS = 'POST_PLATFORM_LOG_SUCCESS'
export const POST_PLATFORM_LOG_PENDING = 'POST_PLATFORM_LOG_PENDING'
export const POST_PLATFORM_LOG_ERROR = 'POST_PLATFORM_LOG_ERROR'

//SHIFTS
export const GET_SHIFTS_SUCCESS = 'GET_SHIFTS_SUCCESS'
export const GET_SHIFTS_ERROR = 'GET_SHIFTS_ERROR'
export const GET_SHIFTS_PENDING = 'GET_SHIFTS_PENDING'
export const PUT_ACCEPT_SHIFT_SUCCESS = 'PUT_ACCEPT_SHIFT_SUCCESS'
export const PUT_ACCEPT_SHIFT_ERROR = 'PUT_ACCEPT_SHIFT_ERROR'
export const PUT_ACCEPT_SHIFT_PENDING = 'PUT_ACCEPT_SHIFT_PENDING'
export const PUT_REJECT_SHIFT_SUCCESS = 'PUT_REJECT_SHIFT_SUCCESS'
export const PUT_REJECT_SHIFT_ERROR = 'PUT_REJECT_SHIFT_ERROR'
export const PUT_REJECT_SHIFT_PENDING = 'PUT_REJECT_SHIFT_PENDING'

//BADGES
export const GET_BADGES_SUCCESS = 'GET_BADGES_SUCCESS'
export const GET_BADGES_ERROR = 'GET_BADGES_ERROR'
export const GET_BADGES_PENDING = 'GET_BADGES_PENDING'
export const GET_EARNED_BADGES_SUCCESS = 'GET_EARNED_BADGES_SUCCESS'
export const GET_EARNED_BADGES_ERROR = 'GET_EARNED_BADGES_ERROR'
export const GET_EARNED_BADGES_PENDING = 'GET_EARNED_BADGES_PENDING'

// FEEDBACK
export const GET_FEEDBACK_BY_ID_SUCCESS = 'GET_FEEDBACK_BY_ID_SUCCESS'
export const GET_FEEDBACK_BY_ID_ERROR = 'GET_FEEDBACK_BY_ID_ERROR'
export const GET_FEEDBACK_BY_ID_PENDING = 'GET_FEEDBACK_BY_ID_PENDING'
export const GET_FEEDBACK_ALL_SUCCESS = 'GET_FEEDBACK_ALL_SUCCESS'
export const GET_FEEDBACK_ALL_ERROR = 'GET_FEEDBACK_ALL_ERROR'
export const GET_FEEDBACK_ALL_PENDING = 'GET_FEEDBACK_ALL_PENDING'
export const GET_EVENT_TEMPLATE_SUCCESS = 'GET_EVENT_TEMPLATE_SUCCESS'
export const GET_EVENT_TEMPLATE_ERROR = 'GET_EVENT_TEMPLATE_ERROR'
export const GET_EVENT_TEMPLATE_PENDING = 'GET_EVENT_TEMPLATE_PENDING'
export const POST_SURVEY_FEEDBACK_SUCCESS = 'POST_SURVEY_FEEDBACK_SUCCESS'
export const POST_SURVEY_FEEDBACK_ERROR = 'POST_SURVEY_FEEDBACK_ERROR'
export const POST_SURVEY_FEEDBACK_PENDING = 'POST_SURVEY_FEEDBACK_PENDING'
export const GET_FEEDBACK_PERSON_DETAILS_SUCCESS = 'GET_FEEDBACK_PERSON_DETAILS_SUCCESS'
export const GET_FEEDBACK_PERSON_DETAILS_ERROR = 'GET_FEEDBACK_PERSON_DETAILS_ERROR'
export const GET_FEEDBACK_PERSON_DETAILS_PENDING = 'GET_FEEDBACK_PERSON_DETAILS_PENDING'

// COMPLIMENTS
export const SET_COMPLIMENT_FILTER = 'SET_COMPLIMENT_FILTER'
export const GET_COMPLIMENT_BY_ID_SUCCESS = 'GET_COMPLIMENT_BY_ID_SUCCESS'
export const GET_COMPLIMENT_BY_ID_ERROR = 'GET_COMPLIMENT_BY_ID_ERROR'
export const GET_COMPLIMENT_BY_ID_PENDING = 'GET_COMPLIMENT_BY_ID_PENDING'
export const DELETE_COMPLIMENT_BY_ID_SUCCESS = 'DELETE_COMPLIMENT_BY_ID_SUCCESS'
export const DELETE_COMPLIMENT_BY_ID_ERROR = 'DELETE_COMPLIMENT_BY_ID_ERROR'
export const DELETE_COMPLIMENT_BY_ID_PENDING = 'DELETE_COMPLIMENT_BY_ID_PENDING'
export const GET_RECEIVED_COMPLIMENTS_SUCCESS = 'GET_RECEIVED_COMPLIMENTS_SUCCESS'
export const GET_RECEIVED_COMPLIMENTS_ERROR = 'GET_RECEIVED_COMPLIMENTS_ERROR'
export const GET_RECEIVED_COMPLIMENTS_PENDING = 'GET_RECEIVED_COMPLIMENTS_PENDING'
export const GET_SENT_COMPLIMENTS_SUCCESS = 'GET_SENT_COMPLIMENTS_SUCCESS'
export const GET_SENT_COMPLIMENTS_ERROR = 'GET_SENT_COMPLIMENTS_ERROR'
export const GET_SENT_COMPLIMENTS_PAGED_PENDING = 'GET_SENT_COMPLIMENTS_PAGED_PENDING'
export const GET_SENT_COMPLIMENTS_PAGED_SUCCESS = 'GET_SENT_COMPLIMENTS_PAGED_SUCCESS'
export const GET_SENT_COMPLIMENTS_PAGED_ERROR = 'GET_SENT_COMPLIMENTS_PAGED_ERROR'
export const GET_SENT_COMPLIMENTS_PENDING = 'GET_SENT_COMPLIMENTS_PENDING'
export const POST_COMPLIMENT_SUCCESS = 'POST_COMPLIMENT_SUCCESS'
export const POST_COMPLIMENT_ERROR = 'POST_COMPLIMENT_ERROR'
export const POST_COMPLIMENT_PENDING = 'POST_COMPLIMENT_PENDING'
export const PATCH_COMPLIMENT_COMMENT_SUCCESS = 'PATCH_COMPLIMENT_COMMENT_SUCCESS'
export const PATCH_COMPLIMENT_COMMENT_ERROR = 'PATCH_COMPLIMENT_COMMENT_ERROR'
export const PATCH_COMPLIMENT_COMMENT_PENDING = 'PATCH_COMPLIMENT_COMMENT_PENDING'
export const POST_MODERATION_ITEM_FOR_COMPLIMENT_SUCCESS = 'POST_MODERATION_ITEM_FOR_COMPLIMENT_SUCCESS'

// MODERATION
export const POST_MODERATION_ITEM_SUCCESS = 'POST_MODERATION_ITEM_SUCCESS'
export const POST_MODERATION_ITEM_ERROR = 'POST_MODERATION_ITEM_ERROR'
export const POST_MODERATION_ITEM_PENDING = 'POST_MODERATION_ITEM_PENDING'


// CONTACTS
export const GET_CONTACTS_SUCCESS = 'GET_CONTACTS_SUCCESS'
export const GET_CONTACTS_ERROR = 'GET_CONTACTS_ERROR'
export const GET_CONTACTS_PENDING = 'GET_CONTACTS_PENDING'

export const GET_MODERATION_REASONS_SUCCESS = 'GET_MODERATION_REASONS_SUCCESS'
export const GET_MODERATION_REASONS_ERROR = 'GET_MODERATION_REASONS_ERROR'
export const GET_MODERATION_REASONS_PENDING = 'GET_MODERATION_REASONS_PENDING'

//TIME PUNCHES
export const GET_TIME_PUNCHES_SUCCESS = 'GET_TIME_PUNCHES_SUCCESS'
export const GET_TIME_PUNCHES_ERROR = 'GET_TIME_PUNCHES_ERROR'
export const GET_TIME_PUNCHES_PENDING = 'GET_TIME_PUNCHES_PENDING'

export const GET_TIME_PUNCH_DETAILS_SUCCESS = 'GET_TIME_PUNCH_DETAILS_SUCCESS'
export const GET_TIME_PUNCH_DETAILS_ERROR = 'GET_TIME_PUNCH_DETAILS_ERROR'
export const GET_TIME_PUNCH_DETAILS_PENDING = 'GET_TIME_PUNCH_DETAILS_PENDING'


//EVENT CHAT
export const GET_CHAT_ITEM_SUCCESS = 'GET_CHAT_ITEM_SUCCESS'
export const GET_CHAT_ITEM_ERROR = 'GET_CHAT_ITEM_ERROR'
export const GET_CHAT_ITEM_PENDING = 'GET_CHAT_ITEM_PENDING'

export const GET_CHAT_NOTIFICATIONS_SUCCESS = 'GET_CHAT_NOTIFICATIONS_SUCCESS'
export const GET_CHAT_NOTIFICATIONS_ERROR = 'GET_CHAT_NOTIFICATIONS_ERROR'
export const GET_CHAT_NOTIFICATIONS_PENDING = 'GET_CHAT_NOTIFICATIONS_PENDING'

export const REFRESH_CHAT_MESSAGES_PENDING = 'REFRESH_CHAT_MESSAGES_PENDING'
export const REFRESH_CHAT_MESSAGES_ERROR = 'REFRESH_CHAT_MESSAGES_ERROR'
export const REFRESH_CHAT_MESSAGES_SUCCESS = 'REFRESH_CHAT_MESSAGES_SUCCESS'
export const GET_CHAT_MESSAGES_SUCCESS = 'GET_CHAT_MESSAGES_SUCCESS'
export const GET_CHAT_MESSAGES_ERROR = 'GET_CHAT_MESSAGES_ERROR'
export const GET_CHAT_MESSAGES_PENDING = 'GET_CHAT_MESSAGES_PENDING'

export const POST_CHAT_MESSAGE_SUCCESS = 'POST_CHAT_MESSAGE_SUCCESS'
export const POST_CHAT_MESSAGE_ERROR = 'POST_CHAT_MESSAGE_ERROR'
export const POST_CHAT_MESSAGE_PENDING = 'POST_CHAT_MESSAGE_PENDING'

export const EDIT_CHAT_MESSAGE_SUCCESS = 'EDIT_CHAT_MESSAGE_SUCCESS'
export const EDIT_CHAT_MESSAGE_ERROR = 'EDIT_CHAT_MESSAGE_ERROR'
export const EDIT_CHAT_MESSAGE_PENDING = 'EDIT_CHAT_MESSAGE_PENDING'

export const DELETE_CHAT_MESSAGE_SUCCESS = 'DELETE_CHAT_MESSAGE_SUCCESS'
export const DELETE_CHAT_MESSAGE_ERROR = 'DELETE_CHAT_MESSAGE_ERROR'
export const DELETE_CHAT_MESSAGE_PENDING = 'DELETE_CHAT_MESSAGE_PENDING'

export const RESET_CHAT_MESSAGES_STATE = 'RESET_CHAT_MESSAGES_STATE'
export const RESET_CHAT_ITEM_STATE = 'RESET_CHAT_ITEM_STATE'

export const UPDATE_NEW_CHAT_MESSAGES_COUNT = 'UPDATE_NEW_CHAT_MESSAGES_COUNT'
export const SET_NEW_CHAT_MESSAGES_COUNT = 'SET_NEW_CHAT_MESSAGES_COUNT'

// PROFILE
export const GET_PROFILE_IMAGE_SUCCESS = 'GET_PROFILE_IMAGE_SUCCESS'
export const GET_PROFILE_IMAGE_ERROR = 'GET_PROFILE_IMAGE_ERROR'
export const GET_PROFILE_IMAGE_PENDING = 'GET_PROFILE_IMAGE_PENDING'

export const RESET_LIST_OF_PROFILE_IMAGES = 'RESET_LIST_OF_PROFILE_IMAGES'
export const POST_LIST_OF_PROFILE_IMAGES_SUCCESS = 'POST_LIST_OF_PROFILE_IMAGES_SUCCESS'
export const POST_LIST_OF_PROFILE_IMAGES_ERROR = 'POST_LIST_OF_PROFILE_IMAGES_ERROR'
export const POST_LIST_OF_PROFILE_IMAGES_PENDING = 'POST_LIST_OF_PROFILE_IMAGES_PENDING'

export const POST_PROFILE_IMAGE_SUCCESS = 'POST_PROFILE_IMAGE_SUCCESS'
export const POST_PROFILE_IMAGE_ERROR = 'POST_PROFILE_IMAGE_ERROR'
export const POST_PROFILE_IMAGE_PENDING = 'POST_PROFILE_IMAGE_PENDING'

export const DELETE_PROFILE_IMAGE_SUCCESS = 'DELETE_PROFILE_IMAGE_SUCCESS'
export const DELETE_PROFILE_IMAGE_ERROR = 'DELETE_PROFILE_IMAGE_ERROR'
export const DELETE_PROFILE_IMAGE_PENDING = 'DELETE_PROFILE_IMAGE_PENDING'

export const SET_EDITING_COMMUNICATION_PREFERENCES = 'SET_EDITING_COMMUNICATION_PREFERENCES'
export const GET_PROFILE_PREFERRED_LANGUAGE_LIST_SUCCESS = 'GET_PROFILE_PREFERRED_LANGUAGE_LIST_SUCCESS'
export const GET_PROFILE_PREFERRED_LANGUAGE_LIST_ERROR = 'GET_PROFILE_PREFERRED_LANGUAGE_LIST_ERROR'
export const GET_PROFILE_PREFERRED_LANGUAGE_LIST_PENDING = 'GET_PROFILE_PREFERRED_LANGUAGE_LIST_PENDING'
export const GET_PROFILE_PREFERRED_LANGUAGE_SUCCESS = 'GET_PROFILE_PREFERRED_LANGUAGE_SUCCESS'
export const GET_PROFILE_PREFERRED_LANGUAGE_ERROR = 'GET_PROFILE_PREFERRED_LANGUAGE_ERROR'
export const GET_PROFILE_PREFERRED_LANGUAGE_PENDING = 'GET_PROFILE_PREFERRED_LANGUAGE_PENDING'
export const PUT_PROFILE_PREFERRED_LANGUAGE_SUCCESS = 'PUT_PROFILE_PREFERRED_LANGUAGE_SUCCESS'
export const PUT_PROFILE_PREFERRED_LANGUAGE_ERROR = 'PUT_PROFILE_PREFERRED_LANGUAGE_ERROR'
export const PUT_PROFILE_PREFERRED_LANGUAGE_PENDING = 'PUT_PROFILE_PREFERRED_LANGUAGE_PENDING'

//PUBLIC PROFILE
export const GET_PEOPLE_RECEIVED_COMPLIMENTS_SUCCESS = 'GET_PEOPLE_RECEIVED_COMPLIMENTS_SUCCESS'
export const GET_PEOPLE_RECEIVED_COMPLIMENTS_ERROR = 'GET_PEOPLE_RECEIVED_COMPLIMENTS_ERROR'
export const GET_PEOPLE_RECEIVED_COMPLIMENTS_PENDING = 'GET_PEOPLE_RECEIVED_COMPLIMENTS_PENDING'

export const GET_PUBLIC_USER_DETAILS_SUCCESS = 'GET_PUBLIC_USER_DETAILS_SUCCESS'
export const GET_PUBLIC_USER_DETAILS_ERROR = 'GET_PUBLIC_USER_DETAILS_ERROR'
export const GET_PUBLIC_USER_DETAILS_PENDING = 'GET_PUBLIC_USER_DETAILS_PENDING'

export const GET_PEOPLE_EARNED_BADGES_SUCCESS = 'GET_PEOPLE_EARNED_BADGES_SUCCESS'
export const GET_PEOPLE_EARNED_BADGES_ERROR = 'GET_PEOPLE_EARNED_BADGES_ERROR'
export const GET_PEOPLE_EARNED_BADGES_PENDING = 'GET_PEOPLE_EARNED_BADGES_PENDING'
