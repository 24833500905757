import {
  GET_PUBLIC_USER_DETAILS_SUCCESS,
  GET_PUBLIC_USER_DETAILS_PENDING,
  GET_PUBLIC_USER_DETAILS_ERROR,

  GET_PEOPLE_EARNED_BADGES_SUCCESS,
  GET_PEOPLE_EARNED_BADGES_PENDING,
  GET_PEOPLE_EARNED_BADGES_ERROR,

  GET_PEOPLE_RECEIVED_COMPLIMENTS_SUCCESS,
  GET_PEOPLE_RECEIVED_COMPLIMENTS_PENDING,
  GET_PEOPLE_RECEIVED_COMPLIMENTS_ERROR,

} from '../actions/actionTypes'

export default function complimentsReducer(
  state = {
    personDetails: {},
    personDetailsError: {},
    personDetailsPending: false,

    peopleEarnedBadges: [],
    peopleEarnedBadgesError: {},
    peopleEarnedBadgesPending: false,

    peopleReceivedCompliments: {},
    peopleReceivedComplimentsError: {},
    peopleReceivedComplimentsPending: false,
  },
  action
) {
  switch (action.type) {

    case GET_PUBLIC_USER_DETAILS_SUCCESS:
      return {
        ...state,
        personDetails: action.payload,
      }
    case GET_PUBLIC_USER_DETAILS_ERROR:
      return {
        ...state,
        personDetailsError: action.error,
      }
    case GET_PUBLIC_USER_DETAILS_PENDING:
      return {
        ...state,
        personDetailsPending: action.status,
      }

    case GET_PEOPLE_EARNED_BADGES_SUCCESS:
      return {
        ...state,
        peopleEarnedBadges: action.payload,
      }
    case GET_PEOPLE_EARNED_BADGES_ERROR:
      return {
        ...state,
        peopleEarnedBadgesError: action.error,
      }
    case GET_PEOPLE_EARNED_BADGES_PENDING:
      return {
        ...state,
        peopleEarnedBadgesPending: action.status,
      }

    case GET_PEOPLE_RECEIVED_COMPLIMENTS_SUCCESS:
      return {
        ...state,
        peopleReceivedCompliments: action.payload,
      }
    case GET_PEOPLE_RECEIVED_COMPLIMENTS_ERROR:
      return {
        ...state,
        peopleReceivedComplimentsError: action.error,
      }
    case GET_PEOPLE_RECEIVED_COMPLIMENTS_PENDING:
      return {
        ...state,
        peopleReceivedComplimentsPending: action.status,
      }
    default:
      return state
  }
}