import axios from 'axios'
import get from 'lodash/get'

const CancelToken = axios.CancelToken

// Same mappings as the api-config.js keys
const tokens = {}

export function abortAndGetCancelTokenOptions(requestKey) {
  cancelPreviousRequest(requestKey)
  return {
    cancelToken: generateCancelToken(requestKey),
  }
}

export function abortAndGetCancelToken(requestKey) {
  cancelPreviousRequest(requestKey)
  return generateCancelToken(requestKey)
}

/**
 * For a request key string, generate a CancelToken and store in the map.
 * Return the CancelToken's token
 * @export
 * @param {*} requestKey
 * @returns
 */
export function generateCancelToken(requestKey) {
  cancelPreviousRequest(requestKey)
  const source = CancelToken.source()
  tokens[requestKey] = source
  return source.token
}

/**
 * For a request key string, get the token if available from the tokens map
 * Cancel the request and then null out the key's value
 * @export
 * @param {*} requestKey
 */
export function cancelPreviousRequest(requestKey) {
  const val = get(tokens, requestKey)
  if (val) {
    val.cancel(requestKey)
  }
  tokens[requestKey] = null
}

export default tokens
