import * as types from './actionTypes'
import services from '../services'

export function postPlatformLog(personId, mobile) {
    return dispatch => {
        dispatch(postPlatformLogPending(true))
        services.platformLog
            .postPlatformLog(personId, mobile)
            .then(response => {
                dispatch(postPlatformLogSuccess(response))
                dispatch(postPlatformLogPending(false))
            })
            .catch(error => {
                dispatch(postPlatformLogError(error))
            })
    }
}

const postPlatformLogPending = pending => {
    return {
        type: types.POST_PLATFORM_LOG_PENDING,
        payload: pending,
    }
}

const postPlatformLogSuccess = response => {
    return {
        type: types.POST_PLATFORM_LOG_SUCCESS,
        payload: response.data
    }
}

const postPlatformLogError = error => {
    return {
        type: types.POST_PLATFORM_LOG_ERROR,
        error: error,
    }
}