import { config as appConfig } from './config'
// import template from 'lodash/template'

const IDN_URL = appConfig.IDENTITY_API_URL
const API_URL = appConfig.SCHEDULE_API_URL
const PEOPLE_API_URL = appConfig.PEOPLE_API_URL
const SOCIAL_API_URL = appConfig.SOCIAL_API_URL
const SHIFTS_API_URL = appConfig.SHIFTS_API_URL
const BADGES_API_URL = appConfig.BADGES_API_URL
const NOTIFICATIONS_API_URL = appConfig.NOTIFICATIONS_API_URL
const FEEDBACK_API_URL = appConfig.FEEDBACK_API_URL
const COMPLIMENTS_API_URL = appConfig.COMPLIMENTS_API_URL
const MODERATION_API_URL = appConfig.MODERATION_API_URL
const CONTACTS_API_URL = appConfig.CONTACTS_API_URL
const TRANSLATE_API_URL = appConfig.TRANSLATE_API_URL
const TIME_PUNCHES_API_URL = appConfig.TIME_PUNCHES_API_URL
const PROFILE_API_URL = appConfig.PROFILE_API_URL


const v1 = 'v1'
const v2 = 'v2'

// prettier-ignore
const mapping = {

  // Authentication
  ACCOUNT_LOGOUT:                          `${IDN_URL}/account/logouttoken`,
  CONNECT_USER_INFO:                       `${IDN_URL}/connect/userinfo`,
  SESSION_CHECK:                           `${IDN_URL}/session/check`,

  // /availability
  GENERAL_AVAILABILITY:                    `${PEOPLE_API_URL}/${v1}/availability/general`,
  PATCH_GENERAL_AVAILABILITY:              `${PEOPLE_API_URL}/${v1}/Availability/General`,
  OTHER_AVAILABILITY:                      `${PEOPLE_API_URL}/${v1}/availability/special`,
  PATCH_OTHER_AVAILABILITY:                `${PEOPLE_API_URL}/${v1}/Availability/special`,
  DELETE_OTHER_AVAILABILITY:               `${PEOPLE_API_URL}/${v1}/Availability/DeleteSpecial`,

  // transportation profile
  GET_TRANSPORTATION_TYPES:                `${PEOPLE_API_URL}/${v1}/transportationprofiles/transportationTypes`,
  GET_TRANSPORTATION_PROFILE:              `${PEOPLE_API_URL}/${v1}/TransportationProfiles/[personId]`,
  PATCH_TRANSPORTATION_PROFILE:            `${PEOPLE_API_URL}/${v1}/TransportationProfiles`,
  GET_RIDE_ALONG_ERRORS:                   `${PEOPLE_API_URL}/${v1}/TransportationProfiles/[personId]/RideAlongErrors`,

  // organization
  GET_ORGANIZATION:                       `${PEOPLE_API_URL}/${v1}/organizations/[organizationId]`,
  SEARCH_PEOPLE_IN_ORGANIZATION:          `${PEOPLE_API_URL}/${v1}/organizations/[organizationId]/People/Search/[searchString]`,
  GET_ALL_PEOPLE_IN_ORGANIZATION:         `${PEOPLE_API_URL}/${v1}/organizations/[organizationId]/People/`,

  // /profilePref
  PROFILE_PREF:                           `${API_URL}/${v1}/profile/preferences`,

  // profile API
  GET_PROFILE_IMAGE:                       `${PROFILE_API_URL}/${v1}/Pictures`,
  POST_PROFILE_IMAGE:                      `${PROFILE_API_URL}/${v1}/Pictures/[personId]`,
  GET_PROFILE_PREFERRED_LANGUAGE:          `${PROFILE_API_URL}/${v1}/Languages/preferred`,
  PUT_PROFILE_PREFERRED_LANGUAGE:          `${PROFILE_API_URL}/${v1}/Languages/preferred?language=[language]`,
  GET_PROFILE_PREFERRED_LANGUAGE_LIST:     `${PROFILE_API_URL}/${v1}/Languages`,

  // people API
  PUBLISHED_WEEK_DETAILS:                  `${PEOPLE_API_URL}/${v1}/Organizations/[organizationId]/publishedweek`,
  SCHEDULING_PREFERENCES:                  `${PEOPLE_API_URL}/${v1}/SchedulingPreferences/[personId]`,
  PATCH_SCHEDULING_PREFERENCES:            `${PEOPLE_API_URL}/${v1}/SchedulingPreferences`,
  PERSON_DETAILS:                          `${PEOPLE_API_URL}/${v1}/people/[personId]`,
  GET_PERSON_DETAIL_LIST:                  `${PEOPLE_API_URL}/${v1}/people`,
  GET_SEARCH_ALL_PEOPLE:                   `${PEOPLE_API_URL}/${v1}/People/Search`,

  // /notifications
  NOTIFICATIONS:                           `${API_URL}/${v1}/notifications`,
  NOTIFICATIONS_COUNT:                     `${API_URL}/${v1}/notifications/count`,
  GET_NOTIFICATIONS_HISTORY:               `${NOTIFICATIONS_API_URL}/${v2}/History?fromDateTime=[fromDateTime]`,
  GET_UNREAD_NOTIFICATIONS_HISTORY:        `${NOTIFICATIONS_API_URL}/${v2}/History?fromDateTime=[fromDateTime]&unreadOnly=true`,
  GET_DOTNET_NOTIFICATIONS_COUNT:          `${NOTIFICATIONS_API_URL}/${v2}/History/count?fromDateTime=[fromDateTime]`,
  PATCH_NOTIFICATIONS_HISTORY_STATUS:      `${NOTIFICATIONS_API_URL}/${v2}/History/status/[notificationId]`,
  GET_NOTIFICATION_HISTORY_TYPES:          `${NOTIFICATIONS_API_URL}/${v2}/NotificationType`,
  GET_NOTIFICATION_PREFERENCES:            `${NOTIFICATIONS_API_URL}/${v2}/Preferences`,
  POST_NOTIFICATION_PREFERENCES:           `${NOTIFICATIONS_API_URL}/${v2}/Preferences`,

  // /schedule
  SCHEDULE:                               `${API_URL}/${v1}/schedule`,
  SCHEDULE_DETAILS:                       `${API_URL}/${v1}/schedule/details`,
  DISTRICT_EVENT_DETAILS:                 `${API_URL}/${v1}/district/events/[districtEventId]`,
  GET_EVENT_DETAILS_LIST:                 `${API_URL}/${v1}/schedule/events?scheduledEventIds=[scheduledEventIds]`,
  SCHEDULE_TEAM_DETAILS:                  `${API_URL}/${v1}/schedule/teamDetails`,
  DISTRICT_SCHEDULE_TEAM_MEMBER_INFO:     `${API_URL}/${v1}/districtSchedule/teamMemberInfo`,
  SCHEDULE_APH:                           `${PEOPLE_API_URL}/${v1}/people/aph/[eventId]`,
  SCHEDULE_MEET_SITE:                     `${API_URL}/${v1}/schedule/meetSite?scheduledEventId=[scheduledEventId]`,

  // /confirmWeek
  CONFIRM_WEEK:                           `${API_URL}/${v1}/confirmWeek`,

  // CONFIRM_EVENT:                           `${API_URL}/confirmEvent`, TODO

  // /scheduleChange
  SCHEDULE_CHANGE:                        `${API_URL}/${v1}/scheduleChange`,
  SCHEDULE_CHANGE_HISTORY:                `${API_URL}/${v1}/scheduleChange/history`,
  SCHEDULE_CHANGE_TYPES:                  `${API_URL}/${v1}/scheduleChange/types`,
  SCHEDULE_CHANGE_REASONS:                `${API_URL}/${v1}/scheduleChange/reasons`,
  SCHEDULE_CHANGE_REQUEST:                `${API_URL}/${v1}/scheduleChange/request`,
  SCHEDULE_CHANGE_REQUEST_APPROVE:        `${API_URL}/${v1}/scheduleChange/request/approve`,
  SCHEDULE_CHANGE_REQUEST_DENY:           `${API_URL}/${v1}/scheduleChange/request/deny`,

  // feature access
  GET_FEATURE_ACCESS_LIST:                `${PEOPLE_API_URL}/${v1}/FeatureAccess`,
  GET_PERSON_FEATURE_ACCESS_LIST:         `${PEOPLE_API_URL}/${v1}/FeatureAccess/person/[personId]`,

  // posts
  NEW_POST_COUNT:                         `${SOCIAL_API_URL}/${v1}/Channels/[channelId]/Posts/NewPostCount?sinceDateTime=[sinceDateTime]`,
  CHANNEL:                                `${SOCIAL_API_URL}/${v1}/Channels/[channelId]/Posts`,
  CHANNEL_ACCESS:                         `${SOCIAL_API_URL}/${v1}/Channels/[channelId]/ChannelAccess`,
  POST:                                   `${SOCIAL_API_URL}/${v1}/Posts/[postId]`,
  POST_COMMENTS:                          `${SOCIAL_API_URL}/${v1}/Posts/[postId]/comments`,
  INSERT_POST_COMMENT:                    `${SOCIAL_API_URL}/${v2}/Posts`,
  WATCH_POST:                             `${SOCIAL_API_URL}/${v2}/Posts/[postId]/watch`,
  UNWATCH_POST:                           `${SOCIAL_API_URL}/${v2}/Posts/[postId]/unwatch`,
  WATCH_CHANNEL:                          `${SOCIAL_API_URL}/${v1}/Channels/[channelId]/watch`,
  UNWATCH_CHANNEL:                        `${SOCIAL_API_URL}/${v1}/Channels/[channelId]/unwatch`,
  S3_FILE:                                `${SOCIAL_API_URL}/${v1}/S3/news/file`, 
  TRANSLATE_POST:                         `${TRANSLATE_API_URL}/${v2}/Translation`,
   
  // RConnect API access
  POST_PLATFORM_LOG:                      `${PEOPLE_API_URL}/${v1}/RConnect/PlatformLog`,

  // REMOVED
  // /scheduleChange/request is being used today to make a POST to make a schedule change request.
  // /scheduleChangeRequest is being used as a GET to get the info on a specific Change Request used to
  // Moving these endpoints under "/scheduleChange" for readibility and lessening confusion
  // SCHEDULE_CHANGE_REQUEST:                `${API_URL}/scheduleChangeRequest`,
  // SCHEDULE_CHANGE_REQUEST_APPROVE:        `${API_URL}/scheduleChangeRequest/approve`,
  // SCHEDULE_CHANGE_REQUEST_DENY:           `${API_URL}/scheduleChangeRequest/deny`

  // channels
  CHANNELS:                               `${SOCIAL_API_URL}/${v1}/Channels`,
  
  // Reactions
  USER_REACTIONS:                         `${SOCIAL_API_URL}/${v2}/Posts/Reactions`,
  POST_REACTION:                          `${SOCIAL_API_URL}/${v2}/Reactions`,
  DELETE_REACTION:                        `${SOCIAL_API_URL}/${v1}/Reactions/[reactionId]`,
  
  // Shifts
  SHIFTS:                                 `${SHIFTS_API_URL}/${v1}/Shifts`,
  ACCEPT_SHIFT:                           `${SHIFTS_API_URL}/${v2}/Shifts/[shiftId]/Accept`,
  REJECT_SHIFT:                           `${SHIFTS_API_URL}/${v1}/Shifts/[shiftId]/Reject`,

  //Badges
  BADGES:                                 `${BADGES_API_URL}/${v1}/Badges`,
  PEOPLE_BADGES:                          `${BADGES_API_URL}/${v1}/Badges/people/[personId]`,
  EARNED_BADGES:                          `${BADGES_API_URL}/${v1}/Badges/earned`,
  PEOPLE_EARNED_BADGES:                   `${BADGES_API_URL}/${v1}/Badges/people/[personId]/earned`,

  // feedback
  GET_FEEDBACK_BY_ID:                     `${FEEDBACK_API_URL}/${v1}/Feedback/[feedbackId]`,
  GET_FEEDBACK_BY_EVENT_TEMPLATE:         `${FEEDBACK_API_URL}/${v1}/Feedback?subjectEventId=[subjectEventId]&&subjectPersonId=[subjectPersonId]&templateName=[templateName]`,
  GET_LATEST_SURVEY_TEMPLATE:             `${FEEDBACK_API_URL}/${v1}/Templates/[templateName]/latest`,
  POST_SURVEY_FEEDBACK:                   `${FEEDBACK_API_URL}/${v1}/Feedback`,

  // compliments
  GET_RECEIVED_COMPLIMENTS:               `${COMPLIMENTS_API_URL}/${v1}/Compliments/received`,
  GET_SENT_COMPLIMENTS:                   `${COMPLIMENTS_API_URL}/${v1}/Compliments/sent`,
  GET_SENT_COMPLIMENTS_PAGED:             `${COMPLIMENTS_API_URL}/${v1}/Compliments/sent/[page]?pageSize=[pageSize]`,
  GET_COMPLIMENT_BY_ID:                   `${COMPLIMENTS_API_URL}/${v1}/Compliments/[complimentId]`,
  DELETE_COMPLIMENT_BY_ID:                `${COMPLIMENTS_API_URL}/${v1}/Compliments/[complimentId]`,
  POST_COMPLIMENT:                        `${COMPLIMENTS_API_URL}/${v1}/Compliments`,
  PATCH_COMPLIMENT_COMMENT:               `${COMPLIMENTS_API_URL}/${v1}/Compliments/[complimentId]/Comments`,
  GET_PEOPLE_RECEIVED_COMPLIMENTS:        `${COMPLIMENTS_API_URL}/${v1}/Compliments/people/[personId]/received/summary`,

  // moderation
  POST_MODERATION_ITEM:                   `${MODERATION_API_URL}/${v1}/ModerationItem`,

  //CONTACTS
  CONTACTS:                               `${CONTACTS_API_URL}/${v1}/Contacts`,
  GET_MODERATION_REASONS:                 `${MODERATION_API_URL}/${v1}/Reasons`,

  GET_EVENT_CHAT_ITEM:                    `${SOCIAL_API_URL}/${v1}/ScheduledStores/[scheduledStoreId]/channel`,
  GET_EVENT_CHAT_NOTIFICATIONS:           `${SOCIAL_API_URL}/${v1}/Channels/MessageCounts`,

  TIME_PUNCHES_SUMMARY:                   `${TIME_PUNCHES_API_URL}/${v1}/timepunches/summary`,
  TIME_PUNCH_DETAILS:                     `${TIME_PUNCHES_API_URL}/${v1}/timepunches/[timePunchId]/details`
}

export default mapping
