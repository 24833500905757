import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import './ChannelListItem.scss'
import { t } from '../../../utils/i18n';

class ChannelListItem extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const { channel } = this.props
    const { pathname } = this.props.history.location

    return (
      <div className="row channel-list-item" key={channel.channelId}>
        <div className="col">
          <div className={`card ${channel.newPostCount !== 0 ? 'has-new-posts' : ''}`}>
            <Link
              to={{
                pathname: `${pathname}/channel/${channel.channelId}`,
                state: {
                  channelInfo: channel,
                },
              }}
            >
              <div className="card-body">
                <div className="section">
                  <div className="row">
                    <div className="col">
                      <div className="text-uppercase">
                        <h5>{channel.title}</h5>
                      </div>
                    </div>
                    {
                      channel.newPostCount !== 0 &&
                      <div className="col d-flex justify-content-end">
                        <div className="">{`${t('new_posts')}: ${channel.newPostCount}`}</div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

ChannelListItem.propTypes = {
  channel: PropTypes.object,
  history: PropTypes.object
}

export default ChannelListItem

