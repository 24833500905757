
export function deviceIsIOS() {
    return (/iPhone|iPad|iPod/i.test(navigator.userAgent))
}

export function deviceIsAndroid() {
    return (/Android/i.test(navigator.userAgent))
}

export function isMobileDevice() {
    return deviceIsIOS() || deviceIsAndroid()
}

export function isIOSChrome() {
    var winNav = window.navigator
    return winNav.userAgent.match("CriOS")
}