import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import Preloader from '../../components/Preloader'

import { 
  setRgisBearerToken, 
  setRgisIdTokenHint 
} from '../../components/utils/localStorageUtils'

class OidcCallbackContainer extends Component {
  constructor(props) {
    super(props)

    this.getParameterByName = this.getParameterByName.bind(this)
  }

  componentDidMount() {
    const bearer = this.getParameterByName('access_token', window.location.href)
    const id_token_hint = this.getParameterByName('id_token', window.location.href)

    if (bearer !== null || bearer === '') {
      setRgisBearerToken(bearer)
      setRgisIdTokenHint(id_token_hint)

      this.props.history.push('/initializer')
    }
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href
    name = name.replace(/[[\]]/g, '\\$&')
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url)
    if (!results) return null

    if (!results[2]) return ''

    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  }

  render() {
    return <Preloader />
  }
}

function mapStateToProps() {
  return {
  }  
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
    },
    dispatch
  )
}

OidcCallbackContainer.propTypes = {
  history: PropTypes.object,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OidcCallbackContainer)