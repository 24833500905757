import axios from 'axios'
import apiConfig from '../../config/api-config'
import { toQueryString } from './utils/requestUtil'
import * as cancelTokenUtil from './utils/cancelTokenUtil'

// Get person details
export function fetchNotifications(count) {
  const url = apiConfig.NOTIFICATIONS
  const queryParams = toQueryString({
    page: 0,
    size: count,
  })
  const options = cancelTokenUtil.abortAndGetCancelTokenOptions('NOTIFICATIONS')
  return axios.get(`${url}?${queryParams}`, options)
}

export function fetchNotificationCount(lastCheck) {
  const url = apiConfig.NOTIFICATIONS_COUNT
  const queryParams = toQueryString({
    lastCheck
  })
  return axios.get(`${url}?${queryParams}`)
}

export function fetchDotNetNotificationCount(fromDateTime) {
  const url = apiConfig.GET_DOTNET_NOTIFICATIONS_COUNT.replace('[fromDateTime]', encodeURIComponent(fromDateTime))
  return axios.get(url)
}

export function patchMarkNotificationsAsRead(notificationIds) {
  const url = apiConfig.NOTIFICATIONS
  return axios.patch(`${url}`, notificationIds)
}

export function getChannelIdByPostId(postId) {
  const url = apiConfig.POST.replace('[postId]', postId)
  return axios.get(url)
}

export function getNotificationsHistory(fromDateTime) {
  const url = apiConfig.GET_NOTIFICATIONS_HISTORY.replace('[fromDateTime]', encodeURIComponent(fromDateTime))
  return axios.get(url)
}

export function getUnreadNotificationsHistory(fromDateTime) {
  const url = apiConfig.GET_UNREAD_NOTIFICATIONS_HISTORY.replace('[fromDateTime]', encodeURIComponent(fromDateTime))
  return axios.get(url)
}

export function getNotificationHistoryTypes() {
  const url = apiConfig.GET_NOTIFICATION_HISTORY_TYPES
  return axios.get(url)
}

export function getNotificationPreferences() {
  const url = apiConfig.GET_NOTIFICATION_PREFERENCES
  return axios.get(url)
}

export function postNotificationPreferences(notificationTypeId, deliveryTypeId, enabled) {
  let dataArray = []
  const url = apiConfig.POST_NOTIFICATION_PREFERENCES
  let data = {
    notificationTypeId: notificationTypeId,
    deliveryTypeId: deliveryTypeId,
    enabled: enabled
  }
  dataArray.push(data)
  return axios.post(url, dataArray)
}

export function patchNotificationsHistoryStatusAsRead(notificationId) {
  const READ_STATUS = 'Read'
  const url = apiConfig.PATCH_NOTIFICATIONS_HISTORY_STATUS.replace('[notificationId]', notificationId)
  let data = {
    status: READ_STATUS
  }
  return axios.patch(url, data)
}