import {
  GET_DISTRICT_TEAM_REQUEST,
  GET_DISTRICT_TEAM_REQUEST_ERROR,
  GET_DISTRICT_TEAM_REQUEST_PENDING,
  GET_DISTRICT_TEAM_REQUEST_SUCCESS,
} from '../actions/actionTypes'

export default function districtRequestManagerReducer(
  state = {
    districtRequestResponse: {},
    districtRequestPending: false,
    districtRequestError: false,
    districtRequestSuccess: false,
  },
  action
) {
  switch (action.type) {
    case GET_DISTRICT_TEAM_REQUEST:
      return {
        districtRequestResponse: action.payload,
      }
    case GET_DISTRICT_TEAM_REQUEST_ERROR:
      return {
        ...state,
        districtRequestError: true,
      }
    case GET_DISTRICT_TEAM_REQUEST_PENDING:
      return {
        ...state,
        districtRequestPending: action.payload,
      }
    case GET_DISTRICT_TEAM_REQUEST_SUCCESS:
      return {
        ...state,
        districtRequestSuccess: action.payload,
        districtRequestStatus: false,
        districtRequestError: false,
      }
    default:
      return state
  }
}
