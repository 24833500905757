import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Redirect } from 'react-router-dom'
import { NavLink, withRouter } from 'react-router-dom'
import axios from 'axios'
import * as appConfig from '../../config/config'
import PropTypes from 'prop-types'
import moment from 'moment'

import LanguageSwitcher from './LanguageSwitcher'
import rconlogo from '../../styles/images/w-connect-logo-2x.png'
import Preloader from '../Preloader'
import AppDownloadModal from '../../components/AppDownloadModal';

import { 
  checkSession, 
  logout, 
  resetState, 
  setProfileLocale 
} from '../../state/actions/navigationActions'
import { 
  getUnreadNotificationsCount,
  getUnreadNotificationsHistory,
} from '../../state/actions/notificationsActions'
import { getChannels } from '../../state/actions/newsActions'
import { getShifts } from '../../state/actions/openShiftsActions'
import { getEarnedBadges } from '../../state/actions/badgesActions'
import {
  getSchedule,
  getMonthSchedule,
  getEventChatNotificationsByStoreIds,
  setScheduleDates,
  setNewChatMessagesCount
} from '../../state/actions/scheduleActions'
import { getPublishedWeek } from '../../state/actions/personDetailsActions'
import { 
  setPromptedForAppDownload, 
  setShowAppDownloadModal 
} from '../../state/actions/mobileDeviceActions'

import { t } from '../../utils/i18n'
import { 
  setPreferredLanguage, 
  setNewPosts, 
  getNewPosts, 
  getRgisBearerToken, 
  setCountry, 
  getCountry, 
  getPreferredLanguage, 
  loggedInUserIsManager 
} from '../utils/localStorageUtils'
import { deviceIsAndroid, deviceIsIOS } from '../utils/mobileUtils'
import { 
  hasChatAccess, 
  hasMyScheduleAccess, 
  hasNewsAccess, 
  hasShiftsAccess, 
  hasBadgesAccess, 
  hasComplimentsAccess,
  hasRScheduleAccess,
  hasTimePunchesAccess,
} from '../utils/featureAccessUtils'
import { 
  updateMoment, 
  formatLocale, 
  formatDateNotForDisplay, 
  getEndOfWeek, 
  getStartDate 
} from '../../components/utils/datetimeUtils'
import { 
  nullSafeCheckIsTrue,
  nullSafeCheckIsFalse 
} from '../utils/nullSafeCheckUtils'


import './Navigation.scss'

class Navigation extends Component {
  constructor(props) {
    super(props)

    this.state = {
      collapsed: true,
      language: getPreferredLanguage() || '',
      country: getCountry() || '',
      newPosts: 0,
      newOpenShifts: false,
      newBadges: false,
      newNotifications: false,
      confirmEventDates: {
        start: null,
        end: null,
      },
      isPageReloaded: this.isPageReloaded(),
    }

    this.toggleNavbar = this.toggleNavbar.bind(this)
    this.updateDimensions = this.updateDimensions.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
    this.onSelectLanguage = this.onSelectLanguage.bind(this)
    this.handleLocalStorageChange = this.handleLocalStorageChange.bind(this)

    window.addEventListener('hashchange', this.handleLocalStorageChange)
  }

  isPageReloaded = () => {
    let isPageReloaded = false

    if (window.performance) {
      if (performance.navigation.type === 1) {
        isPageReloaded = true
      }
    }

    return isPageReloaded
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed,
    })
  }

  handleLocalStorageChange() {
    this.setState({
      newPosts: parseInt(getNewPosts())
    })
  }

  componentDidMount() {
    this.autoCheckUserSession() // DO NOT REMOVE USED FOR TIMEOUT, ETC
    this.props.checkSession() 
    updateMoment(getPreferredLanguage(), getCountry())
    window.addEventListener('resize', this.updateDimensions)
    this.checkPromptForAppDownload()
    this.props.getUnreadNotificationsCount(moment().subtract(10, 'days').toISOString())
  }

  autoCheckUserSession = () => {
    const CHECK_USER_SESSION_TIMEOUT = 60000 * 15 // 15 minutes
    document.addEventListener('mousemove', () => {
      clearTimeout(window.z)
      window.z = setTimeout(() => {
        logout()
      }, CHECK_USER_SESSION_TIMEOUT)
    })
  }

  checkPromptForAppDownload = () => {
    if (!this.props.promptedForAppDownload) {
      if (deviceIsIOS()) {
        this.setShowAppDownloadModal(true)
        this.setPromptedForAppDownload(true)
      } else if (deviceIsAndroid()) {
        this.setShowAppDownloadModal(true)
        this.setPromptedForAppDownload(true)
      }
    }
  }

  setPromptedForAppDownload = (prompted) => {
    this.props.setPromptedForAppDownload(prompted)
  }

  setShowAppDownloadModal = (showModal) => {
    this.props.setShowAppDownloadModal(showModal)
  }

  loadMonthlySchedule = () => {
    const startDate = getStartDate()
    const scheduleStartDate = formatDateNotForDisplay(moment(startDate).subtract(28, 'days'))
    const scheduleEndDate = formatDateNotForDisplay(this.props.publishedWeek.endDate)
    this.props.getMonthSchedule(scheduleStartDate, scheduleEndDate)
  }

  checkConfirmEventDone = (prevProps) => {
    if (prevProps.confirmEventPending !== this.props.confirmEventPending && !this.props.confirmEventPending) {
      const startDate = formatDateNotForDisplay(getStartDate())
      if (startDate !== this.props.scheduleStartDate) {
        this.setState({
          confirmEventDates: {
            start: this.props.scheduleStartDate,
            end: this.props.scheduleEndDate,
          },
        })
      }
      this.loadMonthlySchedule()
    }
  }

  checkLoadMySchedule = (prevProps) => {
    ////////////////////for MY SCHEDULE
    if (
      this.props.scheduleStartDate !== prevProps.scheduleStartDate &&
      this.props.scheduleEndDate !== prevProps.scheduleEndDate
    ) {
      const { confirmEventDates } = this.state

      if (confirmEventDates.start === null || confirmEventDates.end === null) {
        this.props.getSchedule(this.props.scheduleStartDate, this.props.scheduleEndDate)
      } else {
        this.props.setScheduleDates(confirmEventDates.start, confirmEventDates.end)
        this.setState({
          confirmEventDates: {
            start: null,
            end: null,
          },
        })
      }
    }
  }

  componentDidUpdate(prevProps) {
    this.checkConfirmEventDone(prevProps)

    if (prevProps.featureAccessPending !== this.props.featureAccessPending && !this.props.featureAccessPending) {
      this.checkGetNewsFeed(this.props.featureAccess)
      this.checkGetShifts(this.props.featureAccess)
      this.checkGetBadges(this.props.featureAccess)
    }

    if (hasMyScheduleAccess(this.props.featureAccess)) {
      this.checkLoadMySchedule(prevProps)

      if (prevProps.monthSchedulePending === true && this.props.monthSchedulePending === false) {
        this.checkChatNotifications()

        this.setScheduleDates()
      }

      if (prevProps.eventChatNotificationsPending === true && this.props.eventChatNotificationsPending === false) {
        this.checkChatMessageCounts()
      }      
    }

    if (prevProps.channelsPending === true && this.props.channelsPending === false) {
      let newPosts = 0
      this.props.channels.map((channel) => {
        newPosts += channel.newPostCount
        return ''
      })
      setNewPosts(newPosts)
      this.setState({
        newPosts
      })
    }

    if (prevProps.shiftsPending === true && this.props.shiftsPending === false) {
      this.setState({
        newOpenShifts: this.props.shifts.length === 0 ? false : true
      })
    }

    if (prevProps.earnedBadgesPending === true && this.props.earnedBadgesPending === false && this.props.earnedBadges.length !== 0) {
      const currentDay = moment().startOf('day')
      const isRecent = moment(new Date(this.props.earnedBadges[0].earnedUnearnedDateTime)).isAfter(currentDay)
      this.setState({
        newBadges: isRecent ? true : false
      })
    }

    this.checkNewNotificationsCount(prevProps)
    this.checkUnreadNotificationsHistory(prevProps)
  }

  checkUnreadNotificationsHistory = (prevProps) => {
    if (nullSafeCheckIsTrue(prevProps.unreadNotificationsHistoryPending) && nullSafeCheckIsFalse(this.props.unreadNotificationsHistoryPending)) {
      const hasNewNotifications = this.props.unreadNotificationsHistory.length > 0 ? true : false
      this.setState({
        newNotifications: hasNewNotifications
      })
    }
  }

  checkNewNotificationsCount = (prevProps) => {
    if (prevProps.newNotificationsCountPending === true && this.props.newNotificationsCountPending === false) {
      const hasNewNotifications = this.props.newNotificationsCount > 0 ? true : false
      this.setState({
        newNotifications: hasNewNotifications
      })

      if (!hasNewNotifications) {
        this.props.getUnreadNotificationsHistory(moment().subtract(10, 'days').toISOString())
      }
    }
  }

  checkGetBadges = (featureAccess) => {
    if (hasBadgesAccess(featureAccess)) {
      this.props.getEarnedBadges(1)
    }
  }

  checkGetShifts = (featureAccess) => {
    if (hasShiftsAccess(featureAccess)) {
      this.props.getShifts()
    }
  }

  checkGetNewsFeed = (featureAccess) => {
    if (hasNewsAccess(featureAccess)) {
      this.props.getChannels()
    }
  }

  setScheduleDates = () => {
    const startDate = getStartDate()
    this.props.setScheduleDates(startDate, getEndOfWeek(startDate))
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  checkChatMessageCounts = () => {
    let newChatMessages = 0

    if (hasChatAccess(this.props.featureAccess)) {
      this.props.eventChatNotifications.map((item) => {
        newChatMessages += item.newPostCount
        return ''
      })
    }
    
    this.props.setNewChatMessagesCount(newChatMessages)
  }

  checkChatNotifications = () => {
    if (hasChatAccess(this.props.featureAccess)) {
      const storeIds = this.props.monthScheduleItems.reduce((total, scheduleItem) => {
        if (scheduleItem.scheduledStoreId) {
          total.push(parseInt(scheduleItem.scheduledStoreId, 10))
        }
        return total
      }, [])

      if (storeIds.length) {
        this.props.getEventChatNotificationsByStoreIds(storeIds)
      }
    }
  }

  static getDerivedStateFromProps(props) {
    let language = getPreferredLanguage() 
    if (props.language && !language) {      
      setPreferredLanguage(props.language)
    }

    let newOpenShifts = true
    if (props.shifts.length === 0) {
      newOpenShifts = false
    }

    return {
      language: language,
      newOpenShifts: newOpenShifts,
    }
  }

  handleLogout(e) {
    e.preventDefault()
    this.props.resetState()
    logout()
  }

  onSelectLanguage(language, country) {
    const bearer = getRgisBearerToken()
    const locale = formatLocale(language, country)
    const currentLanguage = getPreferredLanguage()
    const currentCountry = getCountry()
    const API_URL = appConfig.config.IDENTITY_API_URL

    setPreferredLanguage(language)
    setCountry(country)

    if (language === currentLanguage && country === currentCountry) {
      return
    } else {
      axios
        .post(
          `${API_URL}/account/locale`,
          {
            locale: locale,
          },
          {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${bearer}`,
            },
          }
        )
        .finally(() => {
          this.setState({
            language: language,
            country: country,
          })
          this.props.setProfileLocale(formatLocale(getPreferredLanguage(), getCountry()))
        })
        .catch(() => {
          // console.log(error);
        })
    }
    updateMoment(language, country)
  }

  getRScheduleLink() {
      return appConfig.config.RSCHEDULE_URL
  }

  getRScheduleMenuItemMarkup = () => {
    return (
        <li className="nav-item ">
          <a href={`${this.getRScheduleLink()}`} target="_blank" rel="noopener noreferrer" className="nav-link">
            <div className="d-none d-lg-block">WIS Schedule</div>
            <div className="d-lg-none icon-label" onClick={this.toggleNavbar}>
              <div className="rschedule-icon"></div>
              WIS Schedule
          </div>
          </a>
        </li>
      )
  }

  updateDimensions() {
    if (window.innerWidth > 992) {
      this.setState({
        collapsed: true,
      })
    }
  }

  getRideAlongErrorsMarkUp = () => {
    if (!this.props.rideAlongErrorsPending && this.props.rideAlongErrors.length > 0) {
      return (<i title={t('team_member_not_valid')} className="material-icons error">error</i>)
    } else {
      return ('')
    }
  }

  getMenuItemsByPermissions = () => {
    return (
      <ul className="navbar-nav mr-auto">
        {this.getProfileMenuItemMarkUp()}
        {this.getScheduleMenuItemMarkUpByPermissions()}
        {this.getOpenShiftsMenuItemMarkUpByPermissions()}
        {this.getNotificationMenuItemMarkUp()}
        {this.getNewsMenuItemMarkUpByPermissions()}
        {this.getRScheduleMenuItemMarkUpByPermissions()}
        {this.getSentComplimentsMenuItemMarkUpByPermissions()}
        {this.getContactsMenuItemMarkUpByPermissions()}
        {this.getTimePunchesMenuItemMarkUpByPermissions()}
        {this.getMyTeamMarkUpByPermissions()}
      </ul>)
  }

  getRScheduleMenuItemMarkUpByPermissions = () => {
    if (hasRScheduleAccess(this.props.featureAccess)) {
      return this.getRScheduleMenuItemMarkup()
    } else {
      return ('')
    }
  }

  getScheduleMenuItemMarkUpByPermissions = () => {
    if (hasMyScheduleAccess(this.props.featureAccess)) {
      return this.getScheduleMenuItemMarkUp()
    } else {
      return ('')
    }
  }

  getOpenShiftsMenuItemMarkUpByPermissions = () => {
    if (hasShiftsAccess(this.props.featureAccess)) {
      return this.getOpenShiftsMenuItemMarkUp()
    } else {
      return ('')
    }
  }

  getNewsMenuItemMarkUpByPermissions = () => {
    if (hasNewsAccess(this.props.featureAccess)) {
      return this.getNewsMenuItemMarkUp()
    } else {
      return ('')
    }
  }

  getScheduleMenuItemMarkUp = () => {
    const { newChatMessages } = this.props
    return (
      <li className="nav-item ">
        <NavLink to="/schedule" className="nav-link" activeclassname="active">
          <div className="d-none d-lg-block position-relative">
            {newChatMessages > 0 && <i className="material-icons fiber_manual_record small">fiber_manual_record</i>}
            {t('my_schedule_menu_item')}
          </div>
          <div className="d-lg-none icon-label" onClick={this.toggleNavbar}>
            {newChatMessages > 0 && <i className="material-icons fiber_manual_record small">fiber_manual_record</i>}
            <i className="material-icons insert_invitation">insert_invitation</i>
            {t('my_schedule_menu_item')}
          </div>
        </NavLink>
      </li>
    )
  }

  getOpenShiftsMenuItemMarkUp = () => {
    const { newOpenShifts } = this.state
    return (
      <li className="nav-item">
        <NavLink to="/open-shifts" className="nav-link" activeclassname="active">
          <div className="d-none d-lg-flex align-items-center position-relative">
            {newOpenShifts && <i className="material-icons fiber_manual_record small">fiber_manual_record</i>}
            {t('open_shifts')}
          </div>
          <div className="d-lg-none icon-label" onClick={this.toggleNavbar}>
            {newOpenShifts && <i className="material-icons fiber_manual_record mobile small">fiber_manual_record</i>}
            <i className="material-icons error_outline">error_outline</i>
            {t('open_shifts')}
          </div>
        </NavLink>
      </li>
    )
  }

  getProfileMenuItemMarkUp = () => {
    // const { newBadges } = this.state
    return (
      <li className="nav-item">
        <NavLink to="/profile" className="nav-link" activeclassname="active">
          <div className="d-none d-lg-flex align-items-center position-relative">
            {t('profile_menu_item')}
            {this.getRideAlongErrorsMarkUp()}
          </div>
          <div className="d-lg-none icon-label" onClick={this.toggleNavbar}>
            <i className="material-icons account_circle">account_circle</i>
            {t('profile_menu_item')}
            {this.getRideAlongErrorsMarkUp()}
          </div>
        </NavLink>
      </li>
    )
  }

  getNotificationMenuItemMarkUp = () => {
    const { newNotifications } = this.state
    return (
      <li className="nav-item">
        <NavLink to="/notifications" className="nav-link" activeclassname="active">
          <div className="d-none d-lg-flex align-items-center position-relative">
            {newNotifications && <i className="material-icons fiber_manual_record small">fiber_manual_record</i>}
            {t('notifications_menu_item')}
          </div>
          <div className="d-lg-none icon-label" onClick={this.toggleNavbar}>
            {newNotifications && <i className="material-icons fiber_manual_record mobile small">fiber_manual_record</i>}
            <i className="material-icons notifications">notifications</i>
            {t('notifications_menu_item')}
          </div>
        </NavLink>
      </li>
    )
  }

  getNewsMenuItemMarkUpByPermissions = () => {
    if (hasNewsAccess(this.props.featureAccess)) {
      return this.getNewsMenuItemMarkUp()
    } else {
      return ('')
    }
  }

  getNewsMenuItemMarkUp = () => {
    const { newPosts } = this.state
    return (
      <li className="nav-item">
        <NavLink to="/news" className="nav-link" activeclassname="active">
          <div className="d-none d-lg-flex align-items-center position-relative">
            {newPosts > 0 && <i className="material-icons fiber_manual_record small">fiber_manual_record</i>}
            {t('news_menu_item')}
          </div>
          <div className="d-lg-none icon-label" onClick={this.toggleNavbar}>
            {newPosts > 0 && <i className="material-icons fiber_manual_record mobile small">fiber_manual_record</i>}
            <i className="material-icons public">public</i>
            {t('news_menu_item')}
          </div>
        </NavLink>
      </li>
    )
  }

  getSentComplimentsMenuItemMarkUp() {
    return (
      <li className="nav-item ">
        <NavLink to="/sent-compliments" className="nav-link" activeclassname="active">
          <div className="d-none d-lg-block">{t('send_compliment_menu_item')}</div>
          <div className="d-lg-none icon-label" onClick={this.toggleNavbar}>
            <i className="material-icons favorite_border">favorite_border</i>
            {t('send_compliment_menu_item')}
          </div>
        </NavLink>
      </li>
    )
  }

  getSentComplimentsMenuItemMarkUpByPermissions = () => {
    if (hasComplimentsAccess(this.props.featureAccess)) {
      return this.getSentComplimentsMenuItemMarkUp()
    } else {
      return ('')
    }
  }

  getContactsMenuItemMarkUp = () => {
    return (
      <li className="nav-item ">
        <NavLink to="/contacts" className="nav-link" activeclassname="active">
          <div className="d-none d-lg-block">{t('contacts')}</div>
          <div className="d-lg-none icon-label" onClick={this.toggleNavbar}>
            <i className="material-icons">contacts</i>
            {t('contacts')}
          </div>
        </NavLink>
      </li>
    )
  }

  getContactsMenuItemMarkUpByPermissions = () => {
    return this.getContactsMenuItemMarkUp()
  }

  getTimePunchesMenuItemMarkUp = () => {
    return (
      <li className="nav-item ">
        <NavLink to="/time-punches" className="nav-link" activeclassname="active">
          <div className="d-none d-lg-block">{t('view_my_hours')}</div>
          <div className="d-lg-none icon-label" onClick={this.toggleNavbar}>
            <i className="material-icons">query_builder</i>
            {t('view_my_hours')}
          </div>
        </NavLink>
      </li>
    )
  }

  getTimePunchesMenuItemMarkUpByPermissions = () => {
    if (hasTimePunchesAccess(this.props.featureAccess)) {
      return this.getTimePunchesMenuItemMarkUp()
    } else {
      return ('')
    }
  }

  getMyTeamMarkUp = () => {
    return (
      <li className="nav-item ">
        <NavLink to="/my-team" className="nav-link" activeclassname="active">
          <div className="d-none d-lg-block">{t('my_team')}</div>
          <div className="d-lg-none icon-label" onClick={this.toggleNavbar}>
            <i className="material-icons">query_builder</i>
            {t('my_team')}
          </div>
        </NavLink>
      </li>
    )
  }

  getMyTeamMarkUpByPermissions = () => {
    if (loggedInUserIsManager()) {
      return this.getMyTeamMarkUp()
    } else {
      return ('')
    }
  }

  getMobileNewNotificationsIconMarkUp = () => {
    const { collapsed, newPosts, newOpenShifts, newBadges, newNotifications } = this.state
    const { newChatMessages } = this.props

    if ( (newOpenShifts || newPosts > 0 || newBadges || newNotifications || newChatMessages > 0) && collapsed) {
      return (<i className="material-icons fiber_manual_record mobile small">fiber_manual_record</i>)
    } 

    return ''
  }

  render() {
    const { collapsed, language, isPageReloaded } = this.state

    if (isPageReloaded) {
      this.setState({
        isPageReloaded: false,
      })
      return <Redirect to="/initializer" />
    }

    const navFlag = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show'
    const btnFlag = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right'

    const { 
      featureAccessPending, 
      monthSchedulePending, 
      eventChatNotificationsPending, 
      publishedWeekPending, 
      unreadNotificationsHistoryPending 
    } = this.props

    if (featureAccessPending || monthSchedulePending || eventChatNotificationsPending || publishedWeekPending || unreadNotificationsHistoryPending) {
      return <Preloader />
    }

    return (
      <div className="navigation-component">
        <nav className="navbar navbar-dark navbar-expand-lg">
          <div className="nav-elements row">
            <button
              onClick={this.toggleNavbar}
              className={`${btnFlag}`}
              type="button"
              data-toggle="collapse"
              data-target="#navbarResponsive"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              { this.getMobileNewNotificationsIconMarkUp() }
              <span className="navbar-toggler-icon" />
              <i className="material-icons clear close-icon">clear</i>
            </button>
            <div className="navbar-brand" href="#">
              <NavLink to="/">
                <img src={rconlogo} alt="logo" />
              </NavLink>
            </div>
          </div>
          <div className={`${navFlag}`} id="navbarResponsive">
            {this.getMenuItemsByPermissions()}
            <ul className="navbar-nav extra-links">
              <li className="nav-item">
                <a href="https://wisintl.com/wisconnect-terms-conditions/"  target="_blank" rel="noopener noreferrer" className="nav-link" activeclassname="active">
                  <div className="d-none d-lg-block">{t('terms_and_conditions')}</div>
                  <div className="d-lg-none" onClick={this.toggleNavbar}>
                    {t('terms_and_conditions')}
                  </div>
                </a>
              </li>
              <li className="nav-item logout-link">
                <a onClick={this.handleLogout} className="nav-link" href="# ">
                  {t('logout_menu_item')}
                </a>
              </li>
              <li className="nav-item d-lg-none navbar-rgis-values">
                <a href="https://www.wisintl.com/about-wisconnect/values" className="nav-link">
                  <div className="rgis-values"></div>
                </a>
              </li>
            </ul>
          </div>
          <LanguageSwitcher defaultLanguage={language} onSelectLanguage={this.onSelectLanguage} />
        </nav>

        <AppDownloadModal />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    newNotificationsCount: state.notificationsReducer.newNotificationsCount,
    newNotificationsCountPending: state.notificationsReducer.newNotificationsCountPending,
    firstName: state.loginReducer.profile.firstName,
    lastName: state.loginReducer.profile.lastName,
    email: state.loginReducer.profile.email,
    badgeId: state.loginReducer.profile.badgeId,
    district: state.loginReducer.profile.organization,
    phone: state.loginReducer.profile.phone,
    language: state.loginReducer.profile.language,
    role: state.loginReducer.profile.role,
    rideAlongErrors: state.transportationProfileReducer.rideAlongErrors,
    rideAlongErrorsPending: state.transportationProfileReducer.rideAlongErrorsPending,

    featureAccess: state.featureAccessReducer.featureAccess,
    featureAccessPending: state.featureAccessReducer.featureAccessPending,

    channels: state.newsReducer.channels,
    channelsPending: state.newsReducer.channelsPending,
    profilePending: state.loginReducer.profilePending,
    promptedForAppDownload: state.mobileDeviceReducer.promptedForAppDownload,
    shifts: state.openShiftsReducer.shifts,
    shiftsPending: state.openShiftsReducer.shiftsPending,
    earnedBadges: state.badgesReducer.earnedBadges,
    earnedBadgesPending: state.badgesReducer.earnedBadgesPending,

    monthSchedulePending: state.scheduleReducer.monthSchedulePending,
    monthScheduleItems: state.scheduleReducer.monthScheduleItems,
    eventChatNotifications: state.scheduleReducer.eventChatNotifications,
    eventChatNotificationsPending: state.scheduleReducer.eventChatNotificationsPending,
    scheduleStartDate: state.scheduleReducer.scheduleStartDate,
    scheduleEndDate: state.scheduleReducer.scheduleEndDate,
    newChatMessages: state.scheduleReducer.newChatMessages,

    personDetailsPending: state.personDetailsReducer.personDetailsPending,
    personDetails: state.personDetailsReducer.personDetails,
    publishedWeek: state.personDetailsReducer.publishedWeek,
    publishedWeekPending: state.personDetailsReducer.publishedWeekPending,
    confirmEventPending: state.confirmEventReducer.confirmEventPending,

    unreadNotificationsHistory: state.notificationsReducer.unreadNotificationsHistory,
    unreadNotificationsHistoryError: state.notificationsReducer.unreadNotificationsHistoryError,
    unreadNotificationsHistoryPending: state.notificationsReducer.unreadNotificationsHistoryPending,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      checkSession: () => checkSession(),
      getUnreadNotificationsCount: (lastCheck) => getUnreadNotificationsCount(lastCheck),
      logout: () => logout(),
      resetState: () => resetState(),

      getChannels: () => getChannels(),
      setPromptedForAppDownload: (promptedForAppDownload) => setPromptedForAppDownload(promptedForAppDownload),
      setShowAppDownloadModal: (showModal) => setShowAppDownloadModal(showModal),
      getShifts: () => getShifts(),
      getEarnedBadges: (recentlyEarnedCount) => getEarnedBadges(recentlyEarnedCount),

      getSchedule: (currentDate, nextWeek) => getSchedule(currentDate, nextWeek),
      getMonthSchedule: (currentDate, nextWeek) => getMonthSchedule(currentDate, nextWeek),
      setScheduleDates: (startDate, endDate) => setScheduleDates(startDate, endDate),
      getEventChatNotificationsByStoreIds: (storeIds) => getEventChatNotificationsByStoreIds(storeIds),

      getPublishedWeek: (organizationId) => getPublishedWeek(organizationId),
      setNewChatMessagesCount: (newChatMessages) => setNewChatMessagesCount(newChatMessages),

      setProfileLocale: (profileLocale) => setProfileLocale(profileLocale),

      getUnreadNotificationsHistory: (fromDateTime) => getUnreadNotificationsHistory(fromDateTime),
    },
    dispatch
  )
}

Navigation.propTypes = {
  getUnreadNotificationsCount: PropTypes.func,
  newNotificationsCount: PropTypes.number,
  newNotificationsCountPending: PropTypes.bool,
  badgeId: PropTypes.string,
  checkSession: PropTypes.func,
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  phone: PropTypes.string,
  role: PropTypes.string,
  resetState: PropTypes.func,
  rideAlongErrors: PropTypes.array,
  rideAlongErrorsPending: PropTypes.bool,

  featureAccess: PropTypes.object,
  featureAccessPending: PropTypes.bool,

  getChannels: PropTypes.func,
  channels: PropTypes.array,
  channelsPending: PropTypes.bool,
  profilePending: PropTypes.bool,
  promptedForAppDownload: PropTypes.bool,
  setPromptedForAppDownload: PropTypes.func,
  setShowAppDownloadModal: PropTypes.func,
  district: PropTypes.string,
  language: PropTypes.string,
  getShifts: PropTypes.func,
  shifts: PropTypes.array,
  shiftsPending: PropTypes.bool,
  getEarnedBadges: PropTypes.func,
  earnedBadges: PropTypes.array,
  earnedBadgesPending: PropTypes.bool,
  history: PropTypes.object,

  getMonthSchedule: PropTypes.func,
  getEventChatNotificationsByStoreIds: PropTypes.func,
  eventChatNotifications: PropTypes.array,
  eventChatNotificationsPending: PropTypes.bool,
  monthScheduleItems: PropTypes.array,
  monthSchedulePending: PropTypes.bool,
  setScheduleDates: PropTypes.func,
  scheduleStartDate: PropTypes.string,
  scheduleEndDate: PropTypes.string,
  getSchedule: PropTypes.func,

  personDetailsPending: PropTypes.bool,
  personDetails: PropTypes.object,
  getPublishedWeek: PropTypes.func,
  publishedWeekPending: PropTypes.bool,
  publishedWeek: PropTypes.object,
  newChatMessages: PropTypes.number,
  setNewChatMessagesCount: PropTypes.func,
  confirmEventPending: PropTypes.bool,

  setProfileLocale: PropTypes.func,

  getUnreadNotificationsHistory: PropTypes.func,
  unreadNotificationsHistory: PropTypes.array,
  unreadNotificationsHistoryError: PropTypes.object,
  unreadNotificationsHistoryPending: PropTypes.bool,
}

const nav = connect(
  mapStateToProps,
  mapDispatchToProps
)(Navigation)

export default withRouter(nav)
