import React from 'react'
import PropTypes from 'prop-types'
import './FlagForReviewDialogForm.scss'
import { t } from '../../utils/i18n';
import { getPreferredLanguage } from '../../components/utils/localStorageUtils'

class FlagForReviewDialogForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      title: '',
      reasons: [],
      selectedReason: {},
      commentsEnabled: false,
      commentText: '',
      error: false
    }

    this.handleCommentsChange = this.handleCommentsChange.bind(this)
    this.submitDialogForm = this.submitDialogForm.bind(this)
    this.cancelDialogForm = this.cancelDialogForm.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { showModal, title, reasons, commentsEnabled, commentText } = this.props
    if (prevProps.showModal !== showModal) {
      this.setState({
        showModal, title, reasons, commentsEnabled, commentText
      })
    }
  }

  componentWillUnmount() {
    this.setState({
      showModal: false,
      title: '',
      reasons: [],
      selectedReason: {},
      commentsEnabled: false,
      commentText: '',
      error: false
    })
  }

  handleReasonChange(reason) {
    this.setState({ selectedReason: reason, error: false })
  }

  handleCommentsChange(evt) {
    this.setState({ commentText: evt.target.value });
  }

  submitDialogForm() {
    const { selectedReason, commentText } = this.state
    if (Object.keys(selectedReason).length) {
      this.props.modalSubmitAction(selectedReason, commentText)
      this.setState({
        showModal: false,
        title: '',
        reasons: [],
        selectedReason: {},
        commentsEnabled: false,
        commentText: '',
        error: false
      })
    }
    else {
      this.setState({ error: true })
    }
  }

  cancelDialogForm(evt) {
    this.setState({
      showModal: false,
      title: '',
      reasons: [],
      selectedReason: {},
      commentsEnabled: false,
      commentText: '',
      error: false
    })
    this.props.modalCancelAction(evt)
  }

  getTranslatedReason(details) {
    const translatedReasonIndex = details.findIndex((detail) => detail.languageName.includes(getPreferredLanguage().toLowerCase()))
    return details[translatedReasonIndex].description
  }

  render() {
    const {
      showModal, title, reasons, commentsEnabled,
      commentText, error, selectedReason,
    } = this.state

    return (
      <div
        className={`action-modal modal fade ${showModal ? 'show' : ''}`}
        onClick={this.props.modalCancelAction}
        id="actionModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="actionModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content" onClick={(evt) => evt.stopPropagation()}>
            <h3 className="modal-title">
              {title}
            </h3>
            <div className="modal-body d-flex flex-column ">
              {reasons.map((reason, index) => {
                return (
                  <label htmlFor={`reason-${index}`} key={index} className="d-flex align-items-center">
                    <input
                      className="mr-2"
                      name="reason"
                      type="radio"
                      id={`reason-${index}`}
                      checked={reason.tag === selectedReason.tag}
                      onChange={() => this.handleReasonChange(reason)}
                    />
                    {this.getTranslatedReason(reason.details)}
                  </label>
                )
              })}

              {commentsEnabled &&
                <label className="d-flex flex-column">
                  {t('comments_optional')}:
                  <textarea
                    value={commentText}
                    onChange={this.handleCommentsChange}
                  />
                </label>
              }
            </div>
            {error && <span className="text-red text-center mb-3">{title}</span>}
            <div className="row justify-content-center">
              <div className="col-md-4 col-6">
                <input
                  type='button'
                  onClick={this.cancelDialogForm}
                  className="btn btn-secondary w-100"
                  value={t('cancel')}
                />
              </div>
              <div className="col-md-4 col-6">
                <input
                  type='button'
                  onClick={this.submitDialogForm}
                  className="btn btn-rgis-blue w-100"
                  value={t('submit')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


FlagForReviewDialogForm.propTypes = {
  showModal: PropTypes.bool,
  title: PropTypes.string,
  reasons: PropTypes.array,
  commentText: PropTypes.string,
  commentsEnabled: PropTypes.bool,
  modalSubmitAction: PropTypes.func,
  modalCancelAction: PropTypes.func,
}

export default FlagForReviewDialogForm
