import { GET_PROFILE_PREF_SUCCESS, GET_PROFILE_PREF_ERROR, GET_PROFILE_PREF_PENDING } from '../actions/actionTypes'

export default function profilePrefReducer(
  state = {
    profilePref: {},
    profilePrefError: '',
    profilePrefPending: true,
  },
  action
) {
  switch (action.type) {
    case GET_PROFILE_PREF_SUCCESS:
      return {
        ...state,
        profilePref: action.payload,
        profilePrefError: '',
        profilePrefPending: false,
      }
    case GET_PROFILE_PREF_ERROR:
      return {
        ...state,
        profilePrefError: action.error,
        profilePrefPending: false,
      }
    case GET_PROFILE_PREF_PENDING:
      return {
        ...state,
        profilePrefPending: true,
      }
    default:
      return state
  }
}
