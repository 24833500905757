import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  getPostComments, addPostComment, removePostComment, removeCommentReply
} from '../../state/actions/postCardActions.js'
import { postModerationItem } from '../../state/actions/moderationActions'
import { getUserReactions } from '../../state/actions/reactionsActions'
import { postListOfProfileImages } from '../../state/actions/profileActions'
import PropTypes from 'prop-types'
import Preloader from '../Preloader'
import './PostCardComments.scss'
import ActionModal from '../ActionModal/ActionModal.js';
import { t } from '../../utils/i18n'
import PostComment from '../PostComment/PostComment.js';
import FlagForReviewDialogForm from '../FlagForReviewDialogForm'
import { getLoggedInPersonId } from '../utils/localStorageUtils'


class PostCardComments extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      postComments: [],
      commentsLoaded: false,
      commentText: '',
      commentBodyLineHeight: 22,
      popup: {
        showPopup: false,
        popupAction1: null,
        popupAction2: null,
      },
      flagCommentId: null,
      showFlagForReviewDialogForm: false,
      flagForReviewObj: {
        title: t('please_select_reason'),
        reasons: this.props.moderationReasons,
        commentText: '',
        commentsEnabled: true,
      },
      showRepliesForComment: {
        commentId: null,
        status: false,
      },
    }

    this.addCommentChange = this.addCommentChange.bind(this)
    this.addCommentClick = this.addCommentClick.bind(this)
    this.removeCommentClick = this.removeCommentClick.bind(this)
    this.removeCommentAction = this.removeCommentAction.bind(this)
    this.cancelRemoveComment = this.cancelRemoveComment.bind(this)
    this.flagCommentClick = this.flagCommentClick.bind(this)
    this.flagForReviewSubmitAction = this.flagForReviewSubmitAction.bind(this)
    this.cancelFlagComment = this.cancelFlagComment.bind(this)
    this.handleShowRepliesForComment = this.handleShowRepliesForComment.bind(this)
  }

  componentDidMount() {
    const { postId } = this.props.postInfo
    this.props.getPostComments(postId)
  }

  componentDidUpdate(prevProps) {
    const _state = this.state
    if (prevProps.postCommentsPending === true && this.props.postCommentsPending === false) {
      let postIds = this.props.postComments.reduce((total, comment) => {
        if ((comment.likesEnabled && comment.likeCount !== 0)
          || (comment.dislikesEnabled && comment.dislikeCount !== 0)) {
          total.push(comment.postId)
        }
        return total
      }, [])
      this.props.getUserReactions(postIds, 'comments')
      const personIds = this.props.postComments.reduce((total, comment) => {
        if (!total.includes(comment.personId)) {
          total.push(comment.personId)
        }
        return total
      }, [])
      this.props.postListOfProfileImages(personIds, 'comments')
    }

    if (prevProps.userReactionsPending === true && this.props.userReactionsPending === false) {
      _state.postComments = this.props.postComments
      _state.commentsLoaded = true
      this.setState(_state)
      setTimeout(() => {
        this.props.onCommentsDataLoaded()
        if (this.props.scrollToPostId) {
          const post = document.querySelector(
            ".restore-" + this.props.scrollToPostId
          )
          if (post) {
            post.scrollIntoView({ block: "center" })
          }
        }
      }, 100)
    }

    if (prevProps.addPostCommentPending === true && this.props.addPostCommentPending === false) {
      _state.postComments = this.props.postComments
      _state.commentText = ''
      this.setState(_state)
    }

    if (prevProps.removePostCommentPending === true && this.props.removePostCommentPending === false) {
      _state.postComments = this.props.postComments
      _state.commentText = ''
      this.setState(_state)
    }

    if (prevProps.editPostCommentPending === true && this.props.editPostCommentPending === false) {
      _state.postComments = this.props.postComments
      _state.commentText = ''
      this.setState(_state)
    }

    document.querySelectorAll("[data-field='comment-body']").forEach((item) => {
      if (item.value.length === 0) {
        item.rows = 2
      } else {
        item.rows = ~~(item.scrollHeight / this.state.commentBodyLineHeight)
      }
    })
  }

  removeCommentClick(typeOfComment, commentId, parentId) {
    const _state = this.state
    _state.popup.showPopup = true
    _state.popup.popupAction1 = () => this.removeCommentAction(typeOfComment, commentId, parentId)
    _state.popup.popupAction2 = this.cancelRemoveComment
    this.setState(_state)
  }

  cancelRemoveComment() {
    const _state = this.state
    _state.popup.showPopup = false
    this.setState(_state)
  }

  removeCommentAction(typeOfComment, commentId, parentId) {
    const _state = this.state
    _state.popup.showPopup = false
    if (typeOfComment === 'reply') {
      this.props.removeCommentReply(commentId, parentId)
    } else {
      this.props.removePostComment(commentId)
    }
    this.setState(_state)
  }

  addCommentChange(evt) {
    evt.preventDefault()
    const _state = this.state;
    _state.commentText = evt.target.value
    this.setState(_state)
  }

  addCommentClick() {
    const { postInfo } = this.props
    const { commentText } = this.state

    const data = {
      channelId: postInfo.channelId,
      parentId: postInfo.postId,
      body: commentText,
    };
    this.props.addPostComment(data)
  }

  flagCommentClick(commentId) {
    const _state = this.state
    _state.showFlagForReviewDialogForm = true
    _state.flagCommentId = commentId
    this.setState(_state)
  }

  flagForReviewSubmitAction(reason, comments) {
    const { flagCommentId } = this.state

    const moderationItem = {
      entityId: flagCommentId,
      moderatingPersonId: getLoggedInPersonId(),
      moderationReason_Tag: reason.tag,
      systemId: 1,
      comments,
    }
    this.props.postModerationItem(moderationItem, 'FLAGGED_COMMENT')
    this.setState({
      showFlagForReviewDialogForm: false,
    })
  }

  cancelFlagComment() {
    this.setState({ showFlagForReviewDialogForm: false })
  }

  handleShowRepliesForComment(commentId, status) {
    this.setState({
      showRepliesForComment: {
        commentId, status
      }
    })
  }

  renderPostCardComments() {
    const { postComments, showRepliesForComment } = this.state
    const { userAccess, postInfo, profileImages, history, commentToNavigateTo } = this.props

    return (
      <div className="col-12 comments-list">
        <h4>{t('comments')}:</h4>
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body">
                {
                  postComments.slice(0).reverse().map((comment, index) =>
                    <div key={`${comment.postId}${index}`} className={`restore-${comment.postId}`}>
                      <PostComment
                        type='comment'
                        index={index}
                        comment={comment}
                        postInfo={postInfo}
                        userAccess={userAccess}
                        nrOfComments={postComments.length}
                        removeCommentClick={this.removeCommentClick}
                        flagCommentClick={this.flagCommentClick}
                        showRepliesForComment={showRepliesForComment}
                        handleShowRepliesForComment={this.handleShowRepliesForComment}
                        userAvatar={profileImages.find(obj => obj.personId === comment.personId)}
                        commentToNavigateTo={commentToNavigateTo}
                        history={history}
                      />
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderAddPostComment() {
    const { commentText } = this.state
    if (!this.props.postInfo.commentsEnabled) {
      return (
        <div className='no-new-comments-msg'>
          {t('no_new_comments_message')}
        </div>
      )
    } else {
      return (
        <form className="col-12 add-comment-container">
          <div className="row align-items-center">
            <div className="col-md-11 col-9">
              <textarea
                data-field="comment-body"
                onChange={this.addCommentChange}
                value={commentText}
                placeholder={t('type_comment')}
                maxLength="2000"
              />
            </div>
            <div className="col-md-1 col-3 d-flex flex-row justify-content-end">
              <input
                type='button'
                onClick={this.addCommentClick}
                className="btn btn-rgis-blue cursor-pointer uppercase"
                value={t('add')}
                disabled={!commentText.length}
              />
            </div>
          </div>
        </form>
      )
    }
  }

  render() {
    const { popup, showFlagForReviewDialogForm, flagForReviewObj } = this.state
    const {
      postCommentsPending, addPostCommentPending,
      removePostCommentPending, editPostCommentPending,
      addCommentReplyPending, removeCommentReplyPending,
      editPostCommentReplyPending, postInfo, postModerationItemPending,
      userReactionsPending, profileImagesPending,
    } = this.props

    if (postCommentsPending || addPostCommentPending || removePostCommentPending
      || editPostCommentPending || addCommentReplyPending || removeCommentReplyPending
      || editPostCommentReplyPending || postModerationItemPending || userReactionsPending
      || profileImagesPending
    ) {
      return (
        <Preloader />
      )
    }

    return (
      <div className="row post-card-comments">
        {postInfo.commentCount !== 0 && this.renderPostCardComments()}

        {this.renderAddPostComment()}

        <ActionModal
          showModal={popup.showPopup}
          modalTitle={t('remove_popup_title')}
          modalText={t('remove_comment_popup_body')}
          showCancelAction={true}
          modalAcceptButtonText={t('ok')}
          modalCancelButtonText={t('confirm_popup_cancel_button')}
          modalAcceptAction={popup.popupAction1}
          modalCancelAction={popup.popupAction2}
        />

        <FlagForReviewDialogForm
          showModal={showFlagForReviewDialogForm}
          title={flagForReviewObj.title}
          reasons={flagForReviewObj.reasons}
          commentText={flagForReviewObj.commentText}
          commentsEnabled={flagForReviewObj.commentsEnabled}
          modalSubmitAction={this.flagForReviewSubmitAction}
          modalCancelAction={this.cancelFlagComment}
        />
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    postCommentsPending: state.postCardReducer.postCommentsPending,
    postComments: state.postCardReducer.postComments,
    addPostCommentPending: state.postCardReducer.addPostCommentPending,
    removePostCommentPending: state.postCardReducer.removePostCommentPending,
    editPostCommentPending: state.postCardReducer.editPostCommentPending,
    addCommentReplyPending: state.postCardReducer.addCommentReplyPending,
    removeCommentReplyPending: state.postCardReducer.removeCommentReplyPending,
    editPostCommentReplyPending: state.postCardReducer.editPostCommentReplyPending,
    postModerationItemPending: state.moderationReducer.postModerationItemPending,
    moderationReasons: state.moderationReducer.moderationReasons,
    userReactionsPending: state.reactionsReducer.userReactionsPending['comments'],
    profileImages: state.profileReducer.profileImages,
    profileImagesPending: state.profileReducer.profileImagesPending['comments'],
    profileImagesError: state.profileReducer.profileImagesError,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getPostComments: (postId) => getPostComments(postId),
      addPostComment: (data) => addPostComment(data),
      removePostComment: (commentId) => removePostComment(commentId),
      removeCommentReply: (commentId, parentId) => removeCommentReply(commentId, parentId),
      postModerationItem: (moderationItem, flaggedItem) => postModerationItem(moderationItem, flaggedItem),
      getUserReactions: (postIds, category) => getUserReactions(postIds, category),
      postListOfProfileImages: (personIds, location) => postListOfProfileImages(personIds, location),
    },
    dispatch
  )
}

PostCardComments.propTypes = {
  getPostComments: PropTypes.func,
  postCommentsPending: PropTypes.bool,
  postComments: PropTypes.array,
  postInfo: PropTypes.object,
  addPostComment: PropTypes.func,
  addPostCommentPending: PropTypes.bool,
  removePostComment: PropTypes.func,
  removePostCommentPending: PropTypes.bool,
  editPostComment: PropTypes.func,
  editPostCommentPending: PropTypes.bool,
  userAccess: PropTypes.object,
  onCommentsDataLoaded: PropTypes.func,
  addCommentReply: PropTypes.func,
  addCommentReplyPending: PropTypes.bool,
  removeCommentReply: PropTypes.func,
  removeCommentReplyPending: PropTypes.bool,
  editPostCommentReply: PropTypes.func,
  editPostCommentReplyPending: PropTypes.bool,
  postModerationItem: PropTypes.func,
  postModerationItemPending: PropTypes.bool,
  moderationReasons: PropTypes.array,
  getUserReactions: PropTypes.func,
  userReactionsPending: PropTypes.bool,
  scrollToPostId: PropTypes.number,
  postListOfProfileImages: PropTypes.func,
  profileImages: PropTypes.array,
  profileImagesPending: PropTypes.bool,
  profileImagesError: PropTypes.object,
  history: PropTypes.object,
  commentToNavigateTo: PropTypes.object,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostCardComments)
