import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import { getEventDetails, getEventAph, getEventMeetSite } from '../../state/actions/eventDetailsActions'
import { getTeamDetailsList } from '../../state/actions/teamDetailsListActions'
import { getHistoryRequests } from '../../state/actions/requestDetailsListActions'
import { getChatItemByScheduledStoreId, resetChatItem } from '../../state/actions/eventChatActions'
import { postListOfProfileImages } from '../../state/actions/profileActions'

import EventDetails from '../../components/EventDetails'

import './EventDetailsContainer.scss'

class EventDetailsContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      // personIds: [],
      scheduledEventId: props.match.params.id,
      scheduledStoreId: '',
    }
  }

  componentDidMount() {
    const _locationState = this.props.location.state
    const _state = this.state
    if (_locationState !== undefined && this.props.location.state.scheduledStoreId !== undefined) {
      if (this.state.scheduledStoreId !== this.props.location.state.scheduledStoreId) {
        _state.scheduledStoreId = this.props.location.state.scheduledStoreId
        this.setState(_state)
      }
    }

    this.props.getEventDetails(this.state.scheduledEventId)
    this.props.getEventAph(this.state.scheduledEventId)
    this.props.getEventMeetSite(this.state.scheduledEventId)
    this.props.getTeamDetailsList(this.state.scheduledEventId)
    this.props.getHistoryRequests(this.state.scheduledEventId)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.eventDetailsPending === true && this.props.eventDetailsPending === false) {

      if (this.props.eventDetails.scheduledStoreId !== '') {
        this.props.getChatItemByScheduledStoreId(this.props.eventDetails.scheduledStoreId)
      }
      this.props.postListOfProfileImages([parseInt(this.props.eventDetails.supervisorPersonId)], 'event')

    }
    if (prevProps.teamDetailsListPending === true && this.props.teamDetailsListPending === false) {

      const personIds = []
      this.props.teamDetailsList.map((item) => {
        personIds.push(item.personId)
        return null
      })
      this.props.postListOfProfileImages(personIds, 'event')
    }
  }

  componentWillUnmount() {
    this.props.resetChatItem()
  }

  render() {
    return (
      <div>
        <EventDetails history={this.props.history} />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    eventDetails: state.eventDetailsReducer.eventDetails,
    eventDetailsPending: state.eventDetailsReducer.eventDetailsPending,
    teamDetailsList: state.teamDetailsListReducer.teamDetailsList,
    teamDetailsListPending: state.teamDetailsListReducer.teamDetailsListPending,
    profileLocale: state.loginReducer.profileLocale,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getEventDetails: scheduledEventId => getEventDetails(scheduledEventId),
      getEventAph: scheduledEventId => getEventAph(scheduledEventId),
      getEventMeetSite: scheduledEventId => getEventMeetSite(scheduledEventId),
      getTeamDetailsList: scheduledEventId => getTeamDetailsList(scheduledEventId),
      getHistoryRequests: scheduledEventId => getHistoryRequests(scheduledEventId),
      postListOfProfileImages: (personIds, location) => postListOfProfileImages(personIds, location),
      getChatItemByScheduledStoreId: scheduledStoreId => getChatItemByScheduledStoreId(scheduledStoreId),
      resetChatItem: () => resetChatItem()
    },
    dispatch
  )
}

EventDetailsContainer.propTypes = {
  match: PropTypes.object,
  getEventDetails: PropTypes.func,
  getEventAph: PropTypes.func,
  getEventMeetSite: PropTypes.func,
  getTeamDetailsList: PropTypes.func,
  getHistoryRequests: PropTypes.func,
  getChatItemByScheduledStoreId: PropTypes.func,
  resetChatItem: PropTypes.func,
  postListOfProfileImages: PropTypes.func,
  teamDetailsList: PropTypes.array,
  teamDetailsListPending: PropTypes.bool,
  eventDetails: PropTypes.object,
  eventDetailsPending: PropTypes.bool,
  history: PropTypes.object,
  location: PropTypes.object,
  profileLocale: PropTypes.string,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventDetailsContainer)
