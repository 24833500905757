import axios from 'axios'
import apiConfig from '../../config/api-config'
import * as cancelTokenUtil from './utils/cancelTokenUtil'

export function getOrganization(organizationId) {
  const url = apiConfig.GET_ORGANIZATION.replace('[organizationId]', organizationId)
  const options = cancelTokenUtil.abortAndGetCancelTokenOptions('GET_ORGANIZATION')
  return axios.get(`${url}`, options)
}

export function searchPeopleInOrganization(organizationId, searchString) {
  const url = apiConfig.SEARCH_PEOPLE_IN_ORGANIZATION.replace("[organizationId]", organizationId).replace("[searchString]", searchString)
  const options = cancelTokenUtil.abortAndGetCancelTokenOptions('SEARCH_PEOPLE_IN_ORGANIZATION')
  return axios.get(`${url}`, options)
}

export function getAllPeopleInOrganization(organizationId) {
  const url = apiConfig.GET_ALL_PEOPLE_IN_ORGANIZATION.replace("[organizationId]", organizationId)
  const options = cancelTokenUtil.abortAndGetCancelTokenOptions('GET_ALL_PEOPLE_IN_ORGANIZATION')
  return axios.get(`${url}`, options)
}