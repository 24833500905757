import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'

// Actions
import { getTimePunches } from '../../state/actions/timePunchesActions'

// Common
import Datepicker from '../../components/DatePicker'
import Preloader from '../../components/Preloader'

// Utils
import { formatDateNotForDisplay, getEndOfWeek, getMonthAndDay, getDayOfWeek, getYear, getDay, isToday, getStartDate } from '../../components/utils/datetimeUtils'

import { t } from '../../utils/i18n'

import TimePunchItem from '../../components/TimePunchItem'
import ActionModal from '../../components/ActionModal'
import './TimePunchesContainer.scss'
import { hasTimePunchesAccess } from '../../components/utils/featureAccessUtils'

import { formatNumberDecimal } from '../../components/utils/numberUtils'

class TimePunchesContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      modal: {
        showModal: false,
        modalTitle: '',
        modalText: '',
        showCancelAction: false,
        modalAcceptButtonText: '',
        modalCancelButtonText: '',
        modalAcceptAction: null,
        modalCancelAction: null,
      },
    }

    this.renderWeekPeriod = this.renderWeekPeriod.bind(this)
    this.renderUserHours = this.renderUserHours.bind(this)

    this.renderTimePunchesItems = this.renderTimePunchesItems.bind(this)
    this.renderTimePunchesItem = this.renderTimePunchesItem.bind(this)
    this.renderDate = this.renderDate.bind(this)

    this.showMessageModal = this.showMessageModal.bind(this)
    this.cancelModalAction = this.cancelModalAction.bind(this)
    this.returnDates = this.returnDates.bind(this)
  }

  componentDidMount() {
    const { state } = this.props.location
    if (state && state.timePunchError) {
      this.showMessageModal(t('time_punch_not_found'))
    }

    if (!this.props.timePunchesStartDate) {
      const startDate = getStartDate()
      this.props.getTimePunches(formatDateNotForDisplay(startDate), getEndOfWeek(startDate))
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.featureAccessPending === true && this.props.featureAccessPending === false) {
      if (!hasTimePunchesAccess(this.props.featureAccess)) {
        return this.props.history.push('/profile')
      }
    }
  }

  returnDates(selectedDates, datesObjectForApi) {
    if (this.props.timePunchesStartDate !== datesObjectForApi.currentDate ||
      this.props.timePunchesEndDate !== datesObjectForApi.nextWeek) {
      this.props.getTimePunches(datesObjectForApi.currentDate, datesObjectForApi.nextWeek)
    }
  }

  showMessageModal(message) {
    this.setState({
      modal: {
        showModal: true,
        modalTitle: '',
        modalText: message,
        showCancelAction: false,
        modalAcceptButtonText: t('ok'),
        modalCancelButtonText: '',
        modalAcceptAction: this.cancelModalAction,
        modalCancelAction: null,
      }
    })
  }

  cancelModalAction() {
    this.setState({
      modal: {
        showModal: false,
        modalTitle: '',
        modalText: '',
        showCancelAction: false,
        modalAcceptButtonText: '',
        modalCancelButtonText: '',
        modalAcceptAction: null,
        modalCancelAction: null,
      }
    })
  }

  renderDate(date) {
    const _date = moment(date.split(' ')[0])
    return (
      <div className="row">
        <div className={`col schedule-item-date ${isToday(_date) ? 'bold' : ''}`}>
          <div>{`${getDayOfWeek(_date)} | ${getMonthAndDay(_date)}`}</div>
        </div>
      </div>
    )
  }

  renderTimePunchesItems(eventsByDate, index) {
    // Exit if empty
    if (!eventsByDate || eventsByDate.length === 0) {
      return ''
    }
    const firstEvent = eventsByDate[0]
    return (
      <div key={index}>
        {this.renderDate(firstEvent.inventoryDate)}
        {eventsByDate.map(this.renderTimePunchesItem)}
      </div>
    )
  }

  renderTimePunchesItem(timePunchInfo) {
    return (
      <TimePunchItem 
        key={timePunchInfo.timePunchId} 
        timePunchInfo={timePunchInfo} 
        showMessageModal={this.showMessageModal} 
        history={this.props.history}
      />
    )
  }


  renderUserHours() {
    const { weekTotalWorkTime, weekTotalTravelTime } = this.props
    return (
      <div className="user-hours my-3">
        <div className="row">
          <div className="col-12 d-flex align-items-baseline my-1">
            <b>{t('week_total_work_time').toUpperCase()}:</b>
            <h5 className="ml-2 mr-1">{formatNumberDecimal(weekTotalWorkTime)} {t('hours_abbreviation')}</h5>
          </div>
          <div className="col-12 d-flex align-items-baseline my-1">
            <b>{t('week_total_travel_time').toUpperCase()}:</b>
            <h5 className="ml-2 mr-1">{formatNumberDecimal(weekTotalTravelTime)} {t('hours_abbreviation')}</h5>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col col-md-10 col-xl-6 d-flex">
            <div className="mt-1"><i className="material-icons mr-2">info</i></div>
            <div>{t('user_hours_disclaimer')}</div>
          </div>
        </div>
      </div>
    )
  }

  renderWeekPeriod() {
    const { timePunchesStartDate, timePunchesEndDate, } = this.props
    return (
      <div className="row">
        <div className="col timepunches-date-interval">
          {getMonthAndDay(timePunchesStartDate)} - {getDay(timePunchesEndDate,)}
          &nbsp;{getYear(timePunchesStartDate)}
        </div>
      </div>
    )
  }

  render() {
    const {
      timePunchesItems,
      timePunchesItemsGroupedByDate,
      timePunchesPending,
      timePunchesStartDate,
      timePunchesEndDate,
      featureAccessPending,
    } = this.props

    const { modal } = this.state

    if (timePunchesPending || featureAccessPending) {
      return <Preloader />
    }

    if (timePunchesItemsGroupedByDate.length === 0) {
      return (
        <div>
          <Datepicker
            returnDates={this.returnDates}
            scheduleItems={timePunchesItems}
            scheduleStartDate={timePunchesStartDate}
            scheduleEndDate={timePunchesEndDate}
            publishedWeek={{ endDate: moment().format() }}
            title={t('my_hours')}
          />
          <div className="time-punches-content">
            {this.renderWeekPeriod()}
            <div className="mt-2">{t('no_time_punches_available')}</div>
          </div>
        </div>
      )
    }

    return (
      <div>
        <Datepicker
          returnDates={this.returnDates}
          scheduleItems={timePunchesItems}
          scheduleStartDate={timePunchesStartDate}
          scheduleEndDate={timePunchesEndDate}
          publishedWeek={{ endDate: moment().format() }}
          title={t('my_hours')}
        />

        <div className="time-punches-content">
          {this.renderWeekPeriod()}

          {timePunchesItemsGroupedByDate.length === 0 ?
            <div className="mt-2">{t('no_time_punches_available')}</div>
            :
            <>
              {this.renderUserHours()}

              <div className="row">
                <div className="col col-md-10 col-xl-6">
                  {timePunchesItemsGroupedByDate.map(this.renderTimePunchesItems)}
                </div>
              </div>
            </>
          }
        </div>
        <ActionModal
          {...modal}
        />
        
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    timePunchesStartDate: state.timePunchesReducer.timePunchesStartDate,
    timePunchesEndDate: state.timePunchesReducer.timePunchesEndDate,

    timePunchesItems: state.timePunchesReducer.timePunchesItems,
    timePunchesItemsGroupedByDate: state.timePunchesReducer.timePunchesItemsGroupedByDate,
    timePunchesPending: state.timePunchesReducer.timePunchesPending,
    timePunchesError: state.timePunchesReducer.timePunchesError,

    weekTotalWorkTime: state.timePunchesReducer.weekTotalWorkTime,
    weekTotalTravelTime: state.timePunchesReducer.weekTotalTravelTime,

    featureAccess: state.featureAccessReducer.featureAccess,
    featureAccessPending: state.featureAccessReducer.featureAccessPending,

    profileLocale: state.loginReducer.profileLocale,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getTimePunches: (currentDate, nextWeekDate) => getTimePunches(currentDate, nextWeekDate),
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimePunchesContainer)

TimePunchesContainer.propTypes = {
  getTimePunches: PropTypes.func,

  timePunchesStartDate: PropTypes.string,
  timePunchesEndDate: PropTypes.string,

  timePunchesItems: PropTypes.array,
  timePunchesItemsGroupedByDate: PropTypes.array,

  timePunchesPending: PropTypes.bool,
  timePunchesError: PropTypes.object,

  weekTotalWorkTime: PropTypes.number,
  weekTotalTravelTime: PropTypes.number,

  featureAccess: PropTypes.object,
  featureAccessPending: PropTypes.bool,

  history: PropTypes.object,
  location: PropTypes.object,
  state: PropTypes.object,

  profileLocale: PropTypes.string,
}
