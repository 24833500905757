import {
  GET_SEARCH_ALL_PEOPLE_SUCCESS,
  GET_SEARCH_ALL_PEOPLE_ERROR,
  GET_SEARCH_ALL_PEOPLE_PENDING,
  GET_PERSON_DETAILS_LIST_SUCCESS,
  GET_PERSON_DETAILS_LIST_ERROR,
  GET_PERSON_DETAILS_LIST_PENDING,
  GET_PERSON_DETAILS_SUCCESS,
  GET_PERSON_DETAILS_ERROR,
  GET_PERSON_DETAILS_PENDING,
  GET_PUBLISHED_WEEK_SUCCESS,
  GET_PUBLISHED_WEEK_ERROR,
  GET_PUBLISHED_WEEK_PENDING,
  GET_SCHEDULING_PREFERENCES_SUCCESS,
  GET_SCHEDULING_PREFERENCES_ERROR,
  GET_SCHEDULING_PREFERENCES_PENDING,
  EDITING_SCHEDULING_PREFERENCES,
  NOT_EDITING_SCHEDULING_PREFERENCES,
  SAVE_SCHEDULING_PREFERENCES,
  PATCH_SCHEDULING_PREFERENCES_ERROR,
  PATCH_SCHEDULING_PREFERENCES_PENDING,
  PATCH_SCHEDULING_PREFERENCES_SUCCESS
} from '../actions/actionTypes'
import { cloneDeep } from 'lodash'

export default function personDetailsReducer(
  state = {
    searchAllPersonsList: [],
    searchAllPersonsListError: {},
    searchAllPersonsListPending: false,
    personDetailsList: [],
    personDetailsListError: {},
    personDetailsListPending: false,
    personDetails: {},
    personDetailsError: {},
    personDetailsPending: false,
    publishedWeek: {},
    publishedWeekError: {},
    publishedWeekPending: false,
    schedulingPreferences: {},
    backupSchedulingPreferences: {},
    schedulingPreferencesError: '',
    schedulingPreferencesPending: false,
    editingSchedulingPreferences: false,
    patchSchedulingPreferencesPending: false,
    patchSchedulingPreferencesError: ''
  },
  action
) {
  switch (action.type) {
    case GET_SEARCH_ALL_PEOPLE_SUCCESS:
      return {
        ...state,
        searchAllPersonsList: action.payload,
        searchAllPersonsListError: {},
      }
    case GET_SEARCH_ALL_PEOPLE_ERROR:
      return {
        ...state,
        searchAllPersonsListError: action.error,
      }
    case GET_SEARCH_ALL_PEOPLE_PENDING:
      return {
        ...state,
        searchAllPersonsListPending: action.payload,
      }
    case GET_PERSON_DETAILS_LIST_SUCCESS:
      return {
        ...state,
        personDetailsList: action.payload,
        personDetailsListError: {},
        personDetailsListPending: false,
      }
    case GET_PERSON_DETAILS_LIST_ERROR:
      return {
        ...state,
        personDetailsListError: action.error,
      }
    case GET_PERSON_DETAILS_LIST_PENDING:
      return {
        ...state,
        personDetailsListPending: action.payload,
      }
    case GET_PERSON_DETAILS_SUCCESS:
      return {
        ...state,
        personDetails: action.payload,
        personDetailsError: {},
      }
    case GET_PERSON_DETAILS_ERROR:
      return {
        ...state,
        personDetailsError: action.error,
      }
    case GET_PERSON_DETAILS_PENDING:
      return {
        ...state,
        personDetailsPending: action.pending,
      }
    case GET_PUBLISHED_WEEK_SUCCESS:
      return {
        ...state,
        publishedWeek: action.payload,
        publishedWeekError: {},
      }
    case GET_PUBLISHED_WEEK_ERROR:
      return {
        ...state,
        publishedWeekError: action.error,
      }
    case GET_PUBLISHED_WEEK_PENDING:
      return {
        ...state,
        publishedWeekPending: action.pending,
      }
    case GET_SCHEDULING_PREFERENCES_SUCCESS:
      return {
        ...state,
        schedulingPreferences: action.payload ? action.payload : {},
        backupSchedulingPreferences: action.payload ? cloneDeep(action.payload) : {},
        schedulingPreferencesError: '',
      }
    case GET_SCHEDULING_PREFERENCES_ERROR:
      return {
        ...state,
        schedulingPreferencesError: action.error,
      }
    case GET_SCHEDULING_PREFERENCES_PENDING:
      return {
        ...state,
        schedulingPreferencesPending: action.pending,
      }
    case EDITING_SCHEDULING_PREFERENCES:
      return {
        ...state,
        editingSchedulingPreferences: true,
      }
    case NOT_EDITING_SCHEDULING_PREFERENCES:
      return {
        ...state,
        editingSchedulingPreferences: false,
      }
    case SAVE_SCHEDULING_PREFERENCES:
      return {
        ...state,
        schedulingPreferences: action.payload.schedulingPreferences,
      }
    case PATCH_SCHEDULING_PREFERENCES_ERROR:
      return {
        ...state,
        patchSchedulingPreferencesError: action.error,
      }
    case PATCH_SCHEDULING_PREFERENCES_PENDING:
      return {
        ...state,
        patchSchedulingPreferencesPending: false,
      }
    case PATCH_SCHEDULING_PREFERENCES_SUCCESS:
      return {
        ...state,
        schedulingPreferences: action.payload,
        backupSchedulingPreferences: cloneDeep(action.payload),
        schedulingPreferencesError: '',
        schedulingPreferencesPending: false,
      }
    default:
      return state
  }
}
