import * as types from './actionTypes'
import services from '../services'

export function getContacts() {
  return dispatch => {
    dispatch(getContactsActionPending(true))
    services.contacts
      .fetchContacts()
      .then(response => {
        dispatch(getContactsActionSuccess(response))
        dispatch(getContactsActionPending(false))
      })
      .catch(error => {
        dispatch(getContactsActionError(error))
        dispatch(getContactsActionPending(false))
      })
  }
}


const getContactsActionSuccess = response => {
  return {
    type: types.GET_CONTACTS_SUCCESS,
    payload: response.data,
  }
}
const getContactsActionError = response => {
  return {
    type: types.GET_CONTACTS_ERROR,
    error: response,
  }
}
const getContactsActionPending = status => {
  return {
    type: types.GET_CONTACTS_PENDING,
    status,
  }
}