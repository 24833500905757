import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import './ComplimentsItem.scss'
import PostUserAvatar from '../PostUserAvatar'
import { t } from '../../utils/i18n'
import { formatDate, formatTime } from '../utils/datetimeUtils'
import { obfuscate } from '../utils/obfuscateUtils'
import { getLoggedInPersonId } from '../utils/localStorageUtils'
import { isDefined } from '../utils/nullSafeCheckUtils'
import complimentActionsImg from '../../styles/images/ellipsis-v-solid.svg'
import FlagForReviewDialogForm from '../FlagForReviewDialogForm'
import { postModerationItemBySystem } from '../../state/actions/moderationActions'
import { COMPLIMENTS_MODERATION_SYSTEM_ID } from '../../utils/constants'
import Preloader from '../Preloader'

class ComplimentsItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      complimentActionDropdown: false,
      showFlagForReviewDialogForm: false,
    }

    this.complimentActionsFlagClick = this.complimentActionsFlagClick.bind(this)
    this.complimentActionsClick = this.complimentActionsClick.bind(this)
    this.submitModalAction = this.submitModalAction.bind(this)
    this.cancelModalAction = this.cancelModalAction.bind(this)
  }

  componentDidMount() {
  }

  eventDetailsAreUnknown = () => {
    return (this.eventDetailsAreValid() && this.props.eventDetails.bannerName === t('unknown_event'))
  }

  eventDetailsAreValid = () => {
    return isDefined(this.props.eventDetails)
  }

  renderEventDetailMarkUp = () => {
    if (!this.eventDetailsAreValid()) {
      return ''
    } else if (this.eventDetailsAreUnknown()) {
      return t('unknown_event')
    } else {
      return (
        <div className='row'>
          <div className='col'>
            <div className='row'>
              <div className='col compliment-details'>{t('event_info')}: {this.getEventDetailsName()} | {this.props.eventDetails.storeNumber}</div>
            </div>
          </div>
        </div>)
    }
  }

  complimentActionsFlagClick(evt) {
    evt.stopPropagation()
    
    this.setState({
      complimentActionDropdown: false,
      showFlagForReviewDialogForm: true
    })
  }

  complimentActionsClick(evt) {
    evt.stopPropagation()

    this.setState({
      complimentActionDropdown: !this.state.complimentActionDropdown
    })
  }

  getEventDetailsName = () => {
    return this.props.eventDetails.bannerName === null || this.props.eventDetails.bannerName === undefined
      ? this.props.eventDetails.customerName
      : this.props.eventDetails.bannerName
  }

  submitModalAction(reason, comments) {
    const moderationItem = {
      entityId: this.props.compliment.complimentId,
      moderatingPersonId: getLoggedInPersonId(),
      moderationReason_Tag: reason.tag,
      systemId: COMPLIMENTS_MODERATION_SYSTEM_ID,
      comments: comments,
    }

    this.props.postModerationItemBySystem(moderationItem, COMPLIMENTS_MODERATION_SYSTEM_ID)

    this.setState({
      showFlagForReviewDialogForm: false,
      complimentActionDropdown: false,
    })
  }

  cancelModalAction() {
    this.setState({ 
      showFlagForReviewDialogForm: false, 
      complimentActionDropdown: false,
    })
  }

  render() {
    const { compliment, complimentSenderName, userAvatarUrl, history, postModerationItemPending } = this.props
    const { complimentActionDropdown, showFlagForReviewDialogForm } = this.state

    if (postModerationItemPending) {
      return <Preloader />
    }

    return (
      <div className="row">
        <div className="col-12 mt-1">{formatDate(compliment.createdDate)} {formatTime(compliment.createdDate)}</div>
        <div className="col">
          <div className="card">
            <div className="card-body">
              <div className="section">
                <div className="row personal-info">
                  <div className="col d-flex">
                    <PostUserAvatar
                      personName={complimentSenderName}
                      userAvatarUrl={userAvatarUrl}
                      personId={compliment.senderPersonId}
                      redirectToAvatarPage={false}
                      history={history}
                    />
                    <div className="col d-flex flex-column justify-content-center">
                      <div
                        className="name cursor-pointer"
                        onClick={() => history.push(`public-profile/${obfuscate(compliment.senderPersonId)}`)}
                      >
                        {complimentSenderName}
                      </div>
                    </div>
                  </div>

                  {
                    compliment.isFlagged &&
                    <i className="indicator-icon material-icons flag">flag</i>
                  }

                  {
                    !compliment.isFlagged &&
                    <div className="dropleft compliment-actions">
                      <div className='compliment-actions-overflow' onClick={this.complimentActionsClick} >
                        <img src={complimentActionsImg} alt="Compliment actions" />
                      </div>
                      <div className={`dropdown-menu ${complimentActionDropdown ? 'show' : ''}`} aria-labelledby="dropdownMenu2">
                        <span className='compliment-actions-button' onClick={this.complimentActionsFlagClick}>{t('flag_for_review')}</span>
                      </div>                
                    </div>
                  }

                </div>
              </div>
              <div className="post-body">
                {compliment.comment}
              </div>
              {this.renderEventDetailMarkUp()}
            </div>
          </div>
        </div>

        <FlagForReviewDialogForm
          showModal={showFlagForReviewDialogForm}
          title={t('please_select_reason')}
          reasons={this.props.moderationReasons}
          commentText=''
          commentsEnabled={true}
          modalSubmitAction={this.submitModalAction}
          modalCancelAction={this.cancelModalAction}
        />
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    postModerationItemPending: state.moderationReducer.postModerationItemPending,
    postModerationItemError: state.moderationReducer.postModerationItemError,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      postModerationItemBySystem: (moderationItem, moderationSystemId) => postModerationItemBySystem(moderationItem, moderationSystemId),
    },
    dispatch
  )
}

ComplimentsItem.propTypes = {
  compliment: PropTypes.object,
  complimentSenderName: PropTypes.string,
  eventDetails: PropTypes.object,
  userAvatarUrl: PropTypes.string,
  history: PropTypes.object,
  moderationReasons: PropTypes.array,
  postModerationItemBySystem: PropTypes.func,
  postModerationItemPending: PropTypes.bool,
  postModerationItemError: PropTypes.object,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComplimentsItem)
