import { CONFIRM_EVENT, CONFIRM_EVENT_ERROR, CONFIRM_EVENT_PENDING } from '../actions/actionTypes'

export default function confirmEventReducer(
  state = {
    confirmEventSuccess: false,
    confirmEventPending: false,
    confirmEventError: false,
    confirmEventsIds: [],
  },
  action
) {
  switch (action.type) {
    case CONFIRM_EVENT:
      return {
        ...state,
        confirmEventSuccess: true,
        confirmEventPending: false,
        confirmEventError: false,
        confirmEventIds: action.payload.eventIds,
      }
    case CONFIRM_EVENT_ERROR:
      return {
        ...state,
        confirmEventError: true,
      }
    case CONFIRM_EVENT_PENDING:
      return {
        ...state,
        confirmEventPending: action.payload,
      }
    default:
      return state
  }
}
