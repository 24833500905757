import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { t } from '../../utils/i18n'
import moment from 'moment'

import './NotificationDetailsContainer.scss'
import Preloader from '../../components/Preloader'
import PostUserAvatar from '../../components/PostUserAvatar'

import { 
  patchMarkNotificationsAsRead, 
  getChannelIdByPostId, 
  getOrganizationNameByPersonId,
  patchNotificationsHistoryStatusAsRead
} from '../../state/actions/notificationsActions'
import { getPostInfo } from '../../state/actions/postCardActions'
import { getCompliment } from '../../state/actions/complimentsActions'
import { getEventDetailsList } from '../../state/actions/eventDetailsActions'
import { getPersonDetails } from '../../state/actions/personDetailsActions'
import { postListOfProfileImages } from '../../state/actions/profileActions'

import { formatPhoneNumber } from '../../components/utils/phoneUtils'
import { obfuscate } from '../../components/utils/obfuscateUtils'
import { 
  formatDate, 
  formatTime 
} from '../../components/utils/datetimeUtils'
import { 
  getNotificationPublishedWeekDate, 
  getUnplannedTravelContent
 } from '../../components/utils/notificationUtils'
import { 
  isDefined,
  isDefinedAndNotEmpty, 
  nullSafeCheckIsTrue 
} from '../../components/utils/nullSafeCheckUtils'

import {
  INTEGRITY_VALUE,
  PRIDE_VALUE,
  SERVICE_VALUE,
  TEAMWORK_VALUE,
  INNOVATION_VALUE,
  RESULTS_VALUE,
} from '../../utils/constants'
import {
  NewBadgeEarned,
  NewBadgeUnearned,
  NewComplimentReceived,
  EventPersonAdded,
  EventPersonRemoved,
  PerformanceTracker
} from '../../utils/notificationTypeContants'

class NotificationDetailsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      notificationData: {
        notificationType: ''
      },
      senderName: '',
      senderUserAvatar: {},
      senderPersonId: null,
      compliment: {
        comment: '',
        complimentCoreValues: [],
        eventId: null,
      },
      event: {
        bannerName: '',
        customerName: '',
      },
      reviewerName: '',
      reviewerId: '',
    }

    this.returnToNotifications = this.returnToNotifications.bind(this)
    this.returnCoreValueIconMarkUp = this.returnCoreValueIconMarkUp.bind(this)
    this.onViewPostClick = this.onViewPostClick.bind(this)
    this.onViewNewsPostClick = this.onViewNewsPostClick.bind(this)
  }

  componentDidMount() {
    const { location } = this.props
    if (!location.state || !location.state.notificationData) {
      this.props.history.push('/notifications')
      return
    }    

    if (location.state.notificationData.notificationType === "NEW_RECEIVED_COMPLIMENT") {
      const { complimentId } = location.state.notificationData.notificationDetails
      this.props.getCompliment(complimentId)
    }

    if (location.state.notificationData.notificationType === "WATCHED_CHANNEL_NEW_POST") {      
      this.props.getPostInfo(location.state.notificationData.notificationDetails.postId)
    }

    if (location.state.notificationData.notificationType === "EVENT_SCHED_CHANGE_REQ_ACCEPT"
      || location.state.notificationData.notificationType === "EVENT_SCHED_CHANGE_REQ_DENIED") {
      const { reviewerId } = location.state.notificationData.changeRequest.scheduleChangeRequest
      this.props.getPersonDetails(reviewerId)
      this.props.postListOfProfileImages([reviewerId], 'notification-details')
      this.setState({ reviewerId: reviewerId })
    }

    this.handleComponentDidMountForNewComplimentReceived()

    this.checkOldNotificationsTypeToMarkAsRead(location.state.notificationData)
    this.checkNewNotificationsTypeToMarkAsRead(location.state.notificationData)

    this.setState({
      notificationData: location.state.notificationData,
      senderName: location.state.senderName,
      senderUserAvatar: location.state.senderUserAvatar,
      senderPersonId: location.state.senderPersonId,
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.complimentPending === true && this.props.complimentPending === false) {
      if (this.props.compliment.eventId !== null) {
        this.props.getEventDetailsList([this.props.compliment.eventId])
      }
      this.setState({
        compliment: this.props.compliment
      })
    }

    if (prevProps.eventDetailsListPending === true && this.props.eventDetailsListPending === false) {
      this.setState({
        event: {
          bannerName: this.props.eventDetailsList[0].bannerName,
          customerName: this.props.eventDetailsList[0].customerName,
        }
      })
    }

    if (prevProps.personDetailsPending === true && this.props.personDetailsPending === false) {
      this.setState({
        reviewerName: `${this.props.personDetails.firstName} ${this.props.personDetails.lastName}`,
      })
    }
  }

  handleComponentDidMountForNewComplimentReceived = () => {
    const { location } = this.props

    if (location.state.notificationData.notificationType === NewComplimentReceived) {
      const complimentId = location.state.notificationData.modelData.ComplimentId
      this.props.getCompliment(complimentId)
    }
  }

  isNewNotificationType = (notificationType) => {
    return (notificationType === NewBadgeEarned 
      || notificationType === NewBadgeUnearned 
      || notificationType === NewComplimentReceived
      || notificationType === EventPersonAdded
      || notificationType === EventPersonRemoved
      || notificationType === PerformanceTracker)
  }

  checkOldNotificationsTypeToMarkAsRead = (notificationData) => {
    if (this.isNewNotificationType(notificationData.notificationType)) { return; }

    const SENT_NOTIFICATION_STATUS = 'SENT'
    if (notificationData.status === SENT_NOTIFICATION_STATUS) {
      this.props.patchMarkNotificationsAsRead([notificationData.id])
    }
  }

  checkNewNotificationsTypeToMarkAsRead = (notificationData) => {
    if (!this.isNewNotificationType(notificationData.notificationType)
      || nullSafeCheckIsTrue(notificationData.isRead)) { 
        return; 
    }

    this.props.patchNotificationsHistoryStatusAsRead(notificationData.id)
  }

  getChangeReason(itemData) {
    if (!itemData.changeRequest) {
      return t('not_unavailable')
    }
    switch (itemData.changeRequest.scheduleChangeRequest.reasonCode) {
      case 'MED_EMERGENCY':
        return t('event_reason1')
      case 'FAM_EMERGENCY':
        return t('event_reason2')
      case 'TRANSPORT_ISSUE':
        return t('event_reason3')
      case 'OTHER':
        return t('event_reason4')
      default:
        return t('event_reason4')
    }
  }

  getChangeType(itemData) {
    if (!itemData.changeRequest) {
      return t('not_unavailable')
    }
    switch (itemData.changeRequest.scheduleChangeRequest.typeCode) {
      case 'EVENT_CANNOT_ATTEND':
        return t('event_type1')
      case 'EVENT_ATTEND_LATE':
        return t('event_type2')
      case 'EVENT_LEAVE_EARLY':
        return t('event_type3')
      default:
        return t('event_type3')
    }
  }

  onViewNewsPostClick() {
    const { postInfo } = this.props

    this.props.history.push({
      pathname: `/news/channel/${postInfo.channelId}/post/${postInfo.postId}`,
      state: {
        postInfo: postInfo,
        isNewPost: false,
      }
    });
  }

  onViewPostClick() {
    const { notificationData } = this.state
    this.props.history.push({
      pathname: `/news/channel/${this.props.channelId}/post/${notificationData.notificationDetails.watchedPost.postId}`,
      state:{
        scrollToPostId: notificationData.notificationDetails.updatingPost.postId,
      }
    })
  }

  renderWatchChannelNotificationContent = (itemData) => {
    const userAvatar = this.props.profileImages.find(obj => obj.personId === itemData.notificationDetails.personId)

    return (
      <div>
        <div>
          <PostUserAvatar
              personName={itemData.notificationDetails.personName}
              userAvatarUrl={userAvatar !== undefined ? userAvatar.url : ''}
              personId={itemData.notificationDetails.personId}
              redirectToAvatarPage={false}
              history={this.props.history}
            /> 
            <div className='avatar-name-container'><span className='avatar-name'>{itemData.notificationDetails.personName}</span><br />{t('created_new_post')}</div>
        </div>
        <hr />
        <div className='post-title'>{itemData.notificationDetails.postTitle}</div>
        <div className='post-content'>{itemData.notificationDetails.postBody}</div>
        <hr />
        <div><div className='' onClick={this.onViewNewsPostClick}>{t('go_to_post')}</div></div>
      </div>
    )
  }

  getAbsenceCodeDescription = (itemData) => {
    if (isDefinedAndNotEmpty(itemData.changeRequest.scheduleChangeRequest.absenceCode)) {
    var absenceCode = itemData.changeRequest.scheduleChangeRequest.absenceCode;
    
    let localizedAbsenceCodeDescription = t(absenceCode.toLowerCase())
    if (isDefinedAndNotEmpty(localizedAbsenceCodeDescription)) {
      return localizedAbsenceCodeDescription
    } 
      
      return absenceCode
    }
  }

  getNotificationDetailsContent(itemData) {
    let _notificationType = this.getNotificationType(itemData)
    
    let eventDate = '';
    if (itemData.event){
      if (
        _notificationType === 'event_person_added' ||
        _notificationType === 'event_person_removed' ||
        _notificationType === 'event_person_meet_site_changed'){ //some garbage doesn't come in as UTC.  did this ever work or did someone change garbage data?  doesn't matter, IJASFD
        eventDate = formatDate(moment(itemData.event.date).local());
      }
      else{
        eventDate = formatDate(moment.utc(itemData.event.date).local())
      }
    }

    if (!itemData.notificationType) {
      return t('not_available')
    }
    switch (itemData.notificationType) {
      case 'WATCHED_CHANNEL_NEW_POST':
        return this.renderWatchChannelNotificationContent(itemData)
      case 'EVENT_SCHED_PUBLISHED':
      case 'EVENT_PERSON_REMOVED':
        return (
          <div>
            {
              itemData.notificationType === 'EVENT_SCHED_PUBLISHED' &&
              <p>{t('schedule_published', [getNotificationPublishedWeekDate(itemData.notificationDetails)])}</p>
            }
            {
              itemData.notificationType === 'EVENT_PERSON_REMOVED' &&
              <div>
                <p>{t('no_action_required', [eventDate])}</p>
                <br />
                <p>{itemData.event.title}</p>
                <p>{itemData.event.address}</p>
                <p>{itemData.event.city}, {itemData.event.state} {itemData.event.zipcode}</p>
              </div>
            }
            <br />
            <input
              type='button'
              onClick={() => this.props.history.push('/schedule')}
              className="btn btn-rgis-blue cursor-pointer uppercase"
              value={t('navigate_to_schedule')}
            />
          </div>
        )
      case 'EVENT_PERSON_ADDED':
      case 'EVENT_PERSON_MEET_SITE_CHANGED':
      case 'EVENT_SCHED_CHANGE_REQ_DENIED':
      case 'EVENT_SCHED_CHANGE_REQ_ACCEPT':
      case 'EVENT_SCHED_CHANGE_REQ_SUBMIT':
      case 'EVENT_SCHED_DATE_TIME_CHANGED': {
        const userAvatar = this.props.profileImages.find(obj => obj.personId === this.state.reviewerId)
        return (
          <div>
            {
              itemData.notificationType === 'EVENT_PERSON_ADDED' &&
              <div>
                <p>{t('please_confirm_event')}</p>
                <br />
              </div>
            }
            {
              itemData.notificationType === 'EVENT_SCHED_CHANGE_REQ_SUBMIT' &&
              <div>
                <p>{t('person_request_event_change', [itemData.changeRequest.fullName])}</p>
                <br />
              </div>
            }
            {
              itemData.notificationType === 'EVENT_SCHED_DATE_TIME_CHANGED' &&
              <div>
                <p>{t('confirm_change')}:</p>
                <br />
              </div>
            }
            {
              (itemData.notificationType === 'EVENT_SCHED_CHANGE_REQ_DENIED' ||
                itemData.notificationType === 'EVENT_SCHED_CHANGE_REQ_ACCEPT') &&
              <div className="d-flex align-items-center mb-3">
                <PostUserAvatar
                  personName={this.state.reviewerName}
                  userAvatarUrl={userAvatar !== undefined ? userAvatar.url : ''}
                  personId={this.state.reviewerId}
                  redirectToAvatarPage={false}
                  history={this.props.history}
                />
                <div className="col d-flex flex-column justify-content-center">
                  <div
                    className="name cursor-pointer"
                    onClick={() => this.props.history.push(`/public-profile/${obfuscate(this.state.reviewerId)}`)}
                  >
                    {this.state.reviewerName}
                  </div>
                </div>
                <br />
              </div>
            }
            <p>{t('date')}: {eventDate}</p>
            <br />
            <p>{itemData.event.title}</p>
            <p>{itemData.event.address}</p>
            <p>{itemData.event.city}, {itemData.event.state} {itemData.event.zipcode}</p>
            <br />
            <p>{t('meet_site')}: {itemData.event.meetSite}</p>
            <p>{t('meet_time')}: {formatTime(itemData.event.meetTime)}</p>
            <p>{t('start_time')}: {formatTime(itemData.event.startTime)}</p>
            {
              (itemData.notificationType === 'EVENT_PERSON_MEET_SITE_CHANGED' ||
                itemData.notificationType === 'EVENT_SCHED_DATE_TIME_CHANGED') &&
              <div>
                <br />
                <p>{t('changed_to')}:</p>
                {
                  itemData.notificationType === 'EVENT_PERSON_MEET_SITE_CHANGED' &&
                  <p>{t('meet_site')}: {itemData.notificationDetails.newMeetSite}</p>
                }
                <p>{t('meet_time')}: {formatTime(itemData.notificationDetails.newMeetTime)}</p>
              </div>
            }
            {
              itemData.notificationType === 'EVENT_SCHED_CHANGE_REQ_DENIED' &&
              <div>
                <br />
                <p>{t('changed_type')}: {this.getChangeType(itemData)}</p>
                <p>{t('status')}: {itemData.changeRequest.scheduleChangeRequest.status}</p>
                <p>{t('manager_notes')}: {itemData.changeRequest.scheduleChangeRequest.reviewerNotes}</p>
              </div>
            }
            {
              itemData.notificationType === 'EVENT_SCHED_CHANGE_REQ_ACCEPT' &&
               <div>
                 <br />
                 <p>{t('changed_type')}: {this.getChangeType(itemData)}</p>
                 <p>{t('status')}: {itemData.changeRequest.scheduleChangeRequest.status}</p>

                 { this.getAbsenceCodeDescription(itemData) !== undefined &&
                  <p>{t('designated_absence_code', [this.getAbsenceCodeDescription(itemData)])}</p>
                 }

                 <p>{t('manager_notes')}: {itemData.changeRequest.scheduleChangeRequest.reviewerNotes}</p>
               </div>
             }
            {
              itemData.notificationType === 'EVENT_SCHED_CHANGE_REQ_SUBMIT' &&
              <div>
                <br />
                <p>{t('changed_type')}: {this.getChangeType(itemData)}</p>
                <p>{t('reason')}: {this.getChangeReason(itemData)}</p>
                <p>{t('notes')}: {itemData.changeRequest.scheduleChangeRequest.notes}</p>
              </div>
            }
            <br />
            <input
              type='button'
              onClick={() => this.props.history.push(`/schedule/${itemData.event.id}`)}
              className="btn btn-rgis-blue cursor-pointer uppercase"
              value={t('view_event_details')}
            />
          </div>
        )
      }
      case 'NEW_RECEIVED_COMPLIMENT': {
        const { senderName, compliment, event, senderUserAvatar, senderPersonId } = this.state
        return (
          <div>
            <div className="row">
              <div className="col d-flex">
                <PostUserAvatar
                  personName={senderName}
                  userAvatarUrl={senderUserAvatar !== undefined ? senderUserAvatar.url : ''}
                  personId={senderPersonId}
                  redirectToAvatarPage={false}
                  history={this.props.history}
                />
                <div className="col d-flex flex-column justify-content-center">
                  <div
                    className="name cursor-pointer"
                    onClick={() => this.props.history.push(`/public-profile/${obfuscate(senderPersonId)}`)}
                  >
                    {senderName}
                  </div>
                </div>
              </div>
            </div>
            <br />
            {compliment.eventId !== null &&
              <div className="name">
                <h5>{t('event')}</h5>
                {event.bannerName || event.customerName}

              </div>
            }
            {compliment.eventId !== null ? <br /> : ''}

            {<h5>{t('core_values')}</h5>}
            {this.returnCoreValueIconMarkUp(compliment.complimentCoreValues)}
            <br />
            {compliment.comment.length ? <h5>{t('comment')}</h5> : ''}
            <p>{compliment.comment}</p>
            <br />
            <h6><i>{t('keep_up_the_great_work')}</i></h6>
          </div>
        )
      }
      case 'TIMESHEET_NOT_SUBMITTED':
        return (
          <div>
            <div className="mb-3">{t(`${_notificationType}_text`, [formatDate(itemData.notificationDetails.timesheetDate)])}</div>
          </div>
        )
      case 'MANAGER_TIMESHEETS_NOT_SUBMITTED':
        return (
          <div>
            <div className="mb-3">{t(`${_notificationType}_text`, [itemData.notificationDetails.teamMemberDetails.length])}</div>
            <table className={`table ${window.innerWidth <= 992 ? 'table-sm' : ''} text-center mt-4`}>
              <thead>
                <tr>
                  <th scope="col">{t('name')}</th>
                  <th scope="col">{t('date_of_the_timesheet')}</th>
                  <th scope="col">{t('phone_number')}</th>
                </tr>
              </thead>
              <tbody>
                {itemData.notificationDetails.teamMemberDetails.map((teamMember, index) =>
                  <tr key={`${teamMember.phoneNumber}${index}`}>
                    <td>{teamMember.personName}</td>
                    <td>{formatDate(teamMember.timesheetDate)}</td>
                    <td>
                      <a href={`tel:${formatPhoneNumber(teamMember.phoneNumber)}`}>
                        {formatPhoneNumber(teamMember.phoneNumber)}
                      </a>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )

      case 'UNSUBMITTED_EVENT_TRAVEL':
        return t(`${_notificationType}_text`, [itemData.notificationDetails.bannerName || itemData.notificationDetails.customerName, formatDate(itemData.notificationDetails.date)])
      case 'NewBadgeEarned':
      case 'NewBadgeUnearned':  
        return (
          <div>
            <div className="row">
              <div className="col">{itemData.title}</div>
            </div>
            <div className="row">
              <div className="col">{itemData.body}</div>
            </div>
          </div>
        )
      case 'UNPLANNED_TRAVEL':
        return getUnplannedTravelContent(itemData)
      case NewComplimentReceived:
        return this.getNewComplimentReceivedContent(itemData)      
      case EventPersonAdded:
        return this.getEventPersonAddedContent(itemData)
      case EventPersonRemoved:
        return this.getNewEventPersonRemovedContent(itemData)
      case PerformanceTracker:
        return this.getPerformanceTrackerContent(itemData)
      case 'USER_GENERIC_MESSAGE':
        return this.getGenericContent(itemData);
      default:
        return t(`${_notificationType}_text`)
    }
  }

  getEventPersonAddedContent = (itemData) => {
    return (
      <div>
        <div>
          <p>{t('please_confirm_event')}</p>
          <br />
        </div>
        <p>{t('date')}: {formatDate(itemData.modelData.EventDateTime)} {formatTime(itemData.modelData.EventDateTime)}</p>
        <br />
        <p>{itemData.modelData.CustomerName} {itemData.modelData.StoreNumber}</p>
        <p>{itemData.modelData.EventAddress}</p>
        <p>{itemData.modelData.EventCity}, {itemData.modelData.EventState} {itemData.modelData.EventZipCode}</p>
        <br />
        <p>{t('meet_site')}: {itemData.modelData.EventMeetSite}</p>
        {this.getEventPersonAddedMeetSiteAddressContent(itemData)}
        <p>{t('meet_time')}: {formatTime(itemData.modelData.EventMeetSiteDateTime)}</p>
        <p>{t('start_time')}: {formatTime(itemData.modelData.EventDateTime)}</p>
        <br />
        <input
          type='button'
          onClick={() => this.props.history.push(`/schedule/${itemData.modelData.EventId}`)}
          className="btn btn-rgis-blue cursor-pointer uppercase"
          value={t('view_event_details')}
        />
      </div>
    )
  }

  getEventPersonAddedMeetSiteAddressContent = (itemData) => {
    if (isDefinedAndNotEmpty(itemData.modelData.EventMeetSiteAddress)
       || isDefinedAndNotEmpty(itemData.modelData.EventMeetSiteCity)
       || isDefinedAndNotEmpty(itemData.modelData.EventMeetSiteState)
       || isDefinedAndNotEmpty(itemData.modelData.EventMeetSiteZipCode)) {
      return (
        <div>
          <p>{itemData.modelData.EventMeetSiteAddress}</p>
          <p>{itemData.modelData.EventMeetSiteCity}, {itemData.modelData.EventMeetSiteState} {itemData.modelData.EventMeetSiteZipCode}</p> 
        </div>
      )
    }

    return ''
  }

  isNullOrWhitespace = (input) => {
    if (typeof input === 'undefined' || input == null) {
      return true;
    }

    return input.replace(/\s/g, '').length < 1;
  }

  getBackgroundColorForValue = (d) => {
    const backgroundBlack = "black";
    const backgroundRed = "red";
    const backgroundYellow = "yellow";
    const backgroundGreen = "green";
    // Above 167 => black
    if (d > 167) {
        return backgroundBlack;
    }

    // From 120 to 167 => green
    if (d >= 120) {
        return backgroundGreen;
    }

    // From 76 to 120 => yellow
    if (d >= 76) {
        return backgroundYellow;
    }

    // Below 76 => red
    return backgroundRed;
  }

  roundValue = (value, isPercentage, numDecimals) => {
      let displayValue = '';

      let pow = Math.pow(10, numDecimals);

      if (!isNaN(value)) {
        displayValue = `${Math.ceil(value * pow) / pow}`
      }
      else {
        displayValue = `${value}`
      }

      return isPercentage ? `${displayValue}%` : displayValue;
  }

  getGlobalStatRowHtml = (headerName, value, isPercentage, backgroundColor) => {
    const backgroundBlack = "black";
    const backgroundRed = "red";
    const backgroundYellow = "yellow";
    const backgroundWhite = "white";
    const backgroundGreen = "green";
    
    const blackCssClassName = "black";
    const redCssClassName = "red";
    const yellowCssClassName = "yellow";
    const whiteCssClassName = "white";
    const greenCssClassName = "green";

    let backgroundColorCssClass = ''
    let foregroundColorCssClass = ''

    if (backgroundColor !== null) {
      switch (backgroundColor) {
        case backgroundBlack:
          backgroundColorCssClass = blackCssClassName;
          foregroundColorCssClass = whiteCssClassName;
          break;

      case backgroundRed:
          backgroundColorCssClass = redCssClassName;
          break;

      case backgroundYellow:
          backgroundColorCssClass = yellowCssClassName;
          break;

      case backgroundGreen:
          backgroundColorCssClass = greenCssClassName;
          break;
      }
    }

    let styleObject = {}

    if (!this.isNullOrWhitespace(backgroundColorCssClass)) {
      styleObject["background-color"] = backgroundColorCssClass;
    }

    if (!this.isNullOrWhitespace(foregroundColorCssClass)) {
      styleObject["color"] = foregroundColorCssClass;
    }

    styleObject["text-align"] = "right";

    let displayValue = ''

    if (isPercentage) {
      displayValue = this.roundValue(value, isPercentage, 1)
    } else {
      displayValue = `${value}`
    }

    return (
      <tr>
          <td>{headerName}</td>
          <td style={styleObject}>{displayValue}</td>
      </tr>
    );
  }

  getGlobalStatTableHtml = (itemData) => {
    return (
        <div align="left">
            <p>{itemData.CustomerName}</p>
            <br/>
            <p>{itemData.StoreName}</p>
            <br/>
            <table>
                <tbody>
                    {this.getGlobalStatRowHtml(t("PerformanceTracker_YourAphColon"), this.roundValue(itemData.YourAph, false, 0))}
                    {this.getGlobalStatRowHtml(t("PerformanceTracker_Rank1AphColon"), this.roundValue(itemData.RankOneAph, false, 0))}
                    {this.getGlobalStatRowHtml(t("PerformanceTracker_RankColon"), `${itemData.InventoryRank} / ${itemData.InventoryTotalAuditors}`)}
                    {this.getGlobalStatRowHtml(t("PerformanceTracker_DowntimeColon"), itemData.Downtime, true)}
                    {this.getGlobalStatRowHtml(t("PerformanceTracker_AccuracyColon"), itemData.Accuracy, true)}
                    {this.getGlobalStatRowHtml(t("PerformanceTracker_ScoreColon"), itemData.Score, true, this.getBackgroundColorForValue(itemData.Score))}
                </tbody>
            </table>
            <br/>
        </div>
    )
  }

  getCategoryTableRowScoreCssClassName = (rowData) => {
    const backgroundBlack = "black";
    const backgroundRed = "red";
    const backgroundYellow = "yellow";
    const backgroundWhite = "white";
    const backgroundGreen = "green";

    const blackBackgroundCssClass = "tg-black-bg";
    const redBackgroundCssClass = "tg-red-bg";
    const yellowBackgroundCssClass = "tg-yellow-bg";
    const greenBackgroundCssClass = "tg-green-bg";

    switch (this.getBackgroundColorForValue(rowData.Score)) {
        case backgroundBlack:
            return blackBackgroundCssClass;

        case backgroundRed:
            return redBackgroundCssClass;

        case backgroundYellow:
            return yellowBackgroundCssClass;

        case backgroundGreen:
            return greenBackgroundCssClass;

        default:
            return redBackgroundCssClass;
    }
  }

  getCategoryTableRowHtml = (rowData) => {
    return (
        <tr>
            <td class="tg-clear-bg tg-force-left">{rowData.Name}</td>
            <td class="tg-clear-bg tg-force-left">{this.roundValue(rowData.TargetAph, false, 0)}</td>
            <td class="tg-clear-bg tg-force-left">{this.roundValue(rowData.YourAph, false, 0)} ({rowData.Rank} / {rowData.TotalAuditors})</td>
            <td class="tg-clear-bg tg-force-left">{this.roundValue(rowData.RankOneAph, false, 0)}</td>
            <td class="tg-clear-bg tg-force-left">{this.roundValue(rowData.DownTime, true, 1)}</td>
            <td class={this.getCategoryTableRowScoreCssClassName(rowData)}>{this.roundValue(rowData.Score, true, 1)}</td>
        </tr>
    )
  }

  getCategoryTableRowsHtml = (tableData) => {
    let d = tableData.DepartmentData
    return (
      d.map((row) => this.getCategoryTableRowHtml(row))
    )
  }

  getCategoryTableHeaderHtml = () => {
    return (
        <tr>
          <th class="tg-gray-bg tg-force-left">{t("PerformanceTracker_Department")}</th>
          <th class="tg-gray-bg tg-force-left">{t("PerformanceTracker_TargetAph")}</th>
          <th class="tg-gray-bg tg-force-left">{t("PerformanceTracker_YourAph")}</th>
          <th class="tg-gray-bg tg-force-left">{t("PerformanceTracker_Rank1Aph")}</th>
          <th class="tg-gray-bg tg-force-left">{t("PerformanceTracker_Downtime")}</th>
          <th class="tg-gray-bg tg-force-left">{t("PerformanceTracker_Score")}</th>
        </tr>
    )
  }

  getCategoryTableHtml = (tableData) => {
    return (
        <div>
            <h3>{tableData.Name}</h3>
            <table class="tg">
                <thead>
                    {this.getCategoryTableHeaderHtml()}
                </thead>
                <tbody>
                    {this.getCategoryTableRowsHtml(tableData)}
                </tbody>
            </table>
            <br/>
        </div>
    )
  }

  getCategoryTablesHtml = (itemData) => {
    let i = itemData.CategoryData

    return (
      i.map((row) => this.getCategoryTableHtml(row))
    )
  }

  getPerformanceTrackerContent = (itemData) => {
    return (
      <table class="module table-top-lvl" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%">
          <tbody>
              <tr>
                  <td class="table-top-lvl-td" height="100%" valign="top" bgcolor="">
                      <div align="left">
                          <div align="left">
                              <div align="left">
                                  <span>
                                      <h2>{t("PerformanceTracker_PerformanceTrackerResults")}</h2>
                                  </span>
                              </div>
                              {this.getGlobalStatTableHtml(itemData.modelData)}
                              {this.getCategoryTablesHtml(itemData.modelData)}
                          </div>
                      </div>
                  </td>
              </tr>
          </tbody>
      </table>
    )
  }

  getGenericContent = (itemData) => {
    return (
      <div>{itemData.notificationDetails.body}</div>
    )
  }

  getNewEventPersonRemovedContent = (itemData) => {
    const formattedEventDateTime = formatDate(itemData.modelData.EventDateTime) + ' ' + formatTime(itemData.modelData.EventDateTime)
    return (
      <div>
        <div>
          <p>{t('no_action_required', [formattedEventDateTime])}</p>
          <br />
          <p>{itemData.modelData.StoreNumber} {itemData.modelData.CustomerName}</p> 
          <p>{itemData.modelData.EventAddress}</p>
          <p>{itemData.modelData.EventCity}, {itemData.modelData.EventState} {itemData.modelData.EventZipCode}</p>
        </div>
        <br />
        <input
          type='button'
          onClick={() => this.props.history.push('/schedule')}
          className="btn btn-rgis-blue cursor-pointer uppercase"
          value={t('navigate_to_schedule')}
        />
      </div>
    )
  }

  getNewComplimentReceivedContent = (itemData) => {
    const { compliment, event, senderUserAvatar } = this.state

    const senderName = itemData.modelData.SenderName
    const senderId = itemData.modelData.SenderId

    return (
      <div>
        <div className="row">
          <div className="col d-flex">
            <PostUserAvatar
              personName={senderName}
              userAvatarUrl={isDefined(senderUserAvatar) ? senderUserAvatar.url : ''}
              personId={senderId}
              redirectToAvatarPage={false}
              history={this.props.history}
            />
            <div className="col d-flex flex-column justify-content-center">
              <div
                className="name cursor-pointer"
                onClick={() => this.props.history.push(`/public-profile/${obfuscate(senderId)}`)}
              >
                {senderName}
              </div>
            </div>
          </div>
        </div>
        <br />
        {compliment.eventId !== null &&
          <div className="name">
            <h5>{t('event')}</h5>
            {event.bannerName || event.customerName}

          </div>
        }
        {compliment.eventId !== null ? <br /> : ''}

        {<h5>{t('core_values')}</h5>}
        {this.returnCoreValueIconMarkUp(compliment.complimentCoreValues)}
        <br />
        {compliment.comment.length ? <h5>{t('comment')}</h5> : ''}
        <p>{compliment.comment}</p>
        <br />
        <h6><i>{t('keep_up_the_great_work')}</i></h6>
      </div>
    )
  }

  getNotificationTime(notificationSentDateToFormat) {
    const today = moment().clone().startOf('day');
    const yesterday = moment().clone().subtract(1, 'days').startOf('day');
    const notificationSentDate = moment.utc(notificationSentDateToFormat).local()
    if (notificationSentDate.isSame(today, 'd')) return formatTime(notificationSentDate)
    if (notificationSentDate.isSame(yesterday, 'd')) return t('yesterday')
    return formatDate(notificationSentDate)
  }

  returnToNotifications() {
    const { notificationData } = this.state

    this.props.history.push({
      pathname: `/notifications`,
      state: {
        scrollToNotificationId: notificationData.id,
      }
    })
  }

  getNotificationType = (notificationData) => {
    let notificationType = ''
    if (notificationData !== undefined && notificationData !== null && notificationData.notificationType !== undefined && notificationData.notificationType !== null) {
      notificationType = notificationData.notificationType.toLowerCase()
    }
    return notificationType
  }

  getNotificationTitle(itemData) {
    if (!itemData.notificationType) return t('not_available')

    const _notificationType = this.getNotificationType(itemData)
    if (_notificationType.toLowerCase() === 'user_generic_message'){
      return itemData.notificationDetails.title;
    }

    return t(_notificationType)
  }

  returnCoreValueIconMarkUp(complimentCoreValues) {
    let coreValues = []

    for (let x = 0; x < complimentCoreValues.length; x++) {
      coreValues.push(complimentCoreValues[x].coreValue)
    }

    return (
      <div className="row core-value-icons">
        <div className="col-2 col-md-auto">
          <div
            className={coreValues.indexOf(SERVICE_VALUE) === -1 ? 'core-value-service' : 'core-value-service-fill'}
            title={t('service')}
            />
        </div>
        <div className="col-2 col-md-auto">
          <div
            className={coreValues.indexOf(PRIDE_VALUE) === -1 ? 'core-value-pride' : 'core-value-pride-fill'}
            title={t('pride')}
            />
        </div>
        <div className="col-2 col-md-auto">
          <div
            className={coreValues.indexOf(INTEGRITY_VALUE) === -1 ? 'core-value-integrity' : 'core-value-integrity-fill'}
            title={t('integrity')}
          />
        </div>
        <div className="col-2 col-md-auto">
          <div
          className={coreValues.indexOf(RESULTS_VALUE) === -1 ? 'core-value-results' : 'core-value-results-fill'}
          title={t('results')}
          />
        </div>
        <div className="col-2 col-md-auto">
          <div
            className={coreValues.indexOf(INNOVATION_VALUE) === -1 ? 'core-value-innovation' : 'core-value-innovation-fill'}
            title={t('innovation')}
            />
        </div>
        <div className="col-2 col-md-auto">
          <div
            className={coreValues.indexOf(TEAMWORK_VALUE) === -1 ? 'core-value-teamwork' : 'core-value-teamwork-fill'}
            title={t('teamwork')}
          />
        </div>
        <div className="col-2 col-md-7"></div>
      </div>
    )
  }

  render() {
    const {
      markNotificationsAsReadPending, 
      complimentPending, 
      eventDetailsListPending,
      channelIdPending, 
      organizationNamePending, 
      personDetailsPending,
      profileImagesPending, 
      postCardPending, 
      patchNotificationsHistoryStatusAsReadPending
    } = this.props
    const { notificationData } = this.state

    if (markNotificationsAsReadPending 
      || complimentPending 
      || eventDetailsListPending
      || channelIdPending 
      || organizationNamePending 
      || personDetailsPending
      || profileImagesPending 
      || postCardPending 
      || patchNotificationsHistoryStatusAsReadPending
    ) {
      return <Preloader />
    }

    return (
      <div className="notification-details-container">
        <div className="row">
          <div className="col notification-details-header">{t('details')}</div>
          <div className="col-2 d-flex align-items-center justify-content-end">
            <i
              onClick={this.returnToNotifications}
              className="material-icons arrow_back cursor-pointer other-avail-icon"
            >
              arrow_back
            </i>
          </div>
        </div>
        <div className="notification-details">
          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-body">
                  <div className="section d-flex align-items-center justify-content-between">
                    <h4>{this.getNotificationTitle(notificationData)}</h4>
                    <p>{this.getNotificationTime(notificationData.sentDate)}</p>
                  </div>
                  <hr />
                  <div className="section">
                    {this.getNotificationDetailsContent(notificationData)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    channelId: state.notificationsReducer.channelId,
    channelIdPending: state.notificationsReducer.channelIdPending,
    channelIdError: state.notificationsReducer.channelIdError,
    organizationName: state.notificationsReducer.organizationName,
    organizationNamePending: state.notificationsReducer.organizationNamePending,
    organizationNameError: state.notificationsReducer.organizationNameError,
    markNotificationsAsReadPending: state.notificationsReducer.markNotificationsAsReadPending,
    markNotificationsAsReadError: state.notificationsReducer.markNotificationsAsReadError,
    compliment: state.complimentsReducer.compliment,
    complimentPending: state.complimentsReducer.complimentPending,
    eventDetailsList: state.eventDetailsReducer.eventDetailsList,
    eventDetailsListPending: state.eventDetailsReducer.eventDetailsListPending,
    personDetails: state.personDetailsReducer.personDetails,
    personDetailsPending: state.personDetailsReducer.personDetailsPending,
    profileImages: state.profileReducer.profileImages,
    profileImagesPending: state.profileReducer.profileImagesPending['notification-details'],
    profileImagesError: state.profileReducer.profileImagesError,
    postInfo: state.postCardReducer.postInfo,
    postCardPending: state.postCardReducer.postCardPending,
    postCardError: state.postCardReducer.postCardError,
    profileLocale: state.loginReducer.profileLocale,
    patchNotificationsHistoryStatusAsReadPending: state.notificationsReducer.patchNotificationsHistoryStatusAsReadPending,
    patchNotificationsHistoryStatusAsReadError: state.notificationsReducer.patchNotificationsHistoryStatusAsReadError,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      patchMarkNotificationsAsRead: (notificationIds) => patchMarkNotificationsAsRead(notificationIds),
      getChannelIdByPostId: (postId) => getChannelIdByPostId(postId),
      getOrganizationNameByPersonId: (personId) => getOrganizationNameByPersonId(personId),
      getCompliment: (complimentId) => getCompliment(complimentId),
      getEventDetailsList: (scheduledEventId) => getEventDetailsList(scheduledEventId),
      getPersonDetails: (personId) => getPersonDetails(personId),
      postListOfProfileImages: (personIds, location) => postListOfProfileImages(personIds, location),
      getPostInfo: (postId) => getPostInfo(postId),
      patchNotificationsHistoryStatusAsRead: (notificationId) => patchNotificationsHistoryStatusAsRead(notificationId),
    },
    dispatch
  )
}

NotificationDetailsContainer.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  patchMarkNotificationsAsRead: PropTypes.func,
  getChannelIdByPostId: PropTypes.func,
  getOrganizationNameByPersonId: PropTypes.func,
  markNotificationsAsReadError: PropTypes.object,
  markNotificationsAsReadPending: PropTypes.bool,
  channelId: PropTypes.number,
  channelIdPending: PropTypes.bool,
  channelIdError: PropTypes.object,
  organizationName: PropTypes.string,
  organizationNamePending: PropTypes.bool,
  organizationNameError: PropTypes.object,
  getCompliment: PropTypes.func,
  compliment: PropTypes.object,
  complimentPending: PropTypes.bool,
  getEventDetailsList: PropTypes.func,
  eventDetailsList: PropTypes.array,
  eventDetailsListPending: PropTypes.bool,
  getPersonDetails: PropTypes.func,
  personDetails: PropTypes.object,
  personDetailsPending: PropTypes.bool,
  postListOfProfileImages: PropTypes.func,
  profileImages: PropTypes.array,
  profileImagesPending: PropTypes.bool,
  profileImagesError: PropTypes.object,
  getPostInfo: PropTypes.func,
  postInfo: PropTypes.object,
  postCardPending: PropTypes.bool,
  postCardError: PropTypes.object,
  profileLocale: PropTypes.string,
  patchNotificationsHistoryStatusAsRead: PropTypes.func,
  patchNotificationsHistoryStatusAsReadPending: PropTypes.bool,
  patchNotificationsHistoryStatusAsReadError: PropTypes.object,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationDetailsContainer)
