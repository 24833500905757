import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { cloneDeep } from 'lodash'

import {
  getSentCompliments,
  deleteCompliment,
} from '../../../src/state/actions/complimentsActions'
import { getPersonDetailsList } from '../../state/actions/personDetailsActions'
import { getEventDetailsList } from '../../state/actions/eventDetailsActions'
import { postListOfProfileImages } from '../../state/actions/profileActions'

import { nullSafeCheckIsTrue } from '../../components/utils/nullSafeCheckUtils'
import { 
  hasComplimentsAccess, 
} from '../../components/utils/featureAccessUtils'

import SentComplimentsItem from '../../components/SentCompliments/SentComplimentsItem'
import Preloader from '../../components/Preloader'
import ActionModal from '../../components/ActionModal'
import './SentComplimentsContainer.scss'
import { t } from '../../utils/i18n'

class SentComplimentsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sentCompliments: [],
      sendComplimentModal: false,
      hasLoadedPeople: false,
      modal: {
        showModal: false,
        modalTitle: '',
        modalText: '',
        showCancelAction: false,
        modalAcceptButtonText: '',
        modalCancelButtonText: '',
        modalAcceptAction: null,
        modalCancelAction: null,
      },
    }

    this.editComplimentClick = this.editComplimentClick.bind(this)
    this.removeComplimentClick = this.removeComplimentClick.bind(this)
    this.removeComplimentAction = this.removeComplimentAction.bind(this)
    this.cancelRemoveCompliment = this.cancelRemoveCompliment.bind((this))
  }

  componentDidMount() {
    if (this.props.sentCompliments.length) {
      this.setState({ sentCompliments: this.props.sentCompliments })
    } else {
      this.props.getSentCompliments()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sentComplimentsPending === true && this.props.sentComplimentsPending === false) {
      this.setState({ sentCompliments: this.props.sentCompliments })
      if (this.props.sentCompliments.length) {
        const personIds = this.getRecipientPersonIds()
        this.props.getPersonDetailsList(personIds)
        this.props.postListOfProfileImages(personIds, 'sent-compliments')
        this.props.getEventDetailsList(this.getEventIds())
      }
    }

    if (prevProps.featureAccessPending === true && this.props.featureAccessPending === false) {
      this.checkAccess()
    }

    if (prevProps.deleteComplimentPending === true && this.props.deleteComplimentPending === false) {
      this.setState({ sentCompliments: this.props.sentCompliments })
    }
  }

  getSentComplimentsItemMarkUp = (compliment, userAvatar) => (
    <SentComplimentsItem
      key={compliment.complimentId}
      compliment={compliment}
      complimentReceiverName={this.getPersonNameByPersonId(compliment.recipients[0])}
      userAvatar={userAvatar}
      eventDetails={this.getEventDetailsFromList(compliment)}
      editComplimentClick={this.editComplimentClick}
      removeComplimentClick={this.removeComplimentClick}
      history={this.props.history}
    />
  )

  getEventIds = () => {
    let eventIds = []

    for (let i = 0; i < this.props.sentCompliments.length; i++) {
      if (this.props.sentCompliments[i].eventId === undefined || this.props.sentCompliments === null || this.props.sentCompliments[i].eventId === 0) { continue; }

      if (Number.isInteger(this.props.sentCompliments[i].eventId) && eventIds.indexOf(this.props.sentCompliments[i].eventId) === -1) {
        eventIds.push(this.props.sentCompliments[i].eventId)
      }
    }

    return eventIds
  }

  getPersonNameByPersonId = (personId) => {
    if (!this.state.hasLoadedPeople) {
      const personIds = this.getRecipientPersonIds()
      this.props.getPersonDetailsList(personIds)

      this.state.hasLoadedPeople = true;
    }

    for (let i = 0; i < this.props.personDetailsList.length; i++) {
      if (this.props.personDetailsList[i].personId === personId) {
        return this.props.personDetailsList[i].firstName + ' ' + this.props.personDetailsList[i].lastName
      }
    }
    return ''
  }

  getEventDetailsFromList = (compliment) => {
    if (compliment.eventId === undefined || compliment.eventId === null) { return null; }

    for (let i = 0; i < this.props.eventDetailsList.length; i++) {
      if (this.props.eventDetailsList[i].scheduledEventId === compliment.eventId) {
        return this.props.eventDetailsList[i]
      }
    }

    return null
  }

  getRecipientPersonIds = () => {
    let recipientPersonIds = []
    let i, j

    for (i = 0; i < this.props.sentCompliments.length; i++) {
      for (j = 0; j < this.props.sentCompliments[i].recipients.length; j++) {
        if (recipientPersonIds.indexOf(this.props.sentCompliments[i].recipients[j]) === -1) {
          recipientPersonIds.push(this.props.sentCompliments[i].recipients[j])
        }
      }
    }
    return recipientPersonIds
  }

  checkAccess = () => {
    if (!hasComplimentsAccess(this.props.featureAccess)) {
      return <Redirect to="/profile" />
    }
  }

  renderSentCompliments = (sentCompliments) => {
    const _sentCompliments = cloneDeep(sentCompliments)
    const { profileImages } = this.props

    return sentCompliments.length > 0 ?
      (
        <div className="compliments-width">
          {_sentCompliments.reverse().map((compliment) => {
            const userAvatar = profileImages.find(obj => obj.personId === compliment.recipients[0])
            return this.getSentComplimentsItemMarkUp(compliment, userAvatar)
          })}
        </div>
      )
      :
      (
        <div className="section">
          <div className="row">
            <div className="col no-sent-compliments">{t('no_sent_compliments')}</div>
          </div>
          <div className="row">
            <div className="col your-compliments">{t('compliments_here')}</div>
          </div>
          <div className="row">
            <div onClick={this.addComplimentClick} className="col new-compliment-link cursor-pointer">{t('send_compliment_link')}</div>
          </div>
        </div>
      )
  }

  addComplimentClick = () => {
    this.props.history.push({
      pathname: '/send-compliments',
      state: {
        eventDetails: null,
        navFromEventPage: false,
        navFromSentComplimentsPage: true,
      }
    })
  }

  editComplimentClick(complimentToEdit) {
    let _selectedTeamMembers = this.props.personDetailsList
      .filter((item) => complimentToEdit.recipients.findIndex((recipent) => recipent === item.personId) !== -1)
      .map(member =>
        ({ ...member, employeeName: `${member.firstName} ${member.lastName}` })
      )
    this.props.history.push({
      pathname: `/send-compliments`,
      state: {
        compliment: complimentToEdit,
        selectedTeamMembers: _selectedTeamMembers,
        eventDetails: null,
        navFromEventPage: false,
        navFromSentComplimentsPage: true,
      }
    })
    this.setState({
      complimentToEdit,
    })
  }

  removeComplimentClick(complimentId) {
    const _state = this.state
    _state.modal = {
      showModal: true,
      modalTitle: t('remove_popup_title'),
      modalText: t('remove_compliment_popup_body'),
      showCancelAction: true,
      modalAcceptButtonText: t('yes'),
      modalCancelButtonText: t('confirm_popup_cancel_button'),
      modalAcceptAction: (evt) => this.removeComplimentAction(evt, complimentId),
      modalCancelAction: this.cancelRemoveCompliment,
    }
    this.setState(_state)
  }

  removeComplimentAction(evt, complimentId) {
    evt.stopPropagation()
    this.props.deleteCompliment(complimentId)
    const _state = this.state
    _state.modal = {
      showModal: false,
      modalTitle: '',
      modalText: '',
      showCancelAction: false,
      modalAcceptButtonText: '',
      modalCancelButtonText: '',
      modalAcceptAction: null,
      modalCancelAction: null,
    }
    this.setState(_state)
  }

  cancelRemoveCompliment(evt) {
    evt.stopPropagation()
    const _state = this.state
    _state.modal = {
      showModal: false,
      modalTitle: '',
      modalText: '',
      showCancelAction: false,
      modalAcceptButtonText: '',
      modalCancelButtonText: '',
      modalAcceptAction: null,
      modalCancelAction: null,
    }
    this.setState(_state)
  }

  render() {
    const {
      sentComplimentsPending, featureAccessPending, personDetailsListPending,
      eventDetailsListPending, deleteComplimentPending, profileImagesPending,
    } = this.props
    const { sentCompliments, modal } = this.state
    if (sentComplimentsPending || featureAccessPending || personDetailsListPending
      || eventDetailsListPending || deleteComplimentPending || profileImagesPending
    ) {
      return <Preloader />
    }

    return (
      <div className="sent-compliments-container">
        <div className=" compliments-width">
          <div className="row">
            <div className="col-6 sent-compliments-header">{t('sent_compliments_header')}</div>
            <div className="col-6 d-flex align-items-center justify-content-end">
              <i onClick={this.addComplimentClick} className="material-icons add_box cursor-pointer other-avail-icon ml-4">add_box</i>
            </div>
          </div>
          <div className="justify-content-end">
            {this.renderSentCompliments(sentCompliments)}
          </div>
          <div>
            <a href="https://wisintl.com/about-us/" target="_blank" rel="noopener noreferrer" className="nav-link">
              <div className="rgis-values"></div>
            </a>
          </div>
        </div>
        <ActionModal
          {...modal}
        />
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    getSentCompliments: state.complimentsReducer.getSentCompliments,
    sentCompliments: state.complimentsReducer.sentCompliments,
    sentComplimentsPending: state.complimentsReducer.sentComplimentsPending,
    featureAccess: state.featureAccessReducer.featureAccess,
    featureAccessPending: state.featureAccessReducer.featureAccessPending,
    personDetailsList: state.personDetailsReducer.personDetailsList,
    personDetailsListPending: state.personDetailsReducer.personDetailsListPending,
    personDetailsListError: state.personDetailsReducer.personDetailsListError,
    eventDetailsList: state.eventDetailsReducer.eventDetailsList,
    eventDetailsListPending: state.eventDetailsReducer.eventDetailsListPending,
    eventDetailsListError: state.eventDetailsReducer.eventDetailsListError,
    deleteComplimentPending: state.complimentsReducer.deleteComplimentPending,
    profileImages: state.profileReducer.profileImages,
    profileImagesPending: state.profileReducer.profileImagesPending['sent-compliments'],
    profileImagesError: state.profileReducer.profileImagesError,
    profileLocale: state.loginReducer.profileLocale,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getSentCompliments: () => getSentCompliments(),
      getPersonDetailsList: (personIds) => getPersonDetailsList(personIds),
      getEventDetailsList: (scheduledEventIds) => getEventDetailsList(scheduledEventIds),
      deleteCompliment: (complimentId) => deleteCompliment(complimentId),
      postListOfProfileImages: (personIds, location) => postListOfProfileImages(personIds, location),
    },
    dispatch
  )
}

SentComplimentsContainer.propTypes = {
  getSentCompliments: PropTypes.func,
  sentComplimentsPending: PropTypes.bool,
  sentCompliments: PropTypes.array,
  featureAccess: PropTypes.object,
  featureAccessPending: PropTypes.bool,
  personDetailsList: PropTypes.array,
  personDetailsListPending: PropTypes.bool,
  personDetailsListError: PropTypes.object,
  getPersonDetailsList: PropTypes.func,
  eventDetailsList: PropTypes.array,
  eventDetailsListPending: PropTypes.bool,
  eventDetailsListError: PropTypes.object,
  getEventDetailsList: PropTypes.func,
  history: PropTypes.object,
  deleteCompliment: PropTypes.func,
  deleteComplimentPending: PropTypes.bool,
  postListOfProfileImages: PropTypes.func,
  profileImages: PropTypes.array,
  profileImagesPending: PropTypes.bool,
  profileImagesError: PropTypes.object,
  profileLocale: PropTypes.string,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SentComplimentsContainer)
