import axios from 'axios'
import apiConfig from '../../config/api-config'
import { toQueryString } from './utils/requestUtil'

// Get profile preferences
export function fetchProfilePref(personId) {
  const url = apiConfig.PROFILE_PREF
  const queryParams = toQueryString({
    personId: personId,
  })
  return axios.get(`${url}?${queryParams}`)
}

// Update the profile preferences
export function setProfilePref(formData) {
  const url = apiConfig.PROFILE_PREF
  return axios.post(url, formData)
}
