import filter from 'lodash/filter'
import { ADD_TOAST, REMOVE_TOAST } from '../actions/actionTypes'

export default function toastsReducer(
  state = {
    toasts: [],
  },
  action
) {
  switch (action.type) {
    case ADD_TOAST: {
      const newArray = state.toasts.slice()
      newArray.unshift(action.payload) // Add to start of array
      return {
        toasts: newArray,
      }
    }
    case REMOVE_TOAST:
      return {
        toasts: filter(state.toasts, t => {
          return t.id !== action.payload
        }),
      }
    default:
      return state
  }
}
