import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './MyTeamListItem.scss'
import PostUserAvatar from '../../PostUserAvatar'
import { t } from '../../../utils/i18n'
import { formatPhoneNumber } from '../../utils/phoneUtils'
import { obfuscate } from '../../utils/obfuscateUtils'

class MyTeamListItem extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const { personInfo, userAvatar, history } = this.props

    return (
      <div className="row my-team-list-item my-team-width" key={personInfo.personId}>
        <div className="col">
          <div className="card">
            <div className="card-body">
              <div className="section">
                <div className="row personal-info">
                  <div className="col d-flex">
                    <PostUserAvatar
                      personName={`${personInfo.firstName} ${personInfo.lastName}`}
                      userAvatarUrl={userAvatar !== undefined && userAvatar !== null ? userAvatar.url : ''}
                      personId={personInfo.personId}
                      redirectToAvatarPage={false}
                      history={history}
                    />
                    <div className="col d-flex flex-column justify-content-center">
                      <div
                        className="name cursor-pointer"
                        onClick={() => this.props.history.push(`/public-profile/${obfuscate(personInfo.personId)}`)}
                      >
                        {`${personInfo.firstName} ${personInfo.lastName}`}
                      </div>
                      <div className="name">{personInfo.currentJob}</div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4 phone-number">
                  <div className="col d-flex align-items-center">
                    <div>
                      <i className="material-icons">phone</i>
                    </div>
                    <div className="ml-4">
                      <div><b>{t('phone_number')} 1</b></div>
                      {personInfo.telephoneNumber1 ?
                        <a className="mt-2" onClick={(evt) => evt.stopPropagation()} href={`tel:${formatPhoneNumber(personInfo.telephoneNumber1)}`}>{formatPhoneNumber(personInfo.telephoneNumber1)}</a>
                        :
                        <div className="text-center">{t('no_phone_number')}</div>
                      }
                      <br />
                    </div>
                  </div>

                  <div className="col d-flex align-items-center">
                    <div>
                      <i className="material-icons">phone</i>
                    </div>
                    <div className="ml-4">
                      <div><b>{t('phone_number')} 2</b></div>
                      {personInfo.telephoneNumber2 ?
                        <a className="mt-2" onClick={(evt) => evt.stopPropagation()} href={`tel:${formatPhoneNumber(personInfo.telephoneNumber2)}`}>{formatPhoneNumber(personInfo.telephoneNumber2)}</a>
                        :
                        <div className="text-center">{t('no_phone_number')}</div>
                      }
                      <br />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

MyTeamListItem.propTypes = {
  personInfo: PropTypes.object,
  userAvatar: PropTypes.object,
  history: PropTypes.object,
}

export default MyTeamListItem

