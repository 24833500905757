import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { isEventCompleted } from '../utils/datetimeUtils'
import { formatNumber } from '../utils/numberUtils'
import { t } from '../../utils/i18n'

import './ASETInfo.scss'

export default class ASETInfo extends Component {

  renderActualAphMarkUp() {
    const { eventDetails, eventAph } = this.props
    const { ranking, targetAuaph, targetSpaph, targetExaph, targetTgaph } = eventDetails.scheduleData.asetTargets
    const { estimatedDuration } = eventDetails.scheduleData
    const { date, time } = eventDetails
    let aphGoal = null

    switch (ranking) {
      case 'AUDITOR':
        aphGoal = targetAuaph
        break;
      case 'SPECIALIST':
        aphGoal = targetSpaph
        break;
      case 'EXPERT':
        aphGoal = targetExaph
        break;
      default:
        aphGoal = targetTgaph
    }

    if (isEventCompleted(date, time, estimatedDuration) && eventAph !== undefined && eventAph !== null) {
      if (eventAph >= aphGoal) {
        return (
          <div>
            <div className="mt-2"><span className="boldText">{t('congratulations')}</span> {t('aph_goal_achived')}</div>
            <div className="mt-2">{t('your_actual_aph')}: <span className="boldText">{formatNumber(eventAph)}</span></div>
          </div>
        )
      } else {
        return <div className="mt-2">{t('your_actual_aph')}: <span className="boldText">{formatNumber(eventAph)}</span></div>
      }
    }
    return <div className="mt-2">{t('aph_not_available')}</div>
  }

  render() {
    const { eventDetails } = this.props

    if (!eventDetails.scheduleData.asetTargets || !eventDetails.scheduleData.storeData) {
      return ''
    }
  
    const { ranking, targetAuaph, targetSpaph, targetExaph, targetTgaph } = eventDetails.scheduleData.asetTargets
  
    return (
      <div className="card aset-info">
        <div className="card-body">
          <div className="row aset-vertical">
            <div className={`col ${ranking === 'AUDITOR' ? 'range-active' : ''}`}>
              <div className="label">{formatNumber(targetAuaph)}</div>
              <div className="rangeBar rangeBar-sm">&nbsp;</div>
              <div className="circle">A</div>
            </div>
            <div className={`col ${ranking === 'SPECIALIST' ? 'range-active' : ''}`}>
              <div className="label">{formatNumber(targetSpaph)}</div>
              <div className="rangeBar rangeBar-md">&nbsp;</div>
              <div className="circle">S</div>
            </div>
            <div className={`col ${ranking === 'EXPERT' ? 'range-active' : ''}`}>
              <div className="label">{formatNumber(targetExaph)}</div>
              <div className="rangeBar rangeBar-lg">&nbsp;</div>
              <div className="circle">E</div>
            </div>
            <div className={`col ${ranking === 'TOP_GUN' ? 'range-active' : ''}`}>
              <div className="label">{formatNumber(targetTgaph)}</div>
              <div className="rangeBar rangeBar-xl">&nbsp;</div>
              <div className="circle">T</div>
            </div>
          </div>
          <div className="row">
            <div className="col title">
              {this.renderActualAphMarkUp()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ASETInfo.propTypes = {
  eventDetails: PropTypes.object,
  asetTargets: PropTypes.object,
  storeData: PropTypes.object,
  eventAph: PropTypes.number,
}
