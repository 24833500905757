import { t } from '../../utils/i18n'

export function convertAbsenceCodeToAbsenceName(absenceCode) {
    const unexcusedAbsenceCode = 'UE'
    const excusedAbsenceCode = 'EX'
    const rescheduledAbsenceCode = 'RS'
    const sickAbsenceCode = 'SP'
    const unexcusedAbsenceName = t('unexcused')
    const excusedAbsenceName = t('excused')
    const rescheduledAbsenceName = t('rescheduled')
    const sickAbsenceName = t('sick')
  
    if (absenceCode === undefined || absenceCode === null) { return '' }
  
    let absenceName = ''
  
    switch(absenceCode) {
      case unexcusedAbsenceCode:
        absenceName = unexcusedAbsenceName
        break
      case excusedAbsenceCode:
        absenceName = excusedAbsenceName
        break
      case rescheduledAbsenceCode:
        absenceName = rescheduledAbsenceName
        break
      case sickAbsenceCode:
        absenceName = sickAbsenceName
        break
      default:
        absenceName = ''
    }
  
    return absenceName
  }