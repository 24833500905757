import React from 'react'
import PropTypes from 'prop-types'
import logo from '../../styles/images/wconnect.ico'
import moment from 'moment'

import PostUserAvatar from '../PostUserAvatar'
import './NotificationList.scss'

import { formatDate, formatTime } from '../utils/datetimeUtils'
import { 
  getNotificationPublishedWeekDate, 
  getUnplannedTravelContent 
} from '../utils/notificationUtils'
import { 
  nullSafeCheckIsTrue, 
  isDefined,
  isDefinedAndNotEmpty 
} from '../utils/nullSafeCheckUtils'
import { t } from '../../utils/i18n'

import {
  NewComplimentReceived,
  EventPersonAdded,
  EventPersonRemoved,
  PerformanceTracker
} from '../../utils/notificationTypeContants'

class NotificationList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.handleNotificationClick = this.handleNotificationClick.bind(this)
  }

  getNotificationContent(itemData) {
    const eventDate = itemData.event ? formatDate(itemData.event.date) : ''
    if (!itemData.notificationType) {
      return t('not_available')
    }
    const _notificationType = itemData.notificationType.toLowerCase()
    switch (itemData.notificationType) {
      case 'EVENT_SCHED_PUBLISHED':
        return t(`${_notificationType}_text`, [getNotificationPublishedWeekDate(itemData.notificationDetails)])
      case 'EVENT_PERSON_ADDED':
      case 'EVENT_PERSON_MEET_SITE_CHANGED':
        return t(`${_notificationType}_text`, [eventDate, itemData.event.title])
      case 'EVENT_SCHED_CHANGE_REQ_SUBMIT':
        return t(`${_notificationType}_text`, [eventDate, itemData.changeRequest.fullName])
      case 'EVENT_PERSON_REMOVED':
      case 'EVENT_SCHED_CHANGE_REQ_DENIED':
      case 'EVENT_SCHED_CHANGE_REQ_ACCEPT':
        return t(`${_notificationType}_text`, [eventDate])
      case 'EVENT_SCHED_DATE_TIME_CHANGED':
        return t(`${_notificationType}_text`, [eventDate, itemData.event.title])
      case 'WATCHED_POST_DETAIL_UPDATED':
        return t(`${_notificationType}_text`, [itemData.notificationDetails.updatingPersonName])
      case 'NEW_RECEIVED_COMPLIMENT':
        return t(`${_notificationType}_text`, [this.props.senderName])
      case 'TIMESHEET_NOT_SUBMITTED':
        return t(`${_notificationType}_text`, [formatDate(itemData.notificationDetails.timesheetDate)])
      case 'MANAGER_TIMESHEETS_NOT_SUBMITTED':
        return t(`${_notificationType}_text`, [itemData.notificationDetails.teamMemberDetails.length])
      case 'UNSUBMITTED_EVENT_TRAVEL':
        return t(`${_notificationType}_text`, [itemData.notificationDetails.bannerName || itemData.notificationDetails.customerName, formatDate(itemData.notificationDetails.date)])
      case 'POST_REPLIED_TO':
        return itemData.notificationDetails.postBody
      case 'WATCHED_CHANNEL_NEW_POST':
        return t('new_post_channel_item_detail', [itemData.notificationDetails.personName])
      case 'UNPLANNED_TRAVEL':
        return getUnplannedTravelContent(itemData)
      case 'NewBadgeEarned':
      case 'NewBadgeUnearned':
      case NewComplimentReceived:
      case EventPersonAdded:
      case EventPersonRemoved:
        return itemData.body
      case PerformanceTracker:
        return itemData.title
      case 'USER_GENERIC_MESSAGE':
        return itemData.notificationDetails.body
      default:
        return t(`${_notificationType}_text`)
    }
  }

  getNotificationTime(notificationSentDate) {
    const today = moment().clone().startOf('day');
    const yesterday = moment().clone().subtract(1, 'days').startOf('day');
    const notificationSentDateTime = moment.utc(notificationSentDate).local()

    if (notificationSentDateTime.isSame(today, 'd')) return formatTime(notificationSentDateTime)

    if (notificationSentDateTime.isSame(yesterday, 'd')) return t('yesterday')

    return formatDate(notificationSentDateTime)
  }

  getNotificationTitle(itemData) {
    if (!isDefinedAndNotEmpty(itemData.notificationType)) return t('not_available')

    switch (itemData.notificationType) {
      case 'WATCHED_POST_DETAIL_UPDATED':
        return t(itemData.notificationType.toLowerCase(), [itemData.notificationDetails.watchedPost.postTitle])
      case 'POST_REPLIED_TO':
        return t(itemData.notificationType.toLowerCase(), [this.props.senderName])
      case 'WATCHED_CHANNEL_NEW_POST':
        return t('new_post_channel_item_title')
      case 'UNPLANNED_TRAVEL':
        return this.getUnplannedTravelTitleText(itemData.notificationType)
      case 'NewBadgeEarned':
      case 'NewBadgeUnearned':
      case NewComplimentReceived:
      case EventPersonAdded:
      case EventPersonRemoved:
        return itemData.title
      case 'USER_GENERIC_MESSAGE':
        return itemData.notificationDetails.title 
      default:
        return t(itemData.notificationType.toLowerCase())
    }
  }

  getUnplannedTravelTitleText = (notificationType) => {
    return t(notificationType.toLowerCase())
  }

  getNotificationAvatar(itemData, senderName, userAvatar) {
    switch (itemData.notificationType) {
      case 'NEW_RECEIVED_COMPLIMENT':
      case 'WATCHED_POST_DETAIL_UPDATED':
      case 'EVENT_SCHED_CHANGE_REQ_DENIED':
      case 'EVENT_SCHED_CHANGE_REQ_ACCEPT':
      case 'POST_REPLIED_TO':
      case NewComplimentReceived:
        return (
          <PostUserAvatar
            personName={senderName}
            userAvatarUrl={isDefined(userAvatar) && isDefinedAndNotEmpty(userAvatar.url) ? userAvatar.url : null}
            clickable={false}
          />
        )
      default:
        return (<img src={logo} alt="logo" />)
    }
  }

  handleNotificationClick() {
    const { itemData, senderName, senderPersonId, userAvatar, history } = this.props

    switch (itemData.notificationType) {
      case 'WATCHED_POST_DETAIL_UPDATED':
        if (itemData.status === 'SENT') {
          this.props.patchMarkNotificationsAsRead([itemData.id])
        }
        this.props.redirectToPostDetails(itemData.id, itemData.notificationDetails.watchedPost.postId, itemData.notificationDetails.updatingPost.postId)
        break
      case 'POST_REPLIED_TO':
        if (itemData.status === 'SENT') {
          this.props.patchMarkNotificationsAsRead([itemData.id])
        }
        this.props.redirectToPostDetails(itemData.id, itemData.notificationDetails.repliedToPostId, itemData.notificationDetails.replyPostId)
        break
      default:
        history.push({
          pathname: `/notifications/${itemData.id}`,
          state: {
            notificationData: itemData,
            senderName,
            senderPersonId,
            senderUserAvatar: userAvatar,
          },
        })
    }
  }

  getSenderNameForAvatar = () => {
    const { itemData, senderName } = this.props

    if (!isDefined(senderName) 
      && itemData.notificationType === NewComplimentReceived 
      && isDefined(itemData.modelData) 
      && isDefined(itemData.modelData.SenderName)) {
      return itemData.modelData.SenderName
    }

    return senderName
  }

  render() {
    const { itemData, userAvatar } = this.props
    return (
      <div
        className={`card ${itemData.status === 'READ' || nullSafeCheckIsTrue(itemData.isRead) ? 'read' : ''} restore-${itemData.id}`}
        onClick={this.handleNotificationClick}
      >
        <div className="card-body">
          <div className="row">
            <div className="col-2 col-sm-1 d-flex justify-content-end">
              {this.getNotificationAvatar(itemData, this.getSenderNameForAvatar(), userAvatar)}
            </div>
            <div className="col-8 col-sm-9 d-flex flex-column justify-content-center">
              <div className="notification-title">
                {this.getNotificationTitle(itemData)}
              </div>
              <div className="notification-text">
                <p>
                  {this.getNotificationContent(itemData)}
                </p>
              </div>
            </div>
            <div className="col-2 col-sm-1 d-flex align-items-start justify-content-center">
              <p>{this.getNotificationTime(itemData.sentDate)}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

NotificationList.propTypes = {
  itemData: PropTypes.object,
  senderName: PropTypes.string,
  userAvatar: PropTypes.object,
  senderPersonId: PropTypes.number,
  history: PropTypes.object,
  redirectToPostDetails: PropTypes.func,
  patchMarkNotificationsAsRead: PropTypes.func,
}

export default NotificationList
