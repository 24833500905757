import {
  GET_GENERAL_AVAILABILITY_SUCCESS,
  GET_GENERAL_AVAILABILITY_ERROR,
  GET_GENERAL_AVAILABILITY_PENDING,
  EDITING_GENERAL_AVAILABILITY,
  NOT_EDITING_GENERAL_AVAILABILITY,
  SAVE_GENERAL_AVAILABILITY,
  PATCH_GENERAL_AVAILABILITY_ERROR,
  PATCH_GENERAL_AVAILABILITY_PENDING,
  PATCH_GENERAL_AVAILABILITY_SUCCESS
} from '../actions/actionTypes'

export default function generalAvailabilityReducer(
  state = {
    generalAvailability: [],
    backupGeneralAvailability: [],
    generalAvailabilityError: {},
    generalAvailabilityPending: true,
    editingGeneralAvailability: false,
    patchGeneralAvailabilityPending: false,
    patchGeneralAvailabilityError: '',
  },
  action
) {
  switch (action.type) {
    case GET_GENERAL_AVAILABILITY_SUCCESS:
      return {
        ...state,
        generalAvailability: action.payload ? action.payload : [],
        backupGeneralAvailability: action.payload ? action.payload.slice(0) : [],
        generalAvailabilityError: {},
        generalAvailabilityPending: false,
      }
    case GET_GENERAL_AVAILABILITY_ERROR:
      return {
        ...state,
        generalAvailabilityError: action.payload,
      }
    case GET_GENERAL_AVAILABILITY_PENDING:
      return {
        ...state,
        generalAvailabilityPending: true,
      }
    case EDITING_GENERAL_AVAILABILITY:
      return {
        ...state,
        editingGeneralAvailability: true,
      }
    case NOT_EDITING_GENERAL_AVAILABILITY:
      return {
        ...state,
        editingGeneralAvailability: false,
      }
    case SAVE_GENERAL_AVAILABILITY:
      return {
        ...state,
        generalAvailability: action.payload.generalAvailability
      }
    case PATCH_GENERAL_AVAILABILITY_ERROR:
      return {
        ...state,
        patchGeneralAvailabilityError: action.error,
      }
    case PATCH_GENERAL_AVAILABILITY_PENDING:
      return {
        ...state,
        patchGeneralAvailabilityPending: false,
      }
    case PATCH_GENERAL_AVAILABILITY_SUCCESS:
      return {
        ...state,
        backupGeneralAvailability: state.generalAvailability.slice(0),
        generalAvailabilityError: '',
        generalAvailabilityPending: false,
      }
    default:
      return state
  }
}
