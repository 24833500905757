import React, { Component } from 'react'
import { t } from '../../../../utils/i18n'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { sendRequestEventChange } from '../../../../state/actions/scheduleActions'

import Preloader from '../../../Preloader'
import { nullSafeCheckIsTrue, nullSafeCheckIsFalse, isDefinedAndHasOwnProperties } from '../../../utils/nullSafeCheckUtils'

import './ConfirmationPopup.scss'

class Popup extends Component {
  constructor() {
    super()
    this.state = {
      disableButton: false,
      submittedOnShortNotice: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }


  componentDidUpdate(prevProps) {
    if (nullSafeCheckIsTrue(prevProps.requestEventChangePending) && nullSafeCheckIsFalse(this.props.requestEventChangePending)) {
      this.postShortNoticeSubmissionHandler()
    }
  }

  static getDerivedStateFromProps(props) {
    if (props.showConfirmationPopup === false) {
      return {
        disableButton: false,
      }
    }
    return null
  }

  postShortNoticeSubmissionHandler = () => {
    if (isDefinedAndHasOwnProperties(this.props.requestEventChangeError)) {
      alert(t('request_change_error_message'))
      this.props.cancelConfirmationPopup()
    } else {
      if (nullSafeCheckIsTrue(this.state.submittedOnShortNotice)) {
        this.setState({
          submittedOnShortNotice: false,
        })
  
        this.props.history.push("/contacts")
      } else {
        this.props.handleRequestMade()
      }
    }
  }

  handleSubmit() {
    this.setState({
      disableButton: true,
    })

    let requestDetails = this.props.getRequestDetails()
    this.props.sendRequestEventChange(requestDetails.reasonCode, 
      requestDetails.eventCode, 
      requestDetails.notes, 
      requestDetails.scheduledEventId, 
      requestDetails.attendingTime)
  }

  handleSubmitShortNotice = () => {
    this.setState({
      submittedOnShortNotice: true,
    })
    
    this.handleSubmit()
  }

  requestIsShortNotice = () => {
    return this.props.hourDifference > 0 && this.props.hourDifference <= this.props.shortNoticeHours
  }

  requestIsAfterStartOfEvent = () => {
    return this.props.hourDifference <= 0
  }

  requestHasProperHoursNotice = () => {
    return !this.requestIsShortNotice() && !this.requestIsAfterStartOfEvent()
  }

  getTitleText = () => {
    if (this.requestIsShortNotice()) {
      return t('manager_contact_required')
    } else {
      return ''
    }
  }

  getBodyText = () => {
    if (this.requestIsAfterStartOfEvent()) {
      return this.getBodyTextAfterStartOfEvent()
    } else if (this.requestHasProperHoursNotice()) {
      return this.getBodyTextHasProperHoursNotice()
    } else if (this.requestIsShortNotice()) {
      return this.getBodyTextShortNotice()
    }
    
    return ''
  }

  getBodyTextShortNotice = () => {
    return (
      <div>{t('request_short_notice_text')}
      </div>
    )
  }

  getBodyTextHasProperHoursNotice = () => {
    return (
      <div>
        <p>{t('request_change_4')}.</p>
        <p className="margin-20">{t('request_change_5')}.</p>
      </div>
    )
  }

  getBodyTextAfterStartOfEvent = () => {
    return (
      <div>
        <p>{t('request_change_1')}.</p>
        <p>
          <span className="underLine boldText">{t('request_change_2')}.</span>
        </p>
        <p className="margin-20">{t('request_change_3')}.</p>
      </div>
    )
  }

  getButtonsAfterStartOfEvent = () => {
    return (
      <div className='center-button'>
        <button
          type="button"
          className='notify-button uppercase cursor-pointer'
          data-dismiss="modal"
          onClick={this.props.cancelConfirmationPopup}
        >
          {t("ok")}
        </button>
      </div>
    )
  }

  getButtonsHasProperHoursNotice = () => {
    const { disableButton } = this.state

    return (
      <div className='row'>
        <div className='col-6 center-button'>
          <button
            type="button"
            className='notify-button uppercase cursor-pointer'
            onClick={this.handleSubmit}
            disabled={disableButton}
          >
            {t("submit")}
          </button>
        </div>
        <div className='col-6 center-button'>
          <button
            type="button"
            className='notify-button uppercase cursor-pointer'
            onClick={this.props.cancelConfirmationPopup}
            disabled={disableButton}
          >
            {t('confirm_popup_cancel_button')}
          </button>
        </div>
      </div>      
    )
  }

  getButtonsShortNotice = () => {
    const { disableButton } = this.state

    return (
      <div className='row'>
        <div className='col-6 center-button'>
          <button
            type="button"
            className='notify-button uppercase cursor-pointer'
            onClick={this.handleSubmitShortNotice}
            disabled={disableButton}
          >
            {t("submit")}
          </button>
        </div>
        <div className='col-6 center-button'>
          <button
            type="button"
            className='notify-button uppercase cursor-pointer'
            onClick={this.props.cancelConfirmationPopup}
            disabled={disableButton}
          >
            {t('confirm_popup_cancel_button')}
          </button>
        </div>
      </div>      
    )
  }  

  getButtons = () => {
    if (this.requestIsAfterStartOfEvent()) {
      return this.getButtonsAfterStartOfEvent()
    } else if (this.requestHasProperHoursNotice()) {
      return this.getButtonsHasProperHoursNotice()
    } else if (this.requestIsShortNotice()) {
      return this.getButtonsShortNotice()
    }
    
    return ''
  }

  render() {
    const { requestEventChangePending } = this.props

    if (requestEventChangePending) {
      return <Preloader />
    }

    return (
      <div
        className={`confirmation-pop-up modal fade ${this.props.showConfirmationPopup ? 'show' : ''}`}
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className={`modal-content ${this.requestIsShortNotice() || this.requestIsAfterStartOfEvent() ? 'request-past-time' : 'request-normal-time'}`}>
            <div className="row">
              <div className="col-1">
                <i className="material-icons warning">warning</i>
              </div>
              <div className="col-11 modal-title">
                { this.getTitleText() }
              </div>
            </div>
            <div className="modal-body">
              { this.getBodyText() }
            </div>
            <div>
              { this.getButtons() }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    requestEventChangeStatus: state.scheduleReducer.requestEventChangeStatus,
    requestEventChangePending: state.scheduleReducer.requestEventChangePending,
    requestEventChangeError: state.scheduleReducer.requestEventChangeError,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      sendRequestEventChange: (reasonCode, eventCode, notes, scheduledEventId, attendingTime) => sendRequestEventChange(reasonCode, eventCode, notes, scheduledEventId, attendingTime),
    },
    dispatch
  )
}

Popup.propTypes = {
  showConfirmationPopup: PropTypes.bool,
  cancelConfirmationPopup: PropTypes.func,
  handleRequestMade: PropTypes.func,
  hourDifference: PropTypes.string,
  shortNoticeHours: PropTypes.number,
  history: PropTypes.object,
  getRequestDetails: PropTypes.func,

  sendRequestEventChange: PropTypes.func,
  requestEventChangeStatus: PropTypes.bool,
  requestEventChangePending: PropTypes.bool,
  requestEventChangeError: PropTypes.object,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Popup)