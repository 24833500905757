import React from 'react'
import PropTypes from 'prop-types'
import './ConfirmationPopup.scss'
import Rating from 'react-rating'
import PostUserAvatar from '../../PostUserAvatar'
import { t } from '../../../utils/i18n'

class RemovePopup extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		const { showPopup, personName, eventName, rating, ratingLabel, userAvatar } = this.props
		return (
			<div
				className={`confirmation-popup modal fade ${showPopup ? 'show' : ''}`}
				id="exampleModal"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<h3 className="modal-title">
							{t('rating_successfully_sent')}
            </h3>
						<div className="modal-body">
							<div className="d-flex justify-content-center">
								{personName ?
									<PostUserAvatar
										personName={personName}
										userAvatarUrl={userAvatar !== undefined ? userAvatar.url : ''}
										clickable={false}
									/>
									:
									<i className="material-icons">event</i>
								}
							</div>
							<div className="name mt-1">{personName || eventName}</div>
							<div className="mt-2 rating-stars">
								<Rating
									emptySymbol={<i className="material-icons">star_border</i>}
									fullSymbol={<i className="material-icons rated-star">star_rate</i>}
									fractions={2}
									initialRating={rating}
									readonly
								/>
								<div className="">{ratingLabel} </div>
							</div>
						</div>
						<div className="row flex-column align-items-center">
							<div className="col-12 d-flex justify-content-center">
								<input
									type='button'
									onClick={this.props.onOkClick}
									className="btn btn-rgis-blue mr-2"
									value={t('ok')}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}


RemovePopup.propTypes = {
	showPopup: PropTypes.bool,
	personName: PropTypes.string,
	eventName: PropTypes.string,
	rating: PropTypes.number,
	ratingLabel: PropTypes.string,
	onOkClick: PropTypes.func,
	userAvatar: PropTypes.object,
}

export default RemovePopup
