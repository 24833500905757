import axios from 'axios'
import get from 'lodash/get'
import { getIdentityUserManager } from '../../../utils/odicUserManager'
import { getRgisBearerToken, clearLocalStorage } from '../../../components/utils/localStorageUtils'
import apiConfig from '../../../config/api-config'

// Request Interceptor
axios.interceptors.request.use(
  config => {
    var token = getRgisBearerToken()

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// Response Interceptor
axios.interceptors.response.use(
  response => {
    return response
  },
  error => { 
    try {
      if (get(error, 'request.responseURL') === apiConfig.CONNECT_USER_INFO && get(error, 'response.status') === 401) {
        handleResponseError()
      } else if (axios.isCancel(error)) {
        // TODO Handle is cancel generically
      }
    } catch (ex) {
      handleResponseError()
    }
    return Promise.reject(error)
  }
)

function handleResponseError() {
  clearLocalStorage()
  getIdentityUserManager().signinRedirect()
}