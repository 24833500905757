import axios from 'axios'
import apiConfig from '../../config/api-config'
import * as cancelTokenUtil from './utils/cancelTokenUtil'

// Get person details
export function fetchPersonDetails(personId) {
  const url = apiConfig.PERSON_DETAILS.replace('[personId]', personId)
  const options = cancelTokenUtil.abortAndGetCancelTokenOptions('PERSON_DETAILS')
  return axios.get(`${url}`, options)
}

export function getSearchAllPeople(searchString) {
  const url = apiConfig.GET_SEARCH_ALL_PEOPLE
  var payload = {
    criteria: searchString
  }
  return axios.post(`${url}`, payload)
}

export function getPersonDetailsList(personIds) {
  const url = apiConfig.GET_PERSON_DETAIL_LIST
  const options = cancelTokenUtil.abortAndGetCancelTokenOptions('GET_PERSON_DETAIL_LIST')
  return axios.post(`${url}`, personIds, options)
}

export function getPublishedWeek(organizationId) {
  const url = apiConfig.PUBLISHED_WEEK_DETAILS.replace('[organizationId]', organizationId)
  return axios.get(`${url}`)
}

export function getSchedulingPreferences(personId) {
  const url = apiConfig.SCHEDULING_PREFERENCES.replace('[personId]', personId)
  const options = cancelTokenUtil.abortAndGetCancelTokenOptions('SCHEDULING_PREFERENCES')
  return axios.get(`${url}`, options)
}

export function patchSchedulingPreferences(schedulingPreferences = {}) {
  const url = apiConfig.PATCH_SCHEDULING_PREFERENCES
  const options = cancelTokenUtil.abortAndGetCancelTokenOptions('PATCH_SCHEDULING_PREFERENCES')
  return axios.patch(`${url}`, schedulingPreferences, options)
}

export function patchGeneralAvailability(generalAvailability = []) {
  const url = apiConfig.PATCH_GENERAL_AVAILABILITY
  const options = cancelTokenUtil.abortAndGetCancelTokenOptions('PATCH_GENERAL_AVAILABILITY')
  return axios.patch(`${url}`, generalAvailability, options)
}

// Get the General Availability
export function getGeneralAvailabilty() {
  const url = apiConfig.GENERAL_AVAILABILITY
  const options = cancelTokenUtil.abortAndGetCancelTokenOptions('GENERAL_AVAILABILITY')
  return axios.get(`${url}`, options)
}
