import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import ActionModal from '../../components/ActionModal'
import Preloader from '../../components/Preloader'
import FileUpload from '../../components/FileUpload'
import PostUserAvatar from '../../components/PostUserAvatar'
import FlagForReviewDialogForm from '../../components/FlagForReviewDialogForm'
import './UserAvatarContainer.scss'
import commentActionsImg from '../../styles/images/ellipsis-v-solid.svg'

import { postProfileImage, deleteProfileImage } from '../../state/actions/profileActions'
import { getModerationReasons, postModerationItem } from '../../state/actions/moderationActions'
import { getLoggedInPersonId } from '../../components/utils/localStorageUtils'
import { deviceIsIOS, isIOSChrome, isMobileDevice } from '../../components/utils/mobileUtils'
import { nullSafeCheckIsFalse } from '../../components/utils/nullSafeCheckUtils'
import { obfuscate, deObfuscate } from '../../components/utils/obfuscateUtils'

import Slider from '@material-ui/core/Slider'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'

import Camera, {FACING_MODES} from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

import { t } from '../../utils/i18n'

const MAX_FILE_SIZE = 500000000

class UserAvatarContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      personId: null,
      userAvatarId: '',
      userAvatarUrl: null,
      userAvatarIsFlagged: false,
      showFlagForReviewDialogForm: false,
      flagForReviewObj: {
        title: t('please_select_reason'),
        reasons: [],
        commentText: '',
        commentsEnabled: true,
      },
      personName: '',
      modal: {
        showModal: false,
        modalTitle: '',
        modalText: '',
        showCancelAction: false,
        modalAcceptButtonText: '',
        modalCancelButtonText: '',
        modalAcceptAction: null,
        modalCancelAction: null,
      },
      showDropdownActions: false,
      showUpdateAvatar: false,
      showEditAvatar: false,
      showCamera: false,
      backPhoto: false,



      fileUpload: {
        error: false,
        message: '',
        file: {},
      },

      /////////////// FOR IMAGE CROP
      crop: { x: 0, y: 0 },
      zoom: 1,
      zoomStep: 0.1,
      aspectRatio: 1,
      cropSize: { width: 512, height: 512 },
      croppedAreaPixels: {},
      /////////////// FOR IMAGE CROP
    }
    this.dropdownActionsClick = this.dropdownActionsClick.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.updateAvatarClick = this.updateAvatarClick.bind(this)
    this.resetAvatarClick = this.resetAvatarClick.bind(this)
    this.resetAvatarAction = this.resetAvatarAction.bind(this)
    this.flagAvatarClick = this.flagAvatarClick.bind(this)
    this.onTakeNewPhotoClick = this.onTakeNewPhotoClick.bind(this)
    this.onTakeNewPhotoBackCameraClick = this.onTakeNewPhotoBackCameraClick.bind(this)
    this.onTakePhoto = this.onTakePhoto.bind(this)
    this.onZoomChange = this.onZoomChange.bind(this)
    this.onSaveCrop = this.onSaveCrop.bind(this)
    this.onCancelCrop = this.onCancelCrop.bind(this)
    this.handleDropzoneChange = this.handleDropzoneChange.bind(this)
    this.renderCameraMarkup = this.renderCameraMarkup.bind(this)
    this.getIsManager = this.getIsManager.bind(this)
    this.flagForReviewSubmitAction = this.flagForReviewSubmitAction.bind(this)

    this.cropperRef = React.createRef()
  }

  componentDidMount() {
    const _state = this.state
    let personId = ''
    if (this.props.history.location.pathname === '/my-avatar') {
      personId = getLoggedInPersonId()
    } else {
      personId = deObfuscate(this.props.match.params.person_id)
    }
    const _locationState = this.props.location.state
    if (_locationState === undefined || _locationState.personName === undefined || _locationState.userAvatarUrl === undefined) {
      if (this.props.history.location.pathname === '/my-avatar') {
        this.props.history.push(`/profile`)
        return
      } else {
        this.props.history.push(`/public-profile/${obfuscate(personId)}`)
        return
      }
    }

    if (this.props.moderationReasons.length) {
      _state.flagForReviewObj = {
        ...this.state.flagForReviewObj,
        reasons: this.props.moderationReasons
      }
    } else {
      this.props.getModerationReasons()
    }

    if (window.innerWidth < 576) {
      _state.cropSize = {
        width: 280,
        height: 280,
      }
    }

    _state.personId = personId
    _state.personName = _locationState.personName
    _state.userAvatarId = _locationState.userAvatarId
    _state.userAvatarUrl = _locationState.userAvatarUrl
    _state.userAvatarIsFlagged = _locationState.userAvatarIsFlagged
    this.setState(_state)
  }

  componentDidUpdate(prevProps) {
    // const _state = this.state

    if (prevProps.postPersonProfileImagePending === true && this.props.postPersonProfileImagePending === false) {
      if (Object.keys(this.props.postPersonProfileImageError).length) {
        this.setState({
          modal: {
            showModal: true,
            modalTitle: t('no_schedule_data'),
            modalText: '',
            showCancelAction: false,
            modalAcceptButtonText: t('ok'),
            modalCancelButtonText: '',
            modalAcceptAction: this.closeModal,
            modalCancelAction: null,
          },
        })
      } else {
        this.setState({
          userAvatarUrl: this.props.postPersonProfileImage,
          showEditAvatar: false,
          showUpdateAvatar: false
        })
      }
    }

    if (prevProps.deleteProfileImagePending === true && this.props.deleteProfileImagePending === false) {
      this.setState({
        userAvatarUrl: this.props.postPersonProfileImage,
        showEditAvatar: false,
        showUpdateAvatar: false
      })
    }

    if (prevProps.moderationReasonsPending === true && this.props.postModerationItemPending === false) {
      this.setState({
        flagForReviewObj: {
          ...this.state.flagForReviewObj,
          reasons: this.props.moderationReasons
        }
      })
    }

    if (prevProps.postModerationItemPending === true && this.props.postModerationItemPending === false) {
      if (Object.keys(this.props.postModerationItemError).length) {
        this.setState({
          modal: {
            showModal: true,
            modalTitle: t('error'),
            modalText: t('try_again_later'),
            showCancelAction: false,
            modalAcceptButtonText: t('ok'),
            modalCancelButtonText: '',
            modalAcceptAction: this.closeModal,
            modalCancelAction: null,
          }
        })
      } else {
        this.setState({
          userAvatarIsFlagged: true
        })
      }
    }
  }

  dropdownActionsClick() {
    this.setState({ showDropdownActions: !this.state.showDropdownActions })
  }

  updateAvatarClick() {
    this.setState({
      showDropdownActions: !this.state.showDropdownActions,
      showUpdateAvatar: true,
      showEditAvatar: false,
    })
  }

  resetAvatarClick() {
    this.setState({
      modal: {
        showModal: true,
        modalTitle: t('remove_popup_title'),
        modalText: t('remove_image_popup_body'),
        showCancelAction: true,
        modalAcceptButtonText: t('ok'),
        modalCancelButtonText: t('cancel'),
        modalAcceptAction: this.resetAvatarAction,
        modalCancelAction: this.closeModal,
      },
      showDropdownActions: !this.state.showDropdownActions,
      showEditAvatar: false,
      showUpdateAvatar: false
    })
  }

  resetAvatarAction() {
    const { personId } = this.state
    this.props.deleteProfileImage(personId)
    this.closeModal()
  }

  flagAvatarClick() {
    this.setState({
      showFlagForReviewDialogForm: true,
      showDropdownActions: false,
    })
  }

  flagForReviewSubmitAction(reason, comments) {
    const moderationItem = {
      entityId: parseInt(this.state.userAvatarId, 10),
      moderatingPersonId: getLoggedInPersonId(),
      moderationReason_Tag: reason.tag,
      systemId: 3,
      comments,
    }
    this.props.postModerationItem(moderationItem)
    this.setState({
      showFlagForReviewDialogForm: false,
    })
  }

  closeModal() {
    this.setState({
      modal: {
        showModal: false,
        modalTitle: '',
        modalText: '',
        showCancelAction: false,
        modalAcceptButtonText: '',
        modalCancelButtonText: '',
        modalAcceptAction: null,
        modalCancelAction: null,
      },
      showFlagForReviewDialogForm: false,
    })
  }

  /////////////// FOR IMAGE CROP
  onCancelCrop() {
    this.setState({
      showEditAvatar: false,
      showUpdateAvatar: false,
    })
  }

  async onSaveCrop() {
    const { personId, fileUpload } = this.state
    const imageDataBase64 = this.cropperRef.current.getCroppedCanvas({ width: 512, height: 512 }).toDataURL(fileUpload.file.type).split(',')[1]
    this.props.postProfileImage(personId, imageDataBase64)
  }

  onZoomChange(zoom, oldZoom) {
    if (!oldZoom) {
      this.cropperRef.current.zoomTo(zoom)
    }
    if (!oldZoom || (Math.abs(zoom - oldZoom) >= this.state.zoomStep)) {
      this.setState({ zoom: zoom })
    }
  }
  /////////////// FOR IMAGE CROP

  handleDropzoneChange(filesAccepted, filesRejected) {
    if (filesRejected.length > 0) {
      if (filesRejected[0].size > MAX_FILE_SIZE) {
        const _state = this.state
        _state.fileUpload = {
          error: true,
          message: `${t('file_too_large')} ${MAX_FILE_SIZE / 1000000} MB`
        }
        this.setState(_state)
      }
    } else {
      const _state = this.state
      _state.fileUpload = {
        error: false,
        message: '',
        file: filesAccepted[0]
      }
      _state.showUpdateAvatar = false
      _state.showEditAvatar = true
      this.setState(_state);
    }
  }

  onTakeNewPhotoClick() {
    this.setState({ showCamera: true, backPhoto: false })
  }

  onTakeNewPhotoBackCameraClick() {
    this.setState({ showCamera: true , backPhoto: true})
  }

  renderEditAvatarMarkup() {
    const { file } = this.state.fileUpload

    return (
      <div className="fs-image w-100">
        <div className="row justify-content-center">
          <div className="col col-md-8 col-xl-6 d-flex justify-content-center">
            <div> {t('user_avatar_edit_message_1')} </div>
          </div>
        </div>

        <div className="row align-items-center justify-content-center mt-3">
          <div className="col col-md-6 crop-container">
            <div className="cropper">
              <Cropper
                ref={this.cropperRef}
                src={file.preview}
                aspectRatio={this.state.aspectRatio}
                style={this.state.cropSize}
                guides={false}
                cropBoxMovable={false}
                cropBoxResizable={false}
                dragMode="move"
                minCanvasWidth={this.state.cropSize.width}
                minCanvasHeight={this.state.cropSize.height}
                minCropBoxWidth={this.state.cropSize.width}
                minCropBoxHeight={this.state.cropSize.height}
                zoom={(e) => this.onZoomChange(e.detail.ratio, e.detail.oldRatio)}
              />
            </div>
            <div className="controls">
              <Slider
                value={this.state.zoom}
                min={0.1}
                max={5}
                step={this.state.zoomStep}
                aria-labelledby="Zoom"
                onChange={(e, zoom) => this.onZoomChange(zoom, null)}
              />
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col col-md-3">
            <input
              type='button'
              onClick={this.onCancelCrop}
              className="btn btn-rgis-blue w-100"
              value={t('cancel')}
            />
          </div>
          <div className="col col-md-3">
            <input
              type='button'
              onClick={this.onSaveCrop}
              className="btn btn-rgis-blue w-100"
              value={t('ok')}
            />
          </div>
        </div>

        <div className="row justify-content-center mt-3">
          <div className="col col-md-8 col-xl-6 d-flex justify-content-center">

            <i className="material-icons mr-2 mt-1">info</i>
            <div>{t('user_avatar_edit_message_2')}</div>

          </div>
        </div>
      </div>
    )
  }

  renderUpdateAvatarMarkup() {
    const { personName, userAvatarUrl, fileUpload} = this.state

    return (
      <div className="align-items-center text-center d-flex flex-column avatar-markup">
        <PostUserAvatar
          personName={personName}
          userAvatarUrl={userAvatarUrl}
          clickable={false}
        />
        <div className="mt-3"><b>{t('avatar_preview')}</b></div>

        <div className="mt-3 row justify-content-center">
          <div className="col-12 d-flex justify-content-center">
            {userAvatarUrl &&
              <div>
                {t('user_avatar_update_message_1')}
              </div>
            }
            {!userAvatarUrl &&
              <div>
                <p>{t('user_avatar_update_message_2')}</p>
                <p>{t('user_avatar_update_message_3')}</p>
              </div>
            }
          </div>
          <div className="col-12 d-flex justify-content-center">
            {(deviceIsIOS() && isIOSChrome()) &&
              <div>
                <b>{t('camera_not_available')}</b>
              </div>
            }
          </div>
        </div>

        <div className="mt-3 row justify-content-center align-items-center">
          <div className="col col-md-6 pb-2 pb-md-0">
            <input
              type='button'
              onClick={this.onTakeNewPhotoClick}
              className="btn btn-rgis-blue w-100"
              value={isMobileDevice() ? t('selfie_camera') : t('take_new_photo')}
              disabled={deviceIsIOS() && isIOSChrome() ? true : false}
            />
          </div>
          {isMobileDevice() ? <>
            <div className="col col-md-6 pb-2 pb-md-0">
              <input
                type='button'
                onClick={this.onTakeNewPhotoBackCameraClick}
                className="btn btn-rgis-blue w-100"
                value={t('back_camera')}
                disabled={deviceIsIOS() && isIOSChrome() ? true : false}
              />
            </div>
            </> : ''
          }
          <div className="col col-md-6">
            <FileUpload
              error={false}
              file={{}}
              accept={['image/jpg', 'image/png', 'image/jpeg']}
              onDrop={this.handleDropzoneChange}
              contentType="image"
              image={''}
              fileIndex={1}
              maxFileSize={MAX_FILE_SIZE}
            >
              <input
                type='button'
                // onClick={this.onSaveCrop}
                className="btn btn-rgis-blue w-100"
                value={t('upload_from_device')}
              />
            </FileUpload>
          </div>
        </div>

        {fileUpload.message && <div className="mt-3 text-center upload-error">{t('attachment_limitation')}</div>}
        {fileUpload.message && <div className="mt-1 text-center upload-error">{fileUpload.message}</div>}

      </div>
    )
  }

  renderAvatarMarkup() {
    const { personName, userAvatarUrl, showEditAvatar, showCamera, userAvatarIsFlagged } = this.state
    return (
      <div className="align-items-center text-center d-flex flex-column avatar-markup">
        {!showEditAvatar && !showCamera && userAvatarIsFlagged && <i className="indicator-icon material-icons flag" title={t('flagged_for_review')}>flag</i>}
        <PostUserAvatar
          personName={personName}
          userAvatarUrl={userAvatarUrl}
          clickable={false}
        />
        <div className="mt-3"><b>{t('avatar_preview')}</b></div>
      </div>
    )
  }

  onTakePhoto(dataUri) {
    const _state = this.state
    _state.fileUpload = {
      error: false,
      message: '',
      file: {
        preview: dataUri,
        name: 'new_browser_snapshot',
        type: 'jpg'
      }
    }
    _state.showUpdateAvatar = false
    _state.showEditAvatar = true
    _state.showCamera = false
    this.setState(_state);
  }

  renderCameraMarkup() {
    return (
      <div className="align-items-center text-center d-flex flex-column avatar-markup">
        <Camera
          onTakePhoto={this.onTakePhoto}
          idealFacingMode={this.state.backPhoto ? FACING_MODES.ENVIRONMENT : FACING_MODES.USER}
          isImageMirror={this.state.backPhoto ? false : true}
          imageType="jpg"
        />
      </div>
    )
  }

  getIsManager() {
    return this.props.role === 'MANAGER'
  }

  getIsNonDistrictUser() {
    let nonDistrictUser = nullSafeCheckIsFalse(this.props.isDistrictUser)
    return this.props.history.location.pathname === '/my-avatar' && nonDistrictUser ? true : false
  }



  render() {
    const { postPersonProfileImagePending, deleteProfileImagePending,
      moderationReasonsPending, postModerationItemPending, history,
    } = this.props

    const { modal, showDropdownActions, showUpdateAvatar, showEditAvatar, userAvatarUrl,
      showCamera, userAvatarIsFlagged, showFlagForReviewDialogForm, flagForReviewObj,
    } = this.state

    if (postPersonProfileImagePending || deleteProfileImagePending || moderationReasonsPending || postModerationItemPending) {
      return <Preloader />
    }

    return (
      <div className="user-avatar-container">
        <div className="row">
          <div className="col user-avatar-header">{t('user_avatar')}</div>
          <div className="col-2 d-flex align-items-center justify-content-end">
            <i
              onClick={history.goBack}
              className="material-icons arrow_back cursor-pointer other-avail-icon"
            >
              arrow_back
            </i>
            <div className="ml-2 dropleft avatar-actions">
              <div
                className="avatar-actions-button"
                onClick={this.dropdownActionsClick}
              >
                <img src={commentActionsImg} alt="Comment actions" />
              </div>
              <div className={`dropdown-menu ${showDropdownActions ? 'show' : ''}`} aria-labelledby="dropdownMenu2">
                <div>
                  {
                    (this.getIsManager() || this.getIsNonDistrictUser()) ?
                      <>
                        <button className="dropdown-item" type="button" onClick={this.updateAvatarClick}>{t('update_avatar')}</button>
                        <button className="dropdown-item" type="button" onClick={this.resetAvatarClick}>{t('reset')}</button>
                      </>
                      :
                      ''
                  }
                  {
                    !userAvatarIsFlagged && userAvatarUrl?
                      <button className="dropdown-item" type="button" onClick={this.flagAvatarClick}>{t('flag_for_review')}</button>
                      :
                      ''}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center position-relative">
          {!showUpdateAvatar && !showEditAvatar && this.renderAvatarMarkup()}

          {showUpdateAvatar && !showCamera && this.renderUpdateAvatarMarkup()}
          {showCamera && this.renderCameraMarkup()}

          {showEditAvatar && this.renderEditAvatarMarkup()}
        </div>

        <ActionModal
          {...modal}
        />

        <FlagForReviewDialogForm
          showModal={showFlagForReviewDialogForm}
          title={flagForReviewObj.title}
          reasons={flagForReviewObj.reasons}
          commentText={flagForReviewObj.commentText}
          commentsEnabled={flagForReviewObj.commentsEnabled}
          modalSubmitAction={this.flagForReviewSubmitAction}
          modalCancelAction={this.closeModal}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    postPersonProfileImage: state.profileReducer.postPersonProfileImage,
    postPersonProfileImagePending: state.profileReducer.postPersonProfileImagePending,
    postPersonProfileImageError: state.profileReducer.postPersonProfileImageError,

    deleteProfileImagePending: state.profileReducer.deleteProfileImagePending,
    deleteProfileImageError: state.profileReducer.deleteProfileImageError,

    moderationReasons: state.moderationReducer.moderationReasons,
    moderationReasonsPending: state.moderationReducer.moderationReasonsPending,
    moderationReasonsError: state.moderationReducer.moderationReasonsError,
    postModerationItemError: state.moderationReducer.postModerationItemError,
    postModerationItemPending: state.moderationReducer.postModerationItemPending,

    role: state.loginReducer.profile.role,
    isDistrictUser: state.loginReducer.profile.isDistrictUser,

    profileLocale: state.loginReducer.profileLocale,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      postProfileImage: (personId, imageDataBase64) => postProfileImage(personId, imageDataBase64),
      deleteProfileImage: (personId) => deleteProfileImage(personId),
      getModerationReasons: () => getModerationReasons(),
      postModerationItem: (moderationItem) => postModerationItem(moderationItem),
    },
    dispatch
  )
}

UserAvatarContainer.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
  personId: PropTypes.string,
  personName: PropTypes.string,
  userAvatarUrl: PropTypes.string,

  postProfileImage: PropTypes.func,
  postPersonProfileImage: PropTypes.string,
  postPersonProfileImagePending: PropTypes.bool,
  postPersonProfileImageError: PropTypes.object,

  deleteProfileImage: PropTypes.func,
  deleteProfileImagePending: PropTypes.bool,
  deleteProfileImageError: PropTypes.object,

  role: PropTypes.string,
  isDistrictUser: PropTypes.string,

  getModerationReasons: PropTypes.func,
  moderationReasons: PropTypes.array,
  moderationReasonsPending: PropTypes.bool,
  moderationReasonsError: PropTypes.object,

  postModerationItem: PropTypes.func,
  postModerationItemPending: PropTypes.bool,
  postModerationItemError: PropTypes.object,

  profileLocale: PropTypes.string,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserAvatarContainer)
