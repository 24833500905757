import * as types from './actionTypes'
import services from '../services'

export function getShifts() {
  return dispatch => {
    dispatch(getShiftsActionPending(true))
    services.shifts
      .getShifts()
      .then(response => {
        dispatch(getShiftsActionSuccess(response))
        dispatch(getShiftsActionPending(false))
      })
      .catch(error => {
        dispatch(getShiftsActionError(error))
        dispatch(getShiftsActionPending(false))
      })
  }
}

const getShiftsActionSuccess = response => {
  return {
    type: types.GET_SHIFTS_SUCCESS,
    payload: response.data,
  }
}
const getShiftsActionError = response => {
  return {
    type: types.GET_SHIFTS_ERROR,
    error: response,
  }
}
const getShiftsActionPending = status => {
  return {
    type: types.GET_SHIFTS_PENDING,
    status,
  }
}

export function putAcceptShift(shiftId) {
  return dispatch => {
    dispatch(putAcceptShiftActionPending(true))
    services.shifts
      .putAcceptShift(shiftId)
      .then(() => {
        dispatch(putAcceptShiftActionSuccess(shiftId))
        dispatch(putAcceptShiftActionPending(false))
      })
      .catch(error => {
        dispatch(putAcceptShiftActionError(error))
        dispatch(putAcceptShiftActionPending(false))
      })
  }
}

const putAcceptShiftActionSuccess = shiftId => {
  return {
    type: types.PUT_ACCEPT_SHIFT_SUCCESS,
    shiftId,
  }
}
const putAcceptShiftActionError = error => {
  return {
    type: types.PUT_ACCEPT_SHIFT_ERROR,
    error,
  }
}
const putAcceptShiftActionPending = status => {
  return {
    type: types.PUT_ACCEPT_SHIFT_PENDING,
    status,
  }
}

export function putRejectShift(shiftId) {
  return dispatch => {
    dispatch(putRejectShiftActionPending(true))
    services.shifts
      .putRejectShift(shiftId)
      .then(() => {
        dispatch(putRejectShiftActionSuccess(shiftId))
        dispatch(putRejectShiftActionPending(false))
      })
      .catch(error => {
        dispatch(putRejectShiftActionError(error))
        dispatch(putRejectShiftActionPending(false))
      })
  }
}

const putRejectShiftActionSuccess = shiftId => {
  return {
    type: types.PUT_REJECT_SHIFT_SUCCESS,
    shiftId,
  }
}
const putRejectShiftActionError = response => {
  return {
    type: types.PUT_REJECT_SHIFT_ERROR,
    error: response,
  }
}
const putRejectShiftActionPending = status => {
  return {
    type: types.PUT_REJECT_SHIFT_PENDING,
    status,
  }
}
