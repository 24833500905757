import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { t } from '../../../utils/i18n'

import './AvailableItem.scss'

export default class AvailableItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      availableEdit: false,
    }
    this.openEditStatus = this.openEditStatus.bind(this)
    this.renderEditButton = this.renderEditButton.bind(this)
  }

  openEditStatus() {
    this.props.handleAvailabilityChange(this.props.dataToRender.item.date)
  }

  renderEditButton(dataToRender) {
    const hideButton = dataToRender.showEditStatus === false || dataToRender.item.confirmed === 'pending'
    if (hideButton) {
      return ''
    }
    return (
      <div className="col edit_status_icon">
        <button onClick={this.openEditStatus}>
          <i className="material-icons">create</i>
        </button>
      </div>
    )
  }

  render() {
    const { dataToRender } = this.props

    return (
      <div className="card not_scheduled">
        <div className="card-body">
          <div className="row">
            <div className="col">
              <div className="not_scheduled_text">{t('not_scheduled')}</div>
            </div>
            {this.renderEditButton(dataToRender)}
          </div>
        </div>
      </div>
    )
  }
}

AvailableItem.propTypes = {
  dataToRender: PropTypes.object,
  handleAvailabilityChange: PropTypes.func,
}
