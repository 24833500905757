// Authorization URL Encoding Callback
const callbackEncoded = encodeURIComponent(
  `${process.env.REACT_APP_UI_URL}/callback?&response_type=id_token token&scope=openid notifications-api-user profile api1 shifts-api-user contacts-api-user moderation-api-user badges-api-user compliments-api-user timepunches-api-user translation-api-user profile-api-user&`
)
// Authorization URL Encoding Connect and Authorize Return URL
const connectAuthorizeEncoded = encodeURIComponent(
  `/connect/authorize/callback?client_id=js&redirect_uri=${callbackEncoded}`
)

// prettier-ignore
export const config = {
  IDENTITY_API_URL: process.env.REACT_APP_IDENTITY_API_URL,
  SCHEDULE_API_URL: process.env.REACT_APP_SCHEDULE_API_URL,
  CALLBACK_API_URL: process.env.REACT_APP_CALLBACK_API_URL,
  LOGOUT_REDIRECT_URL: process.env.REACT_APP_LOGOUT_REDIRECT_URL,
  AUTHORIZATION_URL: `${process.env.REACT_APP_IDENTITY_API_URL}/account/login?returnUrl=${connectAuthorizeEncoded}`,
  PEOPLE_API_URL: process.env.REACT_APP_PEOPLE_API_URL,
  NOTIFICATIONS_API_URL: process.env.REACT_APP_NOTIFICATIONS_API_URL,
  FEEDBACK_API_URL: process.env.REACT_APP_FEEDBACK_API_URL,
  SOCIAL_API_URL: process.env.REACT_APP_SOCIAL_API_URL,
  SHIFTS_API_URL: process.env.REACT_APP_SHIFTS_API_URL,
  BADGES_API_URL: process.env.REACT_APP_BADGES_API_URL,
  COMPLIMENTS_API_URL: process.env.REACT_APP_COMPLIMENTS_API_URL,
  MODERATION_API_URL: process.env.REACT_APP_MODERATION_API_URL,
  CONTACTS_API_URL: process.env.REACT_APP_CONTACTS_API_URL,
  TIME_PUNCHES_API_URL: process.env.REACT_APP_TIME_PUNCHES_API_URL,
  PROFILE_API_URL: process.env.REACT_APP_PROFILE_API_URL,
  RSCHEDULE_URL: process.env.REACT_APP_RSCHEDULE_URL,
  TRANSLATE_API_URL: process.env.REACT_APP_TRANSLATE_API_URL,
  FEATURE_FLAG_4_7_2: process.env.REACT_APP_FEATURE_FLAG_4_7_2,
  FEATURE_FLAG_5_1: process.env.REACT_APP_FEATURE_FLAG_5_1
}
