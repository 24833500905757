import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import axios from 'axios';

import { addNewPost, editPost } from '../../state/actions/newPostAction'
import { uploadFilePromise, deleteFilePromise } from '../../components/utils/utils'
import { t } from '../../utils/i18n'

import { hasNewsAccess } from '../../components/utils/featureAccessUtils'

import Preloader from '../../components/Preloader'
import FileUpload from '../../components/FileUpload'
import videoImg from '../../styles/images/film-solid.svg'
import imageImg from '../../styles/images/image-solid.svg'
import pdfImg from '../../styles/images/file-pdf-solid.svg'
import ActionModal from '../../components/ActionModal'
import './NewPostContainer.scss'

const MAX_FILE_SIZE = 500000000
const MAX_UPLOADING_FILES = {
  video: 1,
  pdf: 5,
  image: 5
}
let _isMounted = false
const CANCEL_REQUEST_MSG = 'Cancel file upload request'

class NewPostContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newPostInfo: {
        channelId: null,
        title: '',
        body: '',
        commentsEnabled: false,
        likesEnabled: false,
        dislikesEnabled: false,
        hideFromHourlyTeamMembers: false,
        postAttachments: [],
      },
      showDiscardPopup: false,
      fileUploadError: {
        error: false,
        message: ''
      },
      newPost: true,
      editPost: false,
      countFileTypes: {
        video: 0,
        image: 0,
        pdf: 0,
      },
      bodyLineHeight: 24,
    }

    this.onFieldChange = this.onFieldChange.bind(this)
    this.onSubmitNewPost = this.onSubmitNewPost.bind(this)
    this.discardChangesAction = this.discardChangesAction.bind(this)
    this.discardChanges = this.discardChanges.bind(this)
    this.cancelDiscardAction = this.cancelDiscardAction.bind(this)
    this.handleDropzoneChange = this.handleDropzoneChange.bind(this)
    this.removeFile = this.removeFile.bind(this)
    this.checkAllUploadsDone = this.checkAllUploadsDone.bind(this)
  }

  componentDidMount() {
    _isMounted = true
    const channelId = parseInt(this.props.match.params.channel_id, 10)
    const _state = this.state
    _state.newPostInfo.channelId = channelId

    const _locationState = this.props.location.state
    if (_locationState !== undefined) {
      const _postAttachaments = _locationState.postAttachments.map((file, index) =>
        ({ fileIndex: index, file, newUpload: false, uploadDone: null, uploadPercentage: null, error: false })
      )
      _state.newPostInfo = {
        ..._state.newPostInfo,
        ..._locationState,
        postAttachments: _postAttachaments
      }
      _state.editPost = true
      _state.newPost = false
      _locationState.postAttachments.map((file) => _state.countFileTypes[file.contentType] += 1)
      this.setState(_state)
    }
  }

  componentDidUpdate(prevProps) {
    document.querySelectorAll("[data-field='body']").forEach((item) => {
      item.rows = ~~(item.scrollHeight / this.state.bodyLineHeight)
    })

    if (prevProps.featureAccessPending === true && this.props.featureAccessPending === false) {
      if (!hasNewsAccess(this.props.featureAccess)) {
        return <Redirect to="/profile" />
      }
    }
  }
  
  componentWillUnmount() {
    _isMounted = false
    const { newPostInfo } = this.state
    newPostInfo.postAttachments.map((file) => {
      if (file.newUpload && !file.uploadDone) {
        file.fileRequestToken.cancel(CANCEL_REQUEST_MSG)
      }
      return ''
    })
  }
  onFieldChange(evt) {
    const { field } = evt.target.dataset
    const _state = this.state
    if (field === 'title' || field === 'body') {
      const { value } = evt.target
      _state.newPostInfo[field] = value
    } else {
      _state.newPostInfo[field] = !_state.newPostInfo[field]
    }

    this.setState(_state)
  }

  onSubmitNewPost(evt) {
    evt.preventDefault()
    const { newPost, editPost } = this.state
    let newPostObj = this.state.newPostInfo

    newPostObj = {
      ...newPostObj,
      postAttachments: newPostObj.postAttachments.map(att => att.file),
    }

    if (newPost === true && editPost === false) {
      this.props.addNewPostAction(newPostObj, this.props.history)
    }
    if (newPost === false && editPost === true) {
      this.props.editPostAction(newPostObj, this.props.history)
    }
  }

  discardChangesAction() {
    const { newPostInfo } = this.state
    newPostInfo.postAttachments.map((file) => {
      if (file.newUpload && file.uploadDone) {
        deleteFilePromise(file.file.previewStorageUrl)
        deleteFilePromise(file.file.storageUrl)
      }
      return ''
    })
    this.props.history.push(`/news/channel/${newPostInfo.channelId}`)
  }

  discardChanges() {
    this.setState({ showDiscardPopup: true })
  }
  cancelDiscardAction() {
    this.setState({ showDiscardPopup: false })
  }

  handleDropzoneChange(filesAccepted, filesRejected, fileIndex, contentType) {
    const _state = this.state
    _state.fileUploadError = {
      error: false,
      message: ''
    }
    //checking max number of uploaded file types
    if (_state.countFileTypes[contentType] + 1 > MAX_UPLOADING_FILES[contentType]) {
      let message = '';
      switch (contentType) {
        case 'video':
          message = t('video_error_message')
          break
        case 'pdf':
          message = t('pdf_error_message')
          break
        case 'image':
          message = t('image_error_message')
          break
        default:
          break
      }
      _state.fileUploadError = {
        error: true,
        message
      }
      
      this.setState(_state)
      return
    }

    _state.countFileTypes[contentType] += 1

    if (filesRejected.length > 0) {
      if (filesRejected[0].size > MAX_FILE_SIZE) {
        const _state = this.state
        _state.fileUploadError = {
          error: true,
          message: `${t('file_too_large')} ${MAX_FILE_SIZE / 1000000} MB`
        }
        this.setState(_state)
      }
    } else {
      const file = filesAccepted[0]
      const fileSize = file.size
      const fileRequestToken = axios.CancelToken.source()
      const newFileObj = {
        fileIndex,
        file: {
          fileName: file.name,
          contentType
        },
        newUpload: true,
        uploadDone: false,
        uploadPercentage: 0,
        error: false,
        fileRequestToken
      }
      _state.newPostInfo.postAttachments.push(newFileObj)
      this.setState(_state);

      const onUploadProgress = (progressEvent) => {
        if (_isMounted === false) return
        const _state = this.state
        let uploadPercentage = Math.round(progressEvent.loaded * 100 / fileSize)
        if (uploadPercentage >= 100) {
          uploadPercentage = 99
        }
        const index = _state.newPostInfo.postAttachments.findIndex((att) => att.fileIndex === fileIndex)
        _state.newPostInfo.postAttachments[index] = {
          ..._state.newPostInfo.postAttachments[index],
          uploadPercentage,
        }

        this.setState(_state)
      }
      uploadFilePromise(file, onUploadProgress, fileRequestToken)
        .then((response) => {
          const _state = this.state
          const index = _state.newPostInfo.postAttachments.findIndex((att) => att.fileIndex === fileIndex)
          _state.newPostInfo.postAttachments[index] = {
            ..._state.newPostInfo.postAttachments[index],
            file: {
              ..._state.newPostInfo.postAttachments[index].file,
              ...response.data
            },
            uploadDone: true,
          }
          this.setState(_state)
        }).catch((error) => {
          if (error.message !== CANCEL_REQUEST_MSG) {
            const _state = this.state
            const index = _state.newPostInfo.postAttachments.findIndex((att) => att.fileIndex === fileIndex)
            _state.newPostInfo.postAttachments[index] = {
              ..._state.newPonewPostInfost.postAttachments[index],
              error: true
            }
            this.setState(_state)
          }
        })
    }
  }

  removeFile(att) {
    const _state = this.state
    _state.newPostInfo.postAttachments = _state.newPostInfo.postAttachments.filter((mapFile) => mapFile.fileIndex !== att.fileIndex)
    _state.countFileTypes[att.file.contentType] -= 1
    _state.fileUploadError = {
      error: false,
      message: ''
    }
    deleteFilePromise(att.file.previewStorageUrl)
    deleteFilePromise(att.file.storageUrl)
    this.setState(_state)
  }
  renderUploadFiles() {
    const { postAttachments } = this.state.newPostInfo
    const { fileUploadError } = this.state
    const lastIndex = postAttachments.length ? postAttachments[postAttachments.length - 1].fileIndex : 0
    return (
      <div>
        <div className="row">
          <div className="col">
            <FileUpload
              error={false}
              file={{}}
              accept={['video/*',]}
              onDrop={this.handleDropzoneChange}
              contentType="video"
              image={videoImg}
              fileIndex={lastIndex + 1}
              maxFileSize={MAX_FILE_SIZE}
            />
          </div>
          <div className="col">
            <FileUpload
              error={false}
              file={{}}
              accept={['image/jpg', 'image/png', 'image/jpeg']}
              onDrop={this.handleDropzoneChange}
              contentType="image"
              image={imageImg}
              fileIndex={lastIndex + 1}
              maxFileSize={MAX_FILE_SIZE}
            />
          </div>
          <div className="col">
            <FileUpload
              error={false}
              file={{}}
              accept={['.pdf']}
              onDrop={this.handleDropzoneChange}
              contentType="pdf"
              image={pdfImg}
              fileIndex={lastIndex + 1}
              maxFileSize={MAX_FILE_SIZE}
            />
          </div>
        </div>
        {fileUploadError.error && <div className="mt-3 text-center upload-error">{t('attachment_limitation')}</div>}
        {fileUploadError.error && <div className="mt-1 text-center upload-error">{fileUploadError.message}</div>}
        <div className="mt-3">
          {postAttachments.map((att, index) => {
            return (
              <div className="row mt-2" key={index}>
                <div className="col d-flex align-items-center justify-content-center">
                  <div className="file-name">{att.file.fileName}</div>
                  {(!att.newUpload || att.uploadDone) ?
                    <i
                      className="material-icons delete cursor-pointer other-avail-icon ml-3"
                      onClick={() => this.removeFile(att)}
                    >
                      delete
                    </i>
                    :
                    <div className="ml-3 d-flex align-items-center">
                      <div className="spinner-border mr-2" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                      {att.uploadPercentage} %
                    </div>
                  }
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  checkAllUploadsDone() {
    const { postAttachments } = this.state.newPostInfo
    let ok = true
    Object.keys(postAttachments).map((key) => {
      if (postAttachments[key].newUpload && !postAttachments[key].uploadDone) {
        ok = false
      }
      return ''
    })
    return ok
  }
  renderNewPostSection() {
    const { title, body, commentsEnabled,
      likesEnabled, dislikesEnabled, hideFromHourlyTeamMembers
    } = this.state.newPostInfo
    return (
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <div className="section">
                <form onSubmit={this.onSubmitNewPost}>
                  <div className="form-group">
                    <label htmlFor="post_title">{t('post_title')}</label>
                    <input
                      type="text"
                      id="post_title"
                      maxLength="128"
                      className="form-control"
                      value={title}
                      data-field="title"
                      onChange={this.onFieldChange}
                      onKeyPress={(e)=>{e.which === 13 && e.preventDefault();}}
                      placeholder={t('optional_field')}
                      autoComplete="off"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="message_body">{t('message_body')}</label>
                    <textarea
                      type="text"
                      id="message_body"
                      className="form-control"
                      value={body}
                      data-field="body"
                      onChange={this.onFieldChange}
                      placeholder={t('required_field')}
                      maxLength="2000"
                    />
                    <small id="emailHelp" className="form-text text-muted"></small>
                  </div>
                  <div className="form-group row align-items-center">
                    <div className="col-md-2 col-12 mt-md-0 mt-2 d-flex align-items-center">
                      <label className="m-0" htmlFor="likes">{t('likes')}</label>
                      <input
                        type="checkbox"
                        id="likes"
                        className="ml-2"
                        value={likesEnabled}
                        checked={likesEnabled}
                        data-field="likesEnabled"
                        onChange={this.onFieldChange}
                        data-toggle="toggle"
                      />
                    </div>
                    <div className="col-md-2 col-12 mt-md-0 mt-2 d-flex align-items-center">
                      <label className="m-0" htmlFor="dislikes">{t('dislikes')}</label>
                      <input
                        type="checkbox"
                        id="dislikes"
                        className="ml-2"
                        value={dislikesEnabled}
                        checked={dislikesEnabled}
                        data-field="dislikesEnabled"
                        onChange={this.onFieldChange}
                        data-toggle="toggle"
                      />
                    </div>
                    <div className="col-md-2 col-12 mt-md-0 mt-2 d-flex align-items-center">
                      <label className="m-0" htmlFor="comments">{t('comments')}</label>
                      <input
                        type="checkbox"
                        id="comments"
                        className="ml-2"
                        value={commentsEnabled}
                        checked={commentsEnabled}
                        data-field="commentsEnabled"
                        onChange={this.onFieldChange}
                        data-toggle="toggle"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="m-0" htmlFor="only_for_managers">{t('only_for_managers')}</label>
                    <input
                      type="checkbox"
                      id="only_for_managers"
                      className="ml-2"
                      value={hideFromHourlyTeamMembers}
                      checked={hideFromHourlyTeamMembers}
                      data-field="hideFromHourlyTeamMembers"
                      onChange={this.onFieldChange}
                      data-toggle="toggle"
                    />
                  </div>

                  {this.renderUploadFiles()}

                  <div className="mt-4 d-flex justify-content-center">
                    <div className="col-md-2 col-6">
                      <input
                        type='button'
                        onClick={this.discardChanges}
                        className="btn btn-secondary cursor-pointer uppercase w-100"
                        value={t('discard')}
                      />
                    </div>
                    <div className="col-md-2 col-6">
                      <input
                        type='submit'
                        onClick={this.discardEditChanges}
                        className="btn btn-rgis-blue cursor-pointer uppercase w-100"
                        value={t('submit')}
                        disabled={!body.length || !this.checkAllUploadsDone()}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }


  render() {
    const { newPostPending, newPostError, featureAccessPending } = this.props
    const { showDiscardPopup, newPost } = this.state

    if (newPostPending || featureAccessPending) {
      return (
        <Preloader />
      )
    }

    if (Object.keys(newPostError).length) {
      if (newPostError.status === 403) {
        return (
          <div className="new-post-container">
            <div className="">
              <div className="text-center channel-header">
                <div>{t('cant_create_post')}</div>
              </div>
            </div>
          </div>
        )
      }
    }

    return (
      <div className="new-post-container">
        <div className="row">
          <div className="col channel-header"> {newPost ? t('new_post') : t('edit_post')}</div>
        </div>
        {this.renderNewPostSection()}
        <ActionModal
          showModal={showDiscardPopup}
          modalTitle={t('remove_popup_title')}
          modalText={t('discard_post_changes')}
          showCancelAction={true}
          modalAcceptButtonText={t('yes')}
          modalCancelButtonText={t('cancel')}
          modalAcceptAction={this.discardChangesAction}
          modalCancelAction={this.cancelDiscardAction}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    newPostPending: state.newPostReducer.newPostPending,
    newPostError: state.newPostReducer.newPostError,
    featureAccess: state.featureAccessReducer.featureAccess,
    featureAccessPending: state.featureAccessReducer.featureAccessPending,
    profileLocale: state.loginReducer.profileLocale,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addNewPostAction: (newPostObj, history) => addNewPost(newPostObj, history),
      editPostAction: (postObj, history) => editPost(postObj, history),
    },
    dispatch
  )
}

NewPostContainer.propTypes = {
  addNewPostAction: PropTypes.func,
  editPostAction: PropTypes.func,
  newPostPending: PropTypes.bool,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  newPostError: PropTypes.object,
  featureAccess: PropTypes.object,
  featureAccessPending: PropTypes.bool,
  profileLocale: PropTypes.string,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewPostContainer)
