import moment from 'moment'

import { getPreferredLanguage, getCountry } from '../../components/utils/localStorageUtils'
import { isDefined, isDefinedAndNotEmpty } from '../../components/utils/nullSafeCheckUtils'
import { t } from '../../utils/i18n'

const FRANCE_ABBREV = 'FR'
const UNITED_STATES_ABBREV = 'US'
const UNITED_KINGDOM_ABBREV = 'GB'
const CANADA_ABBREV = 'CA'

const ENGLISH_ABBREV = 'en'
const FRENCH_ABBREV = 'fr'
const SPANISH_ABBREV = 'es'

const FRANCE_LT_FORMAT = 'HH:mm'
const UNITED_KINGDOM_LT_FORMAT = 'HH:mm'
const UNITED_STATES_LT_FORMAT = 'hh:mm a'
const CANADA_LT_FORMAT = 'HH:mm'

const FRANCE_FRENCH_LONG_DATE_FORMAT = 'DD/MM/YYYY'
const UNITED_STATES_ENGLISH_LONG_DATE_FORMAT = 'M/D/YYYY'
const UNITED_STATES_SPANISH_LONG_DATE_FORMAT = 'M/D/YYYY'
const UNITED_KINGDOM_ENGLISH_LONG_DATE_FORMAT = 'DD/MM/YY'
const CANADA_ENGLISH_LONG_DATE_FORMAT = 'DD/MM/YYYY'
const CANADA_FRENCH_LONG_DATE_FORMAT = 'YYYY-MM-DD'

const FRANCE_FRENCH_LONG_DATE_FORMAT_FOR_DATE_PICKER = 'dd/MM/yyyy'
const UNITED_STATES_ENGLISH_LONG_DATE_FORMAT_FOR_DATE_PICKER = 'MM/dd/yyyy'
const UNITED_STATES_SPANISH_LONG_DATE_FORMAT_FOR_DATE_PICKER = 'MM/dd/yyyy'
const UNITED_KINGDOM_ENGLISH_LONG_DATE_FORMAT_FOR_DATE_PICKER = 'dd/MM/yy'
const CANADA_ENGLISH_LONG_DATE_FORMAT_FOR_DATE_PICKER = 'yyyy-MM-dd'
const CANADA_FRENCH_LONG_DATE_FORMAT_FOR_DATE_PICKER = 'yyyy-MM-dd'

export function dateAIsAfterDateB(dateA, dateB) {
    let formattedA = formatDateNotForDisplay(dateA)
    let momentA = moment(formattedA, 'YYYY-MM-DD')
    
    let formattedB = formatDateNotForDisplay(dateB)
    let momentB = moment(formattedB, 'YYYY-MM-DD')

    return momentA.isAfter(momentB)
}

export function formatDateNotForDisplay(date) {
    if (!isDefinedAndNotEmpty(date)) {
        return null
    }

    return moment(date).format('YYYY-MM-DD')
}

export function formatDate(date, format = 'L') {
    if (!isDefinedAndNotEmpty(date)) {
        return null
    }

    return moment(date)
        .locale(getLocale())
        .format(format)
}

export function getMonthAndDay(date) {
    if (!isDefinedAndNotEmpty(date)) {
        return null
    }

    return getMonth(date).concat(' ', getDay(date))
}

export function getMonth(date) {
    let parsedDate = formatDateNotForDisplay(date)
    return t(moment(parsedDate)
        .locale(getLocale())
        .format('MMMM').toLowerCase())
}

export function getDay(date) {
    let parsedDate = formatDateNotForDisplay(date)
    return moment(parsedDate)
        .locale(getLocale())
        .format('DD')
}

export function getDayOfWeek(date) {
    let parsedDate = formatDateNotForDisplay(date)
    return t(moment(parsedDate)
        .locale(getLocale())
        .format('dddd').toLowerCase())
}

export function isEventCompleted(date, time, duration) {
    const eventEndTime = moment(formatDateNotForDisplay(date) + ' ' + formatTime(time)).add(duration, 'hours')
    const currentTime = moment(new Date())
    return currentTime.isAfter(eventEndTime)
}

export function getStartDate(day = 5) {
    let startDate = moment()
    return startDate.subtract(startDate.day() > day ? 0 : startDate.day() + 1, 'day')
}

export function getYear(date) {
    let parsedDate = formatDateNotForDisplay(date)
    return moment(parsedDate)
        .locale(getLocale())
        .format('YYYY').toLowerCase()
}

export function isBetweenHours(date, time, startHour = 1, endHour = 24) {
    const startTime = formatDateNotForDisplay(date) + ' ' + formatTime(time)
    const endTime = moment(new Date())
    const duration = moment.duration(endTime.diff(startTime))
    const hours = parseInt(duration.asHours())
    if (hours >= startHour && hours < endHour) return true
    return false
  }

export function isToday(date) {
    let parsedDate = formatDateNotForDisplay(date)
    return moment(parsedDate).isSame(moment(), 'd')
}

export function dateIsInThePast(dateToCheck) {
    const today = new moment().startOf('day')
    const dateToCheckMoment = new moment(new Date(dateToCheck))
    return today > dateToCheckMoment
}

export function getEndOfWeek(startOfCurrentWeek) {
    if (!isDefinedAndNotEmpty(startOfCurrentWeek)) {
        return null
    }

    return formatDateNotForDisplay(moment(startOfCurrentWeek).add(6, 'days'))
}

export function getStartOfNextWeek(startOfCurrentWeek) {
    if (!isDefinedAndNotEmpty(startOfCurrentWeek)) {
        return null
    }

    return formatDateNotForDisplay(moment(startOfCurrentWeek).add(1, 'weeks'))
}

export function getStartOfPreviousWeek(startOfCurrentWeek) {
    if (!isDefinedAndNotEmpty(startOfCurrentWeek)) {
        return null
    }

    return formatDateNotForDisplay(moment(startOfCurrentWeek).subtract(1, 'weeks'))
}

export function formatTime(time, format = 'LT') {
    if (!isDefinedAndNotEmpty(time)) {
        return null
    }

    if (typeof time === 'string' || time instanceof String){
        time = time.trim();
    }
    
    var momentTime = moment(time, format);

    if (!momentTime.isValid()) {
        const tempDateStr = '01-01-2020 '
        time = tempDateStr + time
    }
    return momentTime
        .locale(getLocale())
        .format(format)
}

export function formatTimeNotForDisplayIncludeFractionalSeconds(time) {
    if (!isDefinedAndNotEmpty(time)) {
        return null
    }
    if (!moment(time).isValid()) {
        const tempDateStr = '01-01-2020 '
        time = tempDateStr + time
    }
    return moment(time).format(TWENTY_FOUR_HOUR_TIME_FORMAT_INCLUDE_FRACTIONAL_SECONDS)
}

export const TWENTY_FOUR_HOUR_TIME_FORMAT_INCLUDE_FRACTIONAL_SECONDS = 'HH:mm:ss.SSS'
export const TWENTY_FOUR_HOUR_TIME_FORMAT_INCLUDE_SECONDS = 'HH:mm:ss'

export function getTimeFormatByCountry(country) {
    if (!isDefinedAndNotEmpty(country)) {
        return UNITED_STATES_LT_FORMAT
    } else if (country.toUpperCase() === UNITED_STATES_ABBREV) {
        return UNITED_STATES_LT_FORMAT
    } else if (country.toUpperCase() === UNITED_KINGDOM_ABBREV) {
        return UNITED_KINGDOM_LT_FORMAT
    } else if (country.toUpperCase() === FRANCE_ABBREV) {
        return FRANCE_LT_FORMAT
    } else if (country.toUpperCase() === CANADA_ABBREV) {
        return CANADA_LT_FORMAT
    }
}

export function formatTimeNotForDisplayIncludeSeconds(time) {
    if (!isDefinedAndNotEmpty(time)) {
        return null
    }
    if (!moment(time).isValid()) {
        const tempDateStr = '01-01-2020 '
        time = tempDateStr + time
    }
    return moment(time).format(TWENTY_FOUR_HOUR_TIME_FORMAT_INCLUDE_SECONDS)
}

export function formatTimeNotForDisplay(time) {
    if (!isDefinedAndNotEmpty(time)) {
        return null
    }
    if (!moment(time).isValid()) {
        const tempDateStr = '01-01-2020 '
        time = tempDateStr + time
    }
    return moment(time).format('HH:mm')
}

export function timeAIsAfterTimeB(timeA, timeB) {
    let formattedA = formatTimeNotForDisplayIncludeFractionalSeconds(timeA)
    let momentA = moment(formattedA, TWENTY_FOUR_HOUR_TIME_FORMAT_INCLUDE_FRACTIONAL_SECONDS)
    
    let formattedB = formatTimeNotForDisplayIncludeFractionalSeconds(timeB)
    let momentB = moment(formattedB, TWENTY_FOUR_HOUR_TIME_FORMAT_INCLUDE_FRACTIONAL_SECONDS)

    return momentA.isAfter(momentB)
}

export function timeAIsSameAsTimeB(timeA, timeB) {
    let formattedA = formatTimeNotForDisplayIncludeFractionalSeconds(timeA)
    let momentA = moment(formattedA, TWENTY_FOUR_HOUR_TIME_FORMAT_INCLUDE_FRACTIONAL_SECONDS)
    
    let formattedB = formatTimeNotForDisplayIncludeFractionalSeconds(timeB)
    let momentB = moment(formattedB, TWENTY_FOUR_HOUR_TIME_FORMAT_INCLUDE_FRACTIONAL_SECONDS)

    return momentA.isSame(momentB)
}

export function timeAIsBetweenTimeBAndTimeC(timeA, timeB, timeC) {
    let formattedA = formatTimeNotForDisplayIncludeFractionalSeconds(timeA)
    let momentA = moment(formattedA, TWENTY_FOUR_HOUR_TIME_FORMAT_INCLUDE_FRACTIONAL_SECONDS)
    
    let formattedB = formatTimeNotForDisplayIncludeFractionalSeconds(timeB)
    let momentB = moment(formattedB, TWENTY_FOUR_HOUR_TIME_FORMAT_INCLUDE_FRACTIONAL_SECONDS)

    let formattedC = formatTimeNotForDisplayIncludeFractionalSeconds(timeC)
    let momentC = moment(formattedC, TWENTY_FOUR_HOUR_TIME_FORMAT_INCLUDE_FRACTIONAL_SECONDS)

    return momentA.isBetween(momentB, momentC)
}

export function getLocale() {
    return formatLocale(getPreferredLanguage(), getCountry())
}

export function formatLocale(language, country) {
    if (!isDefined(language) || !isDefined(country)) {
        language = ENGLISH_ABBREV
        country = UNITED_STATES_ABBREV
    }

    return language.toLowerCase().concat('-', country.toUpperCase())
}

export function getCurrentLongDateFormatForDatePicker() {
    const country = getCountry()
    const language = getPreferredLanguage()

    if (language.toLowerCase() === FRENCH_ABBREV && country.toUpperCase() === FRANCE_ABBREV) {
        return FRANCE_FRENCH_LONG_DATE_FORMAT_FOR_DATE_PICKER
    } else if (language.toLowerCase() === SPANISH_ABBREV && country.toUpperCase() === UNITED_STATES_ABBREV) {
        return UNITED_STATES_SPANISH_LONG_DATE_FORMAT_FOR_DATE_PICKER
    } else if (language.toLowerCase() === ENGLISH_ABBREV && country.toUpperCase() === UNITED_STATES_ABBREV) {
        return UNITED_STATES_ENGLISH_LONG_DATE_FORMAT_FOR_DATE_PICKER
    } else if (language.toLowerCase() === ENGLISH_ABBREV && country.toUpperCase() === UNITED_KINGDOM_ABBREV) {
        return UNITED_KINGDOM_ENGLISH_LONG_DATE_FORMAT_FOR_DATE_PICKER
    } else if (language.toLowerCase() === ENGLISH_ABBREV && country.toUpperCase() === CANADA_ABBREV) {
        return CANADA_ENGLISH_LONG_DATE_FORMAT_FOR_DATE_PICKER
    } else if (language.toLowerCase() === FRENCH_ABBREV && country.toUpperCase() === CANADA_ABBREV) {
        return CANADA_FRENCH_LONG_DATE_FORMAT_FOR_DATE_PICKER
    } else {
        return UNITED_STATES_ENGLISH_LONG_DATE_FORMAT_FOR_DATE_PICKER
    }
}

export function updateMoment(language, country) {
    if (!isDefined(language) || !isDefined(country)) {
        moment.locale(formatLocale(ENGLISH_ABBREV, UNITED_STATES_ABBREV), EN_US_LOCALE_FORMAT)
    } else if (language.toLowerCase() === FRENCH_ABBREV && country.toUpperCase() === FRANCE_ABBREV) {
        moment.locale(formatLocale(FRENCH_ABBREV, FRANCE_ABBREV), FR_FR_LOCALE_FORMAT)
    } else if (language.toLowerCase() === SPANISH_ABBREV && country.toUpperCase() === UNITED_STATES_ABBREV) {
        moment.locale(formatLocale(SPANISH_ABBREV, UNITED_STATES_ABBREV), ES_US_LOCALE_FORMAT)
    } else if (language.toLowerCase() === ENGLISH_ABBREV && country.toUpperCase() === UNITED_STATES_ABBREV) {
        moment.locale(formatLocale(ENGLISH_ABBREV, UNITED_STATES_ABBREV), EN_US_LOCALE_FORMAT)
    } else if (language.toLowerCase() === ENGLISH_ABBREV && country.toUpperCase() === UNITED_KINGDOM_ABBREV) {
        moment.locale(formatLocale(ENGLISH_ABBREV, UNITED_KINGDOM_ABBREV), EN_GB_LOCALE_FORMAT)
    } else if (language.toLowerCase() === ENGLISH_ABBREV && country.toUpperCase() === CANADA_ABBREV) {
        moment.locale(formatLocale(ENGLISH_ABBREV, CANADA_ABBREV), EN_CA_LOCALE_FORMAT)
    } else if (language.toLowerCase() === FRENCH_ABBREV && country.toUpperCase() === CANADA_ABBREV) {
        moment.locale(formatLocale(FRENCH_ABBREV, CANADA_ABBREV), FR_CA_LOCALE_FORMAT)
    } else {
        moment.locale(formatLocale(ENGLISH_ABBREV, UNITED_STATES_ABBREV), EN_US_LOCALE_FORMAT)
    }
}

const FR_FR_LOCALE_FORMAT = {
    longDateFormat : {
        LT: FRANCE_LT_FORMAT,
        LTS: TWENTY_FOUR_HOUR_TIME_FORMAT_INCLUDE_SECONDS,
        L: FRANCE_FRENCH_LONG_DATE_FORMAT
    },
    week: {
        dow: 6,
    },
}

const ES_US_LOCALE_FORMAT = {
    longDateFormat : {
        LT: UNITED_STATES_LT_FORMAT,
        LTS: "hh:mm:ss a",
        L: UNITED_STATES_SPANISH_LONG_DATE_FORMAT
    },
    week: {
        dow: 6,
    },
}

const EN_US_LOCALE_FORMAT = {
    longDateFormat : {
        LT: UNITED_STATES_LT_FORMAT,
        LTS: "hh:mm:ss a",
        L: UNITED_STATES_ENGLISH_LONG_DATE_FORMAT
    },
    week: {
        dow: 6,
    },
}

const EN_GB_LOCALE_FORMAT = {
    longDateFormat : {
        LT: UNITED_KINGDOM_LT_FORMAT,
        LTS: TWENTY_FOUR_HOUR_TIME_FORMAT_INCLUDE_SECONDS,
        L: UNITED_KINGDOM_ENGLISH_LONG_DATE_FORMAT
    },
    week: {
        dow: 6,
    },
}

const EN_CA_LOCALE_FORMAT = {
    longDateFormat : {
        LT: CANADA_LT_FORMAT,
        LTS: TWENTY_FOUR_HOUR_TIME_FORMAT_INCLUDE_SECONDS,
        L: CANADA_ENGLISH_LONG_DATE_FORMAT
    },
    week: {
        dow: 6,
    },
}

const FR_CA_LOCALE_FORMAT = {
    longDateFormat : {
        LT: CANADA_LT_FORMAT,
        LTS: TWENTY_FOUR_HOUR_TIME_FORMAT_INCLUDE_SECONDS,
        L: CANADA_FRENCH_LONG_DATE_FORMAT
    },
    week: {
        dow: 6,
    },
}