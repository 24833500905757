import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ReactImageVideoLightbox from 'react-image-video-lightbox'

import PropTypes from 'prop-types'
import PostUserAvatar from '../PostUserAvatar'
import ActionModal from '../ActionModal'

import { deleteChatMessage, } from '../../state/actions/eventChatActions'
import { postModerationItem } from '../../state/actions/moderationActions'
import { postReaction, deleteReaction } from '../../state/actions/reactionsActions'

import commentActionsImg from '../../styles/images/ellipsis-v-solid.svg'
import FlagForReviewDialogForm from '../FlagForReviewDialogForm'
import moment from 'moment'
import { obfuscate } from '../../components/utils/obfuscateUtils'
import { getUserChannelAccess } from '../../components/utils/utils'
import paperclipImg from '../../styles/images/paperclip-solid.svg'
import { deviceIsIOS } from '../../components/utils/mobileUtils.js'

import { formatTime, getMonthAndDay, isToday } from '../../components/utils/datetimeUtils'

import './EventChatMessage.scss'
import { getLoggedInPersonId } from '../utils/localStorageUtils'
import { t } from '../../utils/i18n'

class EventChatMessage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      messageActionDropdown: false,
      showFlagForReviewDialogForm: false,
      showFancyBox: {
        show: false,
        startFile: '',
      },

      flagForReviewObj: {
        title: t('please_select_reason'),
        reasons: this.props.moderationReasons,
        commentText: '',
        commentsEnabled: true,
      },

      modal: {
        showModal: false,
        modalTitle: '',
        modalText: '',
        showCancelAction: false,
        modalAcceptButtonText: '',
        modalCancelButtonText: '',
        modalAcceptAction: null,
        modalCancelAction: null,
      },

      userReaction: {
        postId: null,
        reactionId: null,
        reactionType: ''
      },
      likeCount: null,
      dislikeCount: null,
    }

    this.messageActionRef = React.createRef()

    this.renderChatBody = this.renderChatBody.bind(this)

    this.removePostAction = this.removePostAction.bind(this)
    this.cancelModalAction = this.cancelModalAction.bind(this)

    this.removePostClick = this.removePostClick.bind(this)
    this.messageActionsClick = this.messageActionsClick.bind(this)

    this.flagPostForReview = this.flagPostForReview.bind(this)
    this.flagForReviewSubmitAction = this.flagForReviewSubmitAction.bind(this)

    this.editPostClick = this.editPostClick.bind(this)

    this.onMediaClick = this.onMediaClick.bind(this)
    this.renderChatMediaFancyBox = this.renderChatMediaFancyBox.bind(this)

    this.handleOutsideDropdownClick = this.handleOutsideDropdownClick.bind(this)

    this.video = React.createRef();
  }

  componentDidMount() {
    const { messageInfo } = this.props
    const _state = this.state

    if (this.props.userReaction && this.props.userReaction.postId !== null) {
      _state.userReaction = this.props.userReaction
    }

    _state.likeCount = messageInfo.likeCount
    _state.dislikeCount = messageInfo.dislikeCount
    this.setState(_state)

    window.addEventListener('scroll', this.onChatScroll)
    document.addEventListener('mousedown', this.handleOutsideDropdownClick, false)

    const vid = this.video.current;
    if (vid && deviceIsIOS()) {
      setTimeout(() => {
        this.props.onMediaLoad()
      }, 2000)
    }
  }

  onChatScroll() {
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.postReactionPending === true && this.props.postReactionPending === false) ||
      (prevProps.deleteReactionPending === true && this.props.deleteReactionPending === false)
    ) {
      this.setState({
        userReaction: this.props.userReaction
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onPageScroll)
    document.removeEventListener('mousedown', this.handleOutsideDropdownClick, false)
  }

  handleOutsideDropdownClick = (evt) => {
    if (this.messageActionRef.current && !this.messageActionRef.current.contains(evt.target)) {
      this.setState({ messageActionDropdown: false })
    }
  }

  removePostClick() {
    const _state = this.state
    _state.messageActionDropdown = false
    _state.modal = {
      showModal: true,
      modalTitle: t('remove_popup_title'),
      modalText: t('remove_message_popup_body'),
      showCancelAction: true,
      modalAcceptButtonText: t('yes'),
      modalCancelButtonText: t('confirm_popup_cancel_button'),
      modalAcceptAction: this.removePostAction,
      modalCancelAction: this.cancelModalAction,
    }
    this.setState(_state)
  }

  removePostAction() {
    const { postId } = this.props.messageInfo

    this.props.deleteChatMessage(postId)

    this.cancelModalAction()
  }

  cancelModalAction() {
    const _state = this.state
    _state.modal = {
      showModal: false,
      modalTitle: '',
      modalText: '',
      showCancelAction: false,
      modalAcceptButtonText: '',
      modalCancelButtonText: '',
      modalAcceptAction: null,
      modalCancelAction: null,
    }
    _state.showFlagForReviewDialogForm = false

    this.setState(_state)
  }

  flagPostForReview() {
    this.setState({
      showFlagForReviewDialogForm: true,
      messageActionDropdown: false,
    })
  }

  flagForReviewSubmitAction(reason, comments) {
    const { messageInfo } = this.props
    const moderationItem = {
      entityId: messageInfo.postId,
      moderatingPersonId: getLoggedInPersonId(),
      moderationReason_Tag: reason.tag,
      systemId: 1,
      comments,
    }
    this.props.postModerationItem(moderationItem)
    this.setState({
      showFlagForReviewDialogForm: false,
    })
  }

  editPostClick() {
    this.setState({ messageActionDropdown: false })
    this.props.editPostClick(this.props.messageInfo)
  }

  messageActionsClick() {
    this.setState({ messageActionDropdown: !this.state.messageActionDropdown })
  }

  setMessagePosition(messageInfo) {
    return messageInfo.personId === parseInt(getLoggedInPersonId(), 10) ? 'end' : 'start'
  }

  checkIfMessageCreatorIsMe(messageInfo) {
    return messageInfo.personId === parseInt(getLoggedInPersonId(), 10)
  }

  handleReactionChange(evt, reaction) {
    evt.stopPropagation()
    let _state = this.state

    if (_state.userReaction.reactionType !== '') {
      _state = {
        ..._state,
        likeCount: _state.userReaction.reactionType === 'Like' ? _state.likeCount - 1 : _state.likeCount,
        dislikeCount: _state.userReaction.reactionType === 'Dislike' ? _state.dislikeCount - 1 : _state.dislikeCount,
      }
      this.props.deleteReaction(_state.userReaction.reactionId, this.props.messageInfo.postId)
    }
    if (_state.userReaction.reactionType !== reaction) {
      const _userReaction = {
        postId: this.props.messageInfo.postId,
        reactionType: reaction,
      }
      _state = {
        ..._state,
        likeCount: reaction === 'Like' ? _state.likeCount + 1 : _state.likeCount,
        dislikeCount: reaction === 'Dislike' ? _state.dislikeCount + 1 : _state.dislikeCount,
      }
      this.props.postReaction(_userReaction, this.props.messageInfo.postId)
    }
    this.setState(_state)
  }

  renderDate(date) {
    return (
      isToday(date) ?
        formatTime(date)
        :
        `${getMonthAndDay(date)} | ${formatTime(date)}`
    )
  }

  renderReactionButtonsMarkup(likesEnabled, dislikesEnabled) {
    const { userReaction, likeCount, dislikeCount } = this.state
    const { postReactionPending, deleteReactionPending } = this.props

    if (postReactionPending || deleteReactionPending) {
      return (
        <div className="reaction-bar">
          <div className="loading-text">{t('loading')}</div>
        </div>
      )
    }
    return (
      <div className="reaction-bar">
        {likesEnabled &&
          <div
            className="reaction-button"
            onClick={(evt) => this.handleReactionChange(evt, 'Like')}
          >
            <div className={`reaction-like-image ${userReaction.reactionType === 'Like' ? 'green-color' : ''} mr-2`} />
            <div>{likeCount}</div>
          </div>
        }
        {dislikesEnabled &&
          <div
            className="reaction-button"
            onClick={(evt) => this.handleReactionChange(evt, 'Dislike')}
          >
            <div className={`reaction-dislike-image ${userReaction.reactionType === 'Dislike' ? 'red-color' : ''} mr-2`} />
            <div>{dislikeCount}</div>
          </div>
        }
      </div>
    )
  }

  renderChatMediaFancyBox(postMedia) {
    const { startFile } = this.state.showFancyBox
    const data = []
    postMedia.map(media => {
      if (media.contentType !== 'pdf') {
        data.push({
          url: media.signedUrl,
          type: media.contentType === 'image' ? 'photo' : media.contentType,
          altTag: media.fileName,
        })
      }
      return null
    })

    const startIndex = data.findIndex(media => media.url === startFile)

    return (
      <div className="media-fancybox">
        <ReactImageVideoLightbox
          data={data}
          startIndex={startIndex}
          showResourceCount={true}
          onCloseCallback={this.onMediaClick}
        />
      </div>
    )
  }

  onMediaClick(evt) {
    const { signedUrl } = evt.target.dataset
    this.setState({
      showFancyBox: {
        show: !this.state.showFancyBox.show,
        startFile: signedUrl,
      }
    })
  }

  renderChatMedia(postMedia) {
    return (
      <div>
        <div className="chat-media d-flex flex-row flex-wrap align-items-center">
          {
            postMedia.map((media, index) => {
              if (media.contentType === 'video') {
                return (
                  <div className={index > 0 ? 'mt-3' : ''} key={`${media.signedUrl}${index}`}>
                    <video controls={true} playsInline={true} ref={this.video} onLoadedData={this.props.onMediaLoad} onError={this.props.onMediaLoad} className="video" data-signed-url={media.signedUrl} onClick={this.onMediaClick}>
                      <source src={media.signedUrl} type="video/mp4" />
                    </video>
                  </div>
                )
              }
              if (media.contentType === 'image') {
                return (
                  <div className={index > 0 ? 'mt-3' : ''} key={`${media.signedUrl}${index}`}>
                    <img onLoad={this.props.onMediaLoad} onError={this.props.onMediaLoad} className="image" onClick={this.onMediaClick} data-signed-url={media.signedUrl} src={media.previewSignedUrl} alt={media.fileName} />
                  </div>
                )
              }
              return '';
            })
          }
        </div>
        {postMedia.filter((media) => media.contentType === 'pdf').map((media, index) => {
          return (
            <div className="mb-2" key={`${media.signedUrl}${index}`}>
              <img className="paperclip mr-2" src={paperclipImg} alt="paperclip" />
              <a href={media.signedUrl} onClick={(evt) => evt.stopPropagation()} target="_blank" rel="noopener noreferrer">
                {media.fileName.length > 20 ? `${media.fileName.slice(0, 15)}...` : media.fileName}
              </a>
            </div>
          )
        })
        }
      </div>
    )
  }

  renderChatBody(messageInfo, messageCreator) {
    const { editing } = this.props
    return (
      <div className={`chat-body ${editing ? 'editing' : ''}`} title={this.renderDate(moment(new Date(messageInfo.creationDate)), messageCreator)}>
        <div>{messageInfo.body}</div>
        {messageInfo.postAttachments.length !== 0 && this.renderChatMedia(messageInfo.postAttachments)}
      </div>
    )
  }

  renderMessageActions(messageInfo, messageCreator) {
    const { messageActionDropdown } = this.state
    const { channelAccess } = this.props
    return (
      <div
        className={`chat-message-actions ${messageCreator ? 'ml-1 dropleft' : 'mr-1 dropright'} ${messageActionDropdown ? 'clicked' : ''}`}
        ref={this.messageActionRef}
      >
        <div
          className={`message-actions-button ${messageCreator ? 'flex-row' : 'flex-row-reverse'}`}
          onClick={this.messageActionsClick}
        >
          {messageInfo.isFlagged &&
            <i className="ml-1 mr-1 indicator-icon material-icons flag">flag</i>
          }
          <img className="ml-1 mr-1" src={commentActionsImg} alt="Comment actions" />
        </div>
        {(getUserChannelAccess(channelAccess, 'edit_remove', messageInfo.personId) || !messageInfo.isFlagged) &&
          <div className={`dropdown-menu ${messageActionDropdown ? 'show' : ''}`} aria-labelledby="dropdownMenu2">
            {getUserChannelAccess(channelAccess, 'edit_remove', messageInfo.personId) &&
              <div>
                {messageCreator &&
                  <button className="dropdown-item" type="button" onClick={this.editPostClick}>{t('edit')}</button>
                }
                <button className="dropdown-item" type="button" onClick={this.removePostClick}>{t('remove')}</button>
              </div>
            }
            {!messageCreator && !messageInfo.isFlagged &&
              <button className="dropdown-item" type="button" onClick={this.flagPostForReview}>{t('flag_for_review')}</button>
            }
          </div>

        }
      </div>
    )
  }

  renderChatMessage(messageInfo) {
    const messagePosition = this.setMessagePosition(messageInfo)
    const messageCreator = this.checkIfMessageCreatorIsMe(messageInfo)
    const { messageActionDropdown } = this.state
    const { userAvatar, history } = this.props
    return (
      <div className={`mt-3 d-flex flex-column align-items-${messagePosition} add-margin-${messagePosition}`}>
        {!messageCreator &&
          <div className="d-flex flex-row mb-2">
            <div className="personal-info">
              <PostUserAvatar
                personName={messageInfo.personName}
                userAvatarUrl={userAvatar !== undefined ? userAvatar.url : ''}
                personId={messageInfo.personId}
                redirectToAvatarPage={false}
                history={history}
              />
            </div>
            <div className="col chat-person-name">
              <div
                className="name cursor-pointer"
                onClick={() => history.push(`/public-profile/${obfuscate(messageInfo.personId)}`)}
              >
                {messageInfo.personName}
              </div>
              <div className="organization-name">{messageInfo.organizationName}</div>
            </div>
          </div>
        }
        <div className={`chat-message-wrapper ${messageActionDropdown ? 'clicked' : ''} align-items-${messageCreator ? 'end' : 'start'}`}>
          <div className={`chat-message ${messageCreator ? 'flex-row' : 'flex-row-reverse'}`}>
            {this.renderChatBody(messageInfo, messageCreator)}
            {this.renderMessageActions(messageInfo, messageCreator)}
          </div>
          {window.innerWidth <= 992 &&
            <div className="message-date">{this.renderDate(moment(new Date(messageInfo.creationDate)), messageCreator)}</div>
          }
        </div>
        {this.renderReactionButtonsMarkup(messageInfo.likesEnabled, messageInfo.dislikesEnabled)}
      </div>
    )
  }

  render() {
    const { messageInfo } = this.props
    const {
      flagForReviewObj, showFlagForReviewDialogForm, modal, showFancyBox,
    } = this.state

    return (
      <div className={`event-chat-message `}>
        {this.renderChatMessage(messageInfo)}

        <ActionModal
          {...modal}
        />

        <FlagForReviewDialogForm
          showModal={showFlagForReviewDialogForm}
          title={flagForReviewObj.title}
          reasons={flagForReviewObj.reasons}
          commentText={flagForReviewObj.commentText}
          commentsEnabled={flagForReviewObj.commentsEnabled}
          modalSubmitAction={this.flagForReviewSubmitAction}
          modalCancelAction={this.cancelModalAction}
        />

        {showFancyBox.show && this.renderChatMediaFancyBox(messageInfo.postAttachments)}
        {/* <ScrollDownButton preview={preview} dataLoaded={commentsDataLoaded} />  wont work*/}
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  const { postId } = props.messageInfo
  return {
    moderationReasons: state.moderationReducer.moderationReasons,
    userReaction: state.reactionsReducer.userReactions[postId],
    postReactionPending: state.reactionsReducer.postReactionPending[postId],
    deleteReactionPending: state.reactionsReducer.deleteReactionPending[postId],
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deleteChatMessage: (messageId) => deleteChatMessage(messageId),
      postModerationItem: (moderationItem) => postModerationItem(moderationItem),
      postReaction: (reaction, postId) => postReaction(reaction, postId),
      deleteReaction: (reactionId, postId) => deleteReaction(reactionId, postId),
    },
    dispatch
  )
}

EventChatMessage.propTypes = {
  editChatMessage: PropTypes.func,
  deleteChatMessage: PropTypes.func,
  postModerationItem: PropTypes.func,
  editPostClick: PropTypes.func,

  editing: PropTypes.bool,
  messageInfo: PropTypes.object,
  moderationReasons: PropTypes.array,
  userReaction: PropTypes.object,
  postReaction: PropTypes.func,
  postReactionPending: PropTypes.bool,
  deleteReaction: PropTypes.func,
  deleteReactionPending: PropTypes.bool,
  onMediaLoad: PropTypes.func,
  channelAccess: PropTypes.object,
  userAvatar: PropTypes.object,
  history: PropTypes.object,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventChatMessage)
