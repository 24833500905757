import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

import ChannelListItem from '../../components/News/ChannelListItem'
import Preloader from '../../components/Preloader'

import { hasNewsAccess } from '../../components/utils/featureAccessUtils'

import './NewsContainer.scss'
import { t } from '../../utils/i18n'

class NewsContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      channels: []
    }
  }

  componentDidMount(){
    if (this.props.channels.length){
      this.setState({channels: this.props.channels})
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.channelsPending === true && this.props.channelsPending === false) {
      this.setState({channels: this.props.channels})
    }

    if (prevProps.featureAccessPending === true && this.props.featureAccessPending === false) {
      if (!hasNewsAccess(this.props.featureAccess)) {
        return <Redirect to="/profile" />
      }
    }
  }

  render() {
    const { channelsPending, history, featureAccessPending } = this.props
    const { channels } = this.state

    if (channelsPending || featureAccessPending) {
      return <Preloader />
    }

    return (
      <div className="news-container">
        <div className="row">
          <div className="col news-header">{t('news_menu_item')}</div>
        </div>
        <div>
          {
            channels && channels.map((channel) => (
              <ChannelListItem
                key={channel.channelId}
                channel={channel}
                history={history}
              />
            ))
          }
        </div>      
      </div>    
    )
  }
}

function mapStateToProps(state) {
  return {
    channelsPending: state.newsReducer.channelsPending,
    channelsError: state.newsReducer.channelsError,
    channels: state.newsReducer.channels,
    featureAccess: state.featureAccessReducer.featureAccess,
    featureAccessPending: state.featureAccessReducer.featureAccessPending,
    profileLocale: state.loginReducer.profileLocale,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {},
    dispatch
  )
}

NewsContainer.propTypes = {
  channels: PropTypes.array,
  channelsPending: PropTypes.bool,
  channelsError: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  history: PropTypes.object,
  featureAccess: PropTypes.object,
  featureAccessPending: PropTypes.bool,
  profileLocale: PropTypes.string,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsContainer)
