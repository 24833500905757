import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getUserReactions, postReaction, deleteReaction } from '../../state/actions/reactionsActions'
import { removePost, translatePost, watchPost, updatePostReactions } from '../../state/actions/channelActions'
import { postModerationItem } from '../../state/actions/moderationActions'
import PropTypes from 'prop-types'
import PostUserAvatar from '../PostUserAvatar'
import PostCardComments from '../PostCardComments'
import ScrollDownButton from '../ScrollDownButton'
import ActionModal from '../ActionModal'
import FlagForReviewDialogForm from '../FlagForReviewDialogForm'
import WatchPostDialogForm from '../WatchPostDialogForm'
import moment from 'moment'
import paperclipImg from '../../styles/images/paperclip-solid.svg'
import onlyForManagersImg from '../../styles/images/ic_managers_only.png'
import commentActionsImg from '../../styles/images/ellipsis-v-solid.svg'
import { getUserChannelAccess } from '../../components/utils/utils'
import { obfuscate } from '../../components/utils/obfuscateUtils'
import {
  convertLanguageCodeToLanguageName,
} from '../../components/utils/utils'
import { formatTime, getMonthAndDay, isToday } from '../../components/utils/datetimeUtils'

import './PostCardDetails.scss'
import { getLoggedInPersonId } from '../utils/localStorageUtils'
import { t } from '../../utils/i18n'


class PostCardDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showFSImage: false, //show full screen image
      signedUrl: '',
      showMore: false,
      userReaction: {
        postId: null,
        reactionId: null,
        reactionType: ''
      },
      likeCount: null,
      dislikeCount: null,
      postActionDropdown: false,
      commentsDataLoaded: false,
      translations: {
        loadedData: false,
        active: false,
        title: {},
        body: {},
      },
      showFlagForReviewDialogForm: false,
      flagForReviewObj: {
        title: t('please_select_reason'),
        reasons: this.props.moderationReasons,
        commentText: '',
        commentsEnabled: true,
      },
      showWatchDialogForm: {
        showModal: false,
        modalAcceptAction: null,
        modalCancelAction: null,
        modalTitle: '',
        modalText: '',
      },
      modal: {
        showModal: false,
        modalTitle: '',
        modalText: '',
        showCancelAction: false,
        modalAcceptButtonText: '',
        modalCancelButtonText: '',
        modalAcceptAction: null,
        modalCancelAction: null,
      },
    }

    this.onImageClick = this.onImageClick.bind(this)
    this.onCloseFSImageClick = this.onCloseFSImageClick.bind(this)
    this.renderPostBody = this.renderPostBody.bind(this)
    this.onShowMoreClick = this.onShowMoreClick.bind(this)
    this.postActionsClick = this.postActionsClick.bind(this)
    this.editPostClick = this.editPostClick.bind(this)
    this.removePostClick = this.removePostClick.bind(this)
    this.removePostAction = this.removePostAction.bind(this)
    this.cancelPostAction = this.cancelPostAction.bind(this)
    this.onCommentsDataLoaded = this.onCommentsDataLoaded.bind(this)
    this.translatePostClick = this.translatePostClick.bind(this)
    this.translateToOriginalClick = this.translateToOriginalClick.bind(this)
    this.flagPostForReview = this.flagPostForReview.bind(this)
    this.flagForReviewSubmitAction = this.flagForReviewSubmitAction.bind(this)
    this.watchPostClick = this.watchPostClick.bind(this)
    this.watchPostAction = this.watchPostAction.bind(this)
  }

  componentDidMount() {
    const { postInfo } = this.props
    const _state = this.state
    if (this.props.userReaction && this.props.userReaction.postId !== null) {
      _state.userReaction = this.props.userReaction
    } else if (this.props.preview === false
      && ((postInfo.likesEnabled && postInfo.likeCount !== 0)
        || (postInfo.dislikesEnabled && postInfo.dislikeCount !== 0))) {
      this.props.getUserReactions([postInfo.postId], 'posts')
    }

    _state.likeCount = postInfo.likeCount
    _state.dislikeCount = postInfo.dislikeCount
    this.setState(_state)

    window.addEventListener('scroll', this.onPageScroll)
  }

  onPageScroll() {
    if (!this.props || this.props.preview) return;
    const _state = this.state
    _state.showScrollDownButton = false
    if (document.documentElement.scrollTop === 0) {
      _state.showScrollDownButton = true
    }
    this.setState(_state)
  }

  scrollDownClick() {
    const _state = this.state
    _state.showScrollDownButton = false
    window.scrollTo(0, document.documentElement.offsetHeight)
    this.setState(_state)
  }

  componentDidUpdate(prevProps) {
    const _state = this.state

    if (
      (prevProps.userReactionsPending === true
        && this.props.userReactionsPending === false
        && this.props.preview === false
        && this.props.userReaction
        && this.props.userReaction.postId !== null) ||
      (prevProps.postReactionPending === true && this.props.postReactionPending === false) ||
      (prevProps.deleteReactionPending === true && this.props.deleteReactionPending === false)
    ) {
      _state.userReaction = this.props.userReaction
      this.setState(_state)
    }

    if (prevProps.translationsPending === true && this.props.translationsPending === false
      && this.props.translations && !Object.keys(this.state.translations.body).length) {
      _state.translations = this.props.translations
      this.setState(_state)
    }

    if (prevProps.postInfo.isFlagged === false && this.props.postInfo.isFlagged === true) {
      _state.modal = {
        showModal: true,
        modalTitle: t('flag_post'),
        modalText: t('flag_post_submitted'),
        showCancelAction: false,
        modalAcceptButtonText: t('ok'),
        modalCancelButtonText: '',
        modalAcceptAction: this.cancelPostAction,
        modalCancelAction: null,
      }
      this.setState(_state)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onPageScroll)
  }

  onImageClick(evt) {
    evt.preventDefault()
    const { signedUrl } = evt.target.dataset
    const _state = this.state
    _state.showFSImage = true
    _state.signedUrl = signedUrl
    this.setState(_state)
  }

  onLinkClick(evt) {
    evt.stopPropagation()
  }

  onCloseFSImageClick() {
    const _state = this.state
    _state.showFSImage = false
    _state.signedUrl = ''
    this.setState(_state)
  }

  onShowMoreClick(evt) {
    evt.stopPropagation()
    const _state = this.state
    _state.showMore = !this.state.showMore
    this.setState(_state)
  }

  handleReactionChange(evt, reaction) {
    evt.stopPropagation()
    let _state = this.state
    const { userReaction } = this.state
    const { postInfo } = this.props

    if (userReaction.reactionType !== '') {
      _state = {
        ..._state,
        likeCount: _state.userReaction.reactionType === 'Like' ? _state.likeCount - 1 : _state.likeCount,
        dislikeCount: _state.userReaction.reactionType === 'Dislike' ? _state.dislikeCount - 1 : _state.dislikeCount,
      }
      this.props.updatePostReactions(postInfo.postId, userReaction.reactionType, -1)
      this.props.deleteReaction(userReaction.reactionId, postInfo.postId)
    }
    if (userReaction.reactionType !== reaction) {
      const _userReaction = {
        postId: postInfo.postId,
        reactionType: reaction,
      }
      _state = {
        ..._state,
        likeCount: reaction === 'Like' ? _state.likeCount + 1 : _state.likeCount,
        dislikeCount: reaction === 'Dislike' ? _state.dislikeCount + 1 : _state.dislikeCount,
      }
      this.props.updatePostReactions(postInfo.postId, reaction, +1)
      this.props.postReaction(_userReaction, postInfo.postId)
    }
    this.setState(_state)
  }

  renderDate(date) {
    return (
      <div className="row">
        <div className="col">
          {isToday(date) ?
            <div>{formatTime(date)}</div>
            :
            <div>{`${getMonthAndDay(date)} | ${formatTime(date)}`}</div>
          }
        </div>
      </div>
    )
  }

  postActionsClick(evt) {
    evt.stopPropagation()
    this.setState({ postActionDropdown: !this.state.postActionDropdown })
  }
  editPostClick(evt) {
    evt.stopPropagation()
    const {
      channelId, title, body,
      likesEnabled, dislikesEnabled, commentsEnabled,
      postId, hideFromHourlyTeamMembers, postAttachments,
      personId, organizationId, personName, personTitle,
      organizationName
    } = this.props.postInfo
    this.props.history.push({
      pathname: `/news/channel/${channelId}/new_post`,
      state: {
        title,
        body,
        likesEnabled,
        dislikesEnabled,
        commentsEnabled,
        hideFromHourlyTeamMembers,
        postAttachments,
        postId,
        personId,
        organizationId,
        personName,
        personTitle,
        organizationName
      }
    });
  }

  removePostClick(evt) {
    evt.stopPropagation()
    const _state = this.state
    _state.postActionDropdown = false
    _state.modal = {
      showModal: true,
      modalTitle: t('remove_popup_title'),
      modalText: t('remove_post_popup_body'),
      showCancelAction: true,
      modalAcceptButtonText: t('yes'),
      modalCancelButtonText: t('confirm_popup_cancel_button'),
      modalAcceptAction: this.removePostAction,
      modalCancelAction: this.cancelPostAction,
    }
    this.setState(_state)
  }

  removePostAction(evt) {
    evt.stopPropagation()
    const { postId } = this.props.postInfo
    const { preview } = this.props
    this.props.removePostAction(postId)
    if (preview === false) {
      this.props.history.goBack()
    }
    const _state = this.state
    _state.modal = {
      showModal: false,
      modalTitle: '',
      modalText: '',
      showCancelAction: false,
      modalAcceptButtonText: '',
      modalCancelButtonText: '',
      modalAcceptAction: null,
      modalCancelAction: null,
    }
    this.setState(_state)
  }

  cancelPostAction(evt) {
    evt.stopPropagation()
    const _state = this.state
    _state.modal = {
      showModal: false,
      modalTitle: '',
      modalText: '',
      showCancelAction: false,
      modalAcceptButtonText: '',
      modalCancelButtonText: '',
      modalAcceptAction: null,
      modalCancelAction: null,
    }
    _state.showFlagForReviewDialogForm = false
    _state.showWatchDialogForm = {
      showModal: false,
      modalAcceptAction: null,
      modalCancelAction: null,
      modalTitle: '',
      modalText: '',
    }

    this.setState(_state)
  }

  translatePostClick(evt) {
    evt.stopPropagation()
    const { title, body, postId } = this.props.postInfo
    this.postActionsClick(evt)
    this.props.translatePostAction(title, body, postId)
  }
  translateToOriginalClick(evt) {
    evt.stopPropagation()
    const _state = this.state
    _state.translations = {
      ..._state.translations,
      active: !_state.translations.active
    }
    this.setState(_state)
  }

  flagPostForReview(evt) {
    evt.stopPropagation()
    this.setState({
      showFlagForReviewDialogForm: true,
      postActionDropdown: false,
    })
  }

  flagForReviewSubmitAction(reason, comments) {
    const { postInfo } = this.props
    const moderationItem = {
      entityId: postInfo.postId,
      moderatingPersonId: getLoggedInPersonId(),
      moderationReason_Tag: reason.tag,
      systemId: 1,
      comments,
    }
    this.props.postModerationItem(moderationItem)
    this.setState({
      showFlagForReviewDialogForm: false,
    })
  }


  watchPostClick(evt) {
    evt.stopPropagation()
    const _state = this.state
    const { postInfo } = this.props

    if (postInfo.isWatching) {
      _state.modal = {
        showModal: true,
        showCancelAction: true,
        modalAcceptButtonText: t('yes'),
        modalCancelButtonText: t('confirm_popup_cancel_button'),
        modalAcceptAction: this.watchPostAction,
        modalCancelAction: this.cancelPostAction,
        modalTitle: t('disable_watch'),
        modalText: t('unwatch_message'),
      }
    } else {
      _state.showWatchDialogForm = {
        showModal: true,
        modalAcceptButtonText: t('yes'),
        modalCancelButtonText: t('confirm_popup_cancel_button'),
        modalAcceptAction: this.watchPostAction,
        modalCancelAction: this.cancelPostAction,
        modalTitle: t('enable_watch'),
        modalText: t('watch_message'),
      }
    }
    this.setState(_state)
  }

  watchPostAction(isFromMyOrganization) {
    const _state = this.state
    const { postInfo } = this.props
    _state.postActionDropdown = !_state.postActionDropdown
    _state.modal = {
      showModal: false,
      modalTitle: '',
      modalText: '',
      showCancelAction: false,
      modalAcceptButtonText: '',
      modalCancelButtonText: '',
      modalAcceptAction: null,
      modalCancelAction: null,
    }
    _state.showWatchDialogForm = {
      showModal: false,
      modalAcceptAction: null,
      modalCancelAction: null,
      modalTitle: '',
      modalText: '',
    }
    this.setState(_state)
    this.props.watchPostAction(postInfo.postId, postInfo.isWatching, isFromMyOrganization)
  }

  handleNameClick(evt, personId) {
    evt.stopPropagation()
    this.props.history.push(`/public-profile/${obfuscate(personId)}`)
  }

  renderTranslateMenuItem = () => {
    return <button className="dropdown-item" type="button" onClick={this.translatePostClick}>{t('translate')}</button>
  }

  renderPostEditorPersonalInfo(postInfo) {
    const { postActionDropdown } = this.state
    const { userAccess, userAvatar, history } = this.props
    return (
      <div className="row personal-info">
        <div className="col d-flex">
          <PostUserAvatar
            personName={postInfo.personName}
            userAvatarUrl={userAvatar !== undefined ? userAvatar.url : ''}
            personId={postInfo.personId}
            redirectToAvatarPage={false}
            history={history}
          />
          <div className="col d-flex flex-column justify-content-center">
            <div
              className="name cursor-pointer"
              onClick={(evt) => this.handleNameClick(evt, postInfo.personId)}
            >
              {postInfo.personName}
            </div>
            <div className="name">{postInfo.organizationName}</div>
          </div>
          {postInfo.isWatching && <i className="material-icons remove-red-eye">remove_red_eye</i>}
          {
            postInfo.hideFromHourlyTeamMembers &&
            <img className="indicator-icon" src={onlyForManagersImg} alt="Visible to managers only" />
          }
          {
            postInfo.isFlagged &&
            <i className="indicator-icon material-icons flag">flag</i>
          }

          <div className="dropleft post-actions">
            <div
              className="post-actions-button"
              onClick={this.postActionsClick}
            >
              <img src={commentActionsImg} alt="Comment actions" />
            </div>
            <div className={`dropdown-menu ${postActionDropdown ? 'show' : ''}`} aria-labelledby="dropdownMenu2">
              {userAccess && getUserChannelAccess(userAccess, 'edit_remove', postInfo.personId) &&
                <div>
                  <button className="dropdown-item" type="button" onClick={this.editPostClick}>{t('edit')}</button>
                  <button className="dropdown-item" type="button" onClick={this.removePostClick}>{t('remove')}</button>
                </div>
              }
              {this.renderTranslateMenuItem()}
              {
                !postInfo.isFlagged &&
                <button className="dropdown-item" type="button" onClick={this.flagPostForReview}>{t('flag_for_review')}</button>
              }
              <button
                className="dropdown-item"
                type="button"
                onClick={this.watchPostClick}
              >
                {postInfo.isWatching ? t('disable_watch') : t('enable_watch')}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderPostMedia(postMedia) {
    return (
      <div>
        <div className="post-media d-flex flex-row flex-wrap align-items-center">
          {
            postMedia.map((media, index) => {
              if (media.contentType === 'video') {
                return (
                  <div className="mt-3 mr-md-5" key={`${media.signedUrl}${index}`}>
                    <video controls className="video">
                      <source src={media.signedUrl} type="video/mp4" />
                    </video>
                  </div>
                )
              }
              if (media.contentType === 'image') {
                return (
                  <div className="mt-3 mr-md-5" key={`${media.signedUrl}${index}`}>
                    <img className="image" onClick={this.onImageClick} data-signed-url={media.signedUrl} src={media.previewSignedUrl} alt={media.fileName} />
                  </div>
                )
              }
              return '';
            })
          }
        </div>
        {postMedia.filter((media) => media.contentType === 'pdf').map((media, index) => {
          return (
            <div className="mb-2" key={`${media.signedUrl}${index}`}>
              <img className="paperclip mr-2" src={paperclipImg} alt="paperclip" />
              <a href={media.signedUrl} onClick={(evt) => this.onLinkClick(evt)} target="_blank" rel="noopener noreferrer">
                {media.fileName}
              </a>
            </div>
          )
        })
        }
      </div>
    )
  }

  renderSocialMarkup(postInfo) {
    if (postInfo.likesEnabled || postInfo.dislikesEnabled || postInfo.commentsEnabled) {
      return (
        <div className="row social-bar" >
          {this.renderReactionButtonsMarkup(postInfo.likesEnabled, postInfo.dislikesEnabled)}
          {this.renderCommentsCountMarkup(postInfo.commentsEnabled, postInfo.commentCount)}
        </div>
      )
    }
  }

  renderReactionButtonsMarkup(likesEnabled, dislikesEnabled) {
    const { userReaction, likeCount, dislikeCount } = this.state
    const { postReactionPending, deleteReactionPending } = this.props

    if (postReactionPending || deleteReactionPending) {
      return (
        <div className="col-6 d-flex flex-row">
          <div className="loading-text">{t('loading')}</div>
        </div>
      )
    }
    return (
      <div className="col-6 d-flex flex-row">
        {likesEnabled &&
          <div
            className="social-button"
            onClick={(evt) => this.handleReactionChange(evt, 'Like')}
          >
            <div className={`social-like-image ${userReaction.reactionType === 'Like' ? 'green-color' : ''} mr-2`} />
            <div>{likeCount}</div>
          </div>
        }
        {dislikesEnabled &&
          <div
            className="social-button"
            onClick={(evt) => this.handleReactionChange(evt, 'Dislike')}
          >
            <div className={`social-dislike-image ${userReaction.reactionType === 'Dislike' ? 'red-color' : ''} mr-2`} />
            <div>{dislikeCount}</div>
          </div>
        }
      </div>
    )
  }

  renderCommentsCountMarkup(commentsEnabled, commentCount) {
    return (
      <div className="col-6 d-flex align-items-center justify-content-end">
        {commentsEnabled && <div>{t('comments')}: {commentCount}</div>}
      </div>
    )
  }

  renderPostBody(body) {
    const { preview } = this.props
    const { showMore } = this.state
    const maxLenForPreview = 150
    const maxLinesForPreview = 3
    if (preview && (body.length >= maxLenForPreview || body.split("\n").length >= maxLinesForPreview)) {
      return (
        <div>
          <div className="post-body">
            {
              showMore ? body :
                `${body.split("\n").length >= maxLinesForPreview ? body.split("\n", maxLinesForPreview).join("\n") : body.slice(0, maxLenForPreview)}...`
            }
          </div>
          <div onClick={this.onShowMoreClick} className="mt-1 show-more-btn">
            {showMore ? t('show_less') : t('show_more')}
          </div>
        </div>
      )
    }
    return (
      <div className="post-body">
        {body}
      </div>
    )
  }

  renderTranslateBar(translations) {
    const { translationsError } = this.props
    if (translationsError && Object.keys(translationsError).length) {
      return (
        <div className="col-12 col-md-4 p-2 my-2 d-flex justify-content-between translate-bar">
          {t('issues_translating_post')}
        </div>
      )
    }
    return (
      <div>
        {translations.loadedData &&
          <div className="col-12 col-md-4 p-2 my-2 d-flex justify-content-between translate-bar"> {/* add back justify content-between for REP-903 */}
            <div className="d-flex align-items-start">
              <i className="material-icons mr-3">translate</i> {/* remove mr-3 for REP-903 */}
              {translations.loadedData && <div className="ml-2"> {t('translated_from')} {convertLanguageCodeToLanguageName(translations.body.sourceLanguage)} </div>}
              {/* add the above line for REP-903 */}
            </div>
            {translations.active && <div className="text-uppercase cursor-pointer original" onClick={this.translateToOriginalClick}> Original </div>}
            {!translations.active && <div className="text-uppercase cursor-pointer" onClick={this.translateToOriginalClick}> {t('translate')} </div>}
          </div>
        }
      </div>
    )
  }

  renderPostCard(postInfo, preview, newPost) {
    const { translations } = this.state
    return (
      <div className="row">
        <div className="col-12 mt-1">{this.renderDate(moment(new Date(postInfo.creationDate)))}</div>
        <div className="col">
          <div className={`card  ${preview && newPost ? 'new-post-border' : ''}`}>
            <div className="card-body">
              <div className="section">
                {this.renderPostEditorPersonalInfo(postInfo)}
              </div>
              {this.renderTranslateBar(translations)}
              <h3>{translations.active && translations.title ? translations.title.text : postInfo.title}</h3>
              {this.renderPostBody(translations.active ? translations.body.text : postInfo.body)}
              {postInfo.postAttachments.length !== 0 && this.renderPostMedia(postInfo.postAttachments)}
              {this.renderSocialMarkup(postInfo)}
            </div>
          </div>
        </div>
      </div >
    )
  }

  getFSImage(signedUrl) {
    return (
      <div className="fs-image w-100">
        <button onClick={this.onCloseFSImageClick} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <img src={signedUrl} alt="Fullscreen" />
      </div>
    )
  }

  onCommentsDataLoaded() {
    const _state = this.state
    _state.commentsDataLoaded = true
    this.setState(_state)
  }

  render() {
    const { postInfo, newPost, preview, userAccess, history, commentToNavigateTo } = this.props
    const {
      showFSImage, signedUrl, commentsDataLoaded, showWatchDialogForm,
      flagForReviewObj, showFlagForReviewDialogForm, modal
    } = this.state

    if (showFSImage) {
      return (
        <div className="mt-5 text-center">
          {this.getFSImage(signedUrl)}
        </div>
      )
    }
    return (
      <div className='post-card-details'>
        {this.renderPostCard(postInfo, preview, newPost)}

        {!preview &&
          <PostCardComments
            scrollToPostId={this.props.scrollToPostId}
            onCommentsDataLoaded={this.onCommentsDataLoaded}
            postInfo={postInfo}
            userAccess={userAccess || {}}
            commentToNavigateTo={commentToNavigateTo}
            history={history}
          />}

        <ActionModal
          {...modal}
        />

        <FlagForReviewDialogForm
          showModal={showFlagForReviewDialogForm}
          title={flagForReviewObj.title}
          reasons={flagForReviewObj.reasons}
          commentText={flagForReviewObj.commentText}
          commentsEnabled={flagForReviewObj.commentsEnabled}
          modalSubmitAction={this.flagForReviewSubmitAction}
          modalCancelAction={this.cancelPostAction}
        />

        <WatchPostDialogForm
          {...showWatchDialogForm}
        />

        <ScrollDownButton preview={preview} dataLoaded={commentsDataLoaded} />
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  const { postId } = props.postInfo
  return {
    personId: state.loginReducer.profile.personId,
    personName: `${state.loginReducer.profile.firstName} ${state.loginReducer.profile.lastName}`,
    personTitle: state.loginReducer.profile.role,
    organizationId: state.loginReducer.profile.organizationId,
    organizationName: state.loginReducer.profile.organization,
    userReaction: state.reactionsReducer.userReactions[postId],
    userReactionsPending: state.reactionsReducer.userReactionsPending['posts'],
    postReactionPending: state.reactionsReducer.postReactionPending[postId],
    deleteReactionPending: state.reactionsReducer.deleteReactionPending[postId],
    translations: state.channelReducer.translations[postId],
    translationsPending: state.channelReducer.translationsPending,
    translationsError: state.channelReducer.translationsError[postId],
    moderationReasonsPending: state.moderationReducer.moderationReasonsPending,
    moderationReasons: state.moderationReducer.moderationReasons,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getUserReactions: (postIds, category) => getUserReactions(postIds, category),
      postReaction: (reaction, postId) => postReaction(reaction, postId),
      deleteReaction: (reactionId, postId) => deleteReaction(reactionId, postId),
      removePostAction: (postId) => removePost(postId),
      translatePostAction: (title, body, postId) => translatePost(title, body, postId),
      postModerationItem: (moderationItem) => postModerationItem(moderationItem),
      watchPostAction: (postId, isWatching, isFromMyOrganization) => watchPost(postId, isWatching, isFromMyOrganization),
      updatePostReactions: (postId, reactionType, value) => updatePostReactions(postId, reactionType, value),
    },
    dispatch
  )
}

PostCardDetails.propTypes = {
  personId: PropTypes.string,
  personName: PropTypes.string,
  personTitle: PropTypes.string,
  organizationId: PropTypes.string,
  organizationName: PropTypes.string,
  postInfo: PropTypes.object,
  preview: PropTypes.bool,
  newPost: PropTypes.bool,
  getUserReactions: PropTypes.func,
  userReaction: PropTypes.object,
  userReactionsPending: PropTypes.bool,
  postReaction: PropTypes.func,
  postReactionPending: PropTypes.bool,
  deleteReaction: PropTypes.func,
  deleteReactionPending: PropTypes.bool,
  removePostAction: PropTypes.func,
  userAccess: PropTypes.object,
  translatePostAction: PropTypes.func,
  translations: PropTypes.object,
  translationsPending: PropTypes.bool,
  translationsError: PropTypes.object,
  postModerationItem: PropTypes.func,
  watchPostAction: PropTypes.func,
  moderationReasonsPending: PropTypes.bool,
  moderationReasons: PropTypes.array,
  updatePostReactions: PropTypes.func,
  history: PropTypes.object,
  scrollToPostId: PropTypes.number,
  userAvatar: PropTypes.object,
  commentToNavigateTo: PropTypes.object,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostCardDetails)
