import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { t } from '../../utils/i18n'
import './NotFoundContainer.scss'

class NotFoundContainer extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
	}
	render() {
		return (
			<div className="not-found-container">
				{t('page_not_found')}
			</div>
		)
	}
}

function mapStateToProps() {
	return {
	}
}
  
function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
		},
		dispatch
	)
}

NotFoundContainer.propTypes = {
}
  
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(NotFoundContainer)