import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { convertAbsenceCodeToAbsenceName } from '../utils/timepunchUtils'
import { formatNumberDecimal } from '../utils/numberUtils'
import { t } from '../../utils/i18n'

import './TimePunchItem.scss'

export default class TimePunchItem extends Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.showNotAvailableModal = this.showNotAvailableModal.bind(this)
  }

  showNotAvailableModal() {
    const { timePunchInfo } = this.props
    if (timePunchInfo.isPending) {
      this.props.showMessageModal(t('time_punches_details_not_available'))
      return
    }

    this.props.history.push({
      pathname: `/time-punches/${timePunchInfo.timePunchId}`,
      state: {
        timePunchInfo
      }
    })
  }

  renderStoreNumber = (timePunchInfo) => {
    if (timePunchInfo.storeNumber === undefined || timePunchInfo.storeNumber === null || timePunchInfo.storeNumber === '') {
      return ''
    } else {
      return (<div className="store-number mt-1">#{timePunchInfo.storeNumber}</div>)
    }
  }

  render() {
    const { timePunchInfo } = this.props

    return (
      <div className="row time-punch-item" onClick={this.showNotAvailableModal}>
        <div className="col">
          <div className="card" >
            <div className="card-body">
              <div className="section">
                <div className="d-flex align-items-center">
                  {timePunchInfo.isMsr && <i className="material-icons msr mt-2 mr-2">link</i>}
                  <div className="job-name mt-2">{timePunchInfo.customerName}</div>
                </div>
                {this.renderStoreNumber(timePunchInfo)}

                {!timePunchInfo.isPending &&
                  <div className="mt-2">
                    {timePunchInfo.absenceCode ?
                      <div>{`${t('absence_code')}: ${convertAbsenceCodeToAbsenceName(timePunchInfo.absenceCode)}`}</div>
                      :
                      <div className="row">
                        <div className="col-md-6 col-12 d-flex align-items-baseline">
                          {t('work_time').toUpperCase()}:
                            <h5 className="ml-2 mr-1">{formatNumberDecimal(timePunchInfo.hours)} {t('hours_abbreviation')}</h5>
                          </div>
                        <div className="col-md-6 col-12 d-flex align-items-baseline">
                          {t('travel_time').toUpperCase()}:
                            <h5 className="ml-2 mr-1">{formatNumberDecimal(timePunchInfo.travelHours)} {t('hours_abbreviation')}</h5>
                          </div>
                      </div>
                    }
                  </div>
                }
                <hr />

                {timePunchInfo.isPending ?
                  <div className="d-flex align-items-center time-punch-status mb-1">
                    <i className="material-icons mr-2">av_timer</i>
                    <div>{`${t('status')}: ${t('pending')}...`}</div>
                  </div>
                  :
                  <div className="d-flex align-items-center time-punch-status mb-1">
                    <i className="material-icons check-circle mr-2">check_circle</i>
                    <div>{`${t('status')}: ${t('complete')}`}</div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


TimePunchItem.propTypes = {
  showMessageModal: PropTypes.func,
  timePunchInfo: PropTypes.object,
  history: PropTypes.object,
}
