import * as types from './actionTypes'
import services from '../services'

export function getBadges() {
  return dispatch => {
    dispatch(getBadgesActionPending(true))
    services.badges
      .fetchBadges()
      .then(response => {
        dispatch(getBadgesActionSuccess(response))
        dispatch(getBadgesActionPending(false))
      })
      .catch(error => {
        dispatch(getBadgesActionError(error))
        dispatch(getBadgesActionPending(false))

      })
  }
}
export function getPeopleBadges(personId) {
  return dispatch => {
    dispatch(getBadgesActionPending(true))
    services.badges
      .fetchPeopleBadges(personId)
      .then(response => {
        dispatch(getBadgesActionSuccess(response))
        dispatch(getBadgesActionPending(false))
      })
      .catch(error => {
        dispatch(getBadgesActionError(error))
        dispatch(getBadgesActionPending(false))

      })
  }
}



const getBadgesActionSuccess = response => {
  return {
    type: types.GET_BADGES_SUCCESS,
    payload: response.data,
  }
}
const getBadgesActionError = response => {
  return {
    type: types.GET_BADGES_ERROR,
    error: response,
  }
}
const getBadgesActionPending = status => {
  return {
    type: types.GET_BADGES_PENDING,
    status,
  }
}

export function getEarnedBadges(recentlyEarnedCount) {
  return dispatch => {
    dispatch(getEarnedBadgesActionPending(true))
    services.badges
      .fetchEarnedBadges(recentlyEarnedCount)
      .then(response => {
        dispatch(getEarnedBadgesActionSuccess(response))
        dispatch(getEarnedBadgesActionPending(false))
      })
      .catch(error => {
        dispatch(getEarnedBadgesActionError(error))
        dispatch(getEarnedBadgesActionPending(false))

      })
  }
}

const getEarnedBadgesActionSuccess = response => {
  return {
    type: types.GET_EARNED_BADGES_SUCCESS,
    payload: response.data,
  }
}
const getEarnedBadgesActionError = response => {
  return {
    type: types.GET_EARNED_BADGES_ERROR,
    error: response,
  }
}
const getEarnedBadgesActionPending = status => {
  return {
    type: types.GET_EARNED_BADGES_PENDING,
    status,
  }
}
