import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { removeToastMessage } from '../../state/actions/toastsActions'
import Toast from '../../components/Alerts/Toast'

import './ToastContainer.scss'

class ToastContainer extends Component {
  render() {
    return (
      <div className="toast-container container">
        {this.props.toasts &&
          this.props.toasts.map(t => {
            return (
              <Toast
                key={t.id}
                id={t.id}
                type={t.toastType.toLowerCase()}
                body={t.toastMessage}
                remove={this.props.removeToastMessage}
              />
            )
          })}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    toasts: state.toastsReducer.toasts,
    profileLocale: state.loginReducer.profileLocale,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeToastMessage: id => removeToastMessage(id),
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ToastContainer)

ToastContainer.propTypes = {
  toasts: PropTypes.array,
  removeToastMessage: PropTypes.func,
  profileLocale: PropTypes.string,
}
