import * as appConfig from '../config/config'

const IDENTITY_API_URL = appConfig.config.IDENTITY_API_URL
const CALLBACK_URL = appConfig.config.CALLBACK_API_URL

const { Oidc } = window

const identityConfig = {
  authority: IDENTITY_API_URL,
  client_id: 'js',
  redirect_uri: CALLBACK_URL,
  response_type: 'id_token token',
  scope: 'openid profile api1 notifications-api-user shifts-api-user moderation-api-user contacts-api-user badges-api-user compliments-api-user timepunches-api-user translation-api-user profile-api-user',
  post_logout_redirect_uri: `${IDENTITY_API_URL}/`,
}

let identityOidcUserManager
export function getIdentityUserManager() {
  if (!identityOidcUserManager) {
    identityOidcUserManager = new Oidc.UserManager(identityConfig)
  }
  return identityOidcUserManager
}
