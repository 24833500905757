import React, {Component} from 'react';

class Dropdown extends Component{

  render(){
    return (
      <div className="dropdownStyles" id={this.props.scheduleId}>
        {this.props.children}
      </div>
    );
  }
}

export default Dropdown;
