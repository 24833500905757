import {
    GET_OTHER_AVAILABILITY_SUCCESS,
    GET_OTHER_AVAILABILITY_ERROR,
    GET_OTHER_AVAILABILITY_PENDING,
    SHOW_OTHER_AVAILABILITY_LIST_MODAL,
    SET_CURRENT_OTHER_AVAIL_RECORD,
    EDIT_OTHER_AVAILABILITY_RECORD,
    NEW_OTHER_AVAILABILITY_RECORD,
    SET_OTHER_AVAILABILITY,
    PATCH_OTHER_AVAILABILITY_ERROR,
    PATCH_OTHER_AVAILABILITY_PENDING,
    PATCH_OTHER_AVAILABILITY_SUCCESS,
    DELETE_OTHER_AVAILABILITY_ERROR,
    DELETE_OTHER_AVAILABILITY_PENDING,
    DELETE_OTHER_AVAILABILITY_SUCCESS,
    BACKUP_OTHER_AVAILABILITY,
  } from '../actions/actionTypes'  
  import { cloneDeep } from 'lodash'

  export default function generalAvailabilityReducer(
    state = {
      backupOtherAvailability: [],
      otherAvailability: [],
      otherAvailabilityError: {},
      otherAvailabilityPending: false,
      showOtherAvailabilityListModal: false,
      currentOtherAvailabilityRecord: {
        startDate:'',
        endDate:''
      },
      backupCurrentOtherAvailabilityRecord: {
        availabilityList: [],
        startDate:'',
        endDate:''
      },
      editOtherAvailabilityRecord: false,
      newOtherAvailabilityRecord: false,
      patchOtherAvailabilityError: {},
      patchOtherAvailabilityPending: false,      
      deleteOtherAvailabilityError: '',
      deleteOtherAvailabilityPending: false,  
    },
    action
  ) {
    switch (action.type) {
      case GET_OTHER_AVAILABILITY_SUCCESS:
        return {
          ...state,
          otherAvailability: action.payload ? action.payload : [],
          otherAvailabilityError: {},
        }
      case GET_OTHER_AVAILABILITY_ERROR:
        return {
          ...state,
          otherAvailabilityError: action.payload,
        }
      case GET_OTHER_AVAILABILITY_PENDING:
        return {
          ...state,
          otherAvailabilityPending: action.payload,
        }
      case SHOW_OTHER_AVAILABILITY_LIST_MODAL:
        return {
          ...state,
          showOtherAvailabilityListModal: action.payload,
        }
      case SET_CURRENT_OTHER_AVAIL_RECORD:
        return {
          ...state,
          currentOtherAvailabilityRecord: action.payload,
        }
      case EDIT_OTHER_AVAILABILITY_RECORD:
        return {
          ...state,
          editOtherAvailabilityRecord: action.payload,
        }
      case NEW_OTHER_AVAILABILITY_RECORD:
        return {
          ...state,
          newOtherAvailabilityRecord: action.payload,
        }
      case SET_OTHER_AVAILABILITY:
        return {
          ...state,
          otherAvailability: action.payload
        }
      case PATCH_OTHER_AVAILABILITY_ERROR:
        return {
          ...state,
          patchOtherAvailabilityError: action.error,
        }
      case PATCH_OTHER_AVAILABILITY_PENDING:
        return {
          ...state,
          patchOtherAvailabilityPending: action.payload,
        }
      case PATCH_OTHER_AVAILABILITY_SUCCESS:
        return {
          ...state,
          patchOtherAvailabilityError: '',
          patchOtherAvailabilityPending: false,
        }
      case DELETE_OTHER_AVAILABILITY_ERROR:
        return {
          ...state,
          deleteOtherAvailabilityError: action.error,
        }
      case DELETE_OTHER_AVAILABILITY_PENDING:
        return {
          ...state,
          deleteOtherAvailabilityPending: action.payload,
        }
      case DELETE_OTHER_AVAILABILITY_SUCCESS:
        return {
          ...state,
          deleteOtherAvailabilityError: '',
          deleteOtherAvailabilityPending: false,
        }
      case BACKUP_OTHER_AVAILABILITY:
        return {
          ...state,
          backupOtherAvailability: cloneDeep(state.otherAvailability)
        }
      default:
        return state
    }
  }
  