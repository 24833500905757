import * as types from './actionTypes'
import services from '../services'

export function getSearchAllPeople(searchString) {
  return dispatch => {
    dispatch(getSearchAllPeopleActionPending(true))
    services.personDetails
      .getSearchAllPeople(searchString)
      .then(response => {
        dispatch(getSearchAllPeopleActionSuccess(response))
        dispatch(getSearchAllPeopleActionPending(false))
      })
      .catch(error => {
        dispatch(getSearchAllPeopleActionError(error))
        dispatch(getSearchAllPeopleActionPending(false))
      })
  }
}

const getSearchAllPeopleActionSuccess = response => {
  return {
    type: types.GET_SEARCH_ALL_PEOPLE_SUCCESS,
    payload: response.data,
  }
}
const getSearchAllPeopleActionError = response => {
  return {
    type: types.GET_SEARCH_ALL_PEOPLE_ERROR,
    error: response,
  }
}
const getSearchAllPeopleActionPending = status => {
  return {
    type: types.GET_SEARCH_ALL_PEOPLE_PENDING,
    payload: status,
  }
}

export function getPersonDetailsList(personIds) {
  return dispatch => {
    dispatch(getPersonDetailsListActionPending(true))
    services.personDetails
      .getPersonDetailsList(personIds)
      .then(response => {
        dispatch(getPersonDetailsListActionPending(false))
        dispatch(getPersonDetailsListActionSuccess(response))
      })
      .catch(error => {
        dispatch(getPersonDetailsListActionError(error))
      })
  }
}

const getPersonDetailsListActionSuccess = response => {
  return {
    type: types.GET_PERSON_DETAILS_LIST_SUCCESS,
    payload: response.data,
  }
}
const getPersonDetailsListActionError = response => {
  return {
    type: types.GET_PERSON_DETAILS_LIST_ERROR,
    error: response,
  }
}
const getPersonDetailsListActionPending = status => {
  return {
    type: types.GET_PERSON_DETAILS_LIST_PENDING,
    payload: status,
  }
}

export function getPersonDetails(personId) {
  return dispatch => {
    dispatch(getPersonDetailsActionPending(true))
    services.personDetails
      .fetchPersonDetails(personId)
      .then(response => {
        dispatch(getPersonDetailsActionSuccess(response))
        dispatch(getPersonDetailsActionPending(false))
      })
      .catch(error => {
        dispatch(getPersonDetailsActionError(error))
        dispatch(getPersonDetailsActionPending(false))
      })
  }
}

const getPersonDetailsActionSuccess = response => {
  return {
    type: types.GET_PERSON_DETAILS_SUCCESS,
    payload: response.data,
  }
}
const getPersonDetailsActionError = response => {
  return {
    type: types.GET_PERSON_DETAILS_ERROR,
    error: response,
  }
}
const getPersonDetailsActionPending = status => {
  return {
    type: types.GET_PERSON_DETAILS_PENDING,
    pending: status,
  }
}

export function getPublishedWeek(organizationId) {
  return dispatch => {
    dispatch(getPublishedWeekActionPending(true))
    services.personDetails
      .getPublishedWeek(organizationId)
      .then(response => {
        dispatch(getPublishedWeekActionSuccess(response))
        dispatch(getPublishedWeekActionPending(false))
      })
      .catch(error => {
        dispatch(getPublishedWeekActionError(error))
        dispatch(getPublishedWeekActionPending(false))
      })
  }
}

const getPublishedWeekActionSuccess = response => {
  return {
    type: types.GET_PUBLISHED_WEEK_SUCCESS,
    payload: response.data,
  }
}
const getPublishedWeekActionError = error => {
  return {
    type: types.GET_PUBLISHED_WEEK_ERROR,
    error: error,
  }
}
const getPublishedWeekActionPending = status => {
  return {
    type: types.GET_PUBLISHED_WEEK_PENDING,
    pending: status,
  }
}

export function getSchedulingPreferences(personId) {
  return dispatch => {
    dispatch(getSchedulingPreferencesPending(true))
    services.personDetails
      .getSchedulingPreferences(personId)
      .then(response => {
        dispatch(getSchedulingPreferencesPending(false))
        dispatch(getSchedulingPreferencesSuccess(response))
      })
      .catch(error => {
        dispatch(getSchedulingPreferencesError(error))
      })
  }
}

const getSchedulingPreferencesSuccess = response => {
  return {
    type: types.GET_SCHEDULING_PREFERENCES_SUCCESS,
    payload: response.data,
  }
}
const getSchedulingPreferencesError = response => {
  return {
    type: types.GET_SCHEDULING_PREFERENCES_ERROR,
    error: response,
  }
}
const getSchedulingPreferencesPending = status => {
  return {
    type: types.GET_SCHEDULING_PREFERENCES_PENDING,
    pending: status,
  }
}

export function setEditingSchedulingPreferences(editingSchedulingPreferences) {
  return dispatch => {
    dispatch(
      setEditingSchedulingPreferencesAction({
        editingSchedulingPreferences: editingSchedulingPreferences
      })
    )
  }
}

const setEditingSchedulingPreferencesAction = payload => {
  return {
    type: payload.editingSchedulingPreferences ? types.EDITING_SCHEDULING_PREFERENCES : types.NOT_EDITING_SCHEDULING_PREFERENCES,
    payload: payload,
  }
}

export function saveSchedulingPreferences(schedulingPreferences) {
  return dispatch => {
    dispatch(
      saveSchedulingPreferencesAction({
        schedulingPreferences: schedulingPreferences
      })
    )
  }
}

const saveSchedulingPreferencesAction = payload => {
  return {
    type: types.SAVE_SCHEDULING_PREFERENCES,
    payload: payload,
  }
}

export function patchSchedulingPreferences(schedulingPreferences) {
  return dispatch => {
    dispatch(patchSchedulingPreferencesActionPending(true))
    services.personDetails
      .patchSchedulingPreferences(schedulingPreferences)
      .then(response => {
        dispatch(patchSchedulingPreferencesActionPending(false))
        dispatch(patchSchedulingPreferencesActionSuccess(response))
      })
      .catch(error => {
        dispatch(patchSchedulingPreferencesActionError(error))
      })
  }
}

const patchSchedulingPreferencesActionSuccess = response => {
  return {
    type: types.PATCH_SCHEDULING_PREFERENCES_SUCCESS,
    payload: response.data,
  }
}
const patchSchedulingPreferencesActionError = response => {
  return {
    type: types.PATCH_SCHEDULING_PREFERENCES_ERROR,
    error: response,
  }
}
const patchSchedulingPreferencesActionPending = status => {
  return {
    type: types.PATCH_SCHEDULING_PREFERENCES_PENDING,
    pending: status,
  }
}