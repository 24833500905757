import {
  POST_MODERATION_ITEM_SUCCESS,
  POST_MODERATION_ITEM_ERROR,
  POST_MODERATION_ITEM_PENDING,
  GET_MODERATION_REASONS_ERROR,
  GET_MODERATION_REASONS_PENDING,
  GET_MODERATION_REASONS_SUCCESS,
} from '../actions/actionTypes'

export default function moderationReducer(
  state = {
    postModerationItemError: {},
    postModerationItemPending: false,
    moderationReasons: [],
    moderationReasonsPending: false,
    moderationReasonsError: {},
  },
  action
) {
  switch (action.type) {
    case POST_MODERATION_ITEM_SUCCESS:
      return {
        ...state,
        postModerationItemPending: false,
        postModerationItemError: {},
      }
    case POST_MODERATION_ITEM_ERROR:
      return {
        ...state,
        postModerationItemPending: false,
        postModerationItemError: action.error,
      }
    case POST_MODERATION_ITEM_PENDING:
      return {
        ...state,
        postModerationItemError: {},
        postModerationItemPending: action.status,
      }

    case GET_MODERATION_REASONS_SUCCESS:
      return {
        ...state,
        moderationReasons: action.payload,
        moderationReasonsPending: false,
        moderationReasonsError: {},
      }
    case GET_MODERATION_REASONS_ERROR:
      return {
        ...state,
        moderationReasonsPending: false,
        moderationReasonsError: action.error,
      }
    case GET_MODERATION_REASONS_PENDING:
      return {
        ...state,
        moderationReasonsPending: action.status,
      }
    default:
      return state
  }
}
