import axios from 'axios'
import apiConfig from '../../config/api-config'
import * as cancelTokenUtil from './utils/cancelTokenUtil'

export function getTransportationTypes() {
    const url = apiConfig.GET_TRANSPORTATION_TYPES
    const options = cancelTokenUtil.abortAndGetCancelTokenOptions('GET_TRANSPORTATION_TYPES')
    return axios.get(`${url}`, options)
}

export function getTransportationProfile(personId) {
    const url = apiConfig.GET_TRANSPORTATION_PROFILE.replace('[personId]', personId)
    const options = cancelTokenUtil.abortAndGetCancelTokenOptions('GET_TRANSPORTATION_PROFILE')
    return axios.get(`${url}`, options)
}

export function patchTransportationProfile(transportationProfile = {}) {
    const url = apiConfig.PATCH_TRANSPORTATION_PROFILE
    const options = cancelTokenUtil.abortAndGetCancelTokenOptions('PATCH_TRANSPORTATION_PROFILE')
    return axios.patch(`${url}`, transportationProfile, options)
}

export function getRideAlongErrors(personId) {
    const url = apiConfig.GET_RIDE_ALONG_ERRORS.replace('[personId]', personId)
    const options = cancelTokenUtil.abortAndGetCancelTokenOptions('GET_RIDE_ALONG_ERRORS')
    return axios.get(`${url}`, options)
}