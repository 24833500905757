import axios from 'axios'
import apiConfig from '../../config/api-config'
import { toQueryString } from './utils/requestUtil'
import * as cancelTokenUtil from './utils/cancelTokenUtil'

/**
 * In order to differentiate that these are specifically service calls being made
 * the preappended actions will be as follows:
 * GET    Fetch
 * POST   Insert
 * PUT    Update
 * DELETE Remove
 */

// Get the Schedule
export function fetchScheduleByStartDateAndEndDate({ startDate = ``, endDate = `` }) {
  const url = apiConfig.SCHEDULE
  const queryParams = toQueryString({
    startDate: startDate,
    endDate: endDate,
  })
  return axios.get(`${url}?${queryParams}`)
}

// Get the district event details
export function getDistrictEventDetails(districtEventId) {
  const url = apiConfig.DISTRICT_EVENT_DETAILS.replace('[districtEventId]', districtEventId)
  const options = cancelTokenUtil.abortAndGetCancelTokenOptions('DISTRICT_EVENT_DETAILS')
  return axios.get(url, options)
}

// Get the Schedule Details
export function fetchScheduleDetailsByScheduleEventId({ scheduledEventId = `` }) {
  const url = apiConfig.SCHEDULE_DETAILS
  const queryParams = toQueryString({
    scheduledEventId: scheduledEventId,
  })
  return axios.get(`${url}?${queryParams}`)
}

// Get an events Team Details on the Schedule
export function fetchScheduleTeamDetailByScheduleEventId({ scheduledEventId = `` }) {
  const url = apiConfig.SCHEDULE_TEAM_DETAILS
  const queryParams = toQueryString({
    scheduledEventId: scheduledEventId,
  })
  const options = cancelTokenUtil.abortAndGetCancelTokenOptions('SCHEDULE_TEAM_DETAILS')
  return axios.get(`${url}?${queryParams}`, options)
}

// Post Confirm a Week by Event Ids
export function insertConfirmWeekByEventIds({ eventIds = [] }) {
  const url = apiConfig.CONFIRM_WEEK
  return axios.post(url, eventIds)
}

// Post Confirm a Week by Event Ids
export function insertScheduleChangeRequestByEventIdAndReason({
  attendingTime = ``,
  scheduledEventId = ``,
  typeCode = ``,
  reasonCode = ``,
  notes = ``,
}) {
  const url = apiConfig.SCHEDULE_CHANGE_REQUEST
  const data = {
    attendingTime,
    scheduledEventId,
    typeCode,
    reasonCode,
    notes,
  }
  return axios.post(url, data)
}

// Get the Schedule Change
export function fetchScheduleChangeByScheduledEventIdAndPersonId({ scheduledEventId = ``, personId = `` }) {
  const url = apiConfig.SCHEDULE_CHANGE
  const queryParams = toQueryString({
    scheduledEventId: scheduledEventId,
    personId: personId,
  })
  const options = cancelTokenUtil.abortAndGetCancelTokenOptions('SCHEDULE_CHANGE')
  return axios.get(`${url}?${queryParams}`, options)
}

// Get the Schedule Change Request
export function fetchScheduleChangeRequestByChangeRequestIdAndPersonId({ changeRequestId = ``, personId = `` }) {
  const url = apiConfig.SCHEDULE_CHANGE_REQUEST
  const queryParams = toQueryString({
    changeRequestId: changeRequestId,
    personId: personId,
  })
  const options = cancelTokenUtil.abortAndGetCancelTokenOptions('SCHEDULE_CHANGE_REQUEST')
  return axios.get(`${url}?${queryParams}`, options)
}

// Get the District Schedule Team Member Info
export function fetchDistrictScheduleTeamMemberInfoByPersonId({ personId = `` }) {
  const url = apiConfig.DISTRICT_SCHEDULE_TEAM_MEMBER_INFO
  const queryParams = toQueryString({
    personId: personId,
  })
  const options = cancelTokenUtil.abortAndGetCancelTokenOptions('DISTRICT_SCHEDULE_TEAM_MEMBER_INFO')
  return axios.get(`${url}?${queryParams}`, options)
}

// Insert the Schedule Change Request Approval
export function insertScheduleChangeRequestApprove(data = {}) {
  const url = apiConfig.SCHEDULE_CHANGE_REQUEST_APPROVE
  return axios.post(url, JSON.stringify(data))
}

// Insert the Schedule Change Request Denial
export function insertScheduleChangeRequestDeny(data = {}) {
  const url = apiConfig.SCHEDULE_CHANGE_REQUEST_DENY
  return axios.post(url, JSON.stringify(data))
}

// Fetch the Schedule Change History
export function fetchScheduleChangeHistoryByScheduledEventId({ scheduledEventId = `` }) {
  const url = apiConfig.SCHEDULE_CHANGE_HISTORY
  const queryParams = toQueryString({
    scheduledEventId: scheduledEventId,
  })
  const options = cancelTokenUtil.abortAndGetCancelTokenOptions('SCHEDULE_CHANGE_HISTORY')
  return axios.get(`${url}?${queryParams}`, options)
}

// Insert the Availability
export function insertAvailability({
  startDate = ``,
  endDate = ``,
  startTime = ``,
  endTime = ``,
  reason = ``,
  notes = ``,
  organizationId = ``,
}) {
  const url = apiConfig.AVAILABILITY
  const data = {
    startDate,
    endDate,
    startTime,
    endTime,
    reason,
    notes,
    organizationId,
  }
  return axios.post(url, data)
}

export function fetchScheduleChangeTypes() {
  const url = apiConfig.SCHEDULE_CHANGE_TYPES
  const options = cancelTokenUtil.abortAndGetCancelTokenOptions('SCHEDULE_CHANGE_TYPES')
  return axios.get(`${url}`, options)
}

export function fetchScheduleChangeReasons() {
  const url = apiConfig.SCHEDULE_CHANGE_REASONS
  const options = cancelTokenUtil.abortAndGetCancelTokenOptions('SCHEDULE_CHANGE_REASONS')
  return axios.get(`${url}`, options)
}

export function fetchScheduleAphByScheduleEventId(scheduledEventId) {
  const url = apiConfig.SCHEDULE_APH.replace('[eventId]', scheduledEventId)
  const options = cancelTokenUtil.abortAndGetCancelTokenOptions('SCHEDULE_APH')
  return axios.get(`${url}`, options)
}

// get array of schedule details 
export function getEventDetailsList(scheduledEventIds) {
  const url = apiConfig.GET_EVENT_DETAILS_LIST.replace('[scheduledEventIds]', scheduledEventIds.join())
  const options = cancelTokenUtil.abortAndGetCancelTokenOptions('GET_EVENT_DETAILS_LIST')
  return axios.get(url, options)
}


export function fetchChatItems(eventIds) {
  const url = apiConfig.GET_EVENT_CHAT_NOTIFICATIONS
  return axios.put(url,  eventIds);
}

export function fetchScheduleMeetSiteByScheduleEventId(scheduledEventId) {
  const url = apiConfig.SCHEDULE_MEET_SITE.replace('[scheduledEventId]', scheduledEventId)
  const options = cancelTokenUtil.abortAndGetCancelTokenOptions('SCHEDULE_MEET_SITE')
  return axios.get(`${url}`, options)
}
