import * as types from './actionTypes'
import services from '../services'

export function getHistoryRequests(eventId) {
  return dispatch => {
    dispatch(setDistrictHistoryPending(true))
    services.schedule
      .fetchScheduleChangeHistoryByScheduledEventId({
        scheduledEventId: eventId,
      })
      .then(response => {
        dispatch(getDistrictHistoryAction(response))
        dispatch(setDistrictHistoryPending(false))
      })
      .catch(error => {
        dispatch(setDistrictHistoryError(error))
        dispatch(setDistrictHistoryPending(false))
      })
  }
}
const getDistrictHistoryAction = response => {
  return {
    type: types.GET_REQUEST_HISTORY,
    payload: response.data,
  }
}
const setDistrictHistoryError = response => {
  return {
    type: types.GET_REQUEST_HISTORY_ERROR,
    payload: response.status,
  }
}
const setDistrictHistoryPending = response => {
  return {
    type: types.GET_REQUEST_HISTORY_PENDING,
    payload: response,
  }
}

export function getRequestDetails(eventChangeId) {
  return dispatch => {
    dispatch(setRequestDetailsPending(true))
    services.schedule
      .fetchScheduleChangeRequestByChangeRequestIdAndPersonId({
        changeRequestId: eventChangeId,
      })
      .then(response => {
        dispatch(getRequestDetailsAction(response))
      })
      .catch(error => {
        dispatch(setRequestDetailsError(error))
      })
      .finally(() => {
        dispatch(setRequestDetailsPending(false))
      })
  }
}

const getRequestDetailsAction = response => {
  return {
    type: types.GET_REQUEST_DETAILS,
    payload: response.data,
  }
}
const setRequestDetailsError = response => {
  return {
    type: types.GET_REQUEST_DETAILS_ERROR,
    payload: response.status,
  }
}
const setRequestDetailsPending = response => {
  return {
    type: types.GET_REQUEST_DETAILS_PENDING,
    payload: response,
  }
}
