import {
	GET_PROFILE_IMAGE_SUCCESS,
	GET_PROFILE_IMAGE_ERROR,
	GET_PROFILE_IMAGE_PENDING,

	POST_PROFILE_IMAGE_SUCCESS,
	POST_PROFILE_IMAGE_PENDING,
	POST_PROFILE_IMAGE_ERROR,

	POST_LIST_OF_PROFILE_IMAGES_SUCCESS,
	POST_LIST_OF_PROFILE_IMAGES_ERROR,
	POST_LIST_OF_PROFILE_IMAGES_PENDING,
	RESET_LIST_OF_PROFILE_IMAGES,

	DELETE_PROFILE_IMAGE_SUCCESS,
	DELETE_PROFILE_IMAGE_PENDING,
	DELETE_PROFILE_IMAGE_ERROR,

	SET_EDITING_COMMUNICATION_PREFERENCES,
	GET_PROFILE_PREFERRED_LANGUAGE_LIST_SUCCESS,
	GET_PROFILE_PREFERRED_LANGUAGE_LIST_ERROR,
	GET_PROFILE_PREFERRED_LANGUAGE_LIST_PENDING,
	GET_PROFILE_PREFERRED_LANGUAGE_SUCCESS,
	GET_PROFILE_PREFERRED_LANGUAGE_ERROR,
	GET_PROFILE_PREFERRED_LANGUAGE_PENDING,
	PUT_PROFILE_PREFERRED_LANGUAGE_SUCCESS,
	PUT_PROFILE_PREFERRED_LANGUAGE_ERROR,
	PUT_PROFILE_PREFERRED_LANGUAGE_PENDING,

	POST_MODERATION_ITEM_SUCCESS,
} from '../actions/actionTypes'
import { cloneDeep } from 'lodash'

export default function profileReducer(
	state = {
		profileImage: null,
		profileImagePending: false,
		profileImageError: {},

		postPersonProfileImage: '',
		postPersonProfileImagePending: false,
		postPersonProfileImageError: {},

		profileImages: [],
		profileImagesError: {},
		profileImagesPending: {
			transportation: false,
		},

		deleteProfileImagePending: false,
		deleteProfileImageError: {},

		profilePreferredLanguage: null,
		profilePreferredLanguagePending: false,
		profilePreferredLanguageError: {},

		putProfilePreferredLanguagePending: false,
		putProfilePreferredLanguageError: {},

		editingCommunicationPreferences: false,

		profilePreferredLanguageList: null,
		profilePreferredLanguageListPending: false,
		profilePreferredLanguageListError: {},
	},
	action
) {
	switch (action.type) {
		case GET_PROFILE_PREFERRED_LANGUAGE_LIST_SUCCESS:
			return {
				...state,
				profilePreferredLanguageList: action.payload,
				profilePreferredLanguageListError: {},
			}
		case GET_PROFILE_PREFERRED_LANGUAGE_LIST_ERROR:
			return {
				...state,
				profilePreferredLanguageListError: action.error,
			}
		case GET_PROFILE_PREFERRED_LANGUAGE_LIST_PENDING:
			return {
				...state,
				profilePreferredLanguageListPending: action.status,
			}
		case SET_EDITING_COMMUNICATION_PREFERENCES:
			return {
				...state,
				editingCommunicationPreferences: action.payload,
			}
		case GET_PROFILE_PREFERRED_LANGUAGE_SUCCESS:
			return {
				...state,
				profilePreferredLanguage: action.payload,
				profilePreferredLanguageError: {},
			}
		case GET_PROFILE_PREFERRED_LANGUAGE_ERROR:
			return {
				...state,
				profilePreferredLanguageError: action.error,
			}
		case GET_PROFILE_PREFERRED_LANGUAGE_PENDING:
			return {
				...state,
				profilePreferredLanguagePending: action.status,
			}
		case PUT_PROFILE_PREFERRED_LANGUAGE_SUCCESS:
			return {
				...state,
				putProfilePreferredLanguageError: {},
			}
		case PUT_PROFILE_PREFERRED_LANGUAGE_ERROR:
			return {
				...state,
				putProfilePreferredLanguageError: action.error,
			}
		case PUT_PROFILE_PREFERRED_LANGUAGE_PENDING:
			return {
				...state,
				putProfilePreferredLanguagePending: action.status,
			}
		case GET_PROFILE_IMAGE_SUCCESS:
			return {
				...state,
				profileImage: action.payload,
				profileImageError: {},
			}
		case GET_PROFILE_IMAGE_ERROR:
			return {
				...state,
				profileImageError: action.error,
			}
		case GET_PROFILE_IMAGE_PENDING:
			return {
				...state,
				profileImagePending: action.status,
			}
		case POST_PROFILE_IMAGE_SUCCESS:
			return {
				...state,
				postPersonProfileImage: action.payload,
				postPersonProfileImageError: {},
			}
		case POST_PROFILE_IMAGE_ERROR:
			return {
				...state,
				postPersonProfileImageError: action.error,
			}
		case POST_PROFILE_IMAGE_PENDING:
			return {
				...state,
				postPersonProfileImagePending: action.status,
			}
		case RESET_LIST_OF_PROFILE_IMAGES: {
			return {
				...state,
				profileImages: [],
				profileImagesError: {},
			}
		}
		case POST_LIST_OF_PROFILE_IMAGES_SUCCESS: {
			const _profileImages = cloneDeep(state.profileImages)
			action.payload.map(item => {
				const index = _profileImages.findIndex(_item => item.personId === _item.personId)
				if (index === -1) {
					_profileImages.push(item)
				} else {
					_profileImages[index].url = item.url
				}
				return null
			})
			return {
				...state,
				profileImages: _profileImages,
				profileImagesError: {},
			}
		}
		case POST_LIST_OF_PROFILE_IMAGES_ERROR:
			return {
				...state,
				profileImagesError: action.error,
			}
		case POST_LIST_OF_PROFILE_IMAGES_PENDING: {
			let _profileImagesPending = state.profileImagePending
			_profileImagesPending = {
				..._profileImagesPending,
				[action.location]: action.status,
			}
			return {
				...state,
				profileImagesPending: _profileImagesPending,
			}
		}

		case DELETE_PROFILE_IMAGE_SUCCESS:
			return {
				...state,
				postPersonProfileImage: '',
				deleteProfileImagePending: false,
			}

		case DELETE_PROFILE_IMAGE_ERROR:
			return {
				...state,
				deleteProfileImageError: action.error,
				deleteProfileImagePending: false,
			}

		case DELETE_PROFILE_IMAGE_PENDING:
			return {
				...state,
				deleteProfileImagePending: action.status,
			}

			case POST_MODERATION_ITEM_SUCCESS: {
				const _profileImages = state.profileImages.map(item =>
					item.personId === action.payload.entityPersonId ?
						{ ...item, isFlagged: true }
						:
						item
				)
				return {
					...state,
					profileImages: _profileImages,
				}
			}
		default:
			return state
	}
}
