import {
  POST_NEW_POST_SUCCESS,
  POST_NEW_POST_ERROR,
  POST_NEW_POST_PENDING,
} from '../actions/actionTypes'

export default function channelReducer(
  state = {
    newPostPending: false,
    newPostError: {}
  },
  action
) {
  switch (action.type) {
    case POST_NEW_POST_SUCCESS:
      return {
        ...state,
        newPostPending: false,
      }
    case POST_NEW_POST_ERROR:
      return {
        ...state,
        newPostError: action.error,
        newPostPending: false,
      }
    case POST_NEW_POST_PENDING:
      return {
        ...state,
        newPostPending: true,
      }
    default:
      return state
  }
}
