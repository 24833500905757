import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { getContacts } from '../../state/actions/contactsActions'
import ContactsListItem from '../../components/Contacts/ContactsListItem'
import { postListOfProfileImages } from '../../state/actions/profileActions'

import { isDefined } from '../../components/utils/nullSafeCheckUtils'

import Preloader from '../../components/Preloader'
import './ContactsContainer.scss'
import { t } from '../../utils/i18n';

class ContactsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    if (this.props.contacts.length === 0) {
      this.props.getContacts()
    }
  }

  componentDidUpdate(prevProps) {
    this.loadContacts(prevProps)
  }

  loadContacts = (prevProps) => {
    if (prevProps.contactsPending === true && this.props.contactsPending === false) {
      const personIds = []

      if (!isDefined(this.props.contactsError)) {
        this.props.contacts.map((contact) => (contact.contacts.length &&
          contact.contacts.map((_contact) => {
            if (_contact.personId && !personIds.includes(_contact.personId)) {
              personIds.push(_contact.personId)
            }
            return null
          })
        ))

        if (personIds.length > 0) {
          this.props.postListOfProfileImages(personIds, 'contacts')
        }
      }
    }
  }

  render() {
    const { contactsPending, contacts, profileImages, profileImagesPending } = this.props
    if (contactsPending || profileImagesPending) {
      return <Preloader />
    }

    return (
      <div className="contacts-container">
        <div className="row">
          <div className="col contacts-header">{t('contacts')}</div>
        </div>
        <div>
          {
            contacts && contacts.map((contact) => (
              <div className="mb-4" key={contact.name}>
                <h5>{contact.name}</h5>
                {contact.contacts.length ?
                  contact.contacts.map((_contact) => (
                    <ContactsListItem
                      key={_contact.name}
                      contact={_contact}
                      generalContact={contact.name === 'General Contacts'}
                      userAvatar={profileImages.find(obj => obj.personId === _contact.personId)}
                    />
                  ))
                  :
                  t('no_available_contacts')
                }
              </div>
            ))
          }
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    contactsPending: state.contactsReducer.contactsPending,
    contacts: state.contactsReducer.contacts,
    contactsError: state.contactsReducer.contactsError,
    profileImages: state.profileReducer.profileImages,
    profileImagesPending: state.profileReducer.profileImagesPending['contacts'],
    profileImagesError: state.profileReducer.profileImagesError,
    profileLocale: state.loginReducer.profileLocale,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getContacts: () => getContacts(),
      postListOfProfileImages: (personIds, location) => postListOfProfileImages(personIds, location),
    },
    dispatch
  )
}

ContactsContainer.propTypes = {
  getContacts: PropTypes.func,
  contactsPending: PropTypes.bool,
  contacts: PropTypes.array,
  contactsError: PropTypes.object,
  postListOfProfileImages: PropTypes.func,
  profileImages: PropTypes.array,
  profileImagesPending: PropTypes.bool,
  profileImagesError: PropTypes.object,
  profileLocale: PropTypes.string,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactsContainer)
