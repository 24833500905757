import * as types from './actionTypes'
import services from '../services'

export function getTeamMemberInfo(personId) {
  return dispatch => {
    dispatch(setTeamMemberInfoPending(true))
    services.schedule
      .fetchDistrictScheduleTeamMemberInfoByPersonId({
        personId,
      })
      .then(response => {
        dispatch(setTeamMemberInfoPending(false))
        dispatch(getTeamMemberInfoAction(response, personId))
      })
      .catch(error => {
        dispatch(setTeamMemberInfoError(error))
      })
  }
}
const getTeamMemberInfoAction = (response, personId) => {
  return {
    type: types.GET_TEAM_MEMBER_INFO_SUCCESS,
    payload: response.data,
    status: response.status,
    personId: personId,
  }
}
const setTeamMemberInfoError = response => {
  return {
    type: types.GET_TEAM_MEMBER_INFO_ERROR,
    payload: response,
  }
}
const setTeamMemberInfoPending = status => {
  return {
    type: types.GET_TEAM_MEMBER_INFO_PENDING,
    payload: status,
  }
}
