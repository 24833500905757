import React from 'react'
import PropTypes from 'prop-types'
import './ActionModal.scss'

class ActionModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { showModal, modalText, modalTitle, showCancelAction, modalAcceptButtonText, modalCancelButtonText } = this.props
    return (
      <div
        className={`action-modal modal fade ${showModal ? 'show' : ''}`}
        onClick={this.props.modalCancelAction}
        id="actionModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="actionModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content" onClick={(evt) => evt.stopPropagation()}>
            <h3 className="modal-title">
              {modalTitle}
            </h3>
            <div className="modal-body">
              {modalText}
            </div>
            <div className="row justify-content-center">
              {
                showCancelAction &&
                <div className="col-md-4 col-6">
                  <input
                    type='button'
                    onClick={this.props.modalCancelAction}
                    className="btn btn-secondary w-100"
                    value={modalCancelButtonText}
                  />
                </div>
              }
              <div className="col-md-4 col-6">
                <input
                  type='button'
                  onClick={this.props.modalAcceptAction}
                  className="btn btn-rgis-blue w-100"
                  value={modalAcceptButtonText}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


ActionModal.propTypes = {
  showModal: PropTypes.bool,
  modalTitle: PropTypes.string,
  modalText: PropTypes.string,
  showCancelAction: PropTypes.bool,
  modalAcceptAction: PropTypes.func,
  modalCancelAction: PropTypes.func,
  modalAcceptButtonText: PropTypes.string,
  modalCancelButtonText: PropTypes.string,
}

export default ActionModal
