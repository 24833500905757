import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import TimePicker from 'react-time-picker'

import Popup from './ConfirmationPopup'
import { t } from '../../../utils/i18n'
import { nullSafeCheckIsTrue } from '../../utils/nullSafeCheckUtils'

import { 
  getTimeFormatByCountry, 
  formatDateNotForDisplay, 
  formatTimeNotForDisplayIncludeSeconds, 
  formatTime, 
  formatTimeNotForDisplay 
} from '../../utils/datetimeUtils'
import { getCountry } from '../../utils/localStorageUtils'

import './RequestEventChange.scss'

class RequestEventChange extends Component {
  constructor(props) {
    super(props)

    this.state = {
      reasonValue: '',
      eventValue: '',
      notes: '',
      validForm: false,
      timeRequired: false,
      attendingTime: '',
      showConfirmationPopup: false,
      requestMade: false,
      hourDifference: 0,
      lateTime: this.props.eventStartTime,
      earlyTime: this.props.eventEndTime,
    }
    this.handleModalClose = this.handleModalClose.bind(this)
    this.selectEventType = this.selectEventType.bind(this)
    this.selectReason = this.selectReason.bind(this)
    this.changeNote = this.changeNote.bind(this)
    this.handleRequestMade = this.handleRequestMade.bind(this)
    this.cancelConfirmationPopup = this.cancelConfirmationPopup.bind(this)
    this.showConfirmationPopup = this.showConfirmationPopup.bind(this)
    this.getLateTimeMarkup = this.getLateTimeMarkup.bind(this)
    this.getEarlyTimeMarkup = this.getEarlyTimeMarkup.bind(this)
  }

  static getDerivedStateFromProps(props, state) {
    const { requestChangeMade } = props
    const { requestMade } = state
    if (requestMade === true && requestChangeMade === true) {
      props.modalRequestChangeClose()
      return {
        requestMade: false,
        reasonValue: t('reason'),
        reasonCode: '',
        notes: '',
        eventValue: '',
        eventCode: '',
        validForm: false,
        invalidTime: false,
        attendingTime: '',
        showConfirmationPopup: false,
        timeRequired: false,
      }
    }
    return null
  }

  handleModalClose() {
    this.setState({
      reasonValue: '',
      reasonCode: '',
      notes: '',
      eventValue: '',
      eventCode: '',
      validForm: false,
      invalidTime: false,
      attendingTime: '',
      timeRequired: false,
    })
    this.props.modalRequestChangeClose()
  }

  getLateTimeMarkup() {
    const { eventStartTime, eventEndTime } = this.props
    const { invalidTime, lateTime } = this.state
   
    let defaultTime = formatTimeNotForDisplay(lateTime).toString()
    let startTimeStr = formatTime(eventStartTime).toString()
    let endTimeStr = formatTime(eventEndTime).toString()

    return (
      <div>
        <div>
          <div className="boldText request-title">{t('change_request_late_prompt')}</div>
          <TimePicker
            disableClock={true}
            clearIcon={null}
            id="startTime"
            name="startTime"
            onChange={this.onLateChangeTime}
            value={defaultTime}
            format={getTimeFormatByCountry(getCountry())}
          />
          <div className={`${invalidTime ? '' : 'displayNone'}`}>
            <p>{t('error')}.</p>
            <div className="underLine boldText">{t('request_change_time_range', [startTimeStr, endTimeStr])}</div>
          </div>
        </div>
      </div>
    )
  }

  getEarlyTimeMarkup() {
    const { eventEndTime, eventStartTime} = this.props;
    const { invalidTime, earlyTime } = this.state

    let defaultTime = formatTimeNotForDisplay(earlyTime).toString()
    let startTimeStr = formatTime(eventStartTime).toString()
    let endTimeStr = formatTime(eventEndTime).toString()

    return (
      <div>
        <div>
          <div className="boldText request-title">{t('change_request_early_prompt')}</div>
          <TimePicker
            disableClock={true}
            clearIcon={null}
            id="endTime"
            name="endTime"
            onChange={this.onEarlyChangeTime}
            value={defaultTime}
            format={getTimeFormatByCountry(getCountry())}
          />
          <div className={`${invalidTime ? '' : 'displayNone'}`}>
            <div className="boldText">{t('error')}</div>
            <div className="underLine boldText">{t('request_change_time_range', [startTimeStr,endTimeStr])}</div>
          </div>
        </div>
      </div>
    )
  }

  selectEventType(e) {
    const { reasonValue } = this.state
    let timeCheck = e.target.dataset.eventtypecode !== "EVENT_LEAVE_EARLY" && e.target.dataset.eventtypecode !== "EVENT_ATTEND_LATE"
    this.setState({
      eventValue: e.target.dataset.eventtypecode,
      validForm: reasonValue !== '' && e.target.dataset.eventtypecode !== '' && timeCheck ? true : false,
      timeRequired: !timeCheck,
      invalidTime: false,
      attendingTime: '',
    })
  }

  selectReason(e) {
    const { eventValue, timeRequired } = this.state
    let timeCheck = (eventValue !== "EVENT_LEAVE_EARLY" && eventValue !== "EVENT_ATTEND_LATE") || !timeRequired
    this.setState({
      reasonValue: e.target.dataset.eventreason,
      validForm: eventValue !== '' && e.target.dataset.eventreason !== '' && timeCheck ? true : false,
    })
  }

  changeNote(e) {
    this.setState({
      notes: e.target.value,
    })
  }

  onChangeTime = newTime => {
    const { eventEndTime, eventStartTime } = this.props;
    const { reasonValue } = this.state
    let requestDT = moment()
    let eventDateStr = formatDateNotForDisplay(eventStartTime)

    requestDT = moment(eventDateStr + " " + newTime)
    //Event duration over midnight check
    if(!moment(eventEndTime).isSame(eventStartTime, 'day') && moment(requestDT).hour() < 12){
      requestDT = moment(requestDT).add(1, 'days')
    }
    let timeCheck = moment(requestDT).isBefore(eventStartTime) || moment(requestDT).isAfter(eventEndTime) ? true : false

    this.setState({
      attendingTime: requestDT.isValid ? requestDT : '',
      invalidTime: timeCheck,
      validForm: requestDT.isValid && !timeCheck && reasonValue !== '' ? true : false,
      timeRequired: timeCheck
    })
  }

  onLateChangeTime = newTime => {
    const { eventStartTime } = this.props;

    if (!moment(newTime).isValid()) {
      newTime = new moment(formatDateNotForDisplay(eventStartTime) + ' ' + newTime)
    }

    this.setState({lateTime: newTime})
    this.onChangeTime(formatTimeNotForDisplay(newTime))
  }

  onEarlyChangeTime = newTime => {
    const { eventStartTime } = this.props;

    if (!moment(newTime).isValid()) {
      newTime = new moment(formatDateNotForDisplay(eventStartTime) + ' ' + newTime)
    }

    this.setState({earlyTime: newTime})
    this.onChangeTime(formatTimeNotForDisplay(newTime))
  }

  handleRequestMade = () => {
    this.setState({
      requestMade: true,
      showConfirmationPopup: false,
      requestChangeMade: true,
    })
    this.props.handleChangeRequestMade()
    this.props.modalRequestChangeClose()
  }

  cancelConfirmationPopup() {
    this.setState({
      showConfirmationPopup: false,
    })
  }

  showConfirmationPopup() {
    let datetimeOfEvent = moment(this.props.hourOfEvent)
    let datetimeNow = moment(new Date())
    let duration = moment.duration(datetimeOfEvent.diff(datetimeNow))
    let hours = duration.asHours()
    this.setState({
      showConfirmationPopup: true,
      hourDifference: hours,
    })
  }

  getShortNoticeHours = () => {
    if (this.props.featureAccess === undefined || this.props.featureAccess === null) { return; }

    if (nullSafeCheckIsTrue(this.props.featureAccess.changeRequests48)) { 
      return 48 // 48 hours
    } else {
      return 24 // 24 hours
    }
  }

  getRequestDetails = () => {
    const { reasonValue, eventValue, notes, attendingTime } = this.state
    const scheduledEventId = this.props.scheduledEventId

    let formattedTime = formatTimeNotForDisplayIncludeSeconds(attendingTime)
    return {
      reasonCode: reasonValue,
      eventCode: eventValue,
      notes: notes,
      scheduledEventId: scheduledEventId,
      attendingTime: formattedTime
    }
  }

  renderEventTypes() { }

  render() {
    const { validForm, showConfirmationPopup, notes, hourDifference } = this.state

    return (
      <div>
        <div
          id="modal1"
          className={`modal fade fullPageModal animation-topToBottom request-event-change-modal ${
            this.props.showRequestModal === true ? 'show' : ''
            }`}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{t('request_change')}</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.handleModalClose}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body eventDetails-content">
                <div className="boldText request-title">{t('what_is_happening')}</div>
                {this.props.scheduleChangeTypes.length === 0
                  ? ''
                  : this.props.scheduleChangeTypes.map((event, i) => {
                    return (
                      <div className="request-reason" key={i}>
                        <input
                          type="radio"
                          name="reason"
                          data-eventtypecode={event.typeCode}
                          checked={this.state.eventValue === event.typeCode}
                          onChange={this.selectEventType}
                        />
                        <div className="round-checkbox" />
                        <label
                          className="request-reason-label normalText"
                          data-eventtypecode={event.typeCode}
                        >
                          {event.description}
                        </label>
                      </div>
                    )
                  })}
                <div>
                  {this.state.eventValue === "EVENT_ATTEND_LATE" ? this.getLateTimeMarkup() : ''}
                </div>
                <div>
                  {this.state.eventValue === "EVENT_LEAVE_EARLY" ? this.getEarlyTimeMarkup() : ''}
                </div>
                <hr />
                <div className="boldText request-title">{t('indicate_reason')}</div>
                {this.props.scheduleChangeReasons.length === 0
                  ? ''
                  : this.props.scheduleChangeReasons.map((reason, i) => {
                    return (
                      <div className="request-reason" key={i}>
                        <input
                          type="radio"
                          name="event"
                          checked={this.state.reasonValue === reason.reasonCode}
                          data-eventreason={reason.reasonCode}
                          onChange={this.selectReason}
                        />
                        <div className="round-checkbox" />
                        <label
                          className="request-reason-label normalText"
                          data-eventreason={reason.reasonCode}
                        >
                          {reason.description}
                        </label>
                      </div>
                    )
                  })}
                <div className="note">
                  <textarea placeholder={t('description_of_reason')} onChange={this.changeNote} value={notes} />
                </div>
                <div className="button-wrapper">
                  <button
                    type="button"
                    className="col-lg-2 col-sm-4 btn btn-rgis-blue semiBoldText request-review uppercase cursor-pointer"
                    disabled={!validForm}
                    onClick={this.showConfirmationPopup}
                  >
                    {t('submit')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Popup
          cancelConfirmationPopup={this.cancelConfirmationPopup}
          hourDifference={hourDifference.toString()}
          handleRequestMade={this.handleRequestMade}
          showConfirmationPopup={showConfirmationPopup}
          getLateTimeMarkup={this.getLateTimeMarkup}
          getEarlyTimeMarkup={this.getEarlyTimeMarkup}
          shortNoticeHours={this.getShortNoticeHours()}
          history={this.props.history}
          getRequestDetails={this.getRequestDetails}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    scheduleChangeTypes: state.scheduleReducer.scheduleChangeTypes,
    scheduleChangeReasons: state.scheduleReducer.scheduleChangeReasons,
  }  
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
    },
    dispatch
  )
}

RequestEventChange.propTypes = {
  modalRequestChangeClose: PropTypes.func,
  submitRequestEventChange: PropTypes.func,
  requestChangeMade: PropTypes.bool,
  showRequestModal: PropTypes.bool,
  scheduleChangeTypes: PropTypes.array,
  scheduleChangeReasons: PropTypes.array,
  scheduledEventId: PropTypes.string,
  hourOfEvent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  eventEndTime: PropTypes.object,
  eventStartTime: PropTypes.object,
  featureAccess: PropTypes.object,
  history: PropTypes.object,
  handleChangeRequestMade: PropTypes.func,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestEventChange)