
export function nullSafeCheckIsTrue(objectValue) {
    return isDefined(objectValue) && objectValue.toString().toLowerCase() === 'true'
}

export function nullSafeCheckIsFalse(objectValue) {
    return isDefined(objectValue) && objectValue.toString().toLowerCase() === 'false'
}

export function isDefined(object) {
    return object !== undefined && object !== null && object !== 'undefined'
}

export function isDefinedAndNotEmpty(object) {
    return isDefined(object) && object.toString().length > 0
}

export function isDefinedAndHasOwnProperties(object) {
    if (!isDefined(object)) {
        return false
    }

    for(var prop in object) {
        if (object.hasOwnProperty(prop)) {
            return true
        }
    }

    return false
}