import axios from 'axios'
import apiConfig from '../../config/api-config'

export function postModerationItem(moderationItem) {
  const url = apiConfig.POST_MODERATION_ITEM
  return axios.post(`${url}`, moderationItem)
}

export function getModerationReasons() {
  const url = apiConfig.GET_MODERATION_REASONS
  return axios.get(url);
}
