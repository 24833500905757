import {
    GET_FEATURE_ACCESS_LIST_SUCCESS,
    GET_FEATURE_ACCESS_LIST_PENDING,
    GET_FEATURE_ACCESS_LIST_ERROR,
    GET_PERSON_FEATURE_ACCESS_SUCCESS,
    GET_PERSON_FEATURE_ACCESS_PENDING,
    GET_PERSON_FEATURE_ACCESS_ERROR,
} from '../actions/actionTypes'

export default function featureAccessReducer(
    state = {
        featureAccess: null,
        featureAccessError: {},
        featureAccessPending: false,
        personFeatureAccess: null,
        personFeatureAccessError: {},
        personFeatureAccessPending: false,
    },
    action
) {
    switch (action.type) {
        case GET_FEATURE_ACCESS_LIST_SUCCESS:
            return {
                ...state,
                featureAccess: action.payload ? action.payload : {},
                featureAccessError: {},
            }
        case GET_FEATURE_ACCESS_LIST_PENDING:
            return {
                ...state,
                featureAccessPending: action.payload,
            }
        case GET_FEATURE_ACCESS_LIST_ERROR:
            return {
                ...state,
                featureAccessError: action.error,
            }
        case GET_PERSON_FEATURE_ACCESS_SUCCESS:
            return {
                ...state,
                personFeatureAccess: action.payload ? action.payload : {},
                personFeatureAccessError: {},
            }
        case GET_PERSON_FEATURE_ACCESS_PENDING:
            return {
                ...state,
                personFeatureAccessPending: action.payload,
            }
        case GET_PERSON_FEATURE_ACCESS_ERROR:
            return {
                ...state,
                personFeatureAccessError: action.error,
            }            
        default:
            return state
    }
}