import get from 'lodash/get'
import * as types from './actionTypes'
import services from '../services'

export function getTransportationTypes() {
  return dispatch => {
    services.transportationProfile
      .getTransportationTypes()
      .then(response => {
        dispatch(getTransportationTypesPending(false))
        dispatch(getTransportationTypesSuccess(response))
      })
      .catch(error => {
        dispatch(getTransportationTypesError(error))
      })
  }
}

const getTransportationTypesSuccess = response => {
  return {
    type: types.GET_TRANSPORTATION_TYPES_SUCCESS,
    payload: response.data,
  }
}
const getTransportationTypesError = response => {
  return {
    type: types.GET_TRANSPORTATION_TYPES_ERROR,
    payload: response,
  }
}
const getTransportationTypesPending = response => {
  return {
    type: types.GET_TRANSPORTATION_TYPES_PENDING,
    payload: response,
  }
}

export function setEditingTransportationProfile(editing) {
    return dispatch => {
            dispatch(setEditingTransportationProfileAction(editing))
        }
    }
    
const setEditingTransportationProfileAction = payload => {
    return {
        type: types.EDITING_TRANSPORTATION_PROFILE,
        payload: payload,
    }
}

export function getTransportationProfile(personId) {
  return dispatch => {
    dispatch(getTransportationProfilePending(true))
    services.transportationProfile
      .getTransportationProfile(personId)
      .then(response => {
        dispatch(getTransportationProfileSuccess(response))
        dispatch(getTransportationProfilePending(false))
      })
      .catch(error => {
        dispatch(getTransportationProfileError(error))
        dispatch(getTransportationProfilePending(false))
      })
  }
}

const getTransportationProfileSuccess = response => {
  return {
    type: types.GET_TRANSPORTATION_PROFILE_SUCCESS,
    payload: response.data,
  }
}
const getTransportationProfileError = response => {
  return {
    type: types.GET_TRANSPORTATION_PROFILE_ERROR,
    payload: response,
  }
}
const getTransportationProfilePending = response => {
  return {
    type: types.GET_TRANSPORTATION_PROFILE_PENDING,
    payload: response,
  }
}

export function saveTransportationProfile(transportationProfile) {
  return dispatch => {
    dispatch(
      saveTransportationProfileAction(transportationProfile)
    )
  }
}

const saveTransportationProfileAction = payload => {
  return {
    type: types.SAVE_TRANSPORTATION_PROFILE,
    payload: payload,
  }
}

export function setBackupTransportationProfile() {
  return dispatch => {
    dispatch(
      setBackupTransportationProfileAction()
    )
  }
}

const setBackupTransportationProfileAction = payload => {
  return {
    type: types.BACKUP_TRANSPORTATION_PROFILE,
    payload: payload,
  }
}

export function setSearchRideAlongTransportationProfile(searchString) {
  return dispatch => {
    dispatch(
      setSearchRideAlongTransportationProfileAction(searchString)
    )
  }
}

const setSearchRideAlongTransportationProfileAction = payload => {
  return {
    type: types.SEARCH_RIDE_ALONG_TRANSPORTATION_PROFILE,
    payload: payload,
  }
}

export function patchTransportationProfile(transportationProfile) {
  return dispatch => {
    dispatch(patchTransportationProfileActionPending(true))
    services.transportationProfile
      .patchTransportationProfile(transportationProfile)
      .then(response => {
        dispatch(patchTransportationProfileActionPending(false))
        dispatch(patchTransportationProfileActionSuccess(response))
      })
      .catch(error => {
        dispatch(patchTransportationProfileActionError(error))
      })
  }
}

const patchTransportationProfileActionSuccess = response => {
  return {
    type: types.PATCH_TRANSPORTATION_PROFILE_SUCCESS,
    payload: response.data,
  }
}
const patchTransportationProfileActionError = response => {
  return {
    type: types.PATCH_TRANSPORTATION_PROFILE_ERROR,
    error: response,
  }
}
const patchTransportationProfileActionPending = status => {
  return {
    type: types.PATCH_TRANSPORTATION_PROFILE_PENDING,
    pending: status,
  }
}

export function getRideAlongErrors(personId) {
  return dispatch => {
    dispatch(getRideAlongErrorsActionPending(true))
    services.transportationProfile
      .getRideAlongErrors(personId)
      .then(response => {
        dispatch(getRideAlongErrorsActionPending(false))
        dispatch(getRideAlongErrorsActionSuccess(response))
      })
      .catch(error => {
        dispatch(getRideAlongErrorsActionError(error))
      })
  }
}

const getRideAlongErrorsActionSuccess = response => {
  return {
    type: types.GET_RIDE_ALONG_ERRORS_SUCCESS,
    payload: response.data,
  }
}
const getRideAlongErrorsActionError = response => {
  return {
    type: types.GET_RIDE_ALONG_ERRORS_ERROR,
    error: response,
  }
}
const getRideAlongErrorsActionPending = status => {
  return {
    type: types.GET_RIDE_ALONG_ERRORS_PENDING,
    pending: status,
  }
}