import {
  GET_TEAM_LIST_DETAILS,
  GET_TEAM_LIST_DETAILS_ERROR,
  GET_TEAM_LIST_DETAILS_PENDING,
} from '../actions/actionTypes'

function handleTeamDetailsList(payload) {
  const destructuredArray = []
  for (let i = 0; i < payload.length; i++) {
    for (let j = 0; j < payload[i].length; j++) {
      destructuredArray.push(payload[i][j])
    }
  }
  const teamDetailsList = destructuredArray.sort((a, b) => {
    return a.meetTime > b.meetTime
  })

  return teamDetailsList
}

function handleTeamDetailsListDistricts(payload) {
  const teamDetailsListDistricts = []
  const teamDetailsList = handleTeamDetailsList(payload)

  teamDetailsList.forEach(item => {
    for (let i = 0; i < teamDetailsListDistricts.length; i++) {
      if (
        teamDetailsListDistricts[i].meetSite === item.meetSite &&
        teamDetailsListDistricts[i].meetTime === item.meetTime &&
        teamDetailsListDistricts[i].organizationNumber.trim().replace(/\D+/g, '') ===
          item.organizationNumber.trim().replace(/\D+/g, '')
      ) {
        return false
      }
    }
    teamDetailsListDistricts.push({
      meetSite: item.meetSite,
      organizationNumber: item.organizationNumber.trim().replace(/\D+/g, ''),
      backRoomInd: item.backRoomInd,
      meetTime: item.meetTime,
    })
  })

  const teamDetailstListDistrictsSorted = teamDetailsListDistricts.sort((a, b) => {
    if (a.organizationNumber.trim() === b.organizationNumber.trim()) {
      if (a.meetTime.trim() > b.meetTime.trim()) {
        return 1
      } else {
        return -1
      }
    } else {
      if (a.organizationNumber > b.organizationNumber) {
        return 1
      } else {
        return -1
      }
    }
  })

  return teamDetailstListDistrictsSorted
}

function countPeopleScheduled(payload) {
  let count = 0
  Object.keys(payload[0][0].teamCountDetails).forEach(index => {
    count +=
      parseInt(payload[0][0].teamCountDetails[index].confirmedCount) +
      parseInt(payload[0][0].teamCountDetails[index].pendingReviewCount) +
      parseInt(payload[0][0].teamCountDetails[index].unconfirmedCount)
  })
  return count
}

export default function teamDetailsListReducer(
  state = {
    teamDetailsList: [],
    teamDetailsListDistricts: [],
    teamDetailsListError: {},
    teamDetailsListPending: false,
    teamDetailsListNumberScheduled: 0,
  },
  action
) {
  switch (action.type) {
    case GET_TEAM_LIST_DETAILS:
      return {
        ...state,
        teamDetailsList: handleTeamDetailsList(action.payload),
        teamDetailsListDistricts: handleTeamDetailsListDistricts(action.payload),
        teamDetailsListNumberScheduled: countPeopleScheduled(action.payload),
        teamDetailsListError: {},
        teamDetailsListPending: false,
      }
    case GET_TEAM_LIST_DETAILS_ERROR:
      return {
        ...state,
        teamDetailsListError: action.payload,
      }
    case GET_TEAM_LIST_DETAILS_PENDING:
      return {
        ...state,
        teamDetailsListPending: action.payload,
      }
    default:
      return state
  }
}
