import React from 'react'
import PropTypes from 'prop-types'
import './CoreValuesLegendModal.scss'
import { t } from '../../utils/i18n';

import { INTEGRITY_VALUE, PRIDE_VALUE, SERVICE_VALUE, TEAMWORK_VALUE, INNOVATION_VALUE, RESULTS_VALUE } from '../../utils/constants';

export default class CoreValuesLegendModal extends React.Component {
  renderCoreValues() {
    const coreValues = [SERVICE_VALUE, PRIDE_VALUE, INTEGRITY_VALUE, RESULTS_VALUE, INNOVATION_VALUE, TEAMWORK_VALUE]
    return (
      <div className="">
        {coreValues.map((coreValue) =>
          <div key={coreValue} className="d-flex align-items-center mb-2">
            <div className={`mr-3 core-value-${coreValue.toLowerCase()}-fill`} />
            {t(`${coreValue.toLowerCase()}`)}
          </div>
        )}
      </div >
    )
  }

  render() {
    const { showModal } = this.props
    return (
      <div
        className={`action-modal modal fade ${showModal ? 'show' : ''}`}
        id="actionModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="actionModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content" onClick={(evt) => evt.stopPropagation()}>
            <h3 className="modal-title">
              {t('core_values_legend')}
            </h3>
            <div className="modal-body">
              {this.renderCoreValues()}
            </div>
            <div className="row justify-content-center">
              <div className="col-md-4 col-6">
                <input
                  type='button'
                  onClick={this.props.modalAcceptAction}
                  className="btn btn-rgis-blue w-100"
                  value={t('ok')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

CoreValuesLegendModal.propTypes = {
  showModal: PropTypes.bool,
  modalAcceptAction: PropTypes.func,
}