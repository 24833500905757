import * as types from './actionTypes'
import services from '../services'
import Axios from 'axios'

export function getNotifications(lastCheck) {
  return dispatch => {
    dispatch(getNotificationsActionPending(true))
    services.notification
      .fetchNotificationCount(lastCheck)
      .then(response => {
        if (response.data.totalCount > 0) {
          services.notification
            .fetchNotifications(response.data.totalCount)
            .then(response => {
              dispatch(getNotificationsActionSuccess(response))
              dispatch(getNotificationsActionPending(false))
            })
            // For cases when fetch notification fails
            .catch(error => {
              dispatch(getNotificationsActionError(error))
              dispatch(getNotificationsActionPending(false))
            })
        } else {
          // For cases when no of total count <= 0
          dispatch(getNotificationsActionPending(false))
        }
      })
      .catch(error => {
        // For cases when fetch notification count fails
        dispatch(getNotificationsActionError(error))
        dispatch(getNotificationsActionPending(false))
      })
  }
}

const getNotificationsActionSuccess = response => {
  return {
    type: types.GET_NOTIFICATIONS_SUCCESS,
    payload: response.data,
  }
}

const getNotificationsActionError = response => {
  return {
    type: types.GET_NOTIFICATIONS_ERROR,
    error: response,
  }
}

const getNotificationsActionPending = status => {
  return {
    type: types.GET_NOTIFICATIONS_PENDING,
    pending: status,
  }
}

export function getUnreadNotificationsCount(lastCheck) {
  return dispatch => {
    dispatch(getNewNotificationsCountActionPending(true))
    Axios
    .all([
      services.notification.fetchNotificationCount(lastCheck), 
      services.notification.fetchDotNetNotificationCount(lastCheck)])
    .then(
      Axios.spread((...responses) => {
        const oldNotificationsSystemCountResponse = responses[0];
        const dotNetNotificationSystemCountResponse = responses[1];

        const oldNotificationsSystemUnreadCount = oldNotificationsSystemCountResponse.data.unreadCount;
        const newNotificationsSystemUnreadCount = dotNetNotificationSystemCountResponse.data.New;
        const totalUnreadCount = oldNotificationsSystemUnreadCount + newNotificationsSystemUnreadCount;

        if (totalUnreadCount > 0) {
          dispatch(getNewNotificationsCountActionSuccess(totalUnreadCount))
          dispatch(getNewNotificationsCountActionPending(false))
        }
        else{
          dispatch(getNewNotificationsCountActionError('new notification count is 0'))
          dispatch(getNewNotificationsCountActionPending(false))
        }
      }))
    .catch(error => {
      dispatch(getNewNotificationsCountActionError(error))
      dispatch(getNewNotificationsCountActionPending(false))
    })
  }
}

const getNewNotificationsCountActionSuccess = count => {
  return {
    type: types.GET_NOTIFICATIONS_COUNT_SUCCESS,
    payload: count,
  }
}

const getNewNotificationsCountActionError = response => {
  return {
    type: types.GET_NOTIFICATIONS_COUNT_ERROR,
    error: response,
  }
}

const getNewNotificationsCountActionPending = status => {
  return {
    type: types.GET_NOTIFICATIONS_COUNT_PENDING,
    pending: status,
  }
}

export function patchMarkNotificationsAsRead(notificationIds) {
  return dispatch => {
    dispatch(patchMarkNotificationsAsReadActionPending(true))
    services.notification
      .patchMarkNotificationsAsRead(notificationIds)
      .then(response => {
        dispatch(patchMarkNotificationsAsReadActionSuccess(response, notificationIds))
        dispatch(patchMarkNotificationsAsReadActionPending(false))
      })
      .catch(error => {
        dispatch(patchMarkNotificationsAsReadActionError(error))
        dispatch(patchMarkNotificationsAsReadActionPending(false))
      })
  }
}

const patchMarkNotificationsAsReadActionSuccess = (response, notificationIds) => {
  return {
    type: types.PATCH_MARK_NOTIFICATIONS_AS_READ_SUCCESS,
    payload: response.data.unreadCount,
    notificationIds,
  }
}

const patchMarkNotificationsAsReadActionError = response => {
  return {
    type: types.PATCH_MARK_NOTIFICATIONS_AS_READ_ERROR,
    error: response,
  }
}

const patchMarkNotificationsAsReadActionPending = status => {
  return {
    type: types.PATCH_MARK_NOTIFICATIONS_AS_READ_PENDING,
    pending: status,
  }
}

export function getChannelIdByPostId(postId) {
  return dispatch => {
    dispatch(getChannelIdByPostIdActionPending(true))
    services.notification
      .getChannelIdByPostId(postId)
      .then(response => {
        dispatch(getChannelIdByPostIdActionSuccess(response))
        dispatch(getChannelIdByPostIdActionPending(false))
      })
      .catch(error => {
        dispatch(getChannelIdByPostIdActionError(error))
        dispatch(getChannelIdByPostIdActionPending(false))
      })
  }
}

const getChannelIdByPostIdActionSuccess = response => {
  return {
    type: types.GET_CHANNEL_ID_BY_POST_ID_SUCCESS,
    payload: response.data.channelId,
  }
}

const getChannelIdByPostIdActionError = response => {
  return {
    type: types.GET_CHANNEL_ID_BY_POST_ID_ERROR,
    error: response,
  }
}

const getChannelIdByPostIdActionPending = status => {
  return {
    type: types.GET_CHANNEL_ID_BY_POST_ID_PENDING,
    pending: status,
  }
}

export function getOrganizationNameByPersonId(personId) {
  return dispatch => {
    dispatch(getOrganizationNameByPersonIdActionPending(true))
    services.personDetails
      .fetchPersonDetails(personId)
      .then(response => {
        dispatch(getOrganizationNameByPersonIdActionSuccess(response))
        dispatch(getOrganizationNameByPersonIdActionPending(false))
      })
      .catch(error => {
        dispatch(getOrganizationNameByPersonIdActionError(error))
        dispatch(getOrganizationNameByPersonIdActionPending(false))
      })
  }
}

const getOrganizationNameByPersonIdActionSuccess = response => {
  return {
    type: types.GET_ORG_NAME_BY_PERSON_ID_SUCCESS,
    payload: response.data.organizationName,
  }
}

const getOrganizationNameByPersonIdActionError = response => {
  return {
    type: types.GET_ORG_NAME_BY_PERSON_ID_ERROR,
    error: response,
  }
}

const getOrganizationNameByPersonIdActionPending = status => {
  return {
    type: types.GET_ORG_NAME_BY_PERSON_ID_PENDING,
    pending: status,
  }
}

export function getNotificationsHistory(fromDateTime) {
  return dispatch => {
    dispatch(getNotificationsHistoryActionPending(true))
    services.notification
      .getNotificationsHistory(fromDateTime)
      .then(response => {
          dispatch(getNotificationsHistoryActionSuccess(response))
          dispatch(getNotificationsHistoryActionPending(false))
      })
      .catch(error => {
        dispatch(getNotificationsHistoryActionError(error))
        dispatch(getNotificationsHistoryActionPending(false))
      })
  }
}

const getNotificationsHistoryActionSuccess = response => {
  return {
    type: types.GET_NOTIFICATIONS_HISTORY_SUCCESS,
    payload: response.data,
  }
}

const getNotificationsHistoryActionError = response => {
  return {
    type: types.GET_NOTIFICATIONS_HISTORY_ERROR,
    error: response,
  }
}

const getNotificationsHistoryActionPending = status => {
  return {
    type: types.GET_NOTIFICATIONS_HISTORY_PENDING,
    pending: status,
  }
}

export function getUnreadNotificationsHistory(fromDateTime) {
  return dispatch => {
    dispatch(getUnreadNotificationsHistoryActionPending(true))
    services.notification
      .getUnreadNotificationsHistory(fromDateTime)
      .then(response => {
          dispatch(getUnreadNotificationsHistoryActionSuccess(response))
          dispatch(getUnreadNotificationsHistoryActionPending(false))
      })
      .catch(error => {
        dispatch(getUnreadNotificationsHistoryActionError(error))
        dispatch(getUnreadNotificationsHistoryActionPending(false))
      })
  }
}

const getUnreadNotificationsHistoryActionSuccess = response => {
  return {
    type: types.GET_UNREAD_NOTIFICATIONS_HISTORY_SUCCESS,
    payload: response.data,
  }
}

const getUnreadNotificationsHistoryActionError = response => {
  return {
    type: types.GET_UNREAD_NOTIFICATIONS_HISTORY_ERROR,
    error: response,
  }
}

const getUnreadNotificationsHistoryActionPending = status => {
  return {
    type: types.GET_UNREAD_NOTIFICATIONS_HISTORY_PENDING,
    pending: status,
  }
}

export function patchNotificationsHistoryStatusAsRead(notificationId) {
  return dispatch => {
    dispatch(patchNotificationsHistoryStatusAsReadActionPending(true))
    services.notification
      .patchNotificationsHistoryStatusAsRead(notificationId)
      .then(response => {
          dispatch(patchNotificationsHistoryStatusAsReadActionSuccess(response))
          dispatch(patchNotificationsHistoryStatusAsReadActionPending(false))
      })
      .catch(error => {
        dispatch(patchNotificationsHistoryStatusAsReadActionError(error))
        dispatch(patchNotificationsHistoryStatusAsReadActionPending(false))
      })
  }
}

const patchNotificationsHistoryStatusAsReadActionSuccess = response => {
  return {
    type: types.PATCH_NOTIFICATIONS_HISTORY_STATUS_AS_READ_SUCCESS,
    payload: response.data,
  }
}

const patchNotificationsHistoryStatusAsReadActionError = error => {
  return {
    type: types.PATCH_NOTIFICATIONS_HISTORY_STATUS_AS_READ_ERROR,
    payload: error,
  }
}

const patchNotificationsHistoryStatusAsReadActionPending = pending => {
  return {
    type: types.PATCH_NOTIFICATIONS_HISTORY_STATUS_AS_READ_PENDING,
    payload: pending,
  }
}

export function getNotificationHistoryTypes() {
  return dispatch => {
    dispatch(getNotificationHistoryTypesActionPending(true))
    services.notification
      .getNotificationHistoryTypes()
      .then(response => {
          dispatch(getNotificationHistoryTypesActionSuccess(response))
          dispatch(getNotificationHistoryTypesActionPending(false))
      })
      .catch(error => {
        dispatch(getNotificationHistoryTypesActionError(error))
        dispatch(getNotificationHistoryTypesActionPending(false))
      })
  }
}

const getNotificationHistoryTypesActionSuccess = response => {
  return {
    type: types.GET_NOTIFICATION_HISTORY_TYPES_SUCCESS,
    payload: response.data,
  }
}

const getNotificationHistoryTypesActionError = response => {
  return {
    type: types.GET_NOTIFICATION_HISTORY_TYPES_ERROR,
    payload: response,
  }
}

const getNotificationHistoryTypesActionPending = status => {
  return {
    type: types.GET_NOTIFICATION_HISTORY_TYPES_PENDING,
    payload: status,
  }
}

export function getNotificationPreferences() {
  return dispatch => {
    dispatch(getNotificationPreferencesActionPending(true))
    services.notification
      .getNotificationPreferences()
      .then(response => {
          dispatch(getNotificationPreferencesActionSuccess(response))
          dispatch(getNotificationPreferencesActionPending(false))
      })
      .catch(error => {
        dispatch(getNotificationPreferencesActionError(error))
        dispatch(getNotificationPreferencesActionPending(false))
      })
  }
}

const getNotificationPreferencesActionSuccess = response => {
  return {
    type: types.GET_NOTIFICATION_PREFERENCES_SUCCESS,
    payload: response.data,
  }
}

const getNotificationPreferencesActionError = response => {
  return {
    type: types.GET_NOTIFICATION_PREFERENCES_ERROR,
    payload: response,
  }
}

const getNotificationPreferencesActionPending = status => {
  return {
    type: types.GET_NOTIFICATION_PREFERENCES_PENDING,
    payload: status,
  }
}

export function postNotificationPreferences(notificationTypeId, deliveryTypeId, enabled) {
  return dispatch => {
    dispatch(postNotificationPreferencesActionPending(true))
    services.notification
      .postNotificationPreferences(notificationTypeId, deliveryTypeId, enabled)
      .then(response => {
          dispatch(postNotificationPreferencesActionSuccess(response))
          dispatch(postNotificationPreferencesActionPending(false))
      })
      .catch(error => {
        dispatch(postNotificationPreferencesActionError(error))
        dispatch(postNotificationPreferencesActionPending(false))
      })
  }
}

const postNotificationPreferencesActionSuccess = response => {
  return {
    type: types.POST_NOTIFICATION_PREFERENCES_SUCCESS,
    payload: response.data,
  }
}

const postNotificationPreferencesActionError = response => {
  return {
    type: types.POST_NOTIFICATION_PREFERENCES_ERROR,
    payload: response,
  }
}

const postNotificationPreferencesActionPending = status => {
  return {
    type: types.POST_NOTIFICATION_PREFERENCES_PENDING,
    payload: status,
  }
}