import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import moment from 'moment'

import { getBadges, getPeopleBadges } from '../../state/actions/badgesActions'
import { getPreferredLanguage } from '../../components/utils/localStorageUtils'
import { hasBadgesAccess } from '../../components/utils/featureAccessUtils'

import Preloader from '../../components/Preloader'
import BadgeItem from '../../components/Badges/BadgeItem'
import './BadgesContainer.scss'

class BadgesContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      badges: []
    }
  }

  componentDidMount() {
    const personId = this.props.match.params.person_id
    if (personId) { // badges for another person
      this.props.getPeopleBadges(personId)
    }
    else { // badges for authenticated user
      this.props.getBadges()
    }

  }

  componentDidUpdate(prevProps) {
    if (prevProps.badgesPending === true && this.props.badgesPending === false) {
      this.setState({ badges: this.props.badges })
    }

    if (prevProps.featureAccessPending === true && this.props.featureAccessPending === false) {
      if (!hasBadgesAccess(this.props.featureAccess)) {
        return <Redirect to="/profile" />
      }
    }
  }

  getTranslatedText(details) {
    return details.map((detail, index) => {
      if (detail.language.includes(getPreferredLanguage().toLowerCase())) { 
        return (
          <div key={index} className="">
            <h5>{detail.name}</h5>
          </div>
        )
      }
      return ''
    })
  }

  renderBadgeItems(badge) {
    const { badgeGroupModel, badgeModels, earnedBadgesCount, unearnedBadgesCount } = badge

    if (badgeGroupModel.isProgressive) {
      let badgeModel = {}

      const highestEarnedBadge = badgeModels.find(badgeModel =>
        badgeModel.userEarned
        && ((badgeModel.visibleStartDatetime === null && badgeModel.visibleEndDatetime === null)
          || moment(new Date()).isAfter(new Date(badgeModel.visibleStartDatetime))
          || moment(new Date()).isBefore(new Date(badgeModel.visibleEndDatetime)))
      )
      const highestUnearnedBadge = badgeModels.find(badgeModel =>
        !badgeModel.userEarned && badgeModel.showIfNotEarned
        && ((badgeModel.visibleStartDatetime === null && badgeModel.visibleEndDatetime === null)
          || moment(new Date()).isAfter(new Date(badgeModel.visibleStartDatetime))
          || moment(new Date()).isBefore(new Date(badgeModel.visibleEndDatetime)))
      )
      const additionalData = {
        earnedBadgesCount: 0,
        maxBadgesCount: badgeGroupModel.badgeCount,
        badgeModel: null
      }

      if (earnedBadgesCount === 0) { // no earned badge -> display highest unearned badge and no additional badge
        badgeModel = highestUnearnedBadge
        additionalData.badgeModel = null
      }

      if (unearnedBadgesCount === 0) { // all badges are earned -> display highest earned badge and no additional badge
        badgeModel = highestEarnedBadge
        additionalData.badgeModel = null
        additionalData.earnedBadgesCount = additionalData.maxBadgesCount
      }

      if (earnedBadgesCount && unearnedBadgesCount) { // display highest earned badge and highest unearned badge as additional badge
        badgeModel = highestEarnedBadge
        additionalData.badgeModel = highestUnearnedBadge || null
        additionalData.earnedBadgesCount = highestEarnedBadge.sortOrder
      }

      return (
        <BadgeItem key={badgeModel.id} showDetails={true} badgeModel={badgeModel} isProgressive={true} additionalData={additionalData} />
      )
    }

    return (
      <div>
        {
          badgeModels.map((badgeModel) => {
            if ((badgeModel.userEarned || (!badgeModel.userEarned && badgeModel.showIfNotEarned))
              && ((badgeModel.visibleStartDatetime === null && badgeModel.visibleEndDatetime === null)
                || moment(new Date()).isAfter(new Date(badgeModel.visibleStartDatetime))
                || moment(new Date()).isBefore(new Date(badgeModel.visibleEndDatetime)))
            ) {
              return (
                <BadgeItem key={badgeModel.id} showDetails={true} badgeModel={badgeModel} isProgressive={false} />
              )
            }
            return ''
          })
        }
      </div>
    )
  }

  renderBadgeGroups(badges) {
    return (
      <div className="badges-width">
        {badges.map((badge) => {
          if (!badge.showBadgeGroup) return ''
          return (
            <div className="mb-4" key={badge.badgeGroupModel.id}>
              {this.getTranslatedText(badge.badgeGroupModel.details)}
              {this.renderBadgeItems(badge)}
            </div >
          );
        })}
      </div>
    )
  }

  render() {
    const { badgesPending, featureAccessPending } = this.props;
    const { badges } = this.state;
    if (badgesPending || featureAccessPending) {
      return <Preloader />
    }

    return (
      <div className="badges-container" >
        <div className="d-flex align-items-center justify-content-between badges-width">
          <div className="pl-0 badges-header">Badges</div>
          <div className="d-flex align-items-center justify-content-end">
            <i
              onClick={this.props.history.goBack}
              className="material-icons add_box cursor-pointer other-avail-icon"
            >
              arrow_back
            </i>
          </div>
        </div>
        <div>
          {badges.length ? this.renderBadgeGroups(badges) : ''}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    badges: state.badgesReducer.badges,
    badgesPending: state.badgesReducer.badgesPending,
    badgesError: state.badgesReducer.badgesError,
    featureAccessPending: state.featureAccessReducer.featureAccessPending,
    featureAccess: state.featureAccessReducer.featureAccess,
    profileLocale: state.loginReducer.profileLocale,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getBadges: () => getBadges(),
      getPeopleBadges: (personId) => getPeopleBadges(personId),
    },
    dispatch
  )
}

BadgesContainer.propTypes = {
  badges: PropTypes.array,
  badgesPending: PropTypes.bool,
  badgesError: PropTypes.object,
  getBadges: PropTypes.func,
  history: PropTypes.object,
  featureAccess: PropTypes.object,
  featureAccessPending: PropTypes.bool,
  match: PropTypes.object,
  getPeopleBadges: PropTypes.func,
  profileLocale: PropTypes.string,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BadgesContainer)
