import { isDefinedAndNotEmpty } from './nullSafeCheckUtils'

export function createMapHref(address, city, state, zipCode) {
  if (!addressIsDefined(address, city, zipCode)) {
    return ''
  }

  const addressToEncode = `${address
    .split(',')
    .filter(Boolean)
    .join(', ')} ${city}, ${isDefinedAndNotEmpty(state) ? state : ''} ${zipCode}`
  if (addressToEncode) {
    const encodedAddress = encodeURIComponent(addressToEncode)
    return `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`
  }
  return ''
}

function addressIsDefined(address, city, zipCode) {
  return isDefinedAndNotEmpty(address)
    && isDefinedAndNotEmpty(city)
    && isDefinedAndNotEmpty(zipCode)
}