import React from 'react'
import PropTypes from 'prop-types'
import './ScrollDownButton.scss'

class ScrollDownButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showScrollDownButton: false,
    }

    this.onPageScroll = this.onPageScroll.bind(this)
    this.scrollDownClick = this.scrollDownClick.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onPageScroll)
    this.onDataLoaded()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dataLoaded === false && this.props.dataLoaded === true) {
      this.onDataLoaded()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onPageScroll)
  }

  onDataLoaded() {
    let { showScrollDownButton } = this.state
    if (document.body.scrollHeight > window.innerHeight) {
      showScrollDownButton = true
      this.setState({
        showScrollDownButton
      })
    }
  }

  onPageScroll() {
    if (this.props.preview) return;

    let { showScrollDownButton } = this.state
    if ((document.documentElement.scrollTop === 0 && showScrollDownButton === false)
      || (document.documentElement.scrollTop !== 0 && showScrollDownButton === true)) {
      showScrollDownButton = !showScrollDownButton
      this.setState({
        showScrollDownButton
      })
    }
  }

  scrollDownClick() {
    let { showScrollDownButton } = this.state
    showScrollDownButton = false
    window.scrollTo(0, document.documentElement.offsetHeight)
    this.setState({
      showScrollDownButton
    })
  }

  render() {
    const { preview } = this.props
    const { showScrollDownButton } = this.state

    if (preview || !showScrollDownButton) {
      return null
    }

    return (
      <div onClick={this.scrollDownClick}>
        <i className="material-icons scroll-down">arrow_drop_down_circle</i>
      </div>
    )
  }
}


ScrollDownButton.propTypes = {
  preview: PropTypes.bool,
  dataLoaded: PropTypes.bool,
}

export default ScrollDownButton
