import { 
    nullSafeCheckIsTrue, 
    isDefined 
} from './nullSafeCheckUtils'
import {
    getVersion5Dot1FeaturesActive
} from './utils'

export function hasChatAccess(featureAccess) {
    return isDefined(featureAccess) && nullSafeCheckIsTrue(featureAccess.eventChat)
}

export function hasMyScheduleAccess(featureAccess) {
    return isDefined(featureAccess) && nullSafeCheckIsTrue(featureAccess.mySchedule)
}

export function hasNewsAccess(featureAccess) {
    return isDefined(featureAccess) && nullSafeCheckIsTrue(featureAccess.newsFeed)
}

export function hasShiftsAccess(featureAccess) {
    return isDefined(featureAccess) && nullSafeCheckIsTrue(featureAccess.openShifts)
}

export function hasBadgesAccess(featureAccess) {
    return isDefined(featureAccess) && nullSafeCheckIsTrue(featureAccess.badges)
}

export function hasComplimentsAccess(featureAccess) {
    return isDefined(featureAccess) && nullSafeCheckIsTrue(featureAccess.compliments)
}

export function hasFeedbackAccess(featureAccess) {
    return isDefined(featureAccess) && nullSafeCheckIsTrue(featureAccess.feedback)
}

export function hasAvailabilityPreferencesAccess(featureAccess) {
    return isDefined(featureAccess) && nullSafeCheckIsTrue(featureAccess.availabilityPreferences)
}

export function hasSchedulingPreferencesAccess(featureAccess) {
    return isDefined(featureAccess) && nullSafeCheckIsTrue(featureAccess.schedulingPreferences)
}

export function hasRScheduleAccess(featureAccess) {
    return isDefined(featureAccess) && nullSafeCheckIsTrue(featureAccess.rSchedule)
}

export function hasTimePunchesAccess(featureAccess) {
    return isDefined(featureAccess) && nullSafeCheckIsTrue(featureAccess.timePunches)
}

export function hasAllowAvailabilityChangesAccess(featureAccess) {
    return isDefined(featureAccess) && nullSafeCheckIsTrue(featureAccess.allowAvailabilityChanges)
}

export function hasAllowLateChangeRequestsAccess(featureAccess) {
    return isDefined(featureAccess) && nullSafeCheckIsTrue(featureAccess.allowLateChangeRequests)
}

export function hasChangeRequests48(featureAccess){
    return isDefined(featureAccess) && nullSafeCheckIsTrue(featureAccess.changeRequests48)
}

export function hasAutomatedDaysOffAccess (featureAccess){
    return isDefined(featureAccess) && nullSafeCheckIsTrue(featureAccess.automatedDaysOff)
}

export function hasEstimatedDurationAccess(featureAccess) {
    return isDefined(featureAccess) && nullSafeCheckIsTrue(featureAccess.estimatedDurationEnabled)
}