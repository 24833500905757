import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { chain } from 'lodash'

import { t } from '../../utils/i18n'
import { getRank, getRankAbbreviation } from '../utils/utils'
import { formatPhoneNumber } from '../utils/phoneUtils'
import { obfuscate } from '../utils/obfuscateUtils'
import { getTeamMemberInfo } from '../../state/actions/teamMemberInfoActions'

import './TeamMember.scss'
import PostUserAvatar from '../PostUserAvatar'

class TeamMember extends Component {
  constructor(props) {
    super(props)

    this.renderEmployeeName = this.renderEmployeeName.bind(this)
  }

  renderEmployeeStatus(item) {
    const status = chain(this.props.requestDetailsList)
      .values()
      .flatten()
      .find(requestDetail => requestDetail.personId === item.personId)
      .value()

    const statusDescription = typeCode => {
      switch (typeCode) {
        case 'EVENT_CANNOT_ATTEND':
          return t('cannot_attend')
        case 'EVENT_ATTEND_LATE':
          return t('arriving_late')
        case 'EVENT_LEAVE_EARLY':
          return t('leaving_early')
        default:
          return ''
      }
    }

    if (status) {
      return <div className="status-description">{statusDescription(status.typeCode)}</div>
    }
    return ''
  }

  renderEmployeeName(item, isSupervisor) {
    if (isSupervisor) {
      return (
        <>
          <div className="cursor-pointer" onClick={() => this.props.history.push(`/public-profile/${obfuscate(item.personId)}`)}>
            {item.employeeName} {getRankAbbreviation(item.ranking)}
          </div>
          <a href={`tel:${formatPhoneNumber(item.phoneNumber)}`}>{formatPhoneNumber(item.phoneNumber)}</a>
          {item.status !== 'DENIED' && this.renderEmployeeStatus(item)}
        </>
      )
    }

    return (
      <>
        <div>{item.employeeName}</div>
        <div className="ranking">{getRank(item.ranking)}</div>
        {item.status !== 'DENIED' && this.renderEmployeeStatus(item)}
      </>
    )
  }

  render() {
    const { item, userAvatar, isSupervisor, status, index, history } = this.props

    return (
      <div key={index} className="team-member-info">
        <div className="row align-items-center">
          <PostUserAvatar
            personName={item.employeeName}
            personId={item.personId.toString()}
            userAvatarUrl={userAvatar !== undefined ? userAvatar.url : ''}
            redirectToAvatarPage={false}
            history={history}
          />
          <div className="col">{this.renderEmployeeName(item, isSupervisor)}</div>
          <div className="col-auto text-right">
            <span className={`capitalize ${item.driver ? '' : 'displayNone'}`}>{t('driver')}</span>
            <i className={`material-icons check-circle medium ${status !== 'confirmed' ? '' : 'active'}`}>
              check_circle
            </i>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    requestDetailsList: state.requestDetailsListReducer.requestDetailsList,
    teamMemberInfo: state.teamMemberInfoReducer.teamMemberInfoResponse,
    teamMemberInfoError: state.teamMemberInfoReducer.teamMemberInfoError,
    teamMemberInfoPending: state.teamMemberInfoReducer.teamMemberInfoPending,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getTeamMemberInfo: personId => getTeamMemberInfo(personId),
    },
    dispatch
  )
}

TeamMember.propTypes = {
  index: PropTypes.number,
  userAvatar: PropTypes.object,
  isSupervisor: PropTypes.bool,
  item: PropTypes.object,
  requestDetailsList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  status: PropTypes.string,
  history: PropTypes.object,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamMember)
