import axios from 'axios'
import apiConfig from '../../config/api-config'

export function fetchPersonDetails(personId) {
    const url = apiConfig.PERSON_DETAILS.replace('[personId]', personId)
    return axios.get(`${url}`)
}

export function fetchPeopleReceivedBadges(personId) {
    const url = apiConfig.PEOPLE_EARNED_BADGES.replace('[personId]', personId)
    return axios.get(`${url}`)
}

export function fetchPeopleReceivedCompliments(personId) {
    const url = apiConfig.GET_PEOPLE_RECEIVED_COMPLIMENTS.replace('[personId]', personId)
    return axios.get(`${url}`)
}



