import {
  GET_USER_REACTIONS_SUCCESS,
  GET_USER_REACTIONS_ERROR,
  GET_USER_REACTIONS_PENDING,
  POST_REACTION_SUCCESS,
  POST_REACTION_ERROR,
  POST_REACTION_PENDING,
  DELETE_REACTION_SUCCESS,
  DELETE_REACTION_ERROR,
  DELETE_REACTION_PENDING,
} from '../actions/actionTypes'

import { getLoggedInPersonId } from '../../components/utils/localStorageUtils'

export default function reactionsReducer(
  state = {
    userReactions: {},
    userReactionsError: {},
    userReactionsPending: {
      'chat': false,
      'posts': false,
      'comments': false,
      'replies': false,
    },
    postReactionError: '',
    postReactionPending: {},
    deleteReactionError: '',
    deleteReactionPending: {},
  },
  action
) {
  switch (action.type) {
    case GET_USER_REACTIONS_SUCCESS: {
      let _userReactions = state.userReactions
      const personId = getLoggedInPersonId()
      action.payload.map(item => {
        if (item.personId === personId) {
          _userReactions = {
            ..._userReactions,
            [item.postId]: {
              postId: item.postId,
              reactionId: item.reactionId,
              reactionType: item.reactionType,
            },
          }
        }
        return null
      })

      return {
        ...state,
        userReactions: _userReactions,
        userReactionsError: {},
      }
    }
    case GET_USER_REACTIONS_ERROR:
      return {
        ...state,
        userReactionsError: action.error,
      }
    case GET_USER_REACTIONS_PENDING: {
      const { category, status } = action
      let _userReactionsPending = state.userReactionsPending
      _userReactionsPending = {
        ..._userReactionsPending,
        [category]: status,
      }
      return {
        ...state,
        userReactionsPending: _userReactionsPending,
      }
    }

    case POST_REACTION_SUCCESS: {
      const { postId, payload } = action
      let _userReactions = state.userReactions
      _userReactions = {
        ..._userReactions,
        [postId]: payload
      }
      return {
        ...state,
        userReactions: _userReactions,
        postReactionError: '',
      }
    }
    case POST_REACTION_ERROR:
      return {
        ...state,
        postReactionError: action.error,
      }
    case POST_REACTION_PENDING: {
      const { postId, status } = action
      let _postReactionPending = state.postReactionPending
      _postReactionPending = {
        ..._postReactionPending,
        [postId]: status
      }
      return {
        ...state,
        postReactionPending: _postReactionPending,
      }
    }
    case DELETE_REACTION_SUCCESS: {
      const { postId } = action
      let _userReactions = state.userReactions
      _userReactions = {
        ..._userReactions,
        [postId]: {
          postId: null,
          reactionId: null,
          reactionType: ''
        }
      }
      return {
        ...state,
        userReactions: _userReactions,
        deleteReactionError: '',
      }
    }
    case DELETE_REACTION_ERROR:
      return {
        ...state,
        deleteReactionError: action.error,
      }
    case DELETE_REACTION_PENDING: {
      const { postId, status } = action
      let _deleteReactionPending = state.deleteReactionPending
      _deleteReactionPending = {
        ..._deleteReactionPending,
        [postId]: status
      }
      return {
        ...state,
        deleteReactionPending: _deleteReactionPending,
      }
    }
    default:
      return state
  }
}
