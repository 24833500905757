import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { cloneDeep } from 'lodash'

import { 
    getProfilePreferredLanguage, 
    setEditingCommunicationPreferrences, 
    updateProfilePreferredLanguage, 
    getProfilePreferredLanguageList 
} from '../../../state/actions/profileActions'
import {
    getNotificationHistoryTypes,
    getNotificationPreferences,
    postNotificationPreferences,
} from '../../../state/actions/notificationsActions'

import { 
    isDefinedAndNotEmpty, 
    nullSafeCheckIsFalse, 
    nullSafeCheckIsTrue, 
    isDefined 
} from '../../utils/nullSafeCheckUtils'
import { t } from '../../../utils/i18n'
import {
    is5Dot0NotificationType
} from '../../utils/notificationUtils'

import {
    EmailDeliveryType,
    PushDeliveryType,
} from '../../../utils/notificationDeliveryTypeConstants'
import {
    getVersion5Dot1FeaturesActive, 
} from '../../utils/utils'
import { 
    hasChatAccess, 
    hasMyScheduleAccess, 
    hasNewsAccess, 
    hasShiftsAccess, 
    hasBadgesAccess, 
    hasComplimentsAccess,
    hasRScheduleAccess,
    hasTimePunchesAccess
} from '../../utils/featureAccessUtils'

import Preloader from '../../Preloader'

import './CommunicationPreferences.scss'

class CommunicationPreferences extends Component {
    constructor(props) {
        super(props)
        this.state = {}

        this.editCommunicationPreferrencesOnClick = this.editCommunicationPreferrencesOnClick.bind(this)
        this.handlePreferredLanguageOnChange = this.handlePreferredLanguageOnChange.bind(this)
    }

    componentDidMount() {
        this.props.getProfilePreferredLanguageList()
        this.props.getProfilePreferredLanguage()
        this.props.getNotificationHistoryTypes()
        this.props.getNotificationPreferences()
    }

    componentDidUpdate(prevProps) {
        if (nullSafeCheckIsTrue(prevProps.putProfilePreferredLanguagePending) && nullSafeCheckIsFalse(this.props.putProfilePreferredLanguagePending)) {
            this.props.setEditingCommunicationPreferrences(false)
            this.props.getProfilePreferredLanguage()            
        }

        if (nullSafeCheckIsTrue(prevProps.postNotificationPreferencesPending) && nullSafeCheckIsFalse(this.props.postNotificationPreferencesPending)) {
            this.props.getNotificationPreferences()
        }
    }

    handlePreferredLanguageOnChange = (event) => {
        if (isDefinedAndNotEmpty(event.target.value)) {
            this.props.updateProfilePreferredLanguage(event.target.value)
        }        
    }

    editCommunicationPreferrencesOnClick = () => {
        this.props.setEditingCommunicationPreferrences(!this.props.editingCommunicationPreferences)
    }

    filterNotificationHistoryType = (deliveryType) => {
        let notificationHistoryTypes = []

        for (let i = 0; i < this.props.notificationHistoryTypes.length; i++) {
            if (this.props.notificationHistoryTypes[i].deliveryType === deliveryType) {
                notificationHistoryTypes.push(cloneDeep(this.props.notificationHistoryTypes[i]))
            }
        }

        return notificationHistoryTypes
    }

    getPushMarkUp = () => {
        return this.props.editingCommunicationPreferences ? this.getPushEditableMarkUp() : this.getPushReadOnlyMarkUp()
    }

    addSpaceOnUpperCase = (stringToAddSpace) => {
        let spaceOnUpperCaseString = ''

        for (let i = 0; i < stringToAddSpace.length; i++) {
            let nextCharToCheck = stringToAddSpace.charAt(i)
            if (i > 0 && isNaN(nextCharToCheck) && nextCharToCheck === nextCharToCheck.toUpperCase()) {
                spaceOnUpperCaseString += ' '
            }
            spaceOnUpperCaseString += nextCharToCheck
        }

        return spaceOnUpperCaseString
    }

    getNotificationTypeShouldBeVisible = (notificationType) => {
        return nullSafeCheckIsTrue(notificationType.available) 
                && ( (!getVersion5Dot1FeaturesActive() && is5Dot0NotificationType(notificationType.notificationType))
                    || getVersion5Dot1FeaturesActive())
    }

    getPushNotificationTypeReadOnlyMarkUp = (notificationType) => {
        if (this.getNotificationTypeShouldBeVisible(notificationType) &&
            this.notificationTypeIsSupported(notificationType.notificationType) && 
            this.notificationTypeIsForSupportedFeature(notificationType.notificationType, this.props.featureAccess)) {
            return (
                <div key={notificationType.notificationTypeId + ':' + notificationType.deliveryTypeId} className='row'>
                    <div className='col'>{this.addSpaceOnUpperCase(notificationType.notificationType)}</div>
                    <div className='col'>{this.getReadOnlyNotificationEnabledMarkUp(PushDeliveryType, notificationType.notificationTypeId)}</div>
                </div>
            )
        }
    }

    getPushNotificationTypeEditableMarkUp = (notificationType) => {
        if (this.getNotificationTypeShouldBeVisible(notificationType) &&
            this.notificationTypeIsSupported(notificationType.notificationType) &&
            this.notificationTypeIsForSupportedFeature(notificationType.notificationType, this.props.featureAccess)) {
            return (
                <div key={notificationType.notificationTypeId + ':' + notificationType.deliveryTypeId} className='row'>
                    <div className='col'>{this.addSpaceOnUpperCase(notificationType.notificationType)}</div>
                    <div className='col'>{this.getEditableNotificationEnabledMarkUp(notificationType.deliveryTypeId, notificationType.notificationTypeId)}</div>
                </div>
            )
        }
    }

    getEmailNotificationTypeReadOnlyMarkUp = (notificationType) => {
        if (this.getNotificationTypeShouldBeVisible(notificationType) &&
            this.notificationTypeIsSupported(notificationType.notificationType) &&
            this.notificationTypeIsForSupportedFeature(notificationType.notificationType, this.props.featureAccess)) {
            return (
                <div key={notificationType.notificationTypeId + ':' + notificationType.deliveryTypeId} className='row'>
                    <div className='col'>{this.addSpaceOnUpperCase(notificationType.notificationType)}</div>
                    <div className='col'>{this.getReadOnlyNotificationEnabledMarkUp(EmailDeliveryType, notificationType.notificationTypeId)}</div>
                </div>
            )
        }
    }

    getEditableNotificationEnabledMarkUp = (deliveryTypeId, notificationTypeId) => {
        let enabled = false
        let disabled = false

        for(let i = 0; i < this.props.notificationPreferences.length; i++) {
            if (this.props.notificationPreferences[i].notificationTypeId === notificationTypeId 
                && this.props.notificationPreferences[i].deliveryTypeId === deliveryTypeId) {
                if (nullSafeCheckIsTrue(this.props.notificationPreferences[i].isRequired)) {
                    return (t('cannot_be_disabled'))
                } else if (nullSafeCheckIsTrue(this.props.notificationPreferences[i].enabled)) {
                    enabled = true
                    break
                } else if (nullSafeCheckIsFalse(this.props.notificationPreferences[i].enabled)) {
                    disabled = true
                    break
                }
            }
        }

        return (
            <div className='row'>
                <div className='col'>
                    <input
                        name={`notify-${deliveryTypeId}-${notificationTypeId}`}
                        type="radio"
                        id={`notify-${deliveryTypeId}-${notificationTypeId}`}
                        checked={enabled}
                        onChange={() => this.handleNotifyDeliveryEnabledClick(deliveryTypeId, notificationTypeId, true)}
                    /> {t('enabled')}
                </div>
                <div className='col'>
                    <input
                        name={`notify-${deliveryTypeId}-${notificationTypeId}`}
                        type="radio"
                        id={`notify-${deliveryTypeId}-${notificationTypeId}`}
                        checked={disabled}
                        onChange={() => this.handleNotifyDeliveryEnabledClick(deliveryTypeId, notificationTypeId, false)}
                    /> {t('not_enabled')}
                </div>
            </div>
        );
    }

    getReadOnlyNotificationEnabledMarkUp = (deliveryType, notificationTypeId) => {
        let markUpText = t('default_setting')

        for(let i = 0; i < this.props.notificationPreferences.length; i++) {
            if (this.props.notificationPreferences[i].notificationTypeId === notificationTypeId 
                && this.props.notificationPreferences[i].deliveryType === deliveryType) {
                if (nullSafeCheckIsTrue(this.props.notificationPreferences[i].isRequired)) {
                    markUpText = t('cannot_be_disabled')
                    break
                } else if (nullSafeCheckIsTrue(this.props.notificationPreferences[i].enabled)) {
                    markUpText = t('enabled')
                } else if (nullSafeCheckIsFalse(this.props.notificationPreferences[i].enabled)) {
                    markUpText = t('not_enabled')
                }
            }
        }

        return markUpText;
    }

    handleNotifyDeliveryEnabledClick = (deliveryTypeId, notificationTypeId, enabled) => {
        this.props.postNotificationPreferences(notificationTypeId, deliveryTypeId, enabled)
    }

    notificationTypeIsForSupportedFeature = (notificationType, featureAccess) => {
        switch (notificationType) {
            case "NewBadgeEarned":
            case "NewBadgeUnearned":
                return hasBadgesAccess(featureAccess);
            
            case "NewComplimentReceived":
                return hasComplimentsAccess(featureAccess);
            
            case "ShiftExpiring":
                return hasShiftsAccess(featureAccess);
        }

        return true;
    }

    notificationTypeIsSupported = (notificationType) => {
        switch (notificationType){
            case "ShiftExpiring":
            case "NewBadgeEarned":
            case "NewBadgeUnearned":
            case "NewComplimentReceived":
            case "PerformanceTracker":
              return true;

            default:
              return false;
        }
    }

    getEmailNotificationTypeEditableMarkUp = (notificationType) => {        
        if (
            this.getNotificationTypeShouldBeVisible(notificationType) &&
            this.notificationTypeIsSupported(notificationType.notificationType) &&
            this.notificationTypeIsForSupportedFeature(notificationType.notificationType, this.props.featureAccess)) {
            return (
                <div key={notificationType.notificationTypeId + ':' + notificationType.deliveryTypeId} className='row'>
                    <div className='col'>{this.addSpaceOnUpperCase(notificationType.notificationType)}</div>
                    <div className='col'>{this.getEditableNotificationEnabledMarkUp(notificationType.deliveryTypeId, notificationType.notificationTypeId)}</div>
                </div>
            )
        }
    }

    getPushEditableMarkUp = () => {
        return (
            <div className='row'>
                <div className='col'>
                    <div className='general-title uppercase'>{t('push_notifications')}</div>
                    {this.filterNotificationHistoryType(PushDeliveryType).map(this.getPushNotificationTypeEditableMarkUp)}
                </div>
            </div>  
        )
    }

    getPushReadOnlyMarkUp = () => {
        return (
            <div className='row'>
                <div className='col'>
                    <div className='general-title uppercase'>{t('push_notifications')}</div>
                    {this.filterNotificationHistoryType(PushDeliveryType).map(this.getPushNotificationTypeReadOnlyMarkUp)}
                </div>
            </div>
        )
    }

    getEmailMarkUp = () => {
        return this.props.editingCommunicationPreferences ? this.getEmailEditableMarkUp() : this.getEmailReadOnlyMarkUp()
    }

    getEmailEditableMarkUp = () => {
        return (
            <div className='row'>
                <div className='col'>
                    <div className='general-title uppercase'>{t('email_notifications')}</div>
                    {this.filterNotificationHistoryType(EmailDeliveryType).map(this.getEmailNotificationTypeEditableMarkUp)}
                </div>
            </div>  
        )
    }

    getEmailReadOnlyMarkUp = () => {
        return (
            <div className='row'>
                <div className='col'>
                    <div className='general-title uppercase'>{t('email_notifications')}</div>
                    {this.filterNotificationHistoryType(EmailDeliveryType).map(this.getEmailNotificationTypeReadOnlyMarkUp)}
                </div>
            </div>  
        )
    }

    getLanguageMarkUp = () => {
        return this.props.editingCommunicationPreferences ? this.getLanguageEditableMarkUp() : this.getLanguageReadOnlyMarkUp()
    }

    getLanguageReadOnlyMarkUp = () => {
        return (
            <div>
                <div className='row'>
                    <div className='col'>
                        <div className='general-title uppercase'>{t('communication_preferences')} | <span onClick={() => this.editCommunicationPreferrencesOnClick() } className='communication-preferences-link cursor-pointer'>{t('edit')}</span></div>
                    </div>
                </div>   
                <div className='row'>
                    <div className='col'>
                        {t('preferred_language_emails')} 
                    </div>
                </div>
                <div className='row'>
                    <div className='col bold-text'>
                        { this.getLanguageOptionLabel(this.props.profilePreferredLanguage) }
                    </div>
                </div>
            </div>
        )
    }

    getLanguageEditableMarkUp = () => {
        return (
            <div>
                <div className='row'>
                    <div className='col'>
                        <div className='general-title uppercase'>{t('communication_preferences')} | <span onClick={() => this.editCommunicationPreferrencesOnClick() } className='communication-preferences-link cursor-pointer'>{t('done')}</span></div>
                    </div>
                </div>   
                <div className='row'>
                    <div className='col'>
                        {t('preferred_language_emails')} 
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <select onChange={(event) => this.handlePreferredLanguageOnChange(event)} value={this.props.profilePreferredLanguage}>
                            {this.getPreferredLanguageOptionsMarkUp()}
                        </select>
                    </div>
                </div>
            </div>
        )
    }

    getPreferredLanguageOptionsMarkUp = () => {
        let languageList = ['']
        if (isDefined(this.props.profilePreferredLanguageList) && this.props.profilePreferredLanguageList.length > 0) {
            languageList = languageList.concat(cloneDeep(this.props.profilePreferredLanguageList))
        }        

        return languageList.map(this.getPreferredLanguageOptionMarkUp)
    }
    
    getPreferredLanguageOptionMarkUp = (item) => {
        return (
            <option key={item} value={item}>{this.getLanguageOptionLabel(item)}</option>
        )
    }

    getLanguageOptionLabel = (languageRegionCode) => {
        if (!isDefined(languageRegionCode)) {
            return ''
        }

        let label = ''

        switch (languageRegionCode.toLowerCase()) {
            case 'en-us':
                label = t('en_us')
                break;
            case 'es-us':
                label = t('es_us')
                break;
            case 'fr-ca':
                label = t('fr_ca')
                break;
            case 'fr-fr':
                label = t('fr_fr')
                break;
        }

        return label
    }

    render() {
        const { 
            profilePreferredLanguageListPending, 
            profilePreferredLanguagePending, 
            putProfilePreferredLanguagePending,
            notificationHistoryTypesPending, 
            postNotificationPreferencesPending,
            notificationPreferencesPending,
        } = this.props

        if (profilePreferredLanguageListPending 
            || profilePreferredLanguagePending 
            || putProfilePreferredLanguagePending
            || notificationHistoryTypesPending
            || postNotificationPreferencesPending
            || notificationPreferencesPending) {
            return <Preloader />
        }

        return (
            <div className='communication-preferences-component'>
                {this.getLanguageMarkUp()}
                <hr />
                {this.getPushMarkUp()}
                <hr />
                {this.getEmailMarkUp()}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        profilePreferredLanguageList: state.profileReducer.profilePreferredLanguageList,
        profilePreferredLanguageListError: state.profileReducer.profilePreferredLanguageListError,
        profilePreferredLanguageListPending: state.profileReducer.profilePreferredLanguageListPending, 
        profilePreferredLanguage: state.profileReducer.profilePreferredLanguage,
        profilePreferredLanguageError: state.profileReducer.profilePreferredLanguageError,
        profilePreferredLanguagePending: state.profileReducer.profilePreferredLanguagePending, 
        putProfilePreferredLanguagePending: state.profileReducer.putProfilePreferredLanguagePending,       
        putProfilePreferredLanguageError: state.profileReducer.putProfilePreferredLanguageError,
        editingCommunicationPreferences: state.profileReducer.editingCommunicationPreferences,
        profileLocale: state.loginReducer.profileLocale,
        notificationHistoryTypes: state.notificationsReducer.notificationHistoryTypes,
        notificationHistoryTypesPending: state.notificationsReducer.notificationHistoryTypesPending,
        notificationHistoryTypesError: state.notificationsReducer.notificationHistoryTypesError,
        notificationPreferences: state.notificationsReducer.notificationPreferences,
        notificationPreferencesPending: state.notificationsReducer.notificationPreferencesPending,
        notificationPreferencesError: state.notificationsReducer.notificationPreferencesError,
        postNotificationPreferencesPending: state.notificationsReducer.postNotificationPreferencesPending,
        postNotificationPreferencesError: state.notificationsReducer.postNotificationPreferencesError,
        featureAccess: state.featureAccessReducer.featureAccess,
        featureAccessPending: state.featureAccessReducer.featureAccessPending
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getProfilePreferredLanguageList: () => getProfilePreferredLanguageList(),
            getProfilePreferredLanguage: () => getProfilePreferredLanguage(),
            setEditingCommunicationPreferrences: (editing) => setEditingCommunicationPreferrences(editing),
            updateProfilePreferredLanguage: (preferredLanguage) => updateProfilePreferredLanguage(preferredLanguage),
            getNotificationHistoryTypes: () => getNotificationHistoryTypes(),
            getNotificationPreferences: () => getNotificationPreferences(),
            postNotificationPreferences: (notificationTypeId, deliveryTypeId, enabled) => postNotificationPreferences(notificationTypeId, deliveryTypeId, enabled),
        },
        dispatch
    )
}

CommunicationPreferences.propTypes = {
    getProfilePreferredLanguageList: PropTypes.func,
    updateProfilePreferredLanguage: PropTypes.func,
    setEditingCommunicationPreferrences: PropTypes.func,
    getProfilePreferredLanguage: PropTypes.func,
    profilePreferredLanguageList: PropTypes.array,
    profilePreferredLanguageListError: PropTypes.object,
    profilePreferredLanguageListPending: PropTypes.bool,
    profilePreferredLanguage: PropTypes.string,
    profilePreferredLanguageError: PropTypes.object,
    profilePreferredLanguagePending: PropTypes.bool,
    putProfilePreferredLanguagePending: PropTypes.bool,
    putProfilePreferredLanguageError: PropTypes.object,
    editingCommunicationPreferences: PropTypes.bool,
    profileLocale: PropTypes.string,

    notificationHistoryTypes: PropTypes.array,
    notificationHistoryTypesPending: PropTypes.bool,
    notificationHistoryTypesError: PropTypes.object,
    getNotificationHistoryTypes: PropTypes.func,

    notificationPreferences: PropTypes.array,
    notificationPreferencesPending: PropTypes.bool,
    notificationPreferencesError: PropTypes.object,
    getNotificationPreferences: PropTypes.func,

    postNotificationPreferencesPending: PropTypes.bool,
    postNotificationPreferencesError: PropTypes.object,
    postNotificationPreferences: PropTypes.func,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommunicationPreferences)

