import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'

// Actions
import { setScheduleDates, getSchedule, sendRequestEventChange } from '../../state/actions/scheduleActions'
import { getScheduleChange } from '../../state/actions/scheduleChangeActions'
import { doRequestChange } from '../../state/actions/requestAvailabilityChangeActions'
import { confirmEvent } from '../../state/actions/scheduleActions'
import { getFeatureAccessList } from '../../state/actions/featureAccessActions'

// Common
import Datepicker from '../../components/DatePicker'
import Preloader from '../../components/Preloader'
import AppDownloadModal from '../../components/AppDownloadModal';
import { getVersion4Dot7Dot2FeaturesActive } from '../../components/utils/utils'

import { formatDate, formatDateNotForDisplay, getMonthAndDay, getDayOfWeek, getYear, isToday, dateIsInThePast } from '../../components/utils/datetimeUtils'

import { t } from '../../utils/i18n'

import AvailableItem from '../../components/Schedule/AvailableItem'
import RequestEventChange from '../../components/Schedule/RequestEventChange'
import ScheduledItem from '../../components/Schedule/ScheduledItem'
import './ScheduleContainer.scss'
import { isDefinedAndNotEmpty, isDefinedAndHasOwnProperties, nullSafeCheckIsTrue, isDefined } from '../../components/utils/nullSafeCheckUtils'
import ActionModal from '../../components/ActionModal'
import { hasMyScheduleAccess } from '../../components/utils/featureAccessUtils'

class ScheduleContainer extends Component {
  constructor(props) {
    super(props)

    this.currentRenderDate = ''
    this.state = {
      showEditStatus: false,
      currentRenderDate: '',
      editStatusDate: '',
      showConfirmWeek: false,
      showRequestModal: false,
      requestChangeId: '',
      hourOfEvent: '',
      scheduledEventIdDetails: '',
      modal: {
        showModal: false,
        modalTitle: '',
        modalText: '',
        showCancelAction: false,
        modalAcceptButtonText: '',
        modalCancelButtonText: '',
        modalAcceptAction: null,
        modalCancelAction: null,
      },
      nrOfEvents: {
        all: 0,
        available: 0,
        confirmed: 0,
      },
      confirmAllClicked: false,
    }

    this.renderScheduleItems = this.renderScheduleItems.bind(this)
    this.renderScheduleItem = this.renderScheduleItem.bind(this)
    this.handleAvailabilityChange = this.handleAvailabilityChange.bind(this)
    this.handleRenderAfterEdit = this.handleRenderAfterEdit.bind(this)
    this.submitRequestEventChange = this.submitRequestEventChange.bind(this)
    this.renderDate = this.renderDate.bind(this)

    // Modal Request Change
    this.handleModalOpenRequestChange = this.handleModalOpenRequestChange.bind(this)
    this.modalRequestChangeClose = this.modalRequestChangeClose.bind(this)

    // Modal Edit Status
    this.modalCloseEditStatus = this.modalCloseEditStatus.bind(this)

    this.returnDates = this.returnDates.bind(this)
    this.handleSchedule = this.handleSchedule.bind(this)
    this.handleConfirmAllEvents = this.handleConfirmAllEvents.bind(this)
    this.confirmAllEventsAction = this.confirmAllEventsAction.bind(this)
    this.cancelModalAction = this.cancelModalAction.bind(this)
  }

  componentDidMount() {
    this.checkGetFeatureAccess()

    this.getEventsConfirmationStatus()
  }

  componentDidUpdate(prevProps) {
    this.checkConfirmEventsOnUpdate(prevProps)
  }

  checkGetFeatureAccess = () => {
    if (this.props.featureAccessPending !== true && this.props.featureAccess !== undefined && this.props.featureAccess !== null) {
      this.props.getFeatureAccessList()
    }
  }

  checkConfirmEventsOnUpdate = (prevProps) => {
    if (getVersion4Dot7Dot2FeaturesActive()) {
      if (prevProps.confirmEventPending === true && this.props.confirmEventPending === false) {
        const { nrOfEvents, confirmAllClicked } = this.state
        if (confirmAllClicked) {
          this.setState({
            modal: {
              showModal: true,
              modalTitle: t('events_confirmed'),
              modalText: t('all_events_confirmed'),
              showCancelAction: false,
              modalAcceptButtonText: t('ok'),
              modalAcceptAction: this.cancelModalAction,
            },
            nrOfEvents: {
              all: nrOfEvents.all,
              available: 0,
              confirmed: nrOfEvents.confirmed + nrOfEvents.available,
            },
            confirmAllClicked: false,
          })
        } else {
          this.setState({
            nrOfEvents: {
              all: nrOfEvents.all,
              available: nrOfEvents.available - 1,
              confirmed: nrOfEvents.confirmed + 1,
            },
          })
        }
      }

      if (prevProps.scheduleItemsStatus === true && this.props.scheduleItemsStatus === false) {
        this.getEventsConfirmationStatus()
      }
    }
  }

  handleSchedule() {
    if (!this.props.scheduleItemsStatus) {
      this.props.getSchedule(this.props.scheduleStartDate, this.props.scheduleEndDate)
    }
  }

  handleModalOpenRequestChange(currentEventId, hourOfEvent) {
    const { showRequestModal } = this.state
    this.setState({
      showRequestModal: showRequestModal === false ? true : false,
      requestChangeId: currentEventId,
      hourOfEvent: hourOfEvent,
    })
  }

  modalRequestChangeClose() {
    this.setState({
      showRequestModal: false,
    })
  }

  modalRequestChangeSubmit() {
    this.setState({
      showRequestModal: false,
    })

    this.handleSchedule()
  }

  isNextWeekAfterPublishedWeekEndDate = (publishedWeek, startDate) => {
    if (!isDefinedAndNotEmpty(publishedWeek.endDate)) {
      return true
    }

    let publishedEndDate = String(publishedWeek.endDate.split('T')[0])
    return !moment(moment(startDate).add(1, 'week')).isBefore(moment(publishedEndDate))
  }

  getNextWeekConsideringEndOfPublishedWeek = (currentDate, nextWeek) => {
    let lastWeek = this.isNextWeekAfterPublishedWeekEndDate(this.props.publishedWeek, currentDate)
    let hasLastWeekLastDayScheduleData = false
    if (lastWeek) {
      let monthScheduleItemScheduleData = this.props.monthScheduleItems[this.props.monthScheduleItems.length - 1].scheduleData
      hasLastWeekLastDayScheduleData = monthScheduleItemScheduleData !== undefined && monthScheduleItemScheduleData !== null
    }
    return lastWeek && hasLastWeekLastDayScheduleData ? formatDateNotForDisplay(moment(nextWeek).add(1, 'days')) : formatDateNotForDisplay(nextWeek)
  }

  returnDates(selectedDates, datesObjectForApi) {
    if (this.props.scheduleStartDate !== datesObjectForApi.currentDate ||
      this.props.scheduleEndDate !== datesObjectForApi.nextWeek) {
      let nextWeek = this.getNextWeekConsideringEndOfPublishedWeek(datesObjectForApi.currentDate, datesObjectForApi.nextWeek)

      // Update State for dates
      this.props.setScheduleDates(datesObjectForApi.currentDate, nextWeek)

      // Set local states
      this.setState({
        selectedDates: `${selectedDates} - ${nextWeek}`,
        currentDate: datesObjectForApi.currentDate,
        nextWeek: nextWeek,
        year: getYear(datesObjectForApi.currentDate),
      })
    }
  }

  renderDate(date) {
    return (
      <div className="row">
        <div className={`col schedule-item-date ${isToday(date) ? 'bold' : ''}`}>
          <div>{`${getDayOfWeek(date)} | ${getMonthAndDay(date)}`}</div>
        </div>
      </div>
    )
  }

  renderScheduleItems(eventsByDate, index) {
    // Exit if empty
    if (!eventsByDate || eventsByDate.length === 0) {
      return ''
    }
    const firstEvent = eventsByDate[0]

    return (
      <div key={index}>
        {this.renderDate(firstEvent.date)}
        {eventsByDate.map(this.renderScheduleItem)}
      </div>
    )
  }

  getEventChatInfo = (scheduleItem) => {
    return this.props.scheduleItems.find(item => item.scheduledStoreId === scheduleItem.scheduledStoreId) || null
  }

  renderScheduleItem(item, index) {
    const fridayNextThreeWeeksAvailable = moment()
      .subtract(moment().day() > 5 ? 0 : moment().day() + 1, 'day')
      .add(27, 'days')
    const propsDay = moment(new Date(item.date))
    let previousDateFlag = false
    const currentWeekStart = moment().add(-1, 'days')
    const selectedDateIsBeforeCurrentWeek = moment(new Date(item.date)).isBefore(currentWeekStart)
    if (this.currentRenderDate === '') {
      this.currentRenderDate = item.date.split(' ')[0]
    } else {
      if (this.currentRenderDate === item.date.split(' ')[0]) {
        previousDateFlag = true
      } else {
        this.currentRenderDate = item.date.split(' ')[0]
      }
    }

    let dataToRender = {
      item: item,
      index: index,
      previousDateFlag: previousDateFlag,
      showEditStatus: fridayNextThreeWeeksAvailable < propsDay ? true : false,
      chatInfo: this.getEventChatInfo(item)
    }

    if (item.status === 'scheduled') {
      return (
        <div key={index}>
          <ScheduledItem
            selectedDateIsBeforeCurrentWeek={selectedDateIsBeforeCurrentWeek}
            dataToRender={dataToRender}
            handleModalOpenRequestChange={this.handleModalOpenRequestChange}
            history={this.props.history}
            showConfirmButton={!this.hasConfirmAllEventsPermission()}
          />
        </div>
      )
    } else if (item.status === 'available') {
      return (
        <div key={index}>
          <AvailableItem dataToRender={dataToRender} handleAvailabilityChange={this.handleAvailabilityChange} />
        </div>
      )
    } else {
      // Fallback, should still display
      return (
        <div key={index}>
          <AvailableItem dataToRender={dataToRender} handleAvailabilityChange={this.handleAvailabilityChange} />
        </div>
      )
    }
  }

  handleAvailabilityChange(date) {
    const { showRequestModal } = this.state
    this.setState({
      showRequestModal: showRequestModal === false ? true : false,
      editStatusDate: date,
    })
  }

  handleRenderAfterEdit(currentDate, nextWeek) {
    setTimeout(() => {
      getSchedule(currentDate, nextWeek)
    }, 1000)
  }

  submitRequestEventChange(reasonCode, eventCode, notes, scheduledEventId) {
    this.props.sendRequestEventChange(reasonCode, eventCode, notes, scheduledEventId)

    this.modalRequestChangeSubmit()
  }

  // Edit status modal submit. Has logic here so that the modal can be closed on submit.
  modalCloseEditStatus(dataToSend) {
    if (dataToSend === '') {
      this.setState({
        showEditStatus: false,
      })
    } else {
      this.setState({
        showEditStatus: false,
      })
      dataToSend.organizationId = this.props.organizationId.trim().replace(/\D+/g, '')
      this.props.doRequestChange(dataToSend)
    }
  }

  getEventsConfirmationStatus() {
    if (this.props.scheduleItemsGroupedByDate === undefined && this.props.scheduleItemsGroupedByDate === null) { return; }

    let nrOfEvents = {
      all: 0, available: 0, confirmed: 0,
    }
    this.props.scheduleItemsGroupedByDate.forEach((scheduleDay) => {
      scheduleDay.forEach((event) => {
        if (event.scheduledEventId !== null) {
          if(!dateIsInThePast(event.date)) {
            nrOfEvents.all += 1

            if (event.confirmed === 'confirmed') {
              nrOfEvents.confirmed += 1
            } else {
              nrOfEvents.available += 1
            }
          }
        }
      })
    })
    this.setState({ nrOfEvents })
  }

  cancelModalAction() {
    this.setState({
      modal: {
        showModal: false,
        modalTitle: '',
        modalText: '',
        showCancelAction: false,
        modalAcceptButtonText: '',
        modalCancelButtonText: '',
        modalAcceptAction: null,
        modalCancelAction: null,
      }
    })
  }

  handleConfirmAllEvents(scheduleDays) {
    const { nrOfEvents } = this.state
    if (nrOfEvents.available !== 0) {
      this.setState({
        modal: {
          showModal: true,
          modalTitle: t('confirm_all'),
          modalText: t('confirm_all_events_text'),
          showCancelAction: true,
          modalAcceptButtonText: t('ok'),
          modalCancelButtonText: t('cancel'),
          modalAcceptAction: () => this.confirmAllEventsAction(scheduleDays),
          modalCancelAction: this.cancelModalAction,
        }
      })
    } else {
      this.setState({
        modal: {
          showModal: true,
          modalTitle: '',
          modalText: t('all_events_already_confirmed'),
          showCancelAction: false,
          modalAcceptButtonText: t('ok'),
          modalAcceptAction: this.cancelModalAction,
        }
      })
    }
  }

  renderConfirmAllSchedule = () => {
    if (!getVersion4Dot7Dot2FeaturesActive()) { return '' }

    const { nrOfEvents } = this.state
    const { scheduleItemsGroupedByDate } = this.props

    return (
      <div className="col confirm-all-events">
        <span className="mr-3">{t('confirmed_events')}: {nrOfEvents.confirmed + '/' + nrOfEvents.all}</span>
        <input
          type="button"
          className={`btn-confirm ${nrOfEvents.available === 0 ? 'd-none' : ''}`}
          onClick={() => this.handleConfirmAllEvents(scheduleItemsGroupedByDate)}
          value={this.getConfirmAllButtonText()}
        />
      </div>
    )
  }

  getConfirmAllButtonText = () => {
    if(this.hasConfirmAllEventsPermission()) {
      return this.state.nrOfEvents.confirmed === 0 ? t('confirm_week_caps') : t('reconfirm_week')
    } else {
      return t('confirm_week_caps')
    }
  }

  hasConfirmAllEventsPermission = () => {
    return isDefined(this.props.featureAccess) && nullSafeCheckIsTrue(this.props.featureAccess.confirmByWeek) 
  }

  confirmAllEventsAction(scheduleDays) {
    this.setState({ confirmAllClicked: true })

    let unconfirmedEventIds = []
    scheduleDays.forEach((scheduleDay) => {
      scheduleDay.forEach((event) => {
        if (event.scheduledEventId !== null && event.confirmed !== 'confirmed' && !dateIsInThePast(event.date)) {
          unconfirmedEventIds.push(event.scheduledEventId)
        }
      })
    })

    this.props.confirmEvent(unconfirmedEventIds)
  }

  render() {
    const {
      showEditStatus, requestChangeId, showRequestModal, modal,
    } = this.state
    const {
      scheduleItems,
      scheduleItemsGroupedByDate,
      scheduleItemsStatus,
      showNoDataErrorMessage,
      requestEventChangeStatus,
      scheduleStartDate,
      scheduleEndDate,
      personDetailsPending,
      publishedWeekPending,
      publishedWeek,
      featureAccessPending,
      eventChatNotificationsPending,
      monthSchedulePending,
      weekHasNotifications,
      confirmEventPending,
    } = this.props

    if (scheduleItemsStatus
      || (personDetailsPending && !this.props.profilePending)
      || publishedWeekPending
      || featureAccessPending
      || eventChatNotificationsPending
      || monthSchedulePending
      || confirmEventPending) {
      return <Preloader />
    }

    if (isDefinedAndHasOwnProperties(this.props.featureAccess) && !hasMyScheduleAccess(this.props.featureAccess)) {
      return <Redirect to="/profile" />
    }

    return (
      <div>
        <Datepicker
          returnDates={this.returnDates}
          scheduleItems={scheduleItems}
          scheduleStartDate={scheduleStartDate}
          scheduleEndDate={scheduleEndDate}
          publishedWeek={publishedWeek}
          title={t('schedule')}
        />
        <div className="schedule-content">
          <div className={showEditStatus === true ? 'no-overflow-modal' : ''}>
            <div className="row">
              {this.renderConfirmAllSchedule()}
              <div className="col schedule-date-interval">
                {weekHasNotifications && <i className="material-icons fiber_manual_record mobile small">fiber_manual_record</i>}
                {getMonthAndDay(scheduleStartDate)} - {formatDate(scheduleEndDate, 'DD')}
                &nbsp;{getYear(scheduleStartDate)}&nbsp;
                {scheduleItems.length > 0 ? <span>{`${scheduleItems[scheduleItems.length - 1].quarter} W${scheduleItems[scheduleItems.length - 1].week}`}</span> : ''}
              </div>
            </div>
            <div className="row">
              <div className="col col-md-6 col-lg-6 col-xl-6">
                {scheduleItemsGroupedByDate.length > 0 ? (
                  scheduleItemsGroupedByDate.map(this.renderScheduleItems)
                ) : (
                    <div className={`text-center ${Object.keys(showNoDataErrorMessage).length ? '' : 'displayNone'}`}>
                      {t('no_schedule_data')}{' '}
                    </div>
                  )}
              </div>
            </div>
            <RequestEventChange
              requestChangeMade={requestEventChangeStatus}
              hourOfEvent={this.state.hourOfEvent}
              scheduledEventId={requestChangeId}
              showRequestModal={showRequestModal}
              modalRequestChangeClose={this.modalRequestChangeClose}
              submitRequestEventChange={this.submitRequestEventChange}
              featureAccess={this.props.featureAccess}
              history={this.props.history}
            />
          </div>
        </div>
        <AppDownloadModal
          deviceIsIOS={this.deviceIsIOS}
          deviceIsAndroid={this.deviceIsAndroid}
        />
        <ActionModal
          {...modal}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    scheduleStartDate: state.scheduleReducer.scheduleStartDate,
    scheduleEndDate: state.scheduleReducer.scheduleEndDate,
    scheduleItems:
      state.scheduleReducer.scheduleItems !== undefined && state.scheduleReducer.scheduleItems !== ''
        ? state.scheduleReducer.scheduleItems
        : [],
    scheduleItemsGroupedByDate:
      state.scheduleReducer.scheduleItemsGroupedByDate !== undefined &&
        state.scheduleReducer.scheduleItemsGroupedByDate !== ''
        ? state.scheduleReducer.scheduleItemsGroupedByDate
        : [],
    showNoDataErrorMessage: state.scheduleReducer.scheduleItemsError,
    scheduleItemsStatus: state.scheduleReducer.scheduleItemsStatus,
    unconfirmedEventIds: state.scheduleReducer.unconfirmedEventIds,
    showConfirmWeek: state.scheduleReducer.showConfirmWeek,
    requestChangeResponse: state.districtRequestManagerReducer.districtRequestResponse,
    requestChangeStatus: state.requestChangeReducer.requestChangeStatus,
    requestChangeSuccess: state.requestChangeReducer.requestChangeSuccess,
    requestEventChangeStatus: state.scheduleReducer.requestEventChangeStatus,
    organizationId: state.loginReducer.profile.organizationId,
    profilePending: state.loginReducer.profilePending,

    personDetailsPending: state.personDetailsReducer.personDetailsPending,
    personDetails: state.personDetailsReducer.personDetails,
    publishedWeek: state.personDetailsReducer.publishedWeek,
    publishedWeekPending: state.personDetailsReducer.publishedWeekPending,

    featureAccess: state.featureAccessReducer.featureAccess,
    featureAccessPending: state.featureAccessReducer.featureAccessPending,

    eventChatNotifications: state.scheduleReducer.eventChatNotifications,
    eventChatNotificationsPending: state.scheduleReducer.eventChatNotificationsPending,
    eventChatNotificationsError: state.scheduleReducer.eventChatNotificationsError,

    monthScheduleItems: state.scheduleReducer.monthScheduleItems,
    monthSchedulePending: state.scheduleReducer.monthSchedulePending,
    weekHasNotifications: state.scheduleReducer.weekHasNotifications,

    confirmEventPending: state.confirmEventReducer.confirmEventPending,

    profileLocale: state.loginReducer.profileLocale,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getSchedule: (currentDate, nextWeek) => getSchedule(currentDate, nextWeek),
      doRequestChange: changeData => doRequestChange(changeData),
      getScheduleChange: (eventId, personId) => getScheduleChange(eventId, personId),
      sendRequestEventChange: (reasonCode, eventCode, notes, scheduledEventId) =>
        sendRequestEventChange(reasonCode, eventCode, notes, scheduledEventId),
      setScheduleDates: (startDate, endDate) => setScheduleDates(startDate, endDate),
      confirmEvent: (unconfirmedEventIds) => confirmEvent(unconfirmedEventIds),
      getFeatureAccessList: () => getFeatureAccessList(),
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleContainer)

ScheduleContainer.propTypes = {
  scheduleStartDate: PropTypes.string,
  scheduleEndDate: PropTypes.string,
  scheduleItemsStatus: PropTypes.bool,
  profilePending: PropTypes.bool,

  scheduleItems: PropTypes.array,
  scheduleItemsGroupedByDate: PropTypes.array,
  showNoDataErrorMessage: PropTypes.object,
  requestEventChangeStatus: PropTypes.bool,

  getSchedule: PropTypes.func,
  sendRequestEventChange: PropTypes.func,
  setScheduleDates: PropTypes.func,
  confirmWeek: PropTypes.func,
  showConfirmWeek: PropTypes.bool,
  unconfirmedEventIds: PropTypes.array,

  getPersonDetails: PropTypes.func,
  personDetailsPending: PropTypes.bool,
  personDetails: PropTypes.object,
  getPublishedWeek: PropTypes.func,
  publishedWeekPending: PropTypes.bool,
  publishedWeek: PropTypes.object,

  featureAccess: PropTypes.object,
  featureAccessPending: PropTypes.bool,
  getFeatureAccessList: PropTypes.func,

  organizationId: PropTypes.string,
  doRequestChange: PropTypes.func,

  eventChatNotifications: PropTypes.array,
  eventChatNotificationsPending: PropTypes.bool,
  eventChatNotificationsError: PropTypes.object,

  monthScheduleItems: PropTypes.array,
  monthSchedulePending: PropTypes.bool,
  weekHasNotifications: PropTypes.bool,

  confirmEvent: PropTypes.func,
  confirmEventPending: PropTypes.bool,
  history: PropTypes.object,

  profileLocale: PropTypes.string,
}
