import axios from 'axios'
import apiConfig from '../../config/api-config'

export function fetchChatItem(scheduledStoreId) {
  const url = apiConfig.GET_EVENT_CHAT_ITEM.replace('[scheduledStoreId]', scheduledStoreId)
  return axios.get(url);
}

export function fetchChatMessages(chatId, lastPostId, postCount) {
  const url = apiConfig.CHANNEL.replace('[channelId]', chatId);
  return axios.get(url, {
    params: {
      postCount,
      lastPostId
    }
  });
}

export function postChatMessage(data) {
  const url = apiConfig.INSERT_POST_COMMENT
  return axios.post(url, data)
}

export function putChatMessage(data) {
  const url = apiConfig.INSERT_POST_COMMENT
  return axios.put(url, data)
}

export function deleteChatMessage(messageId) {
  const url = apiConfig.POST.replace('[postId]', messageId)
  return axios.delete(url)
}