import * as types from './actionTypes'
import services from '../services'

// const promises = [];
let last = 0;
let total = 0;

const req = (promise) => new Promise((resolve, reject) => {
  total += 1;
  const next = (i) => {
    if (last + 1 !== i) {
      setTimeout(() => {
        next(i);
      }, 100);
      return;
    }
    promise().then((res) => {
      last = i;
      resolve(res);
    }).catch((e) => {
      last = i;
      reject(e);
    })
  }
  next(total);
});

export function getUserReactions(postIds, category) {
  return dispatch => {
    dispatch(getUserReactionsPending(true, category))
    if (postIds.length === 0) {
      setTimeout(() => {
        dispatch(getUserReactionsSuccess([]))
        dispatch(getUserReactionsPending(false, category))
      }, 1);
      return
    }
    services.reactions
      .getUserReactions(postIds)
      .then(response => {
        dispatch(getUserReactionsSuccess(response.data))
        dispatch(getUserReactionsPending(false, category))
      })
      .catch(error => {
        dispatch(getUserReactionsError(error))
        dispatch(getUserReactionsPending(false, category))
      })
  }
}

const getUserReactionsSuccess = response => {
  return {
    type: types.GET_USER_REACTIONS_SUCCESS,
    payload: response,
  }
}
const getUserReactionsError = response => {
  return {
    type: types.GET_USER_REACTIONS_ERROR,
    error: response,
  }
}
const getUserReactionsPending = (status, category) => {
  return {
    type: types.GET_USER_REACTIONS_PENDING,
    status,
    category,
  }
}

export function postReaction(reaction, postId) {
  return dispatch => {
    dispatch(postReactionPending(true, postId))
    req(() => services.reactions
      .postReaction(reaction))
      .then(response => {
        dispatch(postReactionSuccess(response, postId))
        dispatch(postReactionPending(false, postId))
      })
      .catch(error => {
        dispatch(postReactionError(error))
        dispatch(postReactionPending(false, postId))
      })
  }
}

const postReactionSuccess = (response, postId) => {
  return {
    type: types.POST_REACTION_SUCCESS,
    payload: response.data,
    postId
  }
}
const postReactionError = response => {
  return {
    type: types.POST_REACTION_ERROR,
    error: response,
  }
}
const postReactionPending = (status, postId) => {
  return {
    type: types.POST_REACTION_PENDING,
    status,
    postId
  }
}

export function deleteReaction(reactionId, postId) {
  return dispatch => {
    dispatch(deleteReactionPending(true, postId))
    req(() => services.reactions
      .deleteReaction(reactionId))
      .then(response => { // eslint-disable-line no-unused-vars
        dispatch(deleteReactionSuccess(reactionId, postId))
        dispatch(deleteReactionPending(false, postId))
      })
      .catch(error => {
        dispatch(deleteReactionError(error))
        dispatch(deleteReactionPending(false, postId))
      })
  }
}

const deleteReactionSuccess = (response, postId) => {
  return {
    type: types.DELETE_REACTION_SUCCESS,
    payload: response,
    postId
  }
}
const deleteReactionError = response => {
  return {
    type: types.DELETE_REACTION_ERROR,
    error: response,
  }
}
const deleteReactionPending = (status, postId) => {
  return {
    type: types.DELETE_REACTION_PENDING,
    status,
    postId
  }
}