import React, { Children, cloneElement, Component } from 'react'
import PropTypes from 'prop-types'

import Tab from './Tab'

export default class Tabs extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: props.activeTab,
      tabs: [],
    }

    this.addTab = this.addTab.bind(this)
    this.onClick = this.onClick.bind(this)
  }

  addTab(newTab) {
    this.setState(prevState => {
      return {
        tabs: prevState.tabs.concat(newTab),
      }
    })
  }

  // eslint-disable-next-line no-unused-vars
  onClick = tab => event => {
    this.setState(() => {
      return {
        activeTab: tab,
      }
    })
  }

  render() {
    const { children, chatHasNewMessages } = this.props
    return (
      <>
        <div className="d-flex justify-content-around nav-tabs m-0">
          {this.state.tabs.map((tab, index) => (
            <div
              key={index}
              className={`px-0 px-sm-3 nav-item uppercase ${this.state.activeTab.id === tab.id ? 'active' : ''}`}
              onClick={this.onClick(tab)}
            >
              {chatHasNewMessages === true && tab.id === 'event_chat' &&
                <i className="material-icons fiber_manual_record small">fiber_manual_record</i>
              }
              {tab.title}
            </div>
          ))}
        </div>

        {Children.map(children, child => {
          // Only clone <Tab>
          if (child.type && child.type === Tab) {
            return cloneElement(child, {
              activeTab: this.state.activeTab,
              addTab: this.addTab,
            })
          }

          return child
        })}
      </>
    )
  }
}

Tabs.propTypes = {
  activeTab: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  chatHasNewMessages: PropTypes.bool,
}
