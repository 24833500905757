import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

import { getShifts, putAcceptShift, putRejectShift } from '../../state/actions/openShiftsActions'
import { getGeneralAvailability } from '../../state/actions/generalAvailabilityActions'
import { t } from '../../utils/i18n'

import { hasShiftsAccess } from '../../components/utils/featureAccessUtils'
import { nullSafeCheckIsTrue } from '../../components/utils/nullSafeCheckUtils'
import { formatTimeNotForDisplayIncludeSeconds, TWENTY_FOUR_HOUR_TIME_FORMAT_INCLUDE_SECONDS } from '../../components/utils/datetimeUtils'
import { getEventMeetSite } from '../../state/actions/eventDetailsActions'

import Preloader from '../../components/Preloader'
import OpenShiftCard from '../../components/OpenShifts/OpenShiftCard'
import ActionModal from '../../components/ActionModal'
import './OpenShiftsContainer.scss'

class OpenShiftsContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      shifts: [],
      showActionModal: false,
      modal: {
        title: '',
        text: '',
        showCancelAction: false,
        acceptButtonText: '',
        cancelButtonText: '',
        acceptAction: () => { },
        cancelAction: () => { },
      }
    }

    this.signUpOnShift = this.signUpOnShift.bind(this)
    this.hasEventMeetSite = this.hasEventMeetSite.bind(this)
    this.declineShift = this.declineShift.bind(this)
    this.closeActionModal = this.closeActionModal.bind(this)
  }

  componentDidMount() {
    this.props.getGeneralAvailability()
    if (this.props.shifts.length) {
      this.setState({ 
        shifts: this.props.shifts 
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.eventMeetSitePending === true && this.props.eventMeetSitePending === false) {
      this.checkHasMeetSite()
    }

    if (prevProps.shiftsPending === true && this.props.shiftsPending === false) {
      this.setState({ 
        shifts: this.props.shifts 
      })
    }

    if (prevProps.acceptShiftPending === true && this.props.acceptShiftPending === false) {
      const _state = this.state
      if (Object.keys(this.props.acceptShiftError).length === 0) {
        _state.modal = {
          title: t('success'),
          text: t('shift_sign_up_confirmation_text'),
          showCancelAction: false,
          acceptButtonText: t('ok'),
          cancelButtonText: '',
          acceptAction: this.closeActionModal,
          cancelAction: this.closeActionModal
        }
      } else if (this.props.acceptShiftError.response && this.props.acceptShiftError.response.status === 400) {
        _state.modal = {
          title: t('unable_to_accept_open_shift'),
          text: '',
          showCancelAction: false,
          acceptButtonText: t('ok'),
          cancelButtonText: '',
          acceptAction: () => {
            this.closeActionModal()
            this.props.getShifts()
          },
          cancelAction: this.closeActionModal
        }
      } else if (this.props.acceptShiftError.response && this.props.acceptShiftError.response.status === 409) {
        _state.modal = {
          title: t('event_conflict'),
          text: t('event_conflict_text'),
          showCancelAction: false,
          acceptButtonText: t('ok'),
          cancelButtonText: '',
          acceptAction: () => {
            this.closeActionModal()
            this.props.getShifts()
          },
          cancelAction: this.closeActionModal
        }
      } else {
        _state.modal = {
          title: t('something_went_wrong'),
          text: '',
          showCancelAction: false,
          acceptButtonText: t('ok'),
          cancelButtonText: '',
          acceptAction: () => {
            this.closeActionModal()
            this.props.getShifts()
          },
          cancelAction: this.closeActionModal
        }
      }
      _state.showActionModal = true
      _state.shifts = this.props.shifts
      this.setState(_state)
    }

    if (prevProps.rejectShiftPending === true && this.props.rejectShiftPending === false) {
      this.setState({
        shifts: this.props.shifts
      })
    }

    if (prevProps.featureAccessPending === true && this.props.featureAccessPending === false) {
      if (!hasShiftsAccess(this.props.featureAccess)) {
        return <Redirect to="/profile" />
      }
    }
  }

  hasEventMeetSite(shift) {
    this.setState({
      currentShift: shift,
    })
    this.props.getEventMeetSite(shift.eventId)
  }

  checkHasMeetSite() {
    const { eventMeetSite } = this.props
    const { currentShift } = this.state
    
    if (Object.keys(eventMeetSite).length === 0)
    {
      const _state = this.state
      _state.modal = {
        title: t('confirmation'),
        text: t('confirm_open_shift'),
        showCancelAction: true,
        acceptButtonText: t('ok'),
        cancelButtonText: t('cancel'),
        acceptAction: () => {
          this.closeActionModal()
          this.signUpOnShift(currentShift)
        },
        cancelAction: this.closeActionModal,
      }
      _state.showActionModal = true
      this.setState(_state)
    } else {
      this.signUpOnShift(currentShift)
    }
  }

  signUpOnShift(shift) {
    const { generalAvailability } = this.props
    const startShiftDate = moment(formatTimeNotForDisplayIncludeSeconds(new Date(shift.startDateTime)), TWENTY_FOUR_HOUR_TIME_FORMAT_INCLUDE_SECONDS)
    const shiftDuration = moment.duration(shift.duration)
    const endShiftDate = startShiftDate.clone().add(shiftDuration)
    let isAvailabilityConflict = false

    if (
      generalAvailability[startShiftDate.day()].availableTimes.length !== 0 &&
      generalAvailability[endShiftDate.day()].availableTimes.length !== 0
    ) {
      const startDayAvailabilityStartTime = moment(formatTimeNotForDisplayIncludeSeconds(generalAvailability[startShiftDate.day()].availableTimes[0].startTime), TWENTY_FOUR_HOUR_TIME_FORMAT_INCLUDE_SECONDS)
      const startDayAvailabilityEndTime = moment(formatTimeNotForDisplayIncludeSeconds(generalAvailability[startShiftDate.day()].availableTimes[0].endTime), TWENTY_FOUR_HOUR_TIME_FORMAT_INCLUDE_SECONDS)
      const endDayAvailabilityStartTime = moment(formatTimeNotForDisplayIncludeSeconds(generalAvailability[endShiftDate.day()].availableTimes[0].startTime), TWENTY_FOUR_HOUR_TIME_FORMAT_INCLUDE_SECONDS)
      const endDayAvailabilityEndTime = moment(formatTimeNotForDisplayIncludeSeconds(generalAvailability[endShiftDate.day()].availableTimes[0].endTime), TWENTY_FOUR_HOUR_TIME_FORMAT_INCLUDE_SECONDS)

      if (
        moment(formatTimeNotForDisplayIncludeSeconds(startShiftDate), TWENTY_FOUR_HOUR_TIME_FORMAT_INCLUDE_SECONDS).isBetween(startDayAvailabilityStartTime, startDayAvailabilityEndTime) &&
        moment(formatTimeNotForDisplayIncludeSeconds(endShiftDate), TWENTY_FOUR_HOUR_TIME_FORMAT_INCLUDE_SECONDS).isBetween(endDayAvailabilityStartTime, endDayAvailabilityEndTime)
      ) {
        if (
          startShiftDate.isSame(endShiftDate, 'day') ||
          (
            formatTimeNotForDisplayIncludeSeconds(startDayAvailabilityEndTime) === "23:59:59" &&
            formatTimeNotForDisplayIncludeSeconds(endDayAvailabilityStartTime) === "00:00:00"
          )
        ) {
          isAvailabilityConflict = false
        } else {
          isAvailabilityConflict = true
        }
      } else {
        isAvailabilityConflict = true
      }
    } else {
      isAvailabilityConflict = true
    }

    if (isAvailabilityConflict) {
      const _state = this.state
      _state.modal = {
        title: t('availability_conflict'),
        text: t('availability_conflict_text'),
        showCancelAction: true,
        acceptButtonText: t('continue'),
        cancelButtonText: t('cancel'),
        acceptAction: () => {
          this.closeActionModal()
          this.props.putAcceptShift(shift.shiftId)
        },
        cancelAction: this.closeActionModal
      }
      _state.showActionModal = true
      this.setState(_state)
    } else {
      this.props.putAcceptShift(shift.shiftId)
    }
  }

  declineShift(shiftId) {
    const _state = this.state
    _state.modal = {
      title: t('confirmation'),
      text: t('decline_open_shift'),
      showCancelAction: true,
      acceptButtonText: t('decline'),
      cancelButtonText: t('cancel'),
      acceptAction: () => {
        this.closeActionModal()
        this.props.putRejectShift(shiftId)
      },
      cancelAction: this.closeActionModal
    }
    _state.showActionModal = true
    this.setState(_state)
  }

  closeActionModal() {
    const _state = this.state
    _state.modal = {
      title: '',
      text: '',
      showCancelAction: false,
      acceptButtonText: '',
      cancelButtonText: '',
      acceptAction: () => { },
      cancelAction: () => { },
    }
    _state.showActionModal = false
    this.setState(_state)
  }

  render() {
    const {
      shiftsPending,
      generalAvailabilityPending,
      acceptShiftPending,
      rejectShiftPending,
      featureAccessPending,
      eventMeetSitePending,
    } = this.props
    const { shifts, showActionModal, modal } = this.state;
    
    if (shiftsPending || generalAvailabilityPending || acceptShiftPending || rejectShiftPending || featureAccessPending || eventMeetSitePending) {
      return <Preloader />
    }

    if (!hasShiftsAccess(this.props.featureAccess)) {
      return <Redirect to="/profile" />
    }

    return (
      <div className="open-shifts-container">
        <div className="row">
          <div className="col open-shifts-header">{t('open_shifts')}</div>
        </div>
        <div>
          {
            shifts && shifts.map((shift) => (
              <div key={shift.shiftId}>
                <OpenShiftCard
                  shift={shift}
                  hasEventMeetSite={this.hasEventMeetSite}
                  declineShift={this.declineShift}
                />
              </div>
              ))
            }
            {
              !shifts.length &&
              <div>{t('no_available_open_shifts')}</div>
            }
        </div>
        <ActionModal
          showModal={showActionModal}
          modalTitle={modal.title}
          modalText={modal.text}
          showCancelAction={modal.showCancelAction}
          modalAcceptButtonText={modal.acceptButtonText}
          modalCancelButtonText={modal.cancelButtonText}
          modalAcceptAction={modal.acceptAction}
          modalCancelAction={modal.cancelAction}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    shifts: state.openShiftsReducer.shifts,
    shiftsPending: state.openShiftsReducer.shiftsPending,
    shiftsError: state.openShiftsReducer.shiftsError,
    generalAvailability: state.generalAvailabilityReducer.generalAvailability,
    generalAvailabilityPending: state.generalAvailabilityReducer.generalAvailabilityPending,
    acceptShiftPending: state.openShiftsReducer.acceptShiftPending,
    acceptShiftError: state.openShiftsReducer.acceptShiftError,
    rejectShiftPending: state.openShiftsReducer.rejectShiftPending,
    rejectShiftError: state.openShiftsReducer.rejectShiftError,
    featureAccess: state.featureAccessReducer.featureAccess,
    featureAccessPending: state.featureAccessReducer.featureAccessPending,
    profileLocale: state.loginReducer.profileLocale,
    eventMeetSite: state.eventDetailsReducer.eventMeetSite,
    eventMeetSitePending: state.eventDetailsReducer.eventMeetSitePending,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      putAcceptShift: (shiftId) => putAcceptShift(shiftId),
      putRejectShift: (shiftId) => putRejectShift(shiftId),
      getGeneralAvailability: () => getGeneralAvailability(),
      getShifts: () => getShifts(),
      getEventMeetSite: (eventId) => getEventMeetSite(eventId),
    },
    dispatch
  )
}

OpenShiftsContainer.propTypes = {
  shifts: PropTypes.array,
  shiftsPending: PropTypes.bool,
  shiftsError: PropTypes.object,
  putAcceptShift: PropTypes.func,
  acceptShiftPending: PropTypes.bool,
  acceptShiftError: PropTypes.object,
  putRejectShift: PropTypes.func,
  rejectShiftPending: PropTypes.bool,
  rejectShiftError: PropTypes.object,
  getShifts: PropTypes.func,
  history: PropTypes.object,
  getGeneralAvailability: PropTypes.func,
  generalAvailability: PropTypes.array,
  generalAvailabilityPending: PropTypes.bool,
  featureAccess: PropTypes.object,
  featureAccessPending: PropTypes.bool,
  profileLocale: PropTypes.string,
  getEventMeetSite: PropTypes.func,
  eventMeetSite: PropTypes.object,
  eventMeetSitePending: PropTypes.bool,
  signUpOnShift: PropTypes.func,
  hasEventMeetSite: PropTypes.func,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OpenShiftsContainer)
