import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

import { getReceivedCompliments, setComplimentFilter } from '../../state/actions/complimentsActions'
import { getPersonDetailsList } from '../../state/actions/personDetailsActions'
import { getEventDetailsList } from '../../state/actions/eventDetailsActions'
import { postListOfProfileImages } from '../../state/actions/profileActions'
import { getModerationReasons } from '../../state/actions/moderationActions'

import { 
  hasComplimentsAccess, 
} from '../../components/utils/featureAccessUtils'
import { nullSafeCheckIsTrue, isDefined } from '../../components/utils/nullSafeCheckUtils'

import Preloader from '../../components/Preloader'
import ComplimentsItem from '../../components/Compliments/ComplimentsItem'
import './ComplimentsContainer.scss'
import { t } from '../../utils/i18n';
import { INTEGRITY_VALUE, PRIDE_VALUE, SERVICE_VALUE, TEAMWORK_VALUE, INNOVATION_VALUE, RESULTS_VALUE } from '../../utils/constants';

const PAGE_SIZE = 10
class ComplimentsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      receivedCompliments: []
    }
  }

  componentDidMount() {
    this.getReceivedCompliments()
    this.props.getModerationReasons()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.receivedComplimentsPending === true && this.props.receivedComplimentsPending === false) {
      this.setState({ receivedCompliments: this.props.receivedCompliments })
      this.loadComplimentSenderPersonDetailsList()
      this.loadComplimentEventDetailsList()
      
      const personIds = this.props.receivedCompliments.reduce((total, compliment) => {
        if (!total.includes(compliment.senderPersonId)) {
          total.push(compliment.senderPersonId)
        }
        return total
      }, [])
      this.props.postListOfProfileImages(personIds, 'compliments')
      this.props.setComplimentFilter(INTEGRITY_VALUE, 0, this.props.receivedComplimentsFilteredArrays[INTEGRITY_VALUE.toUpperCase()].length)
    }

    if (prevProps.featureAccessPending === true && this.props.featureAccessPending === false) {
      this.checkAccess()
    }
  }

  getReceivedCompliments = () => {
    this.props.getReceivedCompliments()
  }

  loadComplimentEventDetailsList = () => {
    let complimentEventIds = this.getEventIds()

    if (complimentEventIds.length > 0) {
      this.props.getEventDetailsList(complimentEventIds)
    }
  }

  loadComplimentSenderPersonDetailsList = () => {
    let senderPersonIds = this.getSenderPersonIds()

    if (senderPersonIds.length > 0) {
      this.props.getPersonDetailsList(senderPersonIds)
    }
  }

  getEventIds = () => {
    let eventIds = []

    for (let i = 0; i < this.props.receivedCompliments.length; i++) {
      if (!isDefined(this.props.receivedCompliments[i].eventId)) { continue; }

      if (Number.isInteger(this.props.receivedCompliments[i].eventId)
        && eventIds.indexOf(this.props.receivedCompliments[i].eventId) === -1
        && this.props.receivedCompliments[i].eventId > 0) {
        eventIds.push(this.props.receivedCompliments[i].eventId)
      }
    }

    return eventIds
  }

  getSenderPersonIds = () => {
    let senderPersonIds = []
    for (let i = 0; i < this.props.receivedCompliments.length; i++) {
      if (Number.isInteger(this.props.receivedCompliments[i].senderPersonId)
        && senderPersonIds.indexOf(this.props.receivedCompliments[i].senderPersonId) === -1
        && this.props.receivedCompliments[i].senderPersonId > 0) {
        senderPersonIds.push(this.props.receivedCompliments[i].senderPersonId)
      }
    }
    return senderPersonIds
  }

  checkAccess = () => {
    if (!hasComplimentsAccess(this.props.featureAccess)) {
      return <Redirect to="/profile" />
    }
  }

  filterReceivedCompliments = (receivedCompliments) => {
    if (!isDefined(receivedCompliments)) { return []; }

    let startIndex = 0
    let endIndexUnchecked = ((PAGE_SIZE * this.props.complimentFilter.currentPage) + PAGE_SIZE);
    let endIndex = endIndexUnchecked > receivedCompliments.length ? receivedCompliments.length : endIndexUnchecked

    var filteredCompliments = []
    for (let i = startIndex; i < endIndex; i++) {
      filteredCompliments.push(receivedCompliments[i])
    }
    return filteredCompliments
  }

  getReceivedCoreValueCompliments = () => {
    let coreValueCompliments = []

    if (!isDefined(this.props.complimentFilter) || !isDefined(this.props.complimentFilter.complimentFilter)) {
      return coreValueCompliments;
    }

    coreValueCompliments = this.props.receivedComplimentsFilteredArrays[this.props.complimentFilter.complimentFilter.toUpperCase()]

    return coreValueCompliments
  }

  loadMoreComplimentButtonClick = () => {
    this.props.setComplimentFilter(this.props.complimentFilter.complimentFilter, this.props.complimentFilter.currentPage + 1, this.props.complimentFilter.complimentCount)

    this.renderReceivedCompliments()
  }

  renderLoadComplimentsButtonMarkUp = () => {
    return this.props.complimentFilter.complimentCount > (this.props.complimentFilter.currentPage + 1) * PAGE_SIZE
      ? (
        <div>
          <input type='button' onClick={() => this.loadMoreComplimentButtonClick()} className="btn btn-link" value={t('load_more_compliments')} />
        </div>
      )
      : ''
  }

  renderReceivedCompliments = () => {
    const filteredCompliments = this.filterReceivedCompliments(this.getReceivedCoreValueCompliments())
    const { profileImages } = this.props

    return filteredCompliments.length > 0 ?
      (
        <div className="compliments-width">
          {filteredCompliments.map((compliment) => {
            const userAvatar = profileImages.find(obj => obj.personId === compliment.senderPersonId)
            return this.getComplimentsItemMarkUp(compliment, userAvatar)
          })}
          {this.renderLoadComplimentsButtonMarkUp()}
        </div>
      )
      :
      (<div>{t('no_compliments_message')}</div>)
  }

  getComplimentsItemMarkUp = (compliment, userAvatar) => {
    return (
      <ComplimentsItem
        key={compliment.complimentId}
        userAvatarUrl={userAvatar !== undefined ? userAvatar.url : ''}
        compliment={compliment}
        complimentSenderName={this.getPersonNameByPersonId(compliment.senderPersonId)}
        eventDetails={this.getEventDetailsFromList(compliment)}
        history={this.props.history}
        moderationReasons={this.props.moderationReasons}
      />
    )
  }

  getPersonNameByPersonId = (personId) => {
    if (!isDefined(this.props.personDetailsList)) { return ''; }

    for (let i = 0; i < this.props.personDetailsList.length; i++) {
      if (this.props.personDetailsList[i].personId === personId) {
        return this.props.personDetailsList[i].firstName + ' ' + this.props.personDetailsList[i].lastName
      }
    }

    return t('unknown_person')
  }

  getEventDetailsFromList = (compliment) => {
    if (!isDefined(compliment.eventId)) { return null; }

    for (let i = 0; i < this.props.eventDetailsList.length; i++) {
      if (this.props.eventDetailsList[i].scheduledEventId === compliment.eventId) {
        return this.props.eventDetailsList[i]
      }
    }

    return { bannerName: t('unknown_event') }
  }

  setComplimentFilterAndReload = (complimentFilter) => {
    this.props.setComplimentFilter(complimentFilter, 0, this.props.receivedComplimentsFilteredArrays[complimentFilter.toUpperCase()].length)

    this.renderReceivedCompliments()
  }

  getCoreValueImageStyle = (coreValue) => {
    let style = ''

    switch (coreValue) {
      case INTEGRITY_VALUE:
        style = coreValue.toUpperCase() === this.props.complimentFilter.complimentFilter.toUpperCase() ? ' core-value-integrity-selected' : ' core-value-integrity'
        break;
      case PRIDE_VALUE:
        style = coreValue.toUpperCase() === this.props.complimentFilter.complimentFilter.toUpperCase() ? ' core-value-pride-selected' : ' core-value-pride'
        break;
      case SERVICE_VALUE:
        style = coreValue.toUpperCase() === this.props.complimentFilter.complimentFilter.toUpperCase() ? ' core-value-service-selected' : ' core-value-service'
        break;
      case TEAMWORK_VALUE:
        style = coreValue.toUpperCase() === this.props.complimentFilter.complimentFilter.toUpperCase() ? ' core-value-teamwork-selected' : ' core-value-teamwork'
        break;
      case INNOVATION_VALUE:
        style = coreValue.toUpperCase() === this.props.complimentFilter.complimentFilter.toUpperCase() ? ' core-value-innovation-selected' : ' core-value-innovation'
        break;
      case RESULTS_VALUE:
          style = coreValue.toUpperCase() === this.props.complimentFilter.complimentFilter.toUpperCase() ? ' core-value-results-selected' : ' core-value-results'
          break;
      default:
        style = ''
        break;
    }

    return style
  }

  translateCoreValue = (coreValue) => {
    return t(coreValue.toLowerCase())
  }

  getComplimentCount = (coreValue) => {
    if (!isDefined(this.props.receivedComplimentsFilteredArrays)) {
      return ('0')
    }
    if (!isDefined(this.props.receivedComplimentsFilteredArrays[coreValue.toUpperCase()])) {
      return ('0')
    }
    return this.props.receivedComplimentsFilteredArrays[coreValue.toUpperCase()].length.toString()
  }

  render() {
    const {
      receivedComplimentsPending,
      featureAccessPending,
      personDetailsListPending,
      eventDetailsListPending,
      profileImagesPending,
      moderationReasonsPending,
    } = this.props

    if (receivedComplimentsPending
      || featureAccessPending
      || personDetailsListPending
      || eventDetailsListPending
      || profileImagesPending
      || moderationReasonsPending
    ) {
      return <Preloader />
    }

    this.checkAccess()

    return (
      <div className="compliments-container" >
        <div className="d-flex align-items-center justify-content-between compliments-width">
          <div className="pl-0 compliments-header">{t('received_compliments')}</div>
          <div className="d-flex align-items-center justify-content-end">
            <i
              onClick={() => this.props.history.push('/profile')}
              className="material-icons add_box cursor-pointer other-avail-icon"
            >
              arrow_back
              </i>
          </div>
        </div>
        <div className="row">
          <div className="col-2 col-md-1">
            <div
            className={"cursor-pointer core-value-image ".concat(this.getCoreValueImageStyle(SERVICE_VALUE))}
            onClick={() => this.setComplimentFilterAndReload(SERVICE_VALUE)}
            title={t('service')}
            />
          </div>
          <div className="col-2 col-md-1">
            <div
              className={"cursor-pointer core-value-image ".concat(this.getCoreValueImageStyle(PRIDE_VALUE))}
              onClick={() => this.setComplimentFilterAndReload(PRIDE_VALUE)}
              title={t('pride')}
              />
          </div>
          <div className="col-2 col-md-1">
            <div
              className={"cursor-pointer core-value-image ".concat(this.getCoreValueImageStyle(INTEGRITY_VALUE))}
              onClick={() => this.setComplimentFilterAndReload(INTEGRITY_VALUE)}
              title={t('integrity')}
            />
          </div>
          <div className="col-2 col-md-1">
            <div
            className={"cursor-pointer core-value-image ".concat(this.getCoreValueImageStyle(RESULTS_VALUE))}
            onClick={() => this.setComplimentFilterAndReload(RESULTS_VALUE)}
            title={t('results')}
            />
          </div>
          <div className="col-2 col-md-1">
            <div
              className={"cursor-pointer core-value-image ".concat(this.getCoreValueImageStyle(INNOVATION_VALUE))}
              onClick={() => this.setComplimentFilterAndReload(INNOVATION_VALUE)}
              title={t('innovation')}
              />
          </div>
          <div className="col-2 col-md-1">
            <div
              className={"cursor-pointer core-value-image ".concat(this.getCoreValueImageStyle(TEAMWORK_VALUE))}
              onClick={() => this.setComplimentFilterAndReload(TEAMWORK_VALUE)}
              title={t('teamwork')}
            />
          </div>
          <div className="col-2 col-md-7" />
        </div>
        <div className="row">
          <div className="col-2 col-md-1 text-center">
            <div className="core-value-count cursor-pointer core-value-image">{this.getComplimentCount(SERVICE_VALUE)}</div>
          </div>
          <div className="col-2 col-md-1 text-center">
            <div className="core-value-count cursor-pointer core-value-image">{this.getComplimentCount(PRIDE_VALUE)}</div>
          </div>
          <div className="col-2 col-md-1 text-center">
            <div className="core-value-count cursor-pointer core-value-image">{this.getComplimentCount(INTEGRITY_VALUE)}</div>
          </div>
          <div className="col-2 col-md-1 text-center">
            <div className="core-value-count cursor-pointer core-value-image">{this.getComplimentCount(RESULTS_VALUE)}</div>
          </div>
          <div className="col-2 col-md-1 text-center">
            <div className="core-value-count cursor-pointer core-value-image">{this.getComplimentCount(INNOVATION_VALUE)}</div>
          </div>
          <div className="col-2 col-md-1 text-center">
            <div className="core-value-count cursor-pointer core-value-image">{this.getComplimentCount(TEAMWORK_VALUE)}</div>
          </div>
          <div className="col-2 col-md-7" />
        </div>
        <div className="row">
          <div className="col"><i>{t('compliments_filtered_by', [this.translateCoreValue(this.props.complimentFilter.complimentFilter)])}</i></div>
        </div>
        <div>
          {this.renderReceivedCompliments()}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    receivedCompliments: state.complimentsReducer.receivedCompliments,
    receivedComplimentsPending: state.complimentsReducer.receivedComplimentsPending,
    receivedComplimentsError: state.complimentsReducer.receivedComplimentsError,
    featureAccess: state.featureAccessReducer.featureAccess,
    featureAccessPending: state.featureAccessReducer.featureAccessPending,
    complimentFilter: state.complimentsReducer.complimentFilter,
    personDetailsList: state.personDetailsReducer.personDetailsList,
    personDetailsListPending: state.personDetailsReducer.personDetailsListPending,
    personDetailsListError: state.personDetailsReducer.personDetailsListError,
    eventDetailsList: state.eventDetailsReducer.eventDetailsList,
    eventDetailsListPending: state.eventDetailsReducer.eventDetailsListPending,
    eventDetailsListError: state.eventDetailsReducer.eventDetailsListError,
    receivedComplimentsFilteredArrays: state.complimentsReducer.receivedComplimentsFilteredArrays,
    profileImages: state.profileReducer.profileImages,
    profileImagesPending: state.profileReducer.profileImagesPending['compliments'],
    moderationReasons: state.moderationReducer.moderationReasons,
    moderationReasonsPending: state.moderationReducer.moderationReasonsPending,
    moderationReasonsError: state.moderationReducer.moderationReasonsError,
    profileLocale: state.loginReducer.profileLocale,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getReceivedCompliments: (numberOfMostRecentCompliments) => getReceivedCompliments(numberOfMostRecentCompliments),
      setComplimentFilter: (complimentFilter, currentPage, complimentCount) => setComplimentFilter(complimentFilter, currentPage, complimentCount),
      getPersonDetailsList: (personIds) => getPersonDetailsList(personIds),
      getEventDetailsList: (scheduledEventIds) => getEventDetailsList(scheduledEventIds),
      postListOfProfileImages: (personIds, location) => postListOfProfileImages(personIds, location),
      getModerationReasons: () => getModerationReasons(),
    },
    dispatch
  )
}

ComplimentsContainer.propTypes = {
  complimentCategory: PropTypes.string,
  receivedCompliments: PropTypes.array,
  receivedComplimentsPending: PropTypes.bool,
  receivedComplimentsError: PropTypes.object,
  getReceivedCompliments: PropTypes.func,
  history: PropTypes.object,
  featureAccess: PropTypes.object,
  featureAccessPending: PropTypes.bool,
  setComplimentFilter: PropTypes.func,
  complimentFilter: PropTypes.object,
  personDetailsList: PropTypes.array,
  personDetailsListPending: PropTypes.bool,
  personDetailsListError: PropTypes.object,
  getPersonDetailsList: PropTypes.func,
  getEventDetailsList: PropTypes.func,
  eventDetailsList: PropTypes.array,
  eventDetailsListPending: PropTypes.bool,
  eventDetailsListError: PropTypes.object,
  receivedComplimentsFilteredArrays: PropTypes.object,
  postListOfProfileImages: PropTypes.func,
  profileImages: PropTypes.array,
  profileImagesPending: PropTypes.bool,
  getModerationReasons: PropTypes.func,
  moderationReasons: PropTypes.array,
  moderationReasonsPending: PropTypes.bool,
  moderationReasonsError: PropTypes.object,
  profileLocale: PropTypes.string,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComplimentsContainer)
