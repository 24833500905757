import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import { reducer as formReducer } from 'redux-form'

import teamMemberInfoReducer from './teamMemberInfoReducer'
import eventDetailsReducer from './eventDetailsReducer'
import generalAvailabilityReducer from './generalAvailabilityReducer'
import profilePrefReducer from './profilePrefReducer'
import notificationsReducer from './notificationsReducer'
import organizationsReducer from './organizationsReducer'
import otherAvailabilityReducer from './otherAvailabilityReducer'
import personDetailsReducer from './personDetailsReducer'
import loginReducer from './loginReducer'
import requestChangeReducer from './requestChangeReducer'
import requestDetailsListReducer from './requestDetailsListReducer'
import requestDetailsReducer from './requestDetailsReducer'
import scheduleReducer from './scheduleReducer'
import teamDetailsListReducer from './teamDetailsListReducer'
import districtRequestManagerReducer from './districtRequestManagerReducer'
import toastsReducer from './toastsReducer'
import confirmEventReducer from './confirmEventReducer'
import transportationProfileReducer from './transportationProfileReducer'
import mobileDeviceReducer from './mobileDeviceReducer'
import newsReducer from './newsReducer'
import featureAccessReducer from './featureAccessReducer'
import channelReducer from './channelReducer'
import postCardReducer from './postCardReducer'
import reactionsReducer from './reactionsReducer'
import newPostReducer from './newPostReducer'
import platformLogReducer from './platformLogReducer'
import openShiftsReducer from './openShiftsReducer'
import badgesReducer from './badgesReducer'
import feedbackReducer from './feedbackReducer'
import complimentsReducer from './complimentsReducer'
import moderationReducer from './moderationReducer'
import contactsReducer from './contactsReducer'
import timePunchesReducer from './timePunchesReducer'
import eventChatReducer from './eventChatReducer'
import profileReducer from './profileReducer'
import publicProfileReducer from './publicProfileReducer'
import * as types from '../actions/actionTypes'

const rootReducer = (state, action) => {
  if (action.type === types.RESET_STATE) {
    state = undefined
  }

  return appReducer(state, action)
}
  
const appReducer = combineReducers({
  routing: routerReducer,
  teamMemberInfoReducer: teamMemberInfoReducer,
  eventDetailsReducer: eventDetailsReducer,
  generalAvailabilityReducer: generalAvailabilityReducer,
  otherAvailabilityReducer: otherAvailabilityReducer,
  personDetailsReducer: personDetailsReducer,
  profilePrefReducer: profilePrefReducer,
  organizationsReducer: organizationsReducer,
  notificationsReducer: notificationsReducer,
  loginReducer: loginReducer,
  form: formReducer,
  requestChangeReducer: requestChangeReducer,
  requestDetailsListReducer: requestDetailsListReducer,
  requestDetailsReducer: requestDetailsReducer,
  scheduleReducer: scheduleReducer,
  teamDetailsListReducer: teamDetailsListReducer,
  districtRequestManagerReducer: districtRequestManagerReducer,
  toastsReducer: toastsReducer,
  confirmEventReducer: confirmEventReducer,
  transportationProfileReducer: transportationProfileReducer,
  mobileDeviceReducer: mobileDeviceReducer,
  featureAccessReducer: featureAccessReducer,
  newsReducer: newsReducer,
  channelReducer: channelReducer,
  postCardReducer: postCardReducer,
  reactionsReducer: reactionsReducer,
  newPostReducer: newPostReducer,
  platformLogReducer: platformLogReducer,
  openShiftsReducer: openShiftsReducer,
  badgesReducer: badgesReducer,
  feedbackReducer: feedbackReducer,
  complimentsReducer: complimentsReducer,
  moderationReducer: moderationReducer,
  contactsReducer: contactsReducer,
  timePunchesReducer: timePunchesReducer,
  eventChatReducer: eventChatReducer,
  profileReducer: profileReducer,
  publicProfileReducer: publicProfileReducer
})

export default rootReducer
