import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

import { watchChannel } from '../../state/actions/newsActions'
import { getChannelPosts, getChannelAccess, resetState } from '../../state/actions/channelActions'
import { getModerationReasons } from '../../state/actions/moderationActions'
import { t } from '../../utils/i18n'
import { getUserChannelAccess } from '../../components/utils/utils'
import { 
  hasNewsAccess, 
} from '../../components/utils/featureAccessUtils'
import { setNewPosts, getNewPosts } from '../../components/utils/localStorageUtils'
import { getUserReactions } from '../../state/actions/reactionsActions'
import { postListOfProfileImages } from '../../state/actions/profileActions'

import Preloader from '../../components/Preloader'
import PostCardDetails from '../../components/PostCardDetails'
import ActionModal from '../../components/ActionModal'
import commentActionsImg from '../../styles/images/ellipsis-v-solid.svg'

import './ChannelContainer.scss'

let lastPostId = -1

class ChannelContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      channelInfo: {
        title: '',
        newPostCount: 0,
      },
      channelPosts: [],
      scrollY: 0,
      postsLoading: false,
      isNewPost: {},
      channelActionDropdown: false,
      modal: {
        showModal: false,
        modalTitle: '',
        modalText: '',
        showCancelAction: false,
        modalAcceptButtonText: '',
        modalCancelButtonText: '',
        modalAcceptAction: null,
        modalCancelAction: null,
      },
    }
    this.addNewPostClick = this.addNewPostClick.bind(this)
    this.onPageScroll = this.onPageScroll.bind(this)
    this.returnToNews = this.returnToNews.bind(this)
    this.cancelPostAction = this.cancelPostAction.bind(this)
    this.watchChannelClick = this.watchChannelClick.bind(this)
    this.channelActionsClick = this.channelActionsClick.bind(this)
    this.watchChannelAction = this.watchChannelAction.bind(this)
    this.loadMorePostsButtonClick = this.loadMorePostsButtonClick.bind(this)

    window.addEventListener('scroll', this.onPageScroll)
  }

  onPageScroll() {
    const { channelPending } = this.props
    if (channelPending) return;

    if (
      window.innerHeight + document.documentElement.scrollTop
      === document.documentElement.offsetHeight
    ) {
      const _state = this.state
      _state.scrollY = window.scrollY + window.innerHeight - 200
      _state.postsLoading = true
      this.setState(_state)
    }
  }

  componentDidMount() {
    let channelId = this.props.match.params.channel_id
    const _locationState = this.props.location.state
    const _state = this.state

    if (_locationState !== undefined && _locationState.channelInfo !== undefined) {
      _state.channelInfo = _locationState.channelInfo
    } else if (this.props.channels.length) {
      channelId = parseInt(this.props.match.params.channel_id, 10)
      const channelInfo = this.props.channels.find(channel => channel.channelId === channelId)
      if (channelInfo) {
        _state.channelInfo = channelInfo
      }
    }
    if (_locationState !== undefined && _locationState.isNewPost !== undefined) {
      _state.isNewPost = _locationState.isNewPost
    }
    this.setState(_state)
    if (this.props.channelPosts.length === 0 || _locationState === undefined
      || (this.props.channelPosts.length !== 0 && _locationState !== undefined && _locationState.channelInfo !== undefined
        && this.props.channelPosts[0].channelId !== _locationState.channelInfo.channelId)) {
      this.props.resetState()
      lastPostId = -1
      this.props.getChannelPosts(channelId, lastPostId)
    }
    if (this.props.channelPosts.length) {
      const postIds = this.props.channelPosts.reduce((total, post) => {
        if ((post.likesEnabled && post.likeCount !== 0)
          || (post.dislikesEnabled && post.dislikeCount !== 0)) {
          total.push(post.postId)
        }
        return total
      }, [])
      this.props.getUserReactions(postIds, 'posts')
      const personIds = this.props.channelPosts.reduce((total, post) => {
        if (!total.includes(post.personId)) {
          total.push(post.personId)
        }
        return total
      }, [])
      this.props.postListOfProfileImages(personIds, 'posts')

      _state.channelPosts = this.props.channelPosts
      this.setState(_state)
    }

    this.props.getChannelAccess(channelId)
    this.props.getModerationReasons()
  }

  componentDidUpdate(prevProps) {
    if(this.state.postsLoading === false){
      window.scrollTo(0, this.state.scrollY)
    }
    const _state = this.state
    const _locationState = this.props.location.state
    if (prevProps.channelPending === true && this.props.channelPending === false) {
      const postIds = this.props.lastReceivedChannelPosts.reduce((total, post) => {
        if ((post.likesEnabled && post.likeCount !== 0)
          || (post.dislikesEnabled && post.dislikeCount !== 0)) {
          total.push(post.postId)
        }
        return total
      }, [])
      this.props.getUserReactions(postIds, 'posts')
      const personIds = this.props.channelPosts.reduce((total, post) => {
        if (!total.includes(post.personId)) {
          total.push(post.personId)
        }
        return total
      }, [])
      this.props.postListOfProfileImages(personIds, 'posts')
    }

    if (prevProps.watchChannelPending === true && this.props.watchChannelPending === false) {
      const _state = this.state
      if (!Object.keys(this.props.watchChannelError).length) {
        _state.channelInfo.isWatching = !this.state.channelInfo.isWatching
        this.setState(_state)
      }
    }

    if (prevProps.userReactionsPending === true && this.props.userReactionsPending === false) {
      _state.channelPosts = this.props.channelPosts
      for (let i = 0; i < _state.channelInfo.newPostCount && i < _state.channelPosts.length; i++) {
        if (_state.isNewPost[_state.channelPosts[i].postId] === undefined) {
          _state.isNewPost = {
            ..._state.isNewPost,
            [_state.channelPosts[i].postId]: true
          }
        }
      }
      _state.postsLoading = false
      lastPostId = this.props.channelPosts.length ? this.props.channelPosts[this.props.channelPosts.length - 1].postId : -1
      window.scrollTo(0, this.state.scrollY)
      this.setState(_state)
    }

    if (prevProps.removePostPending === true && this.props.removePostPending === false) {
      _state.channelPosts = this.props.channelPosts
      this.setState(_state)
    }

    if (prevProps.watchPostPending === true && this.props.watchPostPending === false) {
      _state.channelPosts = this.props.channelPosts
      this.setState(_state)
      const post = document.querySelector(
        ".restore-" + this.props.scrollToWatchedPostId
      )
      if (post) {
        post.scrollIntoView({ block: "center" })
      }
    }

    if (prevProps.postModerationItemPending === true && this.props.postModerationItemPending === false) {
      _state.channelPosts = this.props.channelPosts
      this.setState(_state)
      const post = document.querySelector(
        ".restore-" + this.props.scrollToFlaggedPostId
      )
      if (post) {
        post.scrollIntoView({ block: "center" })
      }
    }

    if (!Object.keys(prevProps.postModerationItemError).length && Object.keys(this.props.postModerationItemError).length) {
      _state.modal = {
        showModal: true,
        modalTitle: t('error'),
        modalText: t('try_again_later'),
        showCancelAction: false,
        modalAcceptButtonText: t('ok'),
        modalCancelButtonText: '',
        modalAcceptAction: this.cancelPostAction,
        modalCancelAction: null,
      }
      this.setState(_state)
    }

    if (prevProps.channelsPending === true && this.props.channelsPending === false) {
      const channelId = parseInt(this.props.match.params.channel_id, 10)
      _state.channelInfo = this.props.channels.find(channel => channel.channelId === channelId)
      this.setState(_state)
    }

    if (prevProps.userChannelAccessPending === true && this.props.userChannelAccessPending === false) {
      if (_locationState !== undefined && _locationState.scrollToPostId !== undefined) {
        const post = document.querySelector(
          ".restore-" + _locationState.scrollToPostId
        )
        if (post) {
          setTimeout(() => {
            post.scrollIntoView({ block: "center" })
          }, 1)
        }
      }
    }

    if (prevProps.featureAccessPending === true && this.props.featureAccessPending === false) {
      if (!hasNewsAccess(this.props.featureAccess)) {
        return <Redirect to="/profile" />
      }
    }
  }


  componentWillUnmount() {
    window.removeEventListener('scroll', this.onPageScroll)
    // this.props.resetState()
  }

  loadMorePostsButtonClick(){
    const channelId = this.props.match.params.channel_id
    this.props.getChannelPosts(channelId, lastPostId);
  }

  cancelPostAction(evt) {
    evt.stopPropagation()
    const _state = this.state
    _state.modal = {
      showModal: false,
      modalTitle: '',
      modalText: '',
      showCancelAction: false,
      modalAcceptButtonText: '',
      modalCancelButtonText: '',
      modalAcceptAction: null,
      modalCancelAction: null,
    }
    this.setState(_state)
  }

  onPostClick(post) {
    const { pathname } = this.props.history.location
    const { isNewPost } = this.state
    if (isNewPost[post.postId] === true) {
      const newPosts = getNewPosts()
      setNewPosts(newPosts - 1)
      isNewPost[post.postId] = false
    }
    this.props.history.push({
      pathname: `${pathname}/post/${post.postId}`,
      state: {
        postInfo: post,
        isNewPost
      }
    });
  }
  
  renderChannelPosts(channelPosts) {
    const { isNewPost } = this.state
    const { userChannelAccess, profileImages } = this.props
    return (
      <div className="channel-posts-list">
        {
          channelPosts.map((post) => {
            return (
              <div
                key={post.postId}
                className={`restore-${post.postId}`}
                onClick={() => this.onPostClick(post)}
              >
                <PostCardDetails
                  postInfo={post}
                  newPost={isNewPost[post.postId] !== undefined ? isNewPost[post.postId] : false}
                  preview={true}
                  history={this.props.history}
                  userAccess={userChannelAccess}
                  userAvatar={profileImages.find(obj => obj.personId === post.personId)}
                />
              </div>
            )
          })
        }
        {
          !channelPosts.length &&
          <div>{t('no_available_post')}</div>
        }
      </div >
    )
  }

  addNewPostClick() {
    const { pathname } = this.props.history.location
    this.props.history.push(`${pathname}/new_post`)
  }

  returnToNews() {
    this.props.resetState()
    this.props.history.push({
      pathname: '/news'
    })
  }

  channelActionsClick(evt) {
    evt.stopPropagation()
    this.setState({ channelActionDropdown: !this.state.channelActionDropdown })
  }

  watchChannelClick(evt) {
    evt.stopPropagation()
    const _state = this.state
    _state.modal = {
      showModal: true,
      showCancelAction: true,
      modalAcceptButtonText: t('ok'),
      modalCancelButtonText: t('confirm_popup_cancel_button'),
      modalAcceptAction: this.watchChannelAction,
      modalCancelAction: this.cancelPostAction,
      modalTitle: _state.channelInfo.isWatching ? t('disable_watch') : t('enable_watch'),
      modalText: _state.channelInfo.isWatching ? t('unwatch_message') : t('watch_channel_message'),
    }
    this.setState(_state)
  }

  watchChannelAction() {
    const _state = this.state
    const { channelInfo } = this.state
    _state.channelActionDropdown = !_state.channelActionDropdown
    _state.modal = {
      showModal: false,
      modalTitle: '',
      modalText: '',
      showCancelAction: false,
      modalAcceptButtonText: '',
      modalCancelButtonText: '',
      modalAcceptAction: null,
      modalCancelAction: null,
    }
    this.setState(_state)
    this.props.watchChannelAction(channelInfo.channelId, channelInfo.isWatching)
  }

  render() {
    const { channelInfo, channelPosts, modal, channelActionDropdown } = this.state

    const {
      channelError, channelPending, channelsPending, removePostPending,
      userChannelAccessPending, userChannelAccess, userChannelAccessError,
      featureAccessPending, watchPostPending, postModerationItemPending,
      moderationReasonsPending, userReactionsPending, profileImagesPending,
      watchChannelPending,
    } = this.props

    if ((channelPending && lastPostId === -1) || channelsPending || removePostPending
      || userChannelAccessPending || featureAccessPending || watchPostPending
      || postModerationItemPending || moderationReasonsPending || watchChannelPending
      || (userReactionsPending && lastPostId === -1) || profileImagesPending) {
      return <Preloader />
    }

    if (Object.keys(channelError).length) {
      if (channelError.status === 403 || channelError.status === 400) {
        this.props.history.push('/not-found')
      }
    }
    if (Object.keys(userChannelAccessError).length) {
      if (userChannelAccessError.status === 403 || userChannelAccessError.status === 400) {
        this.props.history.push('/not-found')
      }
    }

    if (userChannelAccess.canView === false) {
      return (
        <div className="channel-container">
          <div className="channel-header text-center">
            <div>{t('cant_view_channel').replace('[title]', channelInfo.title)}</div>
          </div>
        </div >
      )
    }
    return (
      <div className="channel-container">
        <div className="row">
          <div className="col channel-header">{channelInfo.title}</div>
          <div className="col-2 d-flex align-items-center justify-content-end">
            <i
              onClick={this.returnToNews}
              className="material-icons arrow_back cursor-pointer other-avail-icon"
            >
              arrow_back
            </i>
            {userChannelAccess && getUserChannelAccess(userChannelAccess, 'create') &&
              <i onClick={this.addNewPostClick} className="material-icons add_box cursor-pointer other-avail-icon ml-4">add_box</i>
            }

            {channelInfo.isWatching && <i className="ml-3 material-icons remove-red-eye">remove_red_eye</i>}

            <div className="dropleft post-actions">
              <div
                className="post-actions-button"
                onClick={this.channelActionsClick}
              >
                <img src={commentActionsImg} alt="Comment actions" />
              </div>
              <div className={`dropdown-menu ${channelActionDropdown ? 'show' : ''}`} aria-labelledby="dropdownMenu2">
                <button
                  className="dropdown-item"
                  type="button"
                  onClick={this.watchChannelClick}
                >
                  {channelInfo.isWatching ? t('disable_watch') : t('enable_watch')}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div>
          {this.renderChannelPosts(channelPosts)}
        </div>
        <div className="row flex-column align-items-center">
          <div className="col-12 d-flex justify-content-center load-posts">
            <input type='button' onClick={() => this.loadMorePostsButtonClick()} className="btn btn-link" value={t('load_more_posts')} />
          </div>
        </div>

        <ActionModal
          {...modal}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    channelPending: state.channelReducer.channelPending[lastPostId],
    channelError: state.channelReducer.channelError,
    channelPosts: state.channelReducer.channelPosts,
    lastReceivedChannelPosts: state.channelReducer.lastReceivedChannelPosts,
    channelsPending: state.newsReducer.channelsPending,
    userChannelAccess: state.channelReducer.userChannelAccess,
    userChannelAccessPending: state.channelReducer.userChannelAccessPending,
    userChannelAccessError: state.channelReducer.userChannelAccessError,
    channels: state.newsReducer.channels,
    removePostPending: state.channelReducer.removePostPending,
    featureAccess: state.featureAccessReducer.featureAccess,
    featureAccessPending: state.featureAccessReducer.featureAccessPending,
    watchPostPending: state.channelReducer.watchPostPending,
    watchChannelPending: state.newsReducer.watchChannelPending,
    watchChannelError: state.newsReducer.watchChannelError,
    postModerationItemPending: state.moderationReducer.postModerationItemPending,
    postModerationItemError: state.moderationReducer.postModerationItemError,
    moderationReasonsPending: state.moderationReducer.moderationReasonsPending,
    scrollToWatchedPostId: state.channelReducer.scrollToWatchedPostId,
    scrollToFlaggedPostId: state.channelReducer.scrollToFlaggedPostId,
    userReactionsPending: state.reactionsReducer.userReactionsPending['posts'],
    profileImages: state.profileReducer.profileImages,
    profileImagesPending: state.profileReducer.profileImagesPending['posts'],
    profileImagesError: state.profileReducer.profileImagesError,
    profileLocale: state.loginReducer.profileLocale,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getChannelPosts: (channelId, lastPostId) => getChannelPosts(channelId, lastPostId),
      getChannelAccess: (channelId) => getChannelAccess(channelId),
      getModerationReasons: () => getModerationReasons(),
      resetState: () => resetState(),
      getUserReactions: (postIds, category) => getUserReactions(postIds, category),
      watchChannelAction: (channelId, isWatching) => watchChannel(channelId, isWatching),
      postListOfProfileImages: (personIds, location) => postListOfProfileImages(personIds, location),
    },
    dispatch
  )
}

ChannelContainer.propTypes = {
  getChannelPosts: PropTypes.func,
  channelPending: PropTypes.bool,
  channelError: PropTypes.object,
  channelPosts: PropTypes.array,
  lastReceivedChannelPosts: PropTypes.array,
  channels: PropTypes.array,
  channelsPending: PropTypes.bool,
  removePostPending: PropTypes.bool,
  resetState: PropTypes.func,
  history: PropTypes.object,
  userChannelAccessPending: PropTypes.bool,
  userChannelAccessError: PropTypes.object,
  userChannelAccess: PropTypes.object,
  getChannelAccess: PropTypes.func,
  match: PropTypes.object,
  location: PropTypes.object,
  featureAccessPending: PropTypes.bool,
  featureAccess: PropTypes.object,
  watchPostPending: PropTypes.bool,
  postModerationItemPending: PropTypes.bool,
  getModerationReasons: PropTypes.func,
  moderationReasonsPending: PropTypes.bool,
  scrollToWatchedPostId: PropTypes.number,
  scrollToFlaggedPostId: PropTypes.number,
  postModerationItemError: PropTypes.object,
  getUserReactions: PropTypes.func,
  userReactionsPending: PropTypes.bool,
  watchChannelAction: PropTypes.func,
  watchChannelPending: PropTypes.bool,
  watchChannelError: PropTypes.object,
  postListOfProfileImages: PropTypes.func,
  profileImages: PropTypes.array,
  profileImagesPending: PropTypes.bool,
  profileImagesError: PropTypes.object,
  profileLocale: PropTypes.string,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChannelContainer)
