import React from 'react'
import PropTypes from 'prop-types'

import { formatNumber } from '../utils/numberUtils'
import { t } from '../../utils/i18n'
import {formatDate } from '../utils/datetimeUtils'
import { loggedInUserIsManager } from '../utils/localStorageUtils'

import './StoreData.scss'

export default function StoreData({ eventDetails, scheduleData }) {
  const isManagerOrStoreEventSupervisor = (eventDetails && eventDetails.supervisor) || loggedInUserIsManager();
  return (
    <div className="card store-data">
      <div className="card-body">
        <div className="section">
          <div className="row">
            <div className="col title">
              <span>
                {t('aph_calc_standard')}: <span className="boldText">{scheduleData.asetTargets !== undefined && scheduleData.asetTargets !== null ? formatNumber(scheduleData.asetTargets.standardAph) : ''}</span>
              </span>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="row">
            {isManagerOrStoreEventSupervisor === true &&
              <div className="col">
                <div>{t('prev_hours')}</div>
                <div>{formatNumber(scheduleData.storeData.previousHours) || 0}</div>
              </div>
            }
            <div className="col">
              <div>{t('authentication')} #</div>
              <div>{eventDetails.authorizationNumbers}</div>
            </div>
          </div>
        </div>

        {isManagerOrStoreEventSupervisor === true &&
        <div className="section">
          <div className="row">
            <div className="col">
              <div>{t('last_visit')}</div>
              <div>{formatDate(scheduleData.storeData.previousDate)}</div>
            </div>
          </div>
        </div>
        }

        {isManagerOrStoreEventSupervisor === true &&
          <div className="row">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th />
                  <th>{t('length')}</th>
                  <th>APH</th>
                  <th>{t('inventory_abbreviation')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="col">{t('previous')}</th>
                  <td>{formatNumber(scheduleData.storeData.previousLength) || 0}h</td>
                  <td>{formatNumber(scheduleData.storeData.previousAph) || 0}</td>
                  <td>{formatNumber(scheduleData.storeData.previousInventory) || 0}</td>
                </tr>
                <tr>
                  <th scope="col">{t('account')}</th>
                  <td>{formatNumber(scheduleData.storeData.customerLength) || 0}h</td>
                  <td>{formatNumber(scheduleData.storeData.customerAph) || 0}</td>
                  <td>{formatNumber(scheduleData.storeData.customerInventory) || 0}</td>
                </tr>
                <tr>
                  <th scope="col">{t('district_menu_item')}</th>
                  <td>{formatNumber(scheduleData.storeData.districtLength) || 0}h</td>
                  <td>{formatNumber(scheduleData.storeData.districtAph) || 0}</td>
                  <td>{formatNumber(scheduleData.storeData.districtInventory) || 0}</td>
                </tr>
              </tbody>
            </table>
          </div>
        }

        {isManagerOrStoreEventSupervisor === true &&
          <div className="row store-data-length">
            <div className="col">
              <div className="circle target-length">{formatNumber(scheduleData.storeData.targetLength) || 0}</div>
              <div>{t('target_length')}</div>
              <div>({t('hours_abbreviation')})</div>
            </div>
            <div className="col">
              <div className="circle max-length">{formatNumber(scheduleData.storeData.maxLength) || 0}</div>
              <div>{t('max_length')}</div>
              <div>({t('hours_abbreviation')})</div>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

StoreData.propTypes = {
  eventDetails: PropTypes.object,
  scheduleData: PropTypes.object,
}
