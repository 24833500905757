import {
        GET_TRANSPORTATION_TYPES_SUCCESS,
        GET_TRANSPORTATION_TYPES_ERROR,
        GET_TRANSPORTATION_TYPES_PENDING,
        EDITING_TRANSPORTATION_PROFILE,
        GET_TRANSPORTATION_PROFILE_SUCCESS,
        GET_TRANSPORTATION_PROFILE_ERROR,
        GET_TRANSPORTATION_PROFILE_PENDING,
        SAVE_TRANSPORTATION_PROFILE,
        BACKUP_TRANSPORTATION_PROFILE,
        PATCH_TRANSPORTATION_PROFILE_SUCCESS,
        PATCH_TRANSPORTATION_PROFILE_ERROR,
        PATCH_TRANSPORTATION_PROFILE_PENDING,
        SEARCH_RIDE_ALONG_TRANSPORTATION_PROFILE,
        GET_RIDE_ALONG_ERRORS_SUCCESS,
        GET_RIDE_ALONG_ERRORS_PENDING,
        GET_RIDE_ALONG_ERRORS_ERROR,
    } from '../actions/actionTypes'  
import { cloneDeep } from 'lodash'

    export default function transportationProfileReducer(
        state = {
            transportationTypes: [],
            transportationTypesError: '',
            transportationTypesPending: false,
            editingTransportationProfile: false,
            backupTransportationProfile: {},
            transportationProfile: {},
            transportationProfileError: '',
            transportationProfilePending: false,
            patchTransportationProfileError: '',
            patchTransportationProfilePending: false,
            searchRideAlongTransportationProfile: '',
            rideAlongErrors: [],
            rideAlongErrorsPending: false,
            rideAlongErrorsError: '',
        },
        action
    ) {
        switch (action.type) {
            case GET_TRANSPORTATION_TYPES_SUCCESS:
                return {
                    ...state,
                    transportationTypes: action.payload ? action.payload : [],
                    transportationTypesError: '',
                    transportationTypesPending: false,
                }
            case GET_TRANSPORTATION_TYPES_ERROR:
                return {
                    ...state,
                    transportationTypesError: action.error,
                }
            case GET_TRANSPORTATION_TYPES_PENDING:
                return {
                    ...state,
                    transportationTypesPending: true,
                }
            case EDITING_TRANSPORTATION_PROFILE:
                return {
                    ...state,
                    editingTransportationProfile: action.payload,
                }
            case GET_TRANSPORTATION_PROFILE_SUCCESS:
                return {
                    ...state,
                    transportationProfile: action.payload && action.payload !== ''  ? action.payload : {},
                    backupTransportationProfile: action.payload ? cloneDeep(action.payload) : {},
                    transportationProfileError: '',
                    transportationProfilePending: false,
                }
            case GET_TRANSPORTATION_PROFILE_ERROR:
                return {
                    ...state,
                    transportationProfileError: action.error,
                }
            case GET_TRANSPORTATION_PROFILE_PENDING:
                return {
                    ...state,
                    transportationProfilePending: action.payload,
                }
            case SAVE_TRANSPORTATION_PROFILE:
                return {
                    ...state,
                    transportationProfile: action.payload,
                }
            case BACKUP_TRANSPORTATION_PROFILE:
                return {
                    ...state,
                    backupTransportationProfile: cloneDeep(state.transportationProfile),
                }
            case SEARCH_RIDE_ALONG_TRANSPORTATION_PROFILE:
                return {
                    ...state,
                    searchRideAlongTransportationProfile: action.payload,
                }
            case PATCH_TRANSPORTATION_PROFILE_SUCCESS:
                return {
                    ...state,
                    transportationProfile: action.payload ? action.payload : {},
                    backupTransportationProfile: action.payload ? cloneDeep(action.payload) : {},
                    patchTransportationProfileError: '',
                    patchTransportationProfilePending: false,
                }
            case PATCH_TRANSPORTATION_PROFILE_ERROR:
                return {
                    ...state,
                    patchTransportationProfileError: action.error,
                }
            case PATCH_TRANSPORTATION_PROFILE_PENDING:
                return {
                    ...state,
                    patchTransportationProfilePending: action.pending,
                }
            case GET_RIDE_ALONG_ERRORS_SUCCESS:
                return {
                    ...state,
                    rideAlongErrors: action.payload ? action.payload : [],
                    rideAlongErrorsError: '',
                    rideAlongErrorsPending: false,
                }     
            case GET_RIDE_ALONG_ERRORS_ERROR:
                return {
                    ...state,
                    rideAlongErrorsError: action.error,
                }
            case GET_RIDE_ALONG_ERRORS_PENDING:
                return {
                    ...state,
                    rideAlongErrorsPending: action.pending,
                }       
            default:
                return state
            }
        }
  