import * as types from './actionTypes'
import services from '../services'

export function getEventDetails(scheduledEventId) {
  return dispatch => {
    dispatch(getEventDetailsActionPending(true))
    services.schedule
      .fetchScheduleDetailsByScheduleEventId({
        scheduledEventId,
      })
      .then(response => {
        dispatch(getEventDetailsActionSuccess(response))
        dispatch(getEventDetailsActionPending(false))
      })
      .catch(error => {
        dispatch(getEventDetailsActionError(error))
        dispatch(getEventDetailsActionPending(false))
      })
  }
}

const getEventDetailsActionSuccess = response => {
  return {
    type: types.GET_EVENT_DETAILS_SUCCESS,
    payload: response.data,
  }
}
const getEventDetailsActionError = response => {
  return {
    type: types.GET_EVENT_DETAILS_ERROR,
    error: response,
  }
}
const getEventDetailsActionPending = status => {
  return {
    type: types.GET_EVENT_DETAILS_PENDING,
    pending: status,
  }
}

export function getEventAph(scheduledEventId) {
  return dispatch => {
    dispatch(getEventAphPending(true))
    services.schedule
      .fetchScheduleAphByScheduleEventId(scheduledEventId)
      .then(response => {
        dispatch(getEventAphSuccess(response))
        dispatch(getEventAphPending(false))
      })
      .catch(error => {
        dispatch(getEventAphError(error))
        dispatch(getEventAphPending(false))
      })
  }
}

const getEventAphSuccess = response => {
  return {
    type: types.GET_EVENT_APH_SUCCESS,
    payload: response.data,
  }
}
const getEventAphError = response => {
  return {
    type: types.GET_EVENT_APH_ERROR,
    error: response,
  }
}
const getEventAphPending = status => {
  return {
    type: types.GET_EVENT_APH_PENDING,
    status,
  }
}

export function getEventDetailsList(scheduledEventIds) {
  return dispatch => {
    dispatch(getEventDetailsListActionPending(true))
    services.schedule
      .getEventDetailsList(scheduledEventIds)
      .then(response => {
        dispatch(getEventDetailsListActionSuccess(response))
        dispatch(getEventDetailsListActionPending(false))
      })
      .catch(error => {
        dispatch(getEventDetailsListActionPending(false))
        dispatch(getEventDetailsListActionError(error))
      })
  }
}

const getEventDetailsListActionSuccess = response => {
  return {
    type: types.GET_EVENT_DETAILS_LIST_SUCCESS,
    payload: response.data,
  }
}
const getEventDetailsListActionError = response => {
  return {
    type: types.GET_EVENT_DETAILS_LIST_ERROR,
    error: response,
  }
}
const getEventDetailsListActionPending = status => {
  return {
    type: types.GET_EVENT_DETAILS_LIST_PENDING,
    payload: status,
  }
}

export function getEventMeetSite(scheduledEventId) {
  return dispatch => {
    dispatch(getEventMeetSitePending(true))
    services.schedule
      .fetchScheduleMeetSiteByScheduleEventId(scheduledEventId)
      .then(response => {
        dispatch(getEventMeetSiteSuccess(response))
        dispatch(getEventMeetSitePending(false))
      })
      .catch(error => {
        dispatch(getEventMeetSiteError(error))
        dispatch(getEventMeetSitePending(false))
      })
  }
}

const getEventMeetSiteSuccess = response => {
  return {
    type: types.GET_EVENT_MEET_SITE_SUCCESS,
    payload: response.data,
  }
}
const getEventMeetSiteError = response => {
  return {
    type: types.GET_EVENT_MEET_SITE_ERROR,
    error: response,
  }
}
const getEventMeetSitePending = status => {
  return {
    type: types.GET_EVENT_MEET_SITE_PENDING,
    status,
  }
}
