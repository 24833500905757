import { createStore, applyMiddleware, compose } from 'redux'
import logger from 'redux-logger'
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import { createBrowserHistory as createHistory } from 'history'
import rootReducer from '../reducers'

export const history = createHistory()

const initialState = {}
const enhancers = []
const middleware = [thunk, routerMiddleware(history)]

/*
 * If Development Environment: enable dev tools, immutable state check, and logging
 */
if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__
  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
    middleware.push(reduxImmutableStateInvariant())
    middleware.push(logger)
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
)

const configureStore = () => {
  const store = createStore(connectRouter(history)(rootReducer), initialState, composedEnhancers)

  // If not production, allow for hot reloading
  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('../reducers', () => {
        store.replaceReducer(rootReducer)
      })
    }
  }

  return store
}

export default configureStore
