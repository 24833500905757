import * as types from './actionTypes'
import services from '../services'


export function getChatItemByScheduledStoreId(scheduledStoreId) {
  return (dispatch) => {
    dispatch(getChatItemPending(true))

    services.eventChat
      .fetchChatItem(scheduledStoreId)
      .then(response => {
        dispatch(getChatItemSuccess(response.data))
        dispatch(getChatItemPending(false))
      })
      .catch(error => {
        dispatch(getchatItemError(error))
        dispatch(getChatItemPending(false))
      })
  }
}
const getChatItemSuccess = response => {
  return {
    type: types.GET_CHAT_ITEM_SUCCESS,
    payload: response,
  }
}
const getchatItemError = response => {
  return {
    type: types.GET_CHAT_ITEM_ERROR,
    error: response,
  }
}
const getChatItemPending = status => {
  return {
    type: types.GET_CHAT_ITEM_PENDING,
    status,
  }
}

export function refreshChatMessages(postCount) {
  return (dispatch, getState) => {
    const { chatItem } = getState().eventChatReducer
    dispatch(refreshChatMessagesPending(true))
    services.eventChat
      .fetchChatMessages(chatItem.channelId, -1, postCount)
      .then(response => {
        dispatch(refreshChatMessagesSuccess(response))
        dispatch(refreshChatMessagesPending(false))
      })
      .catch(error => {
        dispatch(refreshChatMessagesError(error))
        dispatch(refreshChatMessagesPending(false))
      })
  }
}
const refreshChatMessagesSuccess = response => {
  return {
    type: types.REFRESH_CHAT_MESSAGES_SUCCESS,
    payload: response.data,
  }
}
const refreshChatMessagesError = response => {
  return {
    type: types.REFRESH_CHAT_MESSAGES_ERROR,
    error: response,
  }
}
const refreshChatMessagesPending = status => {
  return {
    type: types.REFRESH_CHAT_MESSAGES_PENDING,
    status,
  }
}

export function getChatMessages(firstMessageId) {
  return (dispatch, getState) => {
    const { chatItem, noOfLoadingMessages } = getState().eventChatReducer
    dispatch(getChatMessagesPending(true, firstMessageId))
    services.eventChat
      .fetchChatMessages(chatItem.channelId, firstMessageId, noOfLoadingMessages)
      .then(response => {
        dispatch(getChatMessagesSuccess(response))
        dispatch(getChatMessagesPending(false))
      })
      .catch(error => {
        dispatch(getChatMessagesError(error))
        dispatch(getChatMessagesPending(false))
      })
  }
}
const getChatMessagesSuccess = response => {
  return {
    type: types.GET_CHAT_MESSAGES_SUCCESS,
    payload: response.data,
  }
}
const getChatMessagesError = response => {
  return {
    type: types.GET_CHAT_MESSAGES_ERROR,
    error: response,
  }
}
const getChatMessagesPending = status => {
  return {
    type: types.GET_CHAT_MESSAGES_PENDING,
    status,
  }
}

export function addChatMessage(messageObj) {
  return (dispatch, getState) => {
    const { channelId } = getState().eventChatReducer.chatItem
    dispatch(postChatMessagePending(true))
    services.eventChat
      .postChatMessage({ channelId, ...messageObj })
      .then(response => {
        dispatch(postChatMessageSuccess(response))
        dispatch(postChatMessagePending(false))
      })
      .catch(error => {
        dispatch(postChatMessageError(error))
      })
  }
}
const postChatMessageSuccess = response => {
  return {
    type: types.POST_CHAT_MESSAGE_SUCCESS,
    payload: response.data,
  }
}
const postChatMessageError = response => {
  return {
    type: types.POST_CHAT_MESSAGE_ERROR,
    error: response,
  }
}
const postChatMessagePending = status => {
  return {
    type: types.POST_CHAT_MESSAGE_PENDING,
    status,
  }
}


export function editChatMessage(messageObj) {
  return (dispatch, getState) => {
    const { channelId } = getState().eventChatReducer.chatItem
    dispatch(editChatMessagePending(true))
    services.eventChat
      .putChatMessage({ channelId, ...messageObj })
      .then(response => {
        dispatch(editChatMessageSuccess(response))
        dispatch(editChatMessagePending(false))
      })
      .catch(error => {
        dispatch(editChatMessageError(error))
      })
  }
}
const editChatMessageSuccess = response => {
  return {
    type: types.EDIT_CHAT_MESSAGE_SUCCESS,
    payload: response.data,
  }
}
const editChatMessageError = response => {
  return {
    type: types.EDIT_CHAT_MESSAGE_ERROR,
    error: response,
  }
}
const editChatMessagePending = status => {
  return {
    type: types.EDIT_CHAT_MESSAGE_PENDING,
    status,
  }
}

export function deleteChatMessage(messageId) {
  return dispatch => {
    dispatch(deleteChatMessageActionPending(true))
    services.eventChat
      .deleteChatMessage(messageId)
      .then(response => { //eslint-disable-line
        dispatch(deleteChatMessageActionSuccess(messageId))
        dispatch(deleteChatMessageActionPending(false))
      })
      .catch(error => {
        dispatch(deleteChatMessageActionError(error))
      })
  }
}
const deleteChatMessageActionSuccess = messageId => {
  return {
    type: types.DELETE_CHAT_MESSAGE_SUCCESS,
    messageId,
  }
}
const deleteChatMessageActionError = response => {
  return {
    type: types.DELETE_CHAT_MESSAGE_ERROR,
    error: response,
  }
}
const deleteChatMessageActionPending = status => {
  return {
    type: types.DELETE_CHAT_MESSAGE_PENDING,
    status,
  }
}

export function resetState() {
  return dispatch => {
    dispatch({ type: types.RESET_CHAT_MESSAGES_STATE })
  }
}

export function resetChatItem() {
  return dispatch => {
    dispatch({ type: types.RESET_CHAT_ITEM_STATE })
  }
}
