import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import PropTypes from 'prop-types'
import { t } from '../../utils/i18n'
import DatePicker from 'react-datepicker'
import { cloneDeep } from 'lodash'
import {
  setBackupOtherAvailability,
  setEditOtherAvailabilityRecord,
  setNewOtherAvailabilityRecord,
  setCurrentOtherAvailabilityRecord,
  setOtherAvailability,
  patchOtherAvailability
} from '../../state/actions/otherAvailabilityActions'
import { 
  formatDate, 
  getLocale, 
  getCurrentLongDateFormatForDatePicker, 
  formatTime, 
  formatDateNotForDisplay, 
  timeAIsAfterTimeB,
  timeAIsBetweenTimeBAndTimeC,
  timeAIsSameAsTimeB,
  dateAIsAfterDateB,
} from '../utils/datetimeUtils'

import ActionModal from '../ActionModal/ActionModal.js';
import './OtherAvailabilityList.scss'

const CUSTOM_AVAILABILITY_TYPE_ID = 1
const ANY_AVAILABILITY_TYPE_ID = 2
const NO_AVAILABILITY_TYPE_ID = 3

class OtherAvailabilityList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showActionModal: false,
      availabilityListItem: {},
      dayAvailability: {},
    }

    this.handleExcludeNightPriorFromDayOff = this.handleExcludeNightPriorFromDayOff.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  componentDidMount() {
    this.createBackup()
  }

  restoreFromBackup = () => {
    this.setOtherAvailability(cloneDeep(this.props.backupOtherAvailability))
  }

  createBackup = () => {
    this.props.setBackupOtherAvailability()
  }

  handleEditOtherAvailabilityOnClick = () => {
    this.createBackup()

    this.setEditOtherAvailabilityRecord(true)
  }

  setEditOtherAvailabilityRecord = (editing) => {
    this.props.setEditOtherAvailabilityRecord(editing)
  }

  setNewOtherAvailabilityRecord = (isNew) => {
    this.props.setNewOtherAvailabilityRecord(isNew)
  }

  patchOtherAvailability = (otherAvailability) => {
    this.createBackup()

    this.props.patchOtherAvailability(otherAvailability)
  }

  getEditOtherAvailabilityLink = () => {
    return (
      <a onClick={this.handleEditOtherAvailabilityOnClick} className="other-availability-link uppercase cursor-pointer">{t('edit')}</a> /* eslint-disable-line */
    )
  }

  handleCancelEditOtherAvailabilityOnClick = () => {
    this.restoreFromBackup()

    this.setEditOtherAvailabilityRecord(false)
  }

  getCancelEditOtherAvailabilityLink = () => {
    return (
      <a onClick={this.handleCancelEditOtherAvailabilityOnClick} className="other-availability-link uppercase cursor-pointer">{t('cancel')}</a> /* eslint-disable-line */
    )
  }

  getAvailabilityList = () => {
    const { startDate, endDate } = this.props.currentOtherAvailabilityRecord
    let newAvailabilityList = []

    if (startDate === null || endDate === null) {
      return newAvailabilityList
    }

    let startMoment = moment(startDate)
    let endMoment = moment(endDate)

    for (let i = 0; i < this.props.otherAvailability.length; i++) {
      let currentMoment = moment(this.props.otherAvailability[i].scheduleDate)

      if (currentMoment.isSameOrAfter(startMoment) && currentMoment.isSameOrBefore(endMoment)) {
        newAvailabilityList.push(
          cloneDeep(this.props.otherAvailability[i])
        )
      }
    }

    return newAvailabilityList
  }

  getReadOnlyOtherAvailabilityModalMarkUp = () => {
    return (
      <div>
        <div className="row">
          <div className="col general-title">
            {this.getReadOnlyDateRangeMarkUpWithEdit()}
          </div>
        </div>
        <div>
          {this.getAvailabilityList().map(this.getAvailableListItemReadOnlyMarkUp)}
        </div>
      </div>
    )
  }

  getReadOnlyDateRangeMarkUpWithEdit = () => {
    const { startDate, endDate } = this.props.currentOtherAvailabilityRecord
    if (startDate && endDate) {
      return (
        <div>
          {formatDate(startDate)} - {formatDate(endDate)} | {this.getEditOtherAvailabilityLink()}
        </div>
      )
    }
    return ''
  }

  getReadOnlyDateRangeMarkUpWithCancel = () => {
    const { startDate, endDate } = this.props.currentOtherAvailabilityRecord
    if (startDate && endDate) {
      return (
        <div>
          {formatDate(startDate)} - {formatDate(endDate)} | {this.getCancelEditOtherAvailabilityLink()}
        </div>
      )
    }
    return ''
  }

  handleStartDateChange = (date) => {
    const { startDate, endDate } = this.props.currentOtherAvailabilityRecord
    let formattedStartDate = formatDateNotForDisplay(date)

    let newOtherAvailability = this.props.cloneOtherAvailabilityExceptInRange(startDate, endDate)
    this.setOtherAvailability(newOtherAvailability)

    if (moment(formattedStartDate).isSameOrBefore(moment(endDate))) {
      this.props.addOtherAvailabilityDateRange(
        formattedStartDate,
        endDate)
    }

    let newRecord = {
      endDate: endDate,
      startDate: formattedStartDate
    }

    this.setCurrentOtherAvailabilityRecord(newRecord)
  }

  handleEndDateChange = (date) => {
    const { startDate, endDate } = this.props.currentOtherAvailabilityRecord
    let formattedEndDate = formatDateNotForDisplay(date)

    let newOtherAvailability = this.props.cloneOtherAvailabilityExceptInRange(startDate, endDate)
    this.setOtherAvailability(newOtherAvailability)

    if (moment(formattedEndDate).isSameOrAfter(moment(startDate))) {
      this.props.addOtherAvailabilityDateRange(
        startDate,
        formattedEndDate)
    }

    let newRecord = {
      endDate: formattedEndDate,
      startDate: startDate
    }

    this.setCurrentOtherAvailabilityRecord(newRecord)
  }

  getEditDateRangeStartDate = () => {
    let startDate = moment(this.props.publishedWeek.endDate).add(14, 'd').toDate()
    return startDate
  }

  getEditDateRangeEndDate = () => {
    let endDate = moment(this.getEditDateRangeStartDate()).add(365, 'd').toDate()
    return endDate
  }

  getEditDateRangeMarkUp = () => {
    const { startDate, endDate } = this.props.currentOtherAvailabilityRecord
    const locale = getLocale() 
    const editStatusDateState = formatDate(moment())
    const dateFormat = getCurrentLongDateFormatForDatePicker()
    return (
      <div>
        <div>
          {t('start_date')}
        </div>
        <div>
          <DatePicker
            selected={startDate !== null ? moment(startDate).toDate() : null}
            startDate={this.getEditDateRangeStartDate()}
            maxDate={this.getEditDateRangeEndDate()}
            minDate={this.getEditDateRangeStartDate()}
            dateFormat={dateFormat}
            locale={locale}
            onChange={this.handleStartDateChange}
            publishedWeek={this.props.publishedWeek}
            withPortal
          >
            <div className="header-calendar">
              <div className="selected-year">{moment().year()}</div>
              <div className="selected-date">{editStatusDateState}</div>
            </div>
          </DatePicker>
        </div>
        <div>{t('end_date')}</div>
        <div>
          <DatePicker
            selected={endDate !== null ? moment(endDate).toDate() : null}
            startDate={this.getEditDateRangeStartDate()}
            maxDate={this.getEditDateRangeEndDate()}
            minDate={this.getEditDateRangeStartDate()}
            dateFormat={dateFormat}
            locale={locale}
            onChange={this.handleEndDateChange}
            publishedWeek={this.props.publishedWeek}
            withPortal
          >
            <div className="header-calendar">
              <div className="selected-year">{moment().year()}</div>
              <div className="selected-date">{editStatusDateState}</div>
            </div>
          </DatePicker>
        </div>
      </div>
    )
  }

  getDateInputValueFormat = (dateValue) => {
    return dateValue.replace('/', '-')
  }

  isFormDataValid = (newAvailability) => {
    const { startDate, endDate } = this.props.currentOtherAvailabilityRecord

    if (startDate === null || endDate === null) {
      return false
    }

    if (dateAIsAfterDateB(startDate, endDate)) { 
      alert(t('invalid_date_range'))
      return false 
    }

    for (let i = 0; i < newAvailability.length; i++) {
      if (newAvailability[i].availableTimes === null) {
        return false
      }
      let currentMoment = moment(newAvailability[i].scheduleDate)
      if (currentMoment.isSameOrBefore(moment(endDate)) && currentMoment.isSameOrAfter(moment(startDate))) {
        for (let j = 0; j < newAvailability[i].availableTimes.length; j++) {
          // check if start time is greater than end time
          if (timeAIsAfterTimeB(newAvailability[i].availableTimes[j].startTime, newAvailability[i].availableTimes[j].endTime)) { 
            alert(t('error_start_time_greater_then_end_time') + formatDate(newAvailability[i].scheduleDate))
            return false
          }

          if (j > 0) { // check for overlap
            if (timeAIsBetweenTimeBAndTimeC(newAvailability[i].availableTimes[j].startTime, 
                                            newAvailability[i].availableTimes[0].startTime, 
                                            newAvailability[i].availableTimes[0].endTime)
              || timeAIsBetweenTimeBAndTimeC(newAvailability[i].availableTimes[0].endTime,
                                            newAvailability[i].availableTimes[0].startTime,
                                            newAvailability[i].availableTimes[0].endTime)
              || timeAIsSameAsTimeB(newAvailability[i].availableTimes[j].startTime, newAvailability[i].availableTimes[0].startTime) 
              || timeAIsSameAsTimeB(newAvailability[i].availableTimes[j].startTime, newAvailability[i].availableTimes[0].endTime) 
              || timeAIsSameAsTimeB(newAvailability[i].availableTimes[j].endTime, newAvailability[i].availableTimes[0].startTime) 
              || timeAIsSameAsTimeB(newAvailability[i].availableTimes[j].endTime, newAvailability[i].availableTimes[0].endTime)) {
              alert(t('error_overlapping_time_ranges') + formatDate(newAvailability[i].scheduleDate))
              return false
            }
          }
        }
      }
    }

    return true
  }

  handleSaveOnClick = () => {
    let newOtherAvailability = cloneDeep(this.props.otherAvailability)
    let availabilityList = this.getAvailabilityList()
    for (let i = 0; i < availabilityList.length; i++) {
      for (let j = 0; j < newOtherAvailability.length; j++) {
        if (newOtherAvailability[j].scheduleDate === availabilityList[i].scheduleDate) {
          if (availabilityList[i].availableTimes === null) {
            return
          }
          newOtherAvailability[j].availableTimes = availabilityList[i].availableTimes.length === 0
            ? [] : availabilityList[i].availableTimes.slice(0)
        }
      }
    }
    newOtherAvailability = newOtherAvailability.filter(item => item.availableTimes !== null)

    if (this.isFormDataValid(newOtherAvailability)) {
      this.setOtherAvailability(newOtherAvailability)
      this.patchOtherAvailability(this.props.addSecondsToEndTime(newOtherAvailability))
      this.setEditOtherAvailabilityRecord(false)
      this.props.handleCloseOtherAvailabilityListModalCloseClick(false)
    }
  }

  getAddNewMarkUp = () => {
    const { startDate, endDate } = this.props.currentOtherAvailabilityRecord
    return (
      <div>
        <div className="row">
          <div className="col general-title">
            {this.getEditDateRangeMarkUp()}
          </div>
        </div>
        {startDate !== null && endDate !== null &&
          <div className="row">
            <div className="col general-title">
              {t('please_set_availability')}
            </div>
          </div>
        }
        <div>
          {this.getAvailabilityList().map(this.getAvailableListItemEditMarkUp)}
        </div>
        <div>
          <input type='button' onClick={this.handleSaveOnClick} className="btn-rgis-blue cursor-pointer uppercase" value={t('save')} />
        </div>
      </div>
    )
  }

  getEditExistingMarkUp = () => {
    return (
      <div>
        <div className="row">
          <div className="col general-title">
            {this.getReadOnlyDateRangeMarkUpWithCancel()}
          </div>
        </div>
        <div>
          {this.getAvailabilityList().map(this.getAvailableListItemEditMarkUp)}
        </div>
        <div>
          <input type='button' onClick={this.handleSaveOnClick} className="btn-rgis-blue cursor-pointer uppercase" value={t('save')} />
        </div>
      </div>
    )
  }

  getEditOtherAvailabilityModalMarkUp = () => {
    return (
      <div>
        {this.props.newOtherAvailabilityRecord ? this.getAddNewMarkUp() : this.getEditExistingMarkUp()}
      </div>
    )
  }

  getAvailableListItemEditMarkUp = (item) => {
    return (
      <div key={item.scheduleDate} className="row other-avail-list-item">
        <div className="col">
          {formatDate(item.scheduleDate)}
        </div>
        <div className="col">
          {this.getOtherAvailabilityTimeRangeEditMarkUp(item)}
        </div>
      </div>
    )
  }

  handleAddPeriodOnClick = (item) => {
    let newDailyAvailabilityItem = { ...item }
    let newOtherAvailability = cloneDeep(this.props.otherAvailability)

    for (let i = 0; i < newOtherAvailability.length; i++) {
      if (newOtherAvailability[i].scheduleDate === newDailyAvailabilityItem.scheduleDate) {
        let newAvailableTimes = newOtherAvailability[i].availableTimes.slice(0)
        newOtherAvailability[i].availableTimes = newAvailableTimes.concat([{ startTime: "00:00:00", endTime: "00:29:00" }])
      }
    }

    this.setOtherAvailability(newOtherAvailability)
  }

  getAddPeriodButtonMarkUp = (item) => {
    if (this.getAvailabilityTypeId(item.availableTimes) === CUSTOM_AVAILABILITY_TYPE_ID && item.availableTimes.length < 2) {
      return (
        <i onClick={() => this.handleAddPeriodOnClick(item)} className="material-icons add_box cursor-pointer other-avail-icon">add_box</i>
      )
    } else {
      return ''
    }
  }

  getAvailabilityTypeTextMarkUp = (availabilityTypeId) => {
    if (availabilityTypeId === ANY_AVAILABILITY_TYPE_ID) {
      return (<div>{t('any')}</div>)
    } else if (availabilityTypeId === CUSTOM_AVAILABILITY_TYPE_ID) {
      return (<div>{t('custom')}</div>)
    } else if (availabilityTypeId === NO_AVAILABILITY_TYPE_ID) {
      return (<div>{t('not_available')}</div>)
    }

    return (<div></div>)
  }

  handleAvailabilityTypeOnChange = (event, item) => {
    let newDailyAvailabilityItem = { ...item }
    let newOtherAvailability = cloneDeep(this.props.otherAvailability)

    for (let i = 0; i < newOtherAvailability.length; i++) {
      if (newOtherAvailability[i].scheduleDate === newDailyAvailabilityItem.scheduleDate) {
        newOtherAvailability[i].availableTimes = this.getAvailableTimesByAvailabilityTypeId(event.target.value)
      }
    }
    if (parseInt(event.target.value) === NO_AVAILABILITY_TYPE_ID) {
      this.setState({
        showActionModal: true,
        availabilityListItem: newDailyAvailabilityItem,
      })
    }
    this.setState({
      dayAvailability: {
        ...this.state.dayAvailability,
        [newDailyAvailabilityItem.scheduleDate]: parseInt(event.target.value)
      }
    })
    this.setOtherAvailability(newOtherAvailability)
  }

  setCurrentOtherAvailabilityRecord = (newOtherAvailabilityRecord) => {
    this.props.setCurrentOtherAvailabilityRecord(newOtherAvailabilityRecord)
  }

  getAvailableTimesByAvailabilityTypeId = (availabilityTypeId) => {
    let availableTimes = []

    if (parseInt(availabilityTypeId) === ANY_AVAILABILITY_TYPE_ID) {
      availableTimes.push({
        startTime: '00:00:00',
        endTime: '23:59:00'
      })
    } else if (parseInt(availabilityTypeId) === CUSTOM_AVAILABILITY_TYPE_ID) {
      availableTimes.push({
        startTime: '00:00:00',
        endTime: '00:29:00'
      })
    }

    return availableTimes
  }

  getAvailabilityTypeMarkUp = (availabilityTypeId, item) => {
    const { dayAvailability } = this.state
    return (
      <select defaultValue={0} value={dayAvailability[item.scheduleDate]} onChange={(event) => this.handleAvailabilityTypeOnChange(event, item)}>
        <option value={0} disabled>{`--${t('select_availability')}--`}</option>
        <option value={NO_AVAILABILITY_TYPE_ID}>{t('not_available')}</option>
        <option value={ANY_AVAILABILITY_TYPE_ID}>{t('any')}</option>
        <option value={CUSTOM_AVAILABILITY_TYPE_ID}>{t('custom')}</option>
      </select>
    )
  }

  getAvailabilityTypeId = (availableTimes) => {
    let availabilityTypeId = NO_AVAILABILITY_TYPE_ID

    if (availableTimes === null) {
      availabilityTypeId = 0
    } else if (this.isTimeRangeNotAvailable(availableTimes)) {
      availabilityTypeId = NO_AVAILABILITY_TYPE_ID
    } else if (availableTimes.length === 1) {
      let startTime = formatTime(availableTimes[0].startTime)
      let endTime = formatTime(availableTimes[0].endTime)

      if (this.isTimeRangeAny(startTime, endTime)) {
        availabilityTypeId = ANY_AVAILABILITY_TYPE_ID
      } else {
        availabilityTypeId = CUSTOM_AVAILABILITY_TYPE_ID
      }
    } else {
      availabilityTypeId = CUSTOM_AVAILABILITY_TYPE_ID
    }

    return availabilityTypeId
  }

  getOtherAvailabilityTimeRangeEditMarkUp = (item) => {
    let availabilityTypeId = this.getAvailabilityTypeId(item.availableTimes)

    return (
      <div>
        <div className='row'>
          <div className='col'>{this.getAvailabilityTypeMarkUp(availabilityTypeId, item)} {this.getAddPeriodButtonMarkUp(item)}</div>
        </div>
        <div className='row'>
          <div className='col'>
            {availabilityTypeId !== 0 && item.availableTimes.map((timeRange, index) => {
              timeRange.endTime = this.zeroOutSeconds(timeRange.endTime)
              return this.getEditTimeRangeMarkUp(timeRange, item, index)
            })}
          </div>
        </div>
      </div>
    )
  }

  zeroOutSeconds = (timeToZeroOut) => {
    if (!timeToZeroOut) { return timeToZeroOut }

    let splitTime = timeToZeroOut.split(':')

    if (splitTime.length !== 3) { return timeToZeroOut }

    return splitTime[0] + ':' + splitTime[1] + ':00'
  }

  setOtherAvailability = (otherAvailability) => {
    this.props.setOtherAvailability(otherAvailability)
  }

  handleTimeOnChange = (item, event, timeRangeIndex, isStartTime) => {
    let newDailyAvailabilityItem = { ...item }
    let newOtherAvailability = cloneDeep(this.props.otherAvailability)

    for (let i = 0; i < newOtherAvailability.length; i++) {
      if (newOtherAvailability[i].scheduleDate === newDailyAvailabilityItem.scheduleDate) {
        if (isStartTime) {
          newOtherAvailability[i].availableTimes[timeRangeIndex].startTime = event.target.value
        } else {
          newOtherAvailability[i].availableTimes[timeRangeIndex].endTime = event.target.value
        }
        break;
      }
    }

    this.setOtherAvailability(newOtherAvailability)
  }

  // TODO: Write method to generate time options
  getEditTimeRangeMarkUp = (timeRange, item, timeRangeIndex) => {
    return (
      <div key={item.scheduleDate}>
        <select onChange={(event) => this.handleTimeOnChange(item, event, timeRangeIndex, true)} value={timeRange.startTime}>
          <option value='00:00:00'>{formatTime('12:00 AM')}</option>
          <option value='00:30:00'>{formatTime('12:30 AM')}</option>
          <option value='01:00:00'>{formatTime('01:00 AM')}</option>
          <option value='01:30:00'>{formatTime('01:30 AM')}</option>
          <option value='02:00:00'>{formatTime('02:00 AM')}</option>
          <option value='02:30:00'>{formatTime('02:30 AM')}</option>
          <option value='03:00:00'>{formatTime('03:00 AM')}</option>
          <option value='03:30:00'>{formatTime('03:30 AM')}</option>
          <option value='04:00:00'>{formatTime('04:00 AM')}</option>
          <option value='04:30:00'>{formatTime('04:30 AM')}</option>
          <option value='05:00:00'>{formatTime('05:00 AM')}</option>
          <option value='05:30:00'>{formatTime('05:30 AM')}</option>
          <option value='06:00:00'>{formatTime('06:00 AM')}</option>
          <option value='06:30:00'>{formatTime('06:30 AM')}</option>
          <option value='07:00:00'>{formatTime('07:00 AM')}</option>
          <option value='07:30:00'>{formatTime('07:30 AM')}</option>
          <option value='08:00:00'>{formatTime('08:00 AM')}</option>
          <option value='08:30:00'>{formatTime('08:30 AM')}</option>
          <option value='09:00:00'>{formatTime('09:00 AM')}</option>
          <option value='09:30:00'>{formatTime('09:30 AM')}</option>
          <option value='10:00:00'>{formatTime('10:00 AM')}</option>
          <option value='10:30:00'>{formatTime('10:30 AM')}</option>
          <option value='11:00:00'>{formatTime('11:00 AM')}</option>
          <option value='11:30:00'>{formatTime('11:30 AM')}</option>
          <option value='12:00:00'>{formatTime('12:00 PM')}</option>
          <option value='12:30:00'>{formatTime('12:30 PM')}</option>
          <option value='13:00:00'>{formatTime('01:00 PM')}</option>
          <option value='13:30:00'>{formatTime('01:30 PM')}</option>
          <option value='14:00:00'>{formatTime('02:00 PM')}</option>
          <option value='14:30:00'>{formatTime('02:30 PM')}</option>
          <option value='15:00:00'>{formatTime('03:00 PM')}</option>
          <option value='15:30:00'>{formatTime('03:30 PM')}</option>
          <option value='16:00:00'>{formatTime('04:00 PM')}</option>
          <option value='16:30:00'>{formatTime('04:30 PM')}</option>
          <option value='17:00:00'>{formatTime('05:00 PM')}</option>
          <option value='17:30:00'>{formatTime('05:30 PM')}</option>
          <option value='18:00:00'>{formatTime('06:00 PM')}</option>
          <option value='18:30:00'>{formatTime('06:30 PM')}</option>
          <option value='19:00:00'>{formatTime('07:00 PM')}</option>
          <option value='19:30:00'>{formatTime('07:30 PM')}</option>
          <option value='20:00:00'>{formatTime('08:00 PM')}</option>
          <option value='20:30:00'>{formatTime('08:30 PM')}</option>
          <option value='21:00:00'>{formatTime('09:00 PM')}</option>
          <option value='21:30:00'>{formatTime('09:30 PM')}</option>
          <option value='22:00:00'>{formatTime('10:00 PM')}</option>
          <option value='22:30:00'>{formatTime('10:30 PM')}</option>
          <option value='23:00:00'>{formatTime('11:00 PM')}</option>
          <option value='23:30:00'>{formatTime('11:30 PM')}</option>
        </select>
        <select onChange={(event) => this.handleTimeOnChange(item, event, timeRangeIndex, false)} value={timeRange.endTime}>
          <option value='00:29:00'>{formatTime('12:29 AM')}</option>
          <option value='00:59:00'>{formatTime('12:59 AM')}</option>
          <option value='01:29:00'>{formatTime('01:29 AM')}</option>
          <option value='01:59:00'>{formatTime('01:59 AM')}</option>
          <option value='02:29:00'>{formatTime('02:29 AM')}</option>
          <option value='02:59:00'>{formatTime('02:59 AM')}</option>
          <option value='03:29:00'>{formatTime('03:29 AM')}</option>
          <option value='03:59:00'>{formatTime('03:59 AM')}</option>
          <option value='04:29:00'>{formatTime('04:29 AM')}</option>
          <option value='04:59:00'>{formatTime('04:59 AM')}</option>
          <option value='05:29:00'>{formatTime('05:29 AM')}</option>
          <option value='05:59:00'>{formatTime('05:59 AM')}</option>
          <option value='06:29:00'>{formatTime('06:29 AM')}</option>
          <option value='06:59:00'>{formatTime('06:59 AM')}</option>
          <option value='07:29:00'>{formatTime('07:29 AM')}</option>
          <option value='07:59:00'>{formatTime('07:59 AM')}</option>
          <option value='08:29:00'>{formatTime('08:29 AM')}</option>
          <option value='08:59:00'>{formatTime('08:59 AM')}</option>
          <option value='09:29:00'>{formatTime('09:29 AM')}</option>
          <option value='09:59:00'>{formatTime('09:59 AM')}</option>
          <option value='10:29:00'>{formatTime('10:29 AM')}</option>
          <option value='10:59:00'>{formatTime('10:59 AM')}</option>
          <option value='11:29:00'>{formatTime('11:29 AM')}</option>
          <option value='11:59:00'>{formatTime('11:59 AM')}</option>
          <option value='12:29:00'>{formatTime('12:29 PM')}</option>
          <option value='12:59:00'>{formatTime('12:59 PM')}</option>
          <option value='13:29:00'>{formatTime('01:29 PM')}</option>
          <option value='13:59:00'>{formatTime('01:59 PM')}</option>
          <option value='14:29:00'>{formatTime('02:29 PM')}</option>
          <option value='14:59:00'>{formatTime('02:59 PM')}</option>
          <option value='15:29:00'>{formatTime('03:29 PM')}</option>
          <option value='15:59:00'>{formatTime('03:59 PM')}</option>
          <option value='16:29:00'>{formatTime('04:29 PM')}</option>
          <option value='16:59:00'>{formatTime('04:59 PM')}</option>
          <option value='17:29:00'>{formatTime('05:29 PM')}</option>
          <option value='17:59:00'>{formatTime('05:59 PM')}</option>
          <option value='18:29:00'>{formatTime('06:29 PM')}</option>
          <option value='18:59:00'>{formatTime('06:59 PM')}</option>
          <option value='19:29:00'>{formatTime('07:29 PM')}</option>
          <option value='19:59:00'>{formatTime('07:59 PM')}</option>
          <option value='20:29:00'>{formatTime('08:29 PM')}</option>
          <option value='20:59:00'>{formatTime('08:59 PM')}</option>
          <option value='21:29:00'>{formatTime('09:29 PM')}</option>
          <option value='21:59:00'>{formatTime('09:59 PM')}</option>
          <option value='22:29:00'>{formatTime('10:29 PM')}</option>
          <option value='22:59:00'>{formatTime('10:59 PM')}</option>
          <option value='23:29:00'>{formatTime('11:29 PM')}</option>
          <option value='23:59:00'>{formatTime('11:59 PM')}</option>
        </select>
        {this.getDeletePeriodButtonMarkUp(item, timeRangeIndex)}
      </div>
    )
  }

  getDeletePeriodButtonMarkUp = (item, timeRangeIndex) => {
    if (this.getAvailabilityTypeId(item.availableTimes) === CUSTOM_AVAILABILITY_TYPE_ID) {
      return (
        <i onClick={() => this.handleDeletePeriodOnClick(item, timeRangeIndex)} className="material-icons delete cursor-pointer other-avail-icon">delete</i>
      )
    } else {
      return ''
    }
  }

  handleDeletePeriodOnClick = (item, timeRangeIndex) => {
    let newAvailableTimes = []
    let copyAvailableTimes = item.availableTimes.slice(0)

    for (let i = 0; i < copyAvailableTimes.length; i++) {
      if (i !== timeRangeIndex) {
        newAvailableTimes.push(copyAvailableTimes[i])
      }
    }

    let newOtherAvailability = cloneDeep(this.props.otherAvailability)

    for (let i = 0; i < newOtherAvailability.length; i++) {
      if (newOtherAvailability[i].scheduleDate === item.scheduleDate) {
        newOtherAvailability[i].availableTimes = newAvailableTimes
        break;
      }
    }

    this.setOtherAvailability(newOtherAvailability)
  }

  getOtherAvailabilityModalMarkUp = () => {
    if (this.props.editOtherAvailabilityRecord) {
      return this.getEditOtherAvailabilityModalMarkUp()
    } else {
      return this.getReadOnlyOtherAvailabilityModalMarkUp()
    }
  }

  isTimeRangeAny = (startTime, endTime) => {
    return ((startTime === '00:00' && endTime === '23:59') || (startTime === '12:00 AM' && endTime === '11:59 PM'))
  }

  isTimeRangeNotAvailable = (availableTimes) => {
    return !availableTimes || availableTimes.length === 0
  }

  getOtherAvailabilityTimeRangeReadOnlyMarkUp = (item) => {
    let availabilityTypeId = this.getAvailabilityTypeId(item.availableTimes)

    if (availabilityTypeId === NO_AVAILABILITY_TYPE_ID) {
      return (
        <div key={item.scheduleDate}>{t('not_available')}</div>
      )
    } else if (availabilityTypeId === ANY_AVAILABILITY_TYPE_ID) {
      return (
        <div key={item.scheduleDate}>{t('all_day')}</div>
      )
    } else {
      return (
        item.availableTimes.map((hour, index) => {
          let startTime = formatTime(hour.startTime)
          let endTime = formatTime(hour.endTime)

          return (
            <div key={index}>
              {startTime} - {endTime}
            </div>
          )
        })
      )
    }
  }

  getAvailableListItemReadOnlyMarkUp = (item) => {
    return (
      <div key={item.scheduleDate} className="row other-avail-list-item">
        <div className="col">
          {formatDate(item.scheduleDate)}
        </div>
        <div className="col">
          {this.getOtherAvailabilityTimeRangeReadOnlyMarkUp(item)}
        </div>
      </div>
    )
  }

  closeModal = (evt) => {
    evt.stopPropagation()
    this.setState({
      showActionModal: false
    })
  }

  handleExcludeNightPriorFromDayOff = (item) => {
    let newDailyAvailabilityItem = { ...item }
    let newOtherAvailability = cloneDeep(this.props.otherAvailability)

    for (let i = 0; i < newOtherAvailability.length; i++) {
      if (newOtherAvailability[i].scheduleDate === newDailyAvailabilityItem.scheduleDate) {
        newOtherAvailability[i].availableTimes = [{
          startTime: '00:00:00',
          endTime: '03:59:00'
        }]
        break
      }
    }
    this.setState({
      showActionModal: false,
      availabilityListItem: {},
      dayAvailability: {
        ...this.state.dayAvailability,
        [newDailyAvailabilityItem.scheduleDate]: CUSTOM_AVAILABILITY_TYPE_ID
      }
    })
    this.setOtherAvailability(newOtherAvailability)
  }

  render() {
    const { showActionModal, availabilityListItem } = this.state
    return (
      <div className="other-availability-list-component">
        <div
          id="modalOtherAvailabilityList"
          className={`modal fade fullPageModal animation-topToBottom ${
            this.props.showOtherAvailabilityListModal === true ? 'show' : ''
            }`}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header row">
                <div className="col">
                  <h5 className="modal-title capitalize-first-letter">{t('days_off_special_availability')}</h5>
                </div>
                <div className="col">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => this.props.handleCloseOtherAvailabilityListModalCloseClick(true)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
              <div className="modal-body">
                {this.getOtherAvailabilityModalMarkUp()}
              </div>
            </div>
          </div>
        </div>

        <ActionModal
          showModal={showActionModal}
          modalTitle={t('night_before_day_off')}
          modalText={t('night_before_day_off_text')}
          showCancelAction={true}
          modalAcceptButtonText={t('save')}
          modalCancelButtonText={t('update')}
          modalAcceptAction={this.closeModal}
          modalCancelAction={() => this.handleExcludeNightPriorFromDayOff(availabilityListItem)}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    backupOtherAvailability: state.otherAvailabilityReducer.backupOtherAvailability,
    otherAvailability: state.otherAvailabilityReducer.otherAvailability,
    currentOtherAvailabilityRecord: state.otherAvailabilityReducer.currentOtherAvailabilityRecord,
    editOtherAvailabilityRecord: state.otherAvailabilityReducer.editOtherAvailabilityRecord,
    newOtherAvailabilityRecord: state.otherAvailabilityReducer.newOtherAvailabilityRecord,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setEditOtherAvailabilityRecord: (editing) => setEditOtherAvailabilityRecord(editing),
      setNewOtherAvailabilityRecord: (isNew) => setNewOtherAvailabilityRecord(isNew),
      setCurrentOtherAvailabilityRecord: (newAvailabilityRecord) => setCurrentOtherAvailabilityRecord(newAvailabilityRecord),
      setOtherAvailability: (otherAvailability) => setOtherAvailability(otherAvailability),
      patchOtherAvailability: (otherAvailability) => patchOtherAvailability(otherAvailability),
      setBackupOtherAvailability: () => setBackupOtherAvailability(),
    }, dispatch)
}

OtherAvailabilityList.propTypes = {
  otherAvailability: PropTypes.array,
  showOtherAvailabilityListModal: PropTypes.bool,
  handleCloseOtherAvailabilityListModalCloseClick: PropTypes.func,
  currentOtherAvailabilityRecord: PropTypes.object,
  addOtherAvailabilityDateRange: PropTypes.func,
  setEditOtherAvailabilityRecord: PropTypes.func,
  editOtherAvailabilityRecord: PropTypes.bool,
  newOtherAvailabilityRecord: PropTypes.bool,
  setNewOtherAvailabilityRecord: PropTypes.func,
  setCurrentOtherAvailabilityRecord: PropTypes.func,
  setOtherAvailability: PropTypes.func,
  patchOtherAvailability: PropTypes.func,
  setBackupOtherAvailability: PropTypes.func,
  backupOtherAvailability: PropTypes.array,
  addSecondsToEndTime: PropTypes.func,
  cloneOtherAvailabilityExceptInRange: PropTypes.func,
  publishedWeek: PropTypes.object,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OtherAvailabilityList)
