import axios from 'axios'
import apiConfig from '../../config/api-config'
import * as cancelTokenUtil from './utils/cancelTokenUtil'

export function getOtherAvailabilty() {
    const url = apiConfig.OTHER_AVAILABILITY
    const options = cancelTokenUtil.abortAndGetCancelTokenOptions('OTHER_AVAILABILITY')
    return axios.get(`${url}`, options)
  }

export function patchOtherAvailability(otherAvailability) {
    const url = apiConfig.PATCH_OTHER_AVAILABILITY
    const options = cancelTokenUtil.abortAndGetCancelTokenOptions('PATCH_OTHER_AVAILABILITY')
    return axios.patch(`${url}`, otherAvailability, options)
}

export function deleteOtherAvailability(otherAvailability) {
  const url = apiConfig.DELETE_OTHER_AVAILABILITY
  const options = cancelTokenUtil.abortAndGetCancelTokenOptions('DELETE_OTHER_AVAILABILITY')
  return axios.post(`${url}`, otherAvailability, options)
}