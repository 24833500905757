// notification types
export const EventPersonAdded = 'EventPersonAdded'
export const EventPersonRemoved = 'EventPersonRemoved'
export const NewBadgeEarned = 'NewBadgeEarned'
export const NewBadgeUnearned = 'NewBadgeUnearned'
export const NewComplimentReceived = 'NewComplimentReceived'
export const NEW_RECEIVED_COMPLIMENT = 'NEW_RECEIVED_COMPLIMENT'
export const UNPLANNED_TRAVEL = 'UNPLANNED_TRAVEL'
export const USER_GENERIC_MESSAGE = 'USER_GENERIC_MESSAGE'
export const ShiftExpiring = 'ShiftExpiring'
export const PerformanceTracker = 'PerformanceTracker'
