import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './Toast.scss'

export default class Toast extends Component {
  constructor(props) {
    super(props)

    this.state = {
      show: true,
    }

    // Timeout for the display
    setTimeout(() => {
      this.setState({ show: false })
    }, 3000)

    // Timeout to remove from the stack
    setTimeout(() => {
      this.props.remove(this.props.id)
    }, 4000)
  }

  render() {
    if (!this.props.type) {
      return ''
    }
    return (
      <div className="notification">
        <div className="row">
          <div className="col offset-lg-1 offset-xl-1 col-lg-10 col-xl-12">
            <div className={`toast ${this.props.type} ${this.state.show ? 'show' : 'hide'}`}>
              <div className="toast-body">{this.props.body}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Toast.propTypes = {
  id: PropTypes.number,
  body: PropTypes.string,
  duration: PropTypes.number,
  header: PropTypes.string,
  type: PropTypes.string,
  remove: PropTypes.func,
}
