import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import locationSvg from '../../../styles/images/location.svg'
import clockSvg from '../../../styles/images/clock.svg'
import clockUpdateSvg from '../../../styles/images/clock-update.svg'
import carSvg from '../../../styles/images/car.svg'
import supervisorSvg from '../../../styles/images/supervisor.svg'
import errorOutlineSvg from '../../../styles/images/error_outline.svg'


import './OpenShiftCard.scss'
import { t } from '../../../utils/i18n'
import { getLocale, formatTime, getDayOfWeek, getMonthAndDay } from '../../../components/utils/datetimeUtils'

class OpenShiftCard extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  createMapLink(address) {
    const _address = address.split(',').filter(Boolean).join(', ')
    if (_address) {
      const encodedAddress = encodeURIComponent(_address)
      const url = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`
      return (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {_address}
        </a>
      )
    }
    return ''
  }

  humanizeDuration = (duration, format) => {
    let humanizedDuration = ''
    if (format.includes('hours') && duration.hours() !== 0) {
      humanizedDuration += `${duration.hours()} ${t(`hour${duration.hours() > 1 ? 's' : ''}`)} `
    }
    if (format.includes('minutes') && duration.minutes() !== 0) {
      humanizedDuration += `${duration.minutes()} ${t(`minute${duration.minutes() > 1 ? 's' : ''}`)} `
    }
    return humanizedDuration.trim()
  }

  render() {
    const { shift } = this.props
    const startDateTime = moment(new Date(shift.startDateTime)).locale(getLocale())
    const expirationDateTime = moment(new Date(shift.expirationDateTime)).locale(getLocale())
    const shiftDuration = moment.duration(shift.duration).locale(getLocale()).humanize()
    const expirationDuration = moment.duration(expirationDateTime.diff(moment())).locale(getLocale())

    return (
      <div className="open-shift-card">
        <div className="ml-1 mb-1">
          <span className="font-weight-bold">{getMonthAndDay(startDateTime)} {' '}</span>
          <span>{getDayOfWeek(startDateTime)}</span>
        </div>
        <div className="row open-shift-card">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <div className="section">
                  <div className="customer-name text-uppercase">
                    {shift.bannerName ? shift.bannerName : shift.customerName}
                  </div>
                  <div className="row mt-3">
                    <div className="col-12">
                      <img src={locationSvg} alt="Location" />
                      {this.createMapLink(shift.address)}
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-6 col-md-3 d-flex align-items-center">
                      <img src={clockSvg} alt="Clock" />
                      <div className="shift-time">{formatTime(startDateTime)}</div>
                    </div>
                    <div className="col-6 col-md-3 d-flex align-items-center">
                      <img src={clockUpdateSvg} alt="Clock History" />
                      <div>{shiftDuration.charAt(0).toUpperCase() + shiftDuration.slice(1)}</div>
                    </div>
                  </div>
                  {(shift.isDriver || shift.isSupervisor) &&
                    <div className="row mt-3">
                      {shift.isDriver &&
                        <div className="col-6 col-md-3 d-flex align-items-center">
                          <img src={carSvg} alt="Car" />
                          <div className="driver">{t('driver')}</div>
                        </div>
                      }
                      {shift.isSupervisor &&
                        <div className="col-6 col-md-3 d-flex align-items-center">
                          <img src={supervisorSvg} alt="Car" />
                          <div className="supervisor">{t('supervisor')}</div>
                        </div>
                      }
                    </div>
                  }
                  <div className="row mt-3">
                    <div className="col-12 pr-0 pr-md-3 d-flex align-items-center">
                      <img src={errorOutlineSvg} alt="Error outline" />
                      {shift.desiredPeople - shift.acceptedPeople}
                      {` ${t(`shift${(shift.desiredPeople - shift.acceptedPeople) > 1 ? 's' : ''}_remaining`)}. `}
                      {`${t('offer_expires_in')} `}
                      {expirationDuration.asDays() >= 1
                        ? expirationDuration.humanize()
                        : this.humanizeDuration(expirationDuration, 'hours minutes')
                      }
                    </div>
                  </div>

                  <div className="row justify-content-end mt-3">
                    <div className="col-md-2 col-6">
                      <input
                        type='button'
                        onClick={() => this.props.declineShift(shift.shiftId)}
                        className="btn btn-secondary w-100"
                        value={t('no_thanks')}
                      />
                    </div>
                    <div className="col-md-2 col-6">
                      <input
                        type='button'
                        onClick={() => this.props.hasEventMeetSite(shift)}
                        className="btn btn-rgis-blue w-100"
                        value={t('sign_up')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

OpenShiftCard.propTypes = {
  shift: PropTypes.object,
  hasEventMeetSite: PropTypes.func,
  declineShift: PropTypes.func,
}

export default OpenShiftCard
