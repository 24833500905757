import * as types from './actionTypes'
import services from '../services'

export function getNewPostCount(channelId, sinceDateTime) {
  return dispatch => {
    dispatch(getNewPostCountActionPending(true))
    services.postCard
      .getNewPostCount(channelId, sinceDateTime)
      .then(response => {
        dispatch(getNewPostCountActionSuccess(response))
        dispatch(getNewPostCountActionPending(false))
      })
      .catch(error => {
        dispatch(getNewPostCountActionError(error))
        dispatch(getNewPostCountActionPending(false))
      })
  }
}
const getNewPostCountActionSuccess = response => {
  return {
    type: types.GET_NEW_POST_COUNT_SUCCESS,
    payload: response.data,
  }
}
const getNewPostCountActionError = response => {
  return {
    type: types.GET_NEW_POST_COUNT_ERROR,
    error: response,
  }
}
const getNewPostCountActionPending = status => {
  return {
    type: types.GET_NEW_POST_COUNT_PENDING,
    status,
  }
}

export function getPostInfo(postId) {
  return dispatch => {
    dispatch(getPostCardActionPending(true))
    services.postCard
      .fetchPostCard(postId)
      .then(response => {
        dispatch(getPostCardActionSuccess(response))
        dispatch(getPostCardActionPending(false))
      })
      .catch(error => {
        dispatch(getPostCardActionError(error))
        dispatch(getPostCardActionPending(false))
      })
  }
}

const getPostCardActionSuccess = response => {
  return {
    type: types.GET_POST_CARD_SUCCESS,
    payload: response.data,
  }
}
const getPostCardActionError = response => {
  return {
    type: types.GET_POST_CARD_ERROR,
    error: response,
  }
}
const getPostCardActionPending = status => {
  return {
    type: types.GET_POST_CARD_PENDING,
    status,
  }
}


export function getPostComments(postId) {
  return dispatch => {
    dispatch(getPostCommentsActionPending(true))
    services.postCard
      .fetchPostComments(postId)
      .then(response => {
        dispatch(getPostCommentsActionPending(false))
        dispatch(getPostCommentsActionSuccess(response))
      })
      .catch(error => {
        dispatch(getPostCommentsActionError(error))
      })
  }
}

const getPostCommentsActionSuccess = response => {
  return {
    type: types.GET_POST_COMMENTS_SUCCESS,
    payload: response.data,
  }
}
const getPostCommentsActionError = response => {
  return {
    type: types.GET_POST_COMMENTS_ERROR,
    error: response,
  }
}
const getPostCommentsActionPending = status => {
  return {
    type: types.GET_POST_COMMENTS_PENDING,
    pending: status,
  }
}


export function addPostComment(data) {
  return dispatch => {
    dispatch(addPostCommentActionPending(true))
    services.postCard
      .insertPostComment(data)
      .then(response => {
        dispatch(addPostCommentActionPending(false))
        dispatch(addPostCommentActionSuccess(response))
      })
      .catch(error => {
        dispatch(addPostCommentActionError(error))
      })
  }
}

const addPostCommentActionSuccess = response => {
  return {
    type: types.POST_POST_COMMENT_SUCCESS,
    payload: response.data,
  }
}
const addPostCommentActionError = response => {
  return {
    type: types.POST_POST_COMMENT_ERROR,
    error: response,
  }
}
const addPostCommentActionPending = status => {
  return {
    type: types.POST_POST_COMMENT_PENDING,
    pending: status,
  }
}


export function removePostComment(commentId) {
  return dispatch => {
    dispatch(removePostCommentActionPending(true))
    services.postCard
      .removePostComment(commentId)
      .then(response => { //eslint-disable-line
        dispatch(removePostCommentActionPending(false))
        dispatch(removePostCommentActionSuccess(commentId))
      })
      .catch(error => {
        dispatch(removePostCommentActionError(error))
      })
  }
}

const removePostCommentActionSuccess = response => {
  return {
    type: types.DELETE_POST_COMMENT_SUCCESS,
    payload: response,
  }
}
const removePostCommentActionError = response => {
  return {
    type: types.DELETE_POST_COMMENT_ERROR,
    error: response,
  }
}
const removePostCommentActionPending = status => {
  return {
    type: types.DELETE_POST_COMMENT_PENDING,
    pending: status,
  }
}

export function editPostComment(comment) {
  return dispatch => {
    dispatch(editPostCommentActionPending(true))
    services.postCard
      .editPostComment(comment)
      .then(response => {
        dispatch(editPostCommentActionPending(false))
        dispatch(editPostCommentActionSuccess(response))
      })
      .catch(error => {
        dispatch(editPostCommentActionError(error))
      })
  }
}

const editPostCommentActionSuccess = response => {
  return {
    type: types.EDIT_POST_COMMENT_SUCCESS,
    payload: response.data,
  }
}
const editPostCommentActionError = response => {
  return {
    type: types.EDIT_POST_COMMENT_ERROR,
    error: response,
  }
}
const editPostCommentActionPending = status => {
  return {
    type: types.EDIT_POST_COMMENT_PENDING,
    pending: status,
  }
}

export function getCommentReplies(postId) {
  return dispatch => {
    dispatch(getCommentRepliesActionPending(true))
    services.postCard
      .fetchPostComments(postId)
      .then(response => {
        dispatch(getCommentRepliesActionSuccess(response))
        dispatch(getCommentRepliesActionPending(false))
      })
      .catch(error => {
        dispatch(getCommentRepliesActionError(error))
        dispatch(getCommentRepliesActionPending(false))
      })
  }
}

const getCommentRepliesActionSuccess = response => {
  return {
    type: types.GET_COMMENT_REPLY_SUCCESS,
    payload: response.data,
  }
}
const getCommentRepliesActionError = response => {
  return {
    type: types.GET_COMMENT_REPLY_ERROR,
    error: response,
  }
}
const getCommentRepliesActionPending = status => {
  return {
    type: types.GET_COMMENT_REPLY_PENDING,
    status,
  }
}

export function addCommentReply(data) {
  return dispatch => {
    dispatch(addCommentReplyActionPending(true))
    services.postCard
      .insertPostComment(data)
      .then(response => {
        dispatch(addCommentReplyActionPending(false))
        dispatch(addCommentReplyActionSuccess(response))
      })
      .catch(error => {
        dispatch(addCommentReplyActionError(error))
      })
  }
}

const addCommentReplyActionSuccess = response => {
  return {
    type: types.POST_COMMENT_REPLY_SUCCESS,
    payload: response.data,
  }
}
const addCommentReplyActionError = response => {
  return {
    type: types.POST_COMMENT_REPLY_ERROR,
    error: response,
  }
}
const addCommentReplyActionPending = status => {
  return {
    type: types.POST_COMMENT_REPLY_PENDING,
    pending: status,
  }
}

export function removeCommentReply(commentId, parentId) {
  return dispatch => {
    dispatch(removeCommentReplyActionPending(true))
    services.postCard
      .removePostComment(commentId)
      .then(response => { //eslint-disable-line
        dispatch(removeCommentReplyActionPending(false))
        dispatch(removeCommentReplyActionSuccess({ commentId, parentId }))
      })
      .catch(error => {
        dispatch(removeCommentReplyActionError(error))
      })
  }
}

const removeCommentReplyActionSuccess = response => {
  return {
    type: types.DELETE_COMMENT_REPLY_SUCCESS,
    payload: response,
  }
}
const removeCommentReplyActionError = response => {
  return {
    type: types.DELETE_COMMENT_REPLY_ERROR,
    error: response,
  }
}
const removeCommentReplyActionPending = status => {
  return {
    type: types.DELETE_COMMENT_REPLY_PENDING,
    pending: status,
  }
}

export function editPostCommentReply(comment) {
  return dispatch => {
    dispatch(editPostCommentReplyActionPending(true))
    services.postCard
      .editPostComment(comment)
      .then(response => {
        dispatch(editPostCommentReplyActionSuccess(response))
        dispatch(editPostCommentReplyActionPending(false))
      })
      .catch(error => {
        dispatch(editPostCommentReplyActionError(error))
        dispatch(editPostCommentReplyActionPending(false))
      })
  }
}

const editPostCommentReplyActionSuccess = response => {
  return {
    type: types.EDIT_POST_COMMENT_REPLY_SUCCESS,
    payload: response.data,
  }
}
const editPostCommentReplyActionError = response => {
  return {
    type: types.EDIT_POST_COMMENT_REPLY_ERROR,
    error: response,
  }
}
const editPostCommentReplyActionPending = status => {
  return {
    type: types.EDIT_POST_COMMENT_REPLY_PENDING,
    status,
  }
}
