import { getLocale } from './datetimeUtils'
import { isDefinedAndNotEmpty } from './nullSafeCheckUtils'

export function formatNumber(number) {
    if (isNaN(number)) {
        return number
    }

    number = Number(number)

    if (!isDefinedAndNotEmpty(number)) {
        return number
    } else {
        const locale = getLocale()
        return number.toLocaleString(locale)
    }
}

export function formatNumberDecimal(number, numberOfDecimals = 2) {
    if (isNaN(number)) {
        return number
    }

    number = Number(number)
    
    if (!isDefinedAndNotEmpty(number)) {
        return number
    } else {
        const locale = getLocale()
        return number.toLocaleString(locale, { minimumFractionDigits: numberOfDecimals })
    }
}