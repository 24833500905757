import * as types from './actionTypes'
import services from '../services'

export function getFeatureAccessList() {
    return dispatch => {
        dispatch(getFeatureAccessListPending(true))
        services.featureAccess
            .getFeatureAccess()
            .then(response => {
                dispatch(getFeatureAccessListSuccess(response))
                dispatch(getFeatureAccessListPending(false))
            })
            .catch(error => {
                dispatch(getFeatureAccessListError(error))
            })
    }
}

const getFeatureAccessListPending = pending => {
    return {
        type: types.GET_FEATURE_ACCESS_LIST_PENDING,
        payload: pending,
    }
}

const getFeatureAccessListSuccess = response => {
    return {
        type: types.GET_FEATURE_ACCESS_LIST_SUCCESS,
        payload: response.data
    }
}

const getFeatureAccessListError = error => {
    return {
        type: types.GET_FEATURE_ACCESS_LIST_ERROR,
        error: error,
    }
}

export function getPersonFeatureAccess(personId) {
    return dispatch => {
        dispatch(getPersonFeatureAccessPending(true))
        services.featureAccess
            .getPersonFeatureAccess(personId)
            .then(response => {
                dispatch(getPersonFeatureAccessSuccess(response))
                dispatch(getPersonFeatureAccessPending(false))
            })
            .catch(error => {
                dispatch(getPersonFeatureAccessError(error))
            })
    }
}

const getPersonFeatureAccessPending = pending => {
    return {
        type: types.GET_PERSON_FEATURE_ACCESS_PENDING,
        payload: pending,
    }
}

const getPersonFeatureAccessSuccess = response => {
    return {
        type: types.GET_PERSON_FEATURE_ACCESS_SUCCESS,
        payload: response.data
    }
}

const getPersonFeatureAccessError = error => {
    return {
        type: types.GET_PERSON_FEATURE_ACCESS_ERROR,
        error: error,
    }
}