import {
  GET_CONTACTS_ERROR,
  GET_CONTACTS_PENDING,
  GET_CONTACTS_SUCCESS
} from '../actions/actionTypes'

export default function contactsReducer(
  state = {
    contacts: [],
    contactsError: {},
    contactsPending: false,
  },
  action
) {
  switch (action.type) {
    case GET_CONTACTS_SUCCESS: {
      return {
        ...state,
        contacts: action.payload,
        contactsError: {},
      }
    }
    case GET_CONTACTS_ERROR:
      return {
        ...state,
        contactsError: action.error,
      }
    case GET_CONTACTS_PENDING:
      return {
        ...state,
        contactsPending: action.status,
      }
    default:
      return state
  }
}
