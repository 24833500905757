import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import PropTypes from 'prop-types'
import { t } from '../../utils/i18n'
import { cloneDeep, padStart } from 'lodash'
import { setShowOtherAvailabilityListModal, setCurrentOtherAvailabilityRecord, setEditOtherAvailabilityRecord, setNewOtherAvailabilityRecord, getOtherAvailability, setOtherAvailability, deleteOtherAvailability, setBackupOtherAvailability } from '../../state/actions/otherAvailabilityActions'

import { formatDate, formatDateNotForDisplay } from '../utils/datetimeUtils'
import { getPreferredLanguage } from '../utils/localStorageUtils'
import { isDefined } from '../utils/nullSafeCheckUtils'

import './OtherAvailability.scss'
import OtherAvailabilityList from '../OtherAvailabilityList';

class OtherAvailability extends Component {

    getOtherAvailabilityRequestList = () => {
        const { otherAvailability } = this.props

        let otherAvailabilityList = []

        let startDate = ''
        let endDate = ''
        for (let i = 0; i < otherAvailability.length; i++) {
            let tempDate = otherAvailability[i].scheduleDate
            if (startDate === '') {
                startDate = tempDate
            }

            let isEndOfSequence = i === (otherAvailability.length - 1)
            if (!isEndOfSequence) {
                let nextTempDate = otherAvailability[i + 1].scheduleDate
                let dayAfterTempDate = moment(otherAvailability[i].scheduleDate).add(1, 'days')
                isEndOfSequence = formatDate(nextTempDate) !== formatDate(dayAfterTempDate)
            } 
            
            if (isEndOfSequence) {
                endDate = tempDate
                
                otherAvailabilityList.push({
                    startDate: startDate,
                    endDate: endDate,
                })

                startDate = ''
                endDate = ''
            }
        }

        return otherAvailabilityList
    }

    getAvailabilityList = (startDate, endDate) => {
        let newAvailabilityList = []
        let startIndex = this.getScheduleDateIndex(startDate)
        let endIndex = this.getScheduleDateIndex(endDate)

        for (let i = startIndex; i <= endIndex; i++) {
            newAvailabilityList.push(
                cloneDeep(this.props.otherAvailability[i])
            )
        }

        return newAvailabilityList
    }

    getScheduleDateIndex = (scheduleDate) => {
        let scheduleDateIndex = -1;

        for (let i = 0; i < this.props.otherAvailability.length; i++) {
            if (formatDate(this.props.otherAvailability[i].scheduleDate) === formatDate(scheduleDate)) {
                scheduleDateIndex = i
                break
            }
        }

        return scheduleDateIndex
    }

    handleDeleteAvailabilityOnClick = (item) => {
        if (window.confirm(t('confirm_delete_other_avail'))) {
            this.deleteDateRange(item.startDate, item.endDate)
        }
    }

    deleteDateRange = (startDate, endDate) => {
        let availabilityListToDelete = this.getAvailabilityList(startDate, endDate)
        this.props.deleteOtherAvailability(this.addSecondsToEndTime(availabilityListToDelete))

        let newOtherAvailability = this.cloneOtherAvailabilityExceptInRange(startDate, endDate)
        this.setOtherAvailability(newOtherAvailability)

        this.createBackup()
    }

    createBackup = () => {
        this.props.setBackupOtherAvailability()
    }

    cloneOtherAvailabilityExceptInRange = (startDate, endDate) => {
        let startDateMoment = moment(startDate)
        let endDateMoment = moment(endDate)
        let newAvailability = []

        for (let i = 0; i < this.props.otherAvailability.length; i++) {
            let currentMoment = moment(this.props.otherAvailability[i].scheduleDate)

            if (currentMoment.isSameOrAfter(startDateMoment) && currentMoment.isSameOrBefore(endDateMoment)) {
                continue
            }

            newAvailability.push(cloneDeep(this.props.otherAvailability[i]))
        }

        return newAvailability
    }

    getDeleteOtherAvailablityLinkMarkUp = (item) => {
        return (
            <i onClick={(event) => this.handleDeleteAvailabilityOnClick(item, event)} className="material-icons delete cursor-pointer other-avail-icon">delete</i>
        )
    }

    getOtherAvailabilityRequestItemMarkUp = (item) => {
        return (
            <div key={item.startDate} className="row">
                <div className="col other-avail-date-separator">
                    <a onClick={() => this.handleOtherAvailabilityListItemOnClick(item)} className="cursor-pointer other-availability-link">{formatDate(item.startDate)} - {formatDate(item.endDate)}</a> {this.getDeleteOtherAvailablityLinkMarkUp(item)} {/* eslint-disable-line */}
                </div>
            </div>
        )
    }

    handleOtherAvailabilityListItemOnClick = (item) => {
        this.setCurrentOtherAvailabilityRecord(item)
        this.setEditOtherAvailabilityRecord(false)
        this.setNewOtherAvailabilityRecord(false)
        this.setShowOtherAvailabilityListModal(true)
    }

    setNewOtherAvailabilityRecord = (isNew) => {
        this.props.setNewOtherAvailabilityRecord(isNew)
    }

    setEditOtherAvailabilityRecord = (editing) => {
        this.props.setEditOtherAvailabilityRecord(editing)
    }

    otherAvailabilityHasDate = (scheduleDate) => {
        for (let i = 0; i < this.props.otherAvailability.length; i++) {
            if (formatDate(this.props.otherAvailability[i].scheduleDate) === formatDate(scheduleDate)) {
                return true
            }
        }

        return false
    }

    getNextUnassignedDate = () => {
        let foundNextDate = false
        let scheduleDate = moment(this.props.publishedWeek.endDate).add(7, 'd')

        while (!foundNextDate) {
            if (!this.otherAvailabilityHasDate(scheduleDate)) {
                return scheduleDate
            }

            scheduleDate = scheduleDate.add(1, 'd')
        } 
    }

    cloneOtherAvailability = () => {
        // clean up old date range
        let newAvailabilityList = []

        // add in the other availability that is not 
        for (let i = 0; i < this.props.otherAvailability.length; i++) {
            newAvailabilityList.push(cloneDeep(this.props.otherAvailability[i]))
        }

        return newAvailabilityList
    }

    addOtherAvailabilityDateRange = (startDate, endDate) => {
        let newAvailabilityList = this.cloneOtherAvailability()

        // add date range if it is valid
        if (moment(startDate).isSameOrBefore(moment(endDate))) {
            let formattedStartDate = formatDateNotForDisplay(startDate)
            let formattedEndDate = formatDateNotForDisplay(endDate)
            let dayAfterEndDate = formatDateNotForDisplay(moment(formattedEndDate).add(1, 'days'))

            while (formattedStartDate !== dayAfterEndDate) {
                if (!this.otherAvailabilityHasDate(formattedStartDate)) {
                    newAvailabilityList.push(this.generateOtherAvailabilityForDate(formattedStartDate))
                }

                formattedStartDate = formatDateNotForDisplay(moment(formattedStartDate).add(1, 'days'))
            } 
        }

        this.setOtherAvailability(newAvailabilityList.sort(this.compareAvailability))
    }

    compareAvailability = (a, b) => {
        if (moment(a.scheduleDate).isBefore(moment(b.scheduleDate))) {
            return -1;
        }
        if (moment(a.scheduleDate).isAfter(moment(b.scheduleDate))) {
            return 1;
        }
        return 0;
    }

    generateOtherAvailabilityForDate = (generatedDate) => {
        return {
            scheduleDate: generatedDate,
            availableTimes: null,
            pending: false,
            reasonForChange: null,
            notesForChange: null
        }
    }

    handleAddOtherAvailabilityClick = () => {
        var sendEmail = isDefined(this.props.featureAccess.automatedDaysOff) && (this.props.featureAccess.automatedDaysOff === false);
        if (sendEmail){
            var paddedOrganizationName = padStart(this.props.organizationName, 3, '0');
            var emailAddress = 'dist' + paddedOrganizationName + '@wisintl.com';
            var subject = encodeURIComponent(t('days_off_request_email_subject', [this.props.fullName]));
            var body = encodeURIComponent(t('days_off_request_email_body'));
            var url = 'mailto:' + emailAddress + '?subject=' + subject + '&body=' + body;
            window.open(url);
            return;
        }
        
        let nextUnassignedDate = formatDateNotForDisplay(this.getNextUnassignedDate())

        let newRecord = {
            startDate: null,
            endDate: null
        }

        this.addOtherAvailabilityDateRange(nextUnassignedDate, nextUnassignedDate)
        this.setCurrentOtherAvailabilityRecord(newRecord)
        this.setEditOtherAvailabilityRecord(true)
        this.setNewOtherAvailabilityRecord(true)
        this.setShowOtherAvailabilityListModal(true)
    }

    setOtherAvailability = (otherAvailability) => {
        this.props.setOtherAvailability(otherAvailability)
    }

    setCurrentOtherAvailabilityRecord = (currentOtherAvailabilityRecord) => {
        this.props.setCurrentOtherAvailabilityRecord({...currentOtherAvailabilityRecord})
    }

    handleCloseOtherAvailabilityListModalCloseClick = (restoreBackup) => {
        if (restoreBackup) {
            this.setOtherAvailability(this.props.backupOtherAvailability.slice(0))
        }

        this.setEditOtherAvailabilityRecord(false)
        this.setNewOtherAvailabilityRecord(false)
        this.setShowOtherAvailabilityListModal(false)
    }

    setShowOtherAvailabilityListModal = (showModal) => {
        this.props.setShowOtherAvailabilityListModal(showModal)
    }

    addSecondsToEndTime = (_newAvailability) => {
        let newAvailability = cloneDeep(_newAvailability)
        for (let i = 0; i < newAvailability.length; i++) {
          for (let j = 0; j < newAvailability[i].availableTimes.length; j++) {
            let endTime = newAvailability[i].availableTimes[j].endTime
            let splitEndTime = endTime.split(':')
    
            newAvailability[i].availableTimes[j].endTime = `${splitEndTime[0]}:${splitEndTime[1]}:59`
          }
        }
    
        return newAvailability
    }

    getAddOtherAvailabilityLinkMarkUp = () => {
        return (
            <i onClick={this.handleAddOtherAvailabilityClick} className="material-icons add_box cursor-pointer other-avail-icon">add_box</i>
        )
    }

    getNotSetMarkUp = () => {
        return (<div>{t('not_set')}</div>)
    }

    render() {
        const language = getPreferredLanguage()

        let otherAvailabilityList = this.getOtherAvailabilityRequestList()

        return (
        <div className="other-availability-component">        
            <div className="row">
                <div className={`col general-title uppercase ${language.toUpperCase() === 'FR' ? '' : 'capitalize'}`}>
                    {t('days_off_special_availability')} | {this.getAddOtherAvailabilityLinkMarkUp()}
                </div>
            </div>
            <div>
                {otherAvailabilityList.length > 0 ? this.getOtherAvailabilityRequestList().map(this.getOtherAvailabilityRequestItemMarkUp) : this.getNotSetMarkUp()}
            </div>

            <OtherAvailabilityList
                showOtherAvailabilityListModal={this.props.showOtherAvailabilityListModal}
                handleCloseOtherAvailabilityListModalCloseClick={this.handleCloseOtherAvailabilityListModalCloseClick}
                addOtherAvailabilityDateRange={this.addOtherAvailabilityDateRange}
                addSecondsToEndTime={this.addSecondsToEndTime}
                cloneOtherAvailabilityExceptInRange={this.cloneOtherAvailabilityExceptInRange}
                publishedWeek={this.props.publishedWeek}
                />
        </div>
        )
    }
}

function mapStateToProps(state) {
  return {
    showOtherAvailabilityListModal: state.otherAvailabilityReducer.showOtherAvailabilityListModal,
    otherAvailability: state.otherAvailabilityReducer.otherAvailability,
    backupOtherAvailability: state.otherAvailabilityReducer.backupOtherAvailability,
    profileLocale: state.loginReducer.profileLocale,
    fullName: state.loginReducer.profile.fullName,
    organizationName: state.loginReducer.profile.organization,
    featureAccess: state.featureAccessReducer.featureAccess
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
        setShowOtherAvailabilityListModal: (showModal) => setShowOtherAvailabilityListModal(showModal),  
        setCurrentOtherAvailabilityRecord: (currentOtherAvailabilityRecord) => setCurrentOtherAvailabilityRecord(currentOtherAvailabilityRecord),       
        setEditOtherAvailabilityRecord: (editing) => setEditOtherAvailabilityRecord(editing),
        setNewOtherAvailabilityRecord: (isNew) => setNewOtherAvailabilityRecord(isNew),
        getOtherAvailability: () => getOtherAvailability(),
        setOtherAvailability: (otherAvailability) => setOtherAvailability(otherAvailability),
        deleteOtherAvailability: (otherAvailability) => deleteOtherAvailability(otherAvailability),
        setBackupOtherAvailability: () => setBackupOtherAvailability(),
    }, dispatch)
}

OtherAvailability.propTypes = {
    otherAvailability: PropTypes.array,
    showOtherAvailabilityListModal: PropTypes.bool,
    setShowOtherAvailabilityListModal: PropTypes.func,
    setCurrentOtherAvailabilityRecord: PropTypes.func,
    setEditOtherAvailabilityRecord: PropTypes.func,
    setNewOtherAvailabilityRecord: PropTypes.func,
    getOtherAvailability: PropTypes.func,
    setOtherAvailability: PropTypes.func,
    deleteOtherAvailability: PropTypes.func,
    backupOtherAvailability: PropTypes.array,
    setBackupOtherAvailability: PropTypes.func,
    publishedWeek: PropTypes.object,
    profileLocale: PropTypes.string,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OtherAvailability)
