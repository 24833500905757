import React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getPostInfo } from '../../state/actions/postCardActions.js'
import { getChannelAccess } from '../../state/actions/channelActions'
import { getModerationReasons } from '../../state/actions/moderationActions'
import { postListOfProfileImages } from '../../state/actions/profileActions'
import PropTypes from 'prop-types'
import Preloader from '../../components/Preloader'
import PostCardDetails from '../../components/PostCardDetails'
import ActionModal from '../../components/ActionModal'

import { hasNewsAccess } from '../../components/utils/featureAccessUtils'
import { setNewPosts, getNewPosts } from '../../components/utils/localStorageUtils'

import './PostCardContainer.scss'
import { t } from '../../utils/i18n'

class PostCardContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      postInfo: {
        postId: null,
        channelId: null,
        personId: null,
        personName: '',
        personTitle: '',
        organizationId: '',
        organizationName: '',
        title: '',
        body: '',
        parentId: null,
        commentsEnabled: false,
        likesEnabled: false,
        dislikesEnabled: false,
        hideFromHourlyTeamMembers: '',
        commentCount: null,
        likeCount: null,
        dislikeCount: null,
        creationDate: null,
        updatedDate: null,
        postAttachments: [],
      },
      modal: {
        showModal: false,
        modalTitle: '',
        modalText: '',
        showCancelAction: false,
        modalAcceptButtonText: '',
        modalCancelButtonText: '',
        modalAcceptAction: null,
        modalCancelAction: null,
      },
      scrollToPostId: null,
      commentToNavigateTo: null,
    }

    this.returnToChannelPosts = this.returnToChannelPosts.bind(this)
    this.cancelPostAction = this.cancelPostAction.bind(this)
  }

  componentDidMount() {
    const postId = this.props.match.params.post_id
    const channelId = this.props.match.params.channel_id
    const locationState = this.props.location.state

    if (locationState !== undefined && locationState.navigatedFromNotification === true) {
      this.setState({ commentToNavigateTo: locationState.commentToNavigateTo })
    }

    this.props.getPostInfo(postId)
    this.props.getChannelAccess(channelId)
    this.props.getModerationReasons()

    if (this.props.location.state && this.props.location.state.scrollToPostId) {
      this.setState({ scrollToPostId: this.props.location.state.scrollToPostId })
    }
  }

  componentDidUpdate(prevProps) {
    const _state = this.state
    if (prevProps.postCardPending === true && this.props.postCardPending === false) {
      _state.postInfo = this.props.postInfo
      this.setState(_state)
      this.props.postListOfProfileImages([ this.props.postInfo.personId ], 'post')
    } else {
      if (prevProps.postInfo.commentCount !== this.props.postInfo.commentCount) {
        _state.postInfo = this.props.postInfo
        this.setState(_state)
        this.props.postListOfProfileImages([ this.props.postInfo.personId ], 'post')
      }
    }
    if (prevProps.featureAccessPending === true && this.props.featureAccessPending === false) {
      if (!hasNewsAccess(this.props.featureAccess)) {
        return <Redirect to="/profile" />
      }
    }
    if (prevProps.watchPostPending === true && this.props.watchPostPending === false) {
      _state.postInfo = this.props.postInfo
      this.setState(_state)
    }

    if (prevProps.postModerationItemPending === true && this.props.postModerationItemPending === false) {
      _state.postInfo = this.props.postInfo
      this.setState(_state)
    }

    if (!Object.keys(prevProps.postModerationItemError).length && Object.keys(this.props.postModerationItemError).length) {
      _state.modal = {
        showModal: true,
        modalTitle: t('error'),
        modalText: t('try_again_later'),
        showCancelAction: false,
        modalAcceptButtonText: t('ok'),
        modalCancelButtonText: '',
        modalAcceptAction: this.cancelPostAction,
        modalCancelAction: null,
      }
      this.setState(_state)
    }
  }

  cancelPostAction(evt) {
    evt.stopPropagation()
    const _state = this.state
    _state.modal = {
      showModal: false,
      modalTitle: '',
      modalText: '',
      showCancelAction: false,
      modalAcceptButtonText: '',
      modalCancelButtonText: '',
      modalAcceptAction: null,
      modalCancelAction: null,
    }
    this.setState(_state)
  }

  returnToChannelPosts() {
    const { postInfo } = this.state
    const _locationState = this.props.location.state
    let isNewPost = {}
    if (_locationState !== undefined) {
      isNewPost = _locationState.isNewPost
    } else {
      const newPosts = getNewPosts()
      setNewPosts(newPosts !== 0 ? newPosts - 1 : 0)
      isNewPost = {
        [postInfo.postId]: false
      }
    }
    this.props.history.push({
      pathname: `/news/channel/${postInfo.channelId}`,
      state: {
        scrollToPostId: postInfo.postId,
        isNewPost
      }
    })
  }

  render() {
    const { postInfo, modal, scrollToPostId, commentToNavigateTo } = this.state
    const {
      postCardPending, postCardError, userChannelAccess,
      userChannelAccessPending, featureAccessPending, watchPostPending,
      postModerationItemPending, profileImagesPending, profileImages,
    } = this.props

    if (postCardPending || !postInfo.postId || userChannelAccessPending
      || featureAccessPending || watchPostPending || postModerationItemPending
      || profileImagesPending
    ) {
      return <Preloader />
    }

    if (Object.keys(postCardError).length) {
      if (postCardError.status === 404 || postCardError.status === 400) {
        this.props.history.push('/not-found')
      }
    }

    return (
      <div className="post-card-container">
        <div className="row">
          <div className="col post-card-header">{t('post')}</div>
          <div className="col-2 d-flex align-items-center justify-content-end">
            <i
              onClick={this.returnToChannelPosts}
              className="material-icons arrow_back cursor-pointer other-avail-icon"
            >
              arrow_back
            </i>
          </div>
        </div>
        <div>
          <PostCardDetails
            history={this.props.history}
            postInfo={postInfo}
            preview={false}
            userAccess={userChannelAccess}
            scrollToPostId={scrollToPostId}
            userAvatar={profileImages.find(obj => obj.personId === postInfo.personId)}
            commentToNavigateTo={commentToNavigateTo}
          />
        </div>
        <ActionModal
          {...modal}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    postCardPending: state.postCardReducer.postCardPending,
    postCardError: state.postCardReducer.postCardError,
    postInfo: state.postCardReducer.postInfo,
    userChannelAccess: state.channelReducer.userChannelAccess,
    userChannelAccessPending: state.channelReducer.userChannelAccessPending,
    userChannelAccessError: state.channelReducer.userChannelAccessError,
    featureAccess: state.featureAccessReducer.featureAccess,
    featureAccessPending: state.featureAccessReducer.featureAccessPending,
    watchPostPending: state.channelReducer.watchPostPending,
    postModerationItemPending: state.moderationReducer.postModerationItemPending,
    postModerationItemError: state.moderationReducer.postModerationItemError,
    moderationReasonsPending: state.moderationReducer.moderationReasonsPending,
    profileImages: state.profileReducer.profileImages,
    profileImagesPending: state.profileReducer.profileImagesPending['post'],
    profileImagesError: state.profileReducer.profileImagesError,
    profileLocale: state.loginReducer.profileLocale,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getPostInfo: (postId) => getPostInfo(postId),
      getChannelAccess: (channelId) => getChannelAccess(channelId),
      getModerationReasons: () => getModerationReasons(),
      postListOfProfileImages: (personIds, location) => postListOfProfileImages(personIds, location),
    },
    dispatch
  )
}

PostCardContainer.propTypes = {
  getPostInfo: PropTypes.func,
  postCardPending: PropTypes.bool,
  postCardError: PropTypes.object,
  postInfo: PropTypes.object,
  history: PropTypes.object,
  userChannelAccessPending: PropTypes.bool,
  userChannelAccess: PropTypes.object,
  getChannelAccess: PropTypes.func,
  match: PropTypes.object,
  location: PropTypes.object,
  featureAccess: PropTypes.object,
  featureAccessPending: PropTypes.bool,
  watchPostPending: PropTypes.bool,
  postModerationItemPending: PropTypes.bool,
  getModerationReasons: PropTypes.func,
  moderationReasonsPending: PropTypes.bool,
  postModerationItemError: PropTypes.object,
  postListOfProfileImages: PropTypes.func,
  profileImages: PropTypes.array,
  profileImagesPending: PropTypes.bool,
  profileImagesError: PropTypes.object,
  profileLocale: PropTypes.string,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostCardContainer)
