import { groupBy, sortBy } from 'lodash'
import moment from 'moment'
import { nullSafeCheckIsTrue } from '../../components/utils/nullSafeCheckUtils'

import {
  GET_TIME_PUNCHES_SUCCESS,
  GET_TIME_PUNCHES_ERROR,
  GET_TIME_PUNCHES_PENDING,
  GET_TIME_PUNCH_DETAILS_SUCCESS,
  GET_TIME_PUNCH_DETAILS_ERROR,
  GET_TIME_PUNCH_DETAILS_PENDING,

} from '../actions/actionTypes'


export default function timePunchesReducer(
  state = {
    timePunchesStartDate: null,
    timePunchesEndDate: null,

    timePunchesItems: [],
    timePunchesItemsGroupedByDate: [],
    timePunchesPending: false,
    timePunchesError: {},

    weekTotalWorkTime: 0,
    weekTotalTravelTime: 0,

    timePunchDetails: {},
    timePunchDetailsPending: false,
    timePunchDetailsError: {},
  },
  action
) {
  switch (action.type) {
    case GET_TIME_PUNCHES_SUCCESS: {
      let defaultHours = 0
      const precisionCorrectionFactor = 100 // Reason for precision correction factor https://stackoverflow.com/questions/1458633/how-to-deal-with-floating-point-number-precision-in-javascript
      let _weekTotalWorkTime = defaultHours * precisionCorrectionFactor
      let _weekTotalTravelTime = defaultHours * precisionCorrectionFactor

      action.payload.map((item) => {
        _weekTotalWorkTime += nullSafeCheckIsTrue(item.isPending) ? 0 : (item.hours * precisionCorrectionFactor) // pending hours do not count towards total
        _weekTotalTravelTime += nullSafeCheckIsTrue(item.isPending) ? 0 : (item.travelHours * precisionCorrectionFactor) // pending hours do not count towards total
        return null
      })

      const _timePunchesItemsGroupedByDate = sortBy(groupBy(action.payload, 'inventoryDate'), value => {
        return moment(value[0].inventoryDate)
      })

      return {
        ...state,
        timePunchesStartDate: action.timePunchesStartDate,
        timePunchesEndDate: action.timePunchesEndDate,
        timePunchesItems: action.payload,
        timePunchesItemsGroupedByDate: _timePunchesItemsGroupedByDate,
        timePunchesItemsError: {},

        weekTotalWorkTime: _weekTotalWorkTime / precisionCorrectionFactor,
        weekTotalTravelTime: _weekTotalTravelTime / precisionCorrectionFactor
      }
    }

    case GET_TIME_PUNCHES_PENDING:
      return {
        ...state,
        timePunchesPending: action.status,
      }

    case GET_TIME_PUNCHES_ERROR:
      return {
        ...state,
        timePunchesError: action.error,
      }

    case GET_TIME_PUNCH_DETAILS_SUCCESS: {
      const _timePunchDetails = {}
      _timePunchDetails.listOfTimePunches = parseMealBreaks(action.payload.listOfTimePunches)
      _timePunchDetails.listOfTravelVehicleInformation = parseMealBreaks(action.payload.listOfTravelVehicleInformation)

      return {
        ...state,
        timePunchDetails: _timePunchDetails,
        timePunchDetailsError: {},
      }
    }

    case GET_TIME_PUNCH_DETAILS_PENDING:
      return {
        ...state,
        timePunchDetailsPending: action.status,
      }

    case GET_TIME_PUNCH_DETAILS_ERROR:
      return {
        ...state,
        timePunchDetailsError: action.error,
      }

    default:
      return state
  }
}

function parseMealBreaks(list) {
  let mealBreaks = []
  list.map((item) => {
    mealBreaks = []
    if (item.mealStart !== undefined && item.mealStart !== null) {
      mealBreaks.push({
        mealStart: item.mealStart,
        mealEnd: item.mealEnd
      })
    } else {
      for (let index = 1; item[`meal${index}Start`] !== undefined && item[`meal${index}Start`] !== null; index++) {
        mealBreaks.push({
          mealStart: item[`meal${index}Start`],
          mealEnd: item[`meal${index}End`]
        })
      }
    }
    item.mealBreaks = mealBreaks
    return null
  })
  const _list = sortBy(list, ['startDateTime', 'endDateTime'])
  return _list
}
