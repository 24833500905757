import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import { cloneDeep } from 'lodash'

import './NotificationsContainer.scss'
import NotificationList from '../../components/NotificationList'
import Preloader from '../../components/Preloader'
import ActionModal from '../../components/ActionModal'

import { 
  getNotifications, 
  patchMarkNotificationsAsRead, 
  getNotificationsHistory 
} from '../../state/actions/notificationsActions'
import { getPersonDetailsList } from '../../state/actions/personDetailsActions'
import { postListOfProfileImages } from '../../state/actions/profileActions'
import { getPostInfo } from '../../state/actions/postCardActions'

import { 
  isDefined, 
  isDefinedAndNotEmpty,
  nullSafeCheckIsFalse,
  nullSafeCheckIsTrue
} from '../../components/utils/nullSafeCheckUtils'
import { t } from '../../utils/i18n';
import {
  is5Dot0NotificationType
} from '../../components/utils/notificationUtils'
import {
  getVersion5Dot1FeaturesActive
} from '../../components/utils/utils'
import {
  NEW_RECEIVED_COMPLIMENT,
  NewComplimentReceived,
  UNPLANNED_TRAVEL,
  USER_GENERIC_MESSAGE
} from '../../utils/notificationTypeContants'



class NotificationsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tenDaysAgoDate: null,
      scrollToNotificationId: null,
      watchedPost: null,
      showModal: false,
      notificationsLoaded: false,
      notificationsHistoryLoaded: false,
    }

    this.renderNotifications = this.renderNotifications.bind(this)
    this.redirectToPostDetails = this.redirectToPostDetails.bind(this)
    this.scrollToNotification = this.scrollToNotification.bind(this)
  }

  componentDidMount() {
    const _locationState = this.props.location.state

    if (_locationState !== undefined && _locationState.scrollToNotificationId !== undefined) {
      this.scrollToNotification(_locationState.scrollToNotificationId)
    }
    const tenDaysAgoDate = moment().subtract(10, 'days').toISOString()
    this.setState({
      tenDaysAgoDate
    })
    this.props.getNotifications(tenDaysAgoDate)
    this.props.getNotificationsHistory(tenDaysAgoDate)
  }

  componentDidUpdate(prevProps) {
    if (nullSafeCheckIsTrue(prevProps.notificationsPending) && nullSafeCheckIsFalse(this.props.notificationsPending)) {
      this.handleNotificationsDoneLoading()
    }

    if (nullSafeCheckIsTrue(prevProps.notificationsHistoryPending) && nullSafeCheckIsFalse(this.props.notificationsHistoryPending)) {
      this.handleNotificationsHistoryDoneLoading()
    }

    if (prevProps.postCardPending === true && this.props.postCardPending === false) {
      const { watchedPost } = this.state
      const { postInfo, postCardError } = this.props

      if (postCardError && postCardError.status === 404) {
        this.setState({ showModal: true })
        this.scrollToNotification(this.state.scrollToNotificationId)
      } else {
        this.props.history.push({
          pathname: `/news/channel/${postInfo.channelId}/post/${watchedPost}`,
          state: {
            navigatedFromNotification: true,
            commentToNavigateTo: {
              commentType: postInfo.parentId === watchedPost ? 'comment' : 'reply',
              commentId: postInfo.postId,
              commentParentId: postInfo.parentId,
            },
          }
        })
      }
    }
  }

  handleNotificationsDoneLoading = () => {
    this.setState({
      notificationsLoaded: true
    })
    
    if (this.bothNotificationsSystemsDataLoaded(true, this.state.notificationsHistoryLoaded)) {
      this.getNotificationsTeamMemberInformation()
    }
  }

  handleNotificationsHistoryDoneLoading = () => {
    this.setState({
      notificationsHistoryLoaded: true
    })
    
    if (this.bothNotificationsSystemsDataLoaded(this.state.notificationsLoaded, true)) {
      this.getNotificationsTeamMemberInformation()
    }
  }

  getNotificationsTeamMemberInformation = () => {
    const personIds = new Set()

    this.props.notificationsList.map(notification => {
      if (notification.notificationType === NEW_RECEIVED_COMPLIMENT) {
        personIds.add(notification.notificationDetails.senderPersonId)
      } else if (notification.notificationType === 'WATCHED_POST_DETAIL_UPDATED') {
        personIds.add(notification.notificationDetails.updatingPersonId)
      } else if (notification.notificationType === 'EVENT_SCHED_CHANGE_REQ_DENIED'
        || notification.notificationType === 'EVENT_SCHED_CHANGE_REQ_ACCEPT') {
        personIds.add(notification.changeRequest.scheduleChangeRequest.reviewerId)
      } else if (notification.notificationType === 'POST_REPLIED_TO') {
        personIds.add(notification.notificationDetails.personId)
      }
      return null
    })

    this.props.notificationsHistory.map(notification => {
      if (notification.notificationType === NewComplimentReceived) {
        const modelData = JSON.parse(notification.modelData)
        personIds.add(modelData.SenderId)
      }
      return null
    })

    if (personIds.size > 0) {
      this.props.getPersonDetailsList(personIds)
      this.props.postListOfProfileImages(personIds, 'notifications')
    }
  }

  bothNotificationsSystemsDataLoaded = (notificationsLoaded, notificationsHistoryLoaded) => {
    return nullSafeCheckIsTrue(notificationsLoaded) && nullSafeCheckIsTrue(notificationsHistoryLoaded)
  }

  scrollToNotification(notificationId) {
    const notification = document.querySelector(".restore-" + notificationId)
    if (notification) {
      notification.scrollIntoView({ block: "center" })
    }
  }

  redirectToPostDetails(notificationId, postId, commentId) {
    this.setState({
      scrollToNotificationId: notificationId,
      watchedPost: postId,
    })
    this.props.getPostInfo(commentId)
  }

  // Old notifications system compliment received
  renderNewReceivedComplimentItem = (item, index) => {
    const { personDetailsList, profileImages, history } = this.props
    const personIndex = personDetailsList.findIndex(person => person.personId === item.notificationDetails.senderPersonId)
    if (personIndex !== -1) {
      let senderName = personDetailsList[personIndex].firstName + ' ' + personDetailsList[personIndex].lastName
      let userAvatar = profileImages.find(obj => obj.personId === item.notificationDetails.senderPersonId)
      return (
        <NotificationList
          key={index}
          itemData={item}
          senderPersonId={item.notificationDetails.senderPersonId}
          senderName={senderName}
          userAvatar={userAvatar}
          history={history}
        />
      )
    }
  }

  // New notifications system compliment received
  renderNewComplimentReceivedItem = (item, index) => {
    const { personDetailsList, profileImages, history } = this.props
    const personIndex = personDetailsList.findIndex(person => person.personId === item.modelData.SenderId)
    if (personIndex !== -1) {
      let senderName = personDetailsList[personIndex].firstName + ' ' + personDetailsList[personIndex].lastName
      let userAvatar = profileImages.find(obj => obj.personId === item.modelData.SenderId)
      return (
        <NotificationList
          key={index}
          senderPersonId={item.modelData.SenderId}
          senderName={senderName}
          userAvatar={userAvatar}
          itemData={item}
          history={history}
        />
      )
    }
  }

  renderNotifications(item, index) {
    const { personDetailsList, profileImages, history } = this.props
    if (item.notificationType === NEW_RECEIVED_COMPLIMENT) {
      this.renderNewReceivedComplimentItem(item, index)
    } else if (item.notificationType === NewComplimentReceived) {
      this.renderNewComplimentReceivedItem(item, index)
    } else if (item.notificationType === 'WATCHED_POST_DETAIL_UPDATED') {
      const personIndex = personDetailsList.findIndex(person => person.personId === item.notificationDetails.updatingPersonId)
      if (personIndex !== -1) {
        return (
          <NotificationList
            key={index}
            itemData={item}
            senderPersonId={item.notificationDetails.updatingPersonId}
            senderName={item.notificationDetails.updatingPersonName}
            userAvatar={profileImages.find(obj => obj.personId === item.notificationDetails.updatingPersonId)}
            redirectToPostDetails={this.redirectToPostDetails}
            patchMarkNotificationsAsRead={this.props.patchMarkNotificationsAsRead}
            history={history}
          />
        )
      }
    } else if (item.notificationType === 'EVENT_SCHED_CHANGE_REQ_DENIED'
      || item.notificationType === 'EVENT_SCHED_CHANGE_REQ_ACCEPT') {
      const personIndex = personDetailsList.findIndex(person => person.personId === item.changeRequest.scheduleChangeRequest.reviewerId)
      if (personIndex !== -1) {
        return (
          <NotificationList
            key={index}
            itemData={item}
            senderPersonId={item.changeRequest.scheduleChangeRequest.reviewerId}
            senderName={item.changeRequest.scheduleChangeReviewerName}
            userAvatar={profileImages.find(obj => obj.personId === item.changeRequest.scheduleChangeRequest.reviewerId)}
            history={history}
          />
        )
      }
    } else if (item.notificationType === 'POST_REPLIED_TO') {
      const personIndex = personDetailsList.findIndex(person => person.personId === item.notificationDetails.personId)
      if (personIndex !== -1) {
        return (
          <NotificationList
            key={index}
            itemData={item}
            senderPersonId={item.notificationDetails.personId}
            senderName={item.notificationDetails.personName}
            userAvatar={profileImages.find(obj => obj.personId === item.notificationDetails.personId)}
            redirectToPostDetails={this.redirectToPostDetails}
            patchMarkNotificationsAsRead={this.props.patchMarkNotificationsAsRead}
            history={history}
          />
        )
      }
    }
    return <NotificationList itemData={item} key={index} history={history}/>
  }

  handleRefreshNotificationsClick = () => {
    const { tenDaysAgoDate } = this.state

    this.props.getNotifications(tenDaysAgoDate)
    this.props.getNotificationsHistory(tenDaysAgoDate)
  }

  getNoNotificationsMarkUp = () => {
    return (
      <div className="notification-container">
        <h4>{t('no_notifications_found')}</h4>
        <button
          type="button"
          className="btn btn-rgis-red semiBoldText uppercase"
          onClick={() => this.handleRefreshNotificationsClick()}
        >
          {t('refresh')}
        </button>
      </div>
    )
  }

  notificationsListHasItems = (notificationsList) => {
    return isDefined(notificationsList) && notificationsList.length > 0
  }

  objectIsDefined = (objectToCheck) => {
    return objectToCheck !== undefined && objectToCheck !== null
  }

  closeModal = () => {
    this.setState({ showModal: false })
  }

  is5Dot1OldNotificationType = (notificationType) => {
    return  notificationType === UNPLANNED_TRAVEL
  }

  filterOldNotifications = (oldNotifications) => {
    let filteredOldNotifications = []
    let version5Dot1FeaturesActive = getVersion5Dot1FeaturesActive();

    for (let i = 0; i < oldNotifications.length; i++) {
      let is5Dot1OldNotificationType = this.is5Dot1OldNotificationType(oldNotifications[i].notificationType);
      if (!version5Dot1FeaturesActive && is5Dot1OldNotificationType) {
        continue
      }

      filteredOldNotifications.push(cloneDeep(oldNotifications[i]))
    }

    return filteredOldNotifications
  }

  combineNotifications = (oldNotifications, newNotifications) => {
    let filteredOldNotifications = this.filterOldNotifications(oldNotifications)

    let combinedNotifications = cloneDeep(filteredOldNotifications)
    for(let i = 0; i < newNotifications.length; i++) {
      if (!getVersion5Dot1FeaturesActive() && !is5Dot0NotificationType(newNotifications[i].notificationType)) { // only load the 5.0 notification history types if 5.1 is not active
        continue
      }

      combinedNotifications.push({
        id: newNotifications[i].notificationId,
        notificationType: newNotifications[i].notificationType,
        sentDate: newNotifications[i].notificationDateTime,
        title: newNotifications[i].title,
        body: newNotifications[i].body,
        isRead: newNotifications[i].isRead,
        modelData: isDefinedAndNotEmpty(newNotifications[i].modelData) ? JSON.parse(newNotifications[i].modelData) : null,
      })
    }

    return combinedNotifications.sort(function(notification1, notification2){
      return new Date(notification2.sentDate) - new Date(notification1.sentDate);
    });
  }

  render() {
    const { showModal } = this.state
    const {
      notificationsList, 
      notificationsPending,
      personDetailsListPending, 
      profileImagesPending, 
      postCardPending,
      notificationsHistoryPending,
      notificationsHistory
    } = this.props

    if (notificationsPending 
      || personDetailsListPending
      || profileImagesPending 
      || postCardPending
      || notificationsHistoryPending
    ) {
      return <Preloader />
    } 
    
    let combinedNotifications = this.combineNotifications(notificationsList, notificationsHistory)

    if (!this.notificationsListHasItems(combinedNotifications)) {
      return this.getNoNotificationsMarkUp()
    } 

    return (
      <div className="notification-container">
        <div className="d-block">
          <h3>{t('notifications')}</h3>
        </div>
        <div className="notification-list-component">
          {combinedNotifications.map(this.renderNotifications)}
        </div>
        
        <ActionModal
          showModal={showModal}
          modalTitle={''}
          modalText={t('content_not_found')}
          showCancelAction={false}
          removeAction={this.closeModal}
          modalAcceptButtonText={t('ok')}
          modalAcceptAction={this.closeModal}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    notificationsList: state.notificationsReducer.notifications,
    notificationsPending: state.notificationsReducer.notificationsPending,
    notificationsError: state.notificationsReducer.notificationsError,
    personDetailsList: state.personDetailsReducer.personDetailsList,
    personDetailsListPending: state.personDetailsReducer.personDetailsListPending,
    profileImages: state.profileReducer.profileImages,
    profileImagesPending: state.profileReducer.profileImagesPending['notifications'],
    profileImagesError: state.profileReducer.profileImagesError,
    postInfo: state.postCardReducer.postInfo,
    postCardPending: state.postCardReducer.postCardPending,
    postCardError: state.postCardReducer.postCardError,
    profileLocale: state.loginReducer.profileLocale,

    notificationsHistoryPending: state.notificationsReducer.notificationsHistoryPending,
    notificationsHistory: state.notificationsReducer.notificationsHistory,
    notificationsHistoryError: state.notificationsReducer.notificationsHistoryError,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getNotifications: (lastCheck) => getNotifications(lastCheck),
      getPersonDetailsList: (personIds) => getPersonDetailsList(personIds),
      postListOfProfileImages: (personIds, location) => postListOfProfileImages(personIds, location),
      getPostInfo: (postId) => getPostInfo(postId),
      patchMarkNotificationsAsRead: (notificationIds) => patchMarkNotificationsAsRead(notificationIds),
      getNotificationsHistory: (fromDateTime) => getNotificationsHistory(fromDateTime),
    },
    dispatch
  )
}

NotificationsContainer.propTypes = {
  getNotifications: PropTypes.func,
  notificationsList: PropTypes.array,
  notificationsError: PropTypes.object,
  notificationsPending: PropTypes.bool,
  location: PropTypes.object,
  getPersonDetailsList: PropTypes.func,
  personDetailsList: PropTypes.array,
  personDetailsListPending: PropTypes.bool,
  postListOfProfileImages: PropTypes.func,
  profileImages: PropTypes.array,
  profileImagesPending: PropTypes.bool,
  profileImagesError: PropTypes.object,
  getPostInfo: PropTypes.func,
  postInfo: PropTypes.object,
  postCardPending: PropTypes.bool,
  postCardError: PropTypes.object,
  history: PropTypes.object,
  patchMarkNotificationsAsRead: PropTypes.func,
  profileLocale: PropTypes.string,

  getNotificationsHistory: PropTypes.func,
  notificationsHistoryPending: PropTypes.bool,
  notificationsHistory: PropTypes.array,
  notificationsHistoryError: PropTypes.object,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsContainer)
