import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import { sendRequestEventChange } from '../../state/actions/scheduleActions'
import { t } from '../../utils/i18n'
import RequestEventChange from '../Schedule/RequestEventChange'
import moment from 'moment'
import { getFeatureAccessList } from '../../state/actions/featureAccessActions'
import { hasAllowLateChangeRequestsAccess, hasChangeRequests48 } from '../../components/utils/featureAccessUtils'

import './RequestChangeButton.scss'
import { isDefined, nullSafeCheckIsTrue } from '../utils/nullSafeCheckUtils'

class RequestChangeButton extends Component {
  constructor(props) {
    super(props)

    this.state = {
      requestChangeMade: false,
      showRequestModal: false,
    }

    this.handleClick = this.handleClick.bind(this)
    this.submitRequestEventChange = this.submitRequestEventChange.bind(this)
  }


  componentDidMount() {
    this.props.getFeatureAccessList()
  }

  handleClick() {
    this.setState(prevState => ({
      showRequestModal: !prevState.showRequestModal,
    }))
  }

  submitRequestEventChange(reasonCode, eventCode, notes, scheduledEventId, attendingTime) {
    this.props.sendRequestEventChange(reasonCode, eventCode, notes, scheduledEventId, attendingTime)

    this.handleChangeRequestMade()
  }

  handleChangeRequestMade = () => {
    this.setState({
      requestChangeMade: true,
    })
  }

  handleContactsOnClick = () => {
    this.props.history.push('/contacts');
  }

  render() {
    const { eventDetails, requestDetailsList, featureAccessPending, featureAccess } = this.props
    const { requestChangeMade, showRequestModal } = this.state

    if (nullSafeCheckIsTrue(featureAccessPending)) {
      return ''
    }

    const scheduledEventId = eventDetails.scheduledEventId
    const eventDateTime = eventDetails.date + ' ' + eventDetails.time
    const hasPendingRequest = latestChangeRequestIsPending(requestDetailsList)
    const hasAcceptedRequest = changeRequestIsAccepted(eventDetails.changeRequestStatus);
    const hasDeniedRequest = changeRequestIsDenied(eventDetails.changeRequestStatus);
    const eventEndTime = moment(eventDateTime).add(eventDetails.scheduleData.estimatedDuration, 'h').add(-1, "m");
    const eventStartTime = moment(eventDateTime).add(1, "m");
    const lateNoticeTimePeriod = getLateNoticeTimePeriod(featureAccess);
    const needToContactManager = getNeedToContactManager(featureAccess, lateNoticeTimePeriod, eventStartTime);
               
    if (hasPendingRequest || requestChangeMade) {
      return (
        <div className="request-change-button">
          <button type="button" disabled={true} className="btn btn-primary capitalize">{t('status_request_pending')}</button>
        </div>
      )
    } else if (moment().isAfter(moment(eventDateTime))) {
      return ('')
    } else if(needToContactManager){
      return (
        <div>
          <span style={{textTransform: "none"}}>
            {t('late_change_request_contact_manager_text')}&nbsp;&nbsp;
            <a className="cursor-pointer uppercase" onClick={this.handleContactsOnClick}>{t('late_change_request_contact_manager_link')}</a>
          </span>
        </div>
      )
    } else {
      if (!hasPendingRequest && !hasDeniedRequest && hasAcceptedRequest) {
        return (
          <div className="request-change-button">
            <button type="button" disabled={true} className="btn btn-primary capitalize">{t('status_request_accepted')}</button>
          </div>
        )
      } else {
        if (!hasPendingRequest && hasDeniedRequest && !hasAcceptedRequest) {
          return (
            <div className="request-change-button">
              <button type="button" disabled={true} className="btn btn-primary capitalize">{t('status_request_denied')}</button>
            </div>
          )
        } else {
          return (
            <div className="request-change-button">
              <button type="button" className="btn btn-primary capitalize" onClick={this.handleClick}>
                {t('request_change')}
              </button>

              <RequestEventChange
                requestChangeMade={requestChangeMade}
                hourOfEvent={eventDateTime}
                scheduledEventId={scheduledEventId}
                showRequestModal={showRequestModal}
                eventEndTime={eventEndTime}
                eventStartTime={eventStartTime}
                modalRequestChangeClose={this.handleClick}
                submitRequestEventChange={this.submitRequestEventChange}
                featureAccess={this.props.featureAccess}
                history={this.props.history}
                handleChangeRequestMade={this.handleChangeRequestMade}
              />
            </div>
          )
        }
      }
    }
  }
}

function getLateNoticeTimePeriod(featureAccess){
  if (hasChangeRequests48(featureAccess)){
    return 48;
  }

  return 24;
}

function getNeedToContactManager(featureAccess, lateNoticeTimePeriod, eventStartTime){
  var featureExists = isDefined(featureAccess.allowLateChangeRequests);
  if (!featureExists){
    return false;
  }

  var cutoffTime = moment(eventStartTime).subtract(lateNoticeTimePeriod, "h");
  if (!hasAllowLateChangeRequestsAccess(featureAccess) && cutoffTime < moment()){
    return true;
  }

  return false;
}

function latestChangeRequestIsPending(requestDetailsList) {
  if (!requestDetailsList) { return false; }

  const pendingReviewStatus = 'PENDING_REVIEW';
  let latestRequestId = -1;
  let latestRequestStatus = '';

  let leaveEarlyLatestRequest = getLatestRequest(requestDetailsList.EVENT_LEAVE_EARLY)
  if (leaveEarlyLatestRequest && leaveEarlyLatestRequest.changeRequestId > latestRequestId) {
    latestRequestId = leaveEarlyLatestRequest.changeRequestId
    latestRequestStatus = leaveEarlyLatestRequest.status
  }

  let cannotAttendLatestRequest = getLatestRequest(requestDetailsList.EVENT_CANNOT_ATTEND)
  if (cannotAttendLatestRequest && cannotAttendLatestRequest.changeRequestId > latestRequestId) {
    latestRequestId = cannotAttendLatestRequest.changeRequestId
    latestRequestStatus = cannotAttendLatestRequest.status
  }

  let lateLatestRequest = getLatestRequest(requestDetailsList.EVENT_ATTEND_LATE);
  if (lateLatestRequest && lateLatestRequest.changeRequestId > latestRequestId) {
    latestRequestId = lateLatestRequest.changeRequestId
    latestRequestStatus = lateLatestRequest.status
  }

  return pendingReviewStatus === latestRequestStatus;
}

function getLatestRequest(requests) {
  let latestRequestId = -1
  let latestRequest = null

  if (requests) {
    requests.forEach(function (request) {
      if (request.changeRequestId > latestRequestId) {
        latestRequest = {}
        latestRequest.changeRequestId = request.changeRequestId
        latestRequest.status = request.status
      }
    });
  }

  return latestRequest;
}

function changeRequestIsAccepted(changeRequestStatus) {
  const acceptedReviewStatus = 'APPROVED';
  return acceptedReviewStatus === changeRequestStatus
}

function changeRequestIsDenied(changeRequestStatus) {
  const acceptedReviewStatus = 'DENIED';
  return acceptedReviewStatus === changeRequestStatus
}

function mapStateToProps(state) {
  return {
    eventTypes: state.loginReducer.eventTypes,
    eventReasons: state.loginReducer.eventReasons,
    featureAccess: state.featureAccessReducer.featureAccess,
    featureAccessPending: state.featureAccessReducer.featureAccessPending,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      sendRequestEventChange: (reasonCode, eventCode, notes, scheduledEventId, attendingTime) => sendRequestEventChange(reasonCode, eventCode, notes, scheduledEventId, attendingTime),
      getFeatureAccessList: () => getFeatureAccessList(),
    },
    dispatch
  )
}

RequestChangeButton.propTypes = {
  eventDetails: PropTypes.object,
  sendRequestEventChange: PropTypes.func,
  requestDetailsList: PropTypes.object,
  featureAccess: PropTypes.object,
  featureAccessPending: PropTypes.bool,
  getFeatureAccessList: PropTypes.func,
  history: PropTypes.object,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestChangeButton)
