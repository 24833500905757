import React, {Component} from 'react';
class Button extends Component{
  constructor(props) {
    super(props);
    this.changeState = this.changeState.bind(this);
  }

  changeState(e){
    this.props.onClick(e)
  }
  render(){
    return (
      <div className="col icon-label d-none" id={this.props.scheduleId} onClick={this.changeState}>
      </div>
    );
  }
}

export default Button
