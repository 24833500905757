import * as types from './actionTypes'

export function addToastMessage(status, text) {
  return dispatch => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        toastType: status,
        toastMessage: text,
      })
    )
  }
}

export function removeToastMessage(id) {
  return dispatch => {
    dispatch(removeToast(id))
  }
}

const addToast = call => {
  return {
    type: types.ADD_TOAST,
    payload: call,
  }
}
const removeToast = toastId => {
  return {
    type: types.REMOVE_TOAST,
    payload: toastId,
  }
}
