
import axios from 'axios'
import apiConfig from '../../config/api-config'

export function fetchBadges() {
  const url = apiConfig.BADGES
  return axios.get(`${url}`)
}

export function fetchPeopleBadges(personId) {
  const url = apiConfig.PEOPLE_BADGES.replace('[personId]', personId)
  return axios.get(`${url}`)
}


export function fetchEarnedBadges(recentlyEarnedCount) {
  const url = apiConfig.EARNED_BADGES
  return axios.get(`${url}`, {
    recentlyEarnedCount
  })
}

