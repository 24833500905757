import { GET_REQUEST_DETAILS, GET_REQUEST_DETAILS_ERROR, GET_REQUEST_DETAILS_PENDING } from '../actions/actionTypes'

export default function requestDetailsReducer(
  state = {
    requestDetails: {
      organizationNumber: '',
    },
    requestDetailsPending: false,
  },
  action
) {
  switch (action.type) {
    case GET_REQUEST_DETAILS:
      return {
        ...state,
        requestDetails: action.payload,
        requestDetailsPending: false,
      }
    case GET_REQUEST_DETAILS_ERROR:
      return {
        ...state,
        requestDetails: action.payload,
      }
    case GET_REQUEST_DETAILS_PENDING:
      return {
        ...state,
        requestDetailsPending: action.payload,
      }
    default:
      return state
  }
}
