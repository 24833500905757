import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { t } from '../../utils/i18n'

import { getAllPeopleInOrganization } from '../../state/actions/organizationsActions'
import { postListOfProfileImages, resetListOfProfileImages } from '../../state/actions/profileActions'
import { getUserProfile } from '../../state/actions/navigationActions'

import { 
  loggedInUserIsManager 
} from '../../components/utils/localStorageUtils'
import {
  isDefinedAndNotEmpty,
  nullSafeCheckIsFalse,
  nullSafeCheckIsTrue
} from '../../components/utils/nullSafeCheckUtils'

import './MyTeamContainer.scss'
import Preloader from '../../components/Preloader'
import MyTeamListItem from '../../components/MyTeam/MyTeamListItem'

class MyTeamContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      organizationPeople: [],
    }
  }

  componentDidMount() {
    if (!loggedInUserIsManager()) {
      this.props.history.push('/schedule')
    } else {
      this.props.getUserProfile()
    }
  }

  componentDidUpdate(prevProps) {
    if (nullSafeCheckIsFalse(prevProps.profilePending) && nullSafeCheckIsTrue(this.props.profilePending)) {
      this.checkShouldLoadPeopleInOrganization()
      this.props.resetListOfProfileImages()
    }

    if (prevProps.organizationPeoplePending === true && this.props.organizationPeoplePending === false) {
      const personIds = this.props.organizationPeople.reduce((total, person) => {
        if (!total.includes(person.personId)) {
          total.push(person.personId)
        }
        return total
      }, [])
      this.props.postListOfProfileImages(personIds, 'my-team')
      const sortedOrganizationPeople = this.props.organizationPeople.sort(function(a, b){
        if(a.firstName < b.firstName) { return -1; }
        if(a.firstName > b.firstName) { return 1; }
        return 0;
      })
      this.setState({
        organizationPeople: sortedOrganizationPeople,
      })
    }
  }

  checkShouldLoadPeopleInOrganization = () => {
    if (isDefinedAndNotEmpty(this.props.profile.organizationId) && !isNaN(this.props.profile.organizationId)) {
      this.props.getAllPeopleInOrganization(this.props.profile.organizationId)
    } else {
      this.props.history.push('/schedule')
    }
  }

  render() {
    const { organizationPeoplePending, profilePending, profileImagesPending,
      profileImages, history,
    } = this.props
    const { organizationPeople } = this.state

    if (organizationPeoplePending 
      || profilePending 
      || profileImagesPending) {
      return <Preloader />
    }

    return (
      <div className="my-team-container">
        <div className="row">
          <div className="col my-team-header">{t('my_team')}</div>
        </div>
        <div className="my-team-details">
          {
            organizationPeople.map((person) => (
              <MyTeamListItem
                key={person.personId}
                personInfo={person}
                userAvatar={profileImages.find(obj => obj.personId === person.personId)}
                history={history}
              />
            ))
          }
        </div>
      </div >
    )
  }
}

function mapStateToProps(state) {
  return {
    organizationPeoplePending: state.organizationsReducer.organizationPeoplePending,
    organizationPeople: state.organizationsReducer.organizationPeople,
    organizationPeopleError: state.organizationsReducer.organizationPeopleError,
    profileImages: state.profileReducer.profileImages,
    profileImagesPending: state.profileReducer.profileImagesPending['my-team'],
    profileImagesError: state.profileReducer.profileImagesError,
    profileLocale: state.loginReducer.profileLocale,

    profile: state.loginReducer.profile,
    profilePending: state.loginReducer.profilePending,
    profileError: state.loginReducer.profileError,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getAllPeopleInOrganization: (managerOrganizationId) => getAllPeopleInOrganization(managerOrganizationId),
      postListOfProfileImages: (personIds, location) => postListOfProfileImages(personIds, location),
      resetListOfProfileImages: () => resetListOfProfileImages(),
      getUserProfile: () => getUserProfile(),
    },
    dispatch
  )
}

MyTeamContainer.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  getAllPeopleInOrganization: PropTypes.func,
  organizationPeoplePending: PropTypes.bool,
  organizationPeople: PropTypes.array,
  organizationPeopleError: PropTypes.object,
  postListOfProfileImages: PropTypes.func,
  profileImages: PropTypes.array,
  profileImagesPending: PropTypes.bool,
  profileImagesError: PropTypes.object,
  resetListOfProfileImages: PropTypes.func,
  profileLocale: PropTypes.string,

  getUserProfile: PropTypes.func,
  profile: PropTypes.object,
  profilePending: PropTypes.bool,
  profileError: PropTypes.object,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyTeamContainer)
