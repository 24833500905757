import {
  GET_POSTS_SUCCESS,
  GET_POSTS_ERROR,
  GET_POSTS_PENDING,
  RESET_POSTS_STATE,
  GET_CHANNEL_LAST_POST_SUCCESS,
  GET_CHANNEL_LAST_POST_ERROR,
  GET_CHANNEL_LAST_POST_PENDING,
  GET_CHANNEL_ACCESS_SUCCESS,
  GET_CHANNEL_ACCESS_ERROR,
  GET_CHANNEL_ACCESS_PENDING,
  REMOVE_POST_SUCCESS,
  REMOVE_POST_PENDING,
  REMOVE_POST_ERROR,
  TRANSLATE_POST_SUCCESS,
  TRANSLATE_POST_PENDING,
  TRANSLATE_POST_ERROR,
  POST_MODERATION_ITEM_SUCCESS,
  WATCH_POST_ERROR,
  WATCH_POST_PENDING,
  WATCH_POST_SUCCESS,
  UPDATE_POST_REACTION,
} from '../actions/actionTypes'

export default function channelReducer(
  state = {
    channelPosts: [],
    channelError: {},
    channelPending: {},
    lastReceivedChannelPosts: [],
    userChannelAccess: {},
    userChannelAccessError: {},
    userChannelAccessPending: false,
    channelLastPost: [],
    channelLastPostError: {},
    channelLastPostPending: false,
    removePostPending: false,
    removePostError: {},
    translationsPending: false,
    translationsError: {},
    translations: {},
    watchPostPending: false,
    watchPostError: {},
    watchPostSuccess: false,
    scrollToWatchedPostId: null,
    scrollToFlaggedPostId: null,
  },
  action
) {
  switch (action.type) {
    case GET_POSTS_SUCCESS: {
      return {
        ...state,
        channelPosts: [
          ...state.channelPosts,
          ...action.payload
        ],
        lastReceivedChannelPosts: action.payload,
        channelError: {},
      }
    }
    case GET_POSTS_ERROR:
      return {
        ...state,
        channelError: action.error,
      }
    case GET_POSTS_PENDING: {
      const { status, lastPostId } = action
      let _channelPending = state.channelPending
      _channelPending = {
        ..._channelPending,
        [lastPostId]: status
      }
      return {
        ...state,
        channelPending: _channelPending,
      }
    }
    case RESET_POSTS_STATE:
      return {
        channelPosts: [],
        channelError: {},
        channelPending: {},
        lastReceivedChannelPosts: [],
        userChannelAccess: {},
        userChannelAccessError: {},
        userChannelAccessPending: false,
        channelLastPost: [],
        channelLastPostError: {},
        channelLastPostPending: false,
        removePostPending: false,
        removePostError: {},
        translationsPending: false,
        translationsError: {},
        translations: {},
        watchPostPending: false,
        watchPostError: {},
        watchPostSuccess: false,
        scrollToWatchedPostId: null,
        scrollToFlaggedPostId: null,
      }
    case GET_CHANNEL_ACCESS_SUCCESS:
      return {
        ...state,
        userChannelAccess: action.payload,
        userChannelAccessError: {},
      }
    case GET_CHANNEL_ACCESS_ERROR:
      return {
        ...state,
        userChannelAccessError: action.error,
      }
    case GET_CHANNEL_ACCESS_PENDING:
      return {
        ...state,
        userChannelAccessPending: action.status,
      }

    case GET_CHANNEL_LAST_POST_SUCCESS:
      return {
        ...state,
        channelLastPost: state.channelLastPost.concat(action.payload),
        channelLastPostError: {},
        channelLastPostPending: false,
      }
    case GET_CHANNEL_LAST_POST_ERROR:
      return {
        ...state,
        channelLastPostError: action.error,
        channelLastPostPending: false,
      }
    case GET_CHANNEL_LAST_POST_PENDING:
      return {
        ...state,
        channelLastPostPending: true,
      }

    case REMOVE_POST_SUCCESS: {
      const _channelPosts = state.channelPosts.filter((post) => post.postId !== action.payload)
      return {
        ...state,
        channelPosts: _channelPosts,
        removePostError: {},
        removePostPending: false,
      }
    }
    case REMOVE_POST_ERROR:
      return {
        ...state,
        removePostError: action.error,
        removePostPending: false,
      }
    case REMOVE_POST_PENDING:
      return {
        ...state,
        removePostPending: true,
      }
    case TRANSLATE_POST_SUCCESS: {
      const { postId, payload } = action
      let _translations = state.translations
      _translations = {
        ..._translations,
        [postId]: {
          loadedData: true,
          active: true,
          body: payload[0].data,
          title: payload.length === 2 ? payload[1].data : null,
        }
      }
      return {
        ...state,
        translations: _translations,
        translationsError: {}
      }
    }
    case TRANSLATE_POST_ERROR: {
      const { postId, error } = action
      let _translationsError = state.translationsError
      _translationsError = {
        ..._translationsError,
        [postId]: error
      }
      return {
        ...state,
        translationsError: _translationsError
      }
    }
    case TRANSLATE_POST_PENDING:
      return {
        ...state,
        translationsPending: action.status,
      }

    case POST_MODERATION_ITEM_SUCCESS: {
      const _channelPosts = state.channelPosts.map(post =>
        post.postId === action.payload.entityId ?
          { ...post, isFlagged: true }
          :
          post
      )

      return {
        ...state,
        channelPosts: _channelPosts,
        scrollToFlaggedPostId: action.payload.entityId
      }
    }

    case WATCH_POST_SUCCESS: {
      const { postId, payload } = action
      const _channelPosts = state.channelPosts.map(post =>
        post.postId === postId ?
          { ...post, isWatching: payload }
          :
          post
      )
      return {
        ...state,
        channelPosts: _channelPosts,
        scrollToWatchedPostId: postId,
        watchPostPending: false,
        watchPostError: {}
      }
    }
    case WATCH_POST_ERROR: {
      return {
        ...state,
        watchPostPending: false,
        watchPostError: action.error
      }
    }
    case WATCH_POST_PENDING:
      return {
        ...state,
        watchPostPending: true,
      }
    case UPDATE_POST_REACTION: {
      const { postId, reactionType, value } = action
      const _channelPosts = state.channelPosts.map(post =>
        post.postId === postId ?
          (
            reactionType === 'Like'
              ? { ...post, likeCount: post.likeCount + value }
              : { ...post, dislikeCount: post.dislikeCount + value }
          )
          :
          post
      )
      return {
        ...state,
        channelPosts: _channelPosts,
      }
    }

    default:
      return state
  }
}
