import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Redirect } from 'react-router-dom'

import './PublicProfileContainer.scss'
import { t } from '../../utils/i18n'
import { nullSafeCheckIsFalse, nullSafeCheckIsTrue } from '../../components/utils/nullSafeCheckUtils'
import { loggedInUserIsManager } from '../../components/utils/localStorageUtils'
import BadgeItem from '../../components/Badges/BadgeItem'
import Preloader from '../../components/Preloader'
import PostUserAvatar from '../../components/PostUserAvatar'
import { formatPhoneNumber } from '../../components/utils/phoneUtils'
import { createMapHref } from '../../components/utils/mapUtils'
import { deObfuscate } from '../../components/utils/obfuscateUtils'
import { hasComplimentsAccess, hasBadgesAccess } from '../../components/utils/featureAccessUtils'
import { getPersonDetails, getPeopleEarnedBadges, getPeopleReceivedCompliments } from '../../state/actions/publicProfileActions'
import { postListOfProfileImages, resetListOfProfileImages } from '../../state/actions/profileActions'
import { getAllPeopleInOrganization } from '../../state/actions/organizationsActions'
import { getPersonFeatureAccess } from '../../state/actions/featureAccessActions'
import { formatNumber } from '../../components/utils/numberUtils'

import {
  INTEGRITY_VALUE,
  PRIDE_VALUE,
  SERVICE_VALUE,
  TEAMWORK_VALUE,
  INNOVATION_VALUE,
  RESULTS_VALUE
} from '../../utils/constants';

class PublicProfileContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      personId: null,
      badgesRowRef: null,
      personDetails: {
        personId: '',
        badgeId: '',
        employeeNumber: '',
        firstName: '',
        lastName: '',
        fullName: '',
        organizationId: '',
        organizationName: '',
        hrOrganizationId: '',
        hrOrganizationName: '',
        empRole: '',
        currentJob: '',
        payBasis: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        townOrCity: '',
        state: '',
        country: '',
        postalCode: '',
        emailAddress: '',
        businessEmailAddress: '',
        telephoneNumber1: '',
        telephoneNumber2: '',
        avatarUrl: null,
      },
      peopleEarnedBadges: [],
      peopleReceivedCompliments: {
        SERVICE: 0,
        PRIDE: 0,
        INTEGRITY: 0,
        RESULTS: 0,
        INNOVATION: 0,
        TEAMWORK: 0,
      },
      userAvatarId: '',
      userAvatarUrl: '',
      userAvatarIsFlagged: false,
    }

    this.getComplimentsMarkup = this.getComplimentsMarkup.bind(this)
    this.getBadgesMarkup = this.getBadgesMarkup.bind(this)
    this.complimentUserClick = this.complimentUserClick.bind(this)
    this.createMapLink = this.createMapLink.bind(this)
  }

  componentDidMount() {
    const personId = deObfuscate(this.props.match.params.person_id)
    this.setState({ personId: parseInt(personId) })
    this.props.getPersonDetails(personId)
    this.props.resetListOfProfileImages()
    this.props.getPersonFeatureAccess(personId)
    this.props.postListOfProfileImages([personId], 'public-profile')
  }

  componentDidUpdate(prevProps) {
    if (prevProps.personDetailsPending === true && this.props.personDetailsPending === false) {
      this.setState({ personDetails: this.props.personDetails })
      this.props.getAllPeopleInOrganization(this.props.personDetails.organizationId)
    }

    if (nullSafeCheckIsTrue(prevProps.personFeatureAccessPending) && nullSafeCheckIsFalse(this.props.personFeatureAccessPending)) {
      if (hasBadgesAccess(this.props.personFeatureAccess)) {
        this.getPersonBadges(this.state.personId)
      }

      if (hasComplimentsAccess(this.props.personFeatureAccess)) {
        this.props.getPeopleReceivedCompliments(this.state.personId)
      }
    }

    if (prevProps.peopleEarnedBadgesPending === true && this.props.peopleEarnedBadgesPending === false) {
      this.setState({ peopleEarnedBadges: this.props.peopleEarnedBadges })
    }

    if (prevProps.peopleReceivedComplimentsPending === true && this.props.peopleReceivedComplimentsPending === false) {
      this.setState({ peopleReceivedCompliments: this.props.peopleReceivedCompliments })
    }

    if (prevProps.profileImagesPending === true && this.props.profileImagesPending === false) {
      const userAvatar = this.props.profileImages.find(item => item.personId === this.state.personId)
      if (userAvatar) {
        this.setState({
          userAvatarId: userAvatar.id,
          userAvatarUrl: userAvatar.url,
          userAvatarIsFlagged: userAvatar.isFlagged
        })
      }
    }
  }

  getPersonBadges = (personId) => {
    if (hasBadgesAccess(this.props.personFeatureAccess)) {
      this.props.getPeopleEarnedBadges(personId)
    }
  }

  complimentUserClick() {
    const { personDetails } = this.state
    this.props.history.push({
      pathname: '/send-compliments',
      state: {
        eventDetails: null,
        selectedTeamMembers: [{
          personId: personDetails.personId,
          employeeName: `${personDetails.firstName} ${personDetails.lastName}`,
          organizationName: personDetails.organizationName,
          avatarUrl: personDetails.avatarUrl
        }],
        navFromEventPage: false,
        navFromSentComplimentsPage: false,
        navFromPublicProfile: true,
        organizationPeople: this.props.organizationPeople
      }
    })
  }

  getGiveComplimentButtonMarkup() {
    if (hasComplimentsAccess(this.props.featureAccess)) {
      return (
        <div className="row mt-3">
          <div className="col-8 col-md-4">
            <input
              type="button"
              className="m-0 btn btn-rgis-blue cursor-pointer compliment-btn uppercase w-100"
              onClick={this.complimentUserClick}
              value={t('send_compliment_link')}
            />
          </div>
        </div>
      )
    }
  }

  getComplimentsMarkup(peopleReceivedCompliments) {
    if (!hasComplimentsAccess(this.props.personFeatureAccess)) {
      return ''
    } else {
      return (
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col">
                <hr />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="heading">{t('received_compliments')}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-2 col-md-1">
                <div
                  className={peopleReceivedCompliments[SERVICE_VALUE] > 0 ? 'core-value1-fill' : 'core-value1'}
                  title={t('service')}
                  />
              </div>
              <div className="col-2 col-md-1">
                <div
                  className={peopleReceivedCompliments[PRIDE_VALUE] > 0 ? 'core-value2-fill' : 'core-value2'}
                  title={t('pride')}
                  />
              </div>
              <div className="col-2 col-md-1">
                <div
                  className={peopleReceivedCompliments[INTEGRITY_VALUE] > 0 ? 'core-value3-fill' : 'core-value3'}
                  title={t('integrity')}
                />
              </div>
              <div className="col-2 col-md-1">
                <div
                  className={peopleReceivedCompliments[RESULTS_VALUE] > 0 ? 'core-value4-fill' : 'core-value4'}
                  title={t('results')}
                  />
              </div>
              <div className="col-2 col-md-1">
                <div
                  className={peopleReceivedCompliments[INNOVATION_VALUE] > 0 ? 'core-value5-fill' : 'core-value5'}
                  title={t('innovation')}
                  />
              </div>
              <div className="col-2 col-md-1">
                <div
                  className={peopleReceivedCompliments[TEAMWORK_VALUE] > 0 ? 'core-value6-fill' : 'core-value6'}
                  title={t('teamwork')}
                />
              </div>
              <div className="col-2 col-md-7" />
            </div>
            <div className="row">
              <div className="col-2 col-md-1 text-center">
                <div className='core-value-counts'>{formatNumber(peopleReceivedCompliments[SERVICE_VALUE])}</div>
              </div>
              <div className="col-2 col-md-1 text-center">
                <div className='core-value-counts'>{formatNumber(peopleReceivedCompliments[PRIDE_VALUE])}</div>
              </div>
              <div className="col-2 col-md-1 text-center">
                <div className='core-value-counts'>{formatNumber(peopleReceivedCompliments[INTEGRITY_VALUE])}</div>
              </div>
              <div className="col-2 col-md-1 text-center">
                <div className='core-value-counts'>{formatNumber(peopleReceivedCompliments[RESULTS_VALUE])}</div>
              </div>
              <div className="col-2 col-md-1 text-center">
                <div className='core-value-counts'>{formatNumber(peopleReceivedCompliments[INNOVATION_VALUE])}</div>
              </div>
              <div className="col-2 col-md-1 text-center">
                <div className='core-value-counts'>{formatNumber(peopleReceivedCompliments[TEAMWORK_VALUE])}</div>
              </div>
              <div className="col-2 col-md-7" />
            </div>
          </div>
        </div>
      )
    }
  }

  getBadgesMarkup(badges) {
    const { personDetails, badgesRowRef } = this.state;

    if (!hasBadgesAccess(this.props.personFeatureAccess)) {
      return ''
    }

    let numberOfBadges = 0
    let maxNumberOfBadges = 4
    if (badgesRowRef && badgesRowRef.current) {
      maxNumberOfBadges = parseInt(badgesRowRef.current.offsetWidth / 71.05) - 1
    }

    return (
      <div className="cursor-pointer" onClick={() => this.props.history.push(`/badges/${personDetails.personId}`)}>
        <div className="row">
          <div className="col">
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="heading">{t('earned_badges')}</div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="d-flex flex-wrap align-items-center justify-content-around justify-content-sm-start">
              {
                badges.length === 0 ?
                  <div className='no-recent-badges-text'>{t('no_earned_badges')}</div>
                  :
                  badges.map((badge) => {
                    if ((badge.visibleStartDatetime === null && badge.visibleEndDatetime === null)
                      || moment(new Date()).isAfter(new Date(badge.visibleStartDatetime))
                      || moment(new Date()).isBefore(new Date(badge.visibleEndDatetime))) {
                      numberOfBadges += 1
                      if (numberOfBadges <= maxNumberOfBadges) {
                        return (<BadgeItem key={badge.id} showDetails={false} badgeModel={badge} />)
                      }
                      return ''
                    }
                    return ''
                  })
              }
              {numberOfBadges > maxNumberOfBadges &&
                <div className="more-badges d-flex align-items-center justify-content-center">
                  <div>{numberOfBadges - maxNumberOfBadges}+</div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  createMapLink(personDetails) {
    const encodedAddressUrl = createMapHref(personDetails.addressLine1, personDetails.townOrCity, personDetails.state, personDetails.postalCode)
    if (encodedAddressUrl) {
      return (
        <a href={encodedAddressUrl} target="_blank" rel="noopener noreferrer">
          <div>
            {personDetails.addressLine1
              .split(',')
              .filter(Boolean)
              .join(', ')}
          </div>
          <div>
            {personDetails.townOrCity}, {personDetails.state} {personDetails.postalCode}
          </div>
          <div>
            {personDetails.country}
          </div>
        </a>
      )
    }
    return ''
  }

  render() {
    const { personDetails, peopleEarnedBadges, peopleReceivedCompliments,
      userAvatarId, userAvatarUrl, userAvatarIsFlagged
    } = this.state
    const { peopleReceivedComplimentsPending, personDetailsPending,
      peopleReceivedComplimentsError, personDetailsError,
      peopleEarnedBadgesPending, peopleEarnedBadgesError,
      profileImagesPending, history, personFeatureAccessPending
    } = this.props


    if (personDetailsPending
      || peopleEarnedBadgesPending
      || peopleReceivedComplimentsPending
      || profileImagesPending
      || personFeatureAccessPending
    ) {
      return <Preloader />
    }

    if (Object.keys(personDetailsError).length
      || Object.keys(peopleReceivedComplimentsError).length
      || Object.keys(peopleEarnedBadgesError).length
    ) {
      return <Redirect to="/schedule" />
    }

    return (
      <div className="public-profile-container">
        <div className="d-flex align-items-center justify-content-between badges-width">
          <div className="pl-0 public-profile-header">{t('public_profile')}</div>
          <div className="d-flex align-items-center justify-content-end">
            <i
              onClick={history.goBack}
              className="material-icons arrow-back cursor-pointer other-avail-icon"
            >
              arrow_back
            </i>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="card border-0 shadow-none">
              <div className="card-body">
                <div className="person-details">
                  <div className="section">
                    <div className="row personal-info">
                      <div className="col-auto col-md-2 col-xl-1" >
                        <PostUserAvatar
                          personName={`${personDetails.firstName} ${personDetails.lastName}`}
                          userAvatarId={userAvatarId}
                          userAvatarUrl={userAvatarUrl}
                          userAvatarIsFlagged={userAvatarIsFlagged}
                          personId={personDetails.personId}
                          redirectToAvatarPage={true}
                          history={history}
                        />
                      </div>
                      <div className="col d-flex flex-column justify-content-center">
                        <div className="name">{personDetails.firstName} {personDetails.lastName}</div>
                        <div className="organization-name">{personDetails.organizationName}</div>
                        <div className="rank">{personDetails.currentJob}</div>
                      </div>
                    </div>
                  </div>
                  
                  <div className="section">
                    {this.getComplimentsMarkup(peopleReceivedCompliments)}
                    {this.getGiveComplimentButtonMarkup()}
                  </div>
                  
                  <div className="section" ref={this.badgeRowRef}>
                    {this.getBadgesMarkup(peopleEarnedBadges)}
                  </div>

                  {loggedInUserIsManager() &&
                    <>
                      <div className="section">
                        <div className="row">
                          <div className="col">
                            <hr />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="heading">{t('rgis_organization')}</div>
                            <div className="organization">{personDetails.organizationName}</div>
                          </div>
                        </div>
                      </div>

                      <div className="section">
                        <div className="row">
                          <div className="col">
                            <div className="heading">{t('phone_number')}</div>
                            <div className="phone">
                              <a className="mt-2" href={`tel:${formatPhoneNumber(personDetails.telephoneNumber1 || personDetails.telephoneNumber2)}`}>
                                {formatPhoneNumber(personDetails.telephoneNumber1 || personDetails.telephoneNumber2)}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="section">
                        <div className="row">
                          <div className="col">
                            <div className="heading">{t('home_address')}</div>
                            {this.createMapLink(personDetails)}
                          </div>
                        </div>
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    personDetails: state.publicProfileReducer.personDetails,
    personDetailsError: state.publicProfileReducer.personDetailsError,
    personDetailsPending: state.publicProfileReducer.personDetailsPending,
    role: state.loginReducer.profile.role,

    peopleEarnedBadges: state.publicProfileReducer.peopleEarnedBadges,
    peopleEarnedBadgesError: state.publicProfileReducer.peopleEarnedBadgesError,
    peopleEarnedBadgesPending: state.publicProfileReducer.peopleEarnedBadgesPending,

    peopleReceivedCompliments: state.publicProfileReducer.peopleReceivedCompliments,
    peopleReceivedComplimentsError: state.publicProfileReducer.peopleReceivedComplimentsError,
    peopleReceivedComplimentsPending: state.publicProfileReducer.peopleReceivedComplimentsPending,

    profileImages: state.profileReducer.profileImages,
    profileImagesPending: state.profileReducer.profileImagesPending['public-profile'],
    profileImagesError: state.profileReducer.profileImagesError,

    organizationPeople: state.organizationsReducer.organizationPeople,

    featureAccess: state.featureAccessReducer.featureAccess,
    personFeatureAccess: state.featureAccessReducer.personFeatureAccess,
    personFeatureAccessPending: state.featureAccessReducer.personFeatureAccessPending,

    profileLocale: state.loginReducer.profileLocale,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getPersonDetails: (personId) => getPersonDetails(personId),
      getPeopleEarnedBadges: (personId) => getPeopleEarnedBadges(personId),
      getPeopleReceivedCompliments: (personId) => getPeopleReceivedCompliments(personId),
      postListOfProfileImages: (personIds, location) => postListOfProfileImages(personIds, location),
      getAllPeopleInOrganization: (organizationId) => getAllPeopleInOrganization(organizationId),
      getPersonFeatureAccess: (personId) => getPersonFeatureAccess(personId),
      resetListOfProfileImages: () => resetListOfProfileImages(),
    },
    dispatch
  )
}

PublicProfileContainer.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,

  role: PropTypes.string,

  getPersonDetails: PropTypes.func,
  personDetails: PropTypes.object,
  personDetailsError: PropTypes.object,
  personDetailsPending: PropTypes.bool,

  getPeopleEarnedBadges: PropTypes.func,
  peopleEarnedBadges: PropTypes.array,
  peopleEarnedBadgesError: PropTypes.object,
  peopleEarnedBadgesPending: PropTypes.bool,

  getPeopleReceivedCompliments: PropTypes.func,
  peopleReceivedCompliments: PropTypes.object,
  peopleReceivedComplimentsError: PropTypes.object,
  peopleReceivedComplimentsPending: PropTypes.bool,

  getAllPeopleInOrganization: PropTypes.func,
  organizationPeople: PropTypes.array,

  postListOfProfileImages: PropTypes.func,
  profileImages: PropTypes.array,
  profileImagesPending: PropTypes.bool,
  profileImagesError: PropTypes.object,

  featureAccess: PropTypes.object,
  getPersonFeatureAccess: PropTypes.func,
  personFeatureAccessPending: PropTypes.bool,
  personFeatureAccess: PropTypes.object,

  resetListOfProfileImages: PropTypes.func,

  profileLocale: PropTypes.string,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicProfileContainer)
