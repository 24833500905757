
import axios from 'axios'
import apiConfig from '../../config/api-config'

export function getChannels() {
  const url = apiConfig.CHANNELS
  return axios.get(`${url}`)
}

export function watchChannel(channelId, isWatching) {
  let url
  if (isWatching) {
    url = apiConfig.UNWATCH_CHANNEL.replace('[channelId]', channelId)
  }
  else {
    url = apiConfig.WATCH_CHANNEL.replace('[channelId]', channelId)
  }
  return axios.put(url)
}
