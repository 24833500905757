export function formatPhoneNumber(phoneNumber) {
    if (phoneNumber === undefined || phoneNumber === null) {
        return ''
    }

    let newPhoneNumber = removeNonNumericCharacters(phoneNumber)

    if (newPhoneNumber.length === 10) {
        return newPhoneNumber.substr(0, 3) + '-' + newPhoneNumber.substr(3, 3) + '-' +  newPhoneNumber.substr(6, 4)
    } else if (newPhoneNumber.length === 11) {
        return newPhoneNumber.substr(0, 1) + '-' + newPhoneNumber.substr(1, 3) + '-' + newPhoneNumber.substr(4, 3) + '-' + newPhoneNumber.substr(7, 4)
    }

    return phoneNumber
}

function removeNonNumericCharacters(stringToCheck) {
    let newString = ''

    for (var i = 0; i < stringToCheck.length; i++) {
        if(isNaN(stringToCheck.charAt(i)) || /\s/.test(stringToCheck.charAt(i))) {
            continue
        }

        newString = newString.concat(stringToCheck.charAt(i))
    }

    return newString
}