import React, { Component } from 'react'
import { Redirect, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import { getLoggedInPersonId } from '../utils/localStorageUtils'
import { nullSafeCheckIsTrue } from '../utils/nullSafeCheckUtils'
import { getMonthAndDay, getDayOfWeek } from '../utils/datetimeUtils'

import Preloader from '../Preloader'
import TeamDetails from '../TeamDetails'
import { isEmpty } from 'lodash'

import './EventDetails.scss'

import { Tabs, Tab } from '../Tabs'
import ASETInfo from '../ASETInfo'
import MeetInfo from '../MeetInfo'
import RequestChangeButton from '../RequestChangeButton'
import StoreData from '../StoreData'
import StoreInfo from '../StoreInfo'
import SupervisorInfo from '../SupervisorInfo'
import { t } from '../../utils/i18n'
import { isBetweenHours } from '../utils/datetimeUtils'
import { hasChatAccess, hasComplimentsAccess, hasFeedbackAccess } from '../../components/utils/featureAccessUtils'
import EventChatContainer from '../../containers/EventChatContainer';

class EventDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sendComplimentModal: false,
    }
  }

  showFeedbackBanner() {
    const { eventDetails, featureAccess } = this.props
    if (hasFeedbackAccess(featureAccess) && isBetweenHours(eventDetails.date, eventDetails.time)) {
      return (
        <div className="feedback-banner text-center py-3 mb-2">
          <Link
            to={{
              pathname: `/surveys/${eventDetails.scheduledEventId}`,
              state: { fromEventDetails: true }
            }}
          >
            {t('provide_feedback')}</Link>
        </div>
      )
    }
    return ''
  }

  getIsDriver = () => {
    let isDriver = false
    let personId = getLoggedInPersonId()

    for (let i = 0; i < this.props.teamDetailsList.length; i++) {
      if (nullSafeCheckIsTrue(this.props.teamDetailsList[i].driver) && parseInt(this.props.teamDetailsList[i].personId) === parseInt(personId)) {
        isDriver = true
        break
      }
    }

    return isDriver
  }

  getVehicleType = () => {
    let vehicleType = ''
    let personId = getLoggedInPersonId()

    for (let i = 0; i < this.props.teamDetailsList.length; i++) {
      if (nullSafeCheckIsTrue(this.props.teamDetailsList[i].driver) && parseInt(this.props.teamDetailsList[i].personId) === parseInt(personId)) {
        vehicleType = this.props.teamDetailsList[i].vehicleType
        break
      }
    }

    return vehicleType
  }

  sendComplimentClick = () => {
    const { teamDetailsList, eventDetails } = this.props
    this.props.history.push({
      pathname: '/send-compliments',
      state: {
        eventTeamMembers: teamDetailsList,
        eventDetails: eventDetails,
        navFromEventPage: true,
        navFromSentComplimentsPage: false,
      }
    })
  }

  renderEventChatTab = (eventDetails) => {
    if (hasChatAccess(this.props.featureAccess)) {
      return (<Tab id="event_chat" title={t('chat')}>
        <div className="row">
          <div className="col">
            <EventChatContainer
              eventDetails={eventDetails}
              history={this.props.history}
              scheduledStoreId={eventDetails.scheduledStoreId}
            />
          </div>
        </div>
      </Tab>)
    } else {
      return ''
    }
  }

  renderEventChatSection = (eventDetails) => {
    if (hasChatAccess(this.props.featureAccess)) {
      return (
        <div>
          <h5 className="text-uppercase ml-1 mt-3">{t('chat')}</h5>
          <EventChatContainer
            eventDetails={eventDetails}
            history={this.props.history}
            scheduledStoreId={eventDetails.scheduledStoreId}
          />
        </div>
      )
    } else {
      return ''
    }
  }

  getChatHasNewMessages = (chatItem) => {
    if (hasChatAccess(this.props.featureAccess)) {
      return chatItem.newPostCount !== 0 ? true : false
    } else {
      return false
    }
  }

  getChatItemIsValid = () => {
    if (hasChatAccess(this.props.featureAccess)) {
      const { chatItem } = this.props
      if (chatItem && Object.keys(chatItem).length) {
        return true
      }
      return false
    }
    return true
  }

  getComplimentMarkUpDesktop = () => {
    if (hasComplimentsAccess(this.props.featureAccess)) {
      return (
        <div className="cursor-pointer mr-2" onClick={this.sendComplimentClick}>
          <i className="material-icons">favorite_border</i>
        </div>)
    }

    return ''
  }

  getComplimentMarkUpMobile = () => {
    if (hasComplimentsAccess(this.props.featureAccess)) {
      return (
        <div className="cursor-pointer mr-2 text-right" onClick={this.sendComplimentClick}>
          <i className="material-icons">favorite_border</i>
        </div>)        
    }

    return ''
  }

  render() {
    const {
      eventDetails, eventDetailsPending, eventDetailsError,
      teamDetailsListPending, requestDetailsList, requestDetailsListPending,
      eventAph, eventAphPending, eventMeetSite,
      eventMeetSitePending, chatItem, chatItemPending,
      profileImages, profileImagesPending, history,
    } = this.props

    if (!isEmpty(eventDetailsError)) {
      return (<Redirect to="/schedule/" />)
    }

    if (eventDetailsPending || teamDetailsListPending || requestDetailsListPending
      || eventAphPending || eventMeetSitePending || chatItemPending
      || profileImagesPending || !this.getChatItemIsValid()
    ) {
      return <Preloader />
    }

    const { scheduleData } = eventDetails

    const isSupervisor = eventDetails && eventDetails.supervisor

    const dayOfTheWeek = getDayOfWeek(eventDetails.date)
    const monthAndYear = getMonthAndDay(eventDetails.date)

    return (
      <div className="component-event-details">
        {this.showFeedbackBanner()}
        <div>
          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-body">
                  {window.innerWidth <= 992 && <div className="tabbed">
                    <Tabs activeTab={{ id: 'details' }} chatHasNewMessages={this.getChatHasNewMessages(chatItem)}>
                      <div className="row header">
                        <div className="col">
                          <div className="date">{`${dayOfTheWeek} | ${monthAndYear}`}</div>
                        </div>
                      </div>
                      <Tab id="details" title={t('details')}>
                        <div className="row">
                          <div className="col">
                            <StoreInfo scheduleData={scheduleData} eventDetails={eventDetails} isSupervisor={isSupervisor} isDriver={this.getIsDriver()} vehicleType={this.getVehicleType()}/>
                            <MeetInfo scheduleData={scheduleData} eventDetails={eventDetails} eventMeetSite={eventMeetSite} />
                            <SupervisorInfo
                              scheduleData={scheduleData}
                              eventDetails={eventDetails}
                              userAvatar={profileImages.find(obj => obj.personId === parseInt(eventDetails.supervisorPersonId))}
                              history={history}
                            />
                            <RequestChangeButton eventDetails={eventDetails} requestDetailsList={requestDetailsList} history={this.props.history} />
                          </div>
                        </div>
                      </Tab>
                      <Tab id="team" title={t('team')}>
                        { this.getComplimentMarkUpMobile() }                        
                        <div className="row">
                          <div className="col">
                            <TeamDetails
                              showTeamDetailsList={true}
                              isSupervisor={isSupervisor}
                              profileImages={profileImages}
                              history={history}
                              eventDate={eventDetails.date}
                            />
                          </div>
                        </div>
                      </Tab>
                      <Tab id="aset" title="Aset">
                        <div className="row">
                          <div className="col">
                            <ASETInfo eventDetails={eventDetails} eventAph={eventAph} />
                          </div>
                        </div>
                      </Tab>
                      <Tab id="store" title={t('store')}>
                        <div className="row">
                          <div className="col">
                            <StoreData eventDetails={eventDetails} scheduleData={scheduleData} />
                          </div>
                        </div>
                      </Tab>
                      {this.renderEventChatTab(eventDetails)}
                    </Tabs>
                  </div>
                  }

                  {window.innerWidth > 992 && <div className="non-tabbed">
                    <div className="row header">
                      <div className="col">
                        <div className="store-title">{scheduleData.title}</div>
                        <div className="date">{`${dayOfTheWeek}, ${monthAndYear}`}</div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col col-lg-4">
                        <h5 className="text-uppercase ml-1">{t('details')}</h5>
                        <StoreInfo scheduleData={scheduleData} eventDetails={eventDetails} isSupervisor={isSupervisor} isDriver={this.getIsDriver()} vehicleType={this.getVehicleType()} />
                        <MeetInfo scheduleData={scheduleData} eventDetails={eventDetails} eventMeetSite={eventMeetSite} />
                        <SupervisorInfo
                          scheduleData={scheduleData}
                          eventDetails={eventDetails}
                          userAvatar={profileImages.find(obj => obj.personId === parseInt(eventDetails.supervisorPersonId))}
                          history={history}
                        />
                        <RequestChangeButton eventDetails={eventDetails} requestDetailsList={requestDetailsList} history={this.props.history} />
                        {this.renderEventChatSection(eventDetails)}
                      </div>

                      <div className="col col-lg-4">
                        <div className="d-flex justify-content-between">
                          <h5 className="text-uppercase ml-1">{t('team')}</h5>
                          { this.getComplimentMarkUpDesktop() }
                        </div>
                        <TeamDetails
                          showTeamDetailsList={true}
                          isSupervisor={isSupervisor}
                          profileImages={profileImages}
                          history={history}
                          eventDate={eventDetails.date}
                        />
                      </div>
                      <div className="col col-lg-4">
                        <h5 className="text-uppercase ml-1">Aset</h5>
                        <ASETInfo eventDetails={eventDetails} eventAph={eventAph} />
                        <h5 className="text-uppercase ml-1 mt-3">{t('store')}</h5>
                        <StoreData eventDetails={eventDetails} scheduleData={scheduleData} />
                      </div>
                    </div>
                  </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    eventDetails: state.eventDetailsReducer.eventDetails,
    eventDetailsPending: state.eventDetailsReducer.eventDetailsPending,
    eventDetailsError: state.eventDetailsReducer.eventDetailsError,
    teamDetailsListPending: state.teamDetailsListReducer.teamDetailsListPending,
    teamDetailsList: state.teamDetailsListReducer.teamDetailsList,
    requestDetailsListPending: state.requestDetailsListReducer.requestDetailsListPending,
    requestDetailsList: state.requestDetailsListReducer.requestDetailsList,
    eventAph: state.eventDetailsReducer.eventAph,
    eventAphPending: state.eventDetailsReducer.eventAphPending,
    eventMeetSite: state.eventDetailsReducer.eventMeetSite,
    eventMeetSitePending: state.eventDetailsReducer.eventMeetSitePending,
    chatItem: state.eventChatReducer.chatItem,
    chatItemPending: state.eventChatReducer.chatItemPending,
    profileImages: state.profileReducer.profileImages,
    profileImagesPending: state.profileReducer.profileImagesPending['event'],
    featureAccess: state.featureAccessReducer.featureAccess,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch)
}

EventDetails.propTypes = {
  eventDetailsError: PropTypes.object,
  eventDetails: PropTypes.object,
  eventDetailsPending: PropTypes.bool,
  teamDetailsListPending: PropTypes.bool,
  teamDetailsList: PropTypes.array,
  requestDetailsList: PropTypes.object,
  requestDetailsListPending: PropTypes.bool,
  eventAph: PropTypes.number,
  eventAphPending: PropTypes.bool,
  eventMeetSite: PropTypes.object,
  eventMeetSitePending: PropTypes.bool,
  history: PropTypes.object,
  chatItem: PropTypes.object,
  chatItemPending: PropTypes.bool,
  profileImages: PropTypes.array,
  profileImagesPending: PropTypes.bool,
  featureAccess: PropTypes.object,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventDetails)
