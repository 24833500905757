import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import { createMapHref } from '../../utils/mapUtils'
import carSvg from '../../../styles/images/car.svg'
import supervisorSvg from '../../../styles/images/supervisor.svg'
import { nullSafeCheckIsTrue } from '../../utils/nullSafeCheckUtils'
import AddToCalendarHOC from 'react-add-to-calendar-hoc';
import Dropdown from './Calendar/Dropdown';
import Button from './Calendar/Button';

import { confirmEvent } from '../../../state/actions/scheduleActions'
import { addToastMessage } from '../../../state/actions/toastsActions'
import { getEventDetails } from '../../../state/actions/eventDetailsActions'

import { 
  hasChatAccess,
  hasEstimatedDurationAccess
} from '../../utils/featureAccessUtils'
import { t } from '../../../utils/i18n'
import { formatTime, formatDate, dateIsInThePast } from '../../utils/datetimeUtils'
import { formatNumber } from '../../utils/numberUtils'

import './ScheduledItem.scss'

let selectedIdFromButton = "";

class ScheduledItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeDropDown: false,
      selectedId: ''
    };

    this.handleRequestChange = this.handleRequestChange.bind(this)
    this.getEventDetailsIcons = this.getEventDetailsIcons.bind(this)
    this.confirmEvent = this.confirmEvent.bind(this)
    this.calendarClickHandle = this.calendarClickHandle.bind(this)
  }

  componentDidUpdate(prevProps){
    if(prevProps.eventDetailsPending === true && this.props.eventDetailsPending === false){
      const calendarID = document.getElementById(this.props.eventDetails.scheduledEventId);
      const dropDownCalendarID = document.getElementById("dd" + this.props.eventDetails.scheduledEventId);
      if (dropDownCalendarID === null) {
        calendarID.click()
      }
    }
  }


  getEventDetailsIcons(item) {
    return (
      <div className="row">
        {this.getSupervisorMarkUp(item.supervisor)}
        {this.getDriverMarkUp(item.driver)}
      </div>
    )
  }

  getSupervisorMarkUp = (isSupervisor) => {
    return isSupervisor ?
      (<div className='col'><img className='store-info-icon' src={supervisorSvg} alt="Supervisor" /> {t('supervisor')}</div>)
      : ''
  }

  getDriverMarkUp = (isDriver) => {
    return isDriver ?
      (<div className='col'><img className='store-info-icon' src={carSvg} alt="Driver" /> {t('driver')}</div>)
      : ''
  }

  confirmEvent(item) {
    if (!this.props.confirmEventPending) {
      this.props.confirmEvent(new Array(item.scheduledEventId))
    }
  }

  hasConfirmedEvent = (scheduledEventId) => {
    const { confirmEventIds } = this.props

    if (confirmEventIds === undefined || confirmEventIds === null) { 
      return false
    }

    for(let i = 0; i < confirmEventIds.length; i++) {
      if (scheduledEventId === confirmEventIds[i]) {
        return true
      }
    }

    return false
  }

  renderConfirmationMarkUp(item) {
    if (item.confirmed === 'confirmed' || this.hasConfirmedEvent(item.scheduledEventId)) {
      return (
        <div className="mt-2 col d-flex confirmed-event-checkbox">
          <i className={`material-icons mr-1 check-circle medium active`}>done</i>
          {t('you_have_been_confirmed')}
        </div>
      )
    } else if (dateIsInThePast(item.date)) {
      return (
        <div className="col confirmed-event-checkbox">
          <i className={`material-icons check-circle medium`}>
            done
          </i>
        </div>)
    } else if (nullSafeCheckIsTrue(this.props.showConfirmButton)) {
      const handleClick = () => this.confirmEvent(item)
      return (
        <div className="col">
          <input
            type='button'
            className='col btn-confirm'
            onClick={handleClick}
            value={t('confirm').toUpperCase()}
          />
        </div>
      )
    } else {
      return ''
    }
  }

  handleRequestChange(e) {
    const hourOfEvent = formatDate(this.props.dataToRender.item.date) + ' ' + formatTime(this.props.dataToRender.item.time)
    const currentEventId = e.target.dataset.currenteventid
    this.props.handleModalOpenRequestChange(currentEventId, hourOfEvent)
  }

  createStoreAddressMarkUp = (scheduleData) => {
    if (this.addressIsDefined(scheduleData.address, scheduleData.city, scheduleData.state, scheduleData.zipCode)) {
      return (
        <div className="col job-location-col">
          <div>
            {scheduleData.address
              .split(',')
              .filter(Boolean)
              .join(', ')}
          </div>
          <div>
            {scheduleData.city}, {scheduleData.state} {scheduleData.zipCode}
          </div>
        </div>
      )
    }
    return ''
  }

  addressIsDefined = (address, city, state, zipCode) => {
    if (address !== null || address !== undefined || address !== '') {
      return true
    } else if (city !== null || city !== undefined || city !== '') {
      return true
    } else if (state !== null || state !== undefined || state !== '') {
      return true
    } else if (zipCode !== null || zipCode !== undefined || zipCode !== '') {
      return true
    }

    return false
  }

  eventHasMeetSite = (scheduleData) => {
    const STORE_MEET_SITE = 'STORE'
    if (scheduleData && scheduleData.meetSite) {
      return scheduleData.meetSite.toUpperCase() !== STORE_MEET_SITE
    } else {
      return false
    }
  }

  createStoreMeetSiteLabelMarkUp = (scheduleData) => {
    if (this.eventHasMeetSite(scheduleData)) {
      return (
        <div className="row job-location-row">
          <div className="col meet-site-label">
            {t('meet_site')}
          </div>
        </div>
      )
    } else {
      return ''
    }
  }

  createStoreMeetSiteNameMarkUp = (scheduleData) => {
    if (this.eventHasMeetSite(scheduleData)) {
      return (
        <div className="row job-location-row">
          <div className="col-1 map-point-icon"></div>
          <div className="col">
            {scheduleData.meetSite}
          </div>
        </div>
      )
    } else {
      return ''
    }
  }


  createStoreAddressLinkMarkUp = (scheduleData) => {
    const encodedAddressUrl = createMapHref(scheduleData.address, scheduleData.city, scheduleData.state, scheduleData.zipCode)
    if (encodedAddressUrl) {
      return (
        <div className="col-1 map-point-icon">
          <a href={encodedAddressUrl} target="_blank" rel="noopener noreferrer">
            <i className="material-icons map">place</i>
          </a>
        </div>
      )
    }
    return ''
  }

  createDetailsLinkMarkup = (dataToRender) => {
    if (dataToRender.item.scheduledEventId) {
      return (
        <div className="col job-name-col cursor-pointer">
          <Link to={{
            pathname: `/schedule/${dataToRender.item.scheduledEventId}`,
            state: { scheduledStoreId: dataToRender.item.scheduledStoreId }
          }}>{dataToRender.item.scheduleData.bannerName === null ? dataToRender.item.scheduleData.title : dataToRender.item.scheduleData.bannerName}</Link>
        </div>
      )
    }
    return ''
  }

  renderChatDetails = (dataToRender) => {
    if (hasChatAccess(this.props.featureAccess)) {
      return (
        <div className={`row chat-info ${dataToRender.chatInfo && (dataToRender.chatInfo.newPostCount === 0 ? 'disabled' : '')}`}>
          <div className="col icon-label">
            <i className="material-icons forum mr-2">forum</i>
            {dataToRender.chatInfo &&
              (dataToRender.chatInfo.newPostCount === undefined || dataToRender.chatInfo.newPostCount === null || dataToRender.chatInfo.newPostCount === 0
                ? <span>{t('no_new_messages')}</span>
                : <span>{t('new_messages', [dataToRender.chatInfo.newPostCount])}</span>
              )
            }
          </div>
        </div>
      )
    } else {
      return ''
    }
  }

  calendarClickHandle(id) {
    selectedIdFromButton = id;
    if (Object.entries(this.props.eventDetails).length === 0) {
      this.props.getEventDetails(id)
    } else {
      if (this.props.eventDetails.scheduledEventId !== id) {
        this.props.getEventDetails(id)
      } else {
        const calendarID = document.getElementById(id);
        calendarID.click();
      }
    }
  }

  renderCalendar = (data) => {
    const eventDetails = this.props.eventDetails;
    let supervisorName = "";
    let driverName = "";
    let meetSiteAdress = "";
    let description = "";
    let meetSiteCity = "";
    let meetSiteState = "";
    let meetSiteZipCode = "";

    if(Object.entries(eventDetails).length !== 0){
      supervisorName = eventDetails.supervisorName !== undefined ? eventDetails.supervisorName !== null ? eventDetails.supervisorName : t('no') : t('no');
      driverName = eventDetails.driverDetails.length !== 0 ? eventDetails.driverDetails[0].driverName : t('no');
      meetSiteAdress = eventDetails.meetSiteAddress !== null ? eventDetails.meetSiteAddress +', ': '' ;
      meetSiteCity = eventDetails.meetSiteCity !== null ? eventDetails.meetSiteCity +', ': '' ;
      meetSiteState = eventDetails.meetSiteState !== null ? eventDetails.meetSiteState +' ': '' ;
      meetSiteZipCode = eventDetails.meetSiteZipCode !== null ? eventDetails.meetSiteZipCode : '' ;
    }
    const completeMeetSiteAdress = meetSiteAdress + meetSiteCity + meetSiteState + meetSiteZipCode;

     const eventBegin = moment.utc(new Date(data.item.date + ' ' +data.item.scheduleData.meetTime)).format('YYYYMMDDTHHmmssZ');
     const eventEnd = moment.utc(new Date(data.item.date + ' ' +data.item.time)).add(data.item.scheduleData.estimatedDuration, 'hours').format('YYYYMMDDTHHmmssZ');
     if(completeMeetSiteAdress !== ''){
     description = ' '
     +t('driver')+': '+driverName+' ; \r\n '
     +t('supervisor')+": "+supervisorName +' ; \r\n '
     +t('meet_site')+": " +data.item.scheduleData.meetSite +' ; \r\n '
     +t('meet_site_adress')+": " +completeMeetSiteAdress +' ; \r\n '
     +t('store_adress')+': ' +data.item.scheduleData.address +', ' +data.item.scheduleData.city +', ' +data.item.scheduleData.state +' ' +data.item.scheduleData.zipCode;
   }else if(data.item.scheduleData.meetSite === "STORE"){
     description = ' '
     +t('driver')+': '+driverName+' ; \r\n '
     +t('supervisor')+": "+supervisorName +' ; \r\n '
     +t('store_adress')+': ' +data.item.scheduleData.address +', ' +data.item.scheduleData.city +', ' +data.item.scheduleData.state +' ' +data.item.scheduleData.zipCode;
   }else{
     description = ' '
     +t('driver')+': '+driverName+' ; \r\n '
     +t('supervisor')+": "+supervisorName +' ; \r\n '
     +t('meet_site')+": " +data.item.scheduleData.meetSite +' ; \r\n '
     +t('store_adress')+': ' +data.item.scheduleData.address +', ' +data.item.scheduleData.city +', ' +data.item.scheduleData.state +' ' +data.item.scheduleData.zipCode;
   }
     const duration = moment(data.item.scheduleData.estimatedDuration, 'HH').format('HHmm');



     const event = {
       description: description,
       duration : duration,
       endDatetime: eventEnd,
       location: data.item.scheduleData.meetSite === 'STORE' ? data.item.scheduleData.meetSite : data.item.scheduleData.address +', ' +data.item.scheduleData.city +', ' +data.item.scheduleData.state +' ' +data.item.scheduleData.zipCode,
       startDatetime: eventBegin,
       title: data.item.scheduleData.bannerName === null ? data.item.scheduleData.title : data.item.scheduleData.bannerName,
     }
     const AddToCalendarDropdown = AddToCalendarHOC(Button, Dropdown);
     return(
      <div className={`row calendar-info`} >
        <div className={`col`}>
          <AddToCalendarDropdown
            className="calendar-component"
            event={event}
            buttonProps={{ scheduleId: data.item.scheduledEventId }}
            dropdownProps={{ scheduleId: "dd" + data.item.scheduledEventId }}
          />
        </div>
      </div>
    )
  }
  renderCalendarButton = (data) => {
    let loadingBar = 'd-none';
    if (this.props.eventDetailsPending) {
      loadingBar = selectedIdFromButton === data.item.scheduledEventId ? '' : 'd-none'
    }
    return (
      <div className={`row calendar-info`}>
        <div className="col icon-label" onClick={() => this.calendarClickHandle(data.item.scheduledEventId)}>
          <i className="material-icons calendar_today mr-2">calendar_today</i>
          <span className="calendar-text" >{t('add_to_calendar')}</span>
          <div className={`spinner-border ${loadingBar}`} role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    )
  }

  getEventDurationMarkUp = (estimatedDuration) => {
    if (!hasEstimatedDurationAccess(this.props.featureAccess)) {
      return ''
    }

    return (
      <div className="col job-duration icon-label">
        <i className="material-icons timelapse">timelapse</i>
        <span>{formatNumber(estimatedDuration) + ` ${t('hours')}`}</span>
      </div>
    )
  }

  render() {
    const { dataToRender, monthSchedulePending, confirmEventPending } = this.props

    if (monthSchedulePending || confirmEventPending) { 
      return ('')
    }

    return (
      <div className="card">
        <div className="card-body row schedule-content-card-row">
          <div className="col schedule-content-card-col">
            <div className="row job-info-row">
              <div className="col job-info-col">
                <div className="row job-name-row">
                  {this.createDetailsLinkMarkup(dataToRender)}
                </div>
                <div className="row job-location-row">
                  {this.createStoreAddressLinkMarkUp(dataToRender.item.scheduleData)}
                  {this.createStoreAddressMarkUp(dataToRender.item.scheduleData)}
                </div>
                {this.createStoreMeetSiteLabelMarkUp(dataToRender.item.scheduleData)}
                {this.createStoreMeetSiteNameMarkUp(dataToRender.item.scheduleData)}
                <div className="row job-info">
                  <div className="col job-start-time icon-label">
                    <i className="material-icons access_time">access_time</i>
                    <span>{formatTime(dataToRender.item.scheduleData.meetTime)}</span>
                  </div>
                  {this.getEventDurationMarkUp(dataToRender.item.scheduleData.estimatedDuration)}
                </div>
              </div>
            </div>
            {this.getEventDetailsIcons(dataToRender.item)}
            {this.renderChatDetails(dataToRender)}
            {this.renderCalendarButton(dataToRender)}
            {this.renderCalendar(dataToRender)}
            <div className="row">{this.renderConfirmationMarkUp(dataToRender.item)}</div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    eventDetails: state.eventDetailsReducer.eventDetails,
    eventDetailsPending: state.eventDetailsReducer.eventDetailsPending,

    scheduleStartDate: state.scheduleReducer.scheduleStartDate,
    scheduleEndDate: state.scheduleReducer.scheduleEndDate,

    confirmEventSuccess: state.confirmEventReducer.confirmEventSuccess,
    confirmEventPending: state.confirmEventReducer.confirmEventPending,
    confirmEventError: state.confirmEventReducer.confirmEventError,
    confirmEventIds: state.confirmEventReducer.confirmEventIds,

    monthSchedulePending: state.scheduleReducer.monthSchedulePending,

    featureAccess: state.featureAccessReducer.featureAccess,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addToastMessage: (status, text) => addToastMessage(status, text),
      getEventDetails: scheduledEventId => getEventDetails(scheduledEventId),
      confirmEvent: (unconfirmedEventIds) => confirmEvent(unconfirmedEventIds),
    },
    dispatch
  )
}

ScheduledItem.propTypes = {
  eventDetails: PropTypes.object,
  eventDetailsPending: PropTypes.bool,
  getEventDetails: PropTypes.func,
  dataToRender: PropTypes.object,
  selectedDateIsBeforeCurrentWeek: PropTypes.bool,
  handleModalOpenRequestChange: PropTypes.func,
  addToastMessage: PropTypes.func,
  confirmEvent: PropTypes.func,
  confirmEventPending: PropTypes.bool,
  confirmEventIds: PropTypes.array,
  scheduleStartDate: PropTypes.string,
  scheduleEndDate: PropTypes.string,
  monthSchedulePending: PropTypes.bool,
  featureAccess: PropTypes.object,
  showConfirmButton: PropTypes.bool,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduledItem)
