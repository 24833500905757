import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import configureStore, { history } from './state/store'
import * as serviceWorker from './utils/serviceWorker'

import App from './components/App'

const store = configureStore()

const renderComponent = Component => {
  return render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Component />
      </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
  )
}

renderComponent(App)

if (module.hot) {
  module.hot.accept('./components/App', () => {
    const NextApp = require('./components/App').default
    renderComponent(NextApp)
  })
}

serviceWorker.unregister()
