import * as types from './actionTypes'
import services from '../services'

export function getPersonDetails(personId) {
  return dispatch => {
    dispatch(getPersonDetailsPending(true))
    services.publicProfile
      .fetchPersonDetails(personId)
      .then(response => {
        dispatch(getPersonDetailsSuccess(response))
        dispatch(getPersonDetailsPending(false))
      })
      .catch(error => {
        dispatch(getPersonDetailsError(error))
        dispatch(getPersonDetailsPending(false))
      })
  }
}
const getPersonDetailsSuccess = response => {
  return {
    type: types.GET_PUBLIC_USER_DETAILS_SUCCESS,
    payload: response.data,
  }
}
const getPersonDetailsError = error => {
  return {
    type: types.GET_PUBLIC_USER_DETAILS_ERROR,
    payload: error,
  }
}
const getPersonDetailsPending = status => {
  return {
    type: types.GET_PUBLIC_USER_DETAILS_PENDING,
    status,
  }
}

export function getPeopleEarnedBadges(personId) {
  return dispatch => {
    dispatch(getPeopleEarnedBadgesPending(true))
    services.publicProfile
      .fetchPeopleReceivedBadges(personId)
      .then(response => {
        dispatch(getPeopleEarnedBadgesSuccess(response))
        dispatch(getPeopleEarnedBadgesPending(false))
      })
      .catch(error => {
        dispatch(getPeopleEarnedBadgesError(error))
        dispatch(getPeopleEarnedBadgesPending(false))
      })
  }
}
const getPeopleEarnedBadgesSuccess = response => {
  return {
    type: types.GET_PEOPLE_EARNED_BADGES_SUCCESS,
    payload: response.data,
  }
}
const getPeopleEarnedBadgesError = response => {
  return {
    type: types.GET_PEOPLE_EARNED_BADGES_ERROR,
    payload: response,
  }
}
const getPeopleEarnedBadgesPending = status => {
  return {
    type: types.GET_PEOPLE_EARNED_BADGES_PENDING,
    status,
  }
}

export function getPeopleReceivedCompliments(personId) {
  return dispatch => {
    dispatch(getPeopleReceivedComplimentsPending(true))
    services.publicProfile
      .fetchPeopleReceivedCompliments(personId)
      .then(response => {
        dispatch(getPeopleReceivedComplimentsSuccess(response))
        dispatch(getPeopleReceivedComplimentsPending(false))
      })
      .catch(error => {
        dispatch(getPeopleReceivedComplimentsError(error))
        dispatch(getPeopleReceivedComplimentsPending(false))
      })
  }
}
const getPeopleReceivedComplimentsSuccess = response => {
  return {
    type: types.GET_PEOPLE_RECEIVED_COMPLIMENTS_SUCCESS,
    payload: response.data,
  }
}
const getPeopleReceivedComplimentsError = response => {
  return {
    type: types.GET_PEOPLE_RECEIVED_COMPLIMENTS_ERROR,
    payload: response,
  }
}
const getPeopleReceivedComplimentsPending = status => {
  return {
    type: types.GET_PEOPLE_RECEIVED_COMPLIMENTS_PENDING,
    status,
  }
}