import axios from 'axios'
import apiConfig from '../../config/api-config'

// Get profile image
export function getProfileImage() {
	const url = apiConfig.GET_PROFILE_IMAGE
	return axios.get(`${url}`)
}

// Get list of profile images
export function postListOfProfileImages(personIds) {
    const url = apiConfig.GET_PROFILE_IMAGE
    return axios.post(url, personIds)
}

// Save/Upload profile image
export function postProfileImage(personId, imageDataBase64) {
	const data = { imageDataBase64 }
	const url = apiConfig.POST_PROFILE_IMAGE.replace('[personId]', personId)
	return axios.post(`${url}`, data)
}

// Delete profile image
export function deleteProfileImage(personId) {
	const url = apiConfig.POST_PROFILE_IMAGE.replace('[personId]', personId)
	return axios.delete(url)
}

export function getPreferredLanguage() {
	const url = apiConfig.GET_PROFILE_PREFERRED_LANGUAGE
	return axios.get(`${url}`)
}

export function updatePreferredLanguage(language) {
	const url = apiConfig.PUT_PROFILE_PREFERRED_LANGUAGE.replace('[language]', language)
	return axios.put(`${url}`)
}

export function getPreferredLanguageList() {
	const url = apiConfig.GET_PROFILE_PREFERRED_LANGUAGE_LIST
	return axios.get(`${url}`)
}
