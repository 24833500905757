
import { cloneDeep } from 'lodash'
import {
  GET_NEW_POST_COUNT_SUCCESS,
  GET_NEW_POST_COUNT_ERROR,
  GET_NEW_POST_COUNT_PENDING,
  GET_POST_CARD_SUCCESS,
  GET_POST_CARD_ERROR,
  GET_POST_CARD_PENDING,
  GET_POST_COMMENTS_SUCCESS,
  GET_POST_COMMENTS_PENDING,
  GET_POST_COMMENTS_ERROR,
  POST_POST_COMMENT_SUCCESS,
  POST_POST_COMMENT_PENDING,
  POST_POST_COMMENT_ERROR,
  DELETE_POST_COMMENT_SUCCESS,
  DELETE_POST_COMMENT_PENDING,
  DELETE_POST_COMMENT_ERROR,
  EDIT_POST_COMMENT_SUCCESS,
  EDIT_POST_COMMENT_PENDING,
  EDIT_POST_COMMENT_ERROR,
  GET_COMMENT_REPLY_ERROR,
  GET_COMMENT_REPLY_PENDING,
  GET_COMMENT_REPLY_SUCCESS,
  POST_COMMENT_REPLY_ERROR,
  POST_COMMENT_REPLY_SUCCESS,
  POST_COMMENT_REPLY_PENDING,
  DELETE_COMMENT_REPLY_SUCCESS,
  DELETE_COMMENT_REPLY_PENDING,
  DELETE_COMMENT_REPLY_ERROR,
  EDIT_POST_COMMENT_REPLY_SUCCESS,
  EDIT_POST_COMMENT_REPLY_PENDING,
  EDIT_POST_COMMENT_REPLY_ERROR,
  WATCH_POST_PENDING,
  WATCH_POST_ERROR,
  WATCH_POST_SUCCESS,
  POST_MODERATION_ITEM_SUCCESS,
} from '../actions/actionTypes'

export default function postCardReducer(
  state = {
    newPostCount: 0,
    newPostCountError: {},
    newPostCountPending: false,
    postInfo: {},
    postCardError: {},
    postCardPending: false,
    postComments: [],
    postCommentsError: {},
    postCommentsPending: false,
    addPostCommentPending: false,
    addPostCommentError: {},
    removePostCommentPending: false,
    removePostCommentError: {},
    editPostCommentPending: false,
    editPostCommentError: {},
    commentReplies: [],
    commentRepliesPending: false,
    commentRepliesError: {},
    addCommentReplyPending: false,
    addCommentReplyError: {},
    removeCommentReplyPending: false,
    removeCommentReplyError: {},
    editPostCommentReplyPending: false,
    editPostCommentReplyError: {},
    watchPostPending: false,
    watchPostError: {},
  },
  action
) {
  switch (action.type) {
    case GET_NEW_POST_COUNT_SUCCESS:
      return {
        ...state,
        newPostCount: action.payload,
        newPostCountError: {},
      }
    case GET_NEW_POST_COUNT_ERROR:
      return {
        ...state,
        newPostCountError: action.error,
      }
    case GET_NEW_POST_COUNT_PENDING:
      return {
        ...state,
        newPostCountPending: action.status,
      }
    case GET_POST_CARD_SUCCESS:
      return {
        ...state,
        postInfo: action.payload,
        postCardError: {},
      }
    case GET_POST_CARD_ERROR:
      return {
        ...state,
        postCardError: action.error,
      }
    case GET_POST_CARD_PENDING:
      return {
        ...state,
        postCardPending: action.status,
      }

    case GET_POST_COMMENTS_SUCCESS:
      return {
        ...state,
        postComments: action.payload,
        postCommentsError: {},
        postCommentsPending: false,
      }
    case GET_POST_COMMENTS_ERROR:
      return {
        ...state,
        postCommentsError: action.error,
        postCommentsPending: false,
      }
    case GET_POST_COMMENTS_PENDING:
      return {
        ...state,
        postCommentsPending: true,
      }

    case POST_POST_COMMENT_SUCCESS: {
      const _postComments = cloneDeep(state.postComments)
      _postComments.unshift(action.payload)
      let _postInfo = state.postInfo
      _postInfo = {
        ..._postInfo,
        commentCount: _postInfo.commentCount + 1
      }
      return {
        ...state,
        postInfo: _postInfo,
        postComments: _postComments,
        addPostCommentError: {},
        addPostCommentPending: false,
      }
    }
    case POST_POST_COMMENT_ERROR:
      return {
        ...state,
        addPostCommentError: action.error,
        addPostCommentPending: false,
      }
    case POST_POST_COMMENT_PENDING:
      return {
        ...state,
        addPostCommentPending: true,
      }

    case DELETE_POST_COMMENT_SUCCESS: {
      const _postComments = state.postComments.filter((comment) => comment.postId !== action.payload)
      let _postInfo = state.postInfo
      _postInfo = {
        ..._postInfo,
        commentCount: _postInfo.commentCount - 1
      }
      return {
        ...state,
        postInfo: _postInfo,
        postComments: _postComments,
        removePostCommentError: {},
        removePostCommentPending: false,
      }
    }
    case DELETE_POST_COMMENT_ERROR:
      return {
        ...state,
        removePostCommentError: action.error,
        removePostCommentPending: false,
      }
    case DELETE_POST_COMMENT_PENDING:
      return {
        ...state,
        removePostCommentPending: true,
      }

    case EDIT_POST_COMMENT_SUCCESS: {
      const commentIndex = state.postComments.findIndex(comment => comment.postId === action.payload.postId)
      const _postComments = state.postComments
      _postComments[commentIndex] = action.payload

      return {
        ...state,
        postComments: _postComments,
        editPostCommentError: {},
        editPostCommentPending: false,
      }
    }
    case EDIT_POST_COMMENT_ERROR:
      return {
        ...state,
        editPostCommentError: action.error,
        editPostCommentPending: false,
      }
    case EDIT_POST_COMMENT_PENDING:
      return {
        ...state,
        editPostCommentPending: true,
      }

    case GET_COMMENT_REPLY_SUCCESS:
      return {
        ...state,
        commentReplies: action.payload,
        commentRepliesError: {},
      }
    case GET_COMMENT_REPLY_ERROR:
      return {
        ...state,
        commentRepliesError: action.error,
      }
    case GET_COMMENT_REPLY_PENDING:
      return {
        ...state,
        commentRepliesPending: action.status,
      }

    case POST_COMMENT_REPLY_SUCCESS: {
      const _commentReplies = state.commentReplies
      _commentReplies.unshift(action.payload)

      const _postComments = state.postComments.map((comment) => {
        if (comment.postId === action.payload.parentId) {
          comment.commentCount += 1
        }
        return comment
      })

      return {
        ...state,
        postComments: _postComments,
        commentReplies: _commentReplies,
        addCommentReplyError: {},
        addCommentReplyPending: false,
      }
    }
    case POST_COMMENT_REPLY_ERROR:
      return {
        ...state,
        addCommentReplyError: action.error,
        addCommentReplyPending: false,
      }
    case POST_COMMENT_REPLY_PENDING:
      return {
        ...state,
        addCommentReplyPending: true,
      }
    case DELETE_COMMENT_REPLY_SUCCESS: {
      const _commentReplies = state.commentReplies.filter((comment) => comment.postId !== action.payload.commentId)
      const _postComments = state.postComments.map((comment) => {
        if (comment.postId === action.payload.parentId) {
          comment.commentCount -= 1
        }
        return comment
      })
      return {
        ...state,
        postComments: _postComments,
        commentReplies: _commentReplies,
        removeCommentReplyError: {},
        removeCommentReplyPending: false,
      }
    }
    case DELETE_COMMENT_REPLY_ERROR:
      return {
        ...state,
        removeCommentReplyError: action.error,
        removeCommentReplyPending: false,
      }
    case DELETE_COMMENT_REPLY_PENDING:
      return {
        ...state,
        removeCommentReplyPending: true,
      }

    case EDIT_POST_COMMENT_REPLY_SUCCESS: {
      const commentIndex = state.commentReplies.findIndex(comment => comment.postId === action.payload.postId)
      const _commentReplies = state.commentReplies
      _commentReplies[commentIndex] = action.payload

      return {
        ...state,
        commentReplies: _commentReplies,
        editPostCommentReplyError: {},
      }
    }
    case EDIT_POST_COMMENT_REPLY_ERROR:
      return {
        ...state,
        editPostCommentReplyError: action.error,
      }
    case EDIT_POST_COMMENT_REPLY_PENDING:
      return {
        ...state,
        editPostCommentReplyPending: action.status,
      }
    case WATCH_POST_SUCCESS: {
      return {
        ...state,
        postInfo: {
          ...state.postInfo,
          isWatching: action.payload
        },
        watchPostPending: false,
        watchPostError: {}
      }
    }
    case WATCH_POST_ERROR: {
      return {
        ...state,
        watchPostPending: false,
        watchPostError: action.error
      }
    }
    case WATCH_POST_PENDING:
      return {
        ...state,
        watchPostPending: true,
      }

    case POST_MODERATION_ITEM_SUCCESS: {
      if (action.flaggedItem === "FLAGGED_COMMENT") return;
      return {
        ...state,
        postInfo: {
          ...state.postInfo,
          isFlagged: true
        },
      }
    }

    default:
      return state
  }
}
