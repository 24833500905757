import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './LanguageSwitcher.scss'
import { t } from '../../../utils/i18n'
import { getCountry, getPreferredLanguage } from '../../utils/localStorageUtils'

export default class LanguageSwitcher extends Component {
  constructor(props) {
    super(props)
    const languageCode = getPreferredLanguage()
    this.state = {
      openLanguageSwitcher: false,
      selectedLanguage: this.getLanguageName(languageCode),
      selectedCountry: getCountry()
    }

    this.toggleSwitcher = this.toggleSwitcher.bind(this)
    this.changeLanguage = this.changeLanguage.bind(this)
  }

  componentDidMount() {
    const languageCode = getPreferredLanguage()
    return {
      selectedLanguage: this.getLanguageName(languageCode),
    }
  }

  toggleSwitcher() {
    const { openLanguageSwitcher } = this.state
    this.setState({
      openLanguageSwitcher: openLanguageSwitcher ? false : true,
    })
  }

  getLanguageName(languageCode) {
    switch (languageCode) {
      case 'EN':
        return 'english'
      case 'FR':
        return 'french'
      case 'ES':
        return 'spanish'
      case 'DE':
        return 'german'
      case 'HU':
        return 'hungarian'
      case 'IT':
        return 'italian'
      case 'NL':
        return 'dutch'
      case 'PL':
        return 'polish'
      case 'PT':
        return 'portuguese'
      default:
        return 'english'
    }
  }

  getLanguageCode(languageName) {
    switch (languageName) {
      case 'english':
        return 'EN'
      case 'french':
        return 'FR'
      case 'spanish':
        return 'ES'
      case 'german':
        return 'DE'
      case 'hungarian':
        return 'HU'
      case 'italian':
        return 'IT'
      case 'dutch':
        return 'NL'
      case 'polish':
        return 'PL'
      case 'portuguese':
        return 'PT'
      default:
        return 'EN'
    }
  }

  changeLanguage(e) {
    const languageName = e.currentTarget.dataset.lang
    const countryName = e.currentTarget.dataset.country
    const languageCode = this.getLanguageCode(languageName)
    this.setState({
      selectedLanguage: languageName,
      openLanguageSwitcher: false,
      selectedCountry: countryName,
    })
    this.props.onSelectLanguage(languageCode, countryName)
  }

  render() {
    const { openLanguageSwitcher, selectedLanguage, selectedCountry } = this.state
    return (
      <div>
        <div className="language-component">
          <div className="flag-select rgis-flags">
            <div
              className="selected--flag--option"
              data-selected-language={selectedLanguage}
              onClick={this.toggleSwitcher}
            >
              <span className="country-flag">
                <span className={`${selectedCountry.toLowerCase()}-flag`} /> <span>{t(selectedLanguage)}</span>
              </span>
              <span className="arrow-down ">▾</span>
            </div>
            <div className={`flag-options ${openLanguageSwitcher ? '' : 'displayNone'}`}>
              <div className='continent'>{t('north_america')}</div>
              <div className="flag-option has-label" data-lang="english" data-country='us' onClick={this.changeLanguage}>
                <span className="country-flag">
                  <span className="us-flag" /> <span>{t('united_states')} | {t('english')}</span>
                </span>
              </div>
              <div className="flag-option has-label" data-lang="spanish" data-country='us' onClick={this.changeLanguage}>
                <span className="country-flag">
                  <span className="us-flag" /> <span>{t('united_states')} | {t('spanish')}</span>
                </span>
              </div>
              <div className="flag-option has-label" data-lang="english" data-country='ca' onClick={this.changeLanguage}>
                <span className="country-flag">
                  <span className="ca-flag" /> <span>{t('canada')} | {t('english')}</span>
                </span>
              </div>
              <div className="flag-option has-label" data-lang="french" data-country='ca' onClick={this.changeLanguage}>
                <span className="country-flag">
                  <span className="ca-flag" /> <span>{t('canada')} | {t('french')}</span>
                </span>
              </div>
              <div className='continent'>{t('europe')}</div>
              <div className="flag-option has-label" data-lang="french" data-country='fr' onClick={this.changeLanguage}>
                <span className="country-flag">
                  <span className="fr-flag" /> <span>{t('france')} | {t('french')}</span>
                </span>
              </div>
              <div className="flag-option has-label" data-lang="english" data-country='gb' onClick={this.changeLanguage}>
                <span className="country-flag">
                  <span className="gb-flag" /> <span>{t('united_kingdom')} | {t('english')}</span>
                </span>
              </div>
              <div className="flag-option has-label" data-lang="german" data-country='de' onClick={this.changeLanguage}>
                <span className="country-flag">
                  <span className="de-flag" /> <span>{t('german')}</span>
                </span>
              </div>
              <div className="flag-option has-label" data-lang="hungarian" data-country='hu' onClick={this.changeLanguage}>
                <span className="country-flag">
                  <span className="hu-flag" /> <span>{t('hungarian')}</span>
                </span>
              </div>
              <div className="flag-option has-label" data-lang="italian" data-country='it' onClick={this.changeLanguage}>
                <span className="country-flag">
                  <span className="it-flag" /> <span>{t('italian')}</span>
                </span>
              </div>
              <div className="flag-option has-label" data-lang="dutch" data-country='nl' onClick={this.changeLanguage}>
                <span className="country-flag">
                  <span className="nl-flag" /> <span>{t('dutch')}</span>
                </span>
              </div>
              <div className="flag-option has-label" data-lang="polish" data-country='pl' onClick={this.changeLanguage}>
                <span className="country-flag">
                  <span className="pl-flag" /> <span>{t('polish')}</span>
                </span>
              </div>
              <div className="flag-option has-label" data-lang="portuguese" data-country='pt' onClick={this.changeLanguage}>
                <span className="country-flag">
                  <span className="pt-flag" /> <span>{t('portuguese')}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

LanguageSwitcher.propTypes = {
  onSelectLanguage: PropTypes.func,
}
