import * as types from './actionTypes'

export function setPromptedForAppDownload(promptedForAppDownload) {
  return dispatch => {
    dispatch(
        setPromptedForAppDownloadAction(promptedForAppDownload)
    )
  }
}

const setPromptedForAppDownloadAction = payload => {
  return {
    type: types.SET_PROMPTED_FOR_APP_DOWNLOAD,
    payload: payload,
  }
}

export function setShowAppDownloadModal(showAppDownloadModal) {
  return dispatch => {
    dispatch(
      setShowAppDownloadModalAction(showAppDownloadModal)
    )
  }
}

const setShowAppDownloadModalAction = payload => {
  return {
    type: types.SET_SHOW_APP_DOWNLOAD_MODAL,
    payload: payload,
  }
}
