import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import { getGeneralAvailability } from '../../state/actions/generalAvailabilityActions'
import { getOtherAvailability } from '../../state/actions/otherAvailabilityActions'
import { getPersonDetails, getSchedulingPreferences, getPublishedWeek } from '../../state/actions/personDetailsActions'
import { logout } from '../../state/actions/navigationActions'
import { doRequestChange } from '../../state/actions/requestAvailabilityChangeActions'
import { getTransportationTypes, getTransportationProfile, getRideAlongErrors } from '../../state/actions/transportationProfileActions'
import { postListOfProfileImages } from '../../state/actions/profileActions'
import { getFeatureAccessList } from '../../state/actions/featureAccessActions'

import Preferences from '../../components/Profile/Preferences/'
import TransportationPreferences from '../../components/Profile/TransportationPreferences/'
import CommunicationPreferences from '../../components/Profile/CommunicationPreferences'
import PersonDetails from '../../components/Profile/PersonDetails/'
import GeneralAvailability from '../../components/GeneralAvailability'
import Preloader from '../../components/Preloader'
import { Tabs, Tab } from '../../components/Tabs'
import OtherAvailability from '../../components/OtherAvailability'

import './ProfileContainer.scss'
import { t } from '../../utils/i18n'
import { nullSafeCheckIsTrue, nullSafeCheckIsFalse, isDefined } from '../../components/utils/nullSafeCheckUtils'
import { getLoggedInPersonId } from '../../components/utils/localStorageUtils'
import { hasAvailabilityPreferencesAccess, hasSchedulingPreferencesAccess } from '../../components/utils/featureAccessUtils'

class ProfileContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showEditModal: false,
    }

    this.renderEditModal = this.renderEditModal.bind(this)
    this.modalCloseEditStatus = this.modalCloseEditStatus.bind(this)
  }

  componentDidMount() {
    let personId = getLoggedInPersonId()
    this.props.getFeatureAccessList()
    if (isDefined(personId)) {
      this.props.getPersonDetails(personId)
    } else {
      this.props.logout()
    }
  }

  componentDidUpdate(prevProps) {
    if (nullSafeCheckIsTrue(prevProps.featureAccessPending) && nullSafeCheckIsFalse(this.props.featureAccessPending)) {
      if (this.isDistrictUser() && hasAvailabilityPreferencesAccess(this.props.featureAccess)) {
        this.props.getGeneralAvailability()
        this.props.getOtherAvailability()
      }

      if (this.isDistrictUser() && hasSchedulingPreferencesAccess(this.props.featureAccess)) {
        let personId = getLoggedInPersonId()

        this.props.getTransportationTypes()
        this.props.getSchedulingPreferences(personId)
        this.props.getTransportationProfile(personId)
        this.props.getRideAlongErrors(personId)
      }
    }

    if (this.isDistrictUser() && prevProps.transportationProfilePending === true && this.props.transportationProfilePending === false) {
      this.getTransportationProfileRideAlongProfileAvatars()
    }
  }

  getTransportationProfileRideAlongProfileAvatars = () => {
    const personIds = this.getTransportationProfileRideAlongPersonIds()
    if (personIds.length > 0) {
      this.props.postListOfProfileImages(personIds, 'transportation')
    }
  }

  getTransportationProfileRideAlongPersonIds = () => {
    if (!this.transportationProfileHasRideAlongs()) { return []; }

    return this.props.transportationProfile.rideAlongs.reduce((total, driver) => {
      if (!total.includes(driver.driverId)) {
        total.push(driver.driverId)
      }
      return total
    }, [])
  }

  transportationProfileHasRideAlongs = () => {
    return this.props.transportationProfile.rideAlongs !== undefined && this.props.transportationProfile.rideAlongs !== null && this.props.transportationProfile.rideAlongs.length > 0
  }

  renderEditModal() {
    this.setState({
      showEditModal: this.state.showEditModal ? false : true,
    })
  }

  modalCloseEditStatus(dataToSend) {
    if (dataToSend === '') {
      this.renderEditModal()
    } else {
      this.props.doRequestChange(dataToSend)
      this.renderEditModal()
    }
  }

  isDistrictUser() {
    return nullSafeCheckIsTrue(this.props.profile.isDistrictUser)
  }
  
  getPersonalInfoMarkup() {
    return (
      <div className="row">
        <div className="col">
          <div className="card border-0 shadow-none">
            <div className="card-body">
              <PersonDetails
                history={this.props.history}
                personDetails={this.props.personDetails}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  getSettingsMarkup() {
    return (
      <div className="row">
        <div className="col">
          <div className="card border-0 shadow-none">
            <div className="card-body">              
              <CommunicationPreferences />
            </div>
          </div>
        </div>
      </div>
    )
  }

  getPreferencesMarkup() {
    if (hasSchedulingPreferencesAccess(this.props.featureAccess)) {
      return (
        <Tab id="preferences" title={this.getPreferencesTabTextMarkUp()}>
          <div className="row">
            <div className="col">
              <div className="card border-0 shadow-none">
                <div className="card-body">
                  <i title={t('postal_code_info')} className="material-icons priority_high disclaimer-icon">priority_high</i> {t('preferences_disclaimer')}
                  <hr />
                  <TransportationPreferences history={this.props.history} />
                  <hr />
                  <Preferences />
                </div>
              </div>
            </div>
          </div>                  
        </Tab>
      )
    }

    return ''
  }

  getSpecialAvailabilityWarningMarkUp = () => {
    return this.isDistrictUser() && this.props.otherAvailability.length > 0 ? (
      <div className='warning-label'>{t('days_off_special_avail_warning')}</div>
    ) : ('')
  }

  getAvailabilityMarkup() {
    if (hasAvailabilityPreferencesAccess(this.props.featureAccess)) {
      return (
        <Tab id="availability" title={t('availability')}>
          <div className="row">
            <div className="col">
              <div className="card border-0 shadow-none">
                <div className="card-body">
                  {this.getSpecialAvailabilityWarningMarkUp()}
                  <GeneralAvailability history={this.props.history} />
                  <hr />
                  <OtherAvailability
                    publishedWeek={this.props.publishedWeek} />
                </div>
              </div>
            </div>
          </div>                  
        </Tab>
      )
    }

    return ''
  }

  getDistrictUserProfileMarkup() {
    return (
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <Tabs activeTab={{ id: 'info' }}>
                <Tab id="info" title={t('info')}>
                  {this.getPersonalInfoMarkup()}
                </Tab>
                {this.getAvailabilityMarkup()}
                {this.getPreferencesMarkup()}
                <Tab id="settings" title={t('settings')}>
                  {this.getSettingsMarkup()}
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    )
  }

  getNonDistrictUserProfileMarkup() {
    return (      
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <Tabs activeTab={{ id: 'info' }}>
                <Tab id="info" title={t('info')}>
                  {this.getPersonalInfoMarkup()}
                </Tab>
                <Tab id="settings" title={t('settings')}>
                  {this.getSettingsMarkup()}
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    )
  }

  getProfileMarkup() {
    return this.isDistrictUser() ?
      (this.getDistrictUserProfileMarkup())
      :
      (this.getNonDistrictUserProfileMarkup())
  }

  render() {
    const { personDetailsPending, schedulingPreferencesPending,
      otherAvailabilityPending, transportationTypesPending, transportationProfilePending,
      rideAlongErrorsPending, featureAccessPending,  profileImagesPending } = this.props
    if (personDetailsPending || schedulingPreferencesPending
      || otherAvailabilityPending || transportationTypesPending || transportationProfilePending
      || rideAlongErrorsPending || featureAccessPending || profileImagesPending
    ) {
      return <Preloader />
    }
    return (
      <div className="profile-container">
        <div className="row">
          <div className="col profile-header">Profile</div>
        </div>
        <div>
          {this.getProfileMarkup()}
        </div>
        <div>
          <a href="https://wisintl.com/about-us/" target="_blank" rel="noopener noreferrer" className="nav-link">
            <div className="rgis-values"></div>
          </a>
        </div>
      </div>
    )
  }

  getPreferencesTabTextMarkUp = () => {
    if (!this.props.rideAlongErrorsPending && this.props.rideAlongErrors.length > 0) {
      return (<b>{t('preferences')} <i title={t('team_member_not_valid')} className="material-icons error">error</i></b>)
    } else {
      return (t('preferences'))
    }
  }
}

function mapStateToProps(state) {
  return {
    personDetailsPending: state.personDetailsReducer.personDetailsPending,
    personDetails: state.personDetailsReducer.personDetails,
    schedulingPreferencesPending: state.personDetailsReducer.schedulingPreferencesPending,
    otherAvailability: state.otherAvailabilityReducer.otherAvailability,
    otherAvailabilityPending: state.otherAvailabilityReducer.otherAvailabilityPending,
    transportationTypesPending: state.transportationProfileReducer.transportationTypesPending,
    transportationProfile: state.transportationProfileReducer.transportationProfile,
    transportationProfilePending: state.transportationProfileReducer.transportationProfilePending,
    rideAlongErrors: state.transportationProfileReducer.rideAlongErrors,
    rideAlongErrorsPending: state.transportationProfileReducer.rideAlongErrorsPending,
    publishedWeek: state.personDetailsReducer.publishedWeek,
    featureAccess: state.featureAccessReducer.featureAccess,
    featureAccessPending: state.featureAccessReducer.featureAccessPending,
    profileImagesPending: state.profileReducer.profileImagesPending['transportation'],
    profile: state.loginReducer.profile,
    profileLocale: state.loginReducer.profileLocale,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getGeneralAvailability: () => getGeneralAvailability(),
      doRequestChange: changeData => doRequestChange(changeData),
      getPersonDetails: (personId) => getPersonDetails(personId),
      getSchedulingPreferences: (personId) => getSchedulingPreferences(personId),
      getOtherAvailability: () => getOtherAvailability(),
      getTransportationTypes: () => getTransportationTypes(),
      getTransportationProfile: (personId) => getTransportationProfile(personId),
      getRideAlongErrors: (personId) => getRideAlongErrors(personId),
      getPublishedWeek: () => getPublishedWeek(),
      logout: () => logout(),
      postListOfProfileImages: (personIds, location) => postListOfProfileImages(personIds, location),
      getFeatureAccessList: () => getFeatureAccessList(),
    },
    dispatch
  )
}

ProfileContainer.propTypes = {
  getGeneralAvailability: PropTypes.func,
  getPersonDetails: PropTypes.func,
  getSchedulingPreferences: PropTypes.func,
  schedulingPreferencesPending: PropTypes.bool,
  personDetails: PropTypes.object,
  personDetailsPending: PropTypes.bool,
  getOtherAvailability: PropTypes.func,
  otherAvailabilityPending: PropTypes.bool,
  otherAvailability: PropTypes.array,
  getTransportationTypes: PropTypes.func,
  transportationTypesPending: PropTypes.bool,
  getTransportationProfile: PropTypes.func,
  transportationProfilePending: PropTypes.bool,
  transportationProfile: PropTypes.object,
  rideAlongErrors: PropTypes.array,
  rideAlongErrorsPending: PropTypes.bool,
  getRideAlongErrors: PropTypes.func,
  getPublishedWeek: PropTypes.func,
  publishedWeek: PropTypes.object,
  getFeatureAccessList: PropTypes.func,
  featureAccess: PropTypes.object,
  featureAccessPending: PropTypes.bool,
  history: PropTypes.object,
  doRequestChange: PropTypes.func,
  logout: PropTypes.func,
  postListOfProfileImages: PropTypes.func,
  profileImagesPending: PropTypes.bool,
  profile: PropTypes.object,
  profileLocale: PropTypes.string,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileContainer)
