import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Rating from 'react-rating'
import { Redirect } from 'react-router-dom'
import { getAllFeedbacks } from '../../state/actions/feedbackActions'
import { getEventDetails } from '../../state/actions/eventDetailsActions'
import { postListOfProfileImages } from '../../state/actions/profileActions'
import PropTypes from 'prop-types'
import Preloader from '../../components/Preloader'
import PostUserAvatar from '../../components/PostUserAvatar'
import ActionModal from '../../components/ActionModal'
import { t } from '../../utils/i18n'
import { getLoggedInPersonId } from '../../components/utils/localStorageUtils'
import { hasFeedbackAccess } from '../../components/utils/featureAccessUtils'
import { isBetweenHours } from '../../components/utils/datetimeUtils'
import { getFeatureAccessList } from '../../state/actions/featureAccessActions'

import './SurveysListContainer.scss'
import { nullSafeCheckIsTrue, nullSafeCheckIsFalse } from '../../components/utils/nullSafeCheckUtils'

const EVENT_SURVEY_NAME = 'Overall Event'
const DRIVER_SURVEY_NAME = 'Driver'

class SurveysListContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      eventId: null,
      eventTitle: '',
      overallFeedback: {
        rating: 0,
      },
      driverFeedback: {
        driver: false,
        driverDetails: []
      },
      supervisorFeedback: {},
      popup: {
        showPopup: false,
        popupTitle: '',
        popupText: '',
        popupAction: null,
      }
    }
    this.onFeedbackClick = this.onFeedbackClick.bind(this)
    this.onRateEventClick = this.onRateEventClick.bind(this)
    this.closePopup = this.closePopup.bind(this)
    this.onGoBackClick = this.onGoBackClick.bind(this)
  }

  componentDidMount() {
    this.props.getFeatureAccessList()

    const _state = this.state
    const eventId = this.props.match.params.event_id

    _state.eventId = eventId
    this.setState(_state)
  }


  componentDidUpdate(prevProps) {
    const _state = this.state
    const { eventId } = this.state

    if (nullSafeCheckIsTrue(prevProps.featureAccessPending) 
      && nullSafeCheckIsFalse(this.props.featureAccessPending)
      && hasFeedbackAccess(this.props.featureAccess)) 
    {
      this.props.getEventDetails(eventId)
    }

    if (prevProps.feedbackAllPending === true && this.props.feedbackAllPending === false) {
      const { overallFeedback, driverFeedback } = this.props.feedbackAll
      _state.overallFeedback = overallFeedback
      // merge latest driver rating with _state.driveFeedback
      _state.driverFeedback.driverDetails = _state.driverFeedback.driverDetails.map((driverDetail, index) => ({
        ..._state.driverFeedback.driverDetails[index],
        ...driverFeedback[index]
      }))
      this.setState(_state)

      const personIds = _state.driverFeedback.driverDetails.reduce((total, driver) => {
        if (!total.includes(driver.driverPersonId)) {
          total.push(driver.driverPersonId)
        }
        return total
      }, [])
      this.props.postListOfProfileImages(personIds, 'survey-list')
    }

    if (prevProps.eventDetailsPending === true && this.props.eventDetailsPending === false) {
      const { eventDetails } = this.props
      
      if (!isBetweenHours(eventDetails.date, eventDetails.time)) { // if the event is expired
        this.props.history.goBack()
        return
      }
      
      _state.eventTitle = eventDetails.scheduleData.title
      _state.driverFeedback = {
        driver: eventDetails.driver,
        driverDetails: eventDetails.driverDetails
      }
      this.props.getAllFeedbacks(_state.eventId, eventDetails.driverDetails)
      this.setState(_state)
    }
  }

  onGoBackClick() {
    this.props.history.push(`/schedule/${this.state.eventId}`)
  }

  onRateEventClick(evt) {
    evt.stopPropagation();
    const { templateName } = evt.target.dataset
    const { eventId } = this.state
    this.props.history.push({
      pathname: `/survey-feedback/${eventId}/${templateName}`,
      state: { fromSurveysList: true }
    })
  }

  closePopup() {
    const _state = this.state
    _state.popup = {
      showPopup: false,
      popupTitle: '',
      popupText: '',
      popupAction: null,
    }
    this.setState(_state)
  }

  onFeedbackClick(templateName, rating, subjectPersonId, subjectPersonName) {
    const _state = this.state
    if (rating > 0) {
      _state.popup = {
        showPopup: true,
        popupTitle: '',
        popupText: 'You have already completed this survey',
        popupAction: this.closePopup,
      }
      this.setState(_state)
    } else {
      const { eventId } = this.state
      this.props.history.push({
        pathname: `/survey-feedback/${eventId}/${templateName}`,
        state: {
          fromSurveysList: true,
          subjectPersonId: subjectPersonId,
          subjectPersonName: subjectPersonName
        }
      })
    }
  }

  renderOverallEvent() {
    const { rating } = this.state.overallFeedback
    const { eventTitle } = this.state

    return (
      <div className="mb-4 cursor-pointer" onClick={() => this.onFeedbackClick(EVENT_SURVEY_NAME, rating)} >
        <div className="row overall-event">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <div className="section">
                  <h3>{t('overall_event')}</h3>
                  <h5>{eventTitle}</h5>
                  <div className="rating-stars mt-2">
                    <Rating
                      emptySymbol={<i className="material-icons">star_rate</i>}
                      fullSymbol={<i className="material-icons rated-star">star_rate</i>}
                      fractions={2}
                      initialRating={rating / 2}
                      readonly
                    />
                  </div>
                  {rating === 0 && < div className="d-flex justify-content-start mt-4">
                    <input
                      type='button'
                      onClick={this.onRateEventClick}
                      data-template-name={EVENT_SURVEY_NAME}
                      className="btn btn-rgis-blue cursor-pointer uppercase"
                      value={t('rate_event')}
                    />
                  </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    )
  }

  renderEventDriver(driver, userAvatar) {
    return (
      <div
        key={parseInt(driver.driverPersonId, 10)}
        className="row overall-event"
        onClick={() => this.onFeedbackClick(DRIVER_SURVEY_NAME, driver.rating, driver.driverPersonId, driver.driverName)}>
        <div className="col">
          <div className="card">
            <div className="card-body">
              <div className="section">
                <div className="d-flex flex-wrap align-items-center justify-content-between personal-info">
                  <div className="d-flex">
                    {driver.driverName ?
                      <PostUserAvatar
                        personName={driver.driverName}
                        userAvatarUrl={userAvatar !== undefined ? userAvatar.url : ''}
                        clickable={false}
                      />
                      :
                      <div className="no-avatar d-flex justify-content-center align-items-center">
                        <i className="material-icons">not_interested</i>
                      </div>
                    }

                    <div className="ml-3 d-flex flex-column justify-content-center">
                      <div className="jobName">{DRIVER_SURVEY_NAME}</div>
                      <div className="name">
                        {driver.driverName ?
                          driver.driverName
                          :
                          t('no_driver_information')
                        }
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 mt-sm-0 rating-stars">
                    <Rating
                      emptySymbol={<i className="material-icons">star_rate</i>}
                      fullSymbol={<i className="material-icons rated-star">star_rate</i>}
                      fractions={2}
                      initialRating={driver.rating / 2}
                      readonly
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderEventDrivers() {
    const { driverFeedback } = this.state
    const { profileImages } = this.props
    const personId = getLoggedInPersonId()
    return (
      <div className="mb-4 cursor-pointer">
        <h5>{t('rate_persons')}</h5>
        {driverFeedback.driverDetails.filter(driver => driver.driverPersonId !== personId).length === 0 ?
          <div>{t('no_event_driver')}</div>
          :
          driverFeedback.driverDetails.map(driver => {
            const userAvatar = profileImages.find(obj => obj.personId === parseInt(driver.driverPersonId))
            return driver.driverPersonId !== personId ?
              this.renderEventDriver(driver, userAvatar)
              :
              ''
          })
        }
      </div>
    )
  }

  render() {
    const { showPopup, popupTitle, popupText, popupAction } = this.state.popup
    const { overallFeedback } = this.state
    const { feedbackAllPending, eventDetailsPending, profileImagesPending, featureAccessPending } = this.props

    if (feedbackAllPending || eventDetailsPending || profileImagesPending || featureAccessPending) {
      return (
        <Preloader />
      )
    }

    if (!hasFeedbackAccess(this.props.featureAccess)) {
      return <Redirect to="/profile" />
    }

    return (
      <div className="feedback-container">
        <div className="row">
          <div className="col feedback-header"> {t('feedback')} </div>
          <div className="col-2 d-flex align-items-center justify-content-end">
            <i
              onClick={this.onGoBackClick}
              className="material-icons arrow_back cursor-pointer other-avail-icon"
            >
              arrow_back
            </i>
          </div>
        </div>

        {this.renderOverallEvent()}
        {overallFeedback.rating ? this.renderEventDrivers() : ''}

        <ActionModal
          showModal={showPopup}
          modalTitle={popupTitle}
          modalText={popupText}
          showCancelAction={false}
          removeAction={popupAction}
          modalAcceptButtonText={t('ok')}
          modalCancelButtonText={''}
          modalAcceptAction={popupAction}
          modalCancelAction={popupAction}
        />
      </div >
    )
  }
}

function mapStateToProps(state) {
  return {
    feedbackAll: state.feedbackReducer.feedbackAll,
    feedbackAllPending: state.feedbackReducer.feedbackAllPending,
    feedbackAllError: state.feedbackReducer.eventSurveyError,
    eventDetails: state.eventDetailsReducer.eventDetails,
    eventDetailsPending: state.eventDetailsReducer.eventDetailsPending,
    eventDetailsError: state.eventDetailsReducer.eventDetailsError,
    profileImages: state.profileReducer.profileImages,
    profileImagesPending: state.profileReducer.profileImagesPending['survey-list'],
    profileImagesError: state.profileReducer.profileImagesError,
    featureAccessPending: state.featureAccessReducer.featureAccessPending,
    featureAccess: state.featureAccessReducer.featureAccess,
    profileLocale: state.loginReducer.profileLocale,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getAllFeedbacks: (eventId, driverDetails) => getAllFeedbacks(eventId, driverDetails),
      getEventDetails: (eventId) => getEventDetails(eventId),
      postListOfProfileImages: (personIds, location) => postListOfProfileImages(personIds, location),
      getFeatureAccessList: () => getFeatureAccessList(),
    },
    dispatch
  )
}

SurveysListContainer.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  getAllFeedbacks: PropTypes.func,
  feedbackAllPending: PropTypes.bool,
  feedbackAll: PropTypes.object,
  eventDetailsError: PropTypes.object,
  eventDetails: PropTypes.object,
  eventDetailsPending: PropTypes.bool,
  getEventDetails: PropTypes.func,
  postListOfProfileImages: PropTypes.func,
  profileImages: PropTypes.array,
  profileImagesPending: PropTypes.bool,
  profileImagesError: PropTypes.object,
  featureAccess: PropTypes.object,
  featureAccessPending: PropTypes.bool,
  getFeatureAccessList: PropTypes.func,
  profileLocale: PropTypes.string,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveysListContainer)
