import * as types from './actionTypes'
import services from '../services'


export function getAllFeedbacks(eventId, driverDetails) {
  return dispatch => {
    dispatch(getAllFeedbacksPending(true))
    services.feedback
      .getAllFeedbacks(eventId, driverDetails)
      .then(response => {
        dispatch(getAllFeedbacksSuccess(response))
        dispatch(getAllFeedbacksPending(false))
      })
      .catch(error => {
        dispatch(getAllFeedbacksError(error))
        dispatch(getAllFeedbacksPending(false))
      })
  }
}

const getAllFeedbacksPending = pending => {
  return {
    type: types.GET_FEEDBACK_ALL_PENDING,
    payload: pending,
  }
}

const getAllFeedbacksSuccess = response => {
  return {
    type: types.GET_FEEDBACK_ALL_SUCCESS,
    payload: response
  }
}

const getAllFeedbacksError = response => {
  return {
    type: types.GET_FEEDBACK_ALL_ERROR,
    error: response,
  }
}


export function getFeedbackById(feedbackId) {
  return dispatch => {
    dispatch(getFeedbackByIdPending(true))
    services.feedback
      .getFeedbackById(feedbackId)
      .then(response => {
        dispatch(getFeedbackByIdSuccess(response))
        dispatch(getFeedbackByIdPending(false))
      })
      .catch(error => {
        dispatch(getFeedbackByIdError(error))
        dispatch(getFeedbackByIdPending(false))
      })
  }
}

const getFeedbackByIdPending = pending => {
  return {
    type: types.GET_FEEDBACK_BY_ID_PENDING,
    payload: pending,
  }
}

const getFeedbackByIdSuccess = response => {
  return {
    type: types.GET_FEEDBACK_BY_ID_SUCCESS,
    payload: response.data
  }
}

const getFeedbackByIdError = response => {
  return {
    type: types.GET_FEEDBACK_BY_ID_ERROR,
    error: response,
  }
}

export function getEventSurvey(templateName) {
  return dispatch => {
    dispatch(getEventSurveyPending(true))
    services.feedback
      .getLatestEventSurveyByTemplateName(templateName)
      .then(response => {
        dispatch(getEventSurveySuccess(response))
        dispatch(getEventSurveyPending(false))
      })
      .catch(error => {
        dispatch(getEventSurveyError(error))
        dispatch(getEventSurveyPending(false))
      })
  }
}

const getEventSurveyPending = pending => {
  return {
    type: types.GET_EVENT_TEMPLATE_PENDING,
    payload: pending,
  }
}

const getEventSurveySuccess = response => {
  return {
    type: types.GET_EVENT_TEMPLATE_SUCCESS,
    payload: response.data
  }
}

const getEventSurveyError = response => {
  return {
    type: types.GET_EVENT_TEMPLATE_ERROR,
    error: response,
  }
}

export function postSurveyFeedback(feedback) {
  return dispatch => {
    dispatch(postSurveyFeedbackPending(true))
    services.feedback
      .postSurveyFeedback(feedback)
      .then(response => {
        dispatch(postSurveyFeedbackSuccess(response))
        dispatch(postSurveyFeedbackPending(false))
      })
      .catch(error => {
        dispatch(postSurveyFeedbackError(error))
        dispatch(postSurveyFeedbackPending(false))
      })
  }
}

const postSurveyFeedbackPending = pending => {
  return {
    type: types.POST_SURVEY_FEEDBACK_PENDING,
    payload: pending,
  }
}

const postSurveyFeedbackSuccess = response => {
  return {
    type: types.POST_SURVEY_FEEDBACK_SUCCESS,
    payload: response.data
  }
}

const postSurveyFeedbackError = response => {
  return {
    type: types.POST_SURVEY_FEEDBACK_ERROR,
    error: response,
  }
}


export function getPersonDetails(personId) {
  return dispatch => {
    dispatch(getPersonDetailsActionPending(true))
    services.personDetails
      .fetchPersonDetails(personId)
      .then(response => {
        dispatch(getPersonDetailsActionSuccess(response))
        dispatch(getPersonDetailsActionPending(false))
      })
      .catch(error => {
        dispatch(getPersonDetailsActionError(error))
        dispatch(getPersonDetailsActionPending(false))
      })
  }
}

const getPersonDetailsActionPending = pending => {
  return {
    type: types.GET_FEEDBACK_PERSON_DETAILS_PENDING,
    payload: pending,
  }
}

const getPersonDetailsActionSuccess = response => {
  return {
    type: types.GET_FEEDBACK_PERSON_DETAILS_SUCCESS,
    payload: response.data
  }
}

const getPersonDetailsActionError = response => {
  return {
    type: types.GET_FEEDBACK_PERSON_DETAILS_ERROR,
    error: response,
  }
}