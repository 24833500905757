import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  editPostComment,
  getCommentReplies, addCommentReply,
  editPostCommentReply
} from '../../state/actions/postCardActions.js'
import { getUserReactions, postReaction, deleteReaction } from '../../state/actions/reactionsActions'
import { translatePost } from '../../state/actions/channelActions'
import { postListOfProfileImages } from '../../state/actions/profileActions'
import PropTypes from 'prop-types'
import PostUserAvatar from '../PostUserAvatar'
import Preloader from '../Preloader'
import ActionModal from '../ActionModal'
import commentActionsImg from '../../styles/images/ellipsis-v-solid.svg'
import moment from 'moment'
import {
  convertLanguageCodeToLanguageName,
} from '../utils/utils'
import { obfuscate } from '../utils/obfuscateUtils'
import { getUserChannelAccess } from '../utils/utils'
import { formatTime, getMonthAndDay, isToday } from '../utils/datetimeUtils'
import './PostComment.scss'
import { t } from '../../utils/i18n'
import { cloneDeep } from 'lodash'


class PostComment extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      comment: {},
      commentText: '',
      commentAction: {
        open: false,
        commentId: null
      },
      replyText: '',
      commentReplyClick: {
        open: false,
        commentId: null
      },
      replyAction: {
        open: false,
        commentId: null
      },
      showEditBox: false,
      editCommentText: '',
      commentBodyLineHeight: 22,
      commentReplies: [],
      userReaction: {
        postId: null,
        reactionId: null,
        reactionType: ''
      },
      likeCount: null,
      dislikeCount: null,
      translations: {
        loadedData: false,
        active: false,
        title: {},
        body: {},
      },
      modal: {
        showModal: false,
        modalTitle: '',
        modalText: '',
        showCancelAction: false,
        modalAcceptButtonText: '',
        modalCancelButtonText: '',
        modalAcceptAction: null,
        modalCancelAction: null,
      },
    }

    this.commentActionsClick = this.commentActionsClick.bind(this)
    this.removeCommentClick = this.removeCommentClick.bind(this)
    this.editCommentClick = this.editCommentClick.bind(this)
    this.editCommentChange = this.editCommentChange.bind(this)
    this.editCommentAction = this.editCommentAction.bind(this)
    this.discardEditChanges = this.discardEditChanges.bind(this)
    this.commentReplyClick = this.commentReplyClick.bind(this)
    this.addReplyChange = this.addReplyChange.bind(this)
    this.addReplyClick = this.addReplyClick.bind(this)
    this.translatePostClick = this.translatePostClick.bind(this)
    this.translateToOriginalClick = this.translateToOriginalClick.bind(this)
    this.flagPostForReview = this.flagPostForReview.bind(this)
    this.cancelPostAction = this.cancelPostAction.bind(this)
  }

  componentDidMount() {
    const { comment, commentToNavigateTo } = this.props
    const _state = this.state

    if (this.props.userReaction && this.props.userReaction.postId !== null) {
      _state.userReaction = this.props.userReaction
    }
    _state.likeCount = comment.likeCount
    _state.dislikeCount = comment.dislikeCount
    _state.comment = comment

    if (commentToNavigateTo && commentToNavigateTo.commentId === comment.postId) {
      const _comment = document.querySelector(
        ".comment-" + comment.postId
      )
      if (_comment) {
        setTimeout(() => {
          _comment.scrollIntoView({ block: "center" })
        }, 1)
      }
    }

    if (commentToNavigateTo && commentToNavigateTo.commentParentId === comment.postId) {
      this.commentReplyClick(new Event('dummy'))
    }

    this.setState(_state)
  }

  componentDidUpdate(prevProps) {
    const _state = this.state

    if (prevProps.commentRepliesPending === true
      && this.props.commentRepliesPending === false
      && this.props.commentReplies[0]
      && this.props.commentReplies[0].parentId === _state.comment.postId) {
      let postIds = this.props.commentReplies.reduce((total, reply) => {
        if ((reply.likesEnabled && reply.likeCount !== 0)
          || (reply.dislikesEnabled && reply.dislikeCount !== 0)) {
          total.push(reply.postId)
        }
        return total
      }, [])
      this.props.getUserReactions(postIds, 'replies')
      const personIds = this.props.commentReplies.reduce((total, reply) => {
        if (!total.includes(reply.personId)) {
          total.push(reply.personId)
        }
        return total
      }, [])
      this.props.postListOfProfileImages(personIds, 'replies')
    }

    if (prevProps.userReactionsPending === true && this.props.userReactionsPending === false) {
      _state.commentReplies = this.props.commentReplies
      this.setState(_state)
    }

    if (prevProps.addCommentReplyPending === true && this.props.addCommentReplyPending === false) {
      _state.commentReplies = this.props.commentReplies
      _state.replyText = ''
      this.setState(_state)
    }

    if (prevProps.removeCommentReplyPending === true && this.props.removeCommentReplyPending === false) {
      _state.commentReplies = this.props.commentReplies
      _state.replyText = ''
      this.setState(_state)
    }

    if (prevProps.editPostCommentReplyPending === true && this.props.editPostCommentReplyPending === false) {
      _state.commentReplies = this.props.commentReplies
      _state.replyText = ''
      this.setState(_state)
    }

    if (
      (prevProps.postReactionPending === true && this.props.postReactionPending === false) ||
      (prevProps.deleteReactionPending === true && this.props.deleteReactionPending === false)
    ) {
      _state.userReaction = this.props.userReaction
      this.setState(_state)
    }

    if (prevProps.translationsPending === true && this.props.translationsPending === false
      && this.props.translations && !Object.keys(this.state.translations.body).length) {
      _state.translations = this.props.translations
      this.setState(_state)
    }

    if (prevProps.comment.isFlagged === false && this.props.comment.isFlagged === true) {
      _state.modal = {
        showModal: true,
        modalTitle: t('flag_post'),
        modalText: t('flag_post_submitted'),
        showCancelAction: false,
        modalAcceptButtonText: t('ok'),
        modalCancelButtonText: '',
        modalAcceptAction: this.cancelPostAction,
        modalCancelAction: null,
      }
    }


    document.querySelectorAll("[data-field='comment-body']").forEach((item) => {
      if (item.value.length === 0) {
        item.rows = 2
      } else {
        item.rows = ~~(item.scrollHeight / this.state.commentBodyLineHeight)
      }
    })
  }

  commentActionsClick(evt) {
    evt.stopPropagation()
    const { commentId } = evt.target.dataset
    const _state = this.state
    _state.commentAction = {
      open: !_state.commentAction.open,
      commentId: parseInt(commentId, 10),
    }
    _state.showEditBox = false
    _state.editCommentText = ''
    this.setState(_state)
  }

  removeCommentClick() {
    const { postInfo, type } = this.props
    const _state = this.state
    _state.commentAction.open = false
    this.props.removeCommentClick(type, _state.comment.postId, postInfo.postId)
    this.setState(_state)
  }

  editCommentClick() {
    const _state = this.state
    _state.showEditBox = true
    _state.commentAction.open = false
    _state.editCommentText = _state.comment.body
    this.setState(_state)
  }
  editCommentChange(evt) {
    evt.preventDefault()
    const _state = this.state
    _state.editCommentText = evt.target.value
    this.setState(_state)
  }
  editCommentAction() {
    const { type } = this.props
    const _state = this.state
    _state.showEditBox = false
    _state.comment.body = _state.editCommentText
    if (type === 'comment') {
      this.props.editPostComment(_state.comment)
    } else {
      this.props.editPostCommentReply(_state.comment)
    }
    this.setState(_state)
  }
  discardEditChanges() {
    const _state = this.state
    _state.showEditBox = false
    _state.editCommentText = ''
    this.setState(_state)
  }

  commentReplyClick(evt) {
    evt.stopPropagation()
    const { showRepliesForComment } = this.props
    const _state = this.state
    if (!showRepliesForComment.commentId
      || showRepliesForComment.commentId !== _state.comment.postId) {
      _state.commentReplies = []
      this.props.handleShowRepliesForComment(_state.comment.postId, true)
      if (_state.comment.commentCount !== 0) {
        this.props.getCommentReplies(_state.comment.postId)
      }
    } else if (showRepliesForComment.commentId === _state.comment.postId) {
      this.props.handleShowRepliesForComment(_state.comment.postId, !showRepliesForComment.status)
    }

    _state.replyText = ''
    this.setState(_state)
  }

  addReplyChange(evt) {
    evt.preventDefault()
    const _state = this.state;
    _state.replyText = evt.target.value
    this.setState(_state)
  }
  addReplyClick() {
    const { postInfo, showRepliesForComment } = this.props
    const { replyText } = this.state
    const data = {
      channelId: postInfo.channelId,
      parentId: showRepliesForComment.commentId,
      body: replyText,
    };
    this.props.addCommentReply(data)
  }

  handleReactionChange(evt, reaction) {
    evt.stopPropagation()
    let _state = this.state

    if (_state.userReaction.reactionType !== '') {
      _state = {
        ..._state,
        likeCount: _state.userReaction.reactionType === 'Like' ? _state.likeCount - 1 : _state.likeCount,
        dislikeCount: _state.userReaction.reactionType === 'Dislike' ? _state.dislikeCount - 1 : _state.dislikeCount,
      }
      this.props.deleteReaction(_state.userReaction.reactionId, _state.comment.postId)
    }
    if (_state.userReaction.reactionType !== reaction) {
      const _userReaction = {
        postId: _state.comment.postId,
        reactionType: reaction,
      }
      _state = {
        ..._state,
        likeCount: reaction === 'Like' ? _state.likeCount + 1 : _state.likeCount,
        dislikeCount: reaction === 'Dislike' ? _state.dislikeCount + 1 : _state.dislikeCount,
      }
      this.props.postReaction(_userReaction, _state.comment.postId)
    }
    this.setState(_state)
  }

  flagPostForReview(evt) {
    evt.stopPropagation()
    const _state = this.state
    _state.commentAction.open = false
    this.props.flagCommentClick(_state.comment.postId)
    this.setState(_state)
  }

  cancelPostAction(evt) {
    evt.stopPropagation()
    const _state = this.state
    _state.modal = {
      showModal: false,
      modalTitle: '',
      modalText: '',
      showCancelAction: false,
      modalAcceptButtonText: '',
      modalCancelButtonText: '',
      modalAcceptAction: null,
      modalCancelAction: null,
    }
    this.setState(_state)
  }


  renderDate(date) {
    return (
      <div className="col-6 d-flex flex-row justify-content-end">
        {isToday(date) ?
          <div>{formatTime(date)}</div>
          :
          <div>{`${getMonthAndDay(date)} | ${formatTime(date)}`}</div>
        }
      </div>
    )
  }

  renderCommentReplies() {
    const { commentReplies, replyText, comment } = this.state
    const { userAccess, commentRepliesPending, userReactionsPending,
      profileImagesPending, profileImages, history, commentToNavigateTo,
    } = this.props

    if (commentRepliesPending || userReactionsPending || profileImagesPending) {
      return (
        <div className="text-center">{t('loading')}</div>
      )
    }
    return (
      <div className="mt-3 ml-4 replies-list">
        {
          commentReplies.slice(0).reverse().map((reply, index) =>
            <PostCommentWrapper
              key={`${reply.postId}${index}`}
              type='reply'
              index={index}
              comment={reply}
              postInfo={comment}
              userAccess={userAccess}
              nrOfComments={commentReplies.length}
              removeCommentClick={this.props.removeCommentClick}
              userAvatar={profileImages.find(obj => obj.personId === reply.personId)}
              history={history}
              flagCommentClick={this.props.flagCommentClick}
              commentToNavigateTo={commentToNavigateTo}
            />
          )
        }
        {this.renderAddOrEditPostComment(true, replyText, this.addReplyChange, this.addReplyClick, false)}
      </div>
    )
  }

  renderAddOrEditPostComment(isReply, commentText, onChange, onSubmit, discard_button) {
    if (!this.props.postInfo.commentsEnabled) {
      return isReply ? (<div className='no-new-comments-msg'>{t('no_new_replies_message')}</div>) : (<div className='no-new-comments-msg'>{t('no_new_comments_message')}</div>)
    } else {
      return (
        <form className="col-12 add-comment-container">
          <div className="row align-items-center">
            <div className={`${!discard_button ? 'col-md-11 col-9' : 'col-md-10 col-12'} ${isReply ? 'pl-0' : ''}`}>
              <textarea
                data-field="comment-body"
                onChange={onChange}
                value={commentText}
                placeholder={isReply ? t('type_reply') : t('type_comment')}
                maxLength="2000"
              />
            </div>
            <div className={`${!discard_button ? 'col-md-1 col-3' : 'col-md-2 col-12'} ${isReply ? 'pr-0' : ''} d-flex flex-row justify-content-end`}>
              <input
                type='button'
                onClick={onSubmit}
                className="btn btn-rgis-blue cursor-pointer uppercase"
                value={discard_button ? t('save') : t('add')}
                disabled={!commentText.length}
              />
              {discard_button &&
                <input
                  type='button'
                  onClick={this.discardEditChanges}
                  className="btn btn-secondary cursor-pointer uppercase ml-2"
                  value={t('cancel')}
                  disabled={!commentText.length}
                />
              }
            </div>
          </div>
        </form>
      )
    }
  }

  renderSocialMarkup(creationDate) {
    const { comment } = this.props
    if (comment.likesEnabled || comment.dislikesEnabled || comment.commentsEnabled) {
      return (
        <div className="row social-bar" >
          {this.renderReactionButtonsMarkup(comment.likesEnabled, comment.dislikesEnabled)}
          {this.renderDate(creationDate)}
        </div>
      )
    }
  }

  renderReactionButtonsMarkup(likesEnabled, dislikesEnabled) {
    const { userReaction, likeCount, dislikeCount } = this.state
    const { postReactionPending, deleteReactionPending } = this.props

    if (postReactionPending || deleteReactionPending) {
      return (
        <div className="col-6 d-flex flex-row">
          <div className="loading-text">{t('loading')}</div>
        </div>
      )
    }
    return (
      <div className="col-6 d-flex flex-row">
        {likesEnabled &&
          <div
            className="social-button"
            onClick={(evt) => this.handleReactionChange(evt, 'Like')}
          >
            <div className={`social-like-image ${userReaction.reactionType === 'Like' ? 'green-color' : ''} mr-2`} />
            <div>{likeCount}</div>
          </div>
        }
        {dislikesEnabled &&
          <div
            className="social-button"
            onClick={(evt) => this.handleReactionChange(evt, 'Dislike')}
          >
            <div className={`social-dislike-image ${userReaction.reactionType === 'Dislike' ? 'red-color' : ''} mr-2`} />
            <div>{dislikeCount}</div>
          </div>
        }
      </div>
    )
  }

  translatePostClick(evt) {
    evt.stopPropagation()
    const { title, body, postId } = this.props.comment
    this.commentActionsClick(evt)
    this.props.translatePostAction(title, body, postId)
  }

  translateToOriginalClick(evt) {
    evt.stopPropagation()
    const _state = this.state
    _state.translations = {
      ..._state.translations,
      active: !_state.translations.active
    }
    this.setState(_state)
  }

  renderTranslateBar(translations) {
    const { translationsError } = this.props
    if (translationsError && Object.keys(translationsError).length) {
      return (
        <div className="col-12 col-md-4 p-2 my-2 d-flex justify-content-between translate-bar">
          {t('issues_translating_comment')}
        </div>
      )
    }
    return (
      <div>
        {translations.loadedData &&
          < div className="col-12 col-md-4 p-2 my-2 d-flex justify-content-between translate-bar"> {/* add back justify content-between for REP-903 */}
            <div className="d-flex align-items-start">
              <i className="material-icons mr-3">translate</i> {/* remove mr-3 for REP-903 */}
              {translations.loadedData && <div className="ml-2"> {t('translated_from')} {convertLanguageCodeToLanguageName(translations.body.sourceLanguage)} </div>}
              {/* add the above line for REP-903 */}
            </div>
            {translations.active && <div className="text-uppercase cursor-pointer original" onClick={this.translateToOriginalClick}> Original </div>}
            {!translations.active && <div className="text-uppercase cursor-pointer" onClick={this.translateToOriginalClick}> {t('translate')} </div>}
          </div>
        }
      </div>
    )
  }

  renderTranslateMenuItem = () => {
    return <button className="dropdown-item" type="button" onClick={this.translatePostClick}>{t('translate')}</button>
  }

  handleNameClick(evt, personId) {
    evt.stopPropagation()
    this.props.history.push(`/public-profile/${obfuscate(personId)}`)
  }

  render() {
    const { commentAction, showEditBox, editCommentText, translations, modal } = this.state
    const {
      addCommentReplyPending, removeCommentReplyPending, editPostCommentReplyPending,
      comment, userAccess, index, nrOfComments, showRepliesForComment, userAvatar, history,
    } = this.props

    if (addCommentReplyPending || removeCommentReplyPending || editPostCommentReplyPending) {
      return (
        <Preloader />
      )
    }
    return (
      <div className={`comments-list-item comment-${comment.postId}`}>
        <div className="section d-flex align-items-center personal-info" >
          <div className="d-flex" >
            <PostUserAvatar
              personName={comment.personName}
              userAvatarUrl={userAvatar !== undefined ? userAvatar.url : ''}
              personId={comment.personId}
              redirectToAvatarPage={false}
              history={history}
            />
          </div>
          <div className="col d-flex flex-column justify-content-center">
            <div
              className="name cursor-pointer"
              onClick={(evt) => this.handleNameClick(evt, comment.personId)}
            >
              {comment.personName}
            </div>
            <div className="name">{comment.organizationName}</div>
          </div>

          {comment.isFlagged &&
            <div className="flag-comment">
              <div className="">
                <i className="material-icons flag">flag</i>
              </div>
            </div>
          }

          {showRepliesForComment &&
            <div className="reply-action">
              <div
                className="reply-action-button"
                onClick={this.commentReplyClick}
                data-_comment-id={comment.postId}
                hidden={this.props.postInfo.commentsEnabled ? void 0 : 'yes'}
              >
                <i data-_comment-id={comment.postId} className="material-icons">reply</i>
              </div>
            </div>
          }
          <div className="dropleft comment-actions">
            <div
              className="comment-actions-button"
              onClick={this.commentActionsClick}
              data-comment-id={comment.postId}
            >
              <img data-comment-id={comment.postId} src={commentActionsImg} alt="Comment actions" />
            </div>
            <div className={`dropdown-menu ${commentAction.open && comment.postId === commentAction.commentId ? 'show' : ''}`} aria-labelledby="dropdownMenu2">
              {userAccess && getUserChannelAccess(userAccess, 'edit_remove', comment.personId) &&
                <div>
                  <button className="dropdown-item" type="button" onClick={this.editCommentClick}>{t('edit')}</button>
                  <button className="dropdown-item" type="button" onClick={this.removeCommentClick}>{t('remove')}</button>
                </div>
              }
              { this.renderTranslateMenuItem() }
              {
                !comment.isFlagged &&
                <button className="dropdown-item" type="button" onClick={this.flagPostForReview}>{t('flag_for_review')}</button>
              }
            </div>
          </div>
        </div>

        {this.renderTranslateBar(translations)}

        {!(showEditBox && comment.postId === commentAction.commentId) ?
          <div className="comment-body">{translations.active ? translations.body.text : comment.body} </div>
          :
          this.renderAddOrEditPostComment(false, editCommentText, this.editCommentChange, this.editCommentAction, true)
        }
        {this.renderSocialMarkup(moment(new Date(comment.creationDate)))}

        <div className="mt-3 d-flex align-items-center justify-content-between">
          <div>
            {comment.commentCount > 0 ?
              <div
                className="d-flex align-items-center replies-number pt-2 pb-2 cursor-pointer"
                onClick={this.commentReplyClick}
                data-_comment-id={comment.postId}
              >
                <i data-_comment-id={comment.postId} className="material-icons mr-2">reply</i>
                {comment.commentCount} {comment.commentCount === 1 ? 'Reply' : 'Replies'}
              </div>
              :
              ''
            }
          </div>
        </div>

        {showRepliesForComment
          && showRepliesForComment.status
          && showRepliesForComment.commentId === comment.postId
          && this.renderCommentReplies()
        }
        {index !== nrOfComments - 1 && <hr />}
        <ActionModal
          {...modal}
        />
      </div>
    )
  }
}


function mapStateToProps(state, props) {
  const { postId } = props.comment
  return {
    commentReplies: cloneDeep(state.postCardReducer.commentReplies),
    commentRepliesPending: state.postCardReducer.commentRepliesPending,
    addCommentReplyPending: state.postCardReducer.addCommentReplyPending,
    removeCommentReplyPending: state.postCardReducer.removeCommentReplyPending,
    editPostCommentReplyPending: state.postCardReducer.editPostCommentReplyPending,
    userReaction: state.reactionsReducer.userReactions[postId],
    userReactionsPending: state.reactionsReducer.userReactionsPending['replies'],
    postReactionPending: state.reactionsReducer.postReactionPending[postId],
    deleteReactionPending: state.reactionsReducer.deleteReactionPending[postId],
    translations: state.channelReducer.translations[postId],
    translationsPending: state.channelReducer.translationsPending,
    translationsError: state.channelReducer.translationsError[postId],
    profileImages: state.profileReducer.profileImages,
    profileImagesPending: state.profileReducer.profileImagesPending['replies'],
    profileImagesError: state.profileReducer.profileImagesError,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      editPostComment: (commentObj) => editPostComment(commentObj),
      getCommentReplies: (commentId) => getCommentReplies(commentId),
      addCommentReply: (data) => addCommentReply(data),
      editPostCommentReply: (commentObj) => editPostCommentReply(commentObj),
      postReaction: (reaction, postId) => postReaction(reaction, postId),
      deleteReaction: (reactionId, postId) => deleteReaction(reactionId, postId),
      translatePostAction: (title, body, postId) => translatePost(title, body, postId),
      getUserReactions: (postIds, category) => getUserReactions(postIds, category),
      postListOfProfileImages: (personIds, location) => postListOfProfileImages(personIds, location),
    },
    dispatch
  )
}

PostComment.propTypes = {
  comment: PropTypes.object,
  postInfo: PropTypes.object,
  editPostComment: PropTypes.func,
  userAccess: PropTypes.object,
  onCommentsDataLoaded: PropTypes.func,
  getCommentReplies: PropTypes.func,
  commentReplies: PropTypes.array,
  commentRepliesPending: PropTypes.bool,
  addCommentReply: PropTypes.func,
  addCommentReplyPending: PropTypes.bool,
  editPostCommentReply: PropTypes.func,
  editPostCommentReplyPending: PropTypes.bool,
  removeCommentReplyPending: PropTypes.bool,
  userReaction: PropTypes.object,
  getUserReactions: PropTypes.func,
  userReactionsPending: PropTypes.bool,
  postReaction: PropTypes.func,
  postReactionPending: PropTypes.bool,
  deleteReaction: PropTypes.func,
  deleteReactionPending: PropTypes.bool,
  removeCommentClick: PropTypes.func,
  index: PropTypes.number,
  nrOfComments: PropTypes.number,
  type: PropTypes.string,
  translatePostAction: PropTypes.func,
  translations: PropTypes.object,
  translationsPending: PropTypes.bool,
  translationsError: PropTypes.object,
  flagCommentClick: PropTypes.func,
  showRepliesForComment: PropTypes.object,
  handleShowRepliesForComment: PropTypes.func,
  userAvatar: PropTypes.object,
  history: PropTypes.object,
  postListOfProfileImages: PropTypes.func,
  profileImages: PropTypes.array,
  profileImagesPending: PropTypes.bool,
  profileImagesError: PropTypes.object,
  commentToNavigateTo: PropTypes.object,
}

const PostCommentWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(PostComment)

export default PostCommentWrapper
