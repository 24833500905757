// CORP VALUES
export const INTEGRITY_VALUE = 'INTEGRITY'
export const PRIDE_VALUE = 'PRIDE'
export const SERVICE_VALUE = 'SERVICE'
export const TEAMWORK_VALUE = 'TEAMWORK'
export const INNOVATION_VALUE = 'INNOVATION'
export const RESULTS_VALUE = 'RESULTS'
export const STORE_INVENTORY_VALUE = 'Store Inventory'

// moderation system ids
export const NEWSFEED_MODERATION_SYSTEM_ID = 1
export const COMPLIMENTS_MODERATION_SYSTEM_ID = 2
export const AVATAR_MODERATION_SYSTEM_ID = 3