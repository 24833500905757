import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { t } from '../../utils/i18n'
import { setShowAppDownloadModal } from '../../state/actions/mobileDeviceActions'
import { deviceIsAndroid, deviceIsIOS, isIOSChrome } from '../utils/mobileUtils'
import { getPreferredLanguage } from '../utils/localStorageUtils'

import './AppDownloadModal.scss'


class AppDownloadModal extends Component {

    componentDidMount() {
    }

    handleCloseAppDownloadModalCloseClick = () => {
        this.setShowAppDownloadModal(false)
    }

    setShowAppDownloadModal = (showModal) => {
        this.props.setShowAppDownloadModal(showModal)
    }

    getAppDownloadModalMarkUp = () => {
      let language = getPreferredLanguage() 
      if (deviceIsAndroid()) {
        if (language.toLowerCase() === 'en') {
          return (
            <a target="_blank" rel="noopener noreferrer" href='https://play.google.com/store/apps/details?id=com.wisintl.wisconnectmobile'><img className='google-play-icon' alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png'/></a>
          )
        } else if (language.toLowerCase() === 'fr') {
          return (
            <a target="_blank" rel="noopener noreferrer" href='https://play.google.com/store/apps/details?id=com.wisintl.wisconnectmobile'><img className='google-play-icon' alt='Disponible sur Google Play' src='https://play.google.com/intl/en_us/badges/images/generic/fr-ca_badge_web_generic.png'/></a>
          )
        } else if (language.toLowerCase() === 'es') {
          return (
            <a target="_blank" rel="noopener noreferrer" href='https://play.google.com/store/apps/details?id=com.wisintl.wisconnectmobile'><img className='google-play-icon' alt='Disponible en Google Play' src='https://play.google.com/intl/en_us/badges/images/generic/es-419_badge_web_generic.png'/></a>
          )
        }
      } else if (deviceIsIOS()) {
        if (isIOSChrome()) {
          return (
            <div>
              {this.getIOSAppDivMarkUp(language)}
              <div className='ios-chrome-instructions'>{t('ios_chrome_instructions')}</div>
              <div>
                <input type='text' className='readonly-textbox' ref={(iosTextBoxRef) => this.iosTextBoxRef = iosTextBoxRef} readOnly value='https://apps.apple.com/us/app/wis-connect-mobile/id6447637947' />
                <input type='button' className='btn-rgis-blue' value={t('copy')} onClick={this.copyToClipboard} />
                <div className="copied-text-hidden" ref={(copiedTextRef) => this.copiedTextRef = copiedTextRef}>{t('copied')}</div>
              </div>
            </div>
          )
        } else {
          return (<a target="_blank" rel="noopener noreferrer" href='https://apps.apple.com/us/app/wis-connect-mobile/id6447637947'>{this.getIOSAppDivMarkUp(language)}</a>)
        }
        
      } else {
        return ('')
      }
    }

    copyToClipboard = (e) => {
      if (deviceIsIOS()) {
        var oldContentEditable = this.iosTextBoxRef.contentEditable,
        oldReadOnly = this.iosTextBoxRef.readOnly,
        range = document.createRange();

        this.iosTextBoxRef.contentEditable = true;
        this.iosTextBoxRef.readOnly = false;
        range.selectNodeContents(this.iosTextBoxRef);

        var s = window.getSelection();
        s.removeAllRanges();
        s.addRange(range);

        this.iosTextBoxRef.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.

        this.iosTextBoxRef.contentEditable = oldContentEditable;
        this.iosTextBoxRef.readOnly = oldReadOnly;

        document.execCommand('copy');
        this.copiedTextRef.className = 'copied-text-visible'
      } else {
        this.iosTextBoxRef.select()
        document.execCommand('copy')
        e.target.focus()
        this.copiedTextRef.className = 'copied-text-visible'
      }
    }

    getIOSAppDivMarkUp = (language) => {
      if (language.toLowerCase() === 'en') {
        return (
          <div className='rconnect-ios-en' />
        )
      } else if (language.toLowerCase() === 'fr') {
        return (
          <div className='rconnect-ios-fr' alt="rconnect-ios-fr"/>
        )
      } else if (language.toLowerCase() === 'es') {
        return (
          <div className='rconnect-ios-es' alt="rconnect-ios-es" />
        )
      } 
    }

    render() {
        return (
            <div className="app-download-modal-component">
            <div
              id="modalAppDownload"
              className={`modal fade fullPageModal animation-topToBottom ${
                this.props.showAppDownloadModal === true ? 'show' : ''
              }`}
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header row">
                    <div className="col">
                      <h5 className="modal-title capitalize-first-letter">{t('download_app')}</h5>
                    </div>
                    <div className="col">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={(event) => this.handleCloseAppDownloadModalCloseClick(event)}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                  <div className="modal-body">
                    {this.getAppDownloadModalMarkUp()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
    }
}

function mapStateToProps(state) {
  return {
    showAppDownloadModal: state.mobileDeviceReducer.showAppDownloadModal,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
        setShowAppDownloadModal: (showModal) => setShowAppDownloadModal(showModal)
    }, dispatch)
}

AppDownloadModal.propTypes = {
    showAppDownloadModal: PropTypes.bool,
    setShowAppDownloadModal: PropTypes.func,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppDownloadModal)
