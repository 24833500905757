import axios from 'axios'
import apiConfig from '../../config/api-config'

export function getCompliment(complimentId) {
    const url = apiConfig.GET_COMPLIMENT_BY_ID.replace('[complimentId]', complimentId)
    return axios.get(`${url}`)
}

export function deleteCompliment(complimentId) {
    const url = apiConfig.DELETE_COMPLIMENT_BY_ID.replace('[complimentId]', complimentId)
    return axios.delete(`${url}`)
}

export function getReceivedCompliments() {
    const url = apiConfig.GET_RECEIVED_COMPLIMENTS
    return axios.get(`${url}`)
}

export function getSentCompliments() {
    const url = apiConfig.GET_SENT_COMPLIMENTS
    return axios.get(`${url}`)
}

export function postCompliment(compliment) {
    const url = apiConfig.POST_COMPLIMENT  
    return axios.post(`${url}`, compliment)
  }
  
export function patchComplimentComment(complimentId, comments) {
    const url = apiConfig.PATCH_COMPLIMENT_COMMENT.replace('[complimentId]', complimentId)
    var payload = {
        comments: comments
    }
    return axios.patch(`${url}`, payload)
}

export function getSentComplimentsPaged(page, pageSize) {
    const url = apiConfig.GET_SENT_COMPLIMENTS_PAGED
        .replace('[page]', page)
        .replace('[pageSize]', pageSize)
    return axios.get(`${url}`)
}


