
import axios from 'axios'
import apiConfig from '../../config/api-config'

export function getShifts() {
  const url = apiConfig.SHIFTS
  return axios.get(`${url}`)
}

export function putAcceptShift(shiftId) {
  const url = apiConfig.ACCEPT_SHIFT.replace('[shiftId]', shiftId)
  return axios.put(`${url}`)
}

export function putRejectShift(shiftId) {
  const url = apiConfig.REJECT_SHIFT.replace('[shiftId]', shiftId)
  return axios.put(`${url}`)
}