import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { t } from '../../../utils/i18n'
import { cloneDeep } from 'lodash'
import { setEditingSchedulingPreferences, saveSchedulingPreferences, patchSchedulingPreferences } from '../../../state/actions/personDetailsActions'
import { getPreferredLanguage } from '../../../components/utils/localStorageUtils'

import './Preferences.scss'

class Preferences extends Component {
  constructor(props) {
    super(props)

    this.handlePreferredPostalCodeOnChange.bind(this)
  }

  getSchedulingPreferencesPostalCode() {
    if (this.props.schedulingPreferences) {
      return this.props.schedulingPreferences.preferredPostalCode
    }

    return ''
  }

  getMinimumWeeklyHoursDesired() {
    if (this.props.schedulingPreferences) {
      if (Number.isInteger(this.props.schedulingPreferences.preferredMinHours)) {
        return this.getWeeklyHourString(this.props.schedulingPreferences.preferredMinHours)
      } else {
        return this.props.schedulingPreferences.preferredMinHours
      }
    }

    return ''
  }

  getMaximumWeeklyHoursDesired() {
    if (this.props.schedulingPreferences) {
      if (Number.isInteger(this.props.schedulingPreferences.preferredMaxHours)) {
        return this.getWeeklyHourString(this.props.schedulingPreferences.preferredMaxHours)
      } else {
        return this.props.schedulingPreferences.preferredMaxHours
      }
    }

    return ''
  }

  getWeeklyHourString(hours) {
    const workWeekHours = 40
    if (parseInt(hours) > workWeekHours) {
      return "40+"
    } else {
      return hours
    }
  }

  getTimePreference() {
    if (this.props.schedulingPreferences) {
      if (parseInt(this.props.schedulingPreferences.shiftPreference) === 0) {
        return t("mornings")
      } else if (parseInt(this.props.schedulingPreferences.shiftPreference) === 1) {
        return t("nights")
      }
    }

    return t("any")
  }

  flipEditSchedulingPreferences(editingSchedulingPreferences) {
    this.props.setEditingSchedulingPreferences(!editingSchedulingPreferences)
  }

  handlePreferredPostalCodeOnChange = (event) => {
    var cloneSchedulingPreferences = cloneDeep(this.props.schedulingPreferences)
    cloneSchedulingPreferences.preferredPostalCode = event.target.value
    this.saveSchedulingPreferences(cloneSchedulingPreferences)
  }

  handlePreferredMinHoursOnChange = (event) => {
    var cloneSchedulingPreferences = cloneDeep(this.props.schedulingPreferences)
    cloneSchedulingPreferences.preferredMinHours = event.target.value
    this.saveSchedulingPreferences(cloneSchedulingPreferences)
  }

  handlePreferredMaxHoursOnChange = (event) => {
    var cloneSchedulingPreferences = cloneDeep(this.props.schedulingPreferences)
    cloneSchedulingPreferences.preferredMaxHours = event.target.value
    this.saveSchedulingPreferences(cloneSchedulingPreferences)
  }

  handleShiftPreferenceOnChange = (event) => {
    var cloneSchedulingPreferences = cloneDeep(this.props.schedulingPreferences)
    cloneSchedulingPreferences.shiftPreference = event.target.value
    this.saveSchedulingPreferences(cloneSchedulingPreferences)
  }

  handleSaveClick = (event) => {
    if (this.formIsValid()) {
      this.saveSchedulingPreferences(this.props.schedulingPreferences)
      var cloneSchedulingPreferences = cloneDeep(this.props.schedulingPreferences)
      this.props.patchSchedulingPreferences(cloneSchedulingPreferences)
      this.flipEditSchedulingPreferences(this.props.editingSchedulingPreferences)
    } else {
      event.preventDefault()
    }
  }

  formIsValid = () => {
    let isValid = true
    const PREFERRED_HOUR_MINIMUM_DIFFERENCE = 5
    let postalCodeRegex = /^[0-9]{5}$/
    let country = this.props.personDetails.country
    let postalCodeLength = 5
    if (country === 'CA') {
      postalCodeRegex = /[A-Za-z][0-9][A-Za-z]\s?[0-9][A-Za-z][0-9]/
      postalCodeLength = 7
    }

    if (this.props.schedulingPreferences.preferredPostalCode !== this.props.personDetails.postalCode) {
      if (this.props.schedulingPreferences.preferredPostalCode === undefined || this.props.schedulingPreferences.preferredPostalCode === null
        || this.props.schedulingPreferences.preferredPostalCode === '' || !postalCodeRegex.test(this.props.schedulingPreferences.preferredPostalCode)
        || this.props.schedulingPreferences.preferredPostalCode.length > postalCodeLength) {
        isValid = false
        alert(t(`error_postal_code_${country}`))
      }
    } 
    if (this.props.schedulingPreferences.preferredMinHours === undefined || this.props.schedulingPreferences.preferredMinHours === null
      || this.props.schedulingPreferences.preferredMinHours === '' || !Number.isInteger(parseInt(this.props.schedulingPreferences.preferredMinHours))
      || parseInt(this.props.schedulingPreferences.preferredMinHours) < 0 || parseInt(this.props.schedulingPreferences.preferredMinHours) > 60) {
      isValid = false
      alert(t('error_min_hours'))
    } else if (this.props.schedulingPreferences.preferredMaxHours === undefined || this.props.schedulingPreferences.preferredMaxHours === null
      || this.props.schedulingPreferences.preferredMaxHours === '' || !Number.isInteger(parseInt(this.props.schedulingPreferences.preferredMaxHours))
      || parseInt(this.props.schedulingPreferences.preferredMaxHours) < 0 || parseInt(this.props.schedulingPreferences.preferredMaxHours) > 60) {
      isValid = false
      alert(t('error_max_hours'))
    } else if (parseInt(this.props.schedulingPreferences.preferredMinHours) > parseInt(this.props.schedulingPreferences.preferredMaxHours)) {
      isValid = false
      alert(t('min_hours_max_hours_error'))
    } else if (this.props.schedulingPreferences.twelveHourShift === undefined || this.props.schedulingPreferences.twelveHourShift === null || this.props.schedulingPreferences.twelveHourShift === '') {
      isValid = false
      alert(t('error_twelve_hour_shift'))
    } else if (this.props.schedulingPreferences.neverAboveMaxHours === undefined || this.props.schedulingPreferences.neverAboveMaxHours === null || this.props.schedulingPreferences.neverAboveMaxHours === '') {
      isValid = false
      alert(t('error_never_above_max_hours'))
    } else if ((parseInt(this.props.schedulingPreferences.preferredMaxHours) - parseInt(this.props.schedulingPreferences.preferredMinHours)) < PREFERRED_HOUR_MINIMUM_DIFFERENCE) {
      isValid = false
      alert(t('error_preferred_hour_diff'))
    }

    return isValid
  }

  handleCancelClick = () => {
    var cloneSchedulingPreferences = cloneDeep(this.props.backupSchedulingPreferences)
    this.saveSchedulingPreferences(cloneSchedulingPreferences)
    this.flipEditSchedulingPreferences(this.props.editingSchedulingPreferences)
  }

  saveSchedulingPreferences = (schedulingPreferences) => {
    let cloneSchedulingPreferences = cloneDeep(schedulingPreferences)
    let preferredPostalCode = cloneSchedulingPreferences.preferredPostalCode
    if (preferredPostalCode) {
      cloneSchedulingPreferences.personId = this.props.personDetails.personId
    }
    if (preferredPostalCode && !preferredPostalCode.includes(' ') && this.props.personDetails.country === 'CA' && preferredPostalCode.length === 6) {
      cloneSchedulingPreferences.preferredPostalCode = [preferredPostalCode.slice(0, 3), preferredPostalCode.slice(3)].join(' ')
    }
    this.props.saveSchedulingPreferences(cloneSchedulingPreferences)
  }

  getAvailTwelveHourShiftText = () => {
    return this.getReadOnlyTrueFalseTextMarkUp(this.props.schedulingPreferences.twelveHourShift)
  }

  getReadOnlyTrueFalseTextMarkUp = (valueToCheck) => {
    if (valueToCheck === undefined || valueToCheck === null || valueToCheck === '') {
      return (<div></div>)
    } else if (valueToCheck.toString().toLowerCase() === 'false') {
      return (<div>{t('no')}</div>)
    } else if (valueToCheck.toString().toLowerCase() === 'true') {
      return (<div>{t('yes')}</div>)
    }
  }

  handleTwelveHourShiftOnChange = (event) => {
    var cloneSchedulingPreferences = cloneDeep(this.props.schedulingPreferences)

    if (event.target.value === null || event.target.value === '') {
      cloneSchedulingPreferences.twelveHourShift = null
    } else if (event.target.value.toString().toLowerCase() === 'false') {
      cloneSchedulingPreferences.twelveHourShift = false
    } else if (event.target.value.toString().toLowerCase() === 'true') {
      cloneSchedulingPreferences.twelveHourShift = true
    }

    this.saveSchedulingPreferences(cloneSchedulingPreferences)
  }

  handleNeverAboveMaxHoursOnChange = (event) => {
    var cloneSchedulingPreferences = cloneDeep(this.props.schedulingPreferences)

    if (event.target.value === null || event.target.value === '') {
      cloneSchedulingPreferences.neverAboveMaxHours = null
    } else if (event.target.value.toString().toLowerCase() === 'false') {
      cloneSchedulingPreferences.neverAboveMaxHours = false
    } else if (event.target.value.toString().toLowerCase() === 'true') {
      cloneSchedulingPreferences.neverAboveMaxHours = true
    }

    this.saveSchedulingPreferences(cloneSchedulingPreferences)
  }

  renderNotAboveMaximumMarkUpControl = () => {
    if (this.props.editingSchedulingPreferences) {
      return (
        <div>
          <select value={this.props.schedulingPreferences.neverAboveMaxHours} onChange={this.handleNeverAboveMaxHoursOnChange}>
            <option value=""></option>
            <option value="false">{t('no')}</option>
            <option value="true">{t('yes')}</option>
          </select>
        </div>
      )
    } else {
      return this.getReadOnlyTrueFalseTextMarkUp(this.props.schedulingPreferences.neverAboveMaxHours)
    }
  }

  renderNotAboveMaximumMarkUp = () => {
    if (parseInt(this.props.schedulingPreferences.preferredMaxHours) > 0) {
      return (
        <div className="section">
          <div className="row">
            <div className="col">
              <div className="heading">{t('not_above_maximum')}</div>
              <div>{this.renderNotAboveMaximumMarkUpControl()}</div>
            </div>
          </div>
        </div>
      )
    } else {
      return (<div></div>)
    }
  }

  handlePostalCodeInfoOnClick = () => {
    alert(t('postal_code_info'))
  }

  schedulingPreferencesIsSet = () => {
    return this.props.schedulingPreferences.personId !== undefined && this.props.schedulingPreferences.personId !== null && this.props.schedulingPreferences.personId !== ''
  }

  handleEditOnClickNoContent = () => {
    let cloneSchedulingPreferences = cloneDeep(this.props.schedulingPreferences)
    cloneSchedulingPreferences.preferredPostalCode = this.props.personDetails.postalCode
    cloneSchedulingPreferences.preferredMinHours = ''
    cloneSchedulingPreferences.preferredMaxHours = ''
    cloneSchedulingPreferences.neverAboveMaxHours = false
    cloneSchedulingPreferences.twelveHourShift = false
    this.saveSchedulingPreferences(cloneSchedulingPreferences)

    this.flipEditSchedulingPreferences(false)
  }

  render() {
    const { personDetailsPending, schedulingPreferencesPending, editingSchedulingPreferences, patchSchedulingPreferencesPending } = this.props
    const language = getPreferredLanguage()

    if (personDetailsPending || schedulingPreferencesPending || patchSchedulingPreferencesPending) {
      return (
        <div>
          <h3>{t('scheduling_preferences')}</h3>
          <h5>{t('loading')}</h5>
        </div>
      )
    }

    if (!this.schedulingPreferencesIsSet() && !editingSchedulingPreferences) {
      return (
        <div className="preferences-component">
          <div className="section">
            <div className="row">
              <div className={`col general-title uppercase ${language.toUpperCase() === 'FR' ? '' : 'capitalize'}`}> 
                {t('scheduling_preferences')} | <a className="cursor-pointer preferences-edit-link uppercase" onClick={this.handleEditOnClickNoContent}>{t('edit')}</a> {/* eslint-disable-line */}
              </div>
            </div>
          </div>

          <div className="section">
            <div className="row">
              <div className="col">
                <div className="heading">{t('enter_scheduling_preferences')}</div>
              </div>
            </div>
          </div>
        </div>
      )
    } else if (editingSchedulingPreferences) {
      return (
        <form onSubmit={this.handleSaveClick}>
          <div className="preferences-component">

            <div className="section">
              <div className="row">
                <div className={`col general-title uppercase ${language === 'FR' ? '' : 'capitalize'}`}>
                  {t('scheduling_preferences')} | <a className="cursor-pointer preferences-edit-link uppercase" onClick={this.handleCancelClick}>{t('cancel')}</a> {/* eslint-disable-line*/}
                </div>
              </div>
            </div>

            <div className="section">
              <div className="row">
                <div className="col">
                  <div className="heading">{t('preferred_postal_code_for_sched')}*
                <i title={t('postal_code_info')} onClick={this.handlePostalCodeInfoOnClick} className="cursor-pointer material-icons info">info</i></div>
                  <div><input type='text' value={this.props.schedulingPreferences.preferredPostalCode} onChange={this.handlePreferredPostalCodeOnChange} /></div>
                </div>
              </div>
            </div>

            <div className="section">
              <div className="row">
                <div className="col">
                  <div className="heading">{t('minimum_weekly_hours_desired')}*</div>
                  <div><input type='number' maxLength='2' value={this.props.schedulingPreferences.preferredMinHours} onChange={this.handlePreferredMinHoursOnChange} /></div>
                </div>
              </div>
            </div>

            <div className="section">
              <div className="row">
                <div className="col">
                  <div className="heading">{t('maximum_weekly_hours_desired')}*</div>
                  <div><input type='number' maxLength='2' value={this.props.schedulingPreferences.preferredMaxHours} onChange={this.handlePreferredMaxHoursOnChange} /></div>
                </div>
              </div>
            </div>

            {this.renderNotAboveMaximumMarkUp()}

            <div className="section">
              <div className="row">
                <div className="col">
                  <div className="heading">{t('time_preference')}</div>
                  <div>
                    <select value={this.props.schedulingPreferences.shiftPreference === null ? '' : this.props.schedulingPreferences.shiftPreference} onChange={this.handleShiftPreferenceOnChange}>
                      <option value="">{t("any")}</option>
                      <option value="0">{t("mornings")}</option>
                      <option value="1">{t("nights")}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="section">
              <div className="row">
                <div className="col">
                  <div className="heading">{t('available_twelve_hour')}</div>
                  <div>
                    <select value={this.props.schedulingPreferences.twelveHourShift} onChange={this.handleTwelveHourShiftOnChange}>
                      <option value=""></option>
                      <option value="false">{t('no')}</option>
                      <option value="true">{t('yes')}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="section">
              <div className="row">
                <div className="col">
                  <input className="btn btn-rgis-blue semiBoldText uppercase cursor-pointer" type="submit" value={t('save')} />
                </div>
              </div>
            </div>

            <div className="section">
              <div className="row">
                <div className="col">
                  <div className="heading">*{t('required_fields')}</div>
                </div>
              </div>
            </div>

          </div>
        </form>
      )
    } else {
      return (
        <div className="preferences-component">
          <div className="section">
            <div className="row">
              <div className={`col general-title uppercase ${language === 'FR' ? '' : 'capitalize'}`}>
                {t('scheduling_preferences')} | <a className="cursor-pointer preferences-edit-link uppercase" onClick={() => this.flipEditSchedulingPreferences(false)}>{t('edit')}</a> {/* eslint-disable-line */}
              </div>
            </div>
          </div>

          <div className="section">
            <div className="row">
              <div className="col">
                <div className="heading">{t('preferred_postal_code_for_sched')}</div>
                <div>{this.getSchedulingPreferencesPostalCode()}</div>
              </div>
            </div>
          </div>

          <div className="section">
            <div className="row">
              <div className="col">
                <div className="heading">{t('minimum_weekly_hours_desired')}</div>
                <div>{this.getMinimumWeeklyHoursDesired()}</div>
              </div>
            </div>
          </div>

          <div className="section">
            <div className="row">
              <div className="col">
                <div className="heading">{t('maximum_weekly_hours_desired')}</div>
                <div>{this.getMaximumWeeklyHoursDesired()}</div>
              </div>
            </div>
          </div>

          {this.renderNotAboveMaximumMarkUp()}

          <div className="section">
            <div className="row">
              <div className="col">
                <div className="heading">{t('time_preference')}</div>
                <div>{this.getTimePreference()}</div>
              </div>
            </div>
          </div>

          <div className="section">
            <div className="row">
              <div className="col">
                <div className="heading">{t('available_twelve_hour')}</div>
                <div>
                  {this.getAvailTwelveHourShiftText()}
                </div>
              </div>
            </div>
          </div>

        </div>
      )
    }
  }
}

function mapStateToProps(state) {
  return {
    personDetails: state.personDetailsReducer.personDetails,
    personDetailsPending: state.personDetailsReducer.personDetailsPending,
    schedulingPreferences: state.personDetailsReducer.schedulingPreferences,
    backupSchedulingPreferences: state.personDetailsReducer.backupSchedulingPreferences,
    schedulingPreferencesPending: state.personDetailsReducer.schedulingPreferencesPending,
    editingSchedulingPreferences: state.personDetailsReducer.editingSchedulingPreferences,
    patchSchedulingPreferencesPending: state.personDetailsReducer.patchSchedulingPreferencesPending,
    profileLocale: state.loginReducer.profileLocale,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      patchSchedulingPreferences: (schedulingPreferences) => patchSchedulingPreferences(schedulingPreferences),
      saveSchedulingPreferences: (schedulingPreferences) => saveSchedulingPreferences(schedulingPreferences),
      setEditingSchedulingPreferences: (editing) => setEditingSchedulingPreferences(editing),
    },
    dispatch
  )
}

Preferences.propTypes = {
  patchSchedulingPreferences: PropTypes.func,
  saveSchedulingPreferences: PropTypes.func,
  setEditingSchedulingPreferences: PropTypes.func,
  personDetails: PropTypes.object,
  personDetailsPending: PropTypes.bool,
  schedulingPreferences: PropTypes.object,
  backupSchedulingPreferences: PropTypes.object,
  schedulingPreferencesPending: PropTypes.bool,
  editingSchedulingPreferences: PropTypes.bool,
  patchSchedulingPreferencesPending: PropTypes.bool,
  profileLocale: PropTypes.string,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Preferences)

