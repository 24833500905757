import 'moment/locale/es'
import 'moment/locale/fr'
import 'moment/locale/pt'

import services from '../../state/services'
import { t } from '../../utils/i18n'
import { config as appConfig } from '../../config/config'
import { getLoggedInPersonId } from './localStorageUtils'
import { nullSafeCheckIsTrue } from './nullSafeCheckUtils'

export function convertLanguageCodeToLanguageName(languageCode) {
  const englishLanguageCode = 'en-us'
  const spanishLanguageCode = 'es-us'
  const frenchLanguageCode = 'fr-ca'
  const englishLanguageName = t('english')
  const spanishLanguageName = t('spanish')
  const frenchLanguageName = t('french')

  if (languageCode === undefined || languageCode === null) { return '' }

  let languageName = ''

  switch(languageCode) {
    case englishLanguageCode:
      languageName = englishLanguageName
      break
    case spanishLanguageCode:
      languageName = spanishLanguageName
      break
    case frenchLanguageCode:
      languageName = frenchLanguageName
      break
    default:
      languageName = ''
  }

  return languageName
}

export function getVersion4Dot7Dot2FeaturesActive() {
  return nullSafeCheckIsTrue(appConfig.FEATURE_FLAG_4_7_2)
}

export function getVersion5Dot1FeaturesActive() {
  return nullSafeCheckIsTrue(appConfig.FEATURE_FLAG_5_1)
}

export function getRank(ranking) {
  switch (ranking) {
    case 'AUDITOR':
      return t('auditor')
    case 'SPECIALIST':
      return t('specialist')
    case 'EXPERT':
      return t('expert')
    case 'TOP_GUN':
      return t('top_gun')
    default:
      return ranking
  }
}

export function getRankAbbreviation(ranking) {
  if (ranking) {
    return `(${ranking[0]})`
  }
  return ''
}

export function uploadFilePromise(file, onUploadProgress, fileRequestToken) {
  return services.files.uploadFile(file, onUploadProgress, fileRequestToken);
}

export function deleteFilePromise(url) {
  services.files.deleteFile(url).then(() => {}).catch(() => {});
}

export function getUserChannelAccess(channelAccess, type, ownerId) {
  const { canView, canCreatePosts, isAdmin } = channelAccess
  const personId = parseInt(getLoggedInPersonId())

  if (isAdmin) return true
  // type will be -> view, create, edit_remove
  switch (type) {
    case 'view':
      if (canView) return true
      break
    case 'create':
      if (canCreatePosts) return true
      break
    case 'edit_remove':
      if (personId === ownerId) return true
      break
    default:
      return false
  }
  return false
}

export function padNumberWithLeadingZeroes(number, length) {
  let _number = number
  while (_number.length < length) {
    _number = '0' + _number
  }
  return _number
}
