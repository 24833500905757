import en from './en.json'
import es from './es.json'
import fr from './fr.json'
import de from './de.json'
import hu from './hu.json'
import it from './it.json'
import nl from './nl.json'
import pl from './pl.json'
import pt from './pt.json'

import { get } from 'lodash'
import { getPreferredLanguage } from '../../components/utils/localStorageUtils'

const langs = {
  en,
  es,
  fr,
  de,
  hu,
  it,
  nl,
  pl,
  pt
}

export function t(phrase, phraseParams = []) {
  let phrases = langs[getPreferredLanguage().toLowerCase()]

  if (phraseParams.length === 0) {
    return get(phrases, phrase, '')
  } else {
    let tempPhrase = get(phrases, phrase, '')
    for (let i = 0; i < phraseParams.length; i++) {
      tempPhrase = tempPhrase.replace("{" + i + "}", phraseParams[i])
    }
    return tempPhrase
  }
}
