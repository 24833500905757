import get from 'lodash/get'
import * as types from './actionTypes'
import services from '../services'

import { addToastMessage } from '../../state/actions/toastsActions'

import { ToastType } from '../../components/Alerts/Toast'

import { t } from '../../utils/i18n/index'

export function doRequestChange(dataToSend) {
  return dispatch => {
    dispatch(setRequesChangePending(true))
    services.schedule
      .insertAvailability(dataToSend)
      .then(response => {
        dispatch(setRequesChangePending(false))
        dispatch(doRequestChangeAction(response))
        dispatch(addToastMessage(ToastType.SUCCESS, t('request_successfully_sent')))
      })
      .catch(error => {
        dispatch(setRequestChangeError(error))
        dispatch(addToastMessage(ToastType.ERROR, t('error_occurred_retry')))
      })
  }
}

const doRequestChangeAction = response => {
  return {
    type: types.GET_REQUEST_CHANGE_SUCCESS,
    payload: response.data,
    status: response.status,
  }
}
const setRequestChangeError = response => {
  return {
    type: types.GET_REQUEST_CHANGE_ERROR,
    payload: response,
  }
}
const setRequesChangePending = status => {
  return {
    type: types.GET_REQUEST_CHANGE_PENDING,
    payload: status,
  }
}
