import * as types from './actionTypes'
import services from '../services'

export function getProfileImage() {
	return dispatch => {
		dispatch(getProfileImageActionPending(true))
		services.profile
			.getProfileImage()
			.then(response => {
				dispatch(getProfileImageActionSuccess(response))
				dispatch(getProfileImageActionPending(false))
			})
			.catch(error => {
				dispatch(getProfileImageActionError(error))
				dispatch(getProfileImageActionPending(false))
			})
	}
}
const getProfileImageActionSuccess = response => {
	return {
		type: types.GET_PROFILE_IMAGE_SUCCESS,
		payload: response.data,
	}
}
const getProfileImageActionError = response => {
	return {
		type: types.GET_PROFILE_IMAGE_ERROR,
		error: response,
	}
}
const getProfileImageActionPending = status => {
	return {
		type: types.GET_PROFILE_IMAGE_PENDING,
		status,
	}
}

export function postProfileImage(personId, imageDataBase64) {
	return dispatch => {
		dispatch(postProfileImagePending(true))
		services.profile
			.postProfileImage(personId, imageDataBase64)
			.then(response => {
				dispatch(postProfileImageSuccess(response))
				dispatch(postProfileImagePending(false))
			})
			.catch(error => {
				dispatch(postProfileImageError(error.response || { error: 'error' }))
				dispatch(postProfileImagePending(false))
			})
	}
}

const postProfileImageSuccess = response => {
	return {
		type: types.POST_PROFILE_IMAGE_SUCCESS,
		payload: response.data,
	}
}
const postProfileImageError = response => {
	return {
		type: types.POST_PROFILE_IMAGE_ERROR,
		error: response,
	}
}
const postProfileImagePending = status => {
	return {
		type: types.POST_PROFILE_IMAGE_PENDING,
		status,
	}
}

export function resetListOfProfileImages() {
	return dispatch => {
		dispatch(resetListOfProfileImagesAction())
	}
}

const resetListOfProfileImagesAction = () => {
	return {
		type: types.RESET_LIST_OF_PROFILE_IMAGES,
	}
}

export function postListOfProfileImages(personIds, location) {
	return dispatch => {
		dispatch(postListOfProfileImagesActionPending(location, true))
		services.profile
			.postListOfProfileImages(personIds)
			.then(response => {
				dispatch(postListOfProfileImagesActionSuccess(response))
				dispatch(postListOfProfileImagesActionPending(location, false))
			})
			.catch(error => {
				dispatch(postListOfProfileImagesActionError(error))
				dispatch(postListOfProfileImagesActionPending(location, false))
			})
	}
}

const postListOfProfileImagesActionSuccess = response => {
	return {
		type: types.POST_LIST_OF_PROFILE_IMAGES_SUCCESS,
		payload: response.data,
	}
}
const postListOfProfileImagesActionError = response => {
	return {
		type: types.POST_LIST_OF_PROFILE_IMAGES_ERROR,
		error: response,
	}
}
const postListOfProfileImagesActionPending = (location, status) => {
	return {
		type: types.POST_LIST_OF_PROFILE_IMAGES_PENDING,
		location,
		status,
	}
}

export function deleteProfileImage(personId) {
	return dispatch => {
		dispatch(deleteProfileImagePending(true))
		services.profile
			.deleteProfileImage(personId)
			.then(response => {
				dispatch(deleteProfileImageSuccess(response))
				dispatch(deleteProfileImagePending(false))
			})
			.catch(error => {
				dispatch(deleteProfileImageError(error))
				dispatch(deleteProfileImagePending(false))
			})
	}
}

const deleteProfileImageSuccess = response => {
	return {
		type: types.DELETE_PROFILE_IMAGE_SUCCESS,
		payload: response.data,
	}
}
const deleteProfileImageError = response => {
	return {
		type: types.DELETE_PROFILE_IMAGE_ERROR,
		error: response,
	}
}
const deleteProfileImagePending = status => {
	return {
		type: types.DELETE_PROFILE_IMAGE_PENDING,
		status,
	}
}

export function getProfilePreferredLanguage() {
	return dispatch => {
		dispatch(getProfilePreferredLanguagePending(true))
		services.profile
			.getPreferredLanguage()
			.then(response => {
				dispatch(getProfilePreferredLanguageSuccess(response))
				dispatch(getProfilePreferredLanguagePending(false))
			})
			.catch(error => {
				dispatch(getProfilePreferredLanguageError(error))
				dispatch(getProfilePreferredLanguagePending(false))
			})
	}
}

const getProfilePreferredLanguageSuccess = response => {
	return {
		type: types.GET_PROFILE_PREFERRED_LANGUAGE_SUCCESS,
		payload: response.data,
	}
}
const getProfilePreferredLanguageError = response => {
	return {
		type: types.GET_PROFILE_PREFERRED_LANGUAGE_ERROR,
		error: response,
	}
}
const getProfilePreferredLanguagePending = status => {
	return {
		type: types.GET_PROFILE_PREFERRED_LANGUAGE_PENDING,
		status,
	}
}

export function updateProfilePreferredLanguage(preferredLanguage) {
	return dispatch => {
		dispatch(updateProfilePreferredLanguagePending(true))
		services.profile
			.updatePreferredLanguage(preferredLanguage)
			.then(response => {
				dispatch(updateProfilePreferredLanguageSuccess(response))
				dispatch(updateProfilePreferredLanguagePending(false))
			})
			.catch(error => {
				dispatch(updateProfilePreferredLanguageError(error))
				dispatch(updateProfilePreferredLanguagePending(false))
			})
	}
}

const updateProfilePreferredLanguageSuccess = response => {
	return {
		type: types.PUT_PROFILE_PREFERRED_LANGUAGE_SUCCESS,
		payload: response.data,
	}
}
const updateProfilePreferredLanguageError = response => {
	return {
		type: types.PUT_PROFILE_PREFERRED_LANGUAGE_ERROR,
		error: response,
	}
}
const updateProfilePreferredLanguagePending = status => {
	return {
		type: types.PUT_PROFILE_PREFERRED_LANGUAGE_PENDING,
		status,
	}
}

export function setEditingCommunicationPreferrences(editing) {
	return dispatch => {
		dispatch(setEditingCommunicationPreferrencesAction(editing))
	}
}
const setEditingCommunicationPreferrencesAction = status => {
	return {
		type: types.SET_EDITING_COMMUNICATION_PREFERENCES,
		payload: status,
	}
}

export function getProfilePreferredLanguageList() {
	return dispatch => {
		dispatch(getProfilePreferredLanguageListPending(true))
		services.profile
			.getPreferredLanguageList()
			.then(response => {
				dispatch(getProfilePreferredLanguageListSuccess(response))
				dispatch(getProfilePreferredLanguageListPending(false))
			})
			.catch(error => {
				dispatch(getProfilePreferredLanguageListError(error))
				dispatch(getProfilePreferredLanguageListPending(false))
			})
	}
}

const getProfilePreferredLanguageListSuccess = response => {
	return {
		type: types.GET_PROFILE_PREFERRED_LANGUAGE_LIST_SUCCESS,
		payload: response.data,
	}
}
const getProfilePreferredLanguageListError = response => {
	return {
		type: types.GET_PROFILE_PREFERRED_LANGUAGE_LIST_ERROR,
		error: response,
	}
}
const getProfilePreferredLanguageListPending = status => {
	return {
		type: types.GET_PROFILE_PREFERRED_LANGUAGE_LIST_PENDING,
		status,
	}
}