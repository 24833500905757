import {
  GET_ORGANIZATION_SUCCESS,
  GET_ORGANIZATION_ERROR,
  GET_ORGANIZATION_PENDING,
  SEARCH_PEOPLE_IN_ORGANIZATION_SUCCESS,
  SEARCH_PEOPLE_IN_ORGANIZATION_ERROR,
  SEARCH_PEOPLE_IN_ORGANIZATION_PENDING,
  SET_ORGANIZATION_PEOPLE,
  GET_ALL_PEOPLE_IN_ORGANIZATION_ERROR,
  GET_ALL_PEOPLE_IN_ORGANIZATION_PENDING,
  GET_ALL_PEOPLE_IN_ORGANIZATION_SUCCESS
} from '../actions/actionTypes'

export default function organizationsReducer(
  state = {
    organization: {},
    organizationError: '',
    organizationPending: false,
    organizationPeople: [],
    organizationPeopleError: {},
    organizationPeoplePending: false,
  },
  action
) {
  switch (action.type) {
    case GET_ORGANIZATION_SUCCESS:
      return {
        ...state,
        organization: action.payload,
        organizationError: '',
      }
    case GET_ORGANIZATION_ERROR:
      return {
        ...state,
        organizationError: action.error,
      }
    case GET_ORGANIZATION_PENDING:
      return {
        ...state,
        organizationPending: action.pending,
      }
    case SEARCH_PEOPLE_IN_ORGANIZATION_SUCCESS:
      return {
        ...state,
        organizationPeople: action.payload,
        organizationPeopleError: {},
        organizationPeoplePending: false,
      }
    case SEARCH_PEOPLE_IN_ORGANIZATION_ERROR:
      return {
        ...state,
        organizationPeopleError: action.error,
      }
    case SEARCH_PEOPLE_IN_ORGANIZATION_PENDING:
      return {
        ...state,
        organizationPeoplePending: action.pending,
      }
    case GET_ALL_PEOPLE_IN_ORGANIZATION_SUCCESS:
      return {
        ...state,
        organizationPeople: action.payload,
        organizationPeopleError: {},
        organizationPeoplePending: false,
      }
    case GET_ALL_PEOPLE_IN_ORGANIZATION_ERROR:
      return {
        ...state,
        organizationPeopleError: action.error,
      }
    case GET_ALL_PEOPLE_IN_ORGANIZATION_PENDING:
      return {
        ...state,
        organizationPeoplePending: action.pending,
      }

    case SET_ORGANIZATION_PEOPLE:
      return {
        ...state,
        organizationPeople: action.payload,
      }
    default:
      return state
  }
}
