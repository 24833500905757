import axios from 'axios'
import apiConfig from '../../config/api-config'
import { getLocale } from '../../components/utils/datetimeUtils'

// Get info about channel
export function fetchPostCard(postId) {
  const url = apiConfig.POST.replace('[postId]', postId)
  return axios.get(url)
}

export function fetchPostComments(postId) {
  const url = apiConfig.POST_COMMENTS.replace('[postId]', postId)
  return axios.get(url)
}

export function insertPostComment(data) {
  const url = apiConfig.INSERT_POST_COMMENT
  return axios.post(url, data)
}

export function removePostComment(commentId) {
  const url = apiConfig.POST.replace('[postId]', commentId)
  return axios.delete(url)
}

export function getNewPostCount(channelId, sinceDateTime) {
  const url = apiConfig.NEW_POST_COUNT.replace('[channelId]', channelId).replace('[sinceDateTime]', sinceDateTime)
  return axios.get(url)
}

export function editPostComment(comment) {
  const url = apiConfig.INSERT_POST_COMMENT
  return axios.put(url, comment)
}

function getTranslationApiLocale() {
  const locale = getLocale()
  if (locale === 'en-US' || locale === 'en-GB' || locale === 'en-CA') {
    return 'en-US'
  } else {
    return locale
  }
}

export function translatePromise(text) {
  const url = apiConfig.TRANSLATE_POST
  const model = {
    sourceLanguage: 'auto',
    destinationLanguage: getTranslationApiLocale(),
    text,
  }
  return axios.post(url, model)
}

export function translatePostCard(title, body) {
  const promisesArray = []
  promisesArray.push(translatePromise(body))
  if (title) {
    promisesArray.push(translatePromise(title))
  }
  return Promise.all(promisesArray)
}

export function watchPostCard(postId, isWatching, isFromMyOrganization) {
  let url
  if (isWatching) {
    url = apiConfig.UNWATCH_POST.replace('[postId]', postId)
  }
  else {
    url = `${apiConfig.WATCH_POST.replace('[postId]', postId)}?isFromMyOrganization=${isFromMyOrganization}`
  }
  return axios.put(url)
}
