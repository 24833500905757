import axios from 'axios'
import apiConfig from '../../config/api-config'
import * as cancelTokenUtil from './utils/cancelTokenUtil'

const PLATFORM = "Web"
const PLATFORM_MOBILE = "Mobile"
const PLATFORM_DESKTOP = "Desktop"

export function postPlatformLog(personId, mobile) {
    const url = apiConfig.POST_PLATFORM_LOG
    const options = cancelTokenUtil.abortAndGetCancelTokenOptions('POST_PLATFORM_LOG')
    return axios.post(`${url}`, {"personId": personId, "platform": PLATFORM + ":" + (mobile ? PLATFORM_MOBILE : PLATFORM_DESKTOP)}, options)
}