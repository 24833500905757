import React, { Component } from 'react'
import { registerLocale } from 'react-datepicker'
import moment from 'moment'
import PropTypes from 'prop-types'

import { formatDateNotForDisplay, getEndOfWeek, getStartOfNextWeek, getStartOfPreviousWeek, getMonthAndDay } from '../utils/datetimeUtils'
import { isDefinedAndNotEmpty } from '../utils/nullSafeCheckUtils'

import enUsDateFns from 'date-fns/locale/en-US'
import enCaDateFns from 'date-fns/locale/en-CA'
import enGbDateFns from 'date-fns/locale/en-GB'
import fr from 'date-fns/locale/fr'
import es from 'date-fns/locale/es'

import './DatePicker.scss'

class Datepicker extends Component {
  constructor(props) {
    super(props)

    registerLocale('en-US', enUsDateFns)
    registerLocale('es-US', es)
    registerLocale('fr-CA', fr)
    registerLocale('en-CA', enCaDateFns)
    registerLocale('en-GB', enGbDateFns)
    registerLocale('fr-FR', fr)

    this.state = {
      startDate: '',
      nextWeek: '',
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleNextWeek = this.handleNextWeek.bind(this)
    this.handlePrevWeek = this.handlePrevWeek.bind(this)
    this.isNextWeekDisabled = this.isNextWeekDisabled.bind(this)
    this.isPrevWeekAvailable = this.isPrevWeekAvailable.bind(this)
  }

  static getDerivedStateFromProps(props) {
    return {
      startDate: props.scheduleStartDate ? formatDateNotForDisplay(props.scheduleStartDate) : props.scheduleStartDate,
      nextWeek: props.scheduleEndDate ? formatDateNotForDisplay(props.scheduleEndDate) : props.scheduleEndDate,
    }
  }

  handleChange(incomingDate) {
    let date
    if (!(incomingDate instanceof moment)) {
      date = moment(incomingDate)
    } else {
      date = incomingDate
    }
    date = formatDateNotForDisplay(date.subtract(date.day() > 5 ? 0 : date.day() + 1, 'day'))
    const nextWeek = getEndOfWeek(date)
    const datesObjectForApi = {
      currentDate: date,
      nextWeek: nextWeek,
    }

    this.props.returnDates(getMonthAndDay(date), datesObjectForApi)

    this.setState({
      startDate: date,
      nextWeek: nextWeek,
    })
  }

  handleNextWeek() {
    const { startDate } = this.state
    this.handleChange(getStartOfNextWeek(startDate))
  }

  handlePrevWeek() {
    const { startDate } = this.state
    this.handleChange(getStartOfPreviousWeek(startDate))
  }

  isPrevWeekAvailable() {
    const { startDate } = this.state
    // User can go 4 weeks back
    return !moment(startDate).isAfter(moment().subtract(4, 'week'))
  }

  isNextWeekDisabled() {
    if (!isDefinedAndNotEmpty(this.props.publishedWeek.endDate)) {
      return true
    }

    const { startDate } = this.state
    let publishedEndDate = formatDateNotForDisplay(this.props.publishedWeek.endDate) 
    let scheduleStartDate = formatDateNotForDisplay(startDate)
    let nextStartDate = getStartOfNextWeek(scheduleStartDate)
    let nextStartDateNotBeforePublishedEndDate = !moment(nextStartDate).isBefore(moment(publishedEndDate))

    return nextStartDateNotBeforePublishedEndDate
  }

  render() {
    const { startDate } = this.state
    
    if (startDate == null) {
      return '' // no date? no render!
    } else {
      return (
        <div className="sticky-top date-picker-component">
          <div className="row">
            <div className="col-lg schedule-text">{this.props.title}</div>
            
            <div className="col col-lg-1 date-picker-right">
              <button onClick={this.handlePrevWeek} disabled={this.isPrevWeekAvailable()}>
                <i className="material-icons navigate_before medium">navigate_before</i>
              </button>
              <button onClick={this.handleNextWeek} disabled={this.isNextWeekDisabled()}>
                <i className="material-icons navigate_next medium">navigate_next</i>
              </button>
            </div>
          </div>
        </div>
      )
    }
  }
}

Datepicker.propTypes = {
  returnDates: PropTypes.func,
  publishedWeek: PropTypes.object,
  scheduleStartDate: PropTypes.string,
  scheduleEndDate: PropTypes.string,
  title: PropTypes.string,
}

export default Datepicker
