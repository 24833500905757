import {
    POST_PLATFORM_LOG_SUCCESS,
    POST_PLATFORM_LOG_PENDING,
    POST_PLATFORM_LOG_ERROR,
} from '../actions/actionTypes'

export default function platformLogReducer(
    state = {
        postPlatformLogError: {},
        postPlatformLogPending: false,
        platformLogId: 0,
    },
    action
) {
    switch (action.type) {
        case POST_PLATFORM_LOG_SUCCESS:
            return {
                ...state,
                postPlatformLogError: {},
                platformLogId: action.payload,
            }
        case POST_PLATFORM_LOG_PENDING:
            return {
                ...state,
                postPlatformLogPending: action.payload,
            }
        case POST_PLATFORM_LOG_ERROR:
            return {
                ...state,
                postPlatformLogError: action.error,
            }
        default:
            return state
    }
}