import get from 'lodash/get'
import * as types from './actionTypes'
import services from '../services'

import { addToastMessage } from '../../state/actions/toastsActions'

import { ToastType } from '../../components/Alerts/Toast'

import { formatDateNotForDisplay } from '../../components/utils/datetimeUtils'
import { t } from '../../utils/i18n/index'

import moment from 'moment'

export function setScheduleDates(startDate, endDate) {
  startDate = formatDateNotForDisplay(startDate)
  endDate = formatDateNotForDisplay(endDate)

  return dispatch => {
    dispatch(
      setScheduleDatesAction({
        scheduleStartDate: startDate,
        scheduleEndDate: endDate,
      })
    )
  }
}

export function getSchedule(scheduleStartDate, scheduleEndDate) {
  return (dispatch, getState) => {
    dispatch(getSchedulePending(true))
    setTimeout(() => {
      const { monthScheduleItems, eventChatNotifications } = getState().scheduleReducer
      scheduleStartDate = formatDateNotForDisplay(scheduleStartDate)
      scheduleEndDate = formatDateNotForDisplay(scheduleEndDate)

      const scheduleItems = monthScheduleItems.filter((item) => moment(item.date).isBetween(scheduleStartDate, scheduleEndDate, null, '[]'))

      let response = []
      response = scheduleItems.map((item) => {
        const obj = eventChatNotifications.find((notif) => notif.eventId === parseInt(item.scheduledStoreId), 10)

        return {
          ...item,
          newPostCount: (obj && obj.newPostCount) || 0
        }
      })

      dispatch(getScheduleAction(response))
      dispatch(getSchedulePending(false))
    }, 1)
  }
}

export function resetDistrictEventDetails() {
  return dispatch => {
    dispatch(resetDistrictEventDetailsAction())
  }
}
const resetDistrictEventDetailsAction = () => {
  return {
    type: types.RESET_DISTRICT_EVENT_DETAILS,
  }
}

export function getDistrictEventDetails(districtEventId) {
  return dispatch => {
    dispatch(getDistrictEventDetailsPending(true))
    services.schedule
      .getDistrictEventDetails(districtEventId)
      .then(response => {
        dispatch(getDistrictEventDetailsSuccess(response))
        dispatch(getDistrictEventDetailsPending(false))
      })
      .catch(error => {
        dispatch(getDistrictEventDetailsError(error))
        dispatch(getDistrictEventDetailsPending(false))
      })
  }
}

const getDistrictEventDetailsSuccess = response => {
  return {
    type: types.GET_DISTRICT_EVENT_DETAILS_SUCCESS,
    payload: response.data,
  }
}
const getDistrictEventDetailsError = response => {
  return {
    type: types.GET_DISTRICT_EVENT_DETAILS_ERROR,
    payload: response,
  }
}
const getDistrictEventDetailsPending = status => {
  return {
    type: types.GET_DISTRICT_EVENT_DETAILS_PENDING,
    payload: status,
  }
}

export function getMonthSchedule(currentDate, nextWeek) {
  return dispatch => {
    dispatch(getMonthSchedulePending(true))
    services.schedule
      .fetchScheduleByStartDateAndEndDate({
        startDate: currentDate,
        endDate: nextWeek,
      })
      .then(response => {
        dispatch(getMonthScheduleAction(response))
        dispatch(getMonthSchedulePending(false))
      })
      .catch(error => {
        // Do nothing
        // TODO Handle the Error specifically for the Get Schedule flow
        dispatch(getMonthScheduleError(error))
        dispatch(getMonthSchedulePending(false))
      })
  }
}

export function confirmEvent(unconfirmedEventIds) {
  return dispatch => {
    dispatch(confirmEventPending(true))
    services.schedule
      .insertConfirmWeekByEventIds({
        eventIds: unconfirmedEventIds,
      })
      .then(response => {
        dispatch(confirmEventPending(false))
        dispatch(confirmEventAction(response, unconfirmedEventIds))
        dispatch(addToastMessage(ToastType.SUCCESS, t('event_confirmed')))
      })
      .catch(error => {
        dispatch(confirmEventError(error))
        dispatch(addToastMessage(ToastType.ERROR, t('error_occurred_retry')))
        // TODO Handle the Error specifically for flow
      })
  }
}

export function sendRequestEventChange(reasonCode, eventCode, notes, scheduledEventId, attendingTime) {
  const data = {
    attendingTime: attendingTime,
    scheduledEventId: scheduledEventId,
    typeCode: eventCode,
    reasonCode: reasonCode,
    notes: notes,
  }
  return dispatch => {
    dispatch(requestEventChangePending(true))
    services.schedule
      .insertScheduleChangeRequestByEventIdAndReason(data)
      .then(response => {
        const reqStatus = get(response, 'data.status') !== '' ? true : false
        dispatch(requestEventChange(reqStatus))
        dispatch(requestEventChangePending(false))
      })
      .catch((error) => {
        dispatch(requestEventChangeError(error))
        dispatch(requestEventChangePending(false))
        // TODO Handle error specific for flow
      })
  }
}

// Get Schedule
const setScheduleDatesAction = payload => {
  return {
    type: types.SET_SCHEDULE_DATES,
    payload: payload,
  }
}
const getScheduleAction = response => {
  return {
    type: types.GET_SCHEDULE,
    payload: response,
    status: response.status,
  }
}
// const getScheduleError = response => {
//   return {
//     type: types.GET_SCHEDULE_ERROR,
//     payload: response,
//   }
// }
const getSchedulePending = status => {
  return {
    type: types.GET_SCHEDULE_PENDING,
    payload: status,
  }
}

const getMonthScheduleAction = response => {
  return {
    type: types.GET_MONTH_SCHEDULE_SUCCESS,
    payload: response.data,
  }
}

const getMonthScheduleError = response => {
  return {
    type: types.GET_MONTH_SCHEDULE_ERROR,
    payload: response,
  }
}

const getMonthSchedulePending = status => {
  return {
    type: types.GET_MONTH_SCHEDULE_PENDING,
    status,
  }
}

// Confirm Week
const confirmEventAction = (response, unconfirmedEventIds) => {
  return {
    type: types.CONFIRM_EVENT,
    payload: {
      responseData: response.data,
      eventIds: unconfirmedEventIds,
    },
    status: response.status,
  }
}

const confirmEventPending = status => {
  return {
    type: types.CONFIRM_EVENT_PENDING,
    payload: status,
  }
}

const confirmEventError = response => {
  return {
    type: types.CONFIRM_EVENT_ERROR,
    payload: response,
  }
}

// Request Change
const requestEventChange = reqStatus => {
  return {
    type: types.REQUEST_EVENT_CHANGE_SUCCESS,
    payload: reqStatus,
  }
}
const requestEventChangePending = reqStatus => {
  return {
    type: types.REQUEST_EVENT_CHANGE_PENDING,
    payload: reqStatus,
  }
}
const requestEventChangeError = error => {
  return {
    type: types.REQUEST_EVENT_CHANGE_ERROR,
    payload: error,
  }
}

export function getEventChatNotificationsByStoreIds(storeIds) {
  return dispatch => {
    dispatch(getEventChatNotificationsPending(true))
    services.schedule
      .fetchChatItems(storeIds)
      .then(response => {
        dispatch(getEventChatNotificationsSuccess(response))
        dispatch(getEventChatNotificationsPending(false))
      })
      .catch(error => {
        dispatch(getEventChatNotificationsError(error))
        dispatch(getEventChatNotificationsPending(false))
      })
  }
}
const getEventChatNotificationsSuccess = (response) => {
  return {
    type: types.GET_CHAT_NOTIFICATIONS_SUCCESS,
    payload: response.data
  }
}
const getEventChatNotificationsError = response => {
  return {
    type: types.GET_CHAT_NOTIFICATIONS_ERROR,
    error: response,
  }
}
const getEventChatNotificationsPending = status => {
  return {
    type: types.GET_CHAT_NOTIFICATIONS_PENDING,
    status,
  }
}

export function setNewChatMessagesCount(newChatMessages) {
  return dispatch => {
    dispatch({
      type: types.SET_NEW_CHAT_MESSAGES_COUNT,
      newChatMessages,
    })
  }
}

export function updateNewChatMessagesCount(newChatMessages, scheduledStoreId) {
  return dispatch => {
    dispatch({
      type: types.UPDATE_NEW_CHAT_MESSAGES_COUNT,
      newChatMessages,
      scheduledStoreId,
    })
  }
}

export function getScheduleChangeTypes() {
  return dispatch => {
    dispatch(getScheduleChangeTypesPendingAction(true))
    services.schedule
      .fetchScheduleChangeTypes()
      .then(response => {
        dispatch(getScheduleChangeTypesSuccessAction(response))
        dispatch(getScheduleChangeTypesPendingAction(false))
      })
      .catch(error => {
        dispatch(getScheduleChangeTypesErrorAction(error))
        dispatch(getScheduleChangeTypesPendingAction(false))
      })
  }
}

const getScheduleChangeTypesSuccessAction = response => {
  return {
    type: types.GET_SCHEDULE_CHANGE_TYPES_SUCCESS,
    payload: response.data,
  }
}
const getScheduleChangeTypesErrorAction = error => {
  return {
    type: types.GET_SCHEDULE_CHANGE_TYPES_ERROR,
    payload: error,
  }
}
const getScheduleChangeTypesPendingAction = pending => {
  return {
    type: types.GET_SCHEDULE_CHANGE_TYPES_PENDING,
    payload: pending,
  }
}

export function getScheduleChangeReasons() {
  return dispatch => {
    dispatch(getScheduleChangeReasonsPendingAction(true))
    services.schedule
      .fetchScheduleChangeReasons()
      .then(response => {
        dispatch(getScheduleChangeReasonsSuccessAction(response))
        dispatch(getScheduleChangeReasonsPendingAction(false))
      })
      .catch(error => {
        dispatch(getScheduleChangeReasonsErrorAction(error))
        dispatch(getScheduleChangeReasonsPendingAction(false))
      })
  }
}

const getScheduleChangeReasonsSuccessAction = response => {
  return {
    type: types.GET_SCHEDULE_CHANGE_REASONS_SUCCESS,
    payload: response.data,
  }
}
const getScheduleChangeReasonsErrorAction = error => {
  return {
    type: types.GET_SCHEDULE_CHANGE_REASONS_ERROR,
    payload: error,
  }
}
const getScheduleChangeReasonsPendingAction = pending => {
  return {
    type: types.GET_SCHEDULE_CHANGE_REASONS_PENDING,
    payload: pending,
  }
}