import {
  GET_EVENT_DETAILS_SUCCESS,
  GET_EVENT_DETAILS_ERROR,
  GET_EVENT_DETAILS_PENDING,
  GET_EVENT_APH_SUCCESS,
  GET_EVENT_APH_ERROR,
  GET_EVENT_APH_PENDING,
  GET_EVENT_DETAILS_LIST_SUCCESS,
  GET_EVENT_DETAILS_LIST_ERROR,
  GET_EVENT_DETAILS_LIST_PENDING,
  GET_EVENT_MEET_SITE_SUCCESS,
  GET_EVENT_MEET_SITE_ERROR,
  GET_EVENT_MEET_SITE_PENDING,
} from '../actions/actionTypes'

export default function eventDetailsReducer(
  state = {
    eventDetails: {},
    eventDetailsError: {},
    eventDetailsPending: false,
    eventAph: null,
    eventAphError: '',
    eventAphPending: true,
    eventDetailsList: [],
    eventDetailsListError: {},
    eventDetailsListPending: false,
    eventMeetSite: {},
    eventMeetSiteError: {},
    eventMeetSitePending: false,
  },
  action
) {
  switch (action.type) {
    case GET_EVENT_DETAILS_SUCCESS:
      return {
        ...state,
        eventDetails: action.payload,
        eventDetailsError: {},
      }
    case GET_EVENT_DETAILS_ERROR:
      return {
        ...state,
        eventDetailsError: action.error,
      }
    case GET_EVENT_DETAILS_PENDING:
      return {
        ...state,
        eventDetailsPending: action.pending,
      }
    case GET_EVENT_APH_SUCCESS:
      return {
        ...state,
        eventAph: action.payload.aph,
        eventAphError: {},
      }
    case GET_EVENT_APH_ERROR:
      return {
        ...state,
        eventAph: null,
        eventAphError: action.error,
      }
    case GET_EVENT_APH_PENDING:
      return {
        ...state,
        eventAphPending: action.status,
      }
    case GET_EVENT_DETAILS_LIST_SUCCESS:
      return {
        ...state,
        eventDetailsList: action.payload,
        eventDetailsListError: {},
      }
    case GET_EVENT_DETAILS_LIST_ERROR:
      return {
        ...state,
        eventDetailsListError: action.error,
      }
    case GET_EVENT_DETAILS_LIST_PENDING:
      return {
        ...state,
        eventDetailsListPending: action.payload,
      }
    case GET_EVENT_MEET_SITE_SUCCESS:
      return {
        ...state,
        eventMeetSite: action.payload,
        eventMeetSiteError: {},
      }
    case GET_EVENT_MEET_SITE_ERROR:
      return {
        ...state,
        eventMeetSiteError: action.error,
      }
    case GET_EVENT_MEET_SITE_PENDING:
      return {
        ...state,
        eventMeetSitePending: action.status,
      }
    default:
      return state
  }
}
