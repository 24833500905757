import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { t } from '../../utils/i18n'
import { setEditingGeneralAvailability, saveGeneralAvailability, patchGeneralAvailability } from '../../state/actions/generalAvailabilityActions'
import { hasAllowAvailabilityChangesAccess } from '../../components/utils/featureAccessUtils'
import { getPreferredLanguage } from '../../components/utils/localStorageUtils'
import { 
  formatTime, 
  formatTimeNotForDisplayIncludeFractionalSeconds, 
  formatTimeNotForDisplay, 
  timeAIsAfterTimeB, 
  timeAIsSameAsTimeB, 
  timeAIsBetweenTimeBAndTimeC 
} from '../../components/utils/datetimeUtils'

import './GeneralAvailability.scss'
import { isDefined } from '../utils/nullSafeCheckUtils'

const NOT_AVAILABLE_AVAILABILITY_TYPE_ID = '0'
const ANY_AVAILABILITY_TYPE_ID = '1'
const CUSTOM_AVAILABILITY_TYPE_ID = '2'

const SUNDAY_ID = 1
const MONDAY_ID = 2
const TUESDAY_ID = 3
const WEDNESDAY_ID = 4
const THURSDAY_ID = 5
const FRIDAY_ID = 6
const SATURDAY_ID = 7

class GeneralAvailability extends Component {

  getReadOnlyGeneralAvailabilityMarkUp = () => {
    return this.props.generalAvailability.map(this.getReadOnlyGeneralAvailabilityDayMarkUp)
  }

  getReadOnlyGeneralAvailabilityDayMarkUp = (item, index) => {
    return (
      <div className="row general-calendar-item" key={index}>
        <div className="col">{this.getDayOfWeek(item.dayOfTheWeek)}</div>
        {!this.isTimeRangeNotAvailable(item.availableTimes) ? (
          item.availableTimes.map((hour, index) => {
            let startTime = formatTime(hour.startTime)
            let endTime = formatTime(hour.endTime)

            if (this.isTimeRangeAny(startTime, endTime)) {
              startTime = t('all_day')
              endTime = ''
            } else {
              startTime += ' - '
            }
            return (
              <div className="general-calendar-status text-right ml-auto ml-sm-0" key={index}>
                <b className="general-avail-time-separator d-none d-sm-inline-block">{index > 0 ? '|' : '' }</b>{startTime + endTime}
              </div>
            )
          })
        ) : (
          <div className="general-calendar-status text-right">{t('not_available')}</div>
        )}
        {index !== 6 && <div className="col-12"><hr /></div>} 
      </div>
    )
  }

  getDayOfWeek = (day) => {
    if (day === SUNDAY_ID) {
      return t('sunday')
    } else if (day === MONDAY_ID) {
      return t('monday')
    } else if (day === TUESDAY_ID) {
      return t('tuesday')
    } else if (day === WEDNESDAY_ID) {
      return t('wednesday')
    } else if (day === THURSDAY_ID) {
      return t('thursday')
    } else if (day === FRIDAY_ID) {
      return t('friday')
    } else if (day === SATURDAY_ID) {
      return t('saturday')
    } 
  }

  isTimeRangeAny = (startTime, endTime) => {
    return ( (startTime === '00:00' && endTime === '23:59') || (startTime === '12:00 AM' && endTime === '11:59 PM') )
  }

  isTimeRangeNotAvailable = (availableTimes) => {
    return availableTimes.length === 0
  }

  handleSaveClick = (event) => {
    let newAvailability = this.addSecondsToEndTime(this.props.generalAvailability.slice(0))
    let valid = this.isFormDataValid(newAvailability)

    if (valid) {
      this.props.saveGeneralAvailability(newAvailability)
      this.props.patchGeneralAvailability(newAvailability)
      this.props.setEditingGeneralAvailability(false)
      this.showSaveAvailabilityMessage()
    } else {
      event.preventDefault()
    }
  }

  isFormDataValid = (newAvailability) => {
    for (let i = 0; i < newAvailability.length; i++) {
      for (let j = 0; j < newAvailability[i].availableTimes.length; j++) {
        // check if start time is greater than end time
        if (timeAIsAfterTimeB(newAvailability[i].availableTimes[j].startTime, newAvailability[i].availableTimes[j].endTime)) { 
          alert (t('error_start_time_greater_then_end_time') + this.getDayOfWeek(newAvailability[i].dayOfTheWeek))
          return false
        }

        if (j > 0) { // check for overlap
          if (timeAIsBetweenTimeBAndTimeC(newAvailability[i].availableTimes[j].startTime, 
                                          newAvailability[i].availableTimes[0].startTime, 
                                          newAvailability[i].availableTimes[0].endTime)
              || timeAIsBetweenTimeBAndTimeC(newAvailability[i].availableTimes[j].endTime, 
                                            newAvailability[i].availableTimes[0].startTime, 
                                            newAvailability[i].availableTimes[0].endTime)
              || timeAIsSameAsTimeB(newAvailability[i].availableTimes[j].startTime, newAvailability[i].availableTimes[0].startTime)
              || timeAIsSameAsTimeB(newAvailability[i].availableTimes[j].startTime, newAvailability[i].availableTimes[0].endTime)
              || timeAIsSameAsTimeB(newAvailability[i].availableTimes[j].endTime, newAvailability[i].availableTimes[0].startTime)
              || timeAIsSameAsTimeB(newAvailability[i].availableTimes[j].endTime, newAvailability[i].availableTimes[0].endTime)) {
            alert (t('error_overlapping_time_ranges') + this.getDayOfWeek(newAvailability[i].dayOfTheWeek))
            return false
          }
        }
      }
    }

    return true
  }

  showSaveAvailabilityMessage = () => {
    alert(t('save_general_availability_message'))
  }

  addSecondsToEndTime = (newAvailability) => {
    for (let i = 0; i < newAvailability.length; i++) {
      for (let j = 0; j < newAvailability[i].availableTimes.length; j++) {
        let endTime = newAvailability[i].availableTimes[j].endTime
        let splitEndTime = endTime.split(':')

        newAvailability[i].availableTimes[j].endTime = `${splitEndTime[0]}:${splitEndTime[1]}:59`
      }
    }

    return newAvailability
  }

  getEditableGeneralAvailabilityMarkUp = () => {
    return (      
      <div>
        <form onSubmit={this.handleSaveClick}>
          {this.props.generalAvailability.map(this.getEditableGeneralAvailabilityDayMarkUp)}
          <div className="row">
            <div className="col"></div>
            <div className="col"><input className="btn btn-rgis-blue semiBoldText uppercase cursor-pointer" type="submit" value={t('save')}></input></div>
          </div>
        </form>
      </div>
    )
  }

  handleDeletePeriodOnClick = (item, timeRangeIndex) =>  {
    let newAvailableTimes = []
    let copyAvailableTimes = item.availableTimes.slice(0)

    for (let i = 0; i < copyAvailableTimes.length; i++) {
      if (i !== timeRangeIndex) {
        newAvailableTimes.push(copyAvailableTimes[i])
      }      
    }

    this.saveDayOfGeneralAvailability(
    {
      "dayOfTheWeek": item.dayOfTheWeek,
      "pending": item.pending,
      "availableTimes": newAvailableTimes
    })
  }

  handleAddPeriodOnClick = (item) =>  {
    this.saveDayOfGeneralAvailability(
    {
      "dayOfTheWeek": item.dayOfTheWeek,
      "pending": item.pending,
      "availableTimes": item.availableTimes.concat(this.getGeneralAvailabilityTypeAvailableTimes(CUSTOM_AVAILABILITY_TYPE_ID))
    })
  }

  handleDayGeneralAvailabilityTypeOnChange = (item, event) => {
    this.saveDayOfGeneralAvailability(this.generateDailyGeneralAvailability(event.target.value, item.dayOfTheWeek))
  }

  saveDayOfGeneralAvailability = (dayOfGeneralAvailability) => {
    let newAvailability = []
    
    for(let i = 0; i < this.props.generalAvailability.length; i++) {
      if((dayOfGeneralAvailability.dayOfTheWeek - 1) === i) {
        newAvailability.push(dayOfGeneralAvailability)
      } else {
        newAvailability.push(this.props.generalAvailability[i])
      }
    }

    this.saveGeneralAvailability(newAvailability)
  }

  getAddPeriodButtonMarkUp = (item) => {
    if (this.getDayGeneralAvailabilityType(item) === CUSTOM_AVAILABILITY_TYPE_ID && item.availableTimes.length < 2) {
      return (
        <div className="row general-calendar-item">
          <div className="col">
            <i onClick={() => this.handleAddPeriodOnClick(item)} className="material-icons add_box cursor-pointer other-avail-icon">add_box</i>
          </div>
        </div>
      )
    } else {
      return ''
    }    
  }

  getDeletePeriodButtonMarkUp = (item, timeRangeIndex) => {
    if (this.getDayGeneralAvailabilityType(item) === CUSTOM_AVAILABILITY_TYPE_ID) {
      return (
        <i onClick={() => this.handleDeletePeriodOnClick(item, timeRangeIndex)} className="material-icons delete cursor-pointer other-avail-icon">delete</i>
      )
    } else {
      return ''
    }    
  }

  getDayGeneralAvailabilityType = (item) => {
    if (this.isTimeRangeNotAvailable(item.availableTimes)) {
      return NOT_AVAILABLE_AVAILABILITY_TYPE_ID
    } else if (item.availableTimes.length === 1) {
      let startTime = formatTime(item.availableTimes[0].startTime)
      let endTime = formatTime(item.availableTimes[0].endTime)

      if (this.isTimeRangeAny(startTime, endTime)) {
        return ANY_AVAILABILITY_TYPE_ID
      } else {
        return CUSTOM_AVAILABILITY_TYPE_ID
      }
    } else if (item.availableTimes.length === 2) {
      return CUSTOM_AVAILABILITY_TYPE_ID
    }
  }

  handleStartTimeOnChange = (item, event, timeRangeIndex) => {
    let newAvailableTimes = item.availableTimes.slice(0)
    newAvailableTimes[timeRangeIndex].startTime = this.getValidatedEditedStartTime(event.target.value)

    this.saveDayOfGeneralAvailability(
      {
        "dayOfTheWeek": item.dayOfTheWeek,
        "pending": item.pending,
        "availableTimes": newAvailableTimes
      })
  }

  getValidatedEditedStartTime = (startTime) => {
    let newTime = startTime

    if(!newTime || newTime === '') {
      newTime = "00:00"
    }

    let splitTimeColon = newTime.split(':')
    return splitTimeColon[0] + ':' + this.getStartMinutes(splitTimeColon[1])
  }

  getStartMinutes = (startMinutes) => {
    let newStartMinutes  = startMinutes;

    if (parseInt(startMinutes) === 1) {
      newStartMinutes = '30'
    } else if (parseInt(startMinutes) === 31) {
      newStartMinutes = '00'
    } else if (parseInt(startMinutes) === 29) {
      newStartMinutes = '00'
    } else if (parseInt(startMinutes) === 59) {
      newStartMinutes = '30'
    } else if (parseInt(startMinutes) > 31 && parseInt(startMinutes) <= 59) {
      newStartMinutes = '30'
    } else if (parseInt(startMinutes) > 1 && parseInt(startMinutes) <= 28) {
      newStartMinutes = '00'
    }

    return newStartMinutes
  }
      
  handleEndTimeOnChange = (item, event, timeRangeIndex) => {
    let newAvailableTimes = item.availableTimes.slice(0)
    newAvailableTimes[timeRangeIndex].endTime = this.getValidatedEditedEndTime(event.target.value)

    this.saveDayOfGeneralAvailability(
      {
        "dayOfTheWeek": item.dayOfTheWeek,
        "pending": item.pending,
        "availableTimes": newAvailableTimes
      })
  }

  getValidatedEditedEndTime = (endTime) => {
    let newTime = endTime

    if(!newTime || newTime === '') {
      newTime = "00:29"
    }

    let splitTimeColon = newTime.split(':')
    return splitTimeColon[0] + ':' + this.getEndMinutes(splitTimeColon[1]) + ":59"
  }

  getEndMinutes = (endMinutes) => {
    let newEndMinutes  = endMinutes;

    if (parseInt(endMinutes) === 28) {
      newEndMinutes = '59'
    } else if (parseInt(endMinutes) === 30) {
      newEndMinutes = '59'
    } else if (parseInt(endMinutes) === 0) {
      newEndMinutes = '29'
    } else if (parseInt(endMinutes) === 58) {
      newEndMinutes = '29'
    } else if (parseInt(endMinutes) > 29 && parseInt(endMinutes) < 59) {
      newEndMinutes = '59'
    } else if (parseInt(endMinutes) >= 0 && parseInt(endMinutes) <= 28) {
      newEndMinutes = '29'
    }

    return newEndMinutes
  }

  getEditableTimeRangeMarkUp = (timeRange, item, timeRangeIndex) => {
    let startTime = formatTimeNotForDisplay(formatTimeNotForDisplayIncludeFractionalSeconds(timeRange.startTime))
    let endTime = formatTimeNotForDisplay(formatTimeNotForDisplayIncludeFractionalSeconds(timeRange.endTime))
    let key = item.dayOfTheWeek + "_" + timeRangeIndex

    return (
      <div className="d-flex align-items-center mt-2 mb-2" key={key}>
        <select required value={startTime} onChange={(event) => this.handleStartTimeOnChange(item, event, timeRangeIndex)}>
          <option value='00:00'>{formatTime('12:00 AM')}</option>
          <option value='00:30'>{formatTime('12:30 AM')}</option>
          <option value='01:00'>{formatTime('01:00 AM')}</option>
          <option value='01:30'>{formatTime('01:30 AM')}</option>
          <option value='02:00'>{formatTime('02:00 AM')}</option>
          <option value='02:30'>{formatTime('02:30 AM')}</option>
          <option value='03:00'>{formatTime('03:00 AM')}</option>
          <option value='03:30'>{formatTime('03:30 AM')}</option>
          <option value='04:00'>{formatTime('04:00 AM')}</option>
          <option value='04:30'>{formatTime('04:30 AM')}</option>
          <option value='05:00'>{formatTime('05:00 AM')}</option>
          <option value='05:30'>{formatTime('05:30 AM')}</option>
          <option value='06:00'>{formatTime('06:00 AM')}</option>
          <option value='06:30'>{formatTime('06:30 AM')}</option>
          <option value='07:00'>{formatTime('07:00 AM')}</option>
          <option value='07:30'>{formatTime('07:30 AM')}</option>
          <option value='08:00'>{formatTime('08:00 AM')}</option>
          <option value='08:30'>{formatTime('08:30 AM')}</option>
          <option value='09:00'>{formatTime('09:00 AM')}</option>
          <option value='09:30'>{formatTime('09:30 AM')}</option>
          <option value='10:00'>{formatTime('10:00 AM')}</option>
          <option value='10:30'>{formatTime('10:30 AM')}</option>
          <option value='11:00'>{formatTime('11:00 AM')}</option>
          <option value='11:30'>{formatTime('11:30 AM')}</option>
          <option value='12:00'>{formatTime('12:00 PM')}</option>
          <option value='12:30'>{formatTime('12:30 PM')}</option>
          <option value='13:00'>{formatTime('01:00 PM')}</option>
          <option value='13:30'>{formatTime('01:30 PM')}</option>
          <option value='14:00'>{formatTime('02:00 PM')}</option>
          <option value='14:30'>{formatTime('02:30 PM')}</option>
          <option value='15:00'>{formatTime('03:00 PM')}</option>
          <option value='15:30'>{formatTime('03:30 PM')}</option>
          <option value='16:00'>{formatTime('04:00 PM')}</option>
          <option value='16:30'>{formatTime('04:30 PM')}</option>
          <option value='17:00'>{formatTime('05:00 PM')}</option>
          <option value='17:30'>{formatTime('05:30 PM')}</option>
          <option value='18:00'>{formatTime('06:00 PM')}</option>
          <option value='18:30'>{formatTime('06:30 PM')}</option>
          <option value='19:00'>{formatTime('07:00 PM')}</option>
          <option value='19:30'>{formatTime('07:30 PM')}</option>
          <option value='20:00'>{formatTime('08:00 PM')}</option>
          <option value='20:30'>{formatTime('08:30 PM')}</option>
          <option value='21:00'>{formatTime('09:00 PM')}</option>
          <option value='21:30'>{formatTime('09:30 PM')}</option>
          <option value='22:00'>{formatTime('10:00 PM')}</option>
          <option value='22:30'>{formatTime('10:30 PM')}</option>
          <option value='23:00'>{formatTime('11:00 PM')}</option>
          <option value='23:30'>{formatTime('11:30 PM')}</option>
        </select> - 
        <select required value={endTime} onChange={(event) => this.handleEndTimeOnChange(item, event, timeRangeIndex)} >
          <option value='00:29'>{formatTime('12:29 AM')}</option>
          <option value='00:59'>{formatTime('12:59 AM')}</option>
          <option value='01:29'>{formatTime('01:29 AM')}</option>
          <option value='01:59'>{formatTime('01:59 AM')}</option>
          <option value='02:29'>{formatTime('02:29 AM')}</option>
          <option value='02:59'>{formatTime('02:59 AM')}</option>
          <option value='03:29'>{formatTime('03:29 AM')}</option>
          <option value='03:59'>{formatTime('03:59 AM')}</option>
          <option value='04:29'>{formatTime('04:29 AM')}</option>
          <option value='04:59'>{formatTime('04:59 AM')}</option>
          <option value='05:29'>{formatTime('05:29 AM')}</option>
          <option value='05:59'>{formatTime('05:59 AM')}</option>
          <option value='06:29'>{formatTime('06:29 AM')}</option>
          <option value='06:59'>{formatTime('06:59 AM')}</option>
          <option value='07:29'>{formatTime('07:29 AM')}</option>
          <option value='07:59'>{formatTime('07:59 AM')}</option>
          <option value='08:29'>{formatTime('08:29 AM')}</option>
          <option value='08:59'>{formatTime('08:59 AM')}</option>
          <option value='09:29'>{formatTime('09:29 AM')}</option>
          <option value='09:59'>{formatTime('09:59 AM')}</option>
          <option value='10:29'>{formatTime('10:29 AM')}</option>
          <option value='10:59'>{formatTime('10:59 AM')}</option>
          <option value='11:29'>{formatTime('11:29 AM')}</option>
          <option value='11:59'>{formatTime('11:59 AM')}</option>
          <option value='12:29'>{formatTime('12:29 PM')}</option>
          <option value='12:59'>{formatTime('12:59 PM')}</option>
          <option value='13:29'>{formatTime('01:29 PM')}</option>
          <option value='13:59'>{formatTime('01:59 PM')}</option>
          <option value='14:29'>{formatTime('02:29 PM')}</option>
          <option value='14:59'>{formatTime('02:59 PM')}</option>
          <option value='15:29'>{formatTime('03:29 PM')}</option>
          <option value='15:59'>{formatTime('03:59 PM')}</option>
          <option value='16:29'>{formatTime('04:29 PM')}</option>
          <option value='16:59'>{formatTime('04:59 PM')}</option>
          <option value='17:29'>{formatTime('05:29 PM')}</option>
          <option value='17:59'>{formatTime('05:59 PM')}</option>
          <option value='18:29'>{formatTime('06:29 PM')}</option>
          <option value='18:59'>{formatTime('06:59 PM')}</option>
          <option value='19:29'>{formatTime('07:29 PM')}</option>
          <option value='19:59'>{formatTime('07:59 PM')}</option>
          <option value='20:29'>{formatTime('08:29 PM')}</option>
          <option value='20:59'>{formatTime('08:59 PM')}</option>
          <option value='21:29'>{formatTime('09:29 PM')}</option>
          <option value='21:59'>{formatTime('09:59 PM')}</option>
          <option value='22:29'>{formatTime('10:29 PM')}</option>
          <option value='22:59'>{formatTime('10:59 PM')}</option>
          <option value='23:29'>{formatTime('11:29 PM')}</option>
          <option value='23:59'>{formatTime('11:59 PM')}</option>
        </select>
        {this.getDeletePeriodButtonMarkUp(item, timeRangeIndex)}
      </div>
    )
  }

  getGeneralAvailabilityTypeAvailableTimes = (generalAvailabilityTypeId) => {
    if (generalAvailabilityTypeId === NOT_AVAILABLE_AVAILABILITY_TYPE_ID) {
      return []
    } else if (generalAvailabilityTypeId === ANY_AVAILABILITY_TYPE_ID) {
      return [
          {
            "startTime": "00:00:00",
            "endTime": "23:59:59"
          }
        ]
    } else if (generalAvailabilityTypeId === CUSTOM_AVAILABILITY_TYPE_ID) {
      return [
          {
            "startTime": "00:00:00",
            "endTime": "00:29:59"
          }
        ]
    }
  }

  generateDailyGeneralAvailability = (generalAvailabilityTypeId, dayOfTheWeek) => {
    return {
      "dayOfTheWeek": dayOfTheWeek,
      "pending": false,
      "availableTimes": this.getGeneralAvailabilityTypeAvailableTimes(generalAvailabilityTypeId)
    }
  }

  saveGeneralAvailability = (generalAvailability) => {
    this.props.saveGeneralAvailability(generalAvailability)
  }

  getEditableGeneralAvailabilityDayMarkUp = (item, index) => {
    let dayKey = item.dayOfTheWeek + "_" + index

    return (
      <div key={dayKey}>
        <div className="row general-calendar-item">
          <div className="col-12 mb-4">
            {this.getDayOfWeek(item.dayOfTheWeek)}
          </div>
          <div className="col-12">
            <select value={this.getDayGeneralAvailabilityType(item)} onChange={(event) => this.handleDayGeneralAvailabilityTypeOnChange(item, event)}>
              <option value={NOT_AVAILABLE_AVAILABILITY_TYPE_ID}>{t('not_available')}</option>
              <option value={ANY_AVAILABILITY_TYPE_ID}>{t('any')}</option>
              <option value={CUSTOM_AVAILABILITY_TYPE_ID}>{t('custom')}</option>
            </select>
          </div>
        </div>
        <div className="row general-calendar-item">
          <div className="col-12">
            {item.availableTimes.map((timeRange, index) => {
              return this.getEditableTimeRangeMarkUp(timeRange, item, index)
            })}
          </div>          
        </div>
        {this.getAddPeriodButtonMarkUp(item)}
        {index !== 6 && <div className="col-12"><hr /></div>} 
      </div>
    )
  }

  editingGeneralAvailability = () => {
    return this.props.editingGeneralAvailability
  }

  handleEditOnClick = () => {
    this.props.setEditingGeneralAvailability(true)
  }

  handleCancelOnClick = () => {
    let newGeneralAvailability =  this.props.backupGeneralAvailability.slice(0)
    this.props.saveGeneralAvailability(newGeneralAvailability)

    this.props.setEditingGeneralAvailability(false)
  }

  handleContactsOnClick = () => {
    this.props.history.push('/contacts')
  }

  getEditGeneralAvailabilityLinkMarkUp = () => {
    if (!isDefined(this.props.featureAccess.allowAvailabilityChanges) || hasAllowAvailabilityChangesAccess(this.props.featureAccess)){
      return (
        <a className="cursor-pointer availability-edit-link" onClick={this.handleEditOnClick}>{t('edit')}</a> /* eslint-disable-line */
      )
    }
    
    return (
      <span style={{textTransform: "none"}}>{t('profile_general_availability_contact_manager')} <a className="cursor-pointer uppercase availability-edit-link" onClick={this.handleContactsOnClick}>{t('profile_general_availability_contact_manager_link')}</a></span> /* eslint-disable-line */
    )
  }

  getCancelGeneralAvailabilityLinkMarkUp = () => {
    return (
      <a className="cursor-pointer availability-edit-link" onClick={this.handleCancelOnClick}>{t('cancel')}</a> /* eslint-disable-line */
    )
  }

  render() {
    const { generalAvailability, generalAvailabilityPending } = this.props
    const language = getPreferredLanguage()

    if (generalAvailabilityPending) {
      return (
        <div className="general-availability-component">
          <div className="general-availability row">
            <h1>Loading...</h1>
          </div>
        </div>
      )
    }

    return (
      <div className="general-availability-component">        
        <div className="general-availability row">
          <div className={`col general-title uppercase ${language.toUpperCase() === 'FR' ? '' : 'capitalize'}`}>
            {t('general_availability')} | {this.editingGeneralAvailability() ? this.getCancelGeneralAvailabilityLinkMarkUp() : this.getEditGeneralAvailabilityLinkMarkUp()}
          </div>
        </div>
        <div className="general-calendar">
          {generalAvailability !== '' ? (this.editingGeneralAvailability() ? this.getEditableGeneralAvailabilityMarkUp() : this.getReadOnlyGeneralAvailabilityMarkUp()) : '' }
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    backupGeneralAvailability: state.generalAvailabilityReducer.backupGeneralAvailability,
    generalAvailability: state.generalAvailabilityReducer.generalAvailability,
    generalAvailabilityError: state.generalAvailabilityReducer.generalAvailabilityError,
    generalAvailabilityPending: state.generalAvailabilityReducer.generalAvailabilityPending,
    editingGeneralAvailability: state.generalAvailabilityReducer.editingGeneralAvailability,
    patchGeneralAvailability: state.generalAvailabilityReducer.patchGeneralAvailability,
    patchGeneralAvailabilityPending: state.generalAvailabilityReducer.patchGeneralAvailabilityPending,
    profileLocale: state.loginReducer.profileLocale,
    featureAccess: state.featureAccessReducer.featureAccess
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setEditingGeneralAvailability: (editing) => setEditingGeneralAvailability(editing),      
      saveGeneralAvailability: (generalAvailability) => saveGeneralAvailability(generalAvailability),
      patchGeneralAvailability: (generalAvailability) => patchGeneralAvailability(generalAvailability),
    }, dispatch)
}

GeneralAvailability.propTypes = {
  backupGeneralAvailability: PropTypes.array,
  generalAvailability: PropTypes.array,
  generalAvailabilityPending: PropTypes.bool,
  setEditingGeneralAvailability: PropTypes.func,
  editingGeneralAvailability: PropTypes.bool,
  saveGeneralAvailability: PropTypes.func,
  patchGeneralAvailability: PropTypes.func,
  patchGeneralAvailabilityPending: PropTypes.bool,
  generalAvailabilityError: PropTypes.object,
  profileLocale: PropTypes.string,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralAvailability)
