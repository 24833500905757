import React from 'react'
import PropTypes from 'prop-types'
import './WatchPostDialogForm.scss'
import { t } from '../../utils/i18n';
import { loggedInUserRoleIsFsc } from '../utils/localStorageUtils'

class WatchPostDialogForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedOption: '',
      error: false
    }

    this.submitDialogForm = this.submitDialogForm.bind(this)
    this.cancelDialogForm = this.cancelDialogForm.bind(this)
    this.handleOptionChange = this.handleOptionChange.bind(this)
  }

  componentWillUnmount() {
    this.setState({
      selectedOption: 'watchForEveryone',
      error: false
    })
  }

  handleOptionChange(option) {
    this.setState({
      selectedOption: option,
      error: false,
    })
  }

  submitDialogForm() {
    const { selectedOption } = this.state
    if (selectedOption.length) {
      const isFromMyOrganization = selectedOption === 'watchOnlyForMyOrganization'
      this.props.modalAcceptAction(isFromMyOrganization)
      this.setState({
        selectedOption: '',
        error: false
      })
    }
    else {
      this.setState({ error: true })
    }
  }

  cancelDialogForm(evt) {
    this.setState({
      selectedOption: '',
      error: false
    })
    this.props.modalCancelAction(evt)
  }

  renderWatchForMyOrganizationOption = (selectedOption) => {
    if (loggedInUserRoleIsFsc()) {
      return ''
    } 
      
    return(
      <label htmlFor="option-2" className="d-flex align-items-center text-left">
        <input
          className="mr-2"
          id="option-2"
          type="radio"
          value="option-2"
          checked={selectedOption === 'watchOnlyForMyOrganization'}
          onClick={() => this.handleOptionChange('watchOnlyForMyOrganization')}
        />
          {t('organization_updates')}
      </label>
    )
  }

  render() {
    const { selectedOption, error } = this.state

    const { showModal, modalTitle, modalText, } = this.props
    return (
      <div
        className={`action-modal modal fade ${showModal ? 'show' : ''}`}
        onClick={this.props.modalCancelAction}
        id="actionModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="actionModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content" onClick={(evt) => evt.stopPropagation()}>
            <h3 className="modal-title">
              {modalTitle}
            </h3>
            <div className="modal-body">
              {modalText}
            </div>
           
            <div className="modal-body d-flex flex-column">
              <div className="text-left mb-2">Get:</div>
              <label htmlFor="option-1" className="d-flex align-items-center text-left">
                <input
                  className="mr-2"
                  id="option-1"
                  type="radio"
                  value="option-1"
                  checked={selectedOption === 'watchForEveryone'}
                  onClick={() => this.handleOptionChange('watchForEveryone')}
                />
                  {t('all_updates')}
                </label>
              { this.renderWatchForMyOrganizationOption(selectedOption) }              
            </div>
            {error && <span className="text-red text-center mb-3">{t('please_select_option')}</span>}
            <div className="row justify-content-center">
              <div className="col-md-4 col-6">
                <input
                  type='button'
                  onClick={this.cancelDialogForm}
                  className="btn btn-secondary w-100"
                  value={t('cancel')}
                />
              </div>
              <div className="col-md-4 col-6">
                <input
                  type='button'
                  onClick={this.submitDialogForm}
                  className="btn btn-rgis-blue w-100"
                  value={t('submit')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


WatchPostDialogForm.propTypes = {
  showModal: PropTypes.bool,
  modalTitle: PropTypes.string,
  modalText: PropTypes.string,
  modalAcceptAction: PropTypes.func,
  modalCancelAction: PropTypes.func,
}

export default WatchPostDialogForm
