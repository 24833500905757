import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './BadgeItem.scss'
import { t } from '../../../utils/i18n';
import { getPreferredLanguage } from '../../../components/utils/localStorageUtils'
import { formatDate } from '../../../components/utils/datetimeUtils'
import { formatNumber } from '../../../components/utils/numberUtils'

class BadgeItem extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  getTranslatedText(details) {
    return details.map((detail, index) => {
      if (detail.language.includes(getPreferredLanguage().toLowerCase())) { 
        return (
          <div key={index} className="">
            <h5>{detail.name}</h5>
            <span className="">{detail.description}</span>
          </div>
        )
      }
      return ''
    })
  }

  getBadgeImageType = (userEarned) => {
    const earnedImageType = 'Earned-PNG'
    const unearnedImageType = 'NotEarned-PNG'

    return userEarned ? earnedImageType : unearnedImageType
  }

  getBadgeImageUrl = (userEarned, badgeImages) => {
    let imageType =this.getBadgeImageType(userEarned)

    for (let i = 0; i < badgeImages.length; i++) {
      if (badgeImages[i].imageType === imageType) {
        return badgeImages[i].url
      } 
    }

    return ''
  }

  render() {
    const { badgeModel, showDetails, isProgressive, additionalData } = this.props;

    if (showDetails === false) {
      return (
        <div className="badge-item mr-md-4">
          <div className="align-self-center">
            <img
              className="badge-image-small"
              src={badgeModel.images.length ? this.getBadgeImageUrl(true, badgeModel.images) : null}
              alt={badgeModel.images.length ? this.getBadgeImageType(true) : ''}
            />
          </div>
        </div>
      )
    }
    return (
      <div className="card">
        <div className="card-body">
          <div className="section">
            <div className="badge-item d-flex">
              <div className="align-self-center">
                <img
                  className={`badge-image`}
                  src={badgeModel.images.length ? this.getBadgeImageUrl(badgeModel.userEarned, badgeModel.images) : null}
                  alt={badgeModel.images.length ? this.getBadgeImageType(badgeModel.userEarned) : 'No image available'}
                />
              </div>
              <div className="ml-3 mt-2">
                {this.getTranslatedText(badgeModel.details)}
                {isProgressive ?
                  <div>
                    {formatNumber(additionalData.earnedBadgesCount)}/{formatNumber(additionalData.maxBadgesCount)}
                  </div>
                  : ''
                }
              </div>
            </div>


            <div className="d-flex justify-content-end align-self-end text-uppercase earned-text">
              {badgeModel.userEarned ? formatDate(badgeModel.earnedUnearnedDateTime) : t('not_earned')}
            </div>
          </div>

          {isProgressive && additionalData.badgeModel ?
            <div>
              <hr className="mb-2" />
              <div className="mb-3 next-badges">{t('next_badge_in_series')}</div>
              <div className="badge-item d-flex align-items-center justify-content-between">
                {this.getTranslatedText(additionalData.badgeModel.details)}
                <img
                  className="additional-badge-image"
                  src={additionalData.badgeModel.images.length ? additionalData.badgeModel.images[0].url : null}
                  alt={additionalData.badgeModel.images.length ? additionalData.badgeModel.images[0].imageType : 'No image available'}
                />
              </div>
            </div>
            :
            ''
          }
        </div>
      </div>
    )
  }
}

BadgeItem.propTypes = {
  badgeModel: PropTypes.object,
  showDetails: PropTypes.bool,
  isProgressive: PropTypes.bool,
  additionalData: PropTypes.object
}

export default BadgeItem

