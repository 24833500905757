
import axios from 'axios'
import apiConfig from '../../config/api-config'

export function postNewPost(newPostObj) {
  const url = apiConfig.INSERT_POST_COMMENT
  return axios.post(`${url}`, newPostObj)
}

export function editPost(postObj) {
  const url = apiConfig.INSERT_POST_COMMENT
  return axios.put(`${url}`, postObj)
}