import axios from 'axios'
import apiConfig from '../../config/api-config'
import { toQueryString } from './utils/requestUtil'
import * as cancelTokenUtil from './utils/cancelTokenUtil'

export function fetchTimePunchesByStartDateAndEndDate({ startDate = ``, endDate = `` }) {
  const url = apiConfig.TIME_PUNCHES_SUMMARY
  const queryParams = toQueryString({
    startDate: startDate,
    endDate: endDate,
  })
  const options = cancelTokenUtil.abortAndGetCancelTokenOptions('TIME_PUNCHES_SUMMARY')
  return axios.get(`${url}?${queryParams}`, options)
}

export function fetchTimePunchDetailsById(timePunchId) {
  const url = apiConfig.TIME_PUNCH_DETAILS.replace('[timePunchId]', timePunchId)
  return axios.get(url)
}
