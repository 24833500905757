import {
  GET_CHANNELS_SUCCESS,
  GET_CHANNELS_ERROR,
  GET_CHANNELS_PENDING,
  WATCH_CHANNEL_SUCCESS,
  WATCH_CHANNEL_ERROR,
  WATCH_CHANNEL_PENDING,
} from '../actions/actionTypes'

export default function newsReducer(
  state = {
    channels: [],
    channelsError: '',
    channelsPending: false,

    watchChannelPending: false,
    watchChannelError: {},
  },
  action
) {
  switch (action.type) {
    case GET_CHANNELS_SUCCESS:
      return {
        ...state,
        channels: action.payload,
        channelsError: '',
      }
    case GET_CHANNELS_ERROR:
      return {
        ...state,
        channelsError: action.error,
      }
    case GET_CHANNELS_PENDING:
      return {
        ...state,
        channelsPending: action.status,
      }

    case WATCH_CHANNEL_SUCCESS: {
      const { channelId, payload } = action
      const _channels = state.channels.map(channel =>
        channel.channelId === channelId ?
          { ...channel, isWatching: payload }
          :
          channel
      )
      return {
        ...state,
        channels: _channels,
        watchChannelError: {}
      }
    }
    case WATCH_CHANNEL_ERROR: {
      return {
        ...state,
        watchChannelPending: false,
        watchChannelError: action.error
      }
    }
    case WATCH_CHANNEL_PENDING:
      return {
        ...state,
        watchChannelPending: action.status,
      }
    default:
      return state
  }
}
