import * as types from './actionTypes'
import services from '../services'

export function getChannelPosts(channelId, lastPostId) {
  return dispatch => {
    dispatch(getChannelActionPending(true, lastPostId))
    services.channel
      .fetchChannel(channelId, lastPostId)
      .then(response => {
        dispatch(getChannelActionSuccess(response))
        dispatch(getChannelActionPending(false, lastPostId))
      })
      .catch(error => {
        dispatch(getChannelActionError(error))
        dispatch(getChannelActionPending(false, lastPostId))
      })
  }
}

const getChannelActionSuccess = response => {
  return {
    type: types.GET_POSTS_SUCCESS,
    payload: response.data,
  }
}
const getChannelActionError = response => {
  return {
    type: types.GET_POSTS_ERROR,
    error: response,
  }
}
const getChannelActionPending = (status, lastPostId) => {
  return {
    type: types.GET_POSTS_PENDING,
    status,
    lastPostId
  }
}

export function getChannelAccess(channelId) {
  return dispatch => {
    dispatch(getChannelAccessActionPending(true))
    services.channel
      .fetchChannelAccess(channelId)
      .then(response => {
        dispatch(getChannelAccessActionSuccess(response))
        dispatch(getChannelAccessActionPending(false))
      })
      .catch(error => {
        dispatch(getChannelAccessActionError(error))
        dispatch(getChannelAccessActionPending(false))
      })
  }
}


const getChannelAccessActionSuccess = response => {
  return {
    type: types.GET_CHANNEL_ACCESS_SUCCESS,
    payload: response.data,
  }
}
const getChannelAccessActionError = response => {
  return {
    type: types.GET_CHANNEL_ACCESS_ERROR,
    error: response,
  }
}
const getChannelAccessActionPending = (status) => {
  return {
    type: types.GET_CHANNEL_ACCESS_PENDING,
    status,
  }
}

export function resetState() {
  return dispatch => {
    dispatch({ type: types.RESET_POSTS_STATE })
  }
}

export function getChannelLastPost(channelId) {
  return dispatch => {
    dispatch(getChannelLastPostActionPending(true))
    services.channel
      .getChannelLastPost(channelId)
      .then(response => {
        dispatch(getChannelLastPostActionPending(false))
        dispatch(getChannelLastPostActionSuccess(response))
      })
      .catch(error => {
        dispatch(getChannelLastPostActionError(error))
      })
  }
}


const getChannelLastPostActionSuccess = response => {
  return {
    type: types.GET_CHANNEL_LAST_POST_SUCCESS,
    payload: response.data,
  }
}
const getChannelLastPostActionError = response => {
  return {
    type: types.GET_CHANNEL_LAST_POST_ERROR,
    error: response,
  }
}
const getChannelLastPostActionPending = status => {
  return {
    type: types.GET_CHANNEL_LAST_POST_PENDING,
    pending: status,
  }
}

export function removePost(postId) {
  return dispatch => {
    dispatch(removePostActionPending(true))
    services.postCard
      .removePostComment(postId)
      .then(response => { //eslint-disable-line
        dispatch(removePostActionPending(false))
        dispatch(removePostActionSuccess(postId))
      })
      .catch(error => {
        dispatch(removePostActionError(error))
      })
  }
}

const removePostActionSuccess = response => {
  return {
    type: types.REMOVE_POST_SUCCESS,
    payload: response,
  }
}
const removePostActionError = response => {
  return {
    type: types.REMOVE_POST_ERROR,
    error: response,
  }
}
const removePostActionPending = status => {
  return {
    type: types.REMOVE_POST_PENDING,
    pending: status,
  }
}

export function translatePost(title, body, postId) {
  return dispatch => {
    dispatch(translatePostActionPending(true))
    services.postCard
      .translatePostCard(title, body)
      .then(response => {
        dispatch(translatePostActionSuccess(response, postId))
        dispatch(translatePostActionPending(false))
      })
      .catch(error => {
        dispatch(translatePostActionError(error, postId))
        dispatch(translatePostActionPending(false))
      })
  }
}


const translatePostActionSuccess = (response, postId) => {
  return {
    type: types.TRANSLATE_POST_SUCCESS,
    payload: response,
    postId,
  }
}
const translatePostActionError = (response, postId) => {
  return {
    type: types.TRANSLATE_POST_ERROR,
    error: response,
    postId,
  }
}
const translatePostActionPending = status => {
  return {
    type: types.TRANSLATE_POST_PENDING,
    status,
  }
}

export function watchPost(postId, isWatching, isFromMyOrganization) {
  return dispatch => {
    dispatch(watchPostActionPending(true))
    services.postCard
      .watchPostCard(postId, isWatching, isFromMyOrganization)
      .then(() => {
        dispatch(watchPostActionPending(false))
        dispatch(watchPostActionSuccess(!isWatching, postId))
      })
      .catch(error => {
        dispatch(watchPostActionPending(false))
        dispatch(watchPostActionError(error, postId))
      })
  }
}

const watchPostActionSuccess = (response, postId) => {
  return {
    type: types.WATCH_POST_SUCCESS,
    payload: response,
    postId,
  }
}
const watchPostActionError = (response, postId) => {
  return {
    type: types.WATCH_POST_ERROR,
    error: response,
    postId,
  }
}
const watchPostActionPending = status => {
  return {
    type: types.WATCH_POST_PENDING,
    pending: status,
  }
}

export const updatePostReactions = (postId, reactionType, value) => dispatch => (
  dispatch({
    type: types.UPDATE_POST_REACTION,
    postId,
    reactionType,
    value,
  })
)
