import { chain, get, size, groupBy, sortBy, cloneDeep } from 'lodash'
import moment from 'moment'

import { formatDateNotForDisplay } from '../../components/utils/datetimeUtils'

import {
  SET_SCHEDULE_DATES,
  GET_SCHEDULE,
  GET_SCHEDULE_ERROR,
  GET_SCHEDULE_PENDING,
  REQUEST_EVENT_CHANGE_SUCCESS,
  REQUEST_EVENT_CHANGE_PENDING,
  REQUEST_EVENT_CHANGE_ERROR,
  GET_CHAT_NOTIFICATIONS_SUCCESS,
  GET_CHAT_NOTIFICATIONS_ERROR,
  GET_CHAT_NOTIFICATIONS_PENDING,
  GET_MONTH_SCHEDULE_ERROR,
  GET_MONTH_SCHEDULE_PENDING,
  GET_MONTH_SCHEDULE_SUCCESS,
  SET_NEW_CHAT_MESSAGES_COUNT,
  UPDATE_NEW_CHAT_MESSAGES_COUNT,
  GET_DISTRICT_EVENT_DETAILS_ERROR,
  GET_DISTRICT_EVENT_DETAILS_PENDING,
  GET_DISTRICT_EVENT_DETAILS_SUCCESS,
  RESET_DISTRICT_EVENT_DETAILS,
  GET_SCHEDULE_CHANGE_TYPES_SUCCESS,
  GET_SCHEDULE_CHANGE_TYPES_PENDING,
  GET_SCHEDULE_CHANGE_TYPES_ERROR,
  GET_SCHEDULE_CHANGE_REASONS_SUCCESS,
  GET_SCHEDULE_CHANGE_REASONS_PENDING,
  GET_SCHEDULE_CHANGE_REASONS_ERROR,
} from '../actions/actionTypes'

function getUnconfirmedIds(scheduledEvents) {
  // Given a list of scheduled events:
  // - only include those that have scheduledEvent.confirmed == 'unconfirmed'
  // - turn into a list of scheduledEventIds
  // - remove any null values

  return chain(scheduledEvents)
    .filter(['confirmed', 'unconfirmed'])
    .map('scheduledEventId')
    .without(null)
    .value()
}

export default function scheduleReducer(
  state = {
    scheduleStartDate: null,
    scheduleEndDate: null,
    scheduleItems: [],
    scheduleItemsGroupedByDate: [],
    scheduleItemsError: {},
    scheduleItemsStatus: false,

    requestEventChangeStatus: false,
    requestEventChangePending: false,
    requestEventChangeError: {},

    unconfirmedEventIds: [],

    eventChatNotifications: [],
    eventChatNotificationsPending: false,
    eventChatNotificationsError: {},
    weekHasNotifications: false,
    newChatMessages: 0,

    monthScheduleItems: [],
    monthSchedulePending: false,
    monthScheduleError: {},

    districtEventDetails: {},
    districtEventDetailsPending: false,
    districtEventDetailsError: {},

    scheduleChangeTypes: [],
    scheduleChangeTypesPending: false,
    scheduleChangeTypesError: {},

    scheduleChangeReasons: [],
    scheduleChangeReasonsPending: false,
    scheduleChangeReasonsError: {},
  },
  action
) {
  switch (action.type) {
    case GET_SCHEDULE_CHANGE_REASONS_SUCCESS: {
      let vacation = {
        id: 5,
        reasonCode: 'VACATION',
        description: 'Vacation',
      }
      action.payload.push(vacation)
      return {
        ...state,
        scheduleChangeReasons: action.payload,
        scheduleChangeReasonsError: {},
      }
    }
    case GET_SCHEDULE_CHANGE_REASONS_ERROR:
      return {
        ...state,
        scheduleChangeReasonsError: action.payload,
      }      
    case GET_SCHEDULE_CHANGE_REASONS_PENDING:
      return {
        ...state,
        scheduleChangeReasonsPending: action.payload,
      }        
    case GET_SCHEDULE_CHANGE_TYPES_SUCCESS:
      return {
        ...state,
        scheduleChangeTypes: action.payload,
        scheduleChangeTypesError: {},
      }
    case GET_SCHEDULE_CHANGE_TYPES_ERROR:
      return {
        ...state,
        scheduleChangeTypesError: action.payload,
      }      
    case GET_SCHEDULE_CHANGE_TYPES_PENDING:
      return {
        ...state,
        scheduleChangeTypesPending: action.payload,
      }            
    case SET_SCHEDULE_DATES:
      return {
        ...state,
        scheduleStartDate: formatDateNotForDisplay(get(action, 'payload.scheduleStartDate')),
        scheduleEndDate: formatDateNotForDisplay(get(action, 'payload.scheduleEndDate')),
      }
    case GET_SCHEDULE: {
      return {
        ...state,
        weekHasNotifications: action.payload.filter((item) => item.newPostCount && item.newPostCount !== 0).length ? true : false,
        scheduleItems: action.payload,

        scheduleItemsGroupedByDate: sortBy(groupBy( action.payload, 'date'), key => {
          return moment(key, 'D MMM YYYY')
        }),
        scheduleItemsError: {},
        unconfirmedEventIds: getUnconfirmedIds(action.payload),
        showConfirmWeek: size(getUnconfirmedIds(action.payload)) > 0,
      }
    }
    case GET_SCHEDULE_ERROR:
      return {
        ...state,
        scheduleItemsError: action.payload,
        scheduleItemsStatus: false,
      }
    case GET_SCHEDULE_PENDING:
      return {
        ...state,
        scheduleItemsStatus: action.payload,
      }
    case RESET_DISTRICT_EVENT_DETAILS:
      return {
        ...state,
        districtEventDetails: {},
      }
    case GET_DISTRICT_EVENT_DETAILS_SUCCESS:
      return {
        ...state,
        districtEventDetails: action.payload,
      }
    case GET_DISTRICT_EVENT_DETAILS_PENDING:
      return {
        ...state,
        districtEventDetailsPending: action.payload,
      }
    case GET_DISTRICT_EVENT_DETAILS_ERROR:
      return {
        ...state,
        districtEventDetailsError: action.payload,
      }
    case GET_MONTH_SCHEDULE_SUCCESS:
      return {
        ...state,
        monthScheduleItems: action.payload,
        monthScheduleError: {}
      }
    case GET_MONTH_SCHEDULE_ERROR:
      return {
        ...state,
        monthScheduleItems: [],
        monthScheduleError: {},
        newChatMessages: 0,
      }
    case GET_MONTH_SCHEDULE_PENDING:
      return {
        ...state,
        monthSchedulePending: action.status,
      }

    case REQUEST_EVENT_CHANGE_SUCCESS:
      return {
        ...state,
        requestEventChangeStatus: action.payload,
        requestEventChangeError: {}
      }
    case REQUEST_EVENT_CHANGE_PENDING:
      return {
        ...state,
        requestEventChangePending: action.payload,
      }
    case REQUEST_EVENT_CHANGE_ERROR:
        return {
          ...state,
          requestEventChangeError: action.payload,
        }

    case GET_CHAT_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        eventChatNotifications: action.payload,
        eventChatNotificationsPending: false,
        eventChatNotificationsError: {},
      }
    case GET_CHAT_NOTIFICATIONS_ERROR:
      return {
        ...state,
        eventChatNotifications: [],
        eventChatNotificationsPending: false,
        eventChatNotificationsError: action.error,
      }
    case GET_CHAT_NOTIFICATIONS_PENDING:
      return {
        ...state,
        eventChatNotificationsPending: action.status,
      }
    case SET_NEW_CHAT_MESSAGES_COUNT:
      return {
        ...state,
        newChatMessages: action.newChatMessages,
      }
    case UPDATE_NEW_CHAT_MESSAGES_COUNT: {
      const _scheduleItems = cloneDeep(state.scheduleItems)
      const _eventChatNotifications = cloneDeep(state.eventChatNotifications)
      const itemIndex = _scheduleItems.findIndex(item => item.scheduledStoreId === action.scheduledStoreId)
      const itemIndexEventChat = _eventChatNotifications.findIndex(item => item.eventId === parseInt(action.scheduledStoreId), 10)
      if (itemIndex !== -1) _scheduleItems[itemIndex].newPostCount = 0
      if (itemIndexEventChat !== -1) _eventChatNotifications[itemIndexEventChat].newPostCount = 0
      return {
        ...state,
        newChatMessages: action.newChatMessages,
        scheduleItems: _scheduleItems,
        weekHasNotifications: _scheduleItems.filter((item) => item.newPostCount && item.newPostCount !== 0).length ? true : false,
        eventChatNotifications: _eventChatNotifications,
      }
    }
    default:
      return state
  }
}
