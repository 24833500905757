import axios from 'axios'
import apiConfig from '../../config/api-config'
import * as cancelTokenUtil from './utils/cancelTokenUtil'

const EVENT_SURVEY_NAME = 'Overall Event'
const DRIVER_SURVEY_NAME = 'Driver'

export function getFeedbackById(feedbackId) {
  const url = apiConfig.GET_FEEDBACK_BY_ID.replace('[feedbackId]', feedbackId);
  const options = cancelTokenUtil.abortAndGetCancelTokenOptions('GET_FEEDBACK_BY_ID')
  return axios.get(url, options)
}

export function getAllFeedbacks(eventId, driverDetails) {
    const promisesArray = [
      getFeedbackEventTemplate(eventId, null, EVENT_SURVEY_NAME)
    ]
    for (let index = 0; index < driverDetails.length; index += 1){
      promisesArray.push(getFeedbackEventTemplate(eventId, driverDetails[index].driverPersonId, DRIVER_SURVEY_NAME))
    }
    return Promise.all(promisesArray)
}

export function getFeedbackEventTemplate(subjectEventId, subjectPersonId, templateName){
  const url = apiConfig.GET_FEEDBACK_BY_EVENT_TEMPLATE
    .replace('[subjectEventId]', subjectEventId)
    .replace('[subjectPersonId]', subjectPersonId || '')
    .replace('[templateName]', templateName)
  // const options = cancelTokenUtil.abortAndGetCancelTokenOptions('GET_FEEDBACK_BY_EVENT_TEMPLATE')
  return axios.get(url)
}

export function getLatestEventSurveyByTemplateName(templateName) {
  const url = apiConfig.GET_LATEST_SURVEY_TEMPLATE.replace('[templateName]', templateName)
  const options = cancelTokenUtil.abortAndGetCancelTokenOptions('GET_LATEST_SURVEY_TEMPLATE_EVENT')
  return axios.get(url, options)
}

export function postSurveyFeedback(feedback) {
  const url = apiConfig.POST_SURVEY_FEEDBACK
  // const options = cancelTokenUtil.abortAndGetCancelTokenOptions('POST_SURVEY_FEEDBACK')
  return axios.post(url, feedback)
}
