import {
  GET_NOTIFICATIONS_COUNT_SUCCESS,
  GET_NOTIFICATIONS_COUNT_ERROR,
  GET_NOTIFICATIONS_COUNT_PENDING,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_ERROR,
  GET_NOTIFICATIONS_PENDING,
  GET_UNREAD_NOTIFICATIONS_HISTORY_SUCCESS,
  GET_UNREAD_NOTIFICATIONS_HISTORY_ERROR,
  GET_UNREAD_NOTIFICATIONS_HISTORY_PENDING,
  GET_NOTIFICATIONS_HISTORY_SUCCESS,
  GET_NOTIFICATIONS_HISTORY_ERROR,
  GET_NOTIFICATIONS_HISTORY_PENDING,
  PATCH_MARK_NOTIFICATIONS_AS_READ_SUCCESS,
  PATCH_MARK_NOTIFICATIONS_AS_READ_ERROR,
  PATCH_MARK_NOTIFICATIONS_AS_READ_PENDING,
  GET_CHANNEL_ID_BY_POST_ID_SUCCESS,
  GET_CHANNEL_ID_BY_POST_ID_ERROR,
  GET_CHANNEL_ID_BY_POST_ID_PENDING,
  GET_ORG_NAME_BY_PERSON_ID_ERROR,
  GET_ORG_NAME_BY_PERSON_ID_PENDING,
  GET_ORG_NAME_BY_PERSON_ID_SUCCESS,
  PATCH_NOTIFICATIONS_HISTORY_STATUS_AS_READ_ERROR,
  PATCH_NOTIFICATIONS_HISTORY_STATUS_AS_READ_PENDING,
  PATCH_NOTIFICATIONS_HISTORY_STATUS_AS_READ_SUCCESS,
  GET_NOTIFICATION_HISTORY_TYPES_SUCCESS,
  GET_NOTIFICATION_HISTORY_TYPES_PENDING,
  GET_NOTIFICATION_HISTORY_TYPES_ERROR,
  GET_NOTIFICATION_PREFERENCES_SUCCESS,
  GET_NOTIFICATION_PREFERENCES_PENDING,
  GET_NOTIFICATION_PREFERENCES_ERROR,
  POST_NOTIFICATION_PREFERENCES_SUCCESS,
  POST_NOTIFICATION_PREFERENCES_PENDING,
  POST_NOTIFICATION_PREFERENCES_ERROR,
} from '../actions/actionTypes'

import { isDefinedAndNotEmpty } from '../../components/utils/nullSafeCheckUtils'

export default function notificationsReducer(
  state = {
    postNotificationPreferencesPending: false,
    postNotificationPreferencesError: {},
    notificationPreferences: [],
    notificationPreferencesPending: false,
    notificationPreferencesError: {},
    notificationHistoryTypes: [],
    notificationHistoryTypesPending: false,
    notificationHistoryTypesError: {},
    notifications: [],
    notificationsError: {},
    notificationsPending: false,
    newNotificationsCount: 0,
    newNotificationsCountError: '',
    newNotificationsCountPending: false,
    markNotificationsAsRead: null,
    markNotificationsAsReadError: {},
    markNotificationsAsReadPending: false,
    channelId: null,
    channelIdPending: false,
    channelIdError: {},
    organizationName: '',
    organizationNamePending: false,
    organizationNameError: {},
    notificationsHistory: [],
    notificationsHistoryError: {},
    notificationsHistoryPending: false,
    unreadNotificationsHistory: [],
    unreadNotificationsHistoryError: {},
    unreadNotificationsHistoryPending: false,
    patchNotificationsHistoryStatusAsReadError: {},
    patchNotificationsHistoryStatusAsReadPending: false,
  },
  action
) {
  switch (action.type) {
    case PATCH_NOTIFICATIONS_HISTORY_STATUS_AS_READ_SUCCESS:
      return {
        ...state,
        patchNotificationsHistoryStatusAsReadError: {},
      }
    case PATCH_NOTIFICATIONS_HISTORY_STATUS_AS_READ_ERROR:
      return {
        ...state,
        patchNotificationsHistoryStatusAsReadError: action.payload,
      }
    case PATCH_NOTIFICATIONS_HISTORY_STATUS_AS_READ_PENDING:
      return {
        ...state,
        patchNotificationsHistoryStatusAsReadPending: action.payload,
      }
    case POST_NOTIFICATION_PREFERENCES_SUCCESS:
      return {
        ...state,
        postNotificationPreferencesError: {},
      }
    case POST_NOTIFICATION_PREFERENCES_ERROR:
      return {
        ...state,
        postNotificationPreferencesError: action.payload,
      }
    case POST_NOTIFICATION_PREFERENCES_PENDING:
      return {
        ...state,
        postNotificationPreferencesPending: action.payload,
      }  
    case GET_NOTIFICATION_PREFERENCES_SUCCESS:
      return {
        ...state,
        notificationPreferences: !isDefinedAndNotEmpty(action.payload) ? [] : action.payload,
        notificationPreferencesError: {},
      }
    case GET_NOTIFICATION_PREFERENCES_ERROR:
      return {
        ...state,
        notificationPreferencesError: action.payload,
      }
    case GET_NOTIFICATION_PREFERENCES_PENDING:
      return {
        ...state,
        notificationPreferencesPending: action.payload,
      }
    case GET_NOTIFICATION_HISTORY_TYPES_SUCCESS:
      return {
        ...state,
        notificationHistoryTypes: !isDefinedAndNotEmpty(action.payload) ? [] : action.payload,
        notificationHistoryTypesError: {},
      }
    case GET_NOTIFICATION_HISTORY_TYPES_ERROR:
      return {
        ...state,
        notificationHistoryTypesError: action.payload,
      }
    case GET_NOTIFICATION_HISTORY_TYPES_PENDING:
      return {
        ...state,
        notificationHistoryTypesPending: action.payload,
      }
    case GET_UNREAD_NOTIFICATIONS_HISTORY_SUCCESS:
      return {
        ...state,
        unreadNotificationsHistory: !isDefinedAndNotEmpty(action.payload) ? [] : action.payload,
        unreadNotificationsHistoryError: {},
      }
    case GET_UNREAD_NOTIFICATIONS_HISTORY_ERROR:
      return {
        ...state,
        unreadNotificationsHistoryError: action.error,
      }
    case GET_UNREAD_NOTIFICATIONS_HISTORY_PENDING:
      return {
        ...state,
        unreadNotificationsHistoryPending: action.pending,
      }
    case GET_NOTIFICATIONS_HISTORY_SUCCESS:
      return {
        ...state,
        notificationsHistory: !isDefinedAndNotEmpty(action.payload) ? [] : action.payload,
        notificationsHistoryError: {},
      }
    case GET_NOTIFICATIONS_HISTORY_ERROR:
      return {
        ...state,
        notificationsHistoryError: action.error,
      }
    case GET_NOTIFICATIONS_HISTORY_PENDING:
      return {
        ...state,
        notificationsHistoryPending: action.pending,
      }
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.payload,
        notificationsError: {},
      }
    case GET_NOTIFICATIONS_ERROR:
      return {
        ...state,
        notificationsError: action.error,
      }
    case GET_NOTIFICATIONS_PENDING:
      return {
        ...state,
        notificationsPending: action.pending,
      }
    case GET_NOTIFICATIONS_COUNT_SUCCESS:
      return {
        ...state,
        newNotificationsCount: action.payload,
      }
    case GET_NOTIFICATIONS_COUNT_ERROR:
      return {
        ...state,
        newNotificationsCountError: action.error,
      }
    case GET_NOTIFICATIONS_COUNT_PENDING:
      return {
        ...state,
        newNotificationsCountPending: action.pending,
      }
    case PATCH_MARK_NOTIFICATIONS_AS_READ_SUCCESS: {
      let _notifications = state.notifications
      action.notificationIds.map((id) => {
        const notificationIndex = _notifications.findIndex((notification) => notification.id === id)
        _notifications[notificationIndex].status = 'READ'
        return ''
      })
      return {
        ...state,
        notifications: _notifications,
      }
    }
    case PATCH_MARK_NOTIFICATIONS_AS_READ_ERROR:
      return {
        ...state,
        markNotificationsAsReadError: action.error,
      }
    case PATCH_MARK_NOTIFICATIONS_AS_READ_PENDING:
      return {
        ...state,
        markNotificationsAsReadPending: action.pending,
      }

    case GET_CHANNEL_ID_BY_POST_ID_SUCCESS:
      return {
        ...state,
        channelId: action.payload,
        channelIdPending: false,
        channelIdError: {},
      }
    case GET_CHANNEL_ID_BY_POST_ID_ERROR:
      return {
        ...state,
        channelIdError: action.error,
      }
    case GET_CHANNEL_ID_BY_POST_ID_PENDING:
      return {
        ...state,
        channelIdPending: action.pending,
      }

      case GET_ORG_NAME_BY_PERSON_ID_SUCCESS:
        return {
          ...state,
          organizationName: action.payload,
          organizationNamePending: false,
          organizationNameError: {},
        }
      case GET_ORG_NAME_BY_PERSON_ID_ERROR:
        return {
          ...state,
          organizationNameError: action.error,
        }
      case GET_ORG_NAME_BY_PERSON_ID_PENDING:
        return {
          ...state,
          organizationNamePending: action.pending,
        }
    default:
      return state
  }
}
