import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import './SelectComplimentPeopleModal.scss'
import PostUserAvatar from '../PostUserAvatar'
import { t } from '../../utils/i18n'
import { obfuscate } from '../../components/utils/obfuscateUtils'
import { getLoggedInPersonId } from '../../components/utils/localStorageUtils'
import { getSearchAllPeople } from '../../state/actions/personDetailsActions'
import { cloneDeep } from 'lodash'

class SelectComplimentPeopleModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      myPersonId: null,
      searchText: '',
      sortedTeamMembers: [],
      searchedTeamMembers: [],
      selectedTeamMembers: [],
      sortedTeamMembersCopy: [],
      selectedTeamMembersCopy: [],
      showSearchDropdown: false,
    }
    this.dropdownRef = React.createRef()
    this.inputSearchRef = React.createRef()
  }

  UNSAFE_componentWillMount() {
    this.setState({
      myPersonId: parseInt(getLoggedInPersonId(), 10),
    })
    document.addEventListener('mousedown', this.handleOutsideDropdownClick, false)
  }

  componentDidMount() {
    const { myPersonId } = this.state
    let sortedMembers = []
    if (this.props.navFromEventPage && !this.props.navFromSentComplimentsPage) {
      this.getSortedMembers(this.props.members).map(member => {
        if (myPersonId !== member.personId) {
          sortedMembers.push({ ...member, selected: false })
        }
        return null
      })
    }
    if (!this.props.navFromEventPage && this.props.navFromSentComplimentsPage) {
      const _members = []
      this.props.members.map(member => {
        if (myPersonId !== member.personId) {
          _members.push({ ...member, selected: false, employeeName: `${member.firstName} ${member.lastName}` })
        }
        return null
      })
      sortedMembers = this.getSortedMembers(_members)
    }
    if(this.props.history.location.state === undefined ? false : this.props.history.location.state.navFromPublicProfile){
      const _members = []
      this.props.history.location.state.organizationPeople.map(member => {
        _members.push({ ...member, selected: false, employeeName: `${member.firstName} ${member.lastName}` })
        return null
      })
      sortedMembers = this.getSortedMembers(_members)
    }
    this.setState({ sortedTeamMembers: sortedMembers })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchAllPersonsListPending === true && this.props.searchAllPersonsListPending === false) {
      const { selectedTeamMembers, myPersonId } = this.state
      let searchedTeamMembers = []
      this.props.searchAllPersonsList.map(person => {
        if (myPersonId !== person.personId) {
          searchedTeamMembers.push({ ...person, selected: false, employeeName: `${person.firstName} ${person.lastName}` })
        }
        return null
      })
      selectedTeamMembers.map(member => {
        const indexIfSelected = searchedTeamMembers.findIndex(_member => _member.personId === member.personId)
        if (indexIfSelected !== -1) {
          searchedTeamMembers[indexIfSelected].selected = true
        }
        return null
      })
      this.setState({
        searchedTeamMembers,
        showSearchDropdown: true,
      })
    }

    if (prevProps.showModal === false && this.props.showModal === true) {
      this.setState({
        sortedTeamMembersCopy: cloneDeep(this.state.sortedTeamMembers),
        selectedTeamMembersCopy: cloneDeep(this.state.selectedTeamMembers),
      })
    }

    if (prevProps.navFromEventPage === false && this.props.navFromEventPage === true) {
      const { myPersonId } = this.state
      const sortedMembers = []
      this.getSortedMembers(this.props.members).map(member => {
        if (myPersonId !== member.personId) {
          sortedMembers.push({ ...member, selected: false })
        }
        return null
      })
      this.setState({ sortedTeamMembers: sortedMembers })
    }
    if (this.props.navFromSentComplimentsPage === true
      && !prevProps.members.length && this.props.members.length) {
      const { myPersonId } = this.state
      let sortedMembers = []
      const _members = []
      this.props.members.map(member => {
        if (myPersonId !== member.personId) {
          _members.push({ ...member, selected: false, employeeName: `${member.firstName} ${member.lastName}` })
        }
        return null
      })
      sortedMembers = this.getSortedMembers(_members)
      this.setState({ sortedTeamMembers: sortedMembers })
    }
    if(!prevProps.initialPeople.length && this.props.initialPeople.length){
      this.setState({ selectedTeamMembers: this.props.initialPeople })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideDropdownClick, false)
  }

  handleOutsideDropdownClick = (evt) => {
    if ((this.dropdownRef.current && this.dropdownRef.current.contains(evt.target))
      || (this.inputSearchRef.current && this.inputSearchRef.current.contains(evt.target))) {
      this.setState({ showSearchDropdown: true })
    } else {
      this.setState({ showSearchDropdown: false })
    }
  }

  searchTextChange = (evt) => {
    const searchText = evt.target.value
    const _state = this.state

    if (this.props.navFromEventPage && !this.props.navFromSentComplimentsPage) {
      const { sortedTeamMembers } = this.state
      _state.searchedTeamMembers = searchText.length ?
        cloneDeep(sortedTeamMembers.filter((member) =>
          member.employeeName.toUpperCase().indexOf(searchText.toUpperCase()) > -1))
        :
        []
    } else {
      if (searchText.length > 2) {
        this.props.getSearchAllPeople(searchText)
      }
    }
    _state.searchText = searchText
    this.setState(_state)
  }

  getSortedMembers = (unsortedMembers) => {
    return unsortedMembers.sort((a, b) => {
      if (a.employeeName > b.employeeName) {
        return 1
      } else {
        return -1
      }
    })
  }

  selectTeamMemberClick = (member) => {
    const _state = this.state

    const searchMemberIndex = _state.searchedTeamMembers.findIndex((_member) => _member.personId === member.personId)
    if (searchMemberIndex !== -1) {
      _state.searchedTeamMembers[searchMemberIndex].selected = !_state.searchedTeamMembers[searchMemberIndex].selected
    }

    const sortedMemberIndex = _state.sortedTeamMembers.findIndex((_member) => _member.personId === member.personId)
    if (sortedMemberIndex !== -1) {
      _state.sortedTeamMembers[sortedMemberIndex].selected = !_state.sortedTeamMembers[sortedMemberIndex].selected
    }

    const _selectedTeamMembers = _state.selectedTeamMembers.filter((_member) => _member.personId !== member.personId)
    if (_selectedTeamMembers.length === _state.selectedTeamMembers.length) { //if the member is not selected, add it to array
      _selectedTeamMembers.push(member)
    }

    _state.selectedTeamMembers = _selectedTeamMembers

    this.setState(_state)
  }

  selectAllTeamMembersClick = () => {
    const { sortedTeamMembers, searchedTeamMembers, selectedTeamMembers } = this.state
    let _sortedTeamMembers = [], _selectedTeamMembers = [], _searchedTeamMembers = cloneDeep(searchedTeamMembers)
    _sortedTeamMembers = sortedTeamMembers.map(member => {
      if (member.selected === false) {
        _selectedTeamMembers.push(member)
        const memberIndex = _searchedTeamMembers.findIndex((_member) => _member.personId === member.personId)
        if (memberIndex !== -1) {
          _searchedTeamMembers[memberIndex].selected = true
        }
      }
      return { ...member, selected: true }
    })
    _selectedTeamMembers = _selectedTeamMembers.concat(selectedTeamMembers)
    this.setState({
      sortedTeamMembers: _sortedTeamMembers,
      searchedTeamMembers: _searchedTeamMembers,
      selectedTeamMembers: _selectedTeamMembers,
    })
  }

  deselectAllTeamMembersClick = () => {
    const { sortedTeamMembers, searchedTeamMembers } = this.state
    const _sortedTeamMembers = sortedTeamMembers.map(member => ({ ...member, selected: false }))
    const _searchedTeamMembers = searchedTeamMembers.map(member => ({ ...member, selected: false }))
    this.setState({
      sortedTeamMembers: _sortedTeamMembers,
      searchedTeamMembers: _searchedTeamMembers,
      selectedTeamMembers: [],
    })
  }

  closeSelectPeoplePageAction = () => {
    const { initialPeopleSelected } = this.props

    if (initialPeopleSelected === true) {
      this.clearSearchList()
      this.setState({
        selectedTeamMembers: this.state.selectedTeamMembersCopy,
        sortedTeamMembers: this.state.sortedTeamMembersCopy,
      })
      this.props.closeModal()
    } else {
      this.props.goBackClick()
    }
  }

  handleSelectPeople = () => {
    this.clearSearchList()
    this.props.handleSelectPeople(this.state.selectedTeamMembers)
  }

  clearSearchList = () => {
    this.setState({
      searchedTeamMembers: [],
      searchText: '',
    })
  }

  renderSearchBar = () => {
    const { searchText, showSearchDropdown, searchedTeamMembers, selectedTeamMembers } = this.state
    const { searchAllPersonsListPending, navFromEventPage } = this.props
    return (
      <div className="search-bar mb-2">
        <div className="row">
          <label className="col-12 col-md-4">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder={t('search_team_member')}
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={this.searchTextChange}
                value={searchText}
                ref={this.inputSearchRef}
              />
              <div className={`spinner-border ${!searchAllPersonsListPending ? 'd-none' : ''}`} role="status">
                <span className="sr-only">Loading...</span>
              </div>
              {searchText.length > 0 &&
                <i
                  className={`material-icons cursor-pointer clear medium ${searchAllPersonsListPending ? 'd-none' : ''}`}
                  onClick={this.clearSearchList}
                >
                  clear
                </i>
              }
              <div className="search-dropdown" ref={this.dropdownRef}>
                {showSearchDropdown ?
                  <ul>
                    {
                      searchedTeamMembers.map((member) => (
                        <li
                          key={member.personId}
                          className="list-group-item cursor-pointer"
                          onClick={() => this.selectTeamMemberClick(member)}
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex flex-column justify-content-center">
                              <div>{member.employeeName}</div>
                              {!navFromEventPage && <div className="organization-name">{member.organizationName}</div>}
                            </div>
                            <i className={`material-icons check-circle medium  ${member.selected ? 'active' : ''} `}>
                              check_circle
                            </i>
                          </div>
                        </li>
                      ))
                    }
                  </ul>
                  : ''
                }
              </div>
            </div>
          </label>
          <div className="col-6 col-md-2">
            <input
              type="button"
              className="m-0 btn btn-rgis-blue cursor-pointer uppercase w-100"
              onClick={this.selectAllTeamMembersClick}
              value={t('select_all')}
            />
          </div>
          <div className="col-6 col-md-2">
            <input
              type="button"
              className="m-0 btn btn-rgis-blue cursor-pointer uppercase w-100"
              onClick={this.deselectAllTeamMembersClick}
              disabled={!selectedTeamMembers.length}
              value={t('deselect_all')}
            />
          </div>
          <div className="col-6 col-md-2 mt-2 mt-md-0">
            <input
              type="button"
              className="m-0 btn btn-rgis-blue cursor-pointer uppercase w-100"
              onClick={this.closeSelectPeoplePageAction}
              value={t('cancel')}
            />
          </div>
          <div className="col-6 col-md-2 mt-2 mt-md-0">
            <input
              type="button"
              className="m-0 btn btn-rgis-blue cursor-pointer uppercase w-100"
              onClick={this.handleSelectPeople}
              disabled={!selectedTeamMembers.length}
              value={t('next')}
            />
          </div>
        </div>
      </div>
    )
  }

  handleNameClick(evt, personId) {
    evt.stopPropagation()
    this.props.history.push(`/public-profile/${obfuscate(personId)}`)
  }

  renderTeamMemberList = () => {
    const { sortedTeamMembers, selectedTeamMembers } = this.state
    const { navFromEventPage, profileImages, history } = this.props
    return (
      <div>
        <div className="row">
          <div className="col-12 col-md-4">
            <ul className="list-group">
              {
                sortedTeamMembers.map((member) => {
                  const userAvatar = profileImages.find(obj => obj.personId === member.personId)
                  return (
                    <li
                      key={member.personId}
                      className={`list-group-item cursor-pointer align-items-center justify-content-between ${member.selected ? 'd-none' : 'd-flex'}`}
                      onClick={() => this.selectTeamMemberClick(member)} >
                      <div className="d-flex" >
                        <PostUserAvatar
                          personName={member.employeeName}
                          userAvatarUrl={userAvatar !== undefined ? userAvatar.url : ''}
                          personId={member.personId}
                          redirectToAvatarPage={false}
                          history={history}
                        />
                        <div className="col d-flex flex-column justify-content-center">
                          <div
                            className="name cursor-pointer"
                            onClick={(evt) => this.handleNameClick(evt, member.personId)}
                          >
                            {member.employeeName}
                          </div>
                          {!navFromEventPage && <div className="organization-name">{member.organizationName}</div>}
                        </div>
                      </div>
                      <i className="material-icons add medium">
                        add
                      </i>
                    </li>
                  )
                })
              }
            </ul>
          </div>
          <div className="col-12 col-md-4">
            <h3 className="mt-2 mt-md-0">{t('selected_people')}:</h3>
            <ul className="list-group">
              {
                selectedTeamMembers.map((member) => {
                  const userAvatar = profileImages.find(obj => obj.personId === member.personId)
                  return (
                    <li
                      key={member.personId}
                      className="list-group-item cursor-pointer d-flex align-items-center justify-content-between"
                      onClick={() => this.selectTeamMemberClick(member)} >
                      <div className="d-flex" >
                        <PostUserAvatar
                          personName={member.employeeName}
                          userAvatarUrl={userAvatar !== undefined ? userAvatar.url : ''}
                          personId={member.personId}
                          redirectToAvatarPage={false}
                          history={history}
                        />
                        <div className="col d-flex flex-column justify-content-center">
                          <div
                            className="name cursor-pointer"
                            onClick={(evt) => this.handleNameClick(evt, member.personId)}
                          >
                            {member.employeeName}
                          </div>
                          {!navFromEventPage && <div className="organization-name">{member.organizationName}</div>}
                        </div>
                      </div>
                      <i className="material-icons clear medium">
                        clear
                      </i>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { showModal } = this.props
    return (
      <div className="other-availability-list-component select-compliment-people-modal">
        <div
          id="modalOtherAvailabilityList"
          className={`modal fade fullPageModal animation-topToBottom ${showModal === true ? 'show' : ''}`}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header row">
                <div className="col">
                  <h5 className="modal-title capitalize-first-letter">{t('select_people')}</h5>
                </div>
                <div className="col">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.closeSelectPeoplePageAction}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>

              <div className="modal-body">
                {this.renderSearchBar()}
                {this.renderTeamMemberList()}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    searchAllPersonsList: state.personDetailsReducer.searchAllPersonsList,
    searchAllPersonsListPending: state.personDetailsReducer.searchAllPersonsListPending,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getSearchAllPeople: (searchString) => getSearchAllPeople(searchString)
  }, dispatch)
}

SelectComplimentPeopleModal.propTypes = {
  showModal: PropTypes.bool,
  closeModal: PropTypes.func,
  history: PropTypes.object,
  eventDetails: PropTypes.object,
  members: PropTypes.array,
  navFromEventPage: PropTypes.bool,
  navFromSentComplimentsPage: PropTypes.bool,
  searchAllPersonsList: PropTypes.array,
  searchAllPersonsListPending: PropTypes.bool,
  getSearchAllPeople: PropTypes.func,
  handleSelectPeople: PropTypes.func,
  goBackClick: PropTypes.func,
  initialPeopleSelected: PropTypes.bool,
  initialPeople: PropTypes.array,
  profileImages: PropTypes.array,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectComplimentPeopleModal)
