import {
  SESSION_STATUS,
  GET_USER_PROFILE,
  GET_USER_PROFILE_PENDING,
  GET_USER_PROFILE_ERROR,
  SET_PROFILE_LOCALE,
} from '../actions/actionTypes'

export default function loginReducer(
  state = {
    profile: {
      firstName: '',
      lastName: '',
      email: '',
      badgeId: '',
      district: '',
      organizationId: '',
      phone: '',
      sessionStatus: '',
      language: 'EN',
      role: 'TEAM_MEMBER',
      isDistrictUser: false,
    },
    profilePending: false,
    profileError: {},
    profileLocale: null,
  },
  action
) {
  switch (action.type) {
    case GET_USER_PROFILE:
      return {
        ...state,
        profile: {
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          email: action.payload.email,
          badgeId: action.payload.badgeId,
          organizationId: action.payload.organizationId,
          organization: action.payload.organization !== undefined && action.payload.organization !== null ? action.payload.organization.trim().replace(/\D+/g, '') : '',
          phone: action.payload.phoneNumber,
          language: action.payload.locale,
          role: action.payload.role,
          isDistrictUser: action.payload.isDistrictUser,
          fullName: action.payload.fullName
        },
      }      
    case GET_USER_PROFILE_PENDING:
      return {
        ...state,
        profilePending: action.payload,
      }      
    case GET_USER_PROFILE_ERROR:
      return {
        ...state,
        profileError: action.payload,
      } 
    case SET_PROFILE_LOCALE:
      return {
        ...state,
        profileLocale: action.payload,
      }                  
    case SESSION_STATUS:
      return {
        ...state,
        profile: {
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          email: action.payload.email,
          badgeId: action.payload.badgeId,
          district: action.payload.district,
          phone: action.payload.phoneNumber,
          language: action.payload.locale,
          role: action.payload.role,
        },
      }      
    default:
      return state
  }
}
