import './utils/interceptor'
import * as authService from './authService'
import * as otherAvailabilityService from './otherAvailabilityService'
import * as profilePrefService from './profilePrefService'
import * as scheduleService from './scheduleService'
import * as personDetailsService from './personDetailsService'
import * as notificationsService from './notificationsService'
import * as organizationsService from './organizationsService'
import * as transportationProfileService from './transportationProfileService'
import * as newsService from './newsService'
import * as featureAccessService from './featureAccessService'
import * as channelService from './channelService'
import * as postCardService from './postCardService'
import * as reactionsService from './reactionsService'
import * as postService from './postService.js'
import * as filesService from './filesService.js'
import * as platformLogService from './platformLogService'
import * as openShiftsService from './openShiftsService'
import * as badgesService from './badgesService'
import * as feedbackService from './feedbackService'
import * as complimentsService from './complimentsService'
import * as moderationService from './moderationService'
import * as contactsService from './contactsService'
import * as timePunchesService from './timePunchesService'
import * as eventChatService from './eventChatService'
import * as profileService from './profileService'
import * as publicProfileService from './publicProfileService'

export default {
  auth: authService,
  otherAvailability: otherAvailabilityService,
  profilePref: profilePrefService,
  personDetails: personDetailsService,
  notification: notificationsService,
  schedule: scheduleService,
  organizations: organizationsService, 
  transportationProfile: transportationProfileService,
  featureAccess: featureAccessService,
  news: newsService,
  channel: channelService,
  postCard: postCardService,
  reactions: reactionsService,
  post: postService,
  files: filesService,
  platformLog: platformLogService,
  shifts: openShiftsService,
  badges: badgesService,
  feedback: feedbackService,
  compliments: complimentsService,
  moderation: moderationService,
  contacts: contactsService,
  timePunches: timePunchesService,
  eventChat: eventChatService,
  profile: profileService,
  publicProfile: publicProfileService,
}
