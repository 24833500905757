import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

import Preloader from '../../components/Preloader'
import './SendComplimentsContainer.scss'
import { t } from '../../utils/i18n';
import { postCompliment, patchComplimentComment } from '../../state/actions/complimentsActions'
import { getAllPeopleInOrganization } from '../../state/actions/organizationsActions'
import { postListOfProfileImages } from '../../state/actions/profileActions'
import { INTEGRITY_VALUE, PRIDE_VALUE, SERVICE_VALUE, TEAMWORK_VALUE, INNOVATION_VALUE, RESULTS_VALUE } from '../../utils/constants'

import { hasComplimentsAccess } from '../../components/utils/featureAccessUtils'
import { obfuscate } from '../../components/utils/obfuscateUtils'
import { nullSafeCheckIsTrue } from '../../components/utils/nullSafeCheckUtils'

import PostUserAvatar from '../../components/PostUserAvatar'
import CoreValuesLegendModal from '../../components/CoreValuesLegendModal'
import ActionModal from '../../components/ActionModal/ActionModal'
import SelectComplimentPeopleModal from '../../components/SelectComplimentPeopleModal'


class SendComplimentsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedTeamMembers: [],
      eventDetails: {},
      members: [],
      navFromEventPage: false,
      navFromSentComplimentsPage: false,
      compliments: {
        INTEGRITY: false,
        PRIDE: false,
        SERVICE: false,
        TEAMWORK: false,
        INNOVATION: false,
        RESULTS: false
      },
      comment: '',
      complimentIdToEdit: null,
      showLegendModal: false,
      showDetailedPeople: false,
      modal: {
        showModal: false,
        modalTitle: '',
        modalText: '',
        showCancelAction: false,
        modalAcceptButtonText: '',
        modalCancelButtonText: '',
        modalAcceptAction: () => { },
        modalCancelAction: () => { },
      },
      showSelectPeopleModal: false,
      initialPeopleSelected: false,
    }

    this.setComplimentFilter = this.setComplimentFilter.bind(this)
    this.seeCoreValueLegendClick = this.seeCoreValueLegendClick.bind(this)
    this.onCommentChange = this.onCommentChange.bind(this)
    this.submitCompliments = this.submitCompliments.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.showDetailedPeopleClick = this.showDetailedPeopleClick.bind(this)
    this.goBackClick = this.goBackClick.bind(this)
    this.handleShowSelectPeopleModal = this.handleShowSelectPeopleModal.bind(this)
    this.handleSelectPeople = this.handleSelectPeople.bind(this)
  }

  componentDidMount() {
    const _locationState = this.props.location.state
    const _state = this.state
    if (_locationState !== undefined) {

      if (_locationState.navFromPublicProfile) {
          // we come from public profile
          _state.selectedTeamMembers = _locationState.selectedTeamMembers || []
      } else {
        if (_locationState.compliment) {
          _locationState.compliment.coreValues.map((item) => {
            _state.compliments[item.coreValue] = true
            return null
          })
          _state.comment = _locationState.compliment.comment
          _state.complimentIdToEdit = _locationState.compliment.complimentId
        }

        _state.selectedTeamMembers = _locationState.selectedTeamMembers || []
        _state.eventDetails = _locationState.eventDetails || {}
        _state.navFromEventPage = _locationState.navFromEventPage || false
        if (_state.navFromEventPage) {
          _state.members = _locationState.eventTeamMembers
        }

        if (nullSafeCheckIsTrue(_state.navFromEventPage) || nullSafeCheckIsTrue(_locationState.navFromSentComplimentsPage)) {
          _state.showSelectPeopleModal = true
        }

        _state.navFromSentComplimentsPage = _locationState.navFromSentComplimentsPage || false
      }

    } else {
      _state.navFromSentComplimentsPage = true
    }
    this.setState(_state)

    if (this.props.profilePending === true
      && ((_locationState && !_locationState.compliment && (!_locationState.navFromEventPage && !_locationState.navFromPublicProfile)) || !_locationState)) {
      this.props.getAllPeopleInOrganization(this.props.organizationId)
    }
    else if (_state.complimentIdToEdit === null) {
      this.props.getAllPeopleInOrganization(this.props.organizationId)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.newComplimentPending === true && this.props.newComplimentPending === false
      && this.props.newComplimentSuccess === true) {
      this.setState({
        modal: {
          showModal: true,
          modalTitle: t('submitted_compliment'),
          modalText: '',
          showCancelAction: false,
          modalAcceptButtonText: t('ok'),
          modalCancelButtonText: '',
          modalAcceptAction: () => this.props.history.goBack(),
          modalCancelAction: () => { },
        }
      })
    }

    if (prevProps.patchComplimentCommentPending === true && this.props.patchComplimentCommentPending === false
      && this.props.patchComplimentCommentSuccess === true) {
      this.setState({
        modal: {
          showModal: true,
          modalTitle: t('edited_compliment'),
          modalText: '',
          showCancelAction: false,
          modalAcceptButtonText: t('ok'),
          modalCancelButtonText: '',
          modalAcceptAction: () => this.props.history.goBack(),
          modalCancelAction: () => { },
        }
      })
    }

    if ((prevProps.newComplimentPending === true
      && this.props.newComplimentPending === false
      && Object.keys(this.props.newComplimentError).length)
      || (prevProps.patchComplimentCommentPending === true
        && this.props.patchComplimentCommentPending === false
        && Object.keys(this.props.patchComplimentCommentError).length)) {
      this.setState({
        modal: {
          showModal: true,
          modalTitle: t('something_went_wrong'),
          modalText: t('try_again_later'),
          showCancelAction: false,
          modalAcceptButtonText: t('ok'),
          modalCancelButtonText: '',
          modalAcceptAction: this.closeModal,
          modalCancelAction: () => { },
        }
      })
    }

    if (prevProps.profilePending === false && this.props.profilePending === true) {
      this.props.getAllPeopleInOrganization(this.props.organizationId)
    }

    if (prevProps.organizationPeoplePending === true && this.props.organizationPeoplePending === false) {
      this.setState({
        members: this.props.organizationPeople,
        showSelectPeopleModal: true,
      })
      const personIds = this.props.organizationPeople.reduce((total, person) => {
        if (!total.includes(person.personId)) {
          total.push(person.personId)
        }
        return total
      }, [])
      this.props.postListOfProfileImages(personIds, 'send-compliment')
    }
  }

  checkAccess = () => {
    if (!hasComplimentsAccess(this.props.featureAccess)) {
      return <Redirect to="/schedule" />
    }
  }

  getCoreValueImageStyle = (coreValue) => {
    let style = ''
    const { compliments } = this.state

    switch (coreValue) {
      case INTEGRITY_VALUE:
        style = compliments[coreValue] ? 'core-value-integrity-fill' : 'core-value-integrity'
        break;
      case PRIDE_VALUE:
        style = compliments[coreValue] ? 'core-value-pride-fill' : 'core-value-pride'
        break;
      case SERVICE_VALUE:
        style = compliments[coreValue] ? 'core-value-service-fill' : 'core-value-service'
        break;
      case TEAMWORK_VALUE:
        style = compliments[coreValue] ? 'core-value-teamwork-fill' : 'core-value-teamwork'
        break;
      case INNOVATION_VALUE:
        style = compliments[coreValue] ? 'core-value-innovation-fill' : 'core-value-innovation'
        break;
      case RESULTS_VALUE:
          style = compliments[coreValue] ? 'core-value-results-fill' : 'core-value-results'
          break;
      default:
        style = ''
        break;
    }

    return style
  }

  seeCoreValueLegendClick(evt) {
    evt.preventDefault()
    this.setState({ showLegendModal: !this.state.showLegendModal })
  }

  setComplimentFilter(coreValue) {
    const _state = this.state
    if (_state.complimentIdToEdit === null) {
      this.setState({
        compliments: {
          ..._state.compliments,
          [coreValue]: !_state.compliments[coreValue]
        }
      })
    }
  }

  onCommentChange(evt) {
    this.setState({ comment: evt.target.value })
  }

  submitCompliments() {
    const {
      selectedTeamMembers, eventDetails, compliments,
      comment, navFromEventPage, complimentIdToEdit
    } = this.state

    const compliment = {
      comment,
      eventId: navFromEventPage && eventDetails.scheduledEventId ? parseInt(eventDetails.scheduledEventId, 10) : null,
      complimentCoreTags: Object.keys(compliments).filter(key => compliments[key] === true),
      recipientsList: selectedTeamMembers.map(people => people.personId),
    }

    if (!compliment.complimentCoreTags.length || !compliment.recipientsList.length) {
      this.setState({
        modal: {
          showModal: true,
          modalTitle: '',
          modalText: t('submit_compliment_error'),
          showCancelAction: false,
          modalAcceptButtonText: t('ok'),
          modalCancelButtonText: '',
          modalAcceptAction: this.closeModal,
          modalCancelAction: () => { },
        }
      })
      return
    }

    if (complimentIdToEdit !== null) {
      this.props.patchComplimentComment(complimentIdToEdit, comment)
    } else {
      this.props.postComplimentAction(compliment)
    }
  }

  closeModal() {
    this.setState({
      modal: {
        showModal: false,
        modalTitle: '',
        modalText: '',
        showCancelAction: false,
        modalAcceptButtonText: '',
        modalCancelButtonText: '',
        modalAcceptAction: () => { },
        modalCancelAction: () => { },
      }
    })
  }

  handleShowSelectPeopleModal() {
    this.setState({
      showSelectPeopleModal: !this.state.showSelectPeopleModal
    })
  }

  handleSelectPeople(selectedTeamMembers) {
    this.setState({
      selectedTeamMembers,
      showSelectPeopleModal: false,
      initialPeopleSelected: true,
    })
  }

  showDetailedPeopleClick() {
    this.setState({ showDetailedPeople: !this.state.showDetailedPeople })
  }

  removePersonClick(member) {
    this.setState({
      selectedTeamMembers: this.state.selectedTeamMembers.filter(person => person.personId !== member.personId)
    })
  }

  goBackClick() {
    const { complimentIdToEdit } = this.state
    this.setState({
      modal: {
        showModal: true,
        modalTitle: complimentIdToEdit ? t('discard_edit_compliment') : t('discard_compliment'),
        modalText: complimentIdToEdit ? t('discard_edit_compliment_text') : t('discard_compliment_text'),
        showCancelAction: true,
        modalAcceptButtonText: t('ok'),
        modalCancelButtonText: t('cancel'),
        modalAcceptAction: () => this.props.history.goBack(),
        modalCancelAction: this.closeModal
      }
    })
  }

  handleNameClick(evt, personId) {
    evt.stopPropagation()
    this.props.history.push(`/public-profile/${obfuscate(personId)}`)
  }

  renderPersonsAvatar(selectedTeamMembers) {
    const { profileImages, history } = this.props
    return (
      <div className="row align-items-center inline-avatars">
        {
          selectedTeamMembers.map((person) => {
            const userAvatar = profileImages.find(obj => obj.personId === person.personId)
            return (
              <div key={person.personId} className="ml-2 mb-2">
                <PostUserAvatar
                  personName={person.employeeName}
                  userAvatarUrl={userAvatar !== undefined ? userAvatar.url : ''}
                  personId={person.personId}
                  redirectToAvatarPage={false}
                  history={history}
                />
              </div>
            )
          })
        }
        <div className="ml-3 mb-2">
          {selectedTeamMembers.length ? `${selectedTeamMembers[0].employeeName} ${selectedTeamMembers.length > 1 ? t('and_others') : ''}` : ''}
        </div>
      </div>
    )
  }

  renderDetailedPersons(selectedTeamMembers) {
    const { navFromEventPage } = this.state
    const { profileImages, history } = this.props
    return (
      <div className="w-100">
        <ul className="list-group">
          {
            selectedTeamMembers.map((member) => {
              const userAvatar = profileImages.find(obj => obj.personId === member.personId)
              return (
                <li
                  key={member.personId}
                  className="list-group-item d-flex align-items-center justify-content-between"
                >
                  <div className="d-flex" >
                    <PostUserAvatar
                      personName={member.employeeName}
                      userAvatarUrl={userAvatar !== undefined ? userAvatar.url : ''}
                      personId={member.personId}
                      redirectToAvatarPage={false}
                      history={history}
                    />
                    <div className="col d-flex flex-column justify-content-center">
                      <div
                        className="name cursor-pointer"
                        onClick={(evt) => this.handleNameClick(evt, member.personId)}
                      >
                        {member.employeeName}
                      </div>
                      {!navFromEventPage && <div className="organization-name">{member.organizationName}</div>}
                    </div>
                  </div>
                </li>
              )
            })
          }
        </ul>
      </div>
    )
  }

  renderCoreValues() {
    const { complimentIdToEdit } = this.state
    return (
      <div>
        <h5>{t('core_values')}</h5>
        <div className="row align-items-center mt-3 mb-3">
          <div className="col-2 col-md-auto">
            <div
              className={`${complimentIdToEdit === null && 'cursor-pointer'} ${this.getCoreValueImageStyle(SERVICE_VALUE)}`}
              onClick={() => this.setComplimentFilter(SERVICE_VALUE)}
              title={t('service')}
              />
          </div>
          <div className="col-2 col-md-auto">
            <div
              className={`${complimentIdToEdit === null && 'cursor-pointer'} ${this.getCoreValueImageStyle(PRIDE_VALUE)}`}
              onClick={() => this.setComplimentFilter(PRIDE_VALUE)}
              title={t('pride')}
              />
          </div>
          <div className="col-2 col-md-auto">
            <div
              className={`${complimentIdToEdit === null && 'cursor-pointer'} ${this.getCoreValueImageStyle(INTEGRITY_VALUE)}`}
              onClick={() => this.setComplimentFilter(INTEGRITY_VALUE)}
              title={t('integrity')}
            />
          </div>
          <div className="col-2 col-md-auto">
            <div
              className={`${complimentIdToEdit === null && 'cursor-pointer'} ${this.getCoreValueImageStyle(RESULTS_VALUE)}`}
              onClick={() => this.setComplimentFilter(RESULTS_VALUE)}
              title={t('results')}
              />
          </div>
          <div className="col-2 col-md-auto">
            <div
              className={`${complimentIdToEdit === null && 'cursor-pointer'} ${this.getCoreValueImageStyle(INNOVATION_VALUE)}`}
              onClick={() => this.setComplimentFilter(INNOVATION_VALUE)}
              title={t('innovation')}
              />
          </div>
          <div className="col-2 col-md-auto">
            <div
              className={`${complimentIdToEdit === null && 'cursor-pointer'} ${this.getCoreValueImageStyle(TEAMWORK_VALUE)}`}
              onClick={() => this.setComplimentFilter(TEAMWORK_VALUE)}
              title={t('teamwork')}
            />
          </div>
          <div className="col-2 col-md-7" />
        </div>
        <p>{t('select_core_values')}</p>
        <div className="cursor-pointer" onClick={this.seeCoreValueLegendClick}>
          <a href='https://wisintl.com/about-us/' className="core-values-legend">{t('core_values_legend').toUpperCase()}</a>
        </div>
      </div>
    )
  }

  renderPageButtons() {
    const { complimentIdToEdit } = this.state
    return (
      <div className="d-flex justify-content-end align-items-center">
        <div className="ml-2">
          <input
            type="button"
            className="m-0 btn btn-rgis-blue cursor-pointer uppercase w-100"
            onClick={this.goBackClick}
            value={complimentIdToEdit === null ? t('back') : t('cancel')}
          />
        </div>
        <div className="ml-2">
          <input
            type="button"
            className="m-0 btn btn-rgis-blue cursor-pointer uppercase w-100"
            onClick={this.submitCompliments}
            value={complimentIdToEdit === null ? t('done') : t('save')}
          />
        </div>
      </div>
    )
  }

  renderEventDetailsHeader(scheduleData) {
    const eventName = scheduleData.bannerName === null || scheduleData.bannerName === undefined
      ? scheduleData.title
      : scheduleData.bannerName

    return (<h5>{eventName} #{scheduleData.storeNumber}</h5>)
  }

  render() {
    const {
      selectedTeamMembers, showLegendModal, comment,
      modal, showDetailedPeople, complimentIdToEdit,
      navFromEventPage, navFromSentComplimentsPage, eventDetails,
      showSelectPeopleModal, members, initialPeopleSelected
    } = this.state

    const {
      newComplimentPending, featureAccessPending, patchComplimentCommentPending,
      profilePending, organizationPeoplePending, profileImagesPending,
      history, profileImages,
    } = this.props
    if (newComplimentPending || featureAccessPending || patchComplimentCommentPending
      || profilePending || organizationPeoplePending || profileImagesPending
    ) {
      return <Preloader />
    }

    this.checkAccess()

    return (
      <div className="send-compliments-container" >
        <div className="d-flex align-items-center justify-content-between send-compliments-width">
          <div className="pl-0 compliments-header">{complimentIdToEdit !== null ? t('edit_comment') : t('send_compliments')}</div>
          <div className="d-flex align-items-center justify-content-end">
            <i
              onClick={this.goBackClick}
              className="material-icons large arrow_back cursor-pointer other-avail-icon"
            >
              arrow_back
            </i>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <div className="section">
                  {navFromEventPage && this.renderEventDetailsHeader(eventDetails.scheduleData)}
                  <div className="row my-2">
                    <div className="col-12 d-flex align-items-center justify-content-between">
                      <h5>{selectedTeamMembers.length} {t('people')}</h5>
                      <div>
                        {
                          complimentIdToEdit === null &&
                          <i
                            className="material-icons medium edit cursor-pointer ml-4"
                            onClick={this.handleShowSelectPeopleModal}
                          >
                            edit
                          </i>
                        }
                        <i
                          className="material-icons medium arrow_upward cursor-pointer ml-3"
                          onClick={this.showDetailedPeopleClick}
                        >
                          {showDetailedPeople ? 'arrow_upward' : 'arrow_downward'}
                        </i>
                      </div>
                    </div>
                    <div className="col-12">
                      <hr />
                      <div className="col-12">
                        {showDetailedPeople ? this.renderDetailedPersons(selectedTeamMembers) : this.renderPersonsAvatar(selectedTeamMembers)}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="section mt-5">
                  {this.renderCoreValues()}
                </div>

                <div className="section mt-5">
                  <div className="form-group">
                    <label htmlFor="message_body">{t('comment')}</label>
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder={t('type_comment')}
                      maxLength="500"
                      value={comment}
                      onChange={this.onCommentChange}
                    />
                  </div>
                </div>
                {this.renderPageButtons()}
              </div>
            </div>
          </div>
        </div>

        <ActionModal {...modal} />
        <CoreValuesLegendModal
          showModal={showLegendModal}
          modalAcceptAction={this.seeCoreValueLegendClick}
        />

        <SelectComplimentPeopleModal
          showModal={showSelectPeopleModal}
          closeModal={this.handleShowSelectPeopleModal}
          members={members}
          profileImages={profileImages}
          eventDetails={eventDetails}
          navFromEventPage={navFromEventPage}
          navFromSentComplimentsPage={navFromSentComplimentsPage}
          handleSelectPeople={this.handleSelectPeople}
          initialPeopleSelected={initialPeopleSelected}
          goBackClick={this.goBackClick}
          history={history}
          initialPeople={selectedTeamMembers}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    newComplimentPending: state.complimentsReducer.newComplimentPending,
    newComplimentError: state.complimentsReducer.newComplimentError,
    newComplimentSuccess: state.complimentsReducer.newComplimentSuccess,
    featureAccessPending: state.featureAccessReducer.featureAccessPending,
    featureAccess: state.featureAccessReducer.featureAccess,
    patchComplimentCommentPending: state.complimentsReducer.patchComplimentCommentPending,
    patchComplimentCommentError: state.complimentsReducer.patchComplimentCommentError,
    patchComplimentCommentSuccess: state.complimentsReducer.patchComplimentCommentSuccess,
    organizationId: state.loginReducer.profile.organizationId,
    organizationPeople: state.organizationsReducer.organizationPeople,
    organizationPeoplePending: state.organizationsReducer.organizationPeoplePending,
    profilePending: state.loginReducer.profilePending,
    profileImages: state.profileReducer.profileImages,
    profileImagesPending: state.profileReducer.profileImagesPending['send-compliment'],
    profileImagesError: state.profileReducer.profileImagesError,
    profileLocale: state.loginReducer.profileLocale,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      postComplimentAction: (compliment) => postCompliment(compliment),
      patchComplimentComment: (complimentId, comments) => patchComplimentComment(complimentId, comments),
      getAllPeopleInOrganization: (organizationId) => getAllPeopleInOrganization(organizationId),
      postListOfProfileImages: (personIds, location) => postListOfProfileImages(personIds, location),
    },
    dispatch
  )
}

SendComplimentsContainer.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  postComplimentAction: PropTypes.func,
  newComplimentPending: PropTypes.bool,
  newComplimentError: PropTypes.object,
  newComplimentSuccess: PropTypes.bool,
  featureAccessPending: PropTypes.bool,
  featureAccess: PropTypes.object,
  patchComplimentComment: PropTypes.func,
  patchComplimentCommentPending: PropTypes.bool,
  patchComplimentCommentError: PropTypes.object,
  patchComplimentCommentSuccess: PropTypes.bool,
  getAllPeopleInOrganization: PropTypes.func,
  organizationPeople: PropTypes.array,
  organizationPeoplePending: PropTypes.bool,
  organizationId: PropTypes.string,
  profilePending: PropTypes.bool,
  postListOfProfileImages: PropTypes.func,
  profileImages: PropTypes.array,
  profileImagesPending: PropTypes.bool,
  profileImagesError: PropTypes.object,
  profileLocale: PropTypes.string,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SendComplimentsContainer)
