import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import axios from 'axios';

import { getChatMessages, addChatMessage, editChatMessage, resetState, refreshChatMessages } from '../../state/actions/eventChatActions'
import { uploadFilePromise, deleteFilePromise } from '../../components/utils/utils'
import { getModerationReasons } from '../../state/actions/moderationActions'
import { t } from '../../utils/i18n'
import { getLoggedInPersonId } from '../../components/utils/localStorageUtils'
import { hasChatAccess } from '../../components/utils/featureAccessUtils'
import { getUserReactions } from '../../state/actions/reactionsActions'
import { getChannelAccess } from '../../state/actions/channelActions'
import { postListOfProfileImages } from '../../state/actions/profileActions'
import { updateNewChatMessagesCount } from '../../state/actions/scheduleActions'
import { getNewPostCount } from '../../state/actions/postCardActions'

import Preloader from '../../components/Preloader'
import EventChatMessage from '../../components/EventChatMessage'
import ActionModal from '../../components/ActionModal'
import FileUpload from '../../components/FileUpload'
import paperclipImg from '../../styles/images/paperclip-solid.svg'
import videoImg from '../../styles/images/film-solid.svg'
import imageImg from '../../styles/images/image-solid.svg'
import pdfImg from '../../styles/images/file-pdf-solid.svg'

import './EventChatContainer.scss'

const MAX_FILE_SIZE = 500000000
const MAX_UPLOADING_FILES = {
  video: 1,
  pdf: 5,
  image: 5
}
const CANCEL_REQUEST_MSG = 'Cancel file upload request'

let firstMessageId = -1
let _isMounted = false
let noOfMediaLoaded = 0
let noOfAllMedia = 0

class EventChatContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      chatMessages: [],
      messageBody: '',
      beginningOfChat: false,

      editMessage: {
        editing: false,
        messageInfo: {},
      },

      attachments: [],
      fileUploadError: {
        error: false,
        message: ''
      },
      countFileTypes: {
        video: 0,
        image: 0,
        pdf: 0,
      },

      messagesLoading: false,
      unreadMessageId: -1,
      scrollDownIfLoaded: false,
      showScrollDownButton: false,
      showMedia: false,
      allMediaLoaded: false,

      modal: {
        showModal: false,
        modalTitle: '',
        modalText: '',
        showCancelAction: false,
        modalAcceptButtonText: '',
        modalCancelButtonText: '',
        modalAcceptAction: null,
        modalCancelAction: null,
      },
    }

    this.chatRef = React.createRef()
    this.inputRef = React.createRef()

    this.onChatScroll = this.onChatScroll.bind(this)

    this.renderAddMessageBar = this.renderAddMessageBar.bind(this)
    this.renderChatMessages = this.renderChatMessages.bind(this)

    this.onFieldChange = this.onFieldChange.bind(this)
    this.cancelModalAction = this.cancelModalAction.bind(this)
    this.sendMessageAction = this.sendMessageAction.bind(this)
    this.editPostClick = this.editPostClick.bind(this)
    this.onMediaClick = this.onMediaClick.bind(this)
    this.renderUploadFiles = this.renderUploadFiles.bind(this)
    this.handleDropzoneChange = this.handleDropzoneChange.bind(this)
    this.removeFile = this.removeFile.bind(this)
    this.checkAllUploadsDone = this.checkAllUploadsDone.bind(this)
    this.checkIfMessageCanBeSent = this.checkIfMessageCanBeSent.bind(this)
    this.scrollToBottom = this.scrollToBottom.bind(this)
    this.onMediaLoad = this.onMediaLoad.bind(this)
    this.onRefreshClick = this.onRefreshClick.bind(this)
  }

  onChatScroll(evt) {
    const { chatMessagesPending, beginningOfChat } = this.props
    const { allMediaLoaded } = this.state
    if ((chatMessagesPending || !allMediaLoaded) && firstMessageId === -1) {
      this.chatRef.current.scroll(0, 0)
      return
    }
    if (evt.target.scrollTop === 0 && !beginningOfChat && !chatMessagesPending && allMediaLoaded) {
      this.setState({
        messagesLoading: true,
        allMediaLoaded: false,
      })
      this.props.getChatMessages(firstMessageId)
    }

    let showScrollDownButton = false
    if (this.chatRef.current && (this.chatRef.current.scrollHeight - this.chatRef.current.scrollTop) !== this.chatRef.current.clientHeight) {
      showScrollDownButton = true
    }
    this.setState({ showScrollDownButton })
  }

  onMediaLoad() {
    noOfMediaLoaded += 1
    if (noOfMediaLoaded === noOfAllMedia) {
      this.setState({ allMediaLoaded: true })
    }
    return
  }

  scrollToBottom() {
    if (this.chatRef && this.chatRef.current) {
      setTimeout(() => {
        this.chatRef.current.scroll(0, this.chatRef.current.scrollHeight)
      }, 1);
    }
  }

  componentDidMount() {
    _isMounted = true
    const { chatItem } = this.props
    this.props.getChannelAccess(chatItem.channelId)

    // when this component is mounted we already have the chatId
    this.props.getChatMessages(firstMessageId)
    this.props.getModerationReasons()
  }

  checkRefreshMessages = (prevProps) => {
    if (prevProps.newPostCountPending === true && this.props.newPostCountPending === false) {
      this.props.refreshChatMessages(this.props.newPostCount)
    }
  }

  componentDidUpdate(prevProps) {
    const _state = this.state

    this.checkRefreshMessages(prevProps)

    if (this.state.scrollDownIfLoaded === true && this.state.allMediaLoaded === true) {
      if (firstMessageId === -1) { // scroll down only first time and when we have new messages
        const { chatItem, chatMessages } = this.props
        if (chatItem.newPostCount) { //if new message, scroll to it
          if (chatItem.newPostCount <= chatMessages.length) {
            let unreadMessageIndex = chatMessages.length - chatItem.newPostCount
            let unreadMessageId = -1

            // i will search for other user last message
            for (let index = unreadMessageIndex; index < chatMessages.length; index += 1) {
              if (chatMessages[index].personId !== parseInt(getLoggedInPersonId(), 10)) {
                unreadMessageId = chatMessages[index].postId
                break
              }
            }

            if (unreadMessageId === -1) { // if the last message is mine, scroll to bottom
              this.scrollToBottom();

              firstMessageId = this.props.chatMessages.length ? this.props.chatMessages[0].postId : -1
              _state.scrollDownIfLoaded = false

              this.setState(_state)
              return
            }

            _state.unreadMessageId = unreadMessageId
            const message = document.querySelector(
              ".restore-" + unreadMessageId
            )
            if (message) {
              message.scrollIntoView({ block: "center" })
            }
          }
        } else { // else scroll to bottom because we dont have any new messages
          this.scrollToBottom();
        }
      } else { //scroll to last oldest known message when loading old messages
        const message = document.querySelector(
          ".restore-" + firstMessageId
        )
        if (message) {
          message.scrollIntoView({ block: "center" })
        }
      }

      firstMessageId = this.props.chatMessages.length ? this.props.chatMessages[0].postId : -1
      _state.scrollDownIfLoaded = false

      this.setState(_state)
    }

    if ((!Object.keys(prevProps.chatItemError).length && Object.keys(this.props.chatItemError).length)
      || (!Object.keys(prevProps.chatMessagesError).length && Object.keys(this.props.chatMessagesError).length)
      || (!Object.keys(prevProps.postChatMessageError).length && Object.keys(this.props.postChatMessageError).length)
      || (!Object.keys(prevProps.editChatMessageError).length && Object.keys(this.props.editChatMessageError).length)
      || (!Object.keys(prevProps.deleteChatMessageError).length && Object.keys(this.props.deleteChatMessageError).length)
      || (!Object.keys(prevProps.postModerationItemError).length && Object.keys(this.props.postModerationItemError).length)) {
      _state.modal = {
        showModal: true,
        modalTitle: t('error'),
        modalText: t('try_again_later'),
        showCancelAction: false,
        modalAcceptButtonText: t('ok'),
        modalCancelButtonText: '',
        modalAcceptAction: this.cancelModalAction,
        modalCancelAction: null,
      }
      this.setState(_state)
      return
    }

    if (prevProps.chatMessagesPending === true && this.props.chatMessagesPending === false) {
      const postIds = this.props.lastReceivedChatMessages.reduce((total, message) => {
        if (message.likeCount !== 0 || message.dislikeCount !== 0) {
          total.push(message.postId)
        }
        return total
      }, [])
      const { chatItem } = this.props
      this.props.getUserReactions(postIds, 'chat')
      const personIds = this.props.lastReceivedChatMessages.reduce((total, message) => {
        if (!total.includes(message.personId)) {
          total.push(message.personId)
        }
        return total
      }, [])
      this.props.postListOfProfileImages(personIds, 'chat')
      if (firstMessageId === -1) {
        this.props.updateNewChatMessagesCount(this.props.newChatMessages - chatItem.newPostCount, this.props.scheduledStoreId)
      }
    }

    if (prevProps.userReactionsPending === true && this.props.userReactionsPending === false) {
      _state.chatMessages = this.props.chatMessages
      _state.scrollDownIfLoaded = true
      _state.messagesLoading = false
      _state.beginningOfChat = this.props.beginningOfChat

      noOfAllMedia = 0
      noOfMediaLoaded = 0
      this.props.lastReceivedChatMessages.map(message => {
        if (message.postAttachments.length) {
          message.postAttachments.map(attachment => {
            if (attachment.contentType !== 'pdf') {
              noOfAllMedia += 1
            }
            return null;
          })
        }
        return null
      })
      if (noOfAllMedia === 0) {
        _state.allMediaLoaded = true
      }
      this.setState(_state)
    }

    if ((prevProps.postChatMessagePending === true && this.props.postChatMessagePending === false)
      || (prevProps.editChatMessagePending === true && this.props.editChatMessagePending === false)) {
      _state.chatMessages = this.props.chatMessages
      _state.messageBody = ''

      _state.attachments = []
      _state.fileUploadError = {
        error: false,
        message: ''
      }
      _state.countFileTypes = {
        video: 0,
        image: 0,
        pdf: 0,
      }
      _state.showMedia = false
      this.setState(_state)

      if (prevProps.postChatMessagePending === true && this.props.postChatMessagePending === false) {
        this.scrollToBottom();
      }
    }

    if (prevProps.deleteChatMessagePending === true && this.props.deleteChatMessagePending === false) {
      _state.chatMessages = this.props.chatMessages
      const message = document.querySelector(
        ".restore-" + this.props.scrollToMessageId
      )
      if (message) {
        message.scrollIntoView({ block: "center" })
      }
      this.setState(_state)
    }

    if (prevProps.postModerationItemPending === true && this.props.postModerationItemPending === false) {
      _state.chatMessages = this.props.chatMessages
      _state.modal = {
        showModal: true,
        modalTitle: t('flag_post'),
        modalText: t('flag_post_submitted'),
        showCancelAction: false,
        modalAcceptButtonText: t('ok'),
        modalCancelButtonText: '',
        modalAcceptAction: this.cancelModalAction,
        modalCancelAction: null,
      }
      this.setState(_state)
      const message = document.querySelector(
        ".restore-" + this.props.scrollToMessageId
      )
      if (message) {
        message.scrollIntoView({ block: "center" })
      }
    }
  }

  componentWillUnmount() {
    _isMounted = false
    firstMessageId = -1
    const { attachments } = this.state
    attachments.map((file) => {
      if (file.newUpload && !file.uploadDone) {
        file.fileRequestToken.cancel(CANCEL_REQUEST_MSG)
      }
      return ''
    })

    this.props.resetState()
  }

  cancelModalAction(evt) {
    evt.stopPropagation()
    this.setState({
      modal: {
        showModal: false,
        modalTitle: '',
        modalText: '',
        showCancelAction: false,
        modalAcceptButtonText: '',
        modalCancelButtonText: '',
        modalAcceptAction: null,
        modalCancelAction: null,
      }
    })
  }

  onFieldChange(evt) {
    const _state = this.state
    const { value, dataset } = evt.target
    _state[dataset.field] = value
    this.setState(_state)
  }

  checkAllUploadsDone() {
    const { attachments } = this.state
    let ok = true
    Object.keys(attachments).map((key) => {
      if (attachments[key].newUpload && !attachments[key].uploadDone) {
        ok = false
      }
      return ''
    })
    return ok
  }

  checkIfMessageCanBeSent() {
    const { messageBody, attachments } = this.state
    if (attachments.length && !this.checkAllUploadsDone()) { //if we have uploading files, we cannot send the message
      return false
    }
    if (messageBody.length === 0 && attachments.length === 0) { //if no message and no files to send, we cannot send the message
      return false
    }
    return true
  }

  sendMessageAction() {
    const { editMessage, messageBody, attachments } = this.state

    if (!this.checkIfMessageCanBeSent()) return;

    if (editMessage.editing) {
      const messageObj = {
        ...editMessage.messageInfo,
        body: messageBody,
        postAttachments: attachments.map(att => att.file),
      }
      this.props.editChatMessage(messageObj)
      this.setState({
        editMessage: {
          editing: false,
          messageInfo: {},
        }
      })
    } else {
      const messageObj = {
        body: messageBody,
        commentsEnabled: false,
        likesEnabled: true,
        dislikesEnabled: true,
        hideFromHourlyTeamMembers: false,
        postAttachments: attachments.map(att => att.file),
      }
      this.props.addChatMessage(messageObj)
    }
  }

  editPostClick(messageInfo) {
    const _state = this.state

    if (messageInfo.postAttachments.length) {
      _state.showMedia = true
      _state.attachments = messageInfo.postAttachments.map((file, index) =>
        ({ fileIndex: index, file, newUpload: false, uploadDone: null, uploadPercentage: null, error: false })
      )
      messageInfo.postAttachments.map((file) => _state.countFileTypes[file.contentType] += 1)
    }

    _state.messageBody = messageInfo.body
    _state.editMessage = {
      editing: true,
      messageInfo
    }

    this.inputRef.current.focus();
    this.setState(_state)
  }

  onRefreshClick() {
    const { chatItem } = this.props
    const refreshSinceDate = '1/1/2010'
    this.props.getNewPostCount(chatItem.channelId, refreshSinceDate)
  }

  onMediaClick() {
    this.setState({ showMedia: !this.state.showMedia })
  }

  renderChatMessages(chatMessages) {
    const { editing, messageInfo } = this.state.editMessage
    const { unreadMessageId } = this.state
    const { beginningOfChat } = this.state
    const { userChannelAccess, profileImages } = this.props
    return (
      <div className="chat-messages-list">
        {beginningOfChat && <div className="font-italic text-center">{t('chat_begin_text')}</div>}
        {
          chatMessages.map((message, index) => {
            return (
              <div
                key={message.postId}
                className={`restore-${message.postId}`}
              >
                {unreadMessageId !== -1 && unreadMessageId === message.postId ?
                  <div className="row align-items-center justify-content-center my-3 unread-messages ">
                    <div className="unread-line" />
                    <div className="mx-2 unread-text">{t('unread_messages', [`(${this.props.chatMessages.length - index})`])}</div>
                    <div className="unread-line" />
                  </div>
                  :
                  ''
                }

                <EventChatMessage
                  editing={editing === true && messageInfo.postId === message.postId}
                  messageInfo={message}
                  userAvatar={profileImages.find(obj => obj.personId === message.personId)}
                  editPostClick={this.editPostClick}
                  history={this.props.history}
                  onMediaLoad={this.onMediaLoad}
                  channelAccess={userChannelAccess}
                />
              </div>
            )
          })
        }
      </div>
    )
  }

  handleDropzoneChange(filesAccepted, filesRejected, fileIndex, contentType) {
    const _state = this.state
    _state.fileUploadError = {
      error: false,
      message: ''
    }
    //checking max number of uploaded file types
    if (_state.countFileTypes[contentType] + 1 > MAX_UPLOADING_FILES[contentType]) {
      let message = '';
      switch (contentType) {
        case 'video':
          message = t('video_error_message')
          break
        case 'pdf':
          message = t('pdf_error_message')
          break
        case 'image':
          message = t('image_error_message')
          break
        default:
          break
      }
      _state.fileUploadError = {
        error: true,
        message
      }

      this.setState(_state)
      return
    }

    _state.countFileTypes[contentType] += 1

    if (filesRejected.length > 0) {
      if (filesRejected[0].size > MAX_FILE_SIZE) {
        const _state = this.state
        _state.fileUploadError = {
          error: true,
          message: `${t('file_too_large')} ${MAX_FILE_SIZE / 1000000} MB`
        }
        this.setState(_state)
      }
    } else {
      const file = filesAccepted[0]
      const fileSize = file.size
      const fileRequestToken = axios.CancelToken.source()
      const newFileObj = {
        fileIndex,
        file: {
          fileName: file.name,
          contentType
        },
        newUpload: true,
        uploadDone: false,
        uploadPercentage: 0,
        error: false,
        fileRequestToken
      }
      _state.attachments.push(newFileObj)
      this.setState(_state);

      const onUploadProgress = (progressEvent) => {
        if (_isMounted === false) return
        const _state = this.state
        let uploadPercentage = Math.round(progressEvent.loaded * 100 / fileSize)
        if (uploadPercentage >= 100) {
          uploadPercentage = 99
        }
        const index = _state.attachments.findIndex((att) => att.fileIndex === fileIndex)
        _state.attachments[index] = {
          ..._state.attachments[index],
          uploadPercentage,
        }

        this.setState(_state)
      }
      uploadFilePromise(file, onUploadProgress, fileRequestToken)
        .then((response) => {
          const _state = this.state
          const index = _state.attachments.findIndex((att) => att.fileIndex === fileIndex)
          _state.attachments[index] = {
            ..._state.attachments[index],
            file: {
              ..._state.attachments[index].file,
              ...response.data
            },
            uploadDone: true,
          }
          this.setState(_state)
        }).catch((error) => {
          if (error.message !== CANCEL_REQUEST_MSG) {
            const _state = this.state
            const index = _state.attachments.findIndex((att) => att.fileIndex === fileIndex)
            _state.attachments[index] = {
              ..._state.attachments[index],
              error: true
            }
            this.setState(_state)
          }
        })
    }
  }

  removeFile(att) {
    const _state = this.state
    _state.attachments = _state.attachments.filter((mapFile) => mapFile.fileIndex !== att.fileIndex)
    _state.countFileTypes[att.file.contentType] -= 1
    _state.fileUploadError = {
      error: false,
      message: ''
    }
    deleteFilePromise(att.file.previewStorageUrl)
    deleteFilePromise(att.file.storageUrl)
    this.setState(_state)
  }

  renderUploadFiles() {
    const { attachments } = this.state
    const { fileUploadError } = this.state
    const lastIndex = attachments.length ? attachments[attachments.length - 1].fileIndex : 0
    return (
      <div className="w-100 ">
        <div className="row no-gutters justify-content-around">
          <div className="col-3">
            <FileUpload
              error={false}
              file={{}}
              accept={['video/*',]}
              onDrop={this.handleDropzoneChange}
              contentType="video"
              image={videoImg}
              fileIndex={lastIndex + 1}
              maxFileSize={MAX_FILE_SIZE}
            />
          </div>
          <div className="col-3">
            <FileUpload
              error={false}
              file={{}}
              accept={['image/jpg', 'image/png', 'image/jpeg']}
              onDrop={this.handleDropzoneChange}
              contentType="image"
              image={imageImg}
              fileIndex={lastIndex + 1}
              maxFileSize={MAX_FILE_SIZE}
            />
          </div>
          <div className="col-3">
            <FileUpload
              error={false}
              file={{}}
              accept={['.pdf']}
              onDrop={this.handleDropzoneChange}
              contentType="pdf"
              image={pdfImg}
              fileIndex={lastIndex + 1}
              maxFileSize={MAX_FILE_SIZE}
            />
          </div>
        </div>
        {fileUploadError.error && <div className="mt-3 text-center upload-error">{t('attachment_limitation')}</div>}
        {fileUploadError.error && <div className="mt-1 text-center upload-error">{fileUploadError.message}</div>}
        <div>
          {attachments.map((att, index) => {
            return (
              <div className="row mt-2" key={index}>
                <div className="col d-flex align-items-center justify-content-center">
                  <div className="file-name">{att.file.fileName.length > 20 ? `${att.file.fileName.slice(0, 20)}...` : att.file.fileName}</div>
                  {(!att.newUpload || att.uploadDone) ?
                    <i
                      className="material-icons delete cursor-pointer other-avail-icon ml-3"
                      onClick={() => this.removeFile(att)}
                    >
                      delete
                    </i>
                    :
                    <div className="ml-3 d-flex align-items-center">
                      <div className="spinner-border mr-2" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                      {att.uploadPercentage} %
                    </div>
                  }
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  renderAddMessageBar() {
    const { messageBody, showMedia, showScrollDownButton } = this.state
    return (
      <div className="p-1 chat-add-message-bar">
        {showScrollDownButton &&
          <div className="scroll-to-bottom" onClick={this.scrollToBottom}>
            <i className="material-icons">arrow_downward</i>
          </div>
        }
        {showMedia &&
          <div className="d-flex justify-content-center chat-media">
            {this.renderUploadFiles()}
          </div>
        }
        <div className="d-flex align-items-center mt-1">
          <img className="paperclip cursor-pointer ml-2 mr-3" src={paperclipImg} alt="paperClip" onClick={this.onMediaClick} />
          <input
            type="text"
            id="chat_message"
            className="form-control"
            value={messageBody}
            data-field="messageBody"
            onChange={this.onFieldChange}
            placeholder={"Type a message..."}
            maxLength="2000"
            autoComplete="off"
            onKeyPress={(e) => { e.which === 13 && this.sendMessageAction() }}
            ref={this.inputRef}
          />
          <div className="ml-2 mr-1 d-flex align-items-center send-message" >
            <i
              className={`material-icons send ${!this.checkIfMessageCanBeSent() ? 'disabled' : 'cursor-pointer'}`}
              onClick={this.sendMessageAction}>
              send
            </i>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { chatMessages, modal, messagesLoading, allMediaLoaded } = this.state
    const {
      chatMessagesPending,
      deleteChatMessagePending,
      postModerationItemPending,
      moderationReasonsPending,
      userReactionsPending,
      userChannelAccessPending,
      profileImagesPending,
      featureAccess,
      newPostCountPending,
    } = this.props

    if (!hasChatAccess(featureAccess)) {
      return ''
    }

    if ((chatMessagesPending && firstMessageId === -1)
      || moderationReasonsPending
      || deleteChatMessagePending
      || postModerationItemPending
      || (userReactionsPending && firstMessageId === -1)
      || userChannelAccessPending
      || profileImagesPending
      || newPostCountPending
    ) {
      return (
        <div className="card event-chat-container">
          <Preloader insideDiv />
        </div>
      )
    }

    return (
      <div>
        <div className='div-refresh'><span className='span-refresh'>{t('refresh_chat')}</span><i onClick={this.onRefreshClick} className="material-icons refresh btn-refresh">refresh</i></div>
        <div className="card event-chat-container" ref={this.chatRef} onScroll={this.onChatScroll}>        
          <div className="card-body">
            <div className="section">
              <div>
                {(!allMediaLoaded || messagesLoading) && <Preloader insideDiv />}
                {this.renderChatMessages(chatMessages)}
              </div>

              <ActionModal
                {...modal}
              />
            </div>
          </div>
          {this.renderAddMessageBar()}
        </div>
      </div>      
    )
  }
}

function mapStateToProps(state) {
  return {
    beginningOfChat: state.eventChatReducer.beginningOfChat,
    chatItem: state.eventChatReducer.chatItem,
    chatItemError: state.eventChatReducer.chatItemError,
    chatItemPending: state.eventChatReducer.chatItemPending,
    lastReceivedChatMessages: state.eventChatReducer.lastReceivedChatMessages,

    chatMessages: state.eventChatReducer.chatMessages,
    chatMessagesError: state.eventChatReducer.chatMessagesError,
    chatMessagesPending: state.eventChatReducer.chatMessagesPending,

    postChatMessagePending: state.eventChatReducer.postChatMessagePending,
    postChatMessageError: state.eventChatReducer.postChatMessageError,

    editChatMessagePending: state.eventChatReducer.editChatMessagePending,
    editChatMessageError: state.eventChatReducer.editChatMessageError,

    deleteChatMessagePending: state.eventChatReducer.deleteChatMessagePending,
    deleteChatMessageError: state.eventChatReducer.deleteChatMessageError,

    newChatMessages: state.scheduleReducer.newChatMessages,

    moderationReasonsPending: state.moderationReducer.moderationReasonsPending,
    postModerationItemPending: state.moderationReducer.postModerationItemPending,
    postModerationItemError: state.moderationReducer.postModerationItemError,
    scrollToMessageId: state.eventChatReducer.scrollToMessageId,

    userReactionsPending: state.reactionsReducer.userReactionsPending['chat'],

    userChannelAccess: state.channelReducer.userChannelAccess,
    userChannelAccessPending: state.channelReducer.userChannelAccessPending,
    userChannelAccessError: state.channelReducer.userChannelAccessError,

    profileImages: state.profileReducer.profileImages,
    profileImagesPending: state.profileReducer.profileImagesPending['chat'],
    profileImagesError: state.profileReducer.profileImagesError,

    featureAccess: state.featureAccessReducer.featureAccess,
    newPostCount: state.postCardReducer.newPostCount,
    newPostCountPending: state.postCardReducer.newPostCountPending,
    newPostCountError: state.postCardReducer.newPostCountError,

    profileLocale: state.loginReducer.profileLocale,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      refreshChatMessages: (newPostCount) => refreshChatMessages(newPostCount),
      getChatMessages: (firstMessageId) => getChatMessages(firstMessageId),
      addChatMessage: (messageObj) => addChatMessage(messageObj),
      editChatMessage: (messageObj) => editChatMessage(messageObj),
      getUserReactions: (postIds, category) => getUserReactions(postIds, category),
      getModerationReasons: () => getModerationReasons(),
      resetState: () => resetState(),
      getChannelAccess: (channelId) => getChannelAccess(channelId),
      postListOfProfileImages: (personIds, location) => postListOfProfileImages(personIds, location),
      updateNewChatMessagesCount: (newChatMessages, scheduledStoreId) => updateNewChatMessagesCount(newChatMessages, scheduledStoreId),
      getNewPostCount: (channelId, sinceDateTime) => getNewPostCount(channelId, sinceDateTime),
    },
    dispatch
  )
}

EventChatContainer.propTypes = {
  eventDetails: PropTypes.object,
  history: PropTypes.object,

  refreshChatMessages: PropTypes.func,
  getChatMessages: PropTypes.func,
  addChatMessage: PropTypes.func,
  editChatMessage: PropTypes.func,
  deleteChatMessage: PropTypes.func,
  getModerationReasons: PropTypes.func,
  resetState: PropTypes.func,

  beginningOfChat: PropTypes.bool,
  chatItem: PropTypes.object,
  chatItemPending: PropTypes.bool,
  chatItemError: PropTypes.object,
  lastReceivedChatMessages: PropTypes.array,

  chatMessages: PropTypes.array,
  chatMessagesError: PropTypes.object,
  chatMessagesPending: PropTypes.bool,

  postChatMessagePending: PropTypes.bool,
  postChatMessageError: PropTypes.object,

  editChatMessagePending: PropTypes.bool,
  editChatMessageError: PropTypes.object,

  deleteChatMessagePending: PropTypes.bool,
  deleteChatMessageError: PropTypes.object,

  newChatMessages: PropTypes.number,
  updateNewChatMessagesCount: PropTypes.func,

  moderationReasonsPending: PropTypes.bool,
  postModerationItemPending: PropTypes.bool,
  postModerationItemError: PropTypes.object,
  scrollToMessageId: PropTypes.number,

  getUserReactions: PropTypes.func,
  userReactionsPending: PropTypes.bool,

  getChannelAccess: PropTypes.func,
  userChannelAccess: PropTypes.object,
  userChannelAccessPending: PropTypes.bool,

  postListOfProfileImages: PropTypes.func,
  profileImages: PropTypes.array,
  profileImagesPending: PropTypes.bool,
  profileImagesError: PropTypes.object,

  featureAccess: PropTypes.object,
  newPostCount: PropTypes.string,
  newPostCountPending: PropTypes.bool,
  newPostCountError: PropTypes.object,
  getNewPostCount: PropTypes.func,

  scheduledStoreId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),

  profileLocale: PropTypes.string,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventChatContainer)
