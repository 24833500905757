// to be used for obfuscating a string only not for encrypting
export function obfuscate(stringToObfuscate) {
  return btoa(stringToObfuscate)
}

export function canDeObfuscate(stringToDeObfuscated) {
  try {
    atob(stringToDeObfuscated)
  } catch (ex) {
    return false 
  }

  return true
}

// to be used for obfuscating a string only not for decrypting
export function deObfuscate(stringToDeObfuscated) {
  try {
    return atob(stringToDeObfuscated)
  } catch (ex) {
    // TODO: call logger    
  }

  return null
}