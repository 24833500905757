import React from 'react'
import PropTypes from 'prop-types'

import { t } from '../../utils/i18n'

import './SupervisorInfo.scss'
import { formatPhoneNumber } from '../utils/phoneUtils'
import { obfuscate } from '../utils/obfuscateUtils'
import PostUserAvatar from '../PostUserAvatar'

function getSupervisorInfo(eventDetails, userAvatar, history) {
  if (eventDetails.supervisorName) {
    return (
      <div className="col">
        <div className="row">
            <div className="heading capitalize">{t('supervisor')} </div>
        </div>
        <div className="row">
          <PostUserAvatar
            personName={eventDetails.supervisorName}
            personId={eventDetails.supervisorPersonId}
            userAvatarUrl={userAvatar !== undefined ? userAvatar.url : ''}
            redirectToAvatarPage={false}
            history={history}
          />
          <div className="col">
            <div className="cursor-pointer" onClick={() => history.push(`/public-profile/${obfuscate(eventDetails.supervisorPersonId)}`)}>
              {eventDetails.supervisorName}
            </div>
            <a href={`tel:${formatPhoneNumber(eventDetails.supervisorPhone)}`}>{formatPhoneNumber(eventDetails.supervisorPhone)}</a>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="row">
        <div className="col">
          <div className="heading capitalize">{t('supervisor')} </div>
          <div>{t('supervisor_not_available')}</div>
        </div>
      </div>
    )
  }
}

export default function SupervisorInfo({ eventDetails, userAvatar, history }) {
  return (
    <div className="card supervisor-info">
      <div className="card-body">{getSupervisorInfo(eventDetails, userAvatar, history)}</div>
    </div>
  )
}

SupervisorInfo.propTypes = {
  eventDetails: PropTypes.object,
  userAvatar: PropTypes.object,
  history: PropTypes.object,
}
