import map from 'lodash/map'
import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'

export const toQueryString = function(obj) {
  // Remove null values from the map
  var result = omitBy(obj, isNil)

  // Process map
  return map(result, function(v, k) {
    return encodeURIComponent(k) + '=' + encodeURIComponent(v)
  }).join('&')
}
