import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'

// Actions
import { getTimePunchDetails } from '../../state/actions/timePunchesActions'
import { getDistrictEventDetails, resetDistrictEventDetails } from '../../state/actions/scheduleActions'
import { getOrganization } from '../../state/actions/organizationsActions'

// Common
import Preloader from '../../components/Preloader'

// Utils
import {
  padNumberWithLeadingZeroes,
} from '../../components/utils/utils'
import { convertAbsenceCodeToAbsenceName } from '../../components/utils/timepunchUtils'
import { createMapHref } from '../../components/utils/mapUtils'

import { t } from '../../utils/i18n'
import assignmentIcon from '../../styles/images/baseline-assignment_turned_in-24px.svg'
import cafeIcon from '../../styles/images/baseline-local_cafe-24px.svg'
import diningIcon from '../../styles/images/baseline-local_dining-24px.svg'
import carIcon from '../../styles/images/baseline-directions_car-24px.svg'

import './TimePunchDetailsContainer.scss'
import { formatTime, getDayOfWeek, getMonthAndDay, isToday } from '../../components/utils/datetimeUtils'
import { formatNumberDecimal } from '../../components/utils/numberUtils'
import { STORE_INVENTORY_VALUE } from '../../utils/constants'
import { hasTimePunchesAccess } from '../../components/utils/featureAccessUtils'

class TimePunchDetailsContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      timePunchInfo: {
        inventoryDate: '',
        hours: 0,
        travelHours: 0
      },
      organizationNumber: '',
    }

    this.renderDate = this.renderDate.bind(this)
    this.renderTimePunches = this.renderTimePunches.bind(this)
    this.renderTravelPunches = this.renderTravelPunches.bind(this)
  }

  componentDidMount() {
    const _state = this.state
    const { state } = this.props.location
    if (!state || !state.timePunchInfo) {
      this.props.history.push(`/time-punches`)
      return
    }
    _state.timePunchInfo = state.timePunchInfo
    this.props.getTimePunchDetails(state.timePunchInfo.timePunchId)

    if (this.state.timePunchInfo.timePunchType === STORE_INVENTORY_VALUE) {
      this.props.getDistrictEventDetails(state.timePunchInfo.scheduledEventId)
    } else {
      this.props.resetDistrictEventDetails()
    }

    this.props.getOrganization(this.props.profile.organizationId)

    this.setState(_state)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.featureAccessPending === true && this.props.featureAccessPending === false) {
      if (!hasTimePunchesAccess(this.props.featureAccess)) {
        return this.props.history.push('/profile')
      }
    }

    if (prevProps.timePunchDetailsPending === true && this.props.timePunchDetailsPending === false) {
      if (Object.keys(this.props.timePunchDetailsError).length !== 0 && this.props.timePunchDetailsError.status === 404) {
        return this.props.history.push({
          pathname: '/time-punches',
          state: {
            timePunchError: true,
          }
        })
      }
    }

    if (prevProps.organizationPending === true && this.props.organizationPending === false) {
      this.setState({
        organizationNumber: padNumberWithLeadingZeroes(this.props.organization.organizationNumber, 3),
      })
    }
  }

  renderDate(date) {
    return (
      <div className="row">
        <div className={`col schedule-item-date ${isToday(date) ? 'bold' : ''}`}>
          <div>{`${getDayOfWeek(date)} | ${getMonthAndDay(date)}`}</div>
        </div>
      </div>
    )
  }

  renderAddressMapLink(scheduleData) {
    const encodedAddressUrl = createMapHref(scheduleData.address, scheduleData.city, scheduleData.state, scheduleData.zipCode)
    if (encodedAddressUrl) {
      return (
        <a className="d-flex" href={encodedAddressUrl} target="_blank" rel="noopener noreferrer">
          <i className="material-icons map">place</i>
          {scheduleData.address
            .split(',')
            .filter(Boolean)
            .join(', ')}, {scheduleData.city}, {scheduleData.state} {scheduleData.zipCode}
        </a>
      )
    }
    return ''
  }

  renderTimePunchPeriod(startDateTime, endDateTime) {
    if (!startDateTime || !endDateTime) {
      return ''
    }

    const _startDateTime = startDateTime.split(' ')
    const _endDateTime = endDateTime.split(' ')
    const startMonthDay = _startDateTime[0].split('/').slice(0, 2).join('/')
    const endMonthDay = _endDateTime[0].split('/').slice(0, 2).join('/')

    return `${startMonthDay}, ${formatTime(_startDateTime[1])} - ${endMonthDay}, ${formatTime(_endDateTime[1])}`
  }

  renderTimePunchBreaks(breakCount, mealBreaks) {
    return (
      <>
        {breakCount > 0 &&
          <div className="row lunch-break">
            <div className="col-lg-1 col-2 pr-md-3 pr-0 d-flex justify-content-end">
              <img className="cafe-icon" src={cafeIcon} alt="Cafe icon" />
            </div>
            <div className="col d-flex flex-column justify-content-center">
              <div className="nr-of-breaks">{`${breakCount} ${breakCount > 1 ? t('breaks') : t('break')}`}</div>
            </div>
          </div>
        }
        {mealBreaks.map((mealBreak, index) => {
          return (
            <div className="row meal-break" key={mealBreak.mealStart + index}>
              <div className="col-lg-1 col-2 pr-md-3 pr-0 d-flex justify-content-end">
                <img className="dining-icon" src={diningIcon} alt="Dining icon" />
              </div>
              <div className="col d-flex flex-column justify-content-center">
                <div className="break-period">
                  {formatTime(mealBreak.mealStart.split(' ')[1])}
                  <span className="align-middle pl-2 pr-2"><i className="material-icons">arrow_forward</i></span>
                  {formatTime(mealBreak.mealEnd.split(' ')[1])}
                </div>
              </div>
            </div>
          )
        })}
      </>
    )
  }

  renderStoreNumber = (timePunchInfo) => {
    if (timePunchInfo.storeNumber === undefined || timePunchInfo.storeNumber === null || timePunchInfo.storeNumber === '') {
      return ''
    } else {
      return (<div className="store-number mt-1">#{timePunchInfo.storeNumber}</div>)
    }
  }

  renderTimePunchInfo() {
    const { timePunchInfo } = this.state
    return (
      <div className="section time-punch-info">
        <div className="d-flex align-items-center">
          {timePunchInfo.isMsr && <i className="material-icons msr mt-2 mr-2">link</i>}
          <div className="job-name mt-2">{timePunchInfo.customerName}</div>
        </div>
        {this.renderStoreNumber(timePunchInfo)}
        <div className="store-address mt-1">{this.props.districtEventDetails !== undefined && this.props.districtEventDetails !== null && this.props.districtEventDetails.scheduleData !== undefined && this.props.districtEventDetails.scheduleData.address !== '' && this.renderAddressMapLink(this.props.districtEventDetails.scheduleData)}</div>
        <div className="mt-2">
          {timePunchInfo.absenceCode ?
            <div>{`${t('absence_code')}: ${convertAbsenceCodeToAbsenceName(timePunchInfo.absenceCode)}`}</div>
            :
            <div className="row">
              <div className="col-md-6 col-12 d-flex align-items-baseline">
                {t('work_time').toUpperCase()}:
                <h5 className="ml-2 mr-1">{formatNumberDecimal(timePunchInfo.hours)} {t('hours_abbreviation')}</h5>
              </div>
              <div className="col-md-6 col-12 d-flex align-items-baseline">
                {t('travel_time').toUpperCase()}:
                <h5 className="ml-2 mr-1">{formatNumberDecimal(timePunchInfo.travelHours)} {t('hours_abbreviation')}</h5>
              </div>
            </div>
          }
        </div>
      </div>
    )
  }

  renderTimePunches() {
    const { timePunchDetails } = this.props
    return (timePunchDetails.listOfTimePunches && timePunchDetails.listOfTimePunches.length > 0 &&
      <div className="section">
        <h4 className="mt-4">{t('time_punches').toUpperCase()}</h4>
        {timePunchDetails.listOfTimePunches.map((item, index) => {
          return (
            <div key={((item.adderRoleCode === undefined || item.adderRoleCode === null) ? 0 : item.adderRoleCode) + index}>
              <hr />
              <div className="row time-punch">
                <div className="col-lg-1 col-2 pr-md-3 pr-0 d-flex justify-content-end">
                  <img className="assignment-icon" src={assignmentIcon} alt="Assignment icon" />
                </div>
                <div className="col d-flex flex-column justify-content-center">
                  <div className="name">{item.adderRoleName}</div>
                  <div className="time-period">{this.renderTimePunchPeriod(item.startDateTime, item.endDateTime)}</div>
                  <div className="vehicle-nr">{t('arrival_vehicle_nr', item.arriveVehicleNumber ? ['#' + item.arriveVehicleNumber] : ['(none)'])}</div>
                  <div className="vehicle-nr">{t('departure_vehicle_nr', item.leaveVehicleNumber ? ['#' + item.leaveVehicleNumber] : ['(none)'])}</div>
                </div>
              </div>
              {this.renderTimePunchBreaks(item.breakCount, item.mealBreaks)}
            </div>
          )
        })}
      </div>
    )
  }

  renderTravelPunches() {
    const { timePunchDetails } = this.props
    return (timePunchDetails.listOfTravelVehicleInformation && timePunchDetails.listOfTravelVehicleInformation.length > 0 &&
      <div className="section">
        <h4 className="mt-4">{t('travel_punches').toUpperCase()}</h4>
        {timePunchDetails.listOfTravelVehicleInformation.map((item, index) => {
          return (
            <div key={item.vehicleId + index}>
              <hr />
              <div className="row travel-punch">
                <div className="col-lg-1 col-2 pr-md-3 pr-0 d-flex justify-content-end">
                  <img className="car-icon" src={carIcon} alt="Car icon" />
                </div>
                <div className="col d-flex flex-column justify-content-center">
                  <div className="vehicle-nr">{t('vehicle_nr', ['#' + item.vehicleNum])}</div>
                  <div className="vehicle-type">{t(`vehicle_type_${item.classificationCode.toLowerCase()}`)}</div>
                  <div className="time-period">{this.renderTimePunchPeriod(item.startDateTime, item.endDateTime)}</div>
                  <div className="vehicle-description">
                    {item.isDriver && <span className="border mr-2">{t('driver')}</span>}
                    {item.vehicleDescription}
                  </div>
                </div>
              </div>
              {this.renderTimePunchBreaks(item.breakCount, item.mealBreaks)}
            </div>
          )
        })}
      </div>
    )
  }

  renderReportIssue() {
    const { timePunchInfo, organizationNumber } = this.state
    return (
      <div className="section d-flex justify-content-center mt-4">
        <div className="col-lg-3 col-sm-5 col-12">
          <a
            className="btn btn-secondary uppercase w-100"
            target="_blank"
            rel="noopener noreferrer"
            href={`mailto:timesheets@wisintl.com?Subject=${t('zone')} ${organizationNumber}, ${t('time_punch_issue_for_customer')}: ${timePunchInfo.customerNumber}, ${t('store')}: ${timePunchInfo.storeNumber}, ${t('date')}: ${timePunchInfo.inventoryDate.split(' ')[0]}`}
          >{t('report_issue')}</a>
        </div>
      </div>
    )
  }

  render() {
    const {
      timePunchDetailsPending,
      featureAccessPending,
      districtEventDetailsPending,
      organizationPending,
    } = this.props
    const { timePunchInfo } = this.state

    if (timePunchDetailsPending
      || featureAccessPending
      || districtEventDetailsPending
      || organizationPending
    ) {
      return <Preloader />
    }

    return (
      <div className="time-punch-details">
        <div className="row">
          <div className="col time-punch-details-header">{t('time_punch_details')}</div>
          <div className="col-2 d-flex align-items-center justify-content-end">
            <i
              onClick={() => this.props.history.push('/time-punches')}
              className="material-icons arrow_back cursor-pointer other-avail-icon"
            >
              arrow_back
            </i>
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-12 mt-1">{this.renderDate(moment(new Date(timePunchInfo.inventoryDate)))}</div>
            <div className="col">
              <div className="card">
                <div className="card-body">
                  {this.renderTimePunchInfo()}
                  {this.renderTimePunches()}
                  {this.renderTravelPunches()}
                  {this.renderReportIssue()}
                </div>
              </div>
            </div>
          </div >
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    timePunchDetails: state.timePunchesReducer.timePunchDetails,
    timePunchDetailsPending: state.timePunchesReducer.timePunchDetailsPending,
    timePunchDetailsError: state.timePunchesReducer.timePunchDetailsError,

    featureAccess: state.featureAccessReducer.featureAccess,
    featureAccessPending: state.featureAccessReducer.featureAccessPending,

    districtEventDetails: state.scheduleReducer.districtEventDetails,
    districtEventDetailsPending: state.scheduleReducer.districtEventDetailsPending,
    districtEventDetailsError: state.scheduleReducer.districtEventDetailsError,

    profile: state.loginReducer.profile,

    organization: state.organizationsReducer.organization,
    organizationPending: state.organizationsReducer.organizationPending,

    profileLocale: state.loginReducer.profileLocale,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getTimePunchDetails: (timePunchId) => getTimePunchDetails(timePunchId),
      getDistrictEventDetails: (districtEventId) => getDistrictEventDetails(districtEventId),
      resetDistrictEventDetails: () => resetDistrictEventDetails(),
      getOrganization: (organizationId) => getOrganization(organizationId),
    },
    dispatch
  )
}

TimePunchDetailsContainer.propTypes = {
  getTimePunchDetails: PropTypes.func,
  timePunchDetails: PropTypes.object,
  timePunchDetailsPending: PropTypes.bool,
  timePunchDetailsError: PropTypes.object,
  featureAccess: PropTypes.object,
  featureAccessPending: PropTypes.bool,
  history: PropTypes.object,
  location: PropTypes.object,
  getDistrictEventDetails: PropTypes.func,
  districtEventDetails: PropTypes.object,
  districtEventDetailsPending: PropTypes.bool,
  districtEventDetailsError: PropTypes.object,
  resetDistrictEventDetails: PropTypes.func,
  profile: PropTypes.object,
  getOrganization: PropTypes.func,
  organization: PropTypes.object,
  organizationPending: PropTypes.bool,
  padNumberWithLeadingZeroes: PropTypes.func,
  profileLocale: PropTypes.string,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimePunchDetailsContainer)
