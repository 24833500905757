import * as types from './actionTypes'
import services from '../services'

export function getChannels() {
  return dispatch => {
    dispatch(getChannelsActionPending(true))
    services.news
      .getChannels()
      .then(response => {
        dispatch(getChannelsActionSuccess(response))
        dispatch(getChannelsActionPending(false))
      })
      .catch(error => {
        dispatch(getChannelsActionError(error))
        dispatch(getChannelsActionPending(false))
      })
  }
}

const getChannelsActionSuccess = response => {
  return {
    type: types.GET_CHANNELS_SUCCESS,
    payload: response.data,
  }
}
const getChannelsActionError = response => {
  return {
    type: types.GET_CHANNELS_ERROR,
    error: response,
  }
}
const getChannelsActionPending = status => {
  return {
    type: types.GET_CHANNELS_PENDING,
    status,
  }
}

export function watchChannel(channelId, isWatching) {
  return dispatch => {
    dispatch(watchChannelActionPending(true))
    services.news
      .watchChannel(channelId, isWatching)
      .then(() => {
        dispatch(watchChannelActionSuccess(!isWatching, channelId))
        dispatch(watchChannelActionPending(false))
      })
      .catch(error => {
        dispatch(watchChannelActionError(error, channelId))
        dispatch(watchChannelActionPending(false))
      })
  }
}

const watchChannelActionSuccess = (response, channelId) => {
  return {
    type: types.WATCH_CHANNEL_SUCCESS,
    payload: response,
    channelId,
  }
}
const watchChannelActionPending = status => {
  return {
    type: types.WATCH_CHANNEL_PENDING,
    status,
  }
}
const watchChannelActionError = (response, channelId) => {
  return {
    type: types.WATCH_CHANNEL_ERROR,
    error: response,
    channelId,
  }
}

