import * as types from './actionTypes'
import services from '../services'

export function getScheduleChange(scheduledEventId, personId) {
  return dispatch => {
    dispatch(setScheduleChangePending(true))
    services.schedule
      .fetchScheduleChangeByScheduledEventIdAndPersonId({
        scheduledEventId,
        personId,
      })
      .then(response => {
        dispatch(setScheduleChangePending(false))
        dispatch(setScheduleChangeSuccess(response))
      })
      .catch(error => {
        dispatch(setScheduleChangeError(error))
      })
  }
}

const setScheduleChangeSuccess = response => {
  return {
    type: types.GET_DISTRICT_TEAM_REQUEST,
    payload: response.data,
    status: response.status,
  }
}
const setScheduleChangeError = response => {
  return {
    type: types.GET_DISTRICT_TEAM_REQUEST_ERROR,
    payload: response,
  }
}
const setScheduleChangePending = status => {
  return {
    type: types.GET_DISTRICT_TEAM_REQUEST_PENDING,
    payload: status,
  }
}
