import React from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types'
import './FileUpload.scss';

// const LIST_OF_GDRIVE_PREVIEW = [ //eslint-disable-line
//   'application/pdf',
//   'application/msword',
//   'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
//   'application/vnd.oasis.opendocument.text'
// ];

// const LIST_OF_PREVIEW_NOT_AVAILABLE = [
//   'application/msword',
//   'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
//   'application/vnd.oasis.opendocument.text'
// ];

export default class FileUpload extends React.PureComponent {
  constructor() {
    super();
    this.dropzoneRef = null;
  }


  render() {
    const {
      file, accept, image, contentType, maxFileSize, fileIndex, children
    } = this.props;
    return (
      <div className={`text-center ${image ? 'p-2 file-upload' : ''}`}>
        <Dropzone
          className="dropzone"
          onDrop={(filesAccepted, filesRejected) => this.props.onDrop(filesAccepted, filesRejected, fileIndex, contentType)}
          disableClick={Object.keys(file).length > 0}
          ref={(ref) => { this.dropzoneRef = ref; }}
          accept={accept}
          multiple={false}
          maxSize={maxFileSize}
        >
          <div className="">
            {image ? <img src={image} alt="Upload Video" /> : ''}
            {children ? children : ''}
          </div>
        </Dropzone>
      </div>
    );
  }
}

FileUpload.propTypes = {
  file: PropTypes.object,
  error: PropTypes.bool,
  onDrop: PropTypes.func,
  accept: PropTypes.array,
  image: PropTypes.string,
  contentType: PropTypes.string,
  maxFileSize: PropTypes.number,
  fileIndex: PropTypes.number,
  children: PropTypes.object,
}
