import {
    GET_COMPLIMENT_BY_ID_ERROR,
    GET_COMPLIMENT_BY_ID_SUCCESS,
    GET_COMPLIMENT_BY_ID_PENDING,
    DELETE_COMPLIMENT_BY_ID_ERROR,
    DELETE_COMPLIMENT_BY_ID_SUCCESS,
    DELETE_COMPLIMENT_BY_ID_PENDING,
    GET_RECEIVED_COMPLIMENTS_ERROR,
    GET_RECEIVED_COMPLIMENTS_SUCCESS,
    GET_RECEIVED_COMPLIMENTS_PENDING,
    GET_SENT_COMPLIMENTS_ERROR,
    GET_SENT_COMPLIMENTS_SUCCESS,
    GET_SENT_COMPLIMENTS_PENDING,
    GET_SENT_COMPLIMENTS_PAGED_ERROR,
    GET_SENT_COMPLIMENTS_PAGED_SUCCESS,
    GET_SENT_COMPLIMENTS_PAGED_PENDING,
    POST_COMPLIMENT_ERROR,
    POST_COMPLIMENT_SUCCESS,
    POST_COMPLIMENT_PENDING,
    PATCH_COMPLIMENT_COMMENT_ERROR,
    PATCH_COMPLIMENT_COMMENT_SUCCESS,
    PATCH_COMPLIMENT_COMMENT_PENDING,
    SET_COMPLIMENT_FILTER,
    POST_MODERATION_ITEM_FOR_COMPLIMENT_SUCCESS,
} from '../actions/actionTypes'

import {
    SERVICE_VALUE,
    PRIDE_VALUE,
    INTEGRITY_VALUE,
    RESULTS_VALUE,
    INNOVATION_VALUE,
    TEAMWORK_VALUE
} from '../../utils/constants'
import { cloneDeep } from 'lodash'

export default function complimentsReducer(
    state = {
        compliment: {},
        complimentError: '',
        complimentPending: false,
        deleteComplimentError: '',
        deleteComplimentPending: false,
        receivedCompliments: [],
        receivedComplimentsError: {},
        receivedComplimentsPending: false,
        sentCompliments: [],
        sentComplimentsError: '',
        sentComplimentsPending: false,
        sentComplimentsPaged: [],
        sentComplimentsPagedPending: false,
        sentComplimentsPagedError: '',
        newComplimentError: {},
        newComplimentPending: false,
        newComplimentSuccess: false,
        patchComplimentCommentError: {},
        patchComplimentCommentPending: false,
        patchComplimentCommentSuccess: false,
        receivedComplimentsTotals: {},
        complimentFilter: {
            complimentFilter: INTEGRITY_VALUE,
            currentPage: 0,
            complimentCount: 0,
        },
        receivedComplimentsFilteredArrays: {},
    },
    action
) {
    switch (action.type) {
        case SET_COMPLIMENT_FILTER:
            return {
                ...state,
                complimentFilter: action.payload
            }
        case DELETE_COMPLIMENT_BY_ID_ERROR:
            return {
                ...state,
                deleteComplimentError: action.error,
            }
        case DELETE_COMPLIMENT_BY_ID_PENDING:
            return {
                ...state,
                deleteComplimentPending: action.payload,
            }
        case DELETE_COMPLIMENT_BY_ID_SUCCESS: {
            const _sentCompliments = state.sentCompliments.filter((compliment) => compliment.complimentId !== action.complimentId)
            return {
                ...state,
                sentCompliments: _sentCompliments,
                deleteComplimentError: '',
            }
        }
        case GET_COMPLIMENT_BY_ID_SUCCESS:
            return {
                ...state,
                compliment: action.payload,
                complimentError: '',
            }
        case GET_COMPLIMENT_BY_ID_ERROR:
            return {
                ...state,
                complimentError: action.error,
            }
        case GET_COMPLIMENT_BY_ID_PENDING:
            return {
                ...state,
                complimentPending: action.payload,
            }
        case GET_RECEIVED_COMPLIMENTS_SUCCESS: {
            return {
                ...state,
                receivedCompliments: action.payload,
                receivedComplimentsFilteredArrays: getCoreValueDictionary(initCoreValueDictionaryArray, operateOnCoreValueDictionaryArray, action.payload),
                receivedComplimentsTotals: getCoreValueDictionary(initCoreValueDictionaryInt, operateOnCoreValueDictionaryInt, action.payload),
                receivedComplimentsError: {},
            }        
        }
        case GET_RECEIVED_COMPLIMENTS_ERROR:
            return {
                ...state,
                receivedComplimentsError: action.error,
            }
        case GET_RECEIVED_COMPLIMENTS_PENDING:
            return {
                ...state,
                receivedComplimentsPending: action.payload,
            }
        case GET_SENT_COMPLIMENTS_SUCCESS:
            return {
                ...state,
                sentCompliments: action.payload.length ? action.payload : [],
                sentComplimentsError: '',
            }
        case GET_SENT_COMPLIMENTS_ERROR:
            return {
                ...state,
                sentComplimentsError: action.error,
            }
        case GET_SENT_COMPLIMENTS_PENDING:
            return {
                ...state,
                sentComplimentsPending: action.payload,
            }
            case GET_SENT_COMPLIMENTS_PAGED_SUCCESS:
                return {
                    ...state,
                    sentCompliments: action.payload,
                    sentComplimentsError: '',
                }
            case GET_SENT_COMPLIMENTS_PAGED_ERROR:
                return {
                    ...state,
                    sentComplimentsError: action.error,
                }
            case GET_SENT_COMPLIMENTS_PAGED_PENDING:
                return {
                    ...state,
                    sentComplimentsPending: action.payload,
                }
        case POST_COMPLIMENT_SUCCESS:
            return {
                ...state,
                newComplimentPending: false,
                newComplimentSuccess: true,
            }
        case POST_COMPLIMENT_ERROR:
            return {
                ...state,
                newComplimentError: action.error,
                newComplimentPending: false,
                newComplimentSuccess: false,
            }
        case POST_COMPLIMENT_PENDING:
            return {
                ...state,
                newComplimentPending: true,
            }
        case PATCH_COMPLIMENT_COMMENT_SUCCESS:
            return {
                ...state,
                patchComplimentCommentSuccess: true,
            }
        case PATCH_COMPLIMENT_COMMENT_ERROR:
            return {
                ...state,
                patchComplimentCommentError: action.error,
                patchComplimentCommentSuccess: false,
            }
        case PATCH_COMPLIMENT_COMMENT_PENDING:
            return {
                ...state,
                patchComplimentCommentPending: action.payload,
            }
        case POST_MODERATION_ITEM_FOR_COMPLIMENT_SUCCESS: {
                const _receivedCompliments = state.receivedCompliments.map(compliment =>
                    compliment.complimentId === action.payload.entityId ?
                    { ...compliment, isFlagged: true }
                    :
                    compliment
                )
                return {
                    ...state,
                    receivedCompliments: _receivedCompliments,
                    receivedComplimentsFilteredArrays: getCoreValueDictionary(initCoreValueDictionaryArray, operateOnCoreValueDictionaryArray, _receivedCompliments),
                    receivedComplimentsTotals: getCoreValueDictionary(initCoreValueDictionaryInt, operateOnCoreValueDictionaryInt, _receivedCompliments),
                }
            }
        default:
            return state
    }
}

function initCoreValueDictionaryArray () {
    let coreValueDictionary = { }
    coreValueDictionary[INTEGRITY_VALUE] = []
    coreValueDictionary[PRIDE_VALUE] = []
    coreValueDictionary[SERVICE_VALUE] = []
    coreValueDictionary[TEAMWORK_VALUE] = []
    coreValueDictionary[INNOVATION_VALUE] = []
    coreValueDictionary[RESULTS_VALUE] = []
    return coreValueDictionary
}

function initCoreValueDictionaryInt () {
    let coreValueDictionary = { }
    coreValueDictionary[INTEGRITY_VALUE] = 0
    coreValueDictionary[PRIDE_VALUE] = 0
    coreValueDictionary[SERVICE_VALUE] = 0
    coreValueDictionary[TEAMWORK_VALUE] = 0
    coreValueDictionary[INNOVATION_VALUE] = 0
    coreValueDictionary[RESULTS_VALUE] = 0
    return coreValueDictionary
}

function operateOnCoreValueDictionaryInt (receivedCompliment, coreValueObj, coreValueDictionary) {
    if (coreValueObj.coreValue === undefined || coreValueObj.coreValue === null) { return; }

    coreValueDictionary[coreValueObj.coreValue.toUpperCase()] += 1;

    return coreValueDictionary
}

function operateOnCoreValueDictionaryArray (receivedCompliment, coreValueObj, coreValueDictionary) {
    if (coreValueObj.coreValue === undefined || coreValueObj.coreValue === null) { return; }

    coreValueDictionary[coreValueObj.coreValue.toUpperCase()].push(cloneDeep(receivedCompliment));

    return coreValueDictionary
}

function getCoreValueDictionary(initDictionaryFunction, operateOnCoreValueDictionaryFunction, receivedCompliments) {
    let coreValueDictionary = initDictionaryFunction()

    if (receivedCompliments !== undefined && receivedCompliments !== null && receivedCompliments.length > 0) {
        for (let index = 0; index < receivedCompliments.length; index += 1) {
            for (let cvIndex = 0; cvIndex < receivedCompliments[index].coreValues.length; cvIndex++) {
                const coreValueObj = receivedCompliments[index].coreValues[cvIndex];
                coreValueDictionary = operateOnCoreValueDictionaryFunction(receivedCompliments[index], coreValueObj, coreValueDictionary)
            }            
        }

        return coreValueDictionary
    }

    return coreValueDictionary
}
