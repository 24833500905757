import {
  GET_REQUEST_CHANGE_SUCCESS,
  GET_REQUEST_CHANGE_ERROR,
  GET_REQUEST_CHANGE_PENDING,
} from '../actions/actionTypes'

export default function requestChangeReducer(
  state = {
    requestChangeSuccess: {},
    requestChangeError: {},
    requestChangeStatus: false,
  },
  action
) {
  switch (action.type) {
    case GET_REQUEST_CHANGE_SUCCESS:
      return {
        ...state,
        requestChangeSuccess: action.payload,
        requestChangeError: {},
        requestChangeStatus: false,
      }
    case GET_REQUEST_CHANGE_ERROR:
      return {
        ...state,
        requestChangeError: action.payload,
      }
    case GET_REQUEST_CHANGE_PENDING:
      return {
        ...state,
        requestChangeStatus: action.payload,
      }
    default:
      return state
  }
}
