import React from 'react'
import PropTypes from 'prop-types'
import './PostUserAvatar.scss'
import { isDefinedAndNotEmpty } from '../../components/utils/nullSafeCheckUtils'
import { obfuscate } from '../../components/utils/obfuscateUtils'

class PostUserAvatar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.handleAvatarClick = this.handleAvatarClick.bind(this)
  }

  getAvatarClassName(personName) {
    let lastName;
    if (personName)
      lastName = personName.split(' ')[1];
    if (!personName || !lastName || this.doesLastNameStartWithCWJNQ(lastName)) {
      return "avatarCWJNQNULL"
    } else if (this.doesLastNameStartWithMPLOY(lastName)) {
      return "avatarMPLOY"
    } else if (this.doesLastNameStartWithSDAVZ(lastName)) {
      return "avatarSDAVZ"
    } else if (this.doesLastNameStartWithHRFKUX(lastName)) {
      return "avatarHRFKUX"
    } else if (this.doesLastNameStartWithBGTEI(lastName)) {
      return "avatarBGTEI"
    }
  }

  doesLastNameStartWithLetterInArray(letterArray, lastName) {
    for (let i = 0; i < letterArray.length; i++) {
      if (lastName.toUpperCase().startsWith(letterArray[i])) {
        return true
      }
    }
    return false
  }

  doesLastNameStartWithCWJNQ(lastName) {
    let letters = ['C', 'W', 'J', 'N', 'Q']
    return this.doesLastNameStartWithLetterInArray(letters, lastName)
  }

  doesLastNameStartWithMPLOY(lastName) {
    let letters = ['M', 'P', 'L', 'O', 'Y']
    return this.doesLastNameStartWithLetterInArray(letters, lastName)
  }

  doesLastNameStartWithSDAVZ(lastName) {
    let letters = ['S', 'D', 'A', 'V', 'Z']
    return this.doesLastNameStartWithLetterInArray(letters, lastName)
  }

  doesLastNameStartWithHRFKUX(lastName) {
    let letters = ['H', 'R', 'F', 'K', 'U', 'X']
    return this.doesLastNameStartWithLetterInArray(letters, lastName)
  }

  doesLastNameStartWithBGTEI(lastName) {
    let letters = ['B', 'G', 'T', 'E', 'I']
    return this.doesLastNameStartWithLetterInArray(letters, lastName)
  }

  getAvatarInitials(name) {
    let initials = ''
    if (name && name.length) {
      const splittedName = name.split(' ').slice(0, 2);
      for (let index in splittedName) {
        initials += splittedName[index].toUpperCase().charAt(0)
      }
    }
    return initials
  }

  handleAvatarClick(evt) {
    evt.stopPropagation()
    const { redirectToAvatarPage, personName, userAvatarId,
       userAvatarUrl, userAvatarIsFlagged, personId, history
    } = this.props

    if (personId && history) {
      if (redirectToAvatarPage) {
        if (history.location.pathname === '/profile') {
          history.push({
            pathname: `/my-avatar`,
            state: { personName, userAvatarId, userAvatarUrl, userAvatarIsFlagged }
          })
        } else {
          history.push({
            pathname: `/user-avatar/${obfuscate(personId)}`,
            state: { personName, userAvatarId, userAvatarUrl, userAvatarIsFlagged }
          })
        }
      } else {
        history.push(`/public-profile/${obfuscate(personId)}`)
      }
    }
  }

  render() {
    const { personName, userAvatarUrl, clickable } = this.props

    return (
      <div
        className={`avatar-container ${clickable !== false ? 'cursor-pointer' : ''}`}
        onClick={clickable !== false ? this.handleAvatarClick : () => { }}
      >
        {isDefinedAndNotEmpty(userAvatarUrl)
          ? <img className="user-avatar-image" src={userAvatarUrl} alt="User Avatar" />
          : <div className={`initial-avatar ${this.getAvatarClassName(personName)}`}>
            {this.getAvatarInitials(personName)}
          </div>
        }
      </div>
    )
  }
}


PostUserAvatar.propTypes = {
  personName: PropTypes.string,
  userAvatarId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  userAvatarUrl: PropTypes.string,
  userAvatarIsFlagged: PropTypes.bool,
  redirectToAvatarPage: PropTypes.bool,
  personId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  history: PropTypes.object,
  clickable: PropTypes.bool,
}

export default PostUserAvatar
