import React from 'react'

import Navigation from '../Navigation'
import ToastContainer from '../../containers/ToastContainer'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getRideAlongErrors } from '../../state/actions/transportationProfileActions'
import { getLoggedInPersonId, getRgisBearerToken } from '../utils/localStorageUtils'

import './Home.scss'

class Home extends React.Component {
  componentDidMount() {
    let personId = getLoggedInPersonId()
    let token = getRgisBearerToken()
    if (token !== undefined && token !== null && token !== '' && personId !== undefined && personId !== null && personId !== '') {
      this.props.getRideAlongErrors(personId)
    }
  }

  render() {
    return (
      <div className="home-component">
        <Navigation />
        <ToastContainer />
      </div>
    )
  }
}

function mapStateToProps() {
  return {
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getRideAlongErrors: (personId) => getRideAlongErrors(personId),
    },
    dispatch
  )
}

Home.propTypes = {
  getRideAlongErrors: PropTypes.func,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home)