import get from 'lodash/get'
import * as types from './actionTypes'
import services from '../services'

export function getGeneralAvailability() {
  return dispatch => {
    services.personDetails
      .getGeneralAvailabilty()
      .then(response => {
        dispatch(getGeneralAvailabilityPending(false))
        dispatch(getGeneralAvailabilitySuccess(response))
      })
      .catch(error => {
        dispatch(getGeneralAvailabilityError(error))
      })
  }
}

const getGeneralAvailabilitySuccess = response => {
  return {
    type: types.GET_GENERAL_AVAILABILITY_SUCCESS,
    payload: response.data,
  }
}
const getGeneralAvailabilityError = error => {
  return {
    type: types.GET_GENERAL_AVAILABILITY_ERROR,
    payload: error,
  }
}
const getGeneralAvailabilityPending = response => {
  return {
    type: types.GET_GENERAL_AVAILABILITY_PENDING,
    payload: response,
  }
}

export function setEditingGeneralAvailability(editingGeneralAvailability) {
  return dispatch => {
    dispatch(
      setEditingGeneralAvailabilityAction({
        editingGeneralAvailability: editingGeneralAvailability
      })
    )
  }
}

const setEditingGeneralAvailabilityAction = payload => {
  return {
    type: payload.editingGeneralAvailability ? types.EDITING_GENERAL_AVAILABILITY : types.NOT_EDITING_GENERAL_AVAILABILITY,
    payload: payload,
  }
}

export function saveGeneralAvailability(generalAvailability) {
  return dispatch => {
    dispatch(
      saveGeneralAvailabilityAction({
        generalAvailability: generalAvailability
      })
    )
  }
}

const saveGeneralAvailabilityAction = payload => {
  return {
    type: types.SAVE_GENERAL_AVAILABILITY,
    payload: payload,
  }
}

export function patchGeneralAvailability(generalAvailability) {
  return dispatch => {
    dispatch(patchGeneralAvailabilityActionPending(true))
    services.personDetails
      .patchGeneralAvailability(generalAvailability)
      .then(response => {
        dispatch(patchGeneralAvailabilityActionPending(false))
        dispatch(patchGeneralAvailabilityActionSuccess(response))
      })
      .catch(error => {
        dispatch(patchGeneralAvailabilityActionError(error))
      })
  }
}

const patchGeneralAvailabilityActionSuccess = response => {
  return {
    type: types.PATCH_GENERAL_AVAILABILITY_SUCCESS,
    payload: response.data,
  }
}
const patchGeneralAvailabilityActionError = response => {
  return {
    type: types.PATCH_GENERAL_AVAILABILITY_ERROR,
    error: response,
  }
}
const patchGeneralAvailabilityActionPending = status => {
  return {
    type: types.PATCH_GENERAL_AVAILABILITY_PENDING,
    pending: status,
  }
}
