import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import './ContactDetailsContainer.scss'
import PostUserAvatar from '../../components/PostUserAvatar'
import { formatPhoneNumber } from '../../components/utils/phoneUtils'
import { obfuscate } from '../../components/utils/obfuscateUtils'
import { t } from '../../utils/i18n'


class ContactDetailsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      contactInfo: {},
      generalContact: null,
      userAvatar: {},
    }
  }

  componentDidMount() {
    const _locationState = this.props.location.state
    if (_locationState === undefined || _locationState.contactInfo === undefined || _locationState.generalContact === undefined) {
      this.props.history.push('/contacts')
      return
    }
    this.setState({
      contactInfo: _locationState.contactInfo,
      generalContact: _locationState.generalContact,
      userAvatar: _locationState.userAvatar,
    })
  }

  render() {
    const { contactInfo, generalContact, userAvatar } = this.state
    const { history } = this.props

    return (
      <div className="post-card-container">
        <div className="row">
          <div className="col post-card-header">{t('contact_details')}</div>
          <div className="col-2 d-flex align-items-center justify-content-end">
            <i
              onClick={() => history.push('/contacts')}
              className="material-icons arrow_back cursor-pointer other-avail-icon"
            >
              arrow_back
            </i>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="section">
              <div className="row personal-info ">
                <div>
                  {generalContact ?
                    <h4 className="ml-3">{`${contactInfo.name}${contactInfo.title ? `: ${contactInfo.title}` : ''}`}</h4>
                    :
                    <div className="col d-flex">
                      <PostUserAvatar
                        personName={contactInfo.name}
                        userAvatarUrl={userAvatar !== undefined ? userAvatar.url : ''}
                        personId={contactInfo.personId}
                        redirectToAvatarPage={false}
                        history={history}
                      />
                      <div className="col d-flex flex-column justify-content-center">
                        <div
                          className="name cursor-pointer"
                          onClick={() => history.push(`/public-profile/${obfuscate(contactInfo.personId)}`)}
                        >
                          {contactInfo.name}
                        </div>
                        <div className="name">{contactInfo.title}</div>
                      </div>
                    </div>

                  }
                </div>
              </div>
              {/* <hr /> */}

              <div className="row">
                <div className="col d-flex flex-column justify-content-center">
                  {contactInfo.phone && <hr className="w-100" />}
                  {contactInfo.phone &&
                    <div className="name d-flex align-items-center">
                      <div>
                        <i className="material-icons">phone</i>
                      </div>
                      <div className="ml-4">
                        <div><b>{t('phone_number')}</b></div>
                        <a href={`tel:${formatPhoneNumber(contactInfo.phone)}`}>{formatPhoneNumber(contactInfo.phone)}</a>
                      </div>
                    </div>
                  }

                  {contactInfo.email && <hr className="w-100" />}
                  {contactInfo.email &&
                    <div className="name d-flex align-items-center">
                      <div>
                        <i className="material-icons">email</i>
                      </div>
                      <div className="ml-4">
                        <div><b>{t('email')}</b></div>
                        <a href={`mailto:${contactInfo.email}`}>{contactInfo.email}</a>
                      </div>
                    </div>
                  }

                  {contactInfo.web && <hr className="w-100" />}
                  {contactInfo.web &&
                    <div className="name d-flex align-items-center">
                      <div>
                        <i className="material-icons">web</i>
                      </div>
                      <div className="ml-4">
                        <div><b>{t('web')}</b></div>
                        <a target="_blank" rel="noopener noreferrer" href={contactInfo.web}>{contactInfo.web}</a>
                      </div>
                    </div>
                  }

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps() {
  return {
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
    },
    dispatch
  )
}

ContactDetailsContainer.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactDetailsContainer)