import * as types from './actionTypes'
import services from '../services'

export function getTimePunches(currentDate, nextWeekDate) {
  return dispatch => {
    dispatch(getTimePunchesPending(true))
    services.timePunches
      .fetchTimePunchesByStartDateAndEndDate({
        startDate: currentDate,
        endDate: nextWeekDate,
      })
      .then(response => {
        dispatch(getTimePunchesAction(response, currentDate, nextWeekDate))
        dispatch(getTimePunchesPending(false))
      })
      .catch(error => {
        dispatch(getTimePunchesError(error))
        dispatch(getTimePunchesPending(false))
      })
  }
}

const getTimePunchesAction = (response, timePunchesStartDate, timePunchesEndDate) => {
  return {
    type: types.GET_TIME_PUNCHES_SUCCESS,
    payload: response.data || [],
    timePunchesStartDate,
    timePunchesEndDate
  }
}

const getTimePunchesPending = status => {
  return {
    type: types.GET_TIME_PUNCHES_PENDING,
    status
  }
}

const getTimePunchesError = response => {
  return {
    type: types.GET_TIME_PUNCHES_ERROR,
    error: response.error,
  }
}

export function getTimePunchDetails(timePunchId) {
  return dispatch => {
    dispatch(getTimePunchDetailsPending(true))
    services.timePunches
      .fetchTimePunchDetailsById(timePunchId)
      .then(response => {
        dispatch(getTimePunchDetailsSuccess(response))
        dispatch(getTimePunchDetailsPending(false))
      })
      .catch(error => {
        dispatch(getTimePunchDetailsError(error))
        dispatch(getTimePunchDetailsPending(false))
      })
  }
}

const getTimePunchDetailsSuccess = (response) => {
  return {
    type: types.GET_TIME_PUNCH_DETAILS_SUCCESS,
    payload: response.data,
  }
}

const getTimePunchDetailsPending = status => {
  return {
    type: types.GET_TIME_PUNCH_DETAILS_PENDING,
    status: status,
  }
}

const getTimePunchDetailsError = response => {
  return {
    type: types.GET_TIME_PUNCH_DETAILS_ERROR,
    error: response,
  }
}