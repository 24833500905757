import { chain, toNumber } from 'lodash'
import { GET_REQUEST_HISTORY, GET_REQUEST_HISTORY_ERROR, GET_REQUEST_HISTORY_PENDING } from '../actions/actionTypes'
import { getLoggedInPersonId } from '../../components/utils/localStorageUtils'

function handleRequestDetailsListCount(payload) {
  return chain(payload)
    .values()
    .flatten()
    .value().length
}

function handleMyRequestsDetailsList(payload) {
  let personId = getLoggedInPersonId()
  let myRequestDetailsList = chain(payload)
    .values()
    .flatten()
    .filter(['personId', toNumber(personId)])
    .value()
  return myRequestDetailsList
}

export default function requestDetailsListReducer(
  state = {
    myRequestDetailsList: [],
    requestDetailsList: {},
    requestDetailsListCount: 0,
    requestDetailsListError: {},
    requestDetailsListPending: false,
  },
  action
) {
  switch (action.type) {
    case GET_REQUEST_HISTORY:
      return {
        ...state,
        requestDetailsList: action.payload,
        requestDetailsListCount: handleRequestDetailsListCount(action.payload),
        requestDetailsListError: {},
        requestDetailsListPending: false,
        myRequestDetailsList: handleMyRequestsDetailsList(action.payload),
      }
    case GET_REQUEST_HISTORY_ERROR:
      return {
        ...state,
        requestDetailsListError: action.payload,
      }
    case GET_REQUEST_HISTORY_PENDING:
      return {
        ...state,
        requestDetailsListPending: action.payload,
      }
    default:
      return state
  }
}
