import {
    SET_PROMPTED_FOR_APP_DOWNLOAD, SET_SHOW_APP_DOWNLOAD_MODAL,
  } from '../actions/actionTypes'
  
  export default function mobileDeviceReducer(
    state = {
      promptedForAppDownload: false,
      showAppDownloadModal: false,
    },
    action
  ) {
    switch (action.type) {
      case SET_PROMPTED_FOR_APP_DOWNLOAD:
        return {
          ...state,
          promptedForAppDownload: action.payload,
        }
      case SET_SHOW_APP_DOWNLOAD_MODAL:
        return {
          ...state,
          showAppDownloadModal: action.payload,
        }
      default:
        return state
    }
  }
  