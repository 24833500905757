import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Redirect } from 'react-router-dom'
import Rating from 'react-rating'
import { getEventSurvey, postSurveyFeedback, getPersonDetails } from '../../state/actions/feedbackActions'
import { postListOfProfileImages } from '../../state/actions/profileActions'
import PropTypes from 'prop-types'
import Preloader from '../../components/Preloader'
import PostUserAvatar from '../../components/PostUserAvatar'
import ActionModal from '../../components/ActionModal'
import ConfirmationPopup from '../../components/Feedback/ConfirmationPopup'
import { t } from '../../utils/i18n'
import { obfuscate } from '../../components/utils/obfuscateUtils'
import { getPreferredLanguage } from '../../components/utils/localStorageUtils'
import { nullSafeCheckIsFalse, nullSafeCheckIsTrue } from '../../components/utils/nullSafeCheckUtils'
import { getFeatureAccessList } from '../../state/actions/featureAccessActions'

import './SurveyFeedbackContainer.scss'
import { hasFeedbackAccess } from '../../components/utils/featureAccessUtils'

class SurveyFeedbackContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      eventTemplate: {},
      eventFeedback: {
        templateId: null,
        subjectEventId: null,
        subjectPersonId: null,
        rating: 0,
        ratingLabel: '',
        comments: '',
        feedbackOptions: []
      },
      popup: {
        showPopup: false,
        popupTitle: '',
        popupText: '',
        showSecondAction: false,
        popupActionText1: '',
        popupActionText2: '',
        popupAction1: null,
        popupAction2: null,
      },
      personDetails: {
        fullName: ''
      },
      showSurveyOptions: false,
      feedbackSurveyOptions: {},
      showConfirmationPopup: false,
      templateNameParam: null,
      eventIdParam: null,
      subjectPersonIdParam: null,
      subjectPersonNameParam: null,
    }
    this.onOverallStarClick = this.onOverallStarClick.bind(this)
    this.onOptionStarClick = this.onOptionStarClick.bind(this)
    this.onGoBackClick = this.onGoBackClick.bind(this)
    this.closePopup = this.closePopup.bind(this)
    this.onCommentChange = this.onCommentChange.bind(this)
    this.onFeedbackSubmitClick = this.onFeedbackSubmitClick.bind(this)
    this.onConfirmationOkClick = this.onConfirmationOkClick.bind(this)
    this.onDriverSurveyOptionsSelect = this.onDriverSurveyOptionsSelect.bind(this)
    this.onDriverAdditionalSurveyOptionChange = this.onDriverAdditionalSurveyOptionChange.bind(this)
  }

  componentDidMount() {
    this.props.getFeatureAccessList()

    const _state = this.state
    _state.eventIdParam = this.props.match.params.event_id
    _state.templateNameParam = this.props.match.params.template_name
    _state.subjectPersonIdParam = this.props.location.state.subjectPersonId
    _state.subjectPersonNameParam = this.props.location.state.subjectPersonName
    if (!this.props.location.state || !this.props.location.state.fromSurveysList) {
      this.props.history.push(`/surveys/${_state.eventIdParam}`)
      return
    }
    this.setState(_state)
  }

  componentDidUpdate(prevProps) {
    const _state = this.state

    if (nullSafeCheckIsTrue(prevProps.featureAccessPending) 
      && nullSafeCheckIsFalse(this.props.featureAccessPending) 
      && hasFeedbackAccess(this.props.featureAccess)) {
      this.props.getEventSurvey(_state.templateNameParam)
      _state.eventFeedback.subjectEventId = parseInt(_state.eventIdParam, 10)

      if (_state.subjectPersonIdParam) {
        _state.eventFeedback.subjectPersonId = parseInt(_state.subjectPersonIdParam, 10)
        _state.personDetails.fullName = _state.subjectPersonNameParam
        this.props.postListOfProfileImages([ parseInt(_state.subjectPersonIdParam, 10) ], 'survey-feedback')
      }
    }

    if (prevProps.eventTemplatePending === true && this.props.eventTemplatePending === false) {
      _state.eventTemplate = this.props.eventTemplate
      _state.eventFeedback.templateId = this.props.eventTemplate.id
      _state.eventTemplate.ratingsConfiguration = _state.eventTemplate.ratingsConfiguration.map((rating) => {
        return {
          value: rating.value,
          ...rating.data.find(label => label.language.includes(getPreferredLanguage().toLowerCase()))
        }
      })
      this.setState(_state)
    }

    if (prevProps.postSurveyFeedbackPending === true && this.props.postSurveyFeedbackPending === false) {
      if (Object.keys(this.props.postSurveyFeedbackError).length !== 0) {
        _state.popup = {
          showPopup: true,
          popupTitle: t('something_went_wrong'),
          popupText: '',
          showSecondAction: false,
          popupActionText1: t('ok'),
          popupActionText2: '',
          popupAction1: this.closePopup,
          popupAction2: this.closePopup
        }
      } else {
        _state.showConfirmationPopup = true
      }
      this.setState(_state)
    }

    if (prevProps.personDetailsPending === true && this.props.personDetailsPending === false) {
      _state.personDetails = this.props.personDetails
      this.setState(_state)
    }
  }

  onOverallStarClick(rating) {
    const _state = this.state
    const ratingConfig = _state.eventTemplate.ratingsConfiguration.find((_rating) => _rating.value === rating)
    _state.eventFeedback.rating = rating
    _state.eventFeedback.ratingLabel = ratingConfig.labelText

    if (_state.eventTemplate.hasOptionRating && _state.eventTemplate.name === "Overall Event" && _state.eventTemplate.options.length !== 0
      && (!_state.showSurveyOptions || (_state.showSurveyOptions && ratingConfig.followUpText.length === 0))) {
      _state.eventTemplate.options.map((option) => {
        _state.feedbackSurveyOptions = {
          ..._state.feedbackSurveyOptions,
          [option.id]: {
            id: option.id,
            rating: ratingConfig.followUpText.length !== 0 ? 0 : null,
            ratingLabel: '',
          }
        }
        return ''
      })
    }

    if (!_state.eventTemplate.hasOptionRating && _state.eventTemplate.name === "Driver" && _state.eventTemplate.options.length !== 0
      && !_state.showSurveyOptions) {
      _state.eventTemplate.options.map((option) => {
        _state.feedbackSurveyOptions = {
          ..._state.feedbackSurveyOptions,
          [option.id]: {
            id: option.id,
            minRating: option.minRating,
            maxRating: option.maxRating,
            checked: false,
            additionalOptions: {},
          }
        }
        option.additionalOptions.map((additionalOption) => {
          _state.feedbackSurveyOptions[option.id].additionalOptions = {
            ..._state.feedbackSurveyOptions[option.id].additionalOptions,
            [additionalOption.id]: {
              id: additionalOption.id,
              minRating: additionalOption.minRating,
              maxRating: additionalOption.maxRating,
              checked: false,
            }
          }
          return ''
        })
        return ''
      })
    }

    if (ratingConfig.followUpText.length !== 0) {
      _state.showSurveyOptions = true
    } else {
      _state.showSurveyOptions = false
    }

    this.setState(_state)
  }

  onOptionStarClick(rating, id) {
    const _state = this.state
    _state.feedbackSurveyOptions = {
      ..._state.feedbackSurveyOptions,
      [id]: {
        id,
        rating: rating,
        ratingLabel: _state.eventTemplate.ratingsConfiguration.find((_rating) => _rating.value === rating).labelText
      }
    }
    this.setState(_state)
  }

  onDriverSurveyOptionsSelect(optionId) {
    const _state = this.state
    _state.feedbackSurveyOptions = {
      ..._state.feedbackSurveyOptions,
      [optionId]: {
        ..._state.feedbackSurveyOptions[optionId],
        checked: !_state.feedbackSurveyOptions[optionId].checked,
      }
    }
    this.setState(_state)
  }

  onDriverAdditionalSurveyOptionChange(optionId, additionalOptionId) {
    const _state = this.state
    _state.feedbackSurveyOptions[optionId].additionalOptions = {
      ..._state.feedbackSurveyOptions[optionId].additionalOptions,
      [additionalOptionId]: {
        ..._state.feedbackSurveyOptions[optionId].additionalOptions[additionalOptionId],
        checked: !_state.feedbackSurveyOptions[optionId].additionalOptions[additionalOptionId].checked,
      }
    }
    this.setState(_state)
  }

  closePopup() {
    const _state = this.state
    _state.popup = {
      showPopup: false,
      popupTitle: '',
      popupText: '',
      showSecondAction: true,
      popupActionText1: '',
      popupActionText2: '',
      popupAction1: null,
      popupAction2: null,
    }
    this.setState(_state)
  }

  onGoBackClick() {
    const _state = this.state
    _state.popup = {
      showPopup: true,
      popupTitle: t('confirmation'),
      popupText: t('leave_feedback_survey'),
      showSecondAction: true,
      popupActionText1: t('yes'),
      popupActionText2: t('cancel'),
      popupAction1: () => this.props.history.goBack(),
      popupAction2: this.closePopup
    }
    this.setState(_state)
  }

  onCommentChange(evt) {
    const _state = this.state
    _state.eventFeedback.comments = evt.target.value
    this.setState(_state)
  }

  onFeedbackSubmitClick() {
    const _state = this.state
    let feedback, feedbackOptions = [], feedbackOptionAdditional = []

    if (_state.eventTemplate.name === "Overall Event") {
      feedbackOptions = Object.keys(_state.feedbackSurveyOptions).map((key) => {
        return {
          templateOptionId: _state.feedbackSurveyOptions[key].id,
          rating: _state.feedbackSurveyOptions[key].rating,
          feedbackOptionAdditional: []
        }
      })
      feedback = {
        ..._state.eventFeedback,
        rating: _state.eventFeedback.rating,
        feedbackOptions
      }
    } else {
      Object.keys(_state.feedbackSurveyOptions).map((optionKey) => {
        if (_state.feedbackSurveyOptions[optionKey].minRating <= _state.eventFeedback.rating
          && _state.eventFeedback.rating <= _state.feedbackSurveyOptions[optionKey].maxRating
          && _state.feedbackSurveyOptions[optionKey].checked === true) {
          feedbackOptionAdditional = []
          Object.keys(_state.feedbackSurveyOptions[optionKey].additionalOptions).map((additionalOptionKey) => {
            if (_state.feedbackSurveyOptions[optionKey].additionalOptions[additionalOptionKey].minRating <= _state.eventFeedback.rating
              && _state.eventFeedback.rating <= _state.feedbackSurveyOptions[optionKey].additionalOptions[additionalOptionKey].maxRating
              && _state.feedbackSurveyOptions[optionKey].additionalOptions[additionalOptionKey].checked === true) {
              feedbackOptionAdditional.push({
                templateOptionAdditionalId: _state.feedbackSurveyOptions[optionKey].additionalOptions[additionalOptionKey].id,
                rating: _state.eventFeedback.rating,
              })
            }
            return ''
          })
          feedbackOptions.push({
            templateOptionId: _state.feedbackSurveyOptions[optionKey].id,
            rating: _state.eventFeedback.rating,
            feedbackOptionAdditional
          })
        }
        return ''
      })
      feedback = {
        ..._state.eventFeedback,
        rating: _state.eventFeedback.rating,
        feedbackOptions
      }
    }
    this.props.postSurveyFeedback(feedback)
  }

  onConfirmationOkClick() {
    this.props.history.push({
      pathname: `/surveys/${this.state.eventFeedback.subjectEventId}`,
      state: { fromSurveyFeedback: true }
    })
  }

  renderOverallEventOptionalRatings() {
    const { eventTemplate, eventFeedback, feedbackSurveyOptions } = this.state
    if (eventTemplate.options.findIndex((option) => option.minRating <= eventFeedback.rating && eventFeedback.rating <= option.maxRating) !== -1) {
      return (
        <div className="optional-ratings">
          <hr />
          <h4>{eventTemplate.ratingsConfiguration.find((_rating) => _rating.value === eventFeedback.rating).followUpText}</h4>
          {
            eventTemplate.options.map((option) => {
              if (option.minRating <= eventFeedback.rating && eventFeedback.rating <= option.maxRating) {
                return (
                  <div key={option.id} className="mt-3">
                    <h5 className="option-title">{option.names.find(obj => obj.language.includes(getPreferredLanguage().toLowerCase())).name}</h5>
                    <div className="d-flex flex-md-row flex-column align-items-md-center rating-stars mt-2" onClick={(evt) => evt.stopPropagation()}>
                      <Rating
                        emptySymbol={<i className="material-icons">star_border</i>}
                        fullSymbol={<i className="material-icons rated-star">star_rate</i>}
                        fractions={2}
                        initialRating={feedbackSurveyOptions[option.id].rating / 2}
                        onClick={(value) => this.onOptionStarClick(value * 2, option.id)}
                      />
                      <div className="rating-label text-md-center ml-md-3 ml-1 mb-md-0 mb-2">{feedbackSurveyOptions[option.id].ratingLabel} </div>
                    </div>
                  </div>
                )
              }
              return ''
            })
          }
        </div>
      )
    }
    return ''
  }

  renderDriverSurveyOptions() {
    const { eventTemplate, eventFeedback, feedbackSurveyOptions } = this.state
    if (eventTemplate.options.findIndex((option) => option.minRating <= eventFeedback.rating && eventFeedback.rating <= option.maxRating) !== -1) {
      return (
        <div className="optional-ratings">
          <hr />
          <h4>{eventTemplate.ratingsConfiguration.find((_rating) => _rating.value === eventFeedback.rating).followUpText}</h4>
          <h6>{t('choose_one_or_several_options')}</h6>
          {
            eventTemplate.options.map((option) => {
              if (option.minRating <= eventFeedback.rating && eventFeedback.rating <= option.maxRating) {
                return (
                  <div key={`option-${option.id}`} className="mt-3 d-flex flex-column juctify-content-center">
                    <div className="col-md-3 col-12 p-0">
                      <input
                        type="button"
                        onClick={() => this.onDriverSurveyOptionsSelect(option.id)}
                        className={`btn ${feedbackSurveyOptions[option.id].checked ? 'btn-secondary' : 'btn-outline-secondary'} cursor-pointer uppercase w-100`}
                        value={option.names.find(obj => obj.language.includes(getPreferredLanguage().toLowerCase())).name}
                      />
                    </div>
                    {
                      feedbackSurveyOptions[option.id].checked && option.additionalOptions.length !== 0 &&
                      <div className="d-flex flex-md-row flex-column mt-3">
                        {
                          option.additionalOptions.map((additionalOption) => (
                            <div key={`additional-option-${additionalOption.id}`} className="col-md-3 col-12">
                              <label className="mr-2" htmlFor={`additional-option-${additionalOption.id}`}>
                                {additionalOption.names.find(obj => obj.language.includes(getPreferredLanguage().toLowerCase())).name}
                              </label>
                              <input
                                type="checkbox"
                                id={`additional-option-${additionalOption.id}`}
                                value={feedbackSurveyOptions[option.id].additionalOptions[additionalOption.id].checked}
                                checked={feedbackSurveyOptions[option.id].additionalOptions[additionalOption.id].checked}
                                onChange={() => this.onDriverAdditionalSurveyOptionChange(option.id, additionalOption.id)}
                                data-toggle="toggle"
                              />
                            </div>
                          ))
                        }
                      </div>
                    }
                  </div>
                )
              }
              return ''
            })
          }
        </div>
      )
    }
    return ''
  }

  renderContainerBody() {
    const { eventDetails, profileImages, history } = this.props
    const { eventTemplate, eventFeedback, personDetails, showSurveyOptions, feedbackSurveyOptions } = this.state
    const userAvatar = profileImages.find(obj => obj.personId === eventFeedback.subjectPersonId)
    return (
      <div className="">
        <div className="row overall-event">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <div className="section">
                  <h4>{eventTemplate.titles.find(title => title.language.includes(getPreferredLanguage().toLowerCase())).text}</h4> 
                  <h5>{eventTemplate.bodies.find(body => body.language.includes(getPreferredLanguage().toLowerCase())).text}</h5> 

                  <div className="mt-4 d-flex flex-wrap align-items-center justify-content-between personal-info">
                    {eventFeedback.subjectPersonId &&
                      <div className="d-flex">
                        <PostUserAvatar
                          personName={personDetails.fullName}
                          userAvatarUrl={userAvatar !== undefined ? userAvatar.url : ''}
                          personId={eventFeedback.subjectPersonId}
                          redirectToAvatarPage={false}
                          history={history}
                        />
                        <div className="ml-3 d-flex flex-column justify-content-center">
                          <div
                            className="name cursor-pointer"
                            onClick={() => history.push(`/public-profile/${obfuscate(eventFeedback.subjectPersonId)}`)}
                          >
                            {personDetails.fullName}
                          </div>
                        </div>
                      </div>
                    }
                  </div>

                  {eventFeedback.subjectEventId && !eventFeedback.subjectPersonId &&
                    <div className="mt-3">
                      <h6>{eventDetails.scheduleData.title}</h6>
                      <div>{eventDetails.date}</div>
                    </div>
                  }

                  <div className="d-flex flex-md-row flex-column align-items-md-center rating-stars mt-2" onClick={(evt) => evt.stopPropagation()}>
                    <Rating
                      emptySymbol={<i className="material-icons">star_border</i>}
                      fullSymbol={<i className="material-icons rated-star">star_rate</i>}
                      fractions={2}
                      initialRating={eventFeedback.rating / 2}
                      onClick={(rating) => this.onOverallStarClick(rating * 2)}
                    />
                    <div className="rating-label text-md-center ml-md-3 ml-1 mb-md-0 mb-2">{eventFeedback.ratingLabel} </div>
                  </div>

                  {eventFeedback.rating ?
                    <div className="mt-2 row align-items-center">
                      <div className="col-12 col-md-6">
                        <textarea
                          data-field="comment-body"
                          className="w-100"
                          onChange={this.onCommentChange}
                          value={eventFeedback.comments}
                          placeholder={t('type_comment')}
                          maxLength="500"
                        />
                      </div>
                    </div>
                    :
                    ''
                  }

                  {showSurveyOptions && eventTemplate.name === "Overall Event"
                    ? this.renderOverallEventOptionalRatings() : this.renderDriverSurveyOptions()}

                  <div className="mt-3 d-flex justify-content-center">
                    <div className="col-md-2 col-6">
                      <input
                        type='button'
                        onClick={this.onFeedbackSubmitClick}
                        className="btn btn-rgis-blue cursor-pointer uppercase w-100"
                        value={t('submit')}
                        disabled={
                          !eventFeedback.rating
                          || (eventTemplate.name === "Overall Event"
                            && Object.keys(feedbackSurveyOptions).findIndex((key) => (feedbackSurveyOptions[key].rating === 0)) !== -1)}
                      />
                    </div>
                  </div>
                  <div className="mt-4 px-md-5 disclaimer text-center">
                    <p>
                      {t('feedback_disclaimer1')}
                    </p>
                    <p>
                      {t('feedback_disclaimer2')}{' '}
                      <a href="http://www.rgis.ethicspoint.com" target="_blank" rel="noopener noreferrer">
                        www.rgis.ethicspoint.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { popup } = this.state
    const { eventTemplatePending, postSurveyFeedbackPending, personDetailsPending,
      eventDetails, profileImagesPending, profileImages,
    } = this.props
    const { eventFeedback, eventTemplate, personDetails, showConfirmationPopup, featureAccessPending } = this.state

    if (eventTemplatePending || postSurveyFeedbackPending
      || personDetailsPending || profileImagesPending || featureAccessPending) {
      return (
        <Preloader />
      )
    }

    if (!hasFeedbackAccess(this.props.featureAccess)) {
      return <Redirect to="/profile" />
    }

    return (
      <div className="survey-feedback-container">
        <div className="row">
          <div className="col feedback-header"> {t('feedback')} </div>
          <div className="col-2 d-flex align-items-center justify-content-end">
            <i
              onClick={this.onGoBackClick}
              className="material-icons arrow_back cursor-pointer other-avail-icon"
            >
              arrow_back
            </i>
          </div>
        </div>
        {Object.keys(eventTemplate).length && this.renderContainerBody()}
        <ActionModal
          showModal={popup.showPopup}
          modalTitle={popup.popupTitle}
          modalText={popup.popupText}
          showCancelAction={popup.showSecondAction}
          modalAcceptButtonText={popup.popupActionText1}
          modalCancelButtonText={popup.popupActionText2}
          modalAcceptAction={popup.popupAction1}
          modalCancelAction={popup.popupAction2}
        />
        <ConfirmationPopup
          showPopup={showConfirmationPopup}
          personName={eventFeedback.subjectPersonId ? personDetails.fullName : null}
          eventName={eventFeedback.subjectEventId ? eventDetails.scheduleData.title : null}
          rating={eventFeedback.rating / 2}
          ratingLabel={eventFeedback.ratingLabel}
          onOkClick={this.onConfirmationOkClick}
          userAvatar={profileImages.find(obj => obj.personId === eventFeedback.subjectPersonId)}
        />
      </div >
    )
  }
}

function mapStateToProps(state) {
  return {
    eventTemplate: state.feedbackReducer.eventTemplate,
    eventTemplatePending: state.feedbackReducer.eventTemplatePending,
    eventTemplateError: state.feedbackReducer.eventTemplateError,
    eventDetails: state.eventDetailsReducer.eventDetails,
    postSurveyFeedbackError: state.feedbackReducer.postSurveyFeedbackError,
    postSurveyFeedbackPending: state.feedbackReducer.postSurveyFeedbackPending,
    personDetails: state.feedbackReducer.personDetails,
    personDetailsPending: state.feedbackReducer.personDetailsPending,
    personDetailsError: state.feedbackReducer.personDetailsError,
    profileImages: state.profileReducer.profileImages,
    profileImagesPending: state.profileReducer.profileImagesPending['survey-feedback'],
    profileImagesError: state.profileReducer.profileImagesError,
    featureAccess: state.featureAccessReducer.featureAccess,
    featureAccessPending: state.featureAccessReducer.featureAccessPending,
    profileLocale: state.loginReducer.profileLocale,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getEventSurvey: (templateName) => getEventSurvey(templateName),
      postSurveyFeedback: (feedback) => postSurveyFeedback(feedback),
      getPersonDetails: (personId) => getPersonDetails(personId),
      postListOfProfileImages: (personIds, location) => postListOfProfileImages(personIds, location),
      getFeatureAccessList: () => getFeatureAccessList(),
    },
    dispatch
  )
}

SurveyFeedbackContainer.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  getEventSurvey: PropTypes.func,
  eventTemplatePending: PropTypes.bool,
  eventTemplate: PropTypes.object,
  eventTemplateError: PropTypes.object,
  eventDetails: PropTypes.object,
  postSurveyFeedback: PropTypes.func,
  postSurveyFeedbackError: PropTypes.object,
  postSurveyFeedbackPending: PropTypes.bool,
  getPersonDetails: PropTypes.func,
  personDetails: PropTypes.object,
  personDetailsPending: PropTypes.bool,
  personDetailsError: PropTypes.object,
  postListOfProfileImages: PropTypes.func,
  profileImages: PropTypes.array,
  profileImagesPending: PropTypes.bool,
  profileImagesError: PropTypes.object,
  getFeatureAccessList: PropTypes.func,
  featureAccess: PropTypes.object,
  featureAccessPending: PropTypes.bool,
  profileLocale: PropTypes.string,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveyFeedbackContainer)
