import {
  GET_CHAT_ITEM_SUCCESS,
  GET_CHAT_ITEM_ERROR,
  GET_CHAT_ITEM_PENDING,

  GET_CHAT_MESSAGES_SUCCESS,
  GET_CHAT_MESSAGES_ERROR,
  GET_CHAT_MESSAGES_PENDING,
  REFRESH_CHAT_MESSAGES_SUCCESS,
  REFRESH_CHAT_MESSAGES_ERROR,
  REFRESH_CHAT_MESSAGES_PENDING,

  POST_CHAT_MESSAGE_SUCCESS,
  POST_CHAT_MESSAGE_ERROR,
  POST_CHAT_MESSAGE_PENDING,

  EDIT_CHAT_MESSAGE_SUCCESS,
  EDIT_CHAT_MESSAGE_ERROR,
  EDIT_CHAT_MESSAGE_PENDING,

  DELETE_CHAT_MESSAGE_SUCCESS,
  DELETE_CHAT_MESSAGE_PENDING,
  DELETE_CHAT_MESSAGE_ERROR,

  POST_MODERATION_ITEM_SUCCESS,

  RESET_CHAT_MESSAGES_STATE,
  RESET_CHAT_ITEM_STATE

} from '../actions/actionTypes'

const initialState = {
  noOfLoadingMessages: 50,
  beginningOfChat: false,

  chatItem: {},
  chatItemPending: false,
  chatItemError: {},
  chatMessages: [],
  chatMessagesError: {},
  chatMessagesPending: false,
  lastReceivedChatMessages: [],

  postChatMessagePending: false,
  postChatMessageError: {},

  editChatMessagePending: false,
  editChatMessageError: {},

  deleteChatMessagePending: false,
  deleteChatMessageError: {},

  scrollToMessageId: null,
}

export default function eventChatReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_CHAT_ITEM_SUCCESS: {
      return {
        ...state,
        chatItem: action.payload,
        noOfLoadingMessages: action.payload.newPostCount > state.noOfLoadingMessages ? action.payload.newPostCount : state.noOfLoadingMessages,
        chatItemError: {},
      }
    }
    case GET_CHAT_ITEM_ERROR:
      return {
        ...state,
        chatItemError: action.error,
      }
    case GET_CHAT_ITEM_PENDING: {
      return {
        ...state,
        chatItemPending: action.status,
      }
    }
    case REFRESH_CHAT_MESSAGES_SUCCESS: {
      const _state = {
        ...state,
        chatMessages: action.payload.reverse(),
        lastReceivedChatMessages: action.payload,
        chatMessagesError: {},
        beginningOfChat: true
      }
      return _state
    }
    case REFRESH_CHAT_MESSAGES_ERROR:
      return {
        ...state,
        chatMessagesError: action.error,
      }
    case REFRESH_CHAT_MESSAGES_PENDING: {
      return {
        ...state,
        chatMessagesPending: action.status,
      }
    }
    case GET_CHAT_MESSAGES_SUCCESS: {
      const _state = {
        ...state,
        chatMessages: [...action.payload.reverse(), ...state.chatMessages],
        lastReceivedChatMessages: action.payload.reverse(),
        chatMessagesError: {},
        beginningOfChat: action.payload.length !== state.noOfLoadingMessages
      }
      if (state.chatMessages.length === 0) {
        _state.noOfLoadingMessages = 20
      }
      return _state
    }
    case GET_CHAT_MESSAGES_ERROR:
      return {
        ...state,
        chatMessagesError: action.error,
      }
    case GET_CHAT_MESSAGES_PENDING: {
      return {
        ...state,
        chatMessagesPending: action.status,
      }
    }

    case POST_CHAT_MESSAGE_SUCCESS: {
      const _chatMessages = [...state.chatMessages, action.payload]
      return {
        ...state,
        chatMessages: _chatMessages,
        postChatMessagePending: false,
      }
    }
    case POST_CHAT_MESSAGE_ERROR:
      return {
        ...state,
        postChatMessageError: action.error,
        postChatMessagePending: false,
      }
    case POST_CHAT_MESSAGE_PENDING:
      return {
        ...state,
        postChatMessagePending: action.status,
        postChatMessageError: {},
      }

    case EDIT_CHAT_MESSAGE_SUCCESS: {
      const _chatMessages = [...state.chatMessages]
      const editedIndex = _chatMessages.findIndex(message => message.postId === action.payload.postId)
      _chatMessages[editedIndex] = action.payload
      return {
        ...state,
        chatMessages: _chatMessages,
        editChatMessagePending: false,
      }
    }
    case EDIT_CHAT_MESSAGE_ERROR:
      return {
        ...state,
        editChatMessageError: action.error,
        editChatMessagePending: false,
      }
    case EDIT_CHAT_MESSAGE_PENDING:
      return {
        ...state,
        editChatMessagePending: action.status,
      }

    case DELETE_CHAT_MESSAGE_SUCCESS: {
      return {
        ...state,
        chatMessages: state.chatMessages.filter((messages) => messages.postId !== action.messageId),
        deleteChatMessageError: {},
        deleteChatMessagePending: false,
        scrollToMessageId: action.messageId
      }
    }
    case DELETE_CHAT_MESSAGE_ERROR:
      return {
        ...state,
        deleteChatMessageError: action.error,
        deleteChatMessagePending: false,
      }
    case DELETE_CHAT_MESSAGE_PENDING:
      return {
        ...state,
        deleteChatMessagePending: action.status,
      }

    case POST_MODERATION_ITEM_SUCCESS: {
      const _chatMessages = state.chatMessages.map(message =>
        message.postId === action.payload.entityId ?
          { ...message, isFlagged: true }
          :
          message
      )
      return {
        ...state,
        chatMessages: _chatMessages,
        scrollToMessageId: action.payload.entityId
      }
    }

    case RESET_CHAT_MESSAGES_STATE:
      return {
        ...initialState,
        chatItem: state.chatItem
      }

    case RESET_CHAT_ITEM_STATE:
      return initialState

    default:
      return state
  }
}
