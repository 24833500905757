import {
  GET_SHIFTS_SUCCESS,
  GET_SHIFTS_ERROR,
  GET_SHIFTS_PENDING,
  PUT_ACCEPT_SHIFT_SUCCESS,
  PUT_ACCEPT_SHIFT_ERROR,
  PUT_ACCEPT_SHIFT_PENDING,
  PUT_REJECT_SHIFT_SUCCESS,
  PUT_REJECT_SHIFT_ERROR,
  PUT_REJECT_SHIFT_PENDING,
} from '../actions/actionTypes'
import moment from 'moment'

export default function shiftsReducer(
  state = {
    shifts: [],
    shiftsError: {},
    shiftsPending: false,
    acceptShiftPending: false,
    acceptShiftError: {},
    rejectShiftPending: false,
    rejectShiftError: {},
  },
  action
) {
  switch (action.type) {
    case GET_SHIFTS_SUCCESS:
      return {
        ...state,
        shifts: action.payload === '' ? [] : action.payload.sort((shift1, shift2) => {
          if (moment(shift1.startDateTime).isBefore(moment(shift2.startDateTime))) {
            return -1
          }
          return 1
        }) ,
        shiftsError: {},
      }
    case GET_SHIFTS_ERROR:
      return {
        ...state,
        shiftsError: action.error,
      }
    case GET_SHIFTS_PENDING:
      return {
        ...state,
        shiftsPending: action.status,
      }
    case PUT_ACCEPT_SHIFT_SUCCESS: {
      const _shifts = state.shifts.filter((shift) => shift.shiftId !== action.shiftId)
      return {
        ...state,
        shifts: _shifts,
        acceptShiftError: {},
      }
    }
    case PUT_ACCEPT_SHIFT_ERROR:
      return {
        ...state,
        acceptShiftError: action.error,
      }
    case PUT_ACCEPT_SHIFT_PENDING:
      return {
        ...state,
        acceptShiftPending: action.status,
      }
    case PUT_REJECT_SHIFT_SUCCESS: {
      const _shifts = state.shifts.filter((shift) => shift.shiftId !== action.shiftId)
      return {
        ...state,
        shifts: _shifts,
        rejectShiftError: {},
      }
    }
    case PUT_REJECT_SHIFT_ERROR:
      return {
        ...state,
        rejectShiftError: action.error,
      }
    case PUT_REJECT_SHIFT_PENDING:
      return {
        ...state,
        rejectShiftPending: action.status,
      }
    default:
      return state
  }
}
