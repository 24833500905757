import * as types from './actionTypes'
import services from '../services'

export function addNewPost(newPostObj, history) {
  return dispatch => {
    dispatch(postNewPostActionPending(true))
    services.post
      .postNewPost(newPostObj)
      .then(response => {
        history.goBack()
        dispatch(postNewPostActionPending(false))
        dispatch(postNewPostActionSuccess(response))
      })
      .catch(error => {
        dispatch(postNewPostActionError(error))
      })
  }
}

export function editPost(postObj, history) {
    return dispatch => {
      dispatch(postNewPostActionPending(true))
      services.post
        .editPost(postObj)
        .then(response => {
          history.goBack()
          dispatch(postNewPostActionPending(false))
          dispatch(postNewPostActionSuccess(response))
        })
        .catch(error => {
          dispatch(postNewPostActionError(error))
        })
    }
  }

const postNewPostActionSuccess = response => {
  return {
    type: types.POST_NEW_POST_SUCCESS,
    payload: response.data,
  }
}
const postNewPostActionError = response => {
  return {
    type: types.POST_NEW_POST_ERROR,
    error: response,
  }
}
const postNewPostActionPending = status => {
  return {
    type: types.POST_NEW_POST_PENDING,
    pending: status,
  }
}
