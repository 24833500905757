import {
  GET_BADGES_SUCCESS,
  GET_BADGES_ERROR,
  GET_BADGES_PENDING,
  GET_EARNED_BADGES_SUCCESS,
  GET_EARNED_BADGES_ERROR,
  GET_EARNED_BADGES_PENDING,
} from '../actions/actionTypes'

export default function badgesReducer(
  state = {
    badges: [],
    badgesError: {},
    badgesPending: false,
    earnedBadges: [],
    earnedBadgesError: {},
    earnedBadgesPending: false,
  },
  action
) {
  switch (action.type) {
    case GET_BADGES_SUCCESS: {
      const badges = []
      for (let index = 0; index < action.payload.length; index += 1) {
        const badge = action.payload[index]
        
        const earnedBadgesCount = badge.badgeModels.filter(badgeModel => badgeModel.userEarned).length
        const unearnedBadgesCount = badge.badgeModels.filter(badgeModel => !badgeModel.userEarned && badgeModel.showIfNotEarned).length
        const showBadgeGroup = (earnedBadgesCount > 0 || unearnedBadgesCount > 0) ? true : false // see if the badgeGroup should be rendered

        badges.push({
          ...action.payload[index],
          earnedBadgesCount,
          unearnedBadgesCount,
          showBadgeGroup
        })

        if (showBadgeGroup && !badge.badgeGroupModel.isProgressive) { // sort the badgeModels if they are not progressive
          badges[index].badgeModels.sort((b1, b2) => b2.userEarned - b1.userEarned)
        }

        if (showBadgeGroup && badge.badgeGroupModel.isProgressive) { // sort the badgeModels if they are progressive by highest badge
          badges[index].badgeModels.sort((b1, b2) => b1.sortOrder - b2.sortOrder) // sorting up
        }
      }

      return {
        ...state,
        badges,
        badgesError: {},
      }
    }
    case GET_BADGES_ERROR:
      return {
        ...state,
        badgesError: action.error,
      }
    case GET_BADGES_PENDING:
      return {
        ...state,
        badgesPending: action.status,
      }
    case GET_EARNED_BADGES_SUCCESS:
      return {
        ...state,
        earnedBadges: action.payload,
        earnedBadgesError: {},
      }
    case GET_EARNED_BADGES_ERROR:
      return {
        ...state,
        earnedBadgesError: action.error,
      }
    case GET_EARNED_BADGES_PENDING:
      return {
        ...state,
        earnedBadgesPending: action.status,
      }
    default:
      return state
  }
}
