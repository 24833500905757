import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { t } from '../../utils/i18n'
import { createMapHref } from '../utils/mapUtils'
import { formatTime } from '../utils/datetimeUtils'
import { formatNumber } from '../utils/numberUtils'
import { hasEstimatedDurationAccess } from '../utils/featureAccessUtils'

import { getFeatureAccessList } from '../../state/actions/featureAccessActions'

import carSvg from '../../styles/images/car.svg'
import supervisorSvg from '../../styles/images/supervisor.svg'
import Preloader from '../Preloader'

import './StoreInfo.scss'

class StoreInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }


  componentDidMount() {
    this.props.getFeatureAccessList()
  }

  componentDidUpdate(prevProps) {
  }

  createMapLink = () => {
    const { scheduleData } = this.props

    const encodedAddressUrl = createMapHref(scheduleData.address, scheduleData.city, scheduleData.state, scheduleData.zipCode)
    if (encodedAddressUrl) {
      return (
        <a href={encodedAddressUrl} target="_blank" rel="noopener noreferrer">
          <div>
            {scheduleData.address
              .split(',')
              .filter(Boolean)
              .join(', ')}
          </div>
          <div>
            {scheduleData.city}, {scheduleData.state} {scheduleData.zipCode}
          </div>
        </a>
      )
    }
    return ''
  }

  getSupervisorMarkUp = () => {
    const { isSupervisor } = this.props

    return isSupervisor ?
      (<div className='col'><img className='store-info-icon' src={supervisorSvg} alt="Supervisor" /> {t('supervisor')}</div>)
      : ''
  }

  getEstimatedEventDurationMarkUp = (estimatedDuration) => {
    if (!hasEstimatedDurationAccess(this.props.featureAccess)) {
      return ''
    }
    return (
      <div className="col">
        <div className="heading">{t('estimated_duration')}</div>
        <div className="icon-label">
          <i className="material-icons timelapse">timelapse</i>
          <span>
            {formatNumber(estimatedDuration)} {t('hours')}
          </span>
        </div>
      </div>
    )
  }

  getDriverMarkUp = () => {
    const { vehicleType, isDriver } = this.props

    let vehicle = '';
    if(vehicleType.toUpperCase() === 'PERSONAL'){
      vehicle = t('vehicle_type_personal_car')
    }else if(vehicleType.toUpperCase() === 'COMPANY'){
      vehicle = t('vehicle_type_company_car')
    }else{
      vehicle = ''
    }
    return isDriver ?
      (<div className='col'><img className='vehicle-icon' src={carSvg} alt="Driver" /> {t('driver')} <div className="vehicle-type">{vehicle}</div></div>)
      : ''
  }

  render () {
    const { scheduleData, eventDetails, featureAccessPending } = this.props

    if (featureAccessPending) {
      return <Preloader />
    }

    return (
      <div className="card store-info">
        <div className="card-body">
          <div className="card-title">
            <h6 className="store-title">{scheduleData.title}</h6>
            {scheduleData.bannerName && <div className="banner-name">{scheduleData.bannerName}</div>}
          </div>

          <div className="section">
            <div className="row">
              <div className="col">
                <div className="store-location">
                  {scheduleData.city}, {scheduleData.state}
                </div>
                <div className="store-number">
                  <span className="bold capitalize">{t('store')}:</span> {scheduleData.storeNumber}
                </div>
              </div>
              <div className="col text-right">
                <div>
                  <a href={`tel:${eventDetails.storePhone}`}>{eventDetails.storePhone}</a>
                </div>
              </div>
            </div>
          </div>
          <div className="section">
            <div className="row">
              <div className="col">
                <div className="heading">{t('meet_site')}</div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="store-location">
                  {scheduleData.meetSite }
                </div>
              </div>
            </div>
          </div>

          <div className="section">
            <div className="row">
              <div className="col">
                <div className="heading">{t('store_location')}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-1">
                <i className="material-icons map">place</i>
              </div>
              <div className="col">{this.createMapLink()}</div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="heading">{t('start_time')}</div>
              <div className="icon-label">
                <i className="material-icons access_time">access_time</i>
                <span>{formatTime(eventDetails.storeStartTime)}</span>
              </div>
            </div>
            {this.getEstimatedEventDurationMarkUp(scheduleData.estimatedDuration)}
          </div>

          <div className="row">
              {this.getDriverMarkUp()}
              {this.getSupervisorMarkUp()}
          </div>
        </div>
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    featureAccessPending: state.featureAccessReducer.featureAccessPending,  
    featureAccessError: state.featureAccessReducer.featureAccessError, 
    featureAccess: state.featureAccessReducer.featureAccess,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getFeatureAccessList: () => getFeatureAccessList(),
    },
    dispatch
  )
}

StoreInfo.propTypes = {
  scheduleData: PropTypes.object,
  eventDetails: PropTypes.object,
  isSupervisor: PropTypes.bool,
  isDriver: PropTypes.bool,
  vehicleType: PropTypes.string,

  getFeatureAccessList: PropTypes.func,
  featureAccess: PropTypes.object,
  featureAccessError: PropTypes.object,
  featureAccessPending: PropTypes.bool,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoreInfo)