import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import './ContactsListItem.scss'
import PostUserAvatar from '../../PostUserAvatar'
// import { t } from '../../../utils/i18n'

class ContactsListItem extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const { contact, generalContact, userAvatar } = this.props

    return (
      <div className="row contacts-list-item contacts-width" key={contact.name}>
        <div className="col">
          <div className="card">
            <Link
              to={{
                pathname: `/contacts/contact-details`,
                state: {
                  contactInfo: contact,
                  generalContact,
                  userAvatar,
                },
              }}
            >
              <div className="card-body">
                <div className="section">
                  <div className="row personal-info">
                    <div className="col d-flex">
                      {!generalContact &&
                        <PostUserAvatar
                          personName={contact.name}
                          userAvatarUrl={userAvatar !== undefined ? userAvatar.url : ''}
                          clickable={false}
                        />
                      }
                      <div className="col d-flex flex-column justify-content-center">
                        <div className="name">{contact.name}</div>
                        <div className="name">{contact.title}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

ContactsListItem.propTypes = {
  contact: PropTypes.object,
  generalContact: PropTypes.bool,
  userAvatar: PropTypes.object,
}

export default ContactsListItem

