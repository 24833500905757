import moment from 'moment'

import { t } from '../../utils/i18n/index'
import { 
  isDefined,
  isDefinedAndNotEmpty
} from './nullSafeCheckUtils'
import {
  NewComplimentReceived,
  NewBadgeEarned,
  NewBadgeUnearned,
  ShiftExpiring,
  PerformanceTracker
} from '../../utils/notificationTypeContants'

export function getNotificationPublishedWeekDate(notificationDetails) {
    const { quarter, week, year } = notificationDetails
    let weekDate = moment().startOf('year').year(year).quarter(quarter).add(week - 1, 'w').subtract(3, 'd')
    return `${weekDate.format('MMM. D')} - ${weekDate.add(6, 'd').format('MMM. D')}`
}

export function getUnplannedTravelContent (itemData) {
    let personName = ''
    if (isDefined(itemData.notificationDetails) && isDefined(itemData.notificationDetails.firstName)) {
      personName += itemData.notificationDetails.firstName
    }
    if (isDefined(itemData.notificationDetails) && isDefined(itemData.notificationDetails.lastName)) {
      personName += (' ' + itemData.notificationDetails.lastName)
    }

    let storeName = ''
    if (isDefined(itemData.notificationDetails) && isDefined(itemData.notificationDetails.customerName)) {
      storeName = itemData.notificationDetails.customerName
    }

    let storeNumber = ''
    if (isDefined(itemData.notificationDetails) && isDefined(itemData.notificationDetails.storeNumber)) {
      storeNumber = itemData.notificationDetails.storeNumber
    }

    return t('unplanned_travel_text', [personName, storeName, storeNumber])
}

export function is5Dot0NotificationType(notificationType) {
  if (!isDefinedAndNotEmpty(notificationType)) { return false; }

  return notificationType.toLowerCase() === NewComplimentReceived.toLowerCase()
    || notificationType.toLowerCase() === NewBadgeEarned.toLowerCase()
    || notificationType.toLowerCase() === NewBadgeUnearned.toLowerCase()
    || notificationType.toLowerCase() === ShiftExpiring.toLowerCase()
    || notificationType.toLowerCase() === PerformanceTracker.toLowerCase()
}