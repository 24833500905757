import axios from 'axios'
import apiConfig from '../../config/api-config'
import * as cancelTokenUtil from './utils/cancelTokenUtil'

export function getFeatureAccess() {
    const url = apiConfig.GET_FEATURE_ACCESS_LIST
    const options = cancelTokenUtil.abortAndGetCancelTokenOptions('GET_FEATURE_ACCESS_LIST')
    return axios.get(`${url}`, options)
}

export function getPersonFeatureAccess(personId) {
    const url = apiConfig.GET_PERSON_FEATURE_ACCESS_LIST.replace('[personId]', personId)
    const options = cancelTokenUtil.abortAndGetCancelTokenOptions('GET_PERSON_FEATURE_ACCESS_LIST')
    return axios.get(`${url}`, options)
}