import {
  GET_FEEDBACK_BY_ID_SUCCESS,
  GET_FEEDBACK_BY_ID_ERROR,
  GET_FEEDBACK_BY_ID_PENDING,
  GET_EVENT_TEMPLATE_SUCCESS,
  GET_EVENT_TEMPLATE_ERROR,
  GET_EVENT_TEMPLATE_PENDING,
  POST_SURVEY_FEEDBACK_SUCCESS,
  POST_SURVEY_FEEDBACK_ERROR,
  POST_SURVEY_FEEDBACK_PENDING,
  GET_FEEDBACK_ALL_ERROR,
  GET_FEEDBACK_ALL_PENDING,
  GET_FEEDBACK_ALL_SUCCESS,
  GET_FEEDBACK_PERSON_DETAILS_ERROR,
  GET_FEEDBACK_PERSON_DETAILS_PENDING,
  GET_FEEDBACK_PERSON_DETAILS_SUCCESS
} from '../actions/actionTypes'

export default function feedbackReducer(
  state = {
    feedback: {},
    feedbackError: {},
    feedbackPending: false,

    feedbackAll: {
      overallFeedback: {},
      driverFeedback: [],
      supervisorFeedback: {},
    },
    feedbackAllError: {},
    feedbackAllPending: false,

    eventTemplate: {},
    eventTemplateError: {},
    eventTemplatePending: false,

    postSurveyFeedbackError: {},
    postSurveyFeedbackPending: false,

    personDetails: {},
    personDetailsError: {},
    personDetailsPending: false,
  },
  action
) {
  switch (action.type) {
    case GET_FEEDBACK_ALL_SUCCESS: {
      const overallLength = action.payload[0].data.length
      const overallFeedback = action.payload[0].data.length ? action.payload[0].data[overallLength - 1] : { rating: 0 }
      const driverFeedback = []
      // for drivers
      for (let index = 1; index < action.payload.length; index += 1) {
        if (action.payload[index].data.length === 0) {
          driverFeedback.push({ rating: 0 })
        } else {
          driverFeedback.push(action.payload[index].data[0])
        }
      }
      return {
        ...state,
        feedbackAll: {
          overallFeedback,
          driverFeedback,
        },
        feedbackAllError: {},
      }
    }
    case GET_FEEDBACK_ALL_ERROR: {
      return {
        ...state,
        feedbackAllError: action.error,
      }
    }
    case GET_FEEDBACK_ALL_PENDING: {
      return {
        ...state,
        feedbackAllPending: action.payload,
      }
    }

    case GET_FEEDBACK_BY_ID_SUCCESS: {
      return {
        ...state,
        feedback: action.payload,
        feedbackError: {},
      }
    }
    case GET_FEEDBACK_BY_ID_ERROR: {
      return {
        ...state,
        feedbackError: action.error,
      }
    }
    case GET_FEEDBACK_BY_ID_PENDING: {
      return {
        ...state,
        feedbackPending: action.payload,
      }
    }
    case GET_EVENT_TEMPLATE_SUCCESS: {
      return {
        ...state,
        eventTemplate: action.payload,
        eventTemplateError: {},
      }
    }
    case GET_EVENT_TEMPLATE_ERROR: {
      return {
        ...state,
        eventTemplateError: action.error,
      }
    }
    case GET_EVENT_TEMPLATE_PENDING: {
      return {
        ...state,
        eventTemplatePending: action.payload,
      }
    }
    case POST_SURVEY_FEEDBACK_SUCCESS: {
      return {
        ...state,
        postSurveyFeedbackError: {},
      }
    }
    case POST_SURVEY_FEEDBACK_ERROR: {
      return {
        ...state,
        postSurveyFeedbackError: action.error,
      }
    }
    case POST_SURVEY_FEEDBACK_PENDING: {
      return {
        ...state,
        postSurveyFeedbackPending: action.payload,
      }
    }
    case GET_FEEDBACK_PERSON_DETAILS_SUCCESS: {
      return {
        ...state,
        personDetails: action.payload,
        personDetailsError: {},
      }
    }
    case GET_FEEDBACK_PERSON_DETAILS_ERROR: {
      return {
        ...state,
        personDetailsError: action.error,
      }
    }
    case GET_FEEDBACK_PERSON_DETAILS_PENDING: {
      return {
        ...state,
        personDetailsPending: action.payload,
      }
    }
    default:
      return state
  }
}
