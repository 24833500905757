import React, { Component } from 'react'
import { 
  withRouter, 
  Redirect 
} from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import { cloneDeep } from 'lodash'

import Preloader from '../../components/Preloader'
import ActionModal from '../../components/ActionModal'

import { getPublishedWeek } from '../../state/actions/personDetailsActions'
import { getPersonDetails } from '../../state/actions/personDetailsActions'
import { postPlatformLog } from '../../state/actions/platformLogActions'
import { getUserProfile, logout } from '../../state/actions/navigationActions'
import { getFeatureAccessList } from '../../state/actions/featureAccessActions'
import { getMonthSchedule, getScheduleChangeTypes, getScheduleChangeReasons } from '../../state/actions/scheduleActions'

import { t } from '../../utils/i18n'
import { 
    isMobileDevice 
} from '../../components/utils/mobileUtils'
import { 
  nullSafeCheckIsTrue, 
  nullSafeCheckIsFalse, 
  isDefinedAndHasOwnProperties,
  isDefinedAndNotEmpty,
} from '../../components/utils/nullSafeCheckUtils'
import { 
  getLoggedInPersonId, getRgisBearerToken 
} from '../../components/utils/localStorageUtils'
import { 
  hasMyScheduleAccess, 
} from '../../components/utils/featureAccessUtils'
import { 
  formatDateNotForDisplay, 
  getStartDate 
} from '../../components/utils/datetimeUtils'

class InitializerContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      calledLoadMonthlySchedule: false,
      calledLoadFeatureAccess: false,
      errorCodes: [],
    }
  }

  componentDidMount() {
    this.props.getUserProfile() 
  }

  componentDidUpdate(prevProps) {
    this.checkProfileLoaded(prevProps)
    this.checkPersonDetailsLoaded(prevProps)
    this.checkFeatureAccessLoaded(prevProps)
    this.checkPublishedWeekLoaded(prevProps)
  }

  loadMonthlySchedule = () => {
    const startDate = getStartDate()
    const scheduleStartDate = formatDateNotForDisplay(moment(startDate).subtract(28, 'days'))
    const scheduleEndDate = formatDateNotForDisplay(this.props.publishedWeek.endDate)
    this.props.getMonthSchedule(scheduleStartDate, scheduleEndDate)
    this.setState({
      calledLoadMonthlySchedule: true
    })
  }

  checkPublishedWeekLoaded = (prevProps) => {
    if (!this.hasAnErrorOccurred() && nullSafeCheckIsTrue(prevProps.publishedWeekPending) && nullSafeCheckIsFalse(this.props.publishedWeekPending)) {
      if (isDefinedAndHasOwnProperties(this.props.publishedWeekError)) {
        return
      }

      this.loadMonthlySchedule()
    }
  }

  checkPersonDetailsLoaded = (prevProps) => {
    if (!this.hasAnErrorOccurred() && nullSafeCheckIsTrue(prevProps.personDetailsPending) && nullSafeCheckIsFalse(this.props.personDetailsPending)) {
      if (isDefinedAndHasOwnProperties(this.props.personDetailsError)) {
        return
      }

      this.props.getFeatureAccessList()
      this.setState({
        calledLoadFeatureAccess: true
      })
    }
  }

  checkFeatureAccessLoaded = (prevProps) => {
    if (!this.hasAnErrorOccurred() && nullSafeCheckIsTrue(prevProps.featureAccessPending) && nullSafeCheckIsFalse(this.props.featureAccessPending)) {
      if (isDefinedAndHasOwnProperties(this.props.featureAccessError)) {
        return
      }

      if (hasMyScheduleAccess(this.props.featureAccess)) {
        this.props.getPublishedWeek(this.props.personDetails.organizationId)
        this.props.getScheduleChangeTypes()
        this.props.getScheduleChangeReasons()
      }
    }
  }

  checkProfileLoaded = (prevProps) => {
    if (!this.hasAnErrorOccurred() && nullSafeCheckIsTrue(prevProps.profilePending) && nullSafeCheckIsFalse(this.props.profilePending)) {
      if (isDefinedAndHasOwnProperties(this.props.profileError)) {
        return
      }

      let personId = getLoggedInPersonId()
      this.props.postPlatformLog(personId, isMobileDevice())
      this.props.getPersonDetails(personId)
    }
  }

  addErrorCode = (errorCode) => {
    let { errorCodes } = this.state

    let newErrorCodes = cloneDeep(errorCodes)

    let foundCode = false
    for (let i = 0; i < newErrorCodes.length; i++) {
      if (newErrorCodes[i] === errorCode) {
        foundCode = true
        break
      }
    }

    if (!foundCode) {
      newErrorCodes.push(errorCode)
      this.setState({
        errorCodes: newErrorCodes
      })
    }
  }

  hasAnErrorOccurred = () => {
    let errorOccurred = false

    if (!isDefinedAndNotEmpty(getRgisBearerToken())) { return; }

    if (isDefinedAndHasOwnProperties(this.props.monthScheduleError)) {
      this.addErrorCode('IC1')
      errorOccurred = true
    }
    if (isDefinedAndHasOwnProperties(this.props.publishedWeekError)) {
      this.addErrorCode('IC2')
      errorOccurred = true
    }
    if (isDefinedAndHasOwnProperties(this.props.personDetailsError)) {
      this.addErrorCode('IC3')
      errorOccurred = true
    }
    if (isDefinedAndHasOwnProperties(this.props.featureAccessError)) {
      this.addErrorCode('IC4')
      errorOccurred = true
    }
    if (isDefinedAndHasOwnProperties(this.props.profileError)) {
      this.addErrorCode('IC5')
      errorOccurred = true
    }
    if (isDefinedAndHasOwnProperties(this.props.scheduleChangeTypesError)) {
      this.addErrorCode('IC6')
      errorOccurred = true
    }
    if (isDefinedAndHasOwnProperties(this.props.scheduleChangeReasonsError)) {
      this.addErrorCode('IC7')
      errorOccurred = true
    }

    return errorOccurred
  }

  getBackToLoginMarkUp = () => {
    const { errorCodes } = this.state
    return (
      <ActionModal 
        showModal={true}
        showCancelAction={false}
        modalAcceptButtonText={t('ok')}
        modalCancelButtonText={null}
        modalAcceptAction={() => this.props.logout()}
        modalCancelAction={null}
        modalTitle={t('initialization_error_title')}
        modalText={t('initialization_error_message') + ' ' + t('error_code') + ' ' + errorCodes.join()}
      />
    )
  }

  render() {
    const { 
      profilePending, 
      profile,
      postPlatformLogPending,
      featureAccessPending,
      personDetailsPending,
      publishedWeekPending,
      featureAccess,
      monthSchedulePending,
      scheduleChangeTypesPending,
      scheduleChangeReasonsPending
    } = this.props

    const {
      calledLoadMonthlySchedule,
      calledLoadFeatureAccess
    } = this.state

    if (this.hasAnErrorOccurred()) {
      return this.getBackToLoginMarkUp()
    }

    if (nullSafeCheckIsTrue(profilePending)
      || nullSafeCheckIsTrue(postPlatformLogPending)
      || nullSafeCheckIsTrue(featureAccessPending)
      || nullSafeCheckIsTrue(personDetailsPending)
      || nullSafeCheckIsTrue(publishedWeekPending)
      || nullSafeCheckIsTrue(monthSchedulePending)
      || nullSafeCheckIsFalse(calledLoadFeatureAccess)
      || nullSafeCheckIsTrue(scheduleChangeTypesPending)
      || nullSafeCheckIsTrue(scheduleChangeReasonsPending)
      || (hasMyScheduleAccess(featureAccess) && nullSafeCheckIsFalse(calledLoadMonthlySchedule))) {
      return <Preloader />
    }

    if (nullSafeCheckIsTrue(profile.isDistrictUser)) {
      return <Redirect to="/schedule" />
    }

    return <Redirect to="/profile" />
  }
}

function mapStateToProps(state) {
  return {
    history: PropTypes.object,

    profile: state.loginReducer.profile,
    profilePending: state.loginReducer.profilePending,
    profileError: state.loginReducer.profileError,

    postPlatformLogPending: state.platformLogReducer.postPlatformLogPending,

    featureAccess: state.featureAccessReducer.featureAccess,
    featureAccessPending: state.featureAccessReducer.featureAccessPending,
    featureAccessError: state.featureAccessReducer.featureAccessError,

    personDetails: state.personDetailsReducer.personDetails,
    personDetailsPending: state.personDetailsReducer.personDetailsPending,
    personDetailsError: state.personDetailsReducer.personDetailsError,

    publishedWeekPending: state.personDetailsReducer.publishedWeekPending,
    publishedWeek: state.personDetailsReducer.publishedWeek,
    publishedWeekError: state.personDetailsReducer.publishedWeekError,

    monthScheduleItems: state.scheduleReducer.monthScheduleItems,
    monthSchedulePending: state.scheduleReducer.monthSchedulePending,
    monthScheduleError: state.scheduleReducer.monthScheduleError,

    scheduleChangeTypes: state.scheduleReducer.scheduleChangeTypes,
    scheduleChangeTypesError: state.scheduleReducer.scheduleChangeTypesError,
    scheduleChangeTypesPending: state.scheduleReducer.scheduleChangeTypesPending,

    scheduleChangeReasons: state.scheduleReducer.scheduleChangeReasons,
    scheduleChangeReasonsError: state.scheduleReducer.scheduleChangeReasonsError,
    scheduleChangeReasonsPending: state.scheduleReducer.scheduleChangeReasonsPending,
  }  
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getUserProfile: () => getUserProfile(),
      postPlatformLog: (personId, mobile) => postPlatformLog(personId, mobile),
      getFeatureAccessList: () => getFeatureAccessList(),
      getPersonDetails: (personId) => getPersonDetails(personId),
      getPublishedWeek: (organizationId) => getPublishedWeek(organizationId),
      getMonthSchedule: (currentDate, nextWeek) => getMonthSchedule(currentDate, nextWeek),
      logout: () => logout(),
      getScheduleChangeTypes: () => getScheduleChangeTypes(),
      getScheduleChangeReasons: () => getScheduleChangeReasons(),
    },
    dispatch
  )
}

InitializerContainer.propTypes = {
  history: PropTypes.object,

  getUserProfile: PropTypes.func,
  profile: PropTypes.object,
  profilePending: PropTypes.bool,
  profileError: PropTypes.object,

  postPlatformLog: PropTypes.func,
  postPlatformLogPending: PropTypes.bool,

  getFeatureAccessList: PropTypes.func,
  featureAccess: PropTypes.object,
  featureAccessPending: PropTypes.bool,
  featureAccessError: PropTypes.object,

  getPersonDetails: PropTypes.func,
  personDetailsPending: PropTypes.bool,
  personDetails: PropTypes.object,
  personDetailsError: PropTypes.object,

  getPublishedWeek: PropTypes.func,
  publishedWeekPending: PropTypes.bool,
  publishedWeek: PropTypes.object,
  publishedWeekError: PropTypes.object,

  getMonthSchedule: PropTypes.func,
  monthScheduleItems: PropTypes.array,
  monthSchedulePending: PropTypes.bool,
  monthScheduleError: PropTypes.object,

  logout: PropTypes.func,

  getScheduleChangeTypes: PropTypes.func,
  scheduleChangeTypes: PropTypes.array,
  scheduleChangeTypesError: PropTypes.object,
  scheduleChangeTypesPending: PropTypes.bool,

  getScheduleChangeReasons: PropTypes.func,
  scheduleChangeReasons: PropTypes.array,
  scheduleChangeReasonsError: PropTypes.object,
  scheduleChangeReasonsPending: PropTypes.bool,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InitializerContainer))